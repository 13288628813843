import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon, Input, Popover, Button, Checkbox, Tooltip } from 'antd';
import EmailStatsFilter from '../../EmailStatsFilter/EmailStatsFilter';
import FilterIconWithTooltip from '../../Common/FilterIconWithTooltip/FilterIconWithTooltip';
import './SecondaryListHeader.scss';
import { getCurrentJobDetails } from '../../../Reducers/JobReducer';
import jobMessage from '../../JobForm/JobMessages';

const { Search } = Input;

const mapStateToProps = (state, props) => {
  return {
    currentJobDetails: getCurrentJobDetails(state),
  };
};

const mapDispatchToProps = {};

class SecondaryListHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      isEmailNotSentFilterHidden: true,
      searchQuery: '',
      emailStatsFilterVisible: false,
      shortlistedByFilterVisible: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.onApplyEmailStatsFilter = this.onApplyEmailStatsFilter.bind(this);
    this.onChangeEmailStatsFilters = this.onChangeEmailStatsFilters.bind(this);
    this.onClearEmailStatsFilter = this.onClearEmailStatsFilter.bind(this);
    this.onChangeShortlistedByFilters = this.onChangeShortlistedByFilters.bind(this);
  }

  componentDidMount() {
    const { recentReadNotificationInfo } = this.props;
    const { PersonName } = recentReadNotificationInfo;

    if (PersonName) {
      this.setState({
        showSearch: true,
        searchQuery: PersonName,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { recentReadNotificationInfo } = this.props;
    const { recentReadNotificationInfo: prevRecentReadNotificationInfo } = prevProps;
    const { PersonName: prevPersonName, notificationId: prevNotificationId } = prevRecentReadNotificationInfo;
    const {
      PersonName: currentPersonName,
      notificationId: currentNotificationId,
      PersonName,
    } = recentReadNotificationInfo;
    if (currentPersonName && (currentPersonName !== prevPersonName || currentNotificationId !== prevNotificationId)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        showSearch: true,
        searchQuery: PersonName,
      });
    }
  }

  handleClick() {
    this.setState({ showSearch: true });
  }

  handleBlur() {
    const { searchQuery } = this.state;
    if (searchQuery.trim() === '') this.setState({ showSearch: false, searchQuery: '' }, this.handleSearch(''));
  }

  handleChange(event) {
    const { onSearch, saveRecentReadNotificationInfo, recentReadNotificationInfo } = this.props;
    const { jobId } = recentReadNotificationInfo;
    if (jobId) saveRecentReadNotificationInfo('Connect', {});
    if (!event.target.value) {
      onSearch();
      this.setState({ showSearch: false });
    }
    this.setState({ searchQuery: event.target.value });
  }

  handleSearch(value) {
    const { onSearch } = this.props;
    onSearch(value);
    this.setState({ showSearch: false });
  }

  handleVisibleChange(emailStatsFilterVisible, shortlistedByFilterVisible) {
    this.setState({ emailStatsFilterVisible, shortlistedByFilterVisible });
  }

  onApplyEmailStatsFilter() {
    const {
      onApplyEmailStatsFilter,
      setEmailStatsFilterStatus,
      emailStatsfilterValues,
      shortlistedByFilterValues,
      setShortlistedByFilterStatus,
    } = this.props;
    this.setState({
      emailStatsFilterVisible: false,
      shortlistedByFilterVisible: false,
    });
    setEmailStatsFilterStatus(true);
    setShortlistedByFilterStatus(true);
    onApplyEmailStatsFilter(emailStatsfilterValues, shortlistedByFilterValues);
  }

  onChangeEmailStatsFilters(values) {
    const { onEmailStatsFilterChange } = this.props;
    onEmailStatsFilterChange(values);
  }

  onChangeShortlistedByFilters(values) {
    const { onShortlistedByFilterChange } = this.props;
    onShortlistedByFilterChange(values);
  }

  onClearEmailStatsFilter() {
    const {
      onApplyEmailStatsFilter,
      setEmailStatsFilterStatus,
      onEmailStatsFilterChange,
      onShortlistedByFilterChange,
      setShortlistedByFilterStatus,
    } = this.props;
    this.setState({ emailStatsFilterVisible: false, shortlistedByFilterVisible: false });
    setEmailStatsFilterStatus(false);
    setShortlistedByFilterStatus(false);
    onEmailStatsFilterChange([]);
    onShortlistedByFilterChange([]);
    onApplyEmailStatsFilter([]);
  }

  getShortlistedByFilters = () => {
    const { currentJobDetails } = this.props;
    const candidateAggs = currentJobDetails?.CandidateAggs;
    return candidateAggs?.Shortlist.map(shortlist => (
      <div key={shortlist.Id}>
        <Checkbox value={shortlist.Id}>
          <span>
            <Tooltip title={shortlist.Value}>
              <span className="shortlist-by-name">{shortlist.Value}</span>
            </Tooltip>
            ({shortlist.Count})
          </span>
        </Checkbox>
      </div>
    ));
  };

  render() {
    const {
      count,
      children,
      conversationStatsForJob,
      subscriptionStatsForJob,
      isEmailStatsFilterApplied,
      emailStatsfilterValues,
      shortlistedByFilterValues,
      connectDetails,
      isLoading,
      currentJobDetails,
      isShortlistedByFilterApplied,
    } = this.props;

    const { showSearch, searchQuery, emailStatsFilterVisible, isEmailNotSentFilterHidden, shortlistedByFilterVisible } =
      this.state;
    const shortlistedByFilter = currentJobDetails?.CandidateAggs?.Shortlist
      ? this.getShortlistedByFilters()
      : undefined;

    const isFilterAppliedForShortlist =
      isShortlistedByFilterApplied && shortlistedByFilterValues && shortlistedByFilterValues.length > 0;
    const isFilterAppliedForEmail =
      isEmailStatsFilterApplied && emailStatsfilterValues && emailStatsfilterValues.length > 0;
    const isFilterApplied = isFilterAppliedForShortlist || isFilterAppliedForEmail;
    const emailStatsFilterTitle = (
      <div className="email-stats-filter-title">
        <div className="email-stats-filter-title-text">Filters</div>
        <div className="email-stats-filter-apply-clear">
          <Button className="clear-button" onClick={this.onClearEmailStatsFilter}>
          <FormattedMessage {...jobMessage.clearButton} />
          </Button>
          <Button type="primary" className="apply-button" onClick={this.onApplyEmailStatsFilter}>
          <FormattedMessage {...jobMessage.applyButtonapplyButton} />
          </Button>
        </div>
      </div>
    );

    const emailStatsFilterContent = (
      <div className="email-stats-filter-content">
        <Checkbox.Group
          className="checkbox-group"
          onChange={this.onChangeEmailStatsFilters}
          value={emailStatsfilterValues}
        >
          <h3><FormattedMessage {...jobMessage.communicationsLabel} /></h3>
          <p><FormattedMessage {...jobMessage.totalCandidatesLabel} /> | <FormattedMessage {...jobMessage.totalCommunicationsLabel} /></p>
          <EmailStatsFilter
            conversationStatsForJob={conversationStatsForJob}
            subscriptionStatsForJob={subscriptionStatsForJob}
            connectDetails={connectDetails}
            totalCandidate={count}
            isLoading={isLoading}
            isEmailNotSentFilterHidden={isEmailNotSentFilterHidden}
          />
        </Checkbox.Group>

        {shortlistedByFilter?.length ? (
          <Checkbox.Group
            onChange={this.onChangeShortlistedByFilters}
            value={shortlistedByFilterValues}
            className="checkbox-group"
          >
            <h3 style={{ marginBottom: 0 }}><FormattedMessage {...jobMessage.shortlistedByLabel} /></h3>
            <div>{shortlistedByFilter}</div>
          </Checkbox.Group>
        ) : null}
      </div>
    );

    return (
      <div className="secondaryCardHeader">
        {showSearch ? (
          <Search
            allowClear
            onBlur={this.handleBlur}
            value={searchQuery}
            onChange={this.handleChange}
            onSearch={this.handleSearch}
          />
        ) : (
          <div className="headerTitle">
            <div className="header-title">
              <b><FormattedMessage {...jobMessage.candidatesLabel} /> ({count})</b>
            </div>
            <div className="secondary-list-extras">
              {count > 0 ? (
                <div>
                  <Popover
                    placement="bottom"
                    className="email-stats-filter"
                    title={emailStatsFilterTitle}
                    content={emailStatsFilterContent}
                    trigger="click"
                    visible={emailStatsFilterVisible}
                    onVisibleChange={this.handleVisibleChange}
                  >
                    <FilterIconWithTooltip isFilterApplied={isFilterApplied} style={{ fontSize: 18, marginTop: 5 }} />
                  </Popover>
                </div>
              ) : null}
              {children}
            </div>
            <div className="search">
              <Icon type="search" onClick={this.handleClick} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

SecondaryListHeader.propTypes = {
  count: PropTypes.string,
  onSearch: PropTypes.func,
};

SecondaryListHeader.defaultProps = {
  count: 0,
  onSearch: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryListHeader);
export { SecondaryListHeader as SecondaryListHeaderWithoutStore };
