import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider, Form, Tooltip } from 'antd';
import _ from 'lodash';
import classNames from 'classnames';
import ReportViewFilterContainer from '../../../../Containers/ReportPortalContainer/ReportViewFilterContainer/ReportViewFilterContainer';
import {
  fetchReportPayload,
  getDefaultReportColumnsByAryaName,
  getReportType,
  savedQueryMapper,
  exportReport,
} from '../../../../Utils/ReportPortalUtils/ReportPayloadUtils';
import SaveReportQueryModal from '../SaveReportQueryModal/SaveReportQueryModal';
import ReportDetails from './ReportDetails/ReportDetails';
import styles from './ReportView.module.scss';
import ExportReportButton from './ReportViewFilterButtons/ExportReportButton/ExportReportButton';
import SaveQueryButton from './ReportViewFilterButtons/SaveQueryButton/SaveQueryButton';
import ViewReportButton from './ReportViewFilterButtons/ViewReportButton/ViewReportButton';
import ReportViewHeader from './ReportViewHeader/ReportViewHeader';
import reportPortalMessages from '../../ReportPortalMessages';

export function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export function ReportView(props) {
  const {
    title,
    subtitle,
    filterConfig,
    form,
    reportStats,
    pageSize,
    pageNumber,
    setReportListPageSize,
    setReportListPageNumber,
    getReportStats,
    fetchReportStatsApiStatus,
    exportReportApiStatus,
    reportColumns,
    fetchReportColumnsApiStatus,
    type,
    savedReportDetails,
    onSaveQuerySuccess,
    saveReportQueryApiStatus,
    onSaveQuery,
    clearSaveReportQueryApiStatus,
    hidePagination,
  } = props;
  const isReportStatsApiInProgress = fetchReportStatsApiStatus?.status === 'INPROGRESS';
  const [saveQueryModalVisibility, setSaveQueryModalVisibility] = React.useState(false);
  const [selectedReportColumns, setSelectedReportColumns] = React.useState([]);

  const defaultReportColumnsByAryaName = getDefaultReportColumnsByAryaName(reportColumns);

  const getSortedReportColumns = (columns = []) => {
    let sortedColumns = columns?.filter(
      columnName => !['ALL', 'STATIC', 'DYNAMIC'].includes(columnName?.toUpperCase())
    );
    sortedColumns = _.sortBy(
      sortedColumns,
      columnAryaName => defaultReportColumnsByAryaName?.[columnAryaName]?.Sequence
    );
    return sortedColumns;
  };

  const savedQueryJSON = savedReportDetails?.ReportQuery || '{}';
  const savedQuery = JSON.parse(savedQueryJSON);
  const mappedSavedQuery = savedQueryMapper(savedQuery, savedReportDetails?.dynamicReportColumns);

  React.useEffect(() => {
    if (saveReportQueryApiStatus?.status?.toUpperCase() === 'COMPLETED') {
      setSaveQueryModalVisibility(false);
      clearSaveReportQueryApiStatus();
      if (onSaveQuerySuccess) onSaveQuerySuccess();
    }
  }, [saveReportQueryApiStatus]);

  React.useEffect(() => {
    if (savedReportDetails?.SavedReportId && savedReportDetails?.ReportQuery) {
      const { columns = [] } = mappedSavedQuery;
      const sortedColumns = getSortedReportColumns(columns);
      setSelectedReportColumns(sortedColumns);
    }
  }, [savedReportDetails?.SavedReportId, savedReportDetails?.ReportQuery]);

  useEffect(() => {
    form.validateFields();
  }, []);

  const showSaveQueryModal = () => {
    form.validateFields().then(() => {
      setSaveQueryModalVisibility(true);
    });
  };

  const onSaveReportQuery = ({ queryName, savedQueryId }) => {
    const filter = form.getFieldsValue();
    const fetchPayloadCriteria = { filter, page: 1, pageSize, reportKey: type };
    const reportQuery = fetchReportPayload(fetchPayloadCriteria);
    const columns = reportQuery?.Columns?.filter(
      columnAryaName => !['ALL', 'STATIC', 'DYNAMIC'].includes(columnAryaName?.toUpperCase())
    );
    const sortedColumns = getSortedReportColumns(columns);
    const savedReportQuery = {
      ...reportQuery,
      ReportName: queryName?.trim(),
      Columns: sortedColumns,
      ReportType: getReportType(type),
      SavedReportId: savedQueryId,
      DateRangeType: filter?.dateRangeKey,
    };
    onSaveQuery(savedReportQuery);
  };

  const hideSaveQueryModal = () => {
    setSaveQueryModalVisibility(false);
    clearSaveReportQueryApiStatus();
  };

  const getFilterWithSortedColumns = filter => {
    const sortedColumns = getSortedReportColumns(filter?.columns);
    return {
      ...filter,
      columns: sortedColumns,
    };
  };

  const onViewReportButtonClick = () => {
    form.validateFields().then(filter => {
      setReportListPageNumber(1);
      const filterWithSortedColumns = getFilterWithSortedColumns(filter);
      setSelectedReportColumns(filterWithSortedColumns?.columns);
      getReportStats(filterWithSortedColumns, 1, pageSize);
    });
  };

  const onExportReportButtonClick = () => {
    const { generateReport } = props;
    form.validateFields().then(filter => {
      const filterWithSortedColumns = getFilterWithSortedColumns(filter);
      exportReport(filterWithSortedColumns, type, generateReport, 1, pageSize);
    });
  };

  const fetchReportStats = (_pageNumber, _pageSize) => {
    const filter = form.getFieldsValue();
    getReportStats(filter, _pageNumber, _pageSize);
  };

  const isActionButtonDisabled =
    ['INPROGRESS', 'FAILED'].includes(fetchReportColumnsApiStatus?.status?.toUpperCase()) ||
    hasErrors(form.getFieldsError());

  return (
    <div className={styles.reportView}>
      <div className={styles.reportViewContent}>
        <div className={styles.reportViewHeader}>
          <ReportViewHeader title={title} subtitle={subtitle} />
          {savedReportDetails ? (
            <div className={styles.savedReportDetails}>
              <div className={styles.savedDetailsLabel}>
                <FormattedMessage {...reportPortalMessages.reportViewSavedDetailsLabel} />{' '}
              </div>
              <Tooltip title={savedReportDetails?.ReportName} placement="topLeft">
                <div className={styles.savedDetailsValue}>{savedReportDetails?.ReportName}</div>
              </Tooltip>
            </div>
          ) : null}
        </div>
        <div
          className={classNames(styles.reportViewFilter, {
            [styles.reportViewDisable]: isReportStatsApiInProgress,
          })}
        >
          <ReportViewFilterContainer
            form={form}
            filterConfig={filterConfig}
            savedReportDetails={savedReportDetails}
            type={type}
            fetchReportStatsApiStatus={fetchReportStatsApiStatus}
          />
          <div className={styles.reportFilterButtons}>
            <ViewReportButton
              onClick={onViewReportButtonClick}
              disabled={isActionButtonDisabled}
              loading={fetchReportStatsApiStatus?.status?.toUpperCase() === 'INPROGRESS'}
              type={type}
            />
            <ExportReportButton
              onClick={onExportReportButtonClick}
              disabled={isActionButtonDisabled}
              loading={exportReportApiStatus?.status?.toUpperCase() === 'INPROGRESS'}
              type={type}
            />
            <SaveQueryButton
              buttonText={savedReportDetails ? 'Update Query' : 'Save Query'}
              onClick={showSaveQueryModal}
              disabled={isActionButtonDisabled}
              type={type}
            />
          </div>
        </div>
      </div>
      <Divider className={styles.reportViewDivider} />
      <div className={styles.reportViewContent}>
        <ReportDetails
          reportStats={reportStats}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setReportListPageSize={setReportListPageSize}
          setReportListPageNumber={setReportListPageNumber}
          getReportStats={fetchReportStats}
          fetchReportStatsApiStatus={fetchReportStatsApiStatus}
          title={title}
          reportColumns={reportColumns}
          selectedReportColumns={selectedReportColumns}
          defaultReportColumnsByAryaName={defaultReportColumnsByAryaName}
          fetchReportColumnsApiStatus={fetchReportColumnsApiStatus}
          hidePagination={hidePagination}
        />
      </div>
      <SaveReportQueryModal
        queryDetails={savedReportDetails}
        isVisible={saveQueryModalVisibility}
        onCancel={hideSaveQueryModal}
        onSave={onSaveReportQuery}
        saveQueryApiStatus={saveReportQueryApiStatus}
      />
    </div>
  );
}

export default Form.create()(ReportView);
