import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Timeline } from 'antd';
import { SmartIcon } from '../../Icons/AryaIcons';
import styles from './CandidateAccomplishment.module.scss';
import message from '../../Containers/CandidateDrawer/messages';

function CandidateAccomplishment(props) {
  const { certifications = [] } = props;
  return (
    <div>
      {certifications.length ? (
        <Timeline>
          <div className={styles.accomplishmentsTimeline}>
            {certifications.map(certificate => {
              return (
                <div className={styles.timelineItem}>
                  <Timeline.Item color="#52C41A">
                    <div>
                      <div className={styles.jobTitle}>{certificate.Title}</div>
                      {certificate.Company ? <div className={styles.companyName}>{certificate.Company}</div> : null}
                    </div>
                  </Timeline.Item>
                </div>
              );
            })}
            <div className={styles.hiddenTimeline}>
              <Timeline.Item dot={<div />}></Timeline.Item>
            </div>
          </div>
        </Timeline>
      ) : (
        <div>
          <SmartIcon />
          <span>
            <FormattedMessage {...message.accomplishmentsNotAvailableMessage} />
          </span>
        </div>
      )}
    </div>
  );
}

export default CandidateAccomplishment;
