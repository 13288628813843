import { defineMessages } from 'react-intl';
import CallSettingsMessageJsonEn from '../../Data/En_Us/CallSettingsMessageEn_Us.json';
import CallSettingsMessageJsonEs from '../../Data/Es_Es/CallSettingsMessageEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'CallSettings';
const transformedMessage = formatMessagesWithComponentPrefix(CallSettingsMessageJsonEn, component);
const callSettingsMessages = defineMessages(transformedMessage);

const CallSettingsMessage = defineMessages(callSettingsMessages);
export const callSettingsMessageEn = formatJsonWithComponentPrefix(CallSettingsMessageJsonEn, component);

export const callSettingsMessageEs = formatJsonWithComponentPrefix(CallSettingsMessageJsonEs, component);

export default CallSettingsMessage;
