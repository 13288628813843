import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import { Timeline } from 'antd';
import styles from './CandidateActivityLogs.module.scss';
import { getEventDescription, getEventDisplayText, getEventIcon } from './ActivityLogUtil';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

function CandidateActivityLogs(props) {
  const {
    activityLogs = [],
    candidateActivityLogsCount,
    onLoadMore,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    version,
  } = props;
  const ref = React.useRef(null);

  React.useEffect(() => {
    const onScroll = () => {
      if (ref.current.scrollTop === 0) setCandidateViewHeaderVisibility(true);
      else setCandidateViewHeaderVisibility(false);
    };
    if (ref.current) ref.current.addEventListener('scroll', onScroll);
    return () => {
      if (ref.current) ref.current.removeEventListener('scroll', onScroll);
    };
  }, []);

  const parsedActivityLogs = _.groupBy(activityLogs, activityLog =>
    moment
      .utc(activityLog?.CreatedTime)
      .local()
      .format('MMM DD, YYYY')
  );

  const pendingContent = activityLogs?.length < candidateActivityLogsCount ? 'Loading ...' : null;
  return (
    <div>
      {activityLogs?.length ? (
        <div
          className={classNames(styles.candidateActivityLogs, { [styles.extendView]: !isCandidateViewHeaderVisible })}
          ref={ref}
        >
          <InfiniteScroll
            useWindow={false}
            initialLoad={false}
            threshold={100}
            loadMore={onLoadMore}
            hasMore={activityLogs?.length < candidateActivityLogsCount}
          >
            <Timeline pending={pendingContent}>
              {Object.keys(parsedActivityLogs).map(date => {
                return (
                  <div className={styles.timelineItem}>
                    <Timeline.Item color="#52C41A" key={date}>
                      <div className={styles.activityLog}>
                        <div className={styles.activityLogHeader}>{date}</div>
                        {(parsedActivityLogs?.[date] || []).map(activityLog => {
                          return (
                            <div className={styles.activityContent} key={activityLog}>
                              <div className={styles.activityLogTitle}>
                                <div className={styles.activityMarker}></div>
                                <div className={styles.eventIcon}>{getEventIcon({ activityLog })}</div>
                                <div className={styles.titleName}>
                                  {getEventDisplayText({ eventText: activityLog.EventDisplayText })}
                                </div>
                              </div>
                              <div className={styles.activityLogBody}>
                                {getEventDescription({
                                  event: activityLog,
                                  contentClass: styles.mainContent,
                                  subContentClass: styles.secondaryContent,
                                  version,
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Timeline.Item>
                  </div>
                );
              })}
              {candidateActivityLogsCount ? (
                <div className={styles.hiddenActivityLog}>
                  <Timeline.Item dot={<div />}></Timeline.Item>
                </div>
              ) : null}
            </Timeline>
          </InfiniteScroll>
        </div>
      ) : (
        <div className={styles.emptyScreen}><FormattedMessage {...message.noActivitiesFoundForThisCandidateLabel} /></div>
      )}
    </div>
  );
}

export default CandidateActivityLogs;
