import React from 'react';
import { Form, InputNumber } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './MilesInput.module.scss';
import { validateDistance } from '../../../Utils/FormValidators';
import InfoIconWithTooltip from '../InfoIconWithTooltip/InfoIconWithTooltip';
import message from '../../../Containers/ManualSearchV2/ManualSearchMessages';

const FormItem = Form.Item;

export default function MilesInput(props) {
  const {
    form,
    label,
    initialRange,
    isDistanceDisabled,
    maxAllowedRange,
    inputSize,
    isEmptyDistanceAllowed,
    distanceFieldName,
    formItemStyle,
    candidateContext = 'job',
    placeholder,
    style,
    isManualSearchV2,
  } = props;
  const { getFieldDecorator } = form;
  const getInitialRange = () => {
    if (initialRange) return Math.min(initialRange, 999);
    if (candidateContext === 'segment') return 100;
    return 5;
  };

  const milesPlaceHolder = placeholder || 'In miles';
  const maximumMilesText = placeholder ? '' : 'Maximum Miles';
  const inputStyle = style || { width: '5.5rem' };

  return (
    <FormItem
      label={label}
      style={formItemStyle}
      colon={false}
      className={styles.milesInput}
      validateStatus={isDistanceDisabled ? 'success' : undefined}
      help={isDistanceDisabled ? '' : undefined}
    >
      {getFieldDecorator(distanceFieldName, {
        initialValue: getInitialRange(),
        rules: [
          {
            validator: (rule, validator, callback) =>
              validateDistance(rule, validator, callback, isDistanceDisabled, isEmptyDistanceAllowed),
          },
        ],
      })(
        <InputNumber
          style={inputStyle}
          min={0}
          max={maxAllowedRange}
          placeholder={milesPlaceHolder}
          disabled={isDistanceDisabled}
        />
      )}
      {isManualSearchV2 && <FormattedMessage {...message.milesUnit} />}
      <span
        style={{ fontSize: inputSize === 'large' ? '16px' : '14px', width: '100%' }}
        className={styles.maximumMiles}
      >
        {maximumMilesText} &nbsp;
        <InfoIconWithTooltip
          tooltipTitle={
            <div>
              - <FormattedMessage {...message.maxMilesInfo} />
              <br />
              <p>
                - <FormattedMessage {...message.remoteJobOptionInfoMessage} />
              </p>
            </div>
          }
        />
      </span>
    </FormItem>
  );
}
