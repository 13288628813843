import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import styles from './ManualSearchCandidatesFetchModal.module.scss';
import { getSourceImage, getSourceName } from '../../../Utils/SourceUtils';
import { getManualSearchCandidatesFetchApiStatus } from '../../../Reducers/ManualSearchCandidateReducer';
import message from '../ManualSearchMessages';

const mapStateToProps = (state, props) => {
  const sourceName = getSourceName(props.source);
  return {
    apiStatus: getManualSearchCandidatesFetchApiStatus(state, { jobId: props.jobId, source: sourceName }),
  };
};

const sourceImgIconStyle = { fontSize: 24, height: 24, verticalAlign: 'middle' };

function ManualSearchSourceProgressLoaderV2(props) {
  const { source, apiStatus, userConfig } = props;
  const sourceName = getSourceName(source);
  if (sourceName === 'All') return null;
  const _sourceName = sourceName === 'Active' ? userConfig?.ActiveChannelAlias || 'Active' : sourceName;
  return (
    <div className={styles.sourceItemWrapper}>
      <div className={styles.sourceItem}>
        <div>{getSourceImage(source, sourceImgIconStyle)}</div>
        <div className={styles.sourceName}>{_sourceName}</div>
      </div>
      <div className={styles.divider} />
      <div className={styles.timeWrapper}>
        {apiStatus === 'FAILED' ? (
          <div className={styles.errorMessageWrapper}>
            <div className={styles.closeIcon}>
              <Icon type="close-circle" theme="filled" />
            </div>
            <span className={styles.errorMessageText}>
              <FormattedMessage {...message.failedToSourceLabel} />
            </span>
          </div>
        ) : (
          <>
            {apiStatus === 'INPROGRESS' ? (
              <Icon type="loading" />
            ) : (
              <Icon type="check-circle" theme="filled" className={styles.checkCircle} />
            )}
            <div className={styles.secondsText}>
              {apiStatus === 'INPROGRESS' ? (
                <FormattedMessage {...message.processingLabel} />
              ) : (
                <FormattedMessage {...message.completedLabel} />
              )}{' '}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(ManualSearchSourceProgressLoaderV2);
export {ManualSearchSourceProgressLoaderV2 as ManualSearchSourceProgressLoaderV2WithoutStore}
