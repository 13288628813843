import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import JobMultilocationItem from '../JobMultiLocationItem/JobMultiLocationItem';
import styles from './JobMultiLocationInput.module.scss';
import { locationLayers } from '../../../Utils/LocationUtils';
import jobMessage from '../JobMessages';

function JobMultiLocationInput(props) {
  const {
    locations,
    onChange,
    isLocationSelectionLimitReached,
    isRegionSelectionLimitReached,
    isHidden,
    countryOptions,
    isCountryWideSearchEnabledCountry,
    form,
  } = props;

  return (
    <div style={{ display: isHidden ? 'none' : 'block' }}>
      <Row className={styles.multiLocationLabelsWrapper}>
        <Col span={4}>
          <div className={styles.label}>
            <FormattedMessage {...jobMessage.countryLabelWithAsterisk} />
          </div>
        </Col>
        <Col span={5}>
          <div className={styles.label}>
            <FormattedMessage {...jobMessage.regionLabel} />
          </div>
        </Col>
        <Col span={9}>
          <div className={styles.label}>
            <FormattedMessage {...jobMessage.locationLabel} />
          </div>
        </Col>
        <Col span={5}>
          <div className={styles.label}>
            <FormattedMessage {...jobMessage.primaryLocationLabel} />
          </div>
        </Col>
      </Row>
      {locations?.map((location, index) => (
        <JobMultilocationItem
          onChange={onChange}
          key={location.Id}
          currentKey={location.Id}
          locations={locations}
          currentLocation={location}
          isLocationSelectionLimitReached={isLocationSelectionLimitReached}
          isRegionSelectionLimitReached={isRegionSelectionLimitReached}
          isFirstElement={index === 0}
          isLastElement={index === locations.length - 1}
          countryOptions={countryOptions}
          isCountryWideSearchEnabledCountry={isCountryWideSearchEnabledCountry}
          layers={locationLayers}
          form={form}
        />
      ))}
    </div>
  );
}

export default JobMultiLocationInput;
