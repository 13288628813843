import React from 'react';
import { Modal, Button, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import BulkCandidatesDownloadFilterInfo from '../BulkCandidatesCriteria/BulkCandidatesDownloadFilterInfo';
import styles from './BulkShortlistConfirmationModal.module.scss';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

export default function BulkShortlistConfirmationModal(props) {
  const {
    visible,
    onCancel,
    candidateCount,
    eventCallbacks,
    candidateDownloadFilterConfig,
    candidateCommunicationFilterConfig,
  } = props;

  return (
    <div>
      <Modal visible={visible} footer={null} closable={false} centered>
        <div className={styles.modalContainer}>
          <div className={styles.iconDiv}>
            <Icon type="question-circle" theme="filled" />
          </div>
          <div>
            <div className={styles.bulkConfirmation}>
            <FormattedMessage {...message.areYouSureYouWantToShortlistLabel} />{candidateCount}  <FormattedMessage {...message.candidatesLabel} />?
            </div>
            <BulkCandidatesDownloadFilterInfo
              candidateDownloadFilterConfig={candidateDownloadFilterConfig}
              candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
            />
            <div className={styles.actionButton}>
              <Button
                type="default"
                shape="round"
                style={{ marginRight: '8px' }}
                key="cancel-button"
                onClick={onCancel}
              >
                 <FormattedMessage {...message.cancelLabel} />
              </Button>
              <Button type="primary" shape="round" key="ok-button" onClick={eventCallbacks.onSend}>
              <FormattedMessage {...message.yesShortlist} />
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
