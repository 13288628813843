import { FormattedMessage, injectIntl } from 'react-intl';
import { Checkbox, DatePicker, Form, Icon, Input, Tooltip } from 'antd';
import cardValidator from 'card-validator';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { AmexCardIcon, DiscoverCardIcon, MasterCardIcon, VisaCardIcon } from '../../Icons/AryaIcons';
import { getCardProviderIcon } from './CreditCardDetails';
import './CreditCardForm.scss';
import placeholder from '../Placeholders/PlaceholdersMessages';

const { MonthPicker } = DatePicker;

const supportedCard = ['visa', 'american-express', 'mastercard', 'discover'];

class CreditCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      cardType: '',
    };
  }

  validateNumber = (rule, value, callback) => {
    const cardVaildity = cardValidator.number(value);
    const cardType = _.get(cardVaildity, ['card', 'type'], null);
    const cardName = _.get(cardVaildity, ['card', 'niceType'], null);
    this.setState({
      cardType: cardType || '',
    });
    if ((!cardVaildity.isValid && value !== '') || !supportedCard.includes(cardType)) {
      if (value === undefined || value === '') {
        callback('Card Number Required');
      } else if (cardType && !supportedCard.includes(cardType)) {
        callback(`${cardName} card not supported`);
      } else {
        callback('Invalid Card Number');
      }
    }
    callback();
  };

  validateName = (rule, value, callback) => {
    const trimmedValue = value?.trim();
    if (trimmedValue === undefined || trimmedValue === '') {
      callback('');
      return;
    }
    const nameList = trimmedValue.split(/\s+/);
    const firstName = nameList.shift();
    const lastName = nameList.join(' ');

    if (lastName.length > 50 || firstName.length > 50) {
      callback(`Oops, you have exceeded the maximum character limit of 50.`);
      return;
    }
    callback();
  };

  validateCvv = (rule, value, callback) => {
    const { form } = this.props;
    let cvvLength = 3;
    const cardNumber = form.getFieldValue('Number');
    const cardVaildity = cardValidator.number(cardNumber);
    if (_.get(cardVaildity, ['card', 'type'], null) === 'american-express') {
      cvvLength = 4;
    }
    const cvvVaildity = cardValidator.cvv(value, cvvLength);
    if (!cvvVaildity.isValid && value !== '') {
      if (value === undefined) {
        callback('CVV Number Required');
      } else {
        callback('Invalid CVV');
      }
    }
    callback();
  };

  modalFormInfo = () => {
    const { form } = this.props;
    return form;
  };

  validateExpirationDate = (rule, value, callback) => {
    if (value === '') {
      callback('Please Select Expiry Date');
    }
    callback();
  };

  onChange = () => {
    this.setState(prev => ({ checked: !prev.checked }));
  };

  disabledDate = current => {
    return current && current < moment().subtract(1, 'months');
  };

  getFormItemLabelText = labelText => {
    return <span className="payment-card-form-label">{labelText}</span>;
  };

  render() {
    const { form, creditCardFormDisplay, isCardSaveDisabled, intl } = this.props;
    const { getFieldDecorator } = form;
    const { cardType } = this.state;
    return (
      <Form layout="inline" className="payment-add-card" hideRequiredMark>
        <Form.Item label={this.getFormItemLabelText('Name on Card')} style={{ width: '100%' }} colon={false}>
          {getFieldDecorator('Name', {
            rules: [{ validator: this.validateName }, { required: true }],
          })(
            <div className="payment-card-form-item">
              <Input placeholder={intl.formatMessage({ ...placeholder.nameOnCardLabel })} />
              <div className="payment-card-form-item-offset" />
            </div>
          )}
        </Form.Item>
        <div>
          <Form.Item label={this.getFormItemLabelText('Credit Card Number')} style={{ width: '100%' }} colon={false}>
            {getFieldDecorator('Number', {
              rules: [{ validator: this.validateNumber }, { required: true }],
            })(
              <div className="payment-card-form-item">
                <Input
                  className="payment-card-form-number"
                  placeholder={intl.formatMessage({ ...placeholder.creditCardNumberLabel })}
                  addonAfter={<div className="card-detail-card-icon-wrapper">{getCardProviderIcon(cardType)}</div>}
                />
                <Tooltip
                  placement="top"
                  overlayClassName="supported-payment-cards-tooltip"
                  title={
                    <div className="supported-payment-cards">
                      <div className="supported-payment-card-text">Supported payment types:</div>
                      <div className="supported-payment-card-icons">
                        <VisaCardIcon className="payment-card-icon" />
                        <MasterCardIcon className="payment-card-icon" />
                        <DiscoverCardIcon className="payment-card-icon" />
                        <AmexCardIcon className="payment-card-icon" />
                      </div>
                    </div>
                  }
                >
                  <Icon type="info-circle" className="payment-card-info-icon" />
                </Tooltip>
              </div>
            )}
          </Form.Item>
        </div>
        <div className="payment-date-cvv">
          <Form.Item label={this.getFormItemLabelText('Expiration Date')} colon={false} style={{ width: '150px' }}>
            {getFieldDecorator('Date', {
              rules: [{ validator: this.validateExpirationDate }, { required: true }],
            })(
              <MonthPicker
                disabledDate={this.disabledDate}
                placeholder={intl.formatMessage({ ...placeholder.selectDateLabel })}
              />
            )}
          </Form.Item>
          <Form.Item label={this.getFormItemLabelText('CVV')} colon={false} style={{ width: '150px' }}>
            {getFieldDecorator('Cvv', {
              rules: [{ validator: this.validateCvv }, { required: true }],
            })(<Input placeholder={intl.formatMessage({ ...placeholder.cVVNumberLabel })} />)}
          </Form.Item>
        </div>
        {!creditCardFormDisplay && !isCardSaveDisabled ? (
          <div className="payment-default-add">
            <Form.Item>
              {getFieldDecorator('savePaymentDetails', {
                valuePropName: 'checked',
              })(<Checkbox onChange={this.onChange}>Save this card for future payments</Checkbox>)}
            </Form.Item>
          </div>
        ) : null}
      </Form>
    );
  }
}

CreditCardForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
  }).isRequired,
};

export default injectIntl(CreditCardForm);
export { CreditCardForm as CreditCardFormWithoutInjectIntl };
