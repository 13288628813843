import { Icon } from 'antd';
import * as React from 'react';

const MessageDeliveredSvg = props => (
  <svg fill="none" height="12" viewBox="0 0 12 7" width="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m8.78906.703125-3.16406 3.187495-.70312-.70312 3.16406-3.1875zm2.13284-.703125.7031.703125-6 5.999995-2.78906-2.78906.70312-.70312 2.08594 2.08594zm-10.9219 3.91406.703125-.70312 2.789065 2.78906-.70313.70312z"
      fill="#83878c"
    />
  </svg>
);

const MessageDeliveredIcon = props => <Icon component={() => MessageDeliveredSvg(props)} {...props} />;
export default MessageDeliveredIcon;
