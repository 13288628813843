import React from 'react';
import { FormattedMessage } from 'react-intl';
import ChatBubbleBlockIcon from '../../Icons/ChatBubbleBlockIcon';
import styles from './CandidateOptOutWidget.module.scss';
import message from '../Placeholders/PlaceholdersMessages';

export default function CandidateOptOutWidget({ candidateOptOutType }) {
  return (
    <div className={styles.optOutWidgetWrapper}>
      <div className={styles.optOutIconWrapper}>
        <ChatBubbleBlockIcon className={styles.optOutIcon} /> <p><FormattedMessage {...message.optedOutLabel}/></p>
      </div>
      <div className={styles.optOutReasonContent}><FormattedMessage {...message.candidateOptedOutLabel}/>: {candidateOptOutType}</div>
    </div>
  );
}
