import React from 'react';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './AllTabCandidateAggregationFilterDrawer.module.scss';
import FilterSectionWithCheckbox from './FilterSectionWithCheckbox';
import LinkButton from '../../../Components/Common/LinkButton/LinkButton';
import message from '../../../Components/Placeholders/PlaceholdersMessages';

const RelocationStatusFilter = ({
  sortedRelocationStatus,
  candidatesRelocationStatusField,
  selectedRelocationStatus,
  onClickClear,
  form,
}) => {
  return (
    <div className={styles.filterSection}>
      <div className={styles.filterSectionHeader}>
        <span className={styles.subsectionTitle}><FormattedMessage {...message.willingToRelocateLabel}/></span>
        <LinkButton
          buttonName="Clear all"
          onClickButton={() => {
            onClickClear(candidatesRelocationStatusField);
          }}
          style={{ color: 'rgba(7, 16, 26, 0.5)' }}
        />
      </div>
      {form.getFieldDecorator(candidatesRelocationStatusField, {
        initialValue: selectedRelocationStatus,
      })(
        <Checkbox.Group style={{ width: '100%' }}>
          <FilterSectionWithCheckbox
            filterSectionKeys={Object.keys(sortedRelocationStatus || {})}
            sortedAllTabFilterSection={sortedRelocationStatus || {}}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '12px',
              width: '600px',
            }}
            maxTextLength={35}
          />
        </Checkbox.Group>
      )}
    </div>
  );
};

export default RelocationStatusFilter;
