import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, Icon } from 'antd';
import styles from './CountryChangeModal.module.scss';
import message from '../ManualSearchV2/ManualSearchMessages';


function CountryChangeModal(props) {
  const {
    setIsCountryChangeModalVisible,
    isCountryChangeModalVisible,
    onChange,
    currentLocation,
    updatedCountryCode,
  } = props;
  const handleOk = () => {
    const updatedLocation = {
      ...currentLocation,
      CountryCode: updatedCountryCode,
      Location: undefined,
      Region: undefined,
      State: undefined,
      IsPrimaryLocation: true,
    };
    onChange([updatedLocation]);
    setIsCountryChangeModalVisible(false);
  };

  const handleCancel = () => {
    setIsCountryChangeModalVisible(false);
  };

  return (
    <Modal
      visible={isCountryChangeModalVisible}
      closable={false}
      footer={null}
      height="223px"
      width="471px"
      centered
      zIndex={1201}
    >
      <div className={styles.countryChangeModalContent}>
        <div className={styles.countryChangeModalHeader}>
          <Icon type="info-circle" style={{ color: '#E8A317', marginTop: '6px' }} />
          <FormattedMessage {...message.selectingDifferentCountryLabel} />
        </div>
        <div className={styles.countryChangeModalFooter}>
          <Button onClick={handleCancel} shape="round" size="large">
          <FormattedMessage {...message.cancelLabel} />
          </Button>
          <Button onClick={handleOk} type="primary" shape="round" size="large">
          <FormattedMessage {...message.okLabel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CountryChangeModal;
