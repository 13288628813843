import React from 'react';
import { Empty } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CandidatesFindingIcon } from '../../Icons/AryaIcons';
import { getMarketIntelLink } from '../../Utils/LinkUtils/LinkUtils';
import styles from './FindingCandidates.module.scss';
import message from '../Placeholders/PlaceholdersMessages';

function FindingCandidates({ isFreeTrialPlan, jobId }) {
  const marketIntelLink = getMarketIntelLink(jobId);
  return (
    <>
      {isFreeTrialPlan ? (
        <div className={styles.freeTrialFindingCandidates}>
          <p className={styles.candidateListFreeTrialPlanNoCandidatesFound}>
          <FormattedMessage {...message.stayWithUsLabel}/>
            <br />
            <FormattedMessage {...message.emailNotificationLabel}/>
          </p>
          <img
            src={`${process.env.PUBLIC_URL}/static/Images/findingcandidate.gif`}
            alt="finding candidate gif"
            className={styles.candidateListFreeTrialFindingCandidateGif}
          />
          <div className={styles.candidateListFreeTrialPlanNoCandidatesFoundMessage}>
          <FormattedMessage {...message.AsYouHangAroundLabel}/>{' '}
            <Link to={marketIntelLink}>
              <span className={styles.candidateListFreeTrialText}><FormattedMessage {...message.marketIntelligenceLabel}/></span>{' '}
            </Link>
            <FormattedMessage {...message.empowerYourCandidateSearchLabel}/>
          </div>
        </div>
      ) : (
        <Empty
          imageStyle={{ marginTop: '80px', height: '266px' }}
          image={<CandidatesFindingIcon />}
          description={
            <>
              <p className={styles.candidateListNoCandidatesFound}><FormattedMessage {...message.aryaIsWorkingOnSomeGreatCandidatesLabel}/></p>
              <p className={styles.candidateListNoCandidatesFoundMessage}><FormattedMessage {...message.candidatesToReviewWillAppearHere}/></p>
            </>
          }
        />
      )}
    </>
  );
}

export default FindingCandidates;
