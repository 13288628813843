import deDE from 'antd/lib/locale-provider/de_DE';
import enUS from 'antd/lib/locale-provider/en_US';
import esES from 'antd/lib/locale-provider/es_ES';
import frFR from 'antd/lib/locale-provider/fr_FR';
import itIT from 'antd/lib/locale-provider/it_IT';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';
import it from 'react-intl/locale-data/it';
import zh from 'react-intl/locale-data/zh';
import deDEMessages from './Locales/de_DE.json';
import enUSMessages from './Locales/en_US.json';
import esESMessages from './Locales/es_ES.json';
import frFRMessages from './Locales/fr_FR.json';
import itITMessages from './Locales/it_IT.json';
import zhCNMessages from './Locales/zh_CN.json';
import { manualSearchMessagesEn, manualSearchMessagesEs } from './Containers/ManualSearchV2/ManualSearchMessages';
import { candidateDrawerMessagesEn, candidateDrawerMessagesEs } from './Containers/CandidateDrawer/messages';
import { candidateCardMessagesEn, candidateCardMessagesEs } from './Components/CandidateCard/CandidateCardMessages';
import {
  candidateFiltersMessagesEn,
  candidateFiltersMessagesEs,
} from './Containers/CandidateFilters/CandidateFiltersMessage';
import {
  candidateBulkActionsContainerMessageEn,
  candidateBulkActionsContainerMessageEs,
} from './Containers/CandidateBulkActionsContainer/CandidateBulkActionsContainerMessage';
import { contactPullerMessageEn, contactPullerMessageEs } from './Components/ContactPuller/ContactPullerMessage';
import {
  candidateSortingFilterMessageEn,
  candidateSortingFilterMessageEs,
} from './Components/CandidateSortingFilter/CandidateSortingFilterMessage';
import { emailComposerMessageEn, emailComposerMessageEs } from './Components/EmailComposer/EmailComposerMessage';
import { jobMessagesEn, jobMessagesEs } from './Components/JobForm/JobMessages';
import { chatbotMessagesEn, chatbotMessagesEs } from './Components/Connect/BotConfig/ChatbotMessages';
import { candidateApiErrorMessagesEn, candidateApiErrorMessagesEs } from './Utils/CandidateApiErrorMessagesUtils';
import {
  messageComposerMessageEn,
  messageComposerMessageEs,
} from './Components/MessageComposer/MessageComposerMessage';
import { callSettingsMessageEn, callSettingsMessageEs } from './Components/CallSettings/CallSettingsMessage';
import {
  contactSettingsMessageEn,
  contactSettingsMessageEs,
} from './Components/ContactSettings/ContactSettingsMessage';
import { emailSettingsMessageEn, emailSettingsMessageEs } from './Components/EmailSettings/EmailSettingsMessage';
import {
  applicationSettingMessagesEn,
  applicationSettingMessagesEs,
} from './Components/ApplicationSettings/ApplicationSettingMessages';
import {
  connectTemplatesMessagesEn,
  connectTemplatesMessagesEs,
} from './Containers/BaseTemplates/ConnectTemplatesMessages';
import { connectCardMessagesEn, connectCardMessagesEs } from './Components/ConnectCard/ConnectCardMessages';
import {
  profileSettingMessagesEn,
  profileSettingMessagesEs,
} from './Components/ProfileSettings/ProfileSettingMessages';
import { listMessagesEn, listMessagesEs } from './Containers/Lists/ListsMessages';
import { reportPortalMessagesEn, reportPortalMessagesEs } from './Components/ReportPortal/ReportPortalMessages';
import { placeholderMessagesEn, placeholderMessagesEs } from './Components/Placeholders/PlaceholdersMessages';
import { connectMessagesEn, connectMessagesEs } from './Containers/Connect/ConnectMessages';

const langConfig = {
  de: {
    messages: {
      ...deDEMessages,
    },
    antd: deDE,
    locale: 'de-DE',
    data: de,
  },
  en: {
    messages: {
      ...enUSMessages,
      ...manualSearchMessagesEn,
      ...candidateDrawerMessagesEn,
      ...candidateCardMessagesEn,
      ...candidateFiltersMessagesEn,
      ...candidateBulkActionsContainerMessageEn,
      ...contactPullerMessageEn,
      ...candidateSortingFilterMessageEn,
      ...emailComposerMessageEn,
      ...jobMessagesEn,
      ...chatbotMessagesEn,
      ...candidateApiErrorMessagesEn,
      ...applicationSettingMessagesEn,
      ...messageComposerMessageEn,
      ...callSettingsMessageEn,
      ...contactSettingsMessageEn,
      ...emailSettingsMessageEn,
      ...connectTemplatesMessagesEn,
      ...connectCardMessagesEn,
      ...profileSettingMessagesEn,
      ...listMessagesEn,
      ...reportPortalMessagesEn,
      ...placeholderMessagesEn,
      ...connectMessagesEn,
    },
    antd: enUS,
    locale: 'en-US',
    data: en,
  },
  es: {
    messages: {
      ...esESMessages,
      ...manualSearchMessagesEs,
      ...candidateDrawerMessagesEs,
      ...candidateCardMessagesEs,
      ...candidateFiltersMessagesEs,
      ...candidateBulkActionsContainerMessageEs,
      ...contactPullerMessageEs,
      ...candidateSortingFilterMessageEs,
      ...emailComposerMessageEs,
      ...jobMessagesEs,
      ...chatbotMessagesEs,
      ...candidateApiErrorMessagesEs,
      ...applicationSettingMessagesEs,
      ...messageComposerMessageEs,
      ...callSettingsMessageEs,
      ...contactSettingsMessageEs,
      ...emailSettingsMessageEs,
      ...connectTemplatesMessagesEs,
      ...connectCardMessagesEs,
      ...profileSettingMessagesEs,
      ...listMessagesEs,
      ...reportPortalMessagesEs,
      ...placeholderMessagesEs,
      ...connectMessagesEs,
    },
    antd: esES,
    locale: 'es-ES',
    data: es,
  },
  fr: {
    messages: {
      ...frFRMessages,
    },
    antd: frFR,
    locale: 'fr-FR',
    data: fr,
  },
  it: {
    messages: {
      ...itITMessages,
    },
    antd: itIT,
    locale: 'it-IT',
    data: it,
  },
  zh: {
    messages: {
      ...zhCNMessages,
    },
    antd: zhCN,
    locale: 'zh-CN',
    data: zh,
  },
};

export default langConfig;
