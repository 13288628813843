import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Pagination, Skeleton, Input } from 'antd';
import ReportQueriesList from './ReportQueriesList/ReportQueriesList';
import styles from './ReportQueriesListWrapper.module.scss';
import EmptyReportList from '../../ReportView/ReportDetails/ReportListWrapper/EmptyReportList/EmptyReportList';
import ReportViewHeader from '../../ReportView/ReportViewHeader/ReportViewHeader';
import DeleteReportQueryModal from '../../DeleteReportQueryModal/DeleteReportQueryModal';
import ShareSavedReportContainer from '../../../../../Containers/ReportPortalContainer/ShareSavedReportContainer/ShareSavedReportContainer';
import { getFromCount } from '../../../../../Utils/PaginationUtils';
import reportPortalMessages from '../../../ReportPortalMessages';

const { Search } = Input;

function ReportQueriesListWrapper(props) {
  const {
    pageSize,
    pageNumber,
    setSavedQueriesPageSize,
    setSavedQueriesPageNumber,
    fetchSavedReportQueries,
    fetchSavedQueriesApiStatus,
    loadReportQuery,
    editSavedQuery,
    deleteSavedQuery,
    deleteSavedQueryApiStatus,
    savedReportQueries,
    clearDeleteSavedQueryApiStatus,
    fetchUsersForShare,
    shareReportSavedQuery,
  } = props;

  const [deleteQueryModalVisibility, setDeleteQueryModalVisibility] = React.useState(false);
  const [shareQueryModalVisibility, setShareQueryModalVisibility] = React.useState(false);
  const [queryDetails, setQueryDetails] = React.useState();
  const [searchTerm, setSearchTerm] = React.useState('');

  const totalCount = savedReportQueries?.Total ?? 0;
  const savedReportQueriesList = savedReportQueries?.SavedReports;

  const hideDeleteQueryModal = () => {
    setDeleteQueryModalVisibility(false);
  };

  const hideShareQueryModal = () => {
    setShareQueryModalVisibility(false);
  };

  const showDeleteQueryModal = query => {
    setQueryDetails(query);
    setDeleteQueryModalVisibility(true);
  };

  const showShareQueryModal = query => {
    setQueryDetails(query);
    fetchUsersForShare({ page: 1, isActive: true }); // to fetch first 10 users
    setShareQueryModalVisibility(true);
  };

  const onDeleteQuerySuccess = () => {
    setDeleteQueryModalVisibility(false);
    let updatedPageNumber = pageNumber;
    if (pageNumber > 1 && totalCount - 1 <= getFromCount(pageNumber, pageSize)) {
      updatedPageNumber = pageNumber - 1;
      setSavedQueriesPageNumber(updatedPageNumber);
    }
    fetchSavedReportQueries({ from: getFromCount(updatedPageNumber, pageSize), size: pageSize, searchTerm });
    clearDeleteSavedQueryApiStatus();
  };

  const onShareSavedQuery = ({ shareWith, unshareWith, isShareWithAll, isUnshareWithAll }) => {
    shareReportSavedQuery({
      shareWith,
      unshareWith,
      isShareWithAll,
      isUnshareWithAll,
      reportId: queryDetails.SavedReportId,
    });
    setShareQueryModalVisibility(false);
  };

  React.useEffect(() => {
    if (deleteSavedQueryApiStatus?.status?.toUpperCase() === 'COMPLETED') onDeleteQuerySuccess();
  }, [deleteSavedQueryApiStatus]);

  const onPageChange = async page => {
    await setSavedQueriesPageNumber(page);
    fetchSavedReportQueries({ from: (page - 1) * pageSize, size: pageSize, searchTerm });
  };

  const onShowSizeChange = async (current, size) => {
    await setSavedQueriesPageNumber(1);
    await setSavedQueriesPageSize(size);
    fetchSavedReportQueries({ from: 0, size });
  };

  const onSearch = searchKey => {
    setSearchTerm(searchKey);
    setSavedQueriesPageNumber(1);
    fetchSavedReportQueries({ from: 0, size: pageSize, searchTerm: searchKey });
  };

  const onSearchChange = e => {
    const searchKey = e?.target?.value;
    setSearchTerm(searchKey);
    if (!searchKey) {
      setSavedQueriesPageNumber(1);
      fetchSavedReportQueries({ from: 0, size: pageSize, searchTerm: searchKey });
    }
  };

  const getSavedReportQueriesList = () => {
    if (fetchSavedQueriesApiStatus?.status?.toUpperCase() === 'COMPLETED' && savedReportQueriesList?.length === 0) {
      return <EmptyReportList title="No data found!" />;
    }

    if (fetchSavedQueriesApiStatus?.status?.toUpperCase() === 'FAILED') {
      return <EmptyReportList title="Oops, something just went wrong!" subtitle="Please try again." />;
    }

    return (
      <div>
        <div className={styles.reportQueriesListHeader}>
          <div className={styles.queryNameHeader}>
            <FormattedMessage {...reportPortalMessages.reportQueriesListWrapperName} />
          </div>
          <div className={styles.queryTypeHeader}>
            <FormattedMessage {...reportPortalMessages.reportQueriesListWrapperType} />
          </div>
          <div className={styles.listItemActions} />
        </div>
        <ReportQueriesList
          onRunSavedQuery={loadReportQuery}
          onEditSavedQuery={editSavedQuery}
          onDeleteSavedQuery={showDeleteQueryModal}
          onShareSavedQuery={showShareQueryModal}
          reportQueriesList={savedReportQueries?.SavedReports}
          deleteSavedQueryApiStatus={deleteSavedQueryApiStatus}
        />
      </div>
    );
  };

  return (
    <div className={styles.savedReportQueries}>
      <div className={styles.savedReportQueriesHeader}>
        <ReportViewHeader title="Saved Report Queries" />
        <div className={styles.reportQueriesListFilter}>
          <Search
            className={styles.reportQueriesListSearch}
            value={searchTerm}
            onSearch={onSearch}
            onChange={onSearchChange}
            placeholder="Search"
            allowClear
          />
        </div>
      </div>
      <Skeleton
        active
        paragraph={{ rows: 16 }}
        loading={!['COMPLETED', 'FAILED'].includes(fetchSavedQueriesApiStatus?.status?.toUpperCase())}
      >
        {getSavedReportQueriesList()}
        <div className={styles.savedReportQueriesPagination}>
          <Pagination
            showSizeChanger
            size="small"
            pageSize={pageSize}
            total={totalCount}
            onChange={onPageChange}
            onShowSizeChange={onShowSizeChange}
            hideOnSinglePage={totalCount <= 10}
            current={pageNumber}
            disabled={!['COMPLETED', 'FAILED'].includes(fetchSavedQueriesApiStatus?.status?.toUpperCase())}
          />
        </div>
      </Skeleton>
      <DeleteReportQueryModal
        queryDetails={queryDetails}
        isVisible={deleteQueryModalVisibility}
        onCancel={hideDeleteQueryModal}
        onDelete={deleteSavedQuery}
        deleteQueryApiStatus={deleteSavedQueryApiStatus}
      />
      {shareQueryModalVisibility ? (
        <ShareSavedReportContainer
          isVisible={shareQueryModalVisibility}
          onClose={hideShareQueryModal}
          queryDetails={queryDetails}
          onShare={onShareSavedQuery}
          ownerId={queryDetails.CreatedBy}
          sharedUserIds={queryDetails.SharedWith}
          unSharedUserIds={queryDetails.UnsharedWith}
          isPublic={queryDetails.SharedWithAll || false}
        />
      ) : null}
    </div>
  );
}

ReportQueriesListWrapper.defaultProps = {
  pageSize: 10,
};

export default ReportQueriesListWrapper;
