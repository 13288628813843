import { defineMessages } from 'react-intl';
import manualSearchMessagesJsonEn from '../../Data/En_Us/ManualSearchMessagesEn_Us.json';
import manualSearchMessagesJsonEs from '../../Data/Es_Es/ManualSearchMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'ManualSearch';
const transformedMessage = formatMessagesWithComponentPrefix(manualSearchMessagesJsonEn, component);
const manualSearchMessages = defineMessages(transformedMessage);

export const manualSearchMessagesEn = formatJsonWithComponentPrefix(manualSearchMessagesJsonEn, component);
export const manualSearchMessagesEs = formatJsonWithComponentPrefix(manualSearchMessagesJsonEs, component);
export default manualSearchMessages;
