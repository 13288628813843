import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { hasMatchingKeywords } from '../KeywordHighlightUtil';
import HtmlViewer from '../../Components/HtmlViewer/HtmlViewer';
import message from '../../Containers/CandidateDrawer/messages';

import './TextPreview.scss';

class TextPreview extends React.Component {
  constructor(props) {
    super(props); // partialClassName
    this.state = {
      textContentVisibility: false,
      textClassName: props.partialClassName ?? 'text-preview-partial',
      height: 0,
      seeMoreButton: props.shouldSetSeeMoreButton,
    };
    this.toggleTextContentVisibility = this.toggleTextContentVisibility.bind(this);
    this.workHistoryTextPreviewRef = React.createRef();
  }

  toggleTextContentVisibility() {
    const { textContentVisibility } = this.state;
    const { partialClassName } = this.props;
    this.setState({
      textClassName: textContentVisibility ? partialClassName ?? 'text-preview-partial' : 'text-preview-total',
      textContentVisibility: !textContentVisibility,
    });
  }

  componentDidMount() {
    const { shouldSetSeeMoreButton } = this.props;
    const height = this.divElement?.clientHeight;
    if (this.workHistoryTextPreviewRef.current?.clientHeight && shouldSetSeeMoreButton) {
      const shouldShowButton =
        this.workHistoryTextPreviewRef.current?.scrollHeight >= 2 * this.workHistoryTextPreviewRef.current.clientHeight;
      this.setState({
        seeMoreButton: shouldShowButton,
      });
    }
    this.setState({ height });
  }

  componentDidUpdate(prevProps) {
    const { keywords, partialClassName } = this.props;
    if ((prevProps.keywords?.length || 0) !== (keywords?.length || 0)) {
      const currentHtml = this.workHistoryTextPreviewRef.current?.innerHTML;
      if (this.workHistoryTextPreviewRef.current?.clientHeight && this.state.seeMoreButton) {
        const textContentVisibility = !hasMatchingKeywords(currentHtml, keywords);
        this.setState({
          textClassName: textContentVisibility ? partialClassName ?? 'text-preview-partial' : 'text-preview-total',
          textContentVisibility: !textContentVisibility,
        });
      }
    }
  }

  render() {
    const { text, showSeeMoreButton, context, shouldPreserveStyle } = this.props;
    const { textContentVisibility, textClassName, height, seeMoreButton } = this.state;

    const htmlPurificationOptions = shouldPreserveStyle
      ? []
      : [{ FORBID_ATTR: ['style'], FORBID_TAGS: ['button', 'a'], KEEP_CONTENT: false }];

    return text ? (
      <div className="text-preview-wrapper">
        <div
          className="text-preview-content-wrapper"
          ref={divElement => {
            this.divElement = divElement;
          }}
        >
          <HtmlViewer
            className={`${showSeeMoreButton || seeMoreButton ? textClassName : 'text-preview-total'}`}
            htmlContent={text && text.replace(/\n/gi, '<br />')}
            htmlPurificationOptions={htmlPurificationOptions}
            textPreviewRef={this.workHistoryTextPreviewRef}
          />
        </div>
        {((showSeeMoreButton || seeMoreButton) && context !== 'notes') || (context === 'notes' && height >= 70) ? (
          <div className="text-preview-button-wrapper">
            <Button type="link" onClick={this.toggleTextContentVisibility}>
              {textContentVisibility ? (
                <FormattedMessage {...message.showLessOption} />
              ) : (
                <FormattedMessage {...message.showMoreOption} />
              )}
            </Button>
          </div>
        ) : null}
      </div>
    ) : null;
  }
}

export default TextPreview;
