import React from 'react';
import { Popover, Badge } from 'antd';
import _ from 'lodash';
import { SystemIcon } from '../../Icons/AryaIcons';
import AryaNotifications from '../../Components/AryaNotifications/AryaNotifications';
import './Navbar.scss';

const BellNotification = props => {
  const {
    visiblePopover,
    aryaNotificationsTotalCount,
    aryaNotifications,
    fetchAryaNotifications,
    onClickSystemNotificationItem,
    userByGuId,
    userConfig,
    whiteLabelInfo,
    setJobCandidateTabActiveSourceName,
    setJobCandidateActiveTab,
    popoverParams,
    handlePopoversVisibility,
    aryaNotificationsUnviewedCount,
    showAryaNotification,
    currentPageNotificationCount,
    source,
    notificationTypes,
    onATSSalesCallback,
    hidePopover,
  } = props;
  const title =
    source === 'LeoATS' ? (
      <span className="system-notifcation-popup-title-ats">Notifications</span>
    ) : (
      <h3 className="system-notifcation-popup-title">System Notifications</h3>
    );
  const popoverContainerStyle = {
    display: 'flex',
    height: 50,
  };
  return (
    <div style={source === 'LeoATS' ? popoverContainerStyle : {}}>
      <Popover
        title={title}
        visible={visiblePopover === 'SystemNotification'}
        placement="bottomLeft"
        content={
          aryaNotificationsTotalCount ? (
            <AryaNotifications
              aryaNotifications={aryaNotifications}
              aryaNotificationsTotalCount={aryaNotificationsTotalCount}
              fetchAryaNotifications={fetchAryaNotifications}
              onClick={onClickSystemNotificationItem}
              userByGuId={userByGuId}
              onATSSalesCallback={onATSSalesCallback}
              aryaVersion={_.get(userConfig, 'SubscriptionType')}
              whiteLabelInfo={whiteLabelInfo}
              setJobCandidateTabActiveSourceName={setJobCandidateTabActiveSourceName}
              setJobCandidateActiveTab={setJobCandidateActiveTab}
              source={source}
              hidePopover={hidePopover}
              notificationTypes={notificationTypes}
              currentPageNotificationCount={currentPageNotificationCount}
            />
          ) : (
            <EmptySystemNotification />
          )
        }
        {...popoverParams}
        onVisibleChange={visible => handlePopoversVisibility(visible, 'SystemNotification')}
      >
        <div style={source === 'LeoATS' ? { padding: 10 } : {}}>
          <span className="navbar-menu-item-icon">
            <span className="badged-icon">
              <Badge count={aryaNotificationsUnviewedCount} className="arya">
                <SystemIcon
                  // onClick={source === 'LeoATS' ? handleClickATs : () => {}}
                  style={{ cursor: 'pointer' }}
                  className={`arya-notification-icon ${showAryaNotification ? 'wiggle' : ''}`}
                />
              </Badge>
            </span>
          </span>
        </div>
      </Popover>
    </div>
  );
};

export const EmptySystemNotification = () => (
  <div className="system-notification-empty">
    <div className="system-notification-empty-icon-wrapper">
      <SystemIcon className="arya-notification-icon" />
    </div>
    <div className="system-notification-empty-title">No system notification found</div>
    <div className="system-notification-empty-description">All system related notifications will appear here</div>
  </div>
);

export default BellNotification;
