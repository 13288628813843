import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './CandidateAdvanceFilters.module.scss';
import CandidateAdvanceFilterGroup from './CandidateAdvanceFilterGroup';
import message from '../../../ManualSearchV2/ManualSearchMessages';

function CandidateAdvanceFilterGroupHeader(props) {
  const {
    candidateAggs,
    filterFields,
    handleCandidateAdvanceFilters,
    isClearButtonClicked,
    updateApplyButtonState,
    shortlistedCount,
  } = props;
  const [Include, setInclude] = React.useState([]);
  const [Exclude, setExclude] = React.useState([]);

  React.useEffect(() => {
    const filteredData1 = Include?.filter(item => item?.FilterItems?.length > 0);
    const filteredData2 = Exclude?.filter(item => item?.FilterItems?.length > 0);
    const mainarray = [...filteredData1, ...filteredData2];
    handleCandidateAdvanceFilters(mainarray);
  }, [Include, Exclude, handleCandidateAdvanceFilters]);

  return (
    <div className={styles.candidateFilterWrapper}>
      <div className={styles.filterGroupHeader}>
        <div className={styles.filterGroupHeaderLabel}><FormattedMessage {...message.includeLabel} />:</div>
        <CandidateAdvanceFilterGroup
          candidateAggs={candidateAggs}
          filterFields={filterFields}
          setObject={setInclude}
          isClearButtonClicked={isClearButtonClicked}
          updateApplyButtonState={updateApplyButtonState}
          shortlistedCount={shortlistedCount}
          component="include"
        />
      </div>
      <div className={styles.filterGroupHeaderBorder}></div>
      <div className={styles.filterGroupHeader}>
        <div className={styles.filterGroupHeaderLabel}><FormattedMessage {...message.ExcludeLabel} />:</div>
        <CandidateAdvanceFilterGroup
          candidateAggs={candidateAggs}
          filterFields={filterFields}
          isClearButtonClicked={isClearButtonClicked}
          setObject={setExclude}
          updateApplyButtonState={updateApplyButtonState}
          shortlistedCount={shortlistedCount}
          component="exclude"
        />
      </div>
    </div>
  );
}

export default CandidateAdvanceFilterGroupHeader;
