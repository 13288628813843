import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Pagination } from 'antd';
import styles from './ReportDetails.module.scss';
import EmptyReportList from './ReportListWrapper/EmptyReportList/EmptyReportList';
import ReportListWrapper from './ReportListWrapper/ReportListWrapper';
import reportPortalMessages from '../../../ReportPortalMessages';

export default function ReportDetails(props) {
  const {
    reportStats,
    pageSize,
    pageNumber,
    setReportListPageSize,
    setReportListPageNumber,
    getReportStats,
    fetchReportStatsApiStatus,
    title,
    reportColumns,
    selectedReportColumns,
    defaultReportColumnsByAryaName,
    fetchReportColumnsApiStatus,
    hidePagination,
  } = props;

  const onPageChange = async page => {
    await setReportListPageNumber(page);
    getReportStats(page, pageSize);
  };

  const onShowSizeChange = async (current, size) => {
    await setReportListPageNumber(1);
    await setReportListPageSize(size);
    getReportStats(1, size);
  };

  const totalCount = _.get(reportStats, 'Total', 0);
  const reportList = _.get(reportStats, 'Rows', []);

  const getReportDetails = () => {
    if (fetchReportStatsApiStatus?.status?.toUpperCase() === 'COMPLETED' && reportList?.length === 0) {
      return (
        <EmptyReportList
          title={<FormattedMessage {...reportPortalMessages.emptyReportApiStatusListTitle} />}
          subtitle={<FormattedMessage {...reportPortalMessages.emptyReportApiStatusListSubTitle} />}
        />
      );
    }

    if (fetchReportColumnsApiStatus?.status?.toUpperCase() === 'FAILED') {
      return (
        <EmptyReportList
          title={<FormattedMessage {...reportPortalMessages.emptyReportColumnApiStatusListTitle} />}
          subtitle={<FormattedMessage {...reportPortalMessages.emptyReportColumnApiStatusListSubTitle} />}
        />
      );
    }

    if (fetchReportStatsApiStatus?.status?.toUpperCase() === 'FAILED') {
      return (
        <EmptyReportList
          title={<FormattedMessage {...reportPortalMessages.failedReportApiStatusTitle} />}
          subtitle={<FormattedMessage {...reportPortalMessages.failedReportApiStatusSubTitle} />}
        />
      );
    }

    return (
      <ReportListWrapper
        reportList={reportList}
        title={title}
        fetchReportStatsApiStatus={fetchReportStatsApiStatus}
        reportColumns={reportColumns}
        defaultReportColumnsByAryaName={defaultReportColumnsByAryaName}
        selectedReportColumns={selectedReportColumns}
      />
    );
  };

  return (
    <div className={styles.reportDetails}>
      {getReportDetails()}
      {!hidePagination && (
        <div className={styles.reportDetailsPagination}>
          <Pagination
            showSizeChanger
            size="small"
            pageSize={pageSize}
            total={totalCount}
            onChange={onPageChange}
            onShowSizeChange={onShowSizeChange}
            hideOnSinglePage={totalCount <= 10}
            current={pageNumber}
            disabled={
              fetchReportStatsApiStatus?.status?.toUpperCase() !== 'COMPLETED' &&
              fetchReportStatsApiStatus?.status?.toUpperCase() !== 'FAILED'
            }
          />
        </div>
      )}
    </div>
  );
}

ReportDetails.defaultProps = {
  pageSize: 10,
  hidePagination: false,
};
