import { defineMessages } from 'react-intl';
import chatbotMessagesJsonEn from '../../../Data/En_Us/ChatbotMessagesEn_Us.json';
import chatbotMessagesJsonEs from '../../../Data/Es_Es/ChatbotMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../../Utils/LanguageUtils';

const component = 'Chatbot';
const chatbotMessages = formatMessagesWithComponentPrefix(chatbotMessagesJsonEn, component);

const chatbotMessagesEn = formatJsonWithComponentPrefix(chatbotMessagesJsonEn, component);
const chatbotMessagesEs = formatJsonWithComponentPrefix(chatbotMessagesJsonEs, component);

const messages = defineMessages(chatbotMessages);

export default messages;
export { chatbotMessagesEn, chatbotMessagesEs };
