import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getEventNameByReportType } from '../../../../../../Analytics/Report/ReportEventUtils';
import reportPortalMessages from '../../../../ReportPortalMessages';

export default function ViewReportButton(props) {
  const { onClick, disabled, loading, type } = props;
  return (
    <Button
      type="primary"
      shape="round"
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      sk-event-name={getEventNameByReportType(type, 'view')}
    >
      <FormattedMessage {...reportPortalMessages.viewReportButton} />
    </Button>
  );
}
