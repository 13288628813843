import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import Config from '../../../Config/Config';
import styles from './ConnectV2Redirect.module.scss';
import message from '../../JobForm/JobMessages';

function ConnectV2Redirect(props) {
  const { isVisible } = props;
  if (!isVisible) return null;
  return (
    <div className={styles.connectV2Redirect}>
      <div className={styles.connectDescription}><FormattedMessage {...message.checkOutLatestConnectLabel}/></div>
      <Button shape="round" type="primary" href={Config.urls.aryaConnectV2Client} target="_blank">
      <FormattedMessage {...message.launchLabel}/>
      </Button>
    </div>
  );
}

export default ConnectV2Redirect;
