/* eslint-disable no-param-reassign */
/* eslint-disable */
import _ from 'lodash';
import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import queryString from 'query-string';
import { getFilteredText } from './TextTransformationUtil';
import eventTypes from '../Analytics/EventTypes';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import store from '../store';
import XIcon from '../Icons/XIcon';

const socialMediaList = ['facebook', 'twitter', 'linkedin'];

const SEND_TO = {
  All: ['shortlisted', 'sourced'],
  Shortlisted: ['shortlisted'],
  allIdentifier: 'all',
  shortlistedIdentifier: 'shortlisted',
  userGivenIdentifier: 'usergiven',
};
export const FILTER_BADGE = 'FILTER_BADGE';

export const isWorkflowConfigurationUpdated = ({ AutomationConfiguration, selectedAutomationConfiguration }) => {
  const { definitionId, selectedEmailTemplateSendTo, selectedEmailTemplateFrom, isEmailTemplatePanelEnabled } =
    selectedAutomationConfiguration;
  const workflowAutomationConfiguration = AutomationConfiguration?.Workflows?.[0];

  const currentTemplateId = _.get(workflowAutomationConfiguration, 'WorkflowDefinitionId', undefined);
  let currentTemplateSendTo = _.get(workflowAutomationConfiguration, 'SendTo', SEND_TO.Shortlisted).map(sendTo =>
    sendTo.toLowerCase()
  );
  currentTemplateSendTo = _.isEqual(currentTemplateSendTo.sort(), SEND_TO.Shortlisted)
    ? SEND_TO.shortlistedIdentifier
    : SEND_TO.allIdentifier;
  const sendToUserRecommended = _.get(workflowAutomationConfiguration, 'SendToUserRecommended');
  if (sendToUserRecommended) {
    currentTemplateSendTo = SEND_TO.userGivenIdentifier;
  }
  const currentTemplateFrom = _.get(workflowAutomationConfiguration, 'EmailActivityFromAddress', undefined);
  const currentEmailIsEnabled = _.get(workflowAutomationConfiguration, 'IsEnabled', false);
  let isUpdated = false;
  isUpdated =
    currentEmailIsEnabled !== isEmailTemplatePanelEnabled ||
    currentTemplateId !== definitionId ||
    !_.isEqual(selectedEmailTemplateSendTo, currentTemplateSendTo) ||
    currentTemplateFrom !== selectedEmailTemplateFrom;
  return isUpdated;
};

export const getJobUrlsDisplayContent = (urls, features) => {
  const { aryaJobViewUrl, viewUrl, aryaJobApplyUrl } = urls;
  const { isCustomizeJobUrlEnabled, isAryaJobPostingUrlEnabled } = features;
  if (!isCustomizeJobUrlEnabled && !isAryaJobPostingUrlEnabled) return null;
  const displayContent = { label: 'Careerpage URL', url: viewUrl };
  if (isCustomizeJobUrlEnabled) {
    displayContent.label = 'External Job Posting URL';
  }
  if (isAryaJobPostingUrlEnabled) {
    displayContent.url = isCustomizeJobUrlEnabled
      ? aryaJobViewUrl
      : aryaJobApplyUrl?.replace('&cId={arya_candidate_id}', '');
  }
  return displayContent;
};

export const recommendedJobDescription = 'recommendedJobDescription';
export const SAVE = 'save';

const getJobByJobGuid = (jobById, jobGuid) => {
  const filteredjobId = Object.keys(jobById).filter(job => jobById[job].JobGuid === jobGuid);
  return _.get(jobById, [filteredjobId]);
};

const getIsJobActivationLimitExceeded = (userConfig = {}) => {
  const {
    OrgActivatedJobsCount: orgActivatedJobsCount,
    OrgOpenedJobsCount: orgOpenedJobsCount,
    IsJobActivationChargeEnabledOnJobOpen: isJobActivationChargeEnabledOnJobOpen,
    JobLimit: jobLimit,
  } = userConfig;
  const orgActivatedOrOpenedJobsCount = isJobActivationChargeEnabledOnJobOpen
    ? orgOpenedJobsCount
    : orgActivatedJobsCount;
  return (
    userConfig && (!jobLimit || orgActivatedOrOpenedJobsCount === undefined || jobLimit > orgActivatedOrOpenedJobsCount)
  );
};

const getIsJobActivationAllowed = (userConfig = {}, hadJobActivated, hadJobOpened) => {
  if (userConfig.IsJobActivationChargeEnabledOnJobOpen)
    return hadJobActivated || hadJobOpened || getIsJobActivationLimitExceeded(userConfig);
  return hadJobActivated || getIsJobActivationLimitExceeded(userConfig);
};

const getClickedSources = data => {
  const clickedSources = [];
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) && data[key].isClicked === true) {
      clickedSources.push(data[key].Source);
    }
  }
  return clickedSources;
};

function updateIsClicked(inputObject, apiSources) {
  const updatedInputObject = { ...inputObject };
  for (const key in updatedInputObject) {
    if (Object.prototype.hasOwnProperty.call(updatedInputObject, key)) {
      const matchingSource = apiSources.find(apiSource => {
        if (apiSource.Portal) return apiSource.Portal === updatedInputObject[key].Source.Portal;
        return apiSource.Group === updatedInputObject[key].Source.Group;
      });
      updatedInputObject[key].isClicked = !!matchingSource;
    }
  }
  return updatedInputObject;
}

const getJobLimitExceededAlertMessage = (
  jobLimit,
  showMailLink = true,
  isJobActivationChargeEnabledOnJobOpen,
  appName
) => {
  const jobCreationAlertMessage = (
    <span>
      This account has reached the maximum limit of adding/submitting jobs in &apos;Open&apos; status. If you want to
      add/submit more &apos;Open&apos; jobs in {appName}, please contact{' '}
      {showMailLink ? <a href="mailto:support@leoforce.com">support@leoforce.com</a> : 'support@leoforce.com'}
    </span>
  );
  const jobActivationAlertMessage = (
    <span>
      You have reached the limit of {jobLimit} job activations. To be able to activate more jobs and see candidates,
      please contact{' '}
      {showMailLink ? <a href="mailto:support@leoforce.com">sales@leoforce.com</a> : 'sales@leoforce.com'} to upgrade
      your plan.
    </span>
  );
  return isJobActivationChargeEnabledOnJobOpen ? jobCreationAlertMessage : jobActivationAlertMessage;
};

const getUserGuids = (assignedTo = [], usersById) => {
  return assignedTo.map(id => {
    const user = usersById[id];
    return user ? user?.UserGuid : id;
  });
};

const getTryNowPayload = (jobDetails, skills) => {
  const synonyms = jobDetails?.Synonyms || [];
  return {
    JobTitle: jobDetails?.JobTitle,
    Location: jobDetails?.Location,
    Industries: jobDetails?.Industries,
    CountryCode: jobDetails?.CountryCode || 'US',
    Skills: skills,
    Synonyms: synonyms.filter(el => el && el !== jobDetails?.JobTitle),
    AutoExpand: jobDetails?.AutoExpand,
  };
};

const getTryNowCandidatesPayload = (jobDetails, skills) => {
  const synonyms = jobDetails?.Synonyms || [];
  const titles = synonyms.filter(el => el && el !== jobDetails?.JobTitle);
  titles.push(jobDetails?.JobTitle);
  return {
    Titles: titles,
    Skills: skills,
    Industries: jobDetails?.Industries,
    MinExperience: jobDetails?.MinExperience,
    MaxExperience: jobDetails?.MaxExperience,
    Location: jobDetails?.AutoExpand
      ? {
          CountryCode: jobDetails?.CountryCode || 'US',
          Region: jobDetails?.CountryCode || 'US',
          AutoExpand: true,
        }
      : {
          CountryCode: jobDetails?.CountryCode || 'US',
          Region: jobDetails?.CountryCode || 'US',
          Location: jobDetails?.Location,
          DistanceUnit: 'Miles',
          Distance: jobDetails?.Range ? jobDetails?.Range + 20 : 100,
        },
  };
};

const getSocialMediaButton = (portal, job) => {
  let jobViewUrl = job.ViewUrl;
  if (jobViewUrl) jobViewUrl = queryString.stringifyUrl({ url: jobViewUrl, query: { source: portal } });
  switch (portal.toLowerCase()) {
    case 'facebook':
      return (
        <FacebookShareButton url={jobViewUrl} sk-event-name={eventTypes.job.shareJobSocialMedia.facebook}>
          <FacebookIcon sk-event-name={eventTypes.job.shareJobSocialMedia.facebook} />
        </FacebookShareButton>
      );
    case 'twitter':
      return (
        <TwitterShareButton url={jobViewUrl} sk-event-name={eventTypes.job.shareJobSocialMedia.twitter}>
          <XIcon sk-event-name={eventTypes.job.shareJobSocialMedia.twitter} />
        </TwitterShareButton>
      );
    case 'linkedin':
      return (
        <LinkedinShareButton url={jobViewUrl} sk-event-name={eventTypes.job.shareJobSocialMedia.linkedIn}>
          <LinkedinIcon sk-event-name={eventTypes.job.shareJobSocialMedia.linkedIn} />
        </LinkedinShareButton>
      );
    default:
      return null;
  }
};

const getJobExternalPreviewURL = (jobDetails, currentJobForm) => {
  let jobViewUrl = jobDetails?.ViewUrl;
  if (!jobViewUrl) return null;
  const jobLocation = currentJobForm.getFieldValue('Location');
  let jobDescription = currentJobForm.getFieldValue('JobDescription')?.htmlContent;
  const jobCountry = jobDetails.CountryCode;

  if (jobDescription) {
    const encoder = new TextEncoder();
    const data = encoder.encode(jobDescription);
    const base64 = btoa(String.fromCharCode.apply(null, data));
    jobDescription = base64;
  }
  jobViewUrl = queryString.stringifyUrl({
    url: jobViewUrl,
    query: { jobLocation, jobCountry, jobDescription, preview: true },
  });
  return jobViewUrl;
};

const getSocialMediaList = job => {
  return socialMediaList.map(portal => {
    return getSocialMediaButton(portal, job);
  });
};

const getJobDetailsForAnalytics = jobDetails => {
  const additionalNotes = getFilteredText(jobDetails?.JobAdvanceInfo?.SpecialNotes);
  return {
    additionalInput: {
      employmentTypes: jobDetails?.JobAdvanceInfo?.EmploymentTypes,
      securityClearance: jobDetails?.JobAdvanceInfo?.SecurityClearance,
      workAuthorization: jobDetails?.JobAdvanceInfo?.WorkAuthorization,
      additionalNotes,
    },
    autoExpand: jobDetails?.AutoExpand,
    company: jobDetails?.Client,
    country: jobDetails?.Country,
    education: jobDetails?.JobAdvanceInfo?.Degree,
    experience: { minExperience: jobDetails?.MinExperience, maxExperience: jobDetails?.MaxExperience },
    industries: jobDetails?.Industries,
    jobId: jobDetails?.JobId,
    jobTitle: jobDetails?.JobTitle,
    location: jobDetails?.Location,
    range: jobDetails?.Range,
    recommendedJobDescription: jobDetails?.recommendedJobDescription,
    salary: {
      compensationType: jobDetails?.JobAdvanceInfo?.Salary?.CompensationType,
      currency: jobDetails?.JobAdvanceInfo?.Salary?.Currency,
      maxSalary: jobDetails?.JobAdvanceInfo?.Salary?.MaxSalary,
      minSalary: jobDetails?.JobAdvanceInfo?.Salary?.MinSalary,
    },
    skills: { mustHaves: jobDetails?.MustHaves, niceToHaves: jobDetails?.NiceToHaves },
    titleSynonyms: jobDetails?.Synonyms,
    userId: jobDetails?.CreatedBy,
    zipcode: jobDetails?.Zipcode,
  };
};
const getIsLoading = (createOrderApiStatus, createJobStatus) => {
  return createOrderApiStatus === 'PENDING' || createJobStatus === 'INPROGRESS';
};
const getIsFreeCandidateStepper = (availablePaidCredits, showSampleCandidates, freeJobCreditsCount) => {
  const state = store.getState();
  const featureToggleList = getFeatureToggleList(state);
  const isFreeJobFlowAllowed = featureToggleList.FirstJobFree.IsEnabled;
  return availablePaidCredits === 0 && showSampleCandidates && freeJobCreditsCount > 0 && isFreeJobFlowAllowed;
};

const addPropertyInJobDetails = (jobDetails, property, value) => {
  const newJobDetails = _.cloneDeep(jobDetails);
  newJobDetails[property] = value;
  return newJobDetails;
};

const getGtagPayload = (jobDetails, property = null, value = null) => {
  let updatedJobDetails = _.cloneDeep(jobDetails);
  if (property && value && jobDetails) {
    updatedJobDetails = addPropertyInJobDetails(jobDetails, property, value);
  }
  const analyticsJobDetails = getJobDetailsForAnalytics(updatedJobDetails);
  return {
    jobId: updatedJobDetails?.JobId,
    jobTitle: updatedJobDetails?.JobTitle,
    isAdvanced: updatedJobDetails?.IsAdvancedJob,
    jobDetails: analyticsJobDetails,
  };
};

const getFormattedInput = content => {
  return content?.replace(/&nbsp;|<[^>]+>| &nbsp;/g, '').trim() ? content : '';
};

const validateInputField = content => {
  const regex = /&nbsp;|<[^>]+>| &nbsp;/g;
  return content?.replace(regex, '') || '';
};

export const filterEmptyValuesFromObject = (object = {}) => {
  return _.omitBy(object, value => {
    return (
      value === null ||
      value === undefined ||
      (typeof value === 'string' && value.trim() === '') ||
      (Array.isArray(value) && value.length === 0)
    );
  });
};

export const getIsScoreAndRankAndManualSearchDisabled = ({
  userConfig,
  currentJobDetails,
  isAdvancedSearchV2,
  candidateContext,
}) => {
  const { IsJobActivationChargeEnabledOnJobOpen: isJobActivationChargeEnabledOnJobOpen } = userConfig;
  if (candidateContext !== 'job' || !isAdvancedSearchV2 || !isJobActivationChargeEnabledOnJobOpen) return false;
  const isJobOpenCurrently = currentJobDetails.AryaStatus?.toLowerCase() === 'open';
  return !currentJobDetails.HadOpened || !isJobOpenCurrently;
};

export {
  getJobByJobGuid,
  getIsJobActivationAllowed,
  getIsJobActivationLimitExceeded,
  getJobLimitExceededAlertMessage,
  getUserGuids,
  getTryNowPayload,
  getTryNowCandidatesPayload,
  getSocialMediaButton,
  getSocialMediaList,
  getJobDetailsForAnalytics,
  getJobExternalPreviewURL,
  getIsLoading,
  getIsFreeCandidateStepper,
  getGtagPayload,
  getFormattedInput,
  validateInputField,
  getClickedSources,
  updateIsClicked,
};
