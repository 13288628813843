import { defineMessages } from 'react-intl';

import candidateBulkActionsContainerMessagesJsonEn from '../../Data/En_Us/CandidateBulkActionsContainerMessageEn_Us.json';
import candidateBulkActionsContainerMessagesJsonEs from '../../Data/Es_Es/CandidateBulkActionsContainerMessageEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'CandidateBulkActionsContainer';
const transformedMessage = formatMessagesWithComponentPrefix(candidateBulkActionsContainerMessagesJsonEn, component);
const candidateBulkActionsContainerMessages = defineMessages(transformedMessage);

const CandidateBulkActionsContainerMessage = defineMessages(candidateBulkActionsContainerMessages);
export const candidateBulkActionsContainerMessageEn = formatJsonWithComponentPrefix(
  candidateBulkActionsContainerMessagesJsonEn,
  component
);

export const candidateBulkActionsContainerMessageEs = formatJsonWithComponentPrefix(
  candidateBulkActionsContainerMessagesJsonEs,
  component
);
export default CandidateBulkActionsContainerMessage;
