import React from 'react';
import { Form, Select, Input, Radio, InputNumber, Icon, TreeSelect, Switch, Tooltip, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import * as MonsterClassicUtils from '../../../../../Utils/AdvancedOptionsUtils/MonsterClassicUtils';
import isIncludeResumeSwitchDisabled from '../../../../../Utils/AdvancedOptionsUtils/MonsterAdvancedOptionsUtils';
import { minSalaryValidator, maxSalaryValidator } from '../MonsterFormValidators';
import LanguageFilter from '../LanguageFilter/LanguageFilter';
import './ClassicAdvancedOptions.scss';
import message from '../../../../../Containers/ManualSearchV2/ManualSearchMessages';

const { Item } = Form;
const { Option } = Select;
const { Text } = Typography;

const treeData = (data = []) => {
  const parents = _.cloneDeep(data);
  return parents.map(parent => ({
    value: [parent.Id],
    title: parent.Alias,
    children: parent.Occupations.map(occupation => ({
      title: occupation.Alias,
      value: [parent.Id, occupation.Id],
    })),
  }));
};

class ClassicAdvancedOptions extends React.Component {
  constructor(props) {
    super(props);
    this.languageCount = 0;
    this.isFieldCleared = this.isFieldCleared.bind(this);
    this.clearMinMaxSalary = this.clearMinMaxSalary.bind(this);
    this.addMoreLanguages = this.addMoreLanguages.bind(this);
    this.isAddLanguageButtonDisabled = this.isAddLanguageButtonDisabled.bind(this);
    this.isProficiencyDisabled = this.isProficiencyDisabled.bind(this);
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.clearLanguageProficiency = this.clearLanguageProficiency.bind(this);
  }

  isFieldCleared(value, fieldName) {
    if (!value) {
      if (fieldName === 'monster-salary-currency') {
        this.clearMinMaxSalary();
      }
    }
  }

  clearMinMaxSalary() {
    const { form } = this.props;
    form.setFieldsValue({
      'monster-min-salary': undefined,
      'monster-max-salary': undefined,
    });
  }

  addMoreLanguages() {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    this.languageCount += 1;
    const nextKeys = keys.concat(this.languageCount);
    form.setFieldsValue({
      keys: nextKeys,
    });
  }

  removeLanguage(languageKey) {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const languages = form.getFieldValue('monster-languages');
    const monsterLanguages = languages.map((language, index) => {
      if (index === languageKey) {
        return {
          Language: undefined,
          Proficiency: undefined,
        };
      }
      return language;
    });
    if (keys.length === 1) {
      return;
    }
    form.setFieldsValue({
      keys: keys.filter(key => key !== languageKey),
      'monster-languages': monsterLanguages,
    });
  }

  isAddLanguageButtonDisabled() {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const languages = form.getFieldValue('monster-languages');
    return (
      keys.length === MonsterClassicUtils.Languages.length ||
      !(
        _.get(languages, [keys[keys.length - 1], 'Language'], undefined) &&
        _.get(languages, [keys[keys.length - 1], 'Proficiency'], undefined)
      )
    );
  }

  isProficiencyDisabled(key) {
    const { form } = this.props;
    const languages = form.getFieldValue('monster-languages');
    return !_.get(languages, [key, 'Language'], undefined);
  }

  onLanguageChange(value, key) {
    if (!value) {
      this.clearLanguageProficiency(key);
    }
  }

  clearLanguageProficiency(key) {
    const { form } = this.props;
    const languages = form.getFieldValue('monster-languages');
    const newLanguages = languages.map((language, index) => {
      if (index === key) {
        return {
          Language: language.Language,
          Proficiency: undefined,
        };
      }
      return language;
    });

    form.setFieldsValue({
      'monster-languages': newLanguages,
    });
  }

  render() {
    const { form, defaultSettings, monsterClassicCompanyCatagories } = this.props;
    const salaryTypeSelect = form.getFieldDecorator('monster-salary-type', {
      initialValue: defaultSettings.SalaryType || 'Yearly',
    })(
      <Select
        key="monster-salary-type"
        placeholder= {<FormattedMessage {...message.selectTypeLabel} />}
        dropdownClassName="popover-select"
        className="salary-type-select"
        getPopupContainer={trigger => trigger.parentNode}
      >
        <Option value="Yearly"><FormattedMessage {...message.yearlyLabel} /></Option>
        <Option value="Monthly"><FormattedMessage {...message.monthlyLabel} /></Option>
        <Option value="Weekly"><FormattedMessage {...message.weeklyLabel} /></Option>
        <Option value="Hourly"><FormattedMessage {...message.hourlyLabel} /></Option>
      </Select>
    );

    const comapnyIndustriesLabel = (
      <Text>
        <FormattedMessage {...message.companyIndustriesLabel} />{' '}
        <Tooltip title={<FormattedMessage {...message.jobSeekersInterestedLabel} />}>
          <Icon type="info-circle" />
        </Tooltip>
      </Text>
    );

    return (
      <div className="monster-classic">
        <div className="monster-advanced-options" ref={this.monsterOptionsWrapper}>
          <Item label={<FormattedMessage {...message.recentCandidateJobTitle} />} colon={false}>
            {form.getFieldDecorator('monster-candidate-recent-job-title', {
              initialValue: defaultSettings.RecentJobTitle,
            })(
              <Input
                key="monster-candidate-recent-job-title"
                placeholder="Enter candidate recent job title"
                className="monster-advance-options-input"
              />
            )}
          </Item>
          <Item label={<FormattedMessage {...message.recentJobDescriptionLabel} />} colon={false}>
            {form.getFieldDecorator('monster-candidate-recent-job-description', {
              initialValue: defaultSettings.RecentJobDescription,
            })(
              <Input
                key="monster-candidate-recent-job-description"
                placeholder="Enter recent job description"
                className="monster-advance-options-input"
              />
            )}
          </Item>
          <Item label={<FormattedMessage {...message.targetJobTitlesLabel} />} colon={false}>
            {form.getFieldDecorator('monster-target-job-title', { initialValue: defaultSettings.TargetJobTitle })(
              <Input
                key="monster-target-job-title"
                placeholder="Enter target job titles"
                className="monster-advance-options-input"
              />
            )}
          </Item>
          <Item label={<FormattedMessage {...message.careerLevelsLabel} />} colon={false}>
            {form.getFieldDecorator('monster-career-level', {
              initialValue: defaultSettings.CareerLevels ? defaultSettings.CareerLevels.split(',') : undefined,
            })(
              <Select
                placeholder={<FormattedMessage {...message.selectLevelsLabel} />}
                key="monster-career-level"
                mode="multiple"
                dropdownClassName="popover-select"
                getPopupContainer={trigger => trigger.parentNode}
                className="monster-advance-options-select"
              >
                {MonsterClassicUtils.CareerLevels.map(level => (
                  <Option key={level.value} value={level.value} title={level.displayText}>
                    {level.displayText}
                  </Option>
                ))}
              </Select>
            )}
          </Item>

          <LanguageFilter
            form={form}
            languageSkills={defaultSettings?.LanguageSkills}
            supportedLanguages={MonsterClassicUtils.Languages}
            jobBoardFormDecorator="monster"
            languageProficiencyLevels={MonsterClassicUtils.LanguageProficiencyLevels}
            defaultProficiencyLevel="Unknown"
          />

          <Item label="Job Statuses/Types" colon={false}>
            {form.getFieldDecorator('monster-job-type', {
              initialValue: defaultSettings.DesiredJobType?.split(','),
            })(
              <Select
                key="monster-job-type"
                placeholder={<FormattedMessage {...message.selectTypesLabel} />}
                dropdownClassName="popover-select"
                mode="multiple"
                getPopupContainer={trigger => trigger.parentNode}
                className="monster-advance-options-select"
              >
                {MonsterClassicUtils.TargetJobTypes.map(type => (
                  <Option key={type.value} value={type.value} title={type.displayText}>
                    {type.displayText}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
          <Item label={comapnyIndustriesLabel} colon={false}>
            {form.getFieldDecorator('monster-company-industries', {
              initialValue: defaultSettings.CompanyIndustries
                ? defaultSettings.CompanyIndustries.split(',')
                : undefined,
            })(
              <Select
                key="monster-company-industry"
                placeholder={<FormattedMessage {...message.selectTypesLabel} />}
                mode="multiple"
                dropdownClassName="popover-select"
                getPopupContainer={trigger => trigger.parentNode}
                className="monster-advance-options-select"
              >
                {MonsterClassicUtils.CompanyIndustries.map(industry => (
                  <Option key={industry.value} value={industry.value} title={industry.displayText}>
                    {industry.displayText}
                  </Option>
                ))}
              </Select>
            )}
          </Item>

          <Item label={<FormattedMessage {...message.companyCategoriesLabel} />} colon={false}>
            {form.getFieldDecorator('monster-company-catagories', {
              initialValue: defaultSettings.CompanyCatagories
                ? MonsterClassicUtils.getFormattedCompanyCategories(JSON.parse(defaultSettings.CompanyCatagories))
                : undefined,
            })(
              <TreeSelect
                key="monster-company-catagories"
                placeholder="Select types"
                treeCheckable
                dropdownClassName="popover-select"
                dropdownStyle={{
                  maxHeight: 200,
                  overflow: 'auto',
                  overflowX: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                treeData={treeData(monsterClassicCompanyCatagories)}
                className="monster-advance-options-tree-select"
                getPopupContainer={node => node.parentNode}
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                dropdownMatchSelectWidth
              />
            )}
          </Item>
          <div className="salary-form-item-label">{<FormattedMessage {...message.salaryRange} />} {salaryTypeSelect}</div>
          <div className="salary-form-item">
            <Item>
              {form.getFieldDecorator('monster-salary-currency', {
                initialValue: defaultSettings.SalaryCurrency,
              })(
                <Select
                  placeholder={<FormattedMessage {...message.currencyLabel} />}
                  key="monster-salary-currency"
                  dropdownClassName="popover-select monster-currency"
                  dropdownStyle={{ width: 160, fontSize: 12 }}
                  getPopupContainer={trigger => trigger.parentNode}
                  className="monster-salary-currency"
                  allowClear
                  onChange={value => this.isFieldCleared(value, 'monster-salary-currency')}
                >
                  {MonsterClassicUtils.CurrencyCodes.sort((c1, c2) => {
                    if (c1.toLowerCase() < c2.toLowerCase()) {
                      return -1;
                    }
                    if (c2.toLowerCase() < c1.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  }).map(currency => (
                    <Option key={currency} value={currency}>
                      {currency}
                    </Option>
                  ))}
                </Select>
              )}
            </Item>
            <Item>
              {form.getFieldDecorator('monster-min-salary', {
                initialValue: defaultSettings.MinSalary && parseInt(defaultSettings.MinSalary, 10),
                rules: [{ validator: (...args) => minSalaryValidator(form, args) }],
              })(
                <InputNumber key="monster-min-salary" placeholder="Min Salary" className="monster-min-salary" min={0} />
              )}
            </Item>
            <Item>
              {form.getFieldDecorator('monster-max-salary', {
                initialValue: defaultSettings.MaxSalary && parseInt(defaultSettings.MaxSalary, 10),
                rules: [{ validator: (...args) => maxSalaryValidator(form, args) }],
              })(
                <InputNumber
                  key="monster-max-salary"
                  placeholder="Max Salary"
                  className="monster-max-salary"
                  min={form.getFieldValue('monster-min-salary') ? form.getFieldValue('monster-min -salary') : 0}
                />
              )}
            </Item>
          </div>

          <Item label={<FormattedMessage {...message.includeResumesWithNoSalaryInfo} />} colon={false} className="switch-form-item">
            {form.getFieldDecorator('monster-include-resumes-with-no-salary-info', {
              initialValue:
                defaultSettings.IncludeResumesWithNoSalaryInfo !== undefined
                  ? defaultSettings.IncludeResumesWithNoSalaryInfo === '1'
                  : true,
              valuePropName: 'checked',
            })(
              <Switch
                key="monster-include-resumes"
                defaultChecked
                disabled={isIncludeResumeSwitchDisabled(form, defaultSettings.MinSalary, defaultSettings.MaxSalary)}
              />
            )}
          </Item>

          <Item label={<FormattedMessage {...message.targetWorkSiteLabel} />} colon={false}>
            {form.getFieldDecorator('monster-target-work-site', { initialValue: defaultSettings.TargetWorkSite })(
              <Radio.Group key="monster-target-work-site">
                <Radio value="onsite"><FormattedMessage {...message.onSiteLabel} /></Radio>
                <Radio value="offsite"><FormattedMessage {...message.offSiteLabel} /></Radio>
                <Radio><FormattedMessage {...message.doesNotMatterLabel} /></Radio>
              </Radio.Group>
            )}
          </Item>
          <Item label="Willing to Relocate" colon={false}>
            {form.getFieldDecorator('monster-relocation', {
              initialValue: defaultSettings.WillingToRelocate,
            })(
              <Radio.Group key="monster-relocation">
                <Radio value="true"><FormattedMessage {...message.yesLabel} /></Radio>
                <Radio value="false"><FormattedMessage {...message.noLabel} /></Radio>
                <Radio><FormattedMessage {...message.doesNotMatterLabel} /></Radio>
              </Radio.Group>
            )}
          </Item>
          <Item label={<FormattedMessage {...message.requireProjectLeadershipExperience} />} colon={false}>
            {form.getFieldDecorator('monster-project-leadership-experience', {
              initialValue: defaultSettings.HasProjectLeadershipExperience,
            })(
              <Radio.Group key="monster-project-leadership-experience">
                <Radio value="true"><FormattedMessage {...message.yesLabel} /></Radio>
                <Radio value="false"><FormattedMessage {...message.noLabel} /></Radio>
                <Radio><FormattedMessage {...message.doesNotMatterLabel} /></Radio>
              </Radio.Group>
            )}
          </Item>
          <Item label={<FormattedMessage {...message.requireManagerialExperience} />} colon={false}>
            {form.getFieldDecorator('monster-managerial-experience', {
              initialValue: defaultSettings.HasManagerialExperience,
            })(
              <Radio.Group key="monster-managerial-experience">
                <Radio value="true"><FormattedMessage {...message.yesLabel} /></Radio>
                <Radio value="false"><FormattedMessage {...message.noLabel} /></Radio>
                <Radio><FormattedMessage {...message.doesNotMatterLabel} /></Radio>
              </Radio.Group>
            )}
          </Item>
          <Item label={<FormattedMessage {...message.currentSecurityClearance} />} colon={false}>
            {form.getFieldDecorator('monster-current-security-clearance', {
              initialValue: defaultSettings.SecurityClearance,
            })(
              <Radio.Group key="monster-current-security-clearance">
                <Radio value="true"><FormattedMessage {...message.yesLabel} /></Radio>
                <Radio value="false"><FormattedMessage {...message.noLabel} /></Radio>
                <Radio><FormattedMessage {...message.doesNotMatterLabel} /></Radio>
              </Radio.Group>
            )}
          </Item>
        </div>
      </div>
    );
  }
}

export default ClassicAdvancedOptions;
