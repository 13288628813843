import { defineMessages } from 'react-intl';
import MessageComposerMessageJsonEn from '../../Data/En_Us/MessageComposerMessageEn_Us.json';
import MessageComposerMessageJsonEs from '../../Data/Es_Es/MessageComposerMessageEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'MessageComposer';
const transformedMessage = formatMessagesWithComponentPrefix(MessageComposerMessageJsonEn, component);
const messageComposerMessages = defineMessages(transformedMessage);

const MessageComposerMessage = defineMessages(messageComposerMessages);
export const messageComposerMessageEn = formatJsonWithComponentPrefix(MessageComposerMessageJsonEn, component);

export const messageComposerMessageEs = formatJsonWithComponentPrefix(MessageComposerMessageJsonEs, component);

export default MessageComposerMessage;
