import React from 'react';
import { FormattedMessage } from 'react-intl';
import AlertMessage from '../AlertMessage/AlertMessage';
import jobMessage from '../JobForm/JobMessages';

export default function SmtpNotConfiguredWarning(props) {
  const { selectedEmailFrom, version } = props;
  const descriptionText = <FormattedMessage {...jobMessage.smtpNotLinkedDescriptionText} />;
  const messageText = (
    <span>
      {selectedEmailFrom}
      <span>
        <FormattedMessage {...jobMessage.smtpConfigurationLinkingInstruction} />
      </span>
    </span>
  );
  return (
    <AlertMessage
      message={messageText}
      description={descriptionText}
      showSettingsLink
      type="warning"
      isClosable={false}
      version={version}
    />
  );
}
