import React from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';
import placeholder from '../../Placeholders/PlaceholdersMessages';

function PasswordInput({ form, credentialsValidator, isAuthenticated, jobBoard, intl }) {
  const passwordFieldName = `${jobBoard}password`;
  const passwordError = form.isFieldTouched(passwordFieldName) && form.getFieldError(passwordFieldName);
  return (
    <div className="jobboard-input-container">
      <Form.Item validateStatus={passwordError ? 'error' : ''} help="">
        {form.getFieldDecorator(passwordFieldName, {
          initialValue: isAuthenticated ? '********' : undefined,
          rules: [{ required: true, message: '', validator: credentialsValidator.validateEmptyField }],
        })(
          <Input
            type="password"
            placeholder={intl.formatMessage({ ...placeholder.passwordLabel })}
            className="common-jobboard-password"
            autoComplete="new-password"
            readOnly={isAuthenticated}
          />
        )}
      </Form.Item>
    </div>
  );
}

export default injectIntl(PasswordInput);
export { PasswordInput as PasswordInputWithoutInjectedIntl };
