import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Drawer } from 'antd';
import * as ClientActions from '../../Actions/ClientActions';
import * as JobActions from '../../Actions/JobActions';
import {
  getClientIds,
  getClientsById,
  getClientTotal,
  getActiveClientTotal,
  getAddClientDrawerVisibility,
} from '../../Reducers/ClientReducer';
import { getJobUtilities } from '../../Reducers/JobReducer';
import { getConfig } from '../../Reducers/ConfigReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import * as clientFormActions from '../../Actions/ActionCreators/CreateClientActionCreator';
import ClientForm from '../../Components/ClientForm/ClientForm';
import styles from './ClientFormContainer.module.scss';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer';
import jobMessage from '../../Components/JobForm/JobMessages';

const mapStateToProps = state => ({
  createClientApiStatus: getApiStatus(state, 'createClientApiStatus'),
  utilities: getJobUtilities(state),
  addClientDrawerVisibility: getAddClientDrawerVisibility(state),
  activeClientTotal: getActiveClientTotal(state),
  clientTotal: getClientTotal(state),
  clientIds: getClientIds(state),
  clientsById: getClientsById(state),
  userConfig: getConfig(state),
  featureToggleList: getFeatureToggleList(state),
});

const mapDispatchToProps = {
  setClientFormApiStatus: clientFormActions.setCreateClientApiStatus,
  fetchIndustries: JobActions.fetchIndustries,
  toggleAddClientDrawer: ClientActions.toggleAddClientDrawer,
  changeClientStatus: ClientActions.changeClientStatus,
  editClient: ClientActions.patchUpdateClient,
  fetchClientDetails: ClientActions.fetchClientDetails,
  fetchClientList: ClientActions.fetchClientList,
  addClient: ClientActions.addClient,
  clearCurrentClientCreationInfo: ClientActions.clearCurrentClientCreationInfo,
};

function ClientFormContainer(props) {
  const {
    createClientApiStatus,
    utilities,
    clientsById,
    setClientFormApiStatus,
    fetchIndustries,
    toggleAddClientDrawer,
    editClient,
    addClient,
    clientId,
    visible,
    onCloseDrawer,
    onCloseClientForm,
    clearCurrentClientCreationInfo,
    featureToggleList,
  } = props;

  useEffect(() => {
    return () => {
      clearCurrentClientCreationInfo();
    };
  }, []);

  return (
    <div>
      <Drawer
        destroyOnClose
        title={
          !clientId ? (
            <FormattedMessage {...jobMessage.newClientLabel} />
          ) : (
            <FormattedMessage {...jobMessage.clientProfileLabel} />
          )
        }
        visible={visible}
        onClose={onCloseDrawer}
        className={styles.clientFormDrawer}
      >
        <ClientForm
          onClose={onCloseClientForm}
          onSubmit={clientId ? editClient : addClient}
          clientDetails={clientId ? clientsById[clientId] : {}}
          toggleAddClientDrawer={toggleAddClientDrawer}
          utilities={utilities}
          fetchIndustries={fetchIndustries}
          createClientApiStatus={createClientApiStatus}
          setClientFormApiStatus={setClientFormApiStatus}
          featureToggleList={featureToggleList}
        />
      </Drawer>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientFormContainer);
export { ClientFormContainer as ClientFormWithoutContainer };
