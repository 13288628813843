import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import './PrimaryListHeader.scss';
import jobMessage from '../../JobForm/JobMessages';

const { Search } = Input;

export default class PrimaryListHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      searchQuery: '',
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    const { recentReadNotificationInfo } = this.props;
    const { jobId } = recentReadNotificationInfo;

    if (jobId) {
      this.setState({
        showSearch: true,
        searchQuery: jobId.toString(),
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { recentReadNotificationInfo } = this.props;
    const { recentReadNotificationInfo: prevRecentReadNotificationInfo } = prevProps;
    const { jobId: prevJobId, notificationId: prevNotificationId } = prevRecentReadNotificationInfo;
    const { jobId: currentJobId, notificationId: currentNotificationId } = recentReadNotificationInfo;
    if (currentJobId && (currentJobId !== prevJobId || currentNotificationId !== prevNotificationId)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        showSearch: true,
        searchQuery: currentJobId.toString(),
      });
    }
  }

  handleClick() {
    this.setState({ showSearch: true });
  }

  handleBlur() {
    const { searchQuery } = this.state;
    if (searchQuery.trim() === '') this.setState({ showSearch: false, searchQuery: '' }, this.handleSearch(''));
  }

  handleChange(event) {
    const { onSearch, saveRecentReadNotificationInfo, recentReadNotificationInfo } = this.props;
    const { jobId } = recentReadNotificationInfo;
    if (jobId) saveRecentReadNotificationInfo('Connect', {});
    if (!event.target.value) {
      onSearch();
      this.setState({ showSearch: false });
    }
    this.setState({ searchQuery: event.target.value });
  }

  handleSearch(value) {
    const { onSearch } = this.props;
    onSearch(value);
    this.setState({ showSearch: false });
  }

  render() {
    const { count } = this.props;
    const { showSearch, searchQuery } = this.state;

    return (
      <div className="primary-card-header">
        {showSearch ? (
          <Search
            allowClear
            onBlur={this.handleBlur}
            value={searchQuery}
            onChange={this.handleChange}
            onSearch={this.handleSearch}
          />
        ) : (
          <>
            <div>
              <b>
                <FormattedMessage {...jobMessage.jobsLabel} /> ({count})
              </b>
            </div>
            <div className="search">
              <Icon type="search" onClick={this.handleClick} />
            </div>
          </>
        )}
      </div>
    );
  }
}

PrimaryListHeader.propTypes = {
  count: PropTypes.string,
  onSearch: PropTypes.func,
};

PrimaryListHeader.defaultProps = {
  count: 0,
  onSearch: () => {},
};
