import { defineMessages } from 'react-intl';
import jobMessagesJsonEn from '../../Data/En_Us/JobMessagesEn_Us.json';
import jobMessagesJsonEs from '../../Data/Es_Es/JobMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'Job';
const jobMessages = formatMessagesWithComponentPrefix(jobMessagesJsonEn, component);

const jobMessagesEn = formatJsonWithComponentPrefix(jobMessagesJsonEn, component);
const jobMessagesEs = formatJsonWithComponentPrefix(jobMessagesJsonEs, component);

const messages = defineMessages(jobMessages);

export default messages;
export { jobMessagesEn, jobMessagesEs };
