import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import message from '../../../Containers/ManualSearchV2/ManualSearchMessages';
import './AdvancedOptionsFooter.scss';

export default function AdvancedOptionsFooter(props) {
  const { onClear, onApply } = props;

  return (
    <div className="advance-options-footer">
      <Button key="clear" onClick={onClear}>
      <FormattedMessage {...message.minimizedClearButton} />
      </Button>
      <Button key="apply" type="primary" onClick={onApply}>
      <FormattedMessage {...message.applyLabel} />
      </Button>
    </div>
  );
}
