import { Button, Carousel, Icon, Tag, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AdvertisementPortals } from '../../../Utils/JobDistributionUtils/JobDistributionUtils';
import RecallJobDistribution from '../RecallJobDistribution/RecallJobDistribution';
import styles from './AdvertiseJobComponent.module.scss';
import { isExpiredDateTime } from '../../../Utils/RelativeTimeCalculator';
import eventTypes from '../../../Analytics/EventTypes';
import jobMessage from '../../JobForm/JobMessages';

export default function AdvertiseJobComponent(props) {
  const {
    onPublishPortal,
    portals,
    currentUser,
    distributedJobInfo,
    distributedPortals,
    recalledPortals,
    recallPortalJobDistribution,
    isPublishDisabled,
    aryaJobStatus,
  } = props;

  const [recallVisible, setRecallVisible] = React.useState(false);

  const isPublished = distributedPortals?.find(p => p.toLowerCase() === AdvertisementPortals[0]);
  const isRecalled =
    recalledPortals?.find(p => p.toLowerCase() === AdvertisementPortals[0]) ||
    isExpiredDateTime(distributedJobInfo?.ExpiryDateTime);
  const isPublishClosed = !isPublished && aryaJobStatus === 'Closed';

  const hasEditAccess =
    currentUser.Id === distributedJobInfo?.CreatedBy || currentUser.Id === distributedJobInfo?.UpdatedBy;

  const onRecallConfirm = () => {
    setRecallVisible(false);
    recallPortalJobDistribution();
  };

  const getAdvertiseComponent = () => {
    const advertiseButton = (
      <Button
        type="primary"
        shape="round"
        onClick={() => onPublishPortal(portals[0])}
        disabled={isPublishDisabled || isPublishClosed || isRecalled}
        sk-event-name={eventTypes.job.advertiseJob.goToAdvertiseJob}
      >
        <FormattedMessage {...jobMessage.advertiseNowLabel} />
      </Button>
    );
    if (isRecalled)
      return <Tooltip title={<FormattedMessage {...jobMessage.jobNotAdvertisedReason} />}>{advertiseButton}</Tooltip>;
    if (isPublishClosed)
      return (
        <Tooltip title={<FormattedMessage {...jobMessage.closedJobAdvertiseNotAllowed} />}>{advertiseButton}</Tooltip>
      );
    if (!isPublishDisabled) return advertiseButton;
    return (
      <Tooltip title={<FormattedMessage {...jobMessage.adCampaignSupportLimitationInfo} />}>{advertiseButton}</Tooltip>
    );
  };

  const inactiveTag = () => {
    return isRecalled ? (
      <Tag className={styles.activeTag} color="red">
        <FormattedMessage {...jobMessage.inactiveLabel} />
      </Tag>
    ) : null;
  };

  return (
    <div className={styles.advertiseJobWrapper}>
      <RecallJobDistribution
        visible={recallVisible}
        onCancel={onRecallConfirm}
        onConfirm={() => setRecallVisible(false)}
        onClose={() => setRecallVisible(false)}
        portal={portals[0]}
        isCloseMarkNotRequired
      />
      <div className={styles.advertiseJobTitleWrapper}>
        <div>
          <Icon type="rise" theme="outlined" style={{ color: '#13c26b', marginRight: '10px' }} />
          {isPublished ? (
            <>
              <FormattedMessage {...jobMessage.adCampaignInProgressInfo} />{' '}
              <Tag className={styles.activeTag} color="green">
                <FormattedMessage {...jobMessage.activeLabel} />
              </Tag>
            </>
          ) : (
            <>
              <FormattedMessage {...jobMessage.advertiseJobOption} /> {inactiveTag()}
            </>
          )}
        </div>
      </div>
      <div className={styles.advertiseDescriptionWrapper}>
        {isPublished ? (
          <div>
            <FormattedMessage {...jobMessage.jobPromotedNotice} />
          </div>
        ) : (
          <Carousel autoplay autoplaySpeed={6000} dots={false}>
            <div>
              <FormattedMessage {...jobMessage.ROIBoostLabel} />
            </div>
            <div>
              <FormattedMessage {...jobMessage.reduceCostPerApplicantLabel} />
            </div>
            <div>
              <FormattedMessage {...jobMessage.jobPostingInfoMessage} />
            </div>
          </Carousel>
        )}
      </div>
      <div className={styles.advertiseActionsWrapper}>
        {isPublished ? (
          <>
            <Button
              className={styles.editBtn}
              type="primary"
              shape="round"
              disabled={!hasEditAccess}
              onClick={() => onPublishPortal(portals[0])}
              sk-event-name={eventTypes.job.advertiseJob.editAdvertiseJob}
            >
              <FormattedMessage {...jobMessage.editLabel} />
            </Button>
            <Button
              disabled={!hasEditAccess}
              type="secondary"
              shape="round"
              onClick={() => setRecallVisible(true)}
              sk-event-name={eventTypes.job.advertiseJob.endAdvertiseJob}
            >
              <FormattedMessage {...jobMessage.endCampaignLabel} />
            </Button>
          </>
        ) : (
          getAdvertiseComponent()
        )}
      </div>
    </div>
  );
}
