import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ClearFilter.module.scss';
import jobMessage from '../../JobForm/JobMessages';

function ClearFilters(props) {
  const { onClearFilter } = props;
  return (
    <div
      className={styles.clearAllFilters}
      role="button"
      tabIndex={0}
      onClick={onClearFilter}
      onKeyPress={onClearFilter}
    >
      <FormattedMessage {...jobMessage.clearFilterOption} />
    </div>
  );
}

export default ClearFilters;
