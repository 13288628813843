import { defineMessages } from 'react-intl';
import connectCardMessagesJsonEn from '../../Data/En_Us/ConnectCardMessagesEn_Us.json';
import connectCardMessagesJsonEs from '../../Data/Es_Es/ConnectCardMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'ConnectCard';
const transformedMessage = formatMessagesWithComponentPrefix(connectCardMessagesJsonEn, component);
const connectCardMessages = defineMessages(transformedMessage);

export const connectCardMessagesEn = formatJsonWithComponentPrefix(connectCardMessagesJsonEn, component);

export const connectCardMessagesEs = formatJsonWithComponentPrefix(connectCardMessagesJsonEs, component);

export default connectCardMessages;
