import React from 'react';
import { Popover, Button, Menu, Checkbox, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import FilterIconWithTooltip from '../Common/FilterIconWithTooltip/FilterIconWithTooltip';
import styles from './CandidateJobsFilter.module.scss';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

function CandidateJobsFilter(props) {
  const {
    jobs,
    jobAggregations,
    candidateId,
    fetchCandidateJobs,
    selectedCandidateStageFilterOptions,
    setSelectedCandidateStageFilterOptions,
    selectedJobStatusFilterOptions,
    setSelectedJobStatusFilterOptions,
    searchTerm,
    isFilterPopoverVisible,
    setFilterPopoverVisbility,
  } = props;

  const filters = {
    candidateStage: 'candidateStageFilter',
    jobStatus: 'jobStatusFilter',
  };

  const candidateStageFilters = jobAggregations?.Stages;
  const jobStatusFilters = jobAggregations?.JobStatuses;

  const [selectedFilterOptionsGroup, setFilterOptionsGroup] = React.useState(filters.candidateStage);

  const isFilterSelected = selectedJobStatusFilterOptions?.length || selectedCandidateStageFilterOptions?.length;

  const handleCandidateStageOptionChange = event => {
    if (selectedCandidateStageFilterOptions?.includes(event.target.value)) {
      const index = selectedCandidateStageFilterOptions.indexOf(event.target.value);
      setSelectedCandidateStageFilterOptions(arr => [...arr.splice(0, index), ...arr.splice(index + 1)]);
    } else setSelectedCandidateStageFilterOptions(arr => [...arr, event.target.value]);
  };

  const handleJobStatusOptionChange = event => {
    if (selectedJobStatusFilterOptions?.includes(event.target.value)) {
      const index = selectedJobStatusFilterOptions.indexOf(event.target.value);
      setSelectedJobStatusFilterOptions(arr => [...arr.splice(0, index), ...arr.splice(index + 1)]);
    } else setSelectedJobStatusFilterOptions(arr => [...arr, event.target.value]);
  };

  const handleVisibleChange = newVisible => {
    setFilterPopoverVisbility(newVisible);
  };

  const onApplyFilter = () => {
    fetchCandidateJobs({
      candidateId,
      searchTerm,
      CandidateStages: selectedCandidateStageFilterOptions,
      JobStatuses: selectedJobStatusFilterOptions,
    });
    setFilterPopoverVisbility(false);
  };

  const onClearFilter = () => {
    if (isFilterSelected) {
      setSelectedCandidateStageFilterOptions([]);
      setSelectedJobStatusFilterOptions([]);
      fetchCandidateJobs({
        candidateId,
        CandidateStages: [],
        JobStatuses: [],
        searchTerm,
      });
      setFilterPopoverVisbility(false);
    }
  };

  const candidateStageFilterOptions = (
    <Checkbox.Group
      className={styles.filterCheckboxGroup}
      key="candidateStageFilters"
      value={selectedCandidateStageFilterOptions}
    >
      {candidateStageFilters?.map(entry => (
        <div>
          {' '}
          <Checkbox value={entry.Id} onChange={handleCandidateStageOptionChange}>
            {`${_.capitalize(entry.Value)} (${entry.Count})`}
          </Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  );
  const jobStatusFilterOptions = (
    <Checkbox.Group
      key="jobStatusFilters"
      className={styles.filterCheckboxGroup}
      value={selectedJobStatusFilterOptions}
    >
      {' '}
      {jobStatusFilters?.map(entry => (
        <div>
          {' '}
          <Checkbox value={entry.Id} onChange={handleJobStatusOptionChange}>
            {`${_.capitalize(entry.Value)} (${entry.Count})`}
          </Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  );

  const getFilterOptions = () => {
    switch (selectedFilterOptionsGroup) {
      case filters.candidateStage:
        return candidateStageFilterOptions;
      case filters.jobStatus:
        return jobStatusFilterOptions;
      default:
        return candidateStageFilterOptions;
    }
  };

  const isFilterOptionSelected = selectedFilter => {
    return selectedFilterOptionsGroup === selectedFilter;
  };

  const filterContent = (
    <div className={styles.popoverContent}>
      <div style={{ display: 'flex' }}>
        <div className={styles.subFilter}>
          <Menu className={styles.menu} defaultSelectedKeys={filters.candidateStage}>
            <Menu.Item
              className={`${styles.menuItem} ${isFilterOptionSelected(filters.candidateStage) && styles.selected}`}
              key={filters.candidateStage}
              onClick={() => setFilterOptionsGroup(filters.candidateStage)}
            >
              <div className={styles.menuItemAndCollapseIcon}>
              <FormattedMessage {...message.stageLabel} />
                {isFilterOptionSelected(filters.candidateStage) && (
                  <Icon type="right" className={styles.collapseIcon} />
                )}
              </div>
            </Menu.Item>
            <Menu.Item
              className={`${styles.menuItem} ${isFilterOptionSelected(filters.jobStatus) && styles.selected}`}
              key={filters.jobStatus}
              onClick={() => setFilterOptionsGroup(filters.jobStatus)}
            >
              <div className={styles.menuItemAndCollapseIcon}>
              <FormattedMessage {...message.statusLabel} />
                {isFilterOptionSelected(filters.jobStatus) && <Icon type="right" className={styles.collapseIcon} />}
              </div>
            </Menu.Item>
          </Menu>
        </div>
        <div className={styles.filterOptions}>{getFilterOptions()}</div>
      </div>
    </div>
  );

  return (
    <Popover
      trigger="click"
      visible={isFilterPopoverVisible}
      onVisibleChange={handleVisibleChange}
      placement="leftTop"
      getPopupContainer={trigger => trigger.parentNode}
      content={jobs?.length ? filterContent : null}
      title={
        jobs?.length ? (
          <div className={styles.filter}>
            <div className={styles.filterTitle}>Filters</div>
            <div className={styles.filterApplyClear}>
              <Button shape="round" className={styles.filterClearButton} onClick={onClearFilter}>
              <FormattedMessage {...message.minimizedClearButton} />
              </Button>
              <Button shape="round" className={styles.filterApplyButton} type="primary" onClick={onApplyFilter}>
              <FormattedMessage {...message.applyLabel} />
              </Button>
            </div>
          </div>
        ) : null
      }
    >
      <FilterIconWithTooltip
        title="Filters"
        isFilterApplied={isFilterSelected}
        style={{ fontSize: 20, marginTop: 5 }}
      />
    </Popover>
  );
}

export default CandidateJobsFilter;
