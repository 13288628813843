import React from 'react';
import { Checkbox, Menu, Popover, Button, Badge, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import FilterIcon from '../../Icons/FilterIcon';
import styles from './CandidateSegmentsAndCampaignsFilter.module.scss';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

function CandidateSegmentsAndCampaignsFilter(props) {
  const {
    segments,
    candidateId,
    fetchCandidateSegmentsAndCampaigns,
    segmentAggregation,
    selectedCreatedByFilterOptions,
    setSelectedCreatedByFilterOptions,
    searchTerm,
    isFilterPopoverVisible,
    setFilterPopoverVisbility,
  } = props;

  const createdByFilters = segmentAggregation?.CreatedByName;

  const isFilterApplied = selectedCreatedByFilterOptions?.length > 0;
  const handleVisibleChange = newVisible => {
    setFilterPopoverVisbility(newVisible);
  };

  const onApplyFilter = () => {
    fetchCandidateSegmentsAndCampaigns({
      candidateId,
      searchTerm,
      CreatedBy: selectedCreatedByFilterOptions,
    });
    setFilterPopoverVisbility(false);
  };

  const onClearFilter = () => {
    if (isFilterApplied) {
      setSelectedCreatedByFilterOptions([]);
      fetchCandidateSegmentsAndCampaigns({
        candidateId,
        searchTerm,
        CreatedBy: [],
      });
    }
    setFilterPopoverVisbility(false);
  };

  const createdByOptionChange = event => {
    if (selectedCreatedByFilterOptions?.includes(event.target.value)) {
      const index = selectedCreatedByFilterOptions.indexOf(event.target.value);
      setSelectedCreatedByFilterOptions(arr => [...arr.splice(0, index), ...arr.splice(index + 1)]);
    } else setSelectedCreatedByFilterOptions(arr => [...arr, event.target.value]);
  };

  const createdByFilterOptions = (
    <>
      {createdByFilters?.map(item =>
        item.Value ? (
          <div>
            {' '}
            <Checkbox
              value={item.Id}
              key={item.Id}
              onChange={createdByOptionChange}
              defaultChecked={selectedCreatedByFilterOptions?.includes(item.Id)}
            >
              {`${_.startCase(item.Value)} (${item.Count})`}
            </Checkbox>
          </div>
        ) : null
      )}
    </>
  );
  const filterContent = (
    <div className={styles.content}>
      <div className={styles.filters}>
        <Menu className={styles.menu} defaultSelectedKeys="createdBy">
          <Menu.Item className={styles.menuItem} key="createdBy">
            <div className={styles.menuItemAndCollapseIcon}>
            <FormattedMessage {...message.CreatedByLabel} />
              <Icon type="right" className={styles.collapseIcon} />
            </div>
          </Menu.Item>
        </Menu>
      </div>
      <div className={styles.subFilters}>{createdByFilterOptions}</div>
    </div>
  );

  return (
    <Popover
      trigger="click"
      content={segments?.length ? filterContent : null}
      visible={isFilterPopoverVisible}
      onVisibleChange={handleVisibleChange}
      getPopupContainer={trigger => trigger.parentNode}
      placement="leftTop"
      title={
        segments?.length ? (
          <div className={styles.filter}>
            <div className={styles.filterTitle}>Filters</div>
            <div className={styles.filterApplyClear}>
              <Button shape="round" className={styles.filterClearButton} onClick={onClearFilter}>
              <FormattedMessage {...message.minimizedClearButton} />
              </Button>
              <Button shape="round" className={styles.filterApplyButton} type="primary" onClick={onApplyFilter}>
              <FormattedMessage {...message.applyLabel} />
              </Button>
            </div>
          </div>
        ) : null
      }
    >
      <div className={styles.filterIcon}>
        <Badge dot={isFilterApplied}>
          <FilterIcon />
        </Badge>
      </div>
    </Popover>
  );
}

export default CandidateSegmentsAndCampaignsFilter;
