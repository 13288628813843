/* eslint-disable no-return-assign */
import _ from 'lodash';
import cleanSet from 'clean-set';
import {
  UPDATE_CANDIDATE_RECOMMENDED_INFO,
  ADD_VAULT_CANDIDATE_IDS,
  SET_CANDIDATE_PERSON_ID,
  SET_FETCH_CONTACT_STATUS,
  CLEAR_CANDIDATES_EXTRA_INFO,
  SET_CANDIDATE_VIEW_STATUS,
  SET_CANDIDATE_DOWNLOADED_DETAILS,
  SET_CANDIDATE_FAVOURITE_STATUS,
  SET_CANDIDATE_VIEW_STATUS_V2,
} from '../Actions/ActionCreators/CandidateActions';

const initialState = {
  CandidatesById: {},
};

function CandidateExtraInfoReducer(state = initialState, action = {}) {
  let newState;
  let payload;
  let vaultCandidateState;
  let sources;
  let vaultName;
  let vaultCandidates;
  let candidateId;
  let recommendedInfo;
  let fetchContactStatus;
  let personId;
  let status;
  let publishedStatus;
  let IsDownloaded;
  let IsUnlocked;
  let isFavourite;

  switch (action.type) {
    case CLEAR_CANDIDATES_EXTRA_INFO: {
      return initialState;
    }

    case UPDATE_CANDIDATE_RECOMMENDED_INFO: {
      ({ payload } = action);
      ({ candidateId, recommendedInfo } = payload);
      const candidateDetails = _.get(state, ['CandidatesById', candidateId], {});
      newState = cleanSet(state, ['CandidatesById', candidateId], {
        ...candidateDetails,
        ...recommendedInfo,
      });
      return newState;
    }

    case 'SET_CANDIDATE_STATUS': {
      ({ candidateId, status, publishedStatus } = action.payload);
      newState = cleanSet(state, ['CandidatesById', candidateId, 'Status'], status);
      newState = cleanSet(newState, ['CandidatesById', candidateId, 'PublishStatus'], publishedStatus);
      return newState;
    }
    case ADD_VAULT_CANDIDATE_IDS: {
      newState = { ...state };
      ({ vaultCandidates, vaultName } = action.payload);
      vaultCandidates.forEach(vaultCandidate => {
        candidateId = vaultCandidate.Id;
        vaultCandidateState = {
          Name: vaultName,
          CandidateId: vaultCandidate.VaultCandidateId,
          Portal: 'Internal',
          Type: 'Mapped',
        };
        sources = _.get(state, ['CandidatesById', candidateId, 'Sources'], []);
        sources.unshift(vaultCandidateState);
        newState = cleanSet(state, ['CandidatesById', candidateId, 'Sources'], sources);
      });
      return newState;
    }
    case SET_CANDIDATE_VIEW_STATUS:
      ({ candidateId } = action.payload);
      return cleanSet(state, ['CandidatesById', candidateId, 'IsViewed'], true);

    case SET_FETCH_CONTACT_STATUS:
      ({ candidateId, fetchContactStatus } = action.payload);
      newState = cleanSet(state, ['CandidatesById', candidateId, 'fetchContactStatus'], fetchContactStatus);
      return newState;

    case SET_CANDIDATE_PERSON_ID: {
      ({ candidateId, personId } = action.payload);
      if (_.get(state, ['CandidatesById', candidateId, 'PersonId'])) {
        return state;
      }
      newState = cleanSet(state, ['CandidatesById', candidateId, 'PersonId'], personId);
      return newState;
    }

    case SET_CANDIDATE_DOWNLOADED_DETAILS: {
      ({ candidateId, IsDownloaded, IsUnlocked } = action.payload);
      newState = { ...state };
      const existingCandidate = _.get(newState, ['CandidatesById', candidateId], {});
      const downloadDetails =
        IsUnlocked === undefined
          ? {
              IsDownloaded,
            }
          : {
              IsDownloaded,
              IsUnlocked,
            };
      const mergedCandidateDetails = _.merge(existingCandidate, downloadDetails);
      _.setWith(newState, ['CandidatesById', candidateId], mergedCandidateDetails, Object);
      return newState;
    }

    case SET_CANDIDATE_FAVOURITE_STATUS: {
      ({ candidateId, isFavourite } = action.payload);
      if (_.get(state, ['CandidatesById', candidateId])) {
        return cleanSet(state, ['CandidatesById', candidateId, 'IsFavourite'], isFavourite);
      }
      return state;
    }

    case SET_CANDIDATE_VIEW_STATUS_V2:
      if (_.get(state, ['CandidatesById', action.payload.candidateId])) {
        const newSelectedCandidate = {
          ...state.CandidatesById[action.payload.candidateId],
          IsCandidateViewed: action.payload.viewStatusByCandidateId[action.payload.candidateId].IsViewed,
          LastViewedDate: action.payload.viewStatusByCandidateId[action.payload.candidateId].LatestViewedDate,
          LastViewedBy: action.payload.viewStatusByCandidateId[action.payload.candidateId].LatestViewedBy,
          LastViewedByName: action.payload.viewStatusByCandidateId[action.payload.candidateId].LatestViewedByName,
        };
        return cleanSet(state, ['CandidatesById', action.payload.candidateId], newSelectedCandidate);
      }
      return state;
    case 'REMOVE_CANDIDATE_STATUS': {
      const { aryaCandidateIds} = action.payload;
      newState = { ...state };
      // eslint-disable-next-line no-return-assign
      aryaCandidateIds.map(
        aryaCandidateId => (newState = cleanSet(newState, ['CandidatesById', aryaCandidateId, 'Status'], null))
      );
      return newState;
    }
    case 'SET_CANDIDATE_NAME_AND_EDUCATION': {
      const { candidateEducations, candidateName, candidateId } = action.payload;
      return {
        ...state,
        CandidatesById: {
          ...state.CandidatesById,
          [candidateId]: {
            ...state.CandidatesById[candidateId],
            Name: candidateName,
            Educations: candidateEducations,
          },
        },
      };
    }

    default:
      break;
  }

  return state;
}

function getCandidateExtraInfoById(state) {
  return state.CandidateExtraInfoReducer.CandidatesById;
}

export { CandidateExtraInfoReducer, getCandidateExtraInfoById };
