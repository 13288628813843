import { defineMessages } from 'react-intl';

const messages = defineMessages({
  jobTitle: {
    id: 'ShareJob.Title',
    defaultMessage: 'Share job with others',
  },
  segmentTitle: {
    id: 'ShareSegment.Title',
    defaultMessage: 'Share segment with others',
  },
  share: {
    id: 'ShareJob.OkText.Share',
    defaultMessages: 'Share',
  },
  unshare: {
    id: 'ShareJob.OkText.Unshare',
    defaultMessages: 'Unshare',
  },
  cancel: {
    id: 'ShareJob.CancelText.Cancel',
    defaultMessages: 'Cancel',
  },
  admin: {
    id: 'ShareJob.Admin',
    defaultMessages: 'Admin',
  },
  searchRecruiters: {
    id: 'ShareJob.Search.Placeholder',
    defaultMessage: 'Search Recruiters',
  },
  loading: {
    id: 'ShareJob.Loading',
    defaultMessage: 'Loading',
  },
  owner: {
    id: 'ShareJob.Owner',
  },
  inactiveLabel: {
    id: 'ShareJob.Inactive',
  },
  shareWithAllLabel: {
    id: 'ShareJob.ShareWithAll',
  },
});

export default messages;
