import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';
import uuid from 'uuid';
import ResearchCard from './ResearchCard';
import { SmartIcon } from '../../Icons/AryaIcons';
import message from '../../Containers/CandidateDrawer/messages';

function CandidateResearch(props) {
  const { researchData = [] } = props;
  const researchCards = researchData.map(researchInfo => <ResearchCard researchInfo={researchInfo} />);

  return (
    <div>
      {researchCards.length ? (
        <Row gutter={12}>
          {researchCards.map(researchCard => (
            <Col key={uuid.v4()} span={12}>
              {researchCard}
            </Col>
          ))}
        </Row>
      ) : (
        <div>
          <SmartIcon />
          <span>
            <FormattedMessage {...message.researchNotAvailableMessage} />
          </span>
        </div>
      )}
    </div>
  );
}

export default CandidateResearch;
