import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import CandidateRejectCardContainer from '../../Containers/CandidateRejectCard/CandidateRejectCardContainer';
import CandidateRejectCard from '../CandidateRejectCard/CandidateRejectCard';
import styles from './Candidate360MinimizedCandidateHeaderWrapper.module.scss';
import * as candidateActions from '../../Actions/CandidateActions';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getCandidateConnectStatus } from '../../Reducers/ConnectReducer';
import FetchContactWidgetWrapper from '../ConnectCard/FetchContactWidget/FetchContactWidgetWrapper';
import CandidateBookmark from '../CandidateCard/CandidateBookmark';
import NewCandidateShortlistReject from '../CandidateCard/NewCandidateShortlistReject/NewCandidateShortlistReject';
import SocialIcon from '../Common/SocialIcon/SocialIcon';
import MinimizedRejectedIcon from '../../Icons/MinimizedRejectedIcon';
import MinimizedShortlistedIcon from '../../Icons/MinimizedShortlistedIcon';
import AtsPushComponent from './AtsPushComponent';
import PushCandidateComponent from './PushCandidateComponent';
import { checkAccessConfig, getSourceData } from '../../Utils/CandidateDrawerUtils';
import Candidate360MinimizedCandidateInformationCard from './Candidate360MinimizedCandidateInformationCard';
import { getConfig } from '../../Reducers/ConfigReducer';
import * as CandidateAction from '../../Actions/ActionCreators/CandidateActions';

const mapStateToProps = (state, props) => ({
  candidateBookmarkApiStatus: getApiStatus(state, 'candidateBookmarkApiStatus'),
  candidateConnectStatus: getCandidateConnectStatus(state, props.candidate?.PersonId),
  userConfig: getConfig(state),
});

const mapsDispatchToProps = {
  updateCandidateBookmarkStatus: candidateActions.updateCandidateBookmarkStatus,
  recommendFavouriteCandidate: candidateActions.recommendFavouriteCandidate,
};

function Candidate360MinimizedCandidateHeaderWrapper(props) {
  const {
    candidate,
    candidateType,
    onCandidateStatusChange,
    onCandidateReject,
    candidateContext = 'job',
    isPaidJobServiceEnabled,
    featureToggleList,
    jobId,
    candidateBookmarkApiStatus,
    updateCandidateBookmarkStatus,
    currentJobDetails,
    candidateConnectStatus,
    maskingConfig,
    getCandidateStatus,
    ignoreSimilar,
    version,
    pushCandToAtsStatus,
    atsConfig,
    showPushError,
    showContactInfoButton,
    onClickAtsPush,
    showPushCandidateButton,
    isCandidateDownloaded,
    onPushCandToAts,
    showCandidateDetailTabs,
    candidateId,
    activeTab,
    candidateStatusUpdate,
    userConfig,
    openInNewTab,
    isConnectTab,
    setAddSubsegmentModalStatus,
    selectedSubSegmentId,
  } = props;

  const isAdvancedSearchV2Enabled = featureToggleList.AdvanceSearchV2.IsEnabled;

  const status = candidate?.Status;
  const isBookmarkVisible = candidateType !== 'quick-search';
  const isAddVisible =
    candidateContext === 'job' ||
    (candidateContext === 'segment' &&
      (status ? status.toLowerCase() === 'sourced' || status.toLowerCase() === 'rejected' : true));

  const isDeleteVisible =
    candidateContext === 'job' ||
    (candidateContext === 'segment' &&
      status &&
      (status.toLowerCase() === 'sourced' || status.toLowerCase() === 'shortlisted'));

  const { atsSource, pushedCandidateSource, originalSource } = getSourceData(candidate, version);
  const {
    isPushAllowed,
    isRecreateCandidateAllowed,
    isPushCandidateNotAllowedForAryaClosedJobs,
    isManualCandidateCreationAllowedForApplied,
  } = checkAccessConfig(candidate, atsConfig);
  const candidateBookmark = (
    <CandidateBookmark
      candidate={candidate}
      updateCandidateBookmarkStatus={updateCandidateBookmarkStatus}
      jobId={jobId}
      candidateBookmarkApiStatus={candidateBookmarkApiStatus}
      width={18}
      height={18}
    />
  );

  const shortlistedIcon = <MinimizedShortlistedIcon style={{ fontSize: '24px' }} />;
  const rejectedIcon = <MinimizedRejectedIcon />;
  const candidateShortListReject = (
    <NewCandidateShortlistReject
      isPaidJobServiceEnabled={isPaidJobServiceEnabled}
      activeTab={activeTab}
      featureToggleList={featureToggleList}
      onCandidateStatusChange={onCandidateStatusChange}
      candidateContext={candidateContext}
      isAddVisible={isAddVisible}
      isDeleteVisible={isDeleteVisible}
      candidateStatusUpdate={candidateStatusUpdate}
      candidate={candidate}
      size="medium"
      onCandidateReject={onCandidateReject}
      jobId={jobId}
      candidateId={candidateId}
      className={styles.enhancedShortlistContainer}
      shortlistedIcon={shortlistedIcon}
      rejectedIcon={rejectedIcon}
      iconStyle={{ fontSize: '24px', width: '24px', height: '24px' }}
      candidateTransferIconClassName={styles.candidateTransferIcon}
      iconHeight={24}
      version={version}
      setAddSubsegmentModalStatus={setAddSubsegmentModalStatus}
      selectedSubSegmentId={selectedSubSegmentId}
    />
  );

  const socialIconComponent = <SocialIcon candidate={candidate} isActive={maskingConfig?.IsActive} />;

  const candidatePushComponentV2 =
    version === 'ats' ? (
      <AtsPushComponent
        candidate={candidate}
        atsSource={atsSource}
        onPushCandToAts={onPushCandToAts}
        isLoading={pushCandToAtsStatus === 'INPROGRESS'}
        isPushAllowed={isPushAllowed}
        onClickAtsPush={onClickAtsPush}
        isCandidateDownloaded={isCandidateDownloaded}
        showContactInfoButton={showContactInfoButton}
        showPushCandidateButton={showPushCandidateButton}
        originalSource={originalSource}
        showPushError={showPushError}
      />
    ) : (
      <PushCandidateComponent
        atsSource={pushedCandidateSource}
        onPushCandToAts={onPushCandToAts}
        loading={pushCandToAtsStatus === 'INPROGRESS'}
        isPushAllowed={isPushAllowed}
        jobStatus={currentJobDetails.Status}
        aryaJobStatus={currentJobDetails.AryaStatus}
        isPushCandidateNotAllowedForAryaClosedJobs={isPushCandidateNotAllowedForAryaClosedJobs}
        isManualCandidateCreationAllowedForApplied={isManualCandidateCreationAllowedForApplied}
        appliedtime={candidate.AppliedTime}
        showContactInfoButton={showContactInfoButton}
        showPushCandidateButton={showPushCandidateButton}
        isRecreateCandidateAllowed={isRecreateCandidateAllowed}
      />
    );

  const displayedPushComponent = <div className={styles.candidatePushV2}>{candidatePushComponentV2}</div>;
  const ConditionalCandidateRejectCard =
    isAdvancedSearchV2Enabled && !isConnectTab ? (
      <CandidateRejectCardContainer
        key={_.get(candidate, 'Id')}
        candidate={candidate}
        onCandidateReject={onCandidateReject}
        onCandidateStatusChange={onCandidateStatusChange}
        ignoreSimilar={ignoreSimilar}
        size="small"
        isPaidJobServiceEnabled={isPaidJobServiceEnabled}
        isCandidateListRejectCard
        jobId={jobId}
        candidateContext={candidateContext}
        version={version}
        openInNewTab={openInNewTab}
        featureToggleList={featureToggleList}
      />
    ) : (
      <CandidateRejectCard
        key={_.get(candidate, 'Id')}
        candidate={candidate}
        onCandidateReject={onCandidateReject}
        onCandidateStatusChange={onCandidateStatusChange}
        ignoreSimilar={ignoreSimilar}
        size="small"
        isPaidJobServiceEnabled={isPaidJobServiceEnabled}
        isCandidateListRejectCard
        jobId={jobId}
        candidateContext={candidateContext}
        version={version}
        openInNewTab={openInNewTab}
        featureToggleList={featureToggleList}
        isConnectTab={isConnectTab}
      />
    );

  const { candidateStatus } = getCandidateStatus(candidate, featureToggleList, currentJobDetails.JobGuid);

  return (
    <div className={styles.minimizedCandidateHeader}>
      {(candidateContext === 'segment'||!candidate.drawerRejectFlag) ? (
        <>
          <div className={styles.candidateInfoAndContact}>
            <Candidate360MinimizedCandidateInformationCard
              candidate={candidate}
              featureToggleList={featureToggleList}
              userConfig={userConfig}
              atsConfig={atsConfig}
              jobGuid={currentJobDetails.JobGuid}
            />
            <div className={styles.candidateConnect}>
              {showCandidateDetailTabs ? (
                <FetchContactWidgetWrapper
                  fetchContactStatus={candidate.fetchContactStatus}
                  currentJobDetails={currentJobDetails}
                  candidate={candidate}
                  availableProviders={candidateConnectStatus?.Contact?.AvailableProviders}
                  contact={candidateConnectStatus?.Contact ?? {}}
                />
              ) : null}
            </div>
          </div>
          <div className={styles.bookmarkAndCandidateActions}>
            <div className={styles.candidateActionIcons}>
              <div>{candidateStatus}</div>
              <div className={styles.pushCandidateIcon}>{displayedPushComponent}</div>
              {socialIconComponent}
              {isBookmarkVisible ? candidateBookmark : null}
            </div>
            {candidateShortListReject}
          </div>
        </>
      ) : (
        ConditionalCandidateRejectCard
      )}
    </div>
  );
}
export default connect(mapStateToProps, mapsDispatchToProps)(Candidate360MinimizedCandidateHeaderWrapper);
export { Candidate360MinimizedCandidateHeaderWrapper as Candidate360MinimizedCandidateHeaderWrapperWithoutStore };
