import React from 'react';
import { Row, Col, Affix, Empty, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import styles from './CandidateSegmentsAndCampaigns.module.scss';
import CandidateSegmentsAndCampaignsItem from '../CandidateSegmentsAndCampaignsItem/CandidateSegmentsAndCampaignsItem';
import { EmptySegmentsIcon } from '../../Icons/AryaIcons';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

function CandidateSegmentsAndCampaigns(props) {
  const {
    segments,
    segmentsTotalCount,
    candidateId,
    appendCandidateSegmentsAndCampaigns,
    fetchJobAutomationConfiguration,
    associatedTemplateName,
    isCandidateViewHeaderVisible,
    selectedCreatedByFilterOptions,
    searchTerm,
    version,
    openSegmentAtsView,
  } = props;

  React.useEffect(() => {
    if (segments.length) {
      const jobIds = segments.map(entry => entry.JobId);
      fetchJobAutomationConfiguration({ JobIds: jobIds });
    }
  }, []);

  const getAppendCandidateSegmentsAndCampaignsPayload = ({ from, size }) => {
    return {
      From: from,
      Size: size,
      candidateId,
      searchTerm,
      CreatedBy: selectedCreatedByFilterOptions,
    };
  };

  const fetchCandidateSegmentsAndCampaigns = ({ from, size }) => {
    const payload = getAppendCandidateSegmentsAndCampaignsPayload({ from, size });
    appendCandidateSegmentsAndCampaigns(payload);
  };

  const getTemplateName = jobId => {
    const isEmailEnabled = associatedTemplateName?.[jobId]?.Email?.IsEnabled;
    if (isEmailEnabled) return associatedTemplateName?.[jobId]?.Email?.TemplateName;
    return associatedTemplateName?.[jobId]?.Workflows?.[0]?.WorkflowDefinitionName;
  };

  return (
    <div
      className={classNames(styles.segmentsAndCampaignsTable, { [styles.extendView]: !isCandidateViewHeaderVisible })}
    >
      <Affix>
        <Row className={styles.tableHeading} type="flex" justify="space-between">
          <Col xs={24} sm={7} className={styles.segmentName}>
          <FormattedMessage {...message.segmentNameLabel} />
          </Col>
          <Col xs={24} sm={7}>
          <FormattedMessage {...message.CampaignTemplateLabel} />
          </Col>
          <Col xs={24} sm={5}>
          <FormattedMessage {...message.CreatedBy} />
          </Col>
        </Row>
      </Affix>
      {segments.length ? (
        <InfiniteScroll
          useWindow={false}
          initialLoad={false}
          loadMore={page => fetchCandidateSegmentsAndCampaigns({ from: page * 10, size: 10 })}
          hasMore={segments.length < segmentsTotalCount}
          loader={
            <div key="loader" className={styles.loader}>
              <Icon className={styles.loadingIcon} type="loading" spin />
            </div>
          }
          threshold={100}
        >
          {segments.map(entry => (
            <CandidateSegmentsAndCampaignsItem
              segmentName={entry.JobTitle}
              campaigns={getTemplateName(entry.JobId)}
              createdTime={entry.CreatedTime}
              createdBy={entry.CreatedByName}
              status={entry.JobStatus}
              segmentId={entry.JobId}
              version={version}
              openSegmentAtsView={openSegmentAtsView}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <div className={styles.emptyScreen}>
          <Empty
            image={<EmptySegmentsIcon className={styles.segmentEmpty} />}
            description={
              <span>
                <span className={styles.segmentNotFound}> <FormattedMessage {...message.noSegmentsFoundLabel} /></span>
              </span>
            }
          />
        </div>
      )}
    </div>
  );
}

export default CandidateSegmentsAndCampaigns;
