import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Checkbox, Input } from 'antd';
import { getScoutingAgentofJob } from '../../../../Reducers/ScoutingAgentReducer';
import styles from './ScoutingAgentFilter.module.scss';
import message from '../../CandidateFiltersMessage';

const mapStateToProps = (state, props) => ({
  scoutingAgentData: getScoutingAgentofJob(state, props.jobId),
});

function mergeArrays(array1, array2) {
  const idToItemMap = {};
  array2.forEach(item => {
    idToItemMap[item.Value] = item.Count;
  });
  return array1
    .map(item1 => {
      const item2 = idToItemMap[item1.Id];
      if (item2) {
        return {
          ...item1,
          Count: idToItemMap[item1.Id],
        };
      }
      return null;
    })
    .filter(item => item);
}

const ScoutingAgentFilter = props => {
  const { ScoutingAgentId = [], selectedScoutingAgents, setSelectedScoutingAgents, scoutingAgentData } = props;
  const [filterAgentList, setFilterAgentList] = React.useState([]);
  const stringifiedScoutingAgentIds = JSON.stringify(ScoutingAgentId);
  const stringifiedScoutingAgentData = JSON.stringify(scoutingAgentData);
  const stringifiedFilterAgentList = JSON.stringify(filterAgentList);
  const stringifiedSelectedScoutingAgents = JSON.stringify(selectedScoutingAgents);

  React.useEffect(() => {
    const scoutingAgentFilterListData = mergeArrays(scoutingAgentData, ScoutingAgentId);
    setFilterAgentList(scoutingAgentFilterListData);
  }, [stringifiedScoutingAgentIds, stringifiedScoutingAgentData]);

  React.useEffect(() => {
    if (selectedScoutingAgents.length === filterAgentList.length && selectedScoutingAgents.length === 1) {
      const tempSelectedScoutingAgent = [...selectedScoutingAgents, 'all'];
      setSelectedScoutingAgents(tempSelectedScoutingAgent);
    }
  }, [stringifiedFilterAgentList, stringifiedSelectedScoutingAgents, setSelectedScoutingAgents]);

  const { Search } = Input;
  function removeElementFromArray(array, elementToRemove) {
    const index = array.indexOf(elementToRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  const handleSearch = e => {
    const scoutingAgentFilterListData = mergeArrays(scoutingAgentData, ScoutingAgentId);
    if (e.target.value && e.target.value !== '')
      setFilterAgentList(
        scoutingAgentFilterListData.filter(item => item.Name.toLowerCase().includes(e.target.value.toLowerCase()))
      );
    else {
      setFilterAgentList(scoutingAgentFilterListData);
    }
  };
  const handleSelect = checklist => {
    const allFilterIds = filterAgentList.map(item => item.Id);
    if (checklist.includes('all')) {
      setSelectedScoutingAgents([...allFilterIds, 'all']);
      if (selectedScoutingAgents.length > checklist.length) {
        const tempArray = [...checklist];
        removeElementFromArray(tempArray, 'all');
        setSelectedScoutingAgents(tempArray);
      }
    } else if (selectedScoutingAgents.includes('all')) setSelectedScoutingAgents([]);
    else if (allFilterIds.length === checklist.length) setSelectedScoutingAgents([...checklist, 'all']);
    else setSelectedScoutingAgents(checklist);
  };
  const showScoutingAgentFilter = ScoutingAgentId && ScoutingAgentId.length > 0;
  return showScoutingAgentFilter ? (
    <div>
      <h3><FormattedMessage {...message.scoutingAgentsLabel} /></h3>
      <Search placeholder="Search" onChange={handleSearch} className={styles.agentSearch} allowClear />
      <Checkbox.Group className="checkbox-group" value={selectedScoutingAgents} onChange={handleSelect}>
        <div className={styles.checkboxItemsContainer}>
          <Checkbox value="all"><FormattedMessage {...message.selectAllLabel} /></Checkbox>
          {filterAgentList.map(item => {
            const getTime = moment.utc(item.CreatedOn).local().format('hh:mm A | DD MMM YY');
            const fetchCount = item.Count;
            if (fetchCount > 0)
              return (
                <Checkbox key={item.Id} className={styles.checkboxItem} value={item.Id}>
                  <div className={styles.checkboxDisplay}>
                    <p style={{ marginBottom: 0 }}>
                      <b>{item.Name}</b>
                      <span>
                        {item.IsDeleted && '(Deleted)'} {`(${fetchCount})`}
                      </span>
                    </p>
                    <small style={{ color: '#51575E' }}>
                    <FormattedMessage {...message.createByLabel} /> {item.CreatedBy} <FormattedMessage {...message.onLabel} /> {getTime}
                    </small>
                  </div>
                </Checkbox>
              );
            return null;
          })}
        </div>
      </Checkbox.Group>
    </div>
  ) : (
    <div></div>
  );
};

export default connect(mapStateToProps, null)(ScoutingAgentFilter);
export { ScoutingAgentFilter as ScoutingAgentFilterWithoutStore };
