import React from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import RichTextEditor from '../../Components/Editor/RichTextEditor';
import NotesContext from './NotesContext';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

function NotesEditorField() {
  const { form, onEditorContentChange, editorBody, invalidDescriptionErrorMessage } = React.useContext(NotesContext);
  return (
    <Form.Item
      validateStatus={invalidDescriptionErrorMessage ? <FormattedMessage {...message.errorLabel} /> : <FormattedMessage {...message.successLabel} />}
      help={invalidDescriptionErrorMessage}
    >
      {form.getFieldDecorator(
        'NotesEditor',
        {}
      )(
        <RichTextEditor
          showToolbar
          editorContent={{ htmlContent: editorBody }}
          onChange={onEditorContentChange}
          imageSupport={false}
        />
      )}
    </Form.Item>
  );
}

export default NotesEditorField;
