import * as React from 'react';
import { Icon } from 'antd';

export const ResumePreviewNotAvailableSvg = props => (
  <svg
    width="162"
    height="160"
    viewBox="0 0 162 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <mask
        id="mask0_1418_28891"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="86"
        y="86"
        width="76"
        height="76"
      >
        <rect x="87.6668" y="86.67" width="73.3332" height="73.3332" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1418_28891)">
        <path
          d="M148.166 155.726L135.332 143.046C133.55 143.606 131.755 144.026 129.947 144.306C128.139 144.586 126.268 144.726 124.332 144.726C116.643 144.726 109.793 142.6 103.784 138.348C97.7745 134.096 93.4207 128.583 90.7215 121.81C91.7907 119.111 93.1403 116.603 94.7701 114.286C96.3997 111.968 98.2584 109.893 100.346 108.06L91.9437 99.5044L96.2213 95.2266L152.444 151.449L148.166 155.726ZM124.332 135.56C124.892 135.56 125.414 135.534 125.898 135.483C126.382 135.433 126.904 135.331 127.464 135.178L110.964 118.678C110.811 119.238 110.709 119.76 110.658 120.244C110.608 120.728 110.582 121.25 110.582 121.81C110.582 125.629 111.919 128.876 114.593 131.55C117.267 134.224 120.514 135.56 124.332 135.56ZM146.638 136.935L136.937 127.31C137.293 126.444 137.573 125.566 137.777 124.674C137.98 123.783 138.082 122.828 138.082 121.81C138.082 117.99 136.745 114.742 134.071 112.068C131.397 109.394 128.15 108.06 124.332 108.06C123.314 108.06 122.358 108.162 121.466 108.366C120.574 108.569 119.696 108.849 118.83 109.205L111.041 101.49C113.129 100.625 115.268 99.9754 117.458 99.5426C119.647 99.1098 121.939 98.8932 124.332 98.8932C132.022 98.8932 138.872 101.019 144.882 105.271C150.891 109.523 155.245 115.036 157.944 121.81C156.772 124.814 155.232 127.603 153.322 130.175C151.411 132.746 149.184 135.0 146.638 136.935ZM132.506 122.879L123.339 113.713C124.765 113.458 126.077 113.573 127.273 114.057C128.469 114.541 129.501 115.24 130.369 116.155C131.236 117.07 131.856 118.13 132.229 119.336C132.603 120.542 132.71 121.708 132.506 122.879Z"
          fill="#DCE0E6"
        />
      </g>
      <mask
        id="mask1_1418_28891"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="162"
        height="160"
      >
        <rect x="0.999756" width="159.999" height="159.999" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask1_1418_28891)">
        <path
          d="M87.666 60.0006H120.999L87.666 26.6672V60.0006ZM40.9994 146.667C37.3327 146.667 34.1939 145.362 31.5826 142.751C28.9714 140.14 27.666 136.999 27.666 133.333V26.6672C27.666 23.0005 28.9714 19.8598 31.5826 17.2485C34.1939 14.6373 37.3327 13.334 40.9994 13.334H94.3328L134.332 53.334V82.3339C132.221 81.556 130.027 80.9727 127.749 80.5838C125.471 80.1949 123.166 79.9996 120.832 79.9996C109.721 79.9996 100.305 83.8617 92.5826 91.5838C84.8605 99.3059 80.9994 108.722 80.9994 119.833C80.9994 124.834 81.8881 129.639 83.666 134.251C85.4439 138.863 87.9994 143.000 91.3328 146.667H40.9994Z"
          fill="#DCE0E6"
        />
      </g>
    </g>
  </svg>
);

const ResumePreviewNotAvailableIcon = props => <Icon component={ResumePreviewNotAvailableSvg} {...props} />;

export default ResumePreviewNotAvailableIcon;
