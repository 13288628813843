import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import TemplateRow from '../TemplateRow';
import eventTypes from '../../../../Analytics/EventTypes';
import connectTemplateMessage from '../../../../Containers/BaseTemplates/ConnectTemplatesMessages';

export default class SharedDripTemplateRow extends TemplateRow {
  constructor(props) {
    super(props);
    this.cloneTemplate = this.cloneTemplate.bind(this);
    this.viewTemplate = this.viewTemplate.bind(this);
  }

  cloneTemplate() {
    const { cloneTemplate, id } = this.props;
    cloneTemplate(id);
  }

  viewTemplate() {
    const { viewTemplate, id } = this.props;
    viewTemplate(id);
  }

  render() {
    const { title, createdBy, updatedTime } = this.props;
    return (
      <Row className="template">
        <Col span={8} onClick={this.viewTemplate} style={{ cursor: 'pointer' }}>
          {this.getTitleColumn(title)}
        </Col>
        <Col span={5}>{this.getUpdatedOnColumn(updatedTime)}</Col>
        {/* <Col span={4}>{this.getIsBotIncludedColumn(includesBot)}</Col> */}
        <Col span={5}>{this.getCreatedByColumn(createdBy)}</Col>
        <Col span={5}>
          {this.getCloneColumn(
            <FormattedMessage {...connectTemplateMessage.duplicateLabel} />,
            this.cloneTemplate,
            createdBy,
            eventTypes.connect.dripTemplates.duplicateShared
          )}
        </Col>
      </Row>
    );
  }
}

SharedDripTemplateRow.propTypes = {
  title: PropTypes.string.isRequired,
  includesBot: PropTypes.bool,
  createdBy: PropTypes.string.isRequired,
};

SharedDripTemplateRow.defaultProps = {
  includesBot: false,
};
