import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import TemplateRow from '../TemplateRow';
import eventTypes from '../../../../Analytics/EventTypes';
import connectTemplateMessage from '../../../../Containers/BaseTemplates/ConnectTemplatesMessages';

export default class SharedEmailTemplateRow extends TemplateRow {
  constructor(props) {
    super(props);
    this.viewTemplate = this.viewTemplate.bind(this);
    this.cloneTemplate = this.cloneTemplate.bind(this);
  }

  cloneTemplate() {
    const { id, cloneTemplate } = this.props;
    cloneTemplate(id);
  }

  viewTemplate() {
    const { id, viewTemplate } = this.props;
    viewTemplate(id);
  }

  render() {
    const { title, subject, createdBy, updatedTime } = this.props;

    return (
      <Row className="template">
        <Col span={8} onClick={this.viewTemplate} style={{ cursor: 'pointer' }}>
          {this.addSubject(this.getTitleColumn(title), this.getSubject('Subject', subject))}
        </Col>
        <Col span={5}>{this.getUpdatedOnColumn(updatedTime)}</Col>
        {/* <Col span={4}>{this.getIsBotIncludedColumn(includesBot)}</Col> */}
        <Col span={5}>{this.getCreatedByColumn(createdBy)}</Col>
        <Col span={5}>
          {this.getCloneColumn(
            <FormattedMessage {...connectTemplateMessage.duplicateLabel} />,
            this.cloneTemplate,
            createdBy,
            eventTypes.connect.emailTemplates.duplicateShared
          )}
        </Col>
      </Row>
    );
  }
}

SharedEmailTemplateRow.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  includesBot: PropTypes.bool.isRequired,
  createdBy: PropTypes.string.isRequired,
  cloneTemplate: PropTypes.func.isRequired,
  viewTemplate: PropTypes.func.isRequired,
};
