import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { Form, Input, Select, Dropdown, Menu, Checkbox } from 'antd';
import debounce from 'lodash/debounce';
import _ from 'lodash';
import { locationMenuListWithStateCode } from '../../Utils/LocationUtils';
import './PaymentBillingInfo.scss';
import placeholder from '../Placeholders/PlaceholdersMessages';

const { Option } = Select;

class PaymentBillingInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locationInput: '',
      zipCodeOptions: [],
    };
    this.filterLocation = debounce(this.filterLocation, 600);
    this.callForZipCodes = debounce(this.callForZipCodes, 600);
    this.locationChange = this.locationChange.bind(this);
    this.validateZipCode = debounce(this.validateZipCode.bind(this), 600);
  }

  componentDidUpdate(prevProps) {
    const { selectedAddress } = this.props;
    if (!_.isEqual(prevProps.selectedAddress, selectedAddress)) {
      this.setBillingAddress(selectedAddress || {});
    }
  }

  setBillingAddress = selectedAddress => {
    const { form, fetchZipCodes, resetZipcodes } = this.props;
    const { Street: address, Zip: zipcode, Country: country = 'US', State: state, City: city } = selectedAddress;
    form.setFieldsValue({
      Address: address,
      Zipcode: zipcode,
      Country: country,
    });
    if (city && state) {
      const location = `${city}, ${state}`;
      form.setFieldsValue({
        Location: location,
      });
      if (country && location) {
        fetchZipCodes({ country, location });
      }
    } else {
      form.setFieldsValue({
        Location: undefined,
      });
      resetZipcodes();
    }
  };

  componentWillReceiveProps(nextProps) {
    const { locations, zipCodes } = nextProps.utilities;
    const { state } = this;
    const { form } = this.props;
    if (locations) {
      this.setState({
        locationOptions: locations,
      });
    }
    if (zipCodes) {
      this.setState({
        zipCodeOptions: zipCodes,
      });
    }
    const zipcodeValidStatus = nextProps.utilities.isZipCodeInValid;
    if (
      !zipcodeValidStatus &&
      nextProps.utilities &&
      nextProps.utilities.manualLocation &&
      !_.isEqual(state.manualLocation, nextProps.utilities.manualLocation)
    ) {
      const zipCityState = [];
      if (nextProps.utilities.manualLocation.City) {
        zipCityState.push(nextProps.utilities.manualLocation.City);
      }
      if (nextProps.utilities.manualLocation.State) {
        zipCityState.push(nextProps.utilities.manualLocation.State);
      } else if (nextProps.utilities.manualLocation.StateCode) {
        zipCityState.push(nextProps.utilities.manualLocation.StateCode);
      }
      const zipLocation = zipCityState.join(', ');
      if (!_.isEmpty(nextProps.utilities.manualLocation)) {
        form.setFieldsValue({
          Location: zipLocation,
        });
      }
      this.setState({
        manualLocation: nextProps.utilities.manualLocation,
      });
    }
  }

  filterLocation(value) {
    this.setState({
      locationInput: value,
    });
    const { form, fetchLocations } = this.props;
    const country = form.getFieldValue('Country');
    fetchLocations({
      country,
      search: value,
    });
  }

  locationChange(value) {
    const { form, clearZipCodeError } = this.props;
    if (!value || !value.length) {
      form.setFieldsValue({
        Location: undefined,
        Zipcode: undefined,
      });
      clearZipCodeError();
    }
  }

  callForZipCodes(value) {
    const { form, fetchZipCodes } = this.props;
    const { location } = this.state;
    const country = form.getFieldValue('Country');
    if (location !== value) {
      form.setFieldsValue({
        Zipcode: undefined,
      });
      this.setState({ location: value });
    }
    fetchZipCodes({
      country,
      location: value,
    });
  }

  validateZipCode() {
    const { searchZipCode, form, clearZipValidationError } = this.props;
    const { zipCodeOptions } = this.state;
    const countryCode = form.getFieldValue('Country');
    const code = form.getFieldValue('Zipcode');
    if (!code.length || zipCodeOptions.filter(zipcodes => zipcodes.includes(form.getFieldValue('Zipcode'))).length) {
      clearZipValidationError();
    }
    if (
      code &&
      code.trim().length &&
      !zipCodeOptions.filter(zipcodes => zipcodes.includes(form.getFieldValue('Zipcode'))).length
    ) {
      searchZipCode({
        postalCode: code,
        countryCode,
      });
    }
  }

  validateAddress = (rule, value, callback) => {
    if (value && value.length > 60) {
      callback('address should not exceed 60 characters');
    }
    callback();
  };

  onChange = () => {
    const { form, userDetails } = this.props;
    const isChecked = form.getFieldValue('isBillingSameAsCompanyAddress');
    if (!isChecked) {
      this.setBillingAddress({
        Street: userDetails?.Street,
        Zip: userDetails?.ZipCode,
        ...userDetails,
      });
    } else {
      this.setBillingAddress({});
    }
  };

  render() {
    const {
      location,
      form,
      countries,
      locationApiStatus,
      utilities,
      paymentMode,
      hideHeader,
      isAddressAvaliable,
      selectedAddress,
      intl,
    } = this.props;
    const { getFieldDecorator } = form;
    const { locationInput, locationOptions } = this.state;
    let locationDropDownStatus;
    let _locationMenuList = null;
    let zipCodeMenuList = null;
    const { zipCodeOptions } = this.state;
    const zipCode = form.getFieldValue('Zipcode');
    const zipcodeValidStatus = utilities.isZipCodeInValid && !(zipCodeOptions || []).includes(zipCode);
    if (locationInput.length) {
      locationDropDownStatus = locationApiStatus === 'INPROGRESS' ? 'Loading...' : 'No location found';
    } else {
      locationDropDownStatus = 'Type to search';
    }

    const CountrySelect = countries.map(country => (
      <Option key={country.Iso2Code} value={country.Iso2Code}>
        {country.Name}
      </Option>
    ));
    // const isCountrySelected = form.getFieldValue('CountryCode');
    if (locationOptions) {
      _locationMenuList = locationMenuListWithStateCode(locationOptions);
    }
    const locationSelect = (
      <Select
        allowClear
        key="Location"
        showSearch
        placeholder={intl.formatMessage({ ...placeholder.locationLabel })}
        className="select-dropdown"
        notFoundContent={locationDropDownStatus}
        onSearch={value => this.filterLocation(value)}
        onSelect={value => this.callForZipCodes(value)}
        onChange={value => this.locationChange(value)}
        dropdownClassName="country-dropdown"
        showArrow={false}
      >
        {_locationMenuList?.map(locationOption => (
          <Option key={locationOption} value={locationOption}>
            {locationOption}
          </Option>
        ))}
      </Select>
    );

    zipCodeMenuList = zipCodeOptions?.length ? (
      <Menu>
        {(form.getFieldValue('Zipcode')
          ? zipCodeOptions.filter(zipcodes => zipcodes.includes(form.getFieldValue('Zipcode')))
          : zipCodeOptions
        ).map(zipcode => (
          <Menu.Item key={zipcode} onClick={() => form.setFieldsValue({ Zipcode: zipcode })}>
            {zipcode}
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <div />
    );
    const ispaymentPageLocation = location?.pathname === '/payment';
    const getClassnameForPaymentPage = classNames({
      'billing-container-payment': ispaymentPageLocation,
      'billing-container-profilesettings': !ispaymentPageLocation,
    });

    const _hideHeader = hideHeader || paymentMode === 'CreditCard';
    return (
      <div className="billing-container">
        <div className={getClassnameForPaymentPage}>
          {_hideHeader ? null : <div className="billing-information-header-text">Optional Details</div>}
          {isAddressAvaliable && (_.isEmpty(selectedAddress) || !selectedAddress) ? (
            <div className="payment-default-add" style={{ maginTop: ispaymentPageLocation ? '60px' : '20px' }}>
              <Form.Item>
                {getFieldDecorator('isBillingSameAsCompanyAddress', {
                  valuePropName: 'checked',
                  initialValue: false,
                })(
                  <Checkbox onChange={this.onChange}>
                    Check if the billing address is same as the company address
                  </Checkbox>
                )}
              </Form.Item>
            </div>
          ) : null}
          <div className="billing-address-country">
            <Form.Item label="Billing Address" colon={false} className="billing-address-item">
              {getFieldDecorator('Address', {
                rules: [{ required: paymentMode !== 'AryaJobCredit' }, { validator: this.validateAddress }],
              })(<Input placeholder={intl.formatMessage({ ...placeholder.billingAddressLabel })} />)}
            </Form.Item>
            <Form.Item label="Country" colon={false} className="billing-address-country-item ">
              {getFieldDecorator('Country', {
                initialValue: 'US',
                rules: [{ required: paymentMode !== 'AryaJobCredit' }],
              })(<Select>{CountrySelect}</Select>)}
            </Form.Item>
          </div>
          <div className="billing-state-code">
            <Form.Item label="City, State" colon={false} className="city-state-item">
              {getFieldDecorator('Location', {
                rules: [{ required: paymentMode !== 'AryaJobCredit' }],
                initialValue: undefined,
              })(locationSelect)}
            </Form.Item>
            <Form.Item
              label="Zip/Postal Code"
              colon={false}
              validateStatus={zipcodeValidStatus ? 'error' : undefined}
              help={zipcodeValidStatus ? 'Invalid ZipCode' : undefined}
              className="zip-postalcode-item"
            >
              <Dropdown overlay={zipCodeMenuList} trigger={['click']} overlayClassName="zipcode-dropdown">
                {getFieldDecorator('Zipcode', {
                  rules: [{ required: paymentMode !== 'AryaJobCredit' }],
                  initialValue: undefined,
                })(
                  <Input
                    placeholder={intl.formatMessage({ ...placeholder.postalCodeLabel })}
                    allowClear
                    autoComplete="disabled"
                    onChange={this.validateZipCode}
                  />
                )}
              </Dropdown>
            </Form.Item>
          </div>
        </div>
      </div>
    );
  }
}

PaymentBillingInfo.defaultProps = {
  utilities: {
    countries: [],
    locations: [],
    zipCodes: [],
  },
};
export default injectIntl(PaymentBillingInfo);
export { PaymentBillingInfo as PaymentBillingInfoWithoutInjectIntl };
