import React from 'react';
import { injectIntl,FormattedMessage } from 'react-intl';
import { Form, InputNumber, Select } from 'antd';
import styles from './DistanceForm.module.scss';
import placeholder from '../../Placeholders/PlaceholdersMessages';
import message from '../../../Containers/ManualSearchV2/ManualSearchMessages';

const { Item } = Form;
const { Option } = Select;

function DistanceForm(props) {
  const {
    form,
    distanceFieldDecorator,
    distanceInitialValue,
    distanceUnitFieldDecorator,
    distanceUnitInitialValue,
    isDisabled,
    intl,
  } = props;

  return (
    <div className={styles.distanceForm}>
      <Item colon={false}>
        {form.getFieldDecorator(distanceFieldDecorator, {
          initialValue: distanceInitialValue ?? 50,
        })(
          <InputNumber
            placeholder={intl.formatMessage({ ...placeholder.enterDistanceLabel })}
            min={0}
            max={1000}
            label="Distance"
            className={styles.input}
            disabled={isDisabled}
          />
        )}
      </Item>
      <Item colon={false} className={styles.minExp}>
        {form.getFieldDecorator(distanceUnitFieldDecorator, {
          initialValue: distanceUnitInitialValue ?? 'Miles',
        })(
          <Select
            placeholder={intl.formatMessage({ ...placeholder.selectUOMLabel })}
            getPopupContainer={trigger => trigger.parentNode}
            showArrow
            className={styles.select}
            dropdownClassName={styles.selectDropdown}
            disabled={isDisabled}
          >
            <Option value="Miles"><FormattedMessage {...message.milesWithUnitLabel} /></Option>
            <Option value="Kilometers"><FormattedMessage {...message.KilometersWithUnitLabel} /></Option>
          </Select>
        )}
      </Item>
    </div>
  );
}

export default injectIntl(DistanceForm);
export { DistanceForm as DistanceFormWithoutInjectedIntl };
