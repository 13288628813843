import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Modal, Button, Select, Input, Icon, Form } from 'antd';
import * as AryaPayActions from '../../Actions/AryaPayActions';
import { getConfig } from '../../Reducers/ConfigReducer';
import placeholder from '../Placeholders/PlaceholdersMessages';

const { Option } = Select;

function CreditInfoNotEnough(props) {
  const { visible, onCreditInfoNotEnoughModalOk, form, intl } = props;
  const dispatch = useDispatch();
  const userConfig = useSelector(state => getConfig(state));
  const header = "You don't have enough credits to pull contacts.";
  return (
    <div>
      <Modal visible={visible} footer={null} closable={false} centered>
        <div className="modal-container">
          <div className="icon-div">
            <Icon type="exclamation-circle" theme="filled" />
          </div>
          <div>
            <h3>{header}</h3>
            {_.get(userConfig, 'Role', '') !== 'Recruiter' ? (
              <div>
                <div className="credits-message">
                  <div className="credits-request-title">
                    <FormattedMessage {...placeholder.requestMoreCreditsLabel} />
                  </div>
                  {form.getFieldDecorator('Credits')(
                    <Select
                      className="credits-request-amount"
                      placeholder={intl.formatMessage({ ...placeholder.selectAnOptionLabel })}
                    >
                      <Option value={500}>500</Option>
                      <Option value={1000}>1000</Option>
                      <Option value={2000}>2000</Option>
                      <Option value="Customize"> {intl.formatMessage({ ...placeholder.customizeLabel })}</Option>
                    </Select>
                  )}
                </div>
                {form.getFieldValue('Credits') === 'Customize' ? (
                  <div className="other-amount-request">
                    <div className="other-amount-title">
                      {' '}
                      <FormattedMessage {...placeholder.enterOtherAmountLabel} />
                    </div>
                    {form.getFieldDecorator('OtherAmount')(
                      <Input
                        className="other-amount-input"
                        placeholder={intl.formatMessage({ ...placeholder.enterCreditAmountLabel })}
                      />
                    )}
                  </div>
                ) : null}
                <div className="action-button">
                  <Button
                    type="default"
                    shape="round"
                    style={{ marginRight: '8px' }}
                    key="cancel-button"
                    onClick={() => onCreditInfoNotEnoughModalOk(false)}
                  >
                     <FormattedMessage {...placeholder.cancelLabel} />
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    key="ok-button"
                    onClick={() => {
                      dispatch(
                        AryaPayActions.requestForCredits(
                          form.getFieldValue(form.getFieldValue('Credits') === 'Customize' ? 'OtherAmount' : 'Credits')
                        )
                      );
                      onCreditInfoNotEnoughModalOk(false);
                    }}
                  >
                    <FormattedMessage {...placeholder.sendRequestLabel} />
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <p className="credits-message"><FormattedMessage {...placeholder.contactYourAdministratorLabel} /></p>
                <div className="action-button">
                  <Button
                    type="primary"
                    shape="round"
                    key="ok-button"
                    onClick={() => onCreditInfoNotEnoughModalOk(false)}
                  >
                    <FormattedMessage {...placeholder.okLabel} />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default injectIntl(Form.create()(CreditInfoNotEnough));
export { CreditInfoNotEnough as CreditInfoNotEnoughWithoutInjectIntl };
