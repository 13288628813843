import { Modal, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import message from '../../Components/Placeholders/PlaceholdersMessages';

const TransferConfirmationModal = props => {
  const { visible, setIsVisible, onConfirm, isLoading } = props;
  const handleVisibleChange = () => setIsVisible(!visible);

  const handleConfirm = async () => {
    await onConfirm();
    setIsVisible(false);
  };

  const confirmButtonStyle = { background: '#FFA39E', border: '#FFA39E' };
  const contentStyle = { fontWeight: 500 };
  return (
    <Modal
      title="Transfer ownership"
      visible={visible}
      centered
      onCancel={handleVisibleChange}
      onOk={handleVisibleChange}
      footer={[
        <Button shape="round" key="back" onClick={handleVisibleChange}>
         <FormattedMessage {...message.cancelLabel}/>
        </Button>,
        <Button
          shape="round"
          key="submit"
          loading={isLoading}
          type="primary"
          style={confirmButtonStyle}
          onClick={handleConfirm}
        >
         <FormattedMessage {...message.confirmLabel}/>
        </Button>,
      ]}
    >
      <p style={contentStyle}>
      <FormattedMessage {...message.transferTheownershipWarningLabel}/>{' '}
      </p>
    </Modal>
  );
};

export default TransferConfirmationModal;
