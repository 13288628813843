import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Menu, Row, Col } from 'antd';
import styles from './UserWorkflowTemplateRow.module.scss';
import TemplateRow from '../TemplateRow';
import connectTemplateMessage from '../../../../Containers/BaseTemplates/ConnectTemplatesMessages';

const UserWorkflowTemplateRow = props => {
  const {
    workflowId,
    title,
    updatedTime,
    isPublished,
    duplicateTemplate,
    isDraft,
    location,
    columnLengthName,
    columnLengthDate,
  } = props;
  const { pathname } = location;

  function duplicateWorkflowTemplate() {
    duplicateTemplate(workflowId);
  }

  const templateRowObj = new TemplateRow();
  const workflowDescription = isPublished ? (
    ''
  ) : (
    <div className={styles.draftDesc}>
      <FormattedMessage {...connectTemplateMessage.draftLabel} />
    </div>
  );
  const actionsMenu = (
    <Menu>
      <Menu.Item onClick={duplicateWorkflowTemplate}>
        <span>
          <FormattedMessage {...connectTemplateMessage.duplicateLabel} />
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Row className={styles.template}>
        <Link
          to={{
            pathname: `${pathname}`,
            search: `?tab=workflows&type=preview&showReportsStats=true&workflowId=${
              workflowId || ''
            }&isWorkflowEditAllowedInPreview=true`,
            state: { isDraft },
          }}
        >
          <Col span={columnLengthName} className={styles.templateColumn}>
            {templateRowObj.addSubject(templateRowObj.getTitleColumn(title), workflowDescription)}
          </Col>
        </Link>
        <Col span={columnLengthDate}>{templateRowObj.addSubject(templateRowObj.getUpdatedOnColumn(updatedTime))}</Col>
        <Col span={4}>
          {!isDraft ? (
            <Link
              to={{
                pathname: `${pathname}`,
                search: `?tab=workflows&type=preview&showReportsStats=true&workflowId=${
                  workflowId || ''
                }&isWorkflowEditAllowedInPreview=true`,
                state: { isDraft },
              }}
            >
              <FormattedMessage {...connectTemplateMessage.viewReportLabel} />
            </Link>
          ) : null}
        </Col>
        <Col span={1}>{templateRowObj.getActionsColumn(actionsMenu)}</Col>
      </Row>
    </div>
  );
};

export default UserWorkflowTemplateRow;
