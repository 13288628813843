import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Menu, Icon, Popover } from 'antd';
import OpenAiIcon from '../../../Icons/OpenAiIcon';
import ComposeEmailIcon from '../../../Icons/ComposeEmailIcon';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../../Analytics/EventTypes';
import styles from './ComposeEmailDropdown.module.scss';
import message from '../../../Containers/CandidateDrawer/messages';

const consentStatusMap = {
  Approved: {
    iconType: 'check-circle',
    twoToneColor: '#52c41a',
    content: 'Consent Accepted',
  },
  Pending: {
    iconType: 'clock-circle',
    twoToneColor: '#FAAD14',
    content: 'Consent Pending',
  },
  Failed: {
    iconType: 'exclamation-circle',
    twoToneColor: '#F5222D',
    content: 'Consent Failed',
  },
  Denied: {
    iconType: 'exclamation-circle',
    twoToneColor: '#F5222D',
    content: 'Consent Denied',
  },
};

const ComposeEmailDropdown = ({
  dropdownVisibility,
  onClickToComposeEmailThroughChatGpt,
  Contact,
  onClickToComposeEmailManually,
  setDropdownVisibility,
  isCandidateViewIconVisible,
  composeEmailDropdownRef,
  candidate,
}) => {
  const getConsentStatusIconContent = () => {
    const { ConsentStatus: consentStatus } = Contact;
    const statusData = consentStatusMap[consentStatus];
    if (statusData) {
      return (
        <div>
          <Icon type={statusData.iconType} theme="twoTone" twoToneColor={statusData.twoToneColor} />
          <span className={styles.iconContent}>Status: {statusData.content}</span>
        </div>
      );
    }

    return null;
  };

  const consentStatusIconContent = getConsentStatusIconContent();

  const menu = (
    <Menu>
      <Menu.Item
        onClick={onClickToComposeEmailThroughChatGpt}
        className={styles.menuItems}
        sk-event-name={getEventNameByCandidateStatus(
          eventTypes.candidate.candidateConnect.useChatGptPromptForEmail,
          candidate
        )}
      >
        <div className={styles.icon}>
          <OpenAiIcon />
        </div>
        <div className={styles.textItems}>
          <div className={styles.normalContent}>
            <FormattedMessage {...message.generateEmailWithLabel} />
          </div>
          <div className={styles.textContent}>
            <FormattedMessage {...message.chatGptLabel} />
          </div>
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          onClickToComposeEmailManually(false);
        }}
        className={styles.menuItems}
      >
        <div className={styles.icon}>
          <ComposeEmailIcon />
        </div>
        <div className={styles.textItems}>
          <div className={styles.normalContent}>
            <FormattedMessage {...message.composeEmailWithLabel} />
          </div>
          <div className={styles.textContent}>
            <FormattedMessage {...message.existingTemplatesLabel} />
          </div>
        </div>
      </Menu.Item>
      {consentStatusIconContent !== null && (
        <Menu.Item className={styles.constentMenuItem}>{consentStatusIconContent}</Menu.Item>
      )}
    </Menu>
  );

  return isCandidateViewIconVisible && dropdownVisibility ? (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
      onMouseLeave={() => setDropdownVisibility(false)}
    >
      <Popover
        content={menu}
        visible={dropdownVisibility}
        overlayClassName={styles.suggestionsDropdown}
        onVisibleChange={setDropdownVisibility}
      >
        <a
          onClick={e => e.preventDefault()}
          style={{
            visibility: 'hidden',
            position: 'absolute',
            top: `${composeEmailDropdownRef?.current?.offsetTop}px`,
            left: `${composeEmailDropdownRef?.current?.offsetLeft + 15}px`,
          }}
        >
          i
        </a>
      </Popover>
    </div>
  ) : null;
};

export default ComposeEmailDropdown;
