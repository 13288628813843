import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import styles from './ManualSearchCandidatesFetchModal.module.scss';
import { getSourceName } from '../../../Utils/SourceUtils';
import { getActiveSourceName, getActiveTab } from '../../../Reducers/JobCandidatesTabReducer';
import { getManualSearchPayload } from '../../../Reducers/ManualSearchReducer';
import {
  getManualSearchCandidatesFetchApiStatuses,
  getFetchedCandidatesCountBySources,
} from '../../../Reducers/ManualSearchCandidateReducer';
import { getWhiteLabelInformation, getConfig } from '../../../Reducers/ConfigReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import useSeconds from '../../../Hooks/useSeconds';
import ManualSearchSourceProgressLoader from './ManualSearchSourceProgressLoader';
import message from '../ManualSearchMessages';

const mapStateToProps = (state, props) => {
  return {
    manualSearchPayload: getManualSearchPayload(state, { jobId: props.jobId }),
    manualSearchCandidatesFetchApiStatuses: getManualSearchCandidatesFetchApiStatuses(state, props.jobId),
    allTabCandidatesFetchApi: getApiStatus(state, 'allTabCandidatesFetchApi'),
    activeSourceName: getActiveSourceName(state),
    fetchedCandidateCountBySource: getFetchedCandidatesCountBySources(state, props.jobId),
    whiteLabelInfo: getWhiteLabelInformation(state),
    activeTab: getActiveTab(state),
    userConfig: getConfig(state),
  };
};

function ManualSearchCandidatesFetchModal(props) {
  const {
    visible,
    onViewResults,
    manualSearchPayload,
    manualSearchCandidatesFetchApiStatuses,
    allTabCandidatesFetchApi,
    whiteLabelInfo,
    setManualSearchCandidatesFetchModalVisibility,
    fetchedCandidateCountBySource,
    setJobCandidateTabActiveSourceName,
    candidateContext,
    setManualSearchActiveSource,
    jobId,
    isNonInternalPortalForSegmentEnabled,
    setSearchPerformed,
    activeTab,
    userConfig,
    version,
    pageName,
    isAtsCandidateFetchModalVisible,
  } = props;

  const { seconds } = useSeconds({ isActive: allTabCandidatesFetchApi === 'INPROGRESS' });
  const [isFetched, setIsFetched] = useState(false);

  const AppName = whiteLabelInfo?.AppName ?? 'Arya';

  const { Sources: sources } = manualSearchPayload;

  React.useEffect(() => {
    if (allTabCandidatesFetchApi === 'COMPLETED') onViewResults(!isFetched);
  }, [allTabCandidatesFetchApi]);

  const maximumModalVisibilityTimeLimit = 2;

  const isManualSearchCandidateFetchRequestSuccessful = (sourceName, candidateCount) => {
    const isFetchRequestSuccessful =
      manualSearchCandidatesFetchApiStatuses[sourceName] === 'COMPLETED' && candidateCount > 0;

    const isMaximumModalVisibilityTimeLimitExceeded = seconds >= maximumModalVisibilityTimeLimit;
    return (
      isFetchRequestSuccessful &&
      isMaximumModalVisibilityTimeLimitExceeded &&
      (candidateContext === 'job' || isNonInternalPortalForSegmentEnabled)
    );
  };

  React.useEffect(() => {
    for (let i = 0; i < sources?.length; i += 1) {
      const sourceName = getSourceName(sources[i]);
      const candidateCount = fetchedCandidateCountBySource[sourceName] ?? 0;
      if (isManualSearchCandidateFetchRequestSuccessful(sourceName, candidateCount)) {
        setManualSearchCandidatesFetchModalVisibility(false);
        if (!isFetched && seconds >= maximumModalVisibilityTimeLimit) {
          setIsFetched(true);
          setSearchPerformed(true);
          setJobCandidateTabActiveSourceName({ activeSourceName: sourceName });
          setManualSearchActiveSource({ manualSearchActiveSource: sources[i] });
          break;
        }
      }
    }
  }, [seconds]);

  const isSourcedTab = activeTab === 'sourced';

  const isCandidateFetchModalVisible =
    version === 'ats' ? visible && isAtsCandidateFetchModalVisible : visible && isSourcedTab;

  return (
    <div>
      <Modal visible={isCandidateFetchModalVisible} footer={null} maskClosable={false} closable={false}>
        <div className={styles.content}>
          <div className={styles.title}>
            <FormattedMessage {...message.manualSearchSourcesFetchingLabel} values={{ AppName }} />
          </div>
          <div className={styles.sources}>
            {sources?.map(source => {
              const sourceName = getSourceName(source);
              return (
                <ManualSearchSourceProgressLoader
                  jobId={jobId}
                  key={sourceName}
                  source={source}
                  userConfig={userConfig}
                />
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps)(ManualSearchCandidatesFetchModal);
