import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Menu, Checkbox, Icon } from 'antd';
import _ from 'lodash';
import classNames from 'classnames';
import MinMaxExperienceWithoutForm from '../MinMaxExperienceWithoutForm/MinMaxExperienceWithoutForm';
import styles from './CandidateJobsFilter.module.scss';
import Candidate360TabFilterPopover from './Candidate360TabFilterPopover';
import {
  getMatchingJobsTitleDisplayOptions,
  getMatchingJobSimilarTitles,
} from '../../Utils/CandidateJobsMatchingFilterUtils';
import { removeCommaFromEnd } from '../../Utils/TextUtils';
import message from '../../Containers/CandidateDrawer/messages';

function CandidateJobsMatchingFilter(props) {
  const {
    candidate,
    fetchCandidateMatchingJobs,
    searchTerm,
    setPageNumber,
    pageSize,
    matchingJobsAggregations,
    selectedMatchingJobLocationFilterOptions,
    selectedMatchingJobTitleFilterOptions,
    setCandidateMatchingJobFilters,
    selectedMatchingJobIndustryFilterOptions,
    selectedMatchingJobMinExperienceFilterOptions,
    isFilterPopoverVisible,
    setIsFilterPopoverVisbility,
    setFilterOptionsGroup,
    candidateJobMatchingfilters,
    onClearFilter,
    selectedFilterOptionsGroup,
    minExperience,
    setMinExperience,
    maxExperience,
    setMaxExperience,
    isFilterSelected,
    countries = [],
    candidateCountry,
  } = props;

  const matchingJobLocationsFilters = matchingJobsAggregations?.Locations;
  const matchingJobIndustriesFilters = matchingJobsAggregations?.Industries;
  const matchingJobTitlesDisplayOptions = getMatchingJobsTitleDisplayOptions(matchingJobsAggregations?.Titles);

  const candidateCountryName = countries.find(country => country.Iso2Code === candidateCountry)?.Name;

  const handleVisibleChange = newVisible => {
    setIsFilterPopoverVisbility(newVisible);
  };

  const handleMatchingJobLocationsOptionChange = event => {
    const { value } = event.target;
    let updatedJobLocationFilterOptions;
    if (selectedMatchingJobLocationFilterOptions?.includes(value)) {
      updatedJobLocationFilterOptions = selectedMatchingJobLocationFilterOptions.filter(x => x !== value);
    } else {
      updatedJobLocationFilterOptions = [...selectedMatchingJobLocationFilterOptions, value];
    }
    setCandidateMatchingJobFilters('Location', updatedJobLocationFilterOptions);
  };
  const handleMatchingJobIndustriesOptionChange = event => {
    const { value } = event.target;
    let updatedJobIndustryFilterOptions;
    if (selectedMatchingJobIndustryFilterOptions?.includes(value)) {
      updatedJobIndustryFilterOptions = selectedMatchingJobIndustryFilterOptions.filter(x => x !== value);
    } else {
      updatedJobIndustryFilterOptions = [...selectedMatchingJobIndustryFilterOptions, value];
    }
    setCandidateMatchingJobFilters('Industry', updatedJobIndustryFilterOptions);
  };
  const handleMatchingJobTitlesOptionChange = event => {
    const { value } = event.target;
    let updatedJobTitleFilterOptions;
    const matchingJobSimilarTitles = getMatchingJobSimilarTitles({
      selectedJobTitleFilterOption: value,
      matchingJobTitleFilterOptions: matchingJobTitlesDisplayOptions,
    });
    const isSimilarJobTitleExists = selectedMatchingJobTitleFilterOptions.some(selectedMatchingJobTitleFilterOption =>
      matchingJobSimilarTitles.includes(selectedMatchingJobTitleFilterOption)
    );
    if (isSimilarJobTitleExists) {
      updatedJobTitleFilterOptions = selectedMatchingJobTitleFilterOptions.filter(
        selectedMatchingJobTitleFilterOption => !matchingJobSimilarTitles.includes(selectedMatchingJobTitleFilterOption)
      );
    } else {
      updatedJobTitleFilterOptions = [...selectedMatchingJobTitleFilterOptions, ...matchingJobSimilarTitles];
    }
    setCandidateMatchingJobFilters('JobTitle', updatedJobTitleFilterOptions);
  };
  const onApplyFilter = () => {
    setPageNumber(1);
    fetchCandidateMatchingJobs({
      candidateId: candidate.Id,
      size: pageSize,
      from: 0,
      searchTerm,
      location: selectedMatchingJobLocationFilterOptions,
      candidateExperience: selectedMatchingJobMinExperienceFilterOptions,
      industries: selectedMatchingJobIndustryFilterOptions,
      jobStatuses: [],
      excludeJobStatuses: [],
      titles: selectedMatchingJobTitleFilterOptions,
      skills: candidate.Skills?.slice(0, 200),
      countryCodes: candidate.Country,
      candidateTitle: candidate.Title,
    });
    setIsFilterPopoverVisbility(false);
  };

  const handleMatchingJobMinExperienceOptionChange = value => {
    setCandidateMatchingJobFilters('MinExperience', value);
  };
  const handleMatchingJobMaxExperienceOptionChange = value => {
    setCandidateMatchingJobFilters('MaxExperience', value);
  };

  const getLocation = location => {
    if (location) return `${_.capitalize(removeCommaFromEnd(location))},`;
    return '';
  };

  const matchingJobLocationFilterOptions = matchingJobLocationsFilters?.length ? (
    <Checkbox.Group
      className={styles.filterCheckboxGroup}
      key="jobLocationFilter"
      value={selectedMatchingJobLocationFilterOptions}
    >
      {matchingJobLocationsFilters?.map(entry => (
        <div>
          {' '}
          <Checkbox
            value={entry.Value}
            onChange={handleMatchingJobLocationsOptionChange}
            data-testid="location-checkbox"
          >
            {`${getLocation(entry.Value)} ${candidateCountryName} (${entry.Count})`}
          </Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  ) : (
    <div className={styles.noMatchingFilters}>
      <FormattedMessage {...message.noLocationsAvailableLabel} />
    </div>
  );

  const matchingJobIndustryFilterOptions = matchingJobIndustriesFilters?.length ? (
    <Checkbox.Group
      key="jobIndustryFilter"
      className={styles.filterCheckboxGroup}
      value={selectedMatchingJobIndustryFilterOptions}
    >
      {' '}
      {matchingJobIndustriesFilters?.map(entry => (
        <div>
          {' '}
          <Checkbox
            value={entry.Value}
            onChange={handleMatchingJobIndustriesOptionChange}
            data-testid="industry-checkbox"
          >
            {`${_.capitalize(entry.Value)} (${entry.Count})`}
          </Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  ) : (
    <div className={styles.noMatchingFilters}>
      <FormattedMessage {...message.noIndustriesAvailableLabel} />
    </div>
  );

  const matchingJobTitleFilterOptions = matchingJobTitlesDisplayOptions?.length ? (
    <Checkbox.Group
      key="jobTitleFilter"
      className={styles.filterCheckboxGroup}
      value={selectedMatchingJobTitleFilterOptions}
    >
      {' '}
      {matchingJobTitlesDisplayOptions?.map(entry => (
        <div>
          {' '}
          <Checkbox
            value={entry.similarTitles[0]}
            onChange={handleMatchingJobTitlesOptionChange}
            data-testid="title-checkbox"
          >
            {`${_.capitalize(entry.name)} (${entry.count})`}
          </Checkbox>
        </div>
      ))}
    </Checkbox.Group>
  ) : (
    <div className={styles.noMatchingFilters}>
      <FormattedMessage {...message.noTitlesAvailable} />
    </div>
  );

  const matchingJobExperienceFilterOptions = (
    <div className={styles.experienceWrapper}>
      <MinMaxExperienceWithoutForm
        inputStyle={styles}
        handleMatchingJobMinExperienceOptionChange={handleMatchingJobMinExperienceOptionChange}
        handleMatchingJobMaxExperienceOptionChange={handleMatchingJobMaxExperienceOptionChange}
        selectedMatchingJobMinExperienceFilterOptions={selectedMatchingJobMinExperienceFilterOptions}
        minExperience={minExperience}
        setMinExperience={setMinExperience}
        maxExperience={maxExperience}
        setMaxExperience={setMaxExperience}
        isMinExperienceVisible
        isMaxExperienceVisible={false}
      />
    </div>
  );

  const getFilterOptions = () => {
    switch (selectedFilterOptionsGroup) {
      case candidateJobMatchingfilters.location:
        return matchingJobLocationFilterOptions;
      case candidateJobMatchingfilters.industry:
        return matchingJobIndustryFilterOptions;
      case candidateJobMatchingfilters.title:
        return matchingJobTitleFilterOptions;
      case candidateJobMatchingfilters.experience:
        return matchingJobExperienceFilterOptions;
      default:
        return matchingJobTitleFilterOptions;
    }
  };
  const getIsFilterOptionSelected = selectedFilter => {
    return selectedFilterOptionsGroup === selectedFilter;
  };
  const filterContent = (
    <div className={styles.popoverContent}>
      <div style={{ display: 'flex' }}>
        <div className={styles.subFilter}>
          <Menu className={styles.menu} defaultSelectedKeys={candidateJobMatchingfilters.location}>
            <Menu.Item
              className={classNames(styles.menuItem, {
                [styles.selected]: getIsFilterOptionSelected(candidateJobMatchingfilters.title),
                [styles.noMatchingJob]: matchingJobTitlesDisplayOptions?.length === 0,
              })}
              key={candidateJobMatchingfilters.title}
              onClick={() => setFilterOptionsGroup(candidateJobMatchingfilters.title)}
            >
              <div className={styles.menuItemAndCollapseIcon}>
                <FormattedMessage {...message.titlesLabel} />
                {getIsFilterOptionSelected(candidateJobMatchingfilters.title) &&
                matchingJobTitlesDisplayOptions?.length !== 0 ? (
                  <Icon type="right" className={styles.collapseIcon} />
                ) : null}
              </div>
            </Menu.Item>
            <Menu.Item
              className={classNames(styles.menuItem, {
                [styles.selected]: getIsFilterOptionSelected(candidateJobMatchingfilters.location),
                [styles.noMatchingJob]: matchingJobLocationsFilters?.length === 0,
              })}
              key={candidateJobMatchingfilters.location}
              onClick={() => setFilterOptionsGroup(candidateJobMatchingfilters.location)}
            >
              <div className={styles.menuItemAndCollapseIcon}>
                <FormattedMessage {...message.locationLabel} />
                {getIsFilterOptionSelected(candidateJobMatchingfilters.location) &&
                matchingJobLocationsFilters?.length !== 0 ? (
                  <Icon type="right" className={styles.collapseIcon} />
                ) : null}
              </div>
            </Menu.Item>
            <Menu.Item
              className={classNames(styles.menuItem, {
                [styles.selected]: getIsFilterOptionSelected(candidateJobMatchingfilters.industry),
                [styles.noMatchingJob]: matchingJobIndustriesFilters?.length === 0,
              })}
              key={candidateJobMatchingfilters.industry}
              onClick={() => setFilterOptionsGroup(candidateJobMatchingfilters.industry)}
            >
              <div className={styles.menuItemAndCollapseIcon}>
                <FormattedMessage {...message.industryLabel} />
                {getIsFilterOptionSelected(candidateJobMatchingfilters.industry) &&
                matchingJobIndustriesFilters?.length !== 0 ? (
                  <Icon type="right" className={styles.collapseIcon} />
                ) : null}
              </div>
            </Menu.Item>
            <Menu.Item
              className={classNames(styles.menuItem, {
                [styles.selected]: getIsFilterOptionSelected(candidateJobMatchingfilters.experience),
              })}
              key={candidateJobMatchingfilters.experience}
              onClick={() => setFilterOptionsGroup(candidateJobMatchingfilters.experience)}
            >
              <div className={styles.menuItemAndCollapseIcon}>
                <FormattedMessage {...message.experienceLabel} />
                {getIsFilterOptionSelected(candidateJobMatchingfilters.experience) ? (
                  <Icon type="right" className={styles.collapseIcon} />
                ) : null}
              </div>
            </Menu.Item>
          </Menu>
        </div>
        <div className={styles.filterOptions}>{getFilterOptions()}</div>
      </div>
    </div>
  );

  return (
    <Candidate360TabFilterPopover
      content={filterContent}
      isFilterApplied={isFilterSelected}
      isFilterPopoverVisible={isFilterPopoverVisible}
      handleVisibleChange={handleVisibleChange}
      onClearFilter={onClearFilter}
      onApplyFilter={onApplyFilter}
    />
  );
}
export default CandidateJobsMatchingFilter;
