import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Icon, Button, Modal, notification, Radio, Tooltip, InputNumber } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import * as GsmCharsetUtils from '@trt2/gsm-charset-utils';
import './CandidateBulkActions.scss';
import store from '../../store';
import EmailComposer from '../../Components/EmailComposer/EmailComposer';
import { BulkShortlistModal } from '../../Components/BulkShortlistModal/BulkShortlistModal';
import MessageComposer from '../../Components/MessageComposer/MessageComposer';
import ContactPuller from '../../Components/ContactPuller/ContactPuller';
import BulkShortlistConfirmationModal from '../../Components/BulkShortlistModal/BulkShortlistConfirmationModal';
import SegmentBulkShortlistConfirmationModal from '../../Components/BulkShortlistModal/SegmentBulkShortlistConfirmationModal';
import SegmentBulkShortlistErrorModal from '../../Components/BulkShortlistModal/SegmentBulkShortlistErrorModal';
import * as CandidateActions from '../../Actions/CandidateActions';
import * as ConfigActions from '../../Actions/ConfigActions';
import { getEmailTemplate as _getEmailTemplate } from '../../Actions/EmailTemplates';
import { getTextTemplate as _getTextTemplate } from '../../Actions/TextTemplates';
import * as MergeTagsReducer from '../../Reducers/MergeTagsReducer';
import * as ApiStatusReducer from '../../Reducers/ApiStatusReducer';
import { getJobsById, getSourcingStats } from '../../Reducers/JobReducer';
import { getActualSourcingStats } from '../../Utils/JobSourcingStatsUtil';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as JobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import * as CandidateAdvanceFilterActions from '../../Actions/CandidateAdvanceFiltersActions';
import { getFilteredUserEmails, getSignatures, getAdminFeatures } from '../../Reducers/UserReducer';
import {
  getcandidateDownloadFilterConfig,
  getcandidateCommunicationFilterConfig,
  getConfig,
  getConnectConfig,
} from '../../Reducers/ConfigReducer';
import { getCandidatesConnectInfo } from '../../Reducers/ConnectReducer';
import {
  getConnectSettings,
  getEmailSmtpConfiguration,
  getOrgDefaultEmail,
} from '../../Reducers/ConnectSettingsReducer';
import { getBulkOrderStatuses } from '../../Reducers/OrderReducer';
import { getCandidates, getFilterContextId } from '../../Reducers/CandidateReducer';
import { getEmailTemplatesById, getEmailTemplatesCount, getEmailTemplateIds } from '../../Reducers/EmailTemplates';
import { getTextTemplatesById, getTextTemplatesCount, getTextTemplateIds } from '../../Reducers/TextTemplates';
import { getDripTemplatesById } from '../../Reducers/DripTemplates';
import { EmailIcon, TextIcon, ContactPullIcon } from '../../Icons/AryaIcons';
import DeleteIconV2 from '../../Icons/SegmentCandidateDeleteIconV2';
import AddIconV2 from '../../Icons/SegmentCandidateAddIconV2';
import * as EmailTemplatesActions from '../../Actions/EmailTemplates';
import {
  fetchSubSegments,
  setSubsegmentCreateModalStatus,
  setSubsegmentDeleteModalStatus,
  setSubsegmentAddModalStatus,
  setCreatedSubSegmentId as _setCreatedSubSegmentId,
} from '../../Actions/SubSegmentActions';
import * as MessageTemplatesActions from '../../Actions/TextTemplates';
import * as DripTemplatesActions from '../../Actions/DripTemplates';
import { getCurrentUser, getImpersonatedUserDetails } from '../../Reducers/UserSessionReducer';
import {
  getSourceName,
  getCandidateSource,
  AryaPortals,
  JobboardsWithoutCandidateContact,
} from '../../Utils/SourceUtils';
import { getCountableNounForm } from '../../Utils/TextUtils';
import { isPulseUser } from '../../Utils/ConfigUtils';
import { parseHtmlStringFromEditor } from '../../Utils/DomParserUtils';
import { getCurrentSelectedFromEmail } from '../../Utils/UserInfo';
import { getEmailTemplateDetails } from '../../Utils/EmailTemplateUtil';
import * as AryaPayReducer from '../../Reducers/AryaPayReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getFilteredMergeTags, getEmailMergeTags } from '../../Utils/MergeTagsUtils';
import { validateTemplate } from '../../Utils/Validators';
import { getConsentComplianceInfo } from '../../Utils/ConnectUtils';
import { BulkShortlistCreditInfoAlertStatus, getStorageData } from '../../Utils/LocalStorageUtils';
import {
  getProviderLinkedEmails,
  getIsLinkedEmailsTestSuccesful,
  getSuccessfulTestedEmailList,
} from '../../Utils/ContactUtils';
import {
  getSubSegmentListofSegment,
  getDeleteModalVisbilityStatus,
  getAddModalVisbilityStatus,
  getLastCreatedSubSegmentId,
  getSelectedSubsegmentId,
} from '../../Reducers/SubsegmentReducer';
import { appendCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../Analytics/EventTypes';
import { isPublishIconDisplayAllowed, getIsSelectThisPageChecked } from '../../Utils/CandidateListUtils';
import CandidateTransfer from '../../Components/CandidateTransfer/CandidateTransfer';
import { getAllNonGroupHeadDuplicateCandidateIds } from '../../Reducers/ManualSearchCandidateReducer';
import { getCreateCampaignApiStatus } from '../../Reducers/CandidateAdvanceFiltersReducer';
import { removeUnicodeQuotation, checkForJunkWordsInText, parseTextForJunkDetection } from '../../Components/Utils/SmsUtils';
import AddCandidateToSegmentPopover from '../SubSegment/AddCandidateToSegmentModal';
import DeleteCandidateSegmentModal from '../SubSegment/DeleteCandidateSegmentModal';
import PublishButton from '../../Components/PublishButtonComponent/PublishButton';
import { getCandidatePublishStatus } from '../../Utils/CandidatePublishUtils';
import message from './CandidateBulkActionsContainerMessage';


const debouncedCheckForJunkWordsInText = _.debounce((plainTextContent, setter, junkWordsSetter) => {
  const { hasJunkWords, junkWords } = checkForJunkWordsInText(plainTextContent)
  setter(hasJunkWords);
  junkWordsSetter(junkWords)
}, 600)


const mapStateToProps = (state, props) => {
  const emailSmtpConfiguration = getEmailSmtpConfiguration(state);
  return {
    availableCredits: AryaPayReducer.getAvailableCredits(state),
    orderStatuses: getBulkOrderStatuses(state),
    mergeTags: MergeTagsReducer.getMergeTags(state),
    bulkShortlistApiStatus: ApiStatusReducer.getApiStatus(state, 'bulkShortlistApiStatus'),
    bulkRejectionApiStatus: ApiStatusReducer.getApiStatus(state, 'bulkRejectionApiStatus'),
    fetchSubSegmentApiStatus: ApiStatusReducer.getApiStatus(state, 'fetchSubSegmentApiStatus'),
    userConfig: getConfig(state),
    userConnectConfig: getConnectConfig(state),
    emailTemplateIds: getEmailTemplateIds(state),
    dripTemplatesById: getDripTemplatesById(state),
    messageTemplateIds: getTextTemplateIds(state),
    emailTemplatesById: getEmailTemplatesById(state),
    messageTemplatesById: getTextTemplatesById(state),
    emailTemplatesCount: getEmailTemplatesCount(state),
    messageTemplatesCount: getTextTemplatesCount(state),
    currentUser: getCurrentUser(state),
    impersonatedUser: getImpersonatedUserDetails(state),
    jobsById: getJobsById(state),
    candidatesById: getCandidates(state),
    sourcingStats: getSourcingStats(state, props.jobId),
    emailSmtpConfiguration,
    userEmails: getFilteredUserEmails(state),
    orgDefaultEmail: getOrgDefaultEmail(state),
    signatures: getSignatures(state),
    connectStatuses: getCandidatesConnectInfo(state),
    connectSettings: getConnectSettings(state),
    featureToggleList: getFeatureToggleList(state),
    candidateDownloadFilterConfig: getcandidateDownloadFilterConfig(state),
    candidateCommunicationFilterConfig: getcandidateCommunicationFilterConfig(state),
    duplicateCandidateIds: getAllNonGroupHeadDuplicateCandidateIds(state, props.jobId),
    subsegmentList: getSubSegmentListofSegment(state, props.jobId),
    deleteSubsegmentModalStatus: getDeleteModalVisbilityStatus(state),
    addSubsegmentModalStatus: getAddModalVisbilityStatus(state),
    filterContextId: getFilterContextId(state),
    campaignCreationStatus: getCreateCampaignApiStatus(state),
    adminFeatures: getAdminFeatures(state),
    candidatePublishApiStatus: ApiStatusReducer.getCandidatePublishApiStatus(state),
    lastCreatedCandidateId: getLastCreatedSubSegmentId(state),
    selectedSubSegmentId: getSelectedSubsegmentId(state),
  };
};

const mapDispatchToProps = {
  fetchBulkContacts: CandidateActions.fetchBulkContacts,
  bulkCandidateRemove: CandidateActions.bulkCandidateRemove,
  bulkCandidateShortlist: CandidateActions.bulkCandidateShortlist,
  sendBulkMails: CandidateActions.sendBulkMails,
  sendBulkMessages: CandidateActions.sendBulkMessages,
  bulkCandidateRecommend: CandidateActions.bulkCandidateRecommend,
  fetchEmailTemplates: EmailTemplatesActions.searchEmailTemplates,
  fetchMessageTemplates: MessageTemplatesActions.searchTextTemplates,
  getDripTemplate: DripTemplatesActions.getDripTemplate,
  bulkPublishCandidates: CandidateActions.bulkPublishCandidates,
  updateBulkConnectStatus: CandidateActions.updateBulkConnectStatus,
  fetchUserEmails: ConnectActions.fetchUserEmails,
  getEmailSignatures: ConnectActions.getEmailSignatures,
  getEmailTemplate: _getEmailTemplate,
  getTextTemplate: _getTextTemplate,
  setInvalidTemplateNotification: ConnectActions.setInvalidTemplateNotification,
  fetchCandidateDownloadFilterConfig: ConfigActions.fetchCandidateDownloadFilterConfig,
  fetchCandidateCommunicateFilterConfig: ConfigActions.fetchCandidateCommunicateFilterConfig,
  createCampaign: CandidateAdvanceFilterActions.createCampaign,
  runCampaign: CandidateAdvanceFilterActions.runCampaign,
  bulkSegmentCandidateRecommend: CandidateActions.bulkSegmentCandidateRecommend,
  setSegmentBulkRecommendErrorMessage: JobCandidatesTabActions.setSegmentBulkRecommendErrorMessage,
  fetchSubSegmentsAction: fetchSubSegments,
  setSubsegmentDeleteModalStatusAction: setSubsegmentDeleteModalStatus,
  setSubsegmentModalStatus: setSubsegmentCreateModalStatus,
  setAddSubsegmentModalStatus: setSubsegmentAddModalStatus,
  publishedCandidates: CandidateActions.publishedCandidates,
  setSelectedCandidates: JobCandidatesTabActions.setSelectedCandidates,
  setSelectedActiveCandidateCount: JobCandidatesTabActions.setSelectedActiveCandidateCount,
  setCreatedSubSegmentId: _setCreatedSubSegmentId,
};

export function FinalPublishContent({ isFinalCommit, onChangeFinalCommit }) {
  return (
    <>
      <p>
        <FormattedMessage {...message.finalPublishContent_ClientCandidateListLabel} />
      </p>
      <p>
        <span className="noticeSubTitle">
          <FormattedMessage {...message.finalPublishContentNoteLabel} />
        </span>
        <FormattedMessage {...message.finalPublishContent_SRRejectedCandidatesLabel} />
      </p>
      <div style={{ display: 'flex' }}>
        <p style={{ marginRight: '12px', fontWeight: 'bold' }}>
          <FormattedMessage {...message.finalPublishContentFinalPublishLabel} />{' '}
        </p>
        <Radio.Group onChange={onChangeFinalCommit} defaultValue={isFinalCommit} size="small">
          <Radio.Button value>
            <FormattedMessage {...message.finalPublishContentYesLabel} />{' '}
          </Radio.Button>
          <Radio.Button value={false}>
            <FormattedMessage {...message.finalPublishContentNoLabel} />{' '}
          </Radio.Button>
        </Radio.Group>
      </div>
    </>
  );
}

let initialRenderFlag = false;
const isPulse = isPulseUser();

class CandidateBulkActionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMailInputContent: null,
      mailPreHeader: null,
      selectedFromEmail: undefined,
      isFinalCommit: false,
      isCandidateTransferModalVisible: false,
      preHeaderVisibility: false,
      createCampaignTitle: undefined,
      isCreateCampaign: false,
      campaignId: undefined,
      isSelectCustom: false,
      customSelectNumber: undefined,
      customInputValidationError: undefined,
      segmentBulkRejectApiStatus: undefined,
      segmentShortListedApiStatus: undefined,
      segmentModalVisibility: false,
      includeAlreadyAssociatedCandidates: false,
      tooltipVisibility: false,
      isJunkWordFound: false,
      junkWords: []
    };
    this.handleContactPull = this.handleContactPull.bind(this);
    this.showContactPullerModal = this.showContactPullerModal.bind(this);
    this.onFetchContacts = this.onFetchContacts.bind(this);
    this.handleContactPullCancel = this.handleContactPullCancel.bind(this);
    this.showEmailComposerModal = this.showEmailComposerModal.bind(this);
    this.handleEmailComposerOk = this.handleEmailComposerOk.bind(this);
    this.handleEmailComposerCancel = this.handleEmailComposerCancel.bind(this);
    this.onSendMail = this.onSendMail.bind(this);
    this.onUpdateMailContent = this.onUpdateMailContent.bind(this);
    this.onUpdateEmailSubject = this.onUpdateEmailSubject.bind(this);
    this.onEmailTemplateSelect = this.onEmailTemplateSelect.bind(this);
    this.showMessageComposerModal = this.showMessageComposerModal.bind(this);
    this.handleMessageComposerOk = this.handleMessageComposerOk.bind(this);
    this.handleMessageComposerCancel = this.handleMessageComposerCancel.bind(this);
    this.onSendNewMessage = this.onSendNewMessage.bind(this);
    this.onMessageTemplateSelect = this.onMessageTemplateSelect.bind(this);
    this.onUpdateMessageContent = this.onUpdateMessageContent.bind(this);
    this.onAllPageCheckboxClick = this.onAllPageCheckboxClick.bind(this);
    this.onCurrentPageCheckboxClick = this.onCurrentPageCheckboxClick.bind(this);
    this.bulkCandidateShortlist = this.bulkCandidateShortlist.bind(this);
    this.fetchEmailTemplates = this.fetchEmailTemplates.bind(this);
    this.fetchMessageTemplates = this.fetchMessageTemplates.bind(this);
    this.onCloseCreditInfoAlert = this.onCloseCreditInfoAlert.bind(this);
    this.onChangeFromEmail = this.onChangeFromEmail.bind(this);
    this.showBulkShortlistModal = this.showBulkShortlistModal.bind(this);
    this.onBulkShortlist = this.onBulkShortlist.bind(this);
    this.onBulkShortlistCreditInfoAlertClose = this.onBulkShortlistCreditInfoAlertClose.bind(this);
    this.getEngagedCandidateBucket = this.getEngagedCandidateBucket.bind(this);
    this.handleBulkTranferIcon = this.handleBulkTranferIcon.bind(this);
  }

  componentDidMount() {
    const {
      fetchEmailTemplates,
      fetchMessageTemplates,
      isBulkEmailEnabled,
      isBulkMessageEnabled,
      fetchUserEmails,
      getEmailSignatures,
      candidateDownloadFilterConfig,
      candidateCommunicationFilterConfig,
      fetchCandidateDownloadFilterConfig,
      fetchCandidateCommunicateFilterConfig,
    } = this.props;
    if (!candidateDownloadFilterConfig) {
      fetchCandidateDownloadFilterConfig();
    }
    if (!candidateCommunicationFilterConfig) {
      fetchCandidateCommunicateFilterConfig();
    }
    if (!initialRenderFlag) {
      fetchUserEmails({ testEmailStatus: true });
      getEmailSignatures();
      if (isBulkMessageEnabled) {
        fetchMessageTemplates({ From: 0, Size: 10, IsConsent: true }, true);
      }
      if (isBulkEmailEnabled) {
        fetchEmailTemplates({ From: 0, Size: 10, IncludeSystemTemplate: !isPulse, IncludeDrip: true }, true);
      }

      initialRenderFlag = true;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { messageTemplatesById, dripTemplatesById, emailTemplatesById } = this.props;
    const { templateId, messgaeTemplateId, segmentShortListedApiStatus, segmentBulkRejectApiStatus } = this.state;
    const {
      segmentShortListedApiStatus: prevSegmentShortListedApiStatus,
      segmentBulkRejectApiStatus: prevSegmentBulkRejectApiStatus,
    } = prevState;
    const prevBulkShortlistApiStatus = _.get(prevProps, 'bulkShortlistApiStatus', '');
    const nextBulkShortlistApiStatus = _.get(this.props, 'bulkShortlistApiStatus', '');
    const apiStatusPairs = [
      [prevBulkShortlistApiStatus, nextBulkShortlistApiStatus],
      [prevSegmentShortListedApiStatus, segmentShortListedApiStatus],
      [prevSegmentBulkRejectApiStatus, segmentBulkRejectApiStatus],
    ];
    apiStatusPairs.forEach(([prevStatus, currentStatus]) => {
      this.resetOnCompletedStatus(prevStatus, currentStatus);
    });
    if (templateId) {
      const prevEmailTemplate = _.get(
        prevProps.dripTemplatesById,
        templateId,
        _.get(prevProps.emailTemplatesById, templateId, {})
      );
      const currentEmailTemplate = _.get(dripTemplatesById, templateId, _.get(emailTemplatesById, templateId, {}));
      if (!_.isEqual(prevEmailTemplate, currentEmailTemplate)) {
        const emailTemplateDetails = getEmailTemplateDetails(templateId, emailTemplatesById, dripTemplatesById);
        this.updateEmailTemplateDetails(emailTemplateDetails);
      }
    }

    if (messgaeTemplateId) {
      const selectedTemplateFromPrevProps = prevProps?.messageTemplatesById?.[messgaeTemplateId] ?? {};
      const selectedTemplateFromCurrentProps = messageTemplatesById?.[messgaeTemplateId] ?? {};
      if (!_.isEqual(selectedTemplateFromPrevProps, selectedTemplateFromCurrentProps)) {
        this.updateMessageTemplateDetails(selectedTemplateFromCurrentProps);
      }
    }
  }

  resetOnCompletedStatus = (prevStatus, currentStatus) => {
    const { resetMultiSelect } = this.props;
    const { isSelectCustom } = this.state;
    if (prevStatus !== 'COMPLETED' && currentStatus === 'COMPLETED') {
      resetMultiSelect();
      if (isSelectCustom) {
        this.setState({ isSelectCustom: undefined, customSelectNumber: undefined });
      }
    }
  };

  updateEmailTemplateDetails = emailTemplateDetails => {
    const { Body, Subject, EditorUsed, PreHeader } = emailTemplateDetails;
    this.setState({
      currentMailInputContent: Body,
      mailSubject: Subject,
      mailPreHeader: PreHeader,
      preHeaderVisibility: PreHeader ?? false,
      editorUsed: EditorUsed,
    });
  };

  updateMessageTemplateDetails = messageTemplateDetails => {
    this.setState({
      currentMessageInputContent: _.get(messageTemplateDetails, 'Body', null),
      isConsent: _.get(messageTemplateDetails, 'IsConsent', false),
    });
  };

  fetchEmailTemplates(filter) {
    const { fetchEmailTemplates } = this.props;
    const filterWithDripTemplates = {
      ...filter,
      IncludeSystemTemplate: !isPulse,
      IncludeDrip: true,
    };
    fetchEmailTemplates(filterWithDripTemplates, true);
  }

  fetchMessageTemplates(filter) {
    const { fetchMessageTemplates } = this.props;
    const filterWithConsent = {
      ...filter,
      IsConsent: true,
    };
    fetchMessageTemplates(filterWithConsent, true);
  }

  showEmailComposerModal() {
    this.setState({
      emailComposerVisible: true,
    });
  }

  handleEmailComposerOk() {
    this.setState({
      emailComposerVisible: false,
    });
  }

  handleEmailComposerCancel() {
    this.setState({
      emailComposerVisible: false,
      createCampaignTitle: undefined,
      isCreateCampaign: false,
    });
  }

  showBulkShortlistModal() {
    this.setState({
      bulkShortlistModalVisible: true,
    });
  }

  handleBulkShortlistModalCancel = () => {
    this.setState({
      bulkShortlistModalVisible: false,
    });
  };

  showContactPullerModal() {
    const { selectedCandidates } = this.props;
    this.setState({
      contactPullerVisible: true,
      candidateCount: selectedCandidates.length,
    });
  }

  setCampaignId = id => {
    this.setState({ campaignId: id });
  };

  getIsJobBoardSourcePresent = () => {
    const { userConfig } = this.props;
    const nonJobBoardPortals = [...AryaPortals, ...JobboardsWithoutCandidateContact];
    return userConfig?.Sources?.find(
      sourceConfig =>
        !nonJobBoardPortals.includes(sourceConfig?.Source?.Portal?.toLowerCase()) && !sourceConfig?.Source?.Group
    );
  };

  handleContactPull() {
    const { availableCredits, eventCallback } = this.props;
    const availableCreditValue = availableCredits?.Arya.AvailableCredits;
    if (availableCreditValue < 1) {
      const isJobBoardSourcePresent = this.getIsJobBoardSourcePresent();
      if (!isJobBoardSourcePresent) {
        eventCallback.onNotEnoughCredit(true);
      } else {
        this.showContactPullerModal();
      }
    } else {
      this.showContactPullerModal();
    }
  }

  getDeleteV2Icon = (isBulkIconActivated, activatedBulkOptionIconClassName) => {
    const { activeTab, setSubsegmentDeleteModalStatusAction } = this.props;
    const handleOnClick = e => {
      if (activeTab === 'subsegment' && isBulkIconActivated) {
        setSubsegmentDeleteModalStatusAction(true);
      } else {
        this.handleDeleteButtonClick(e, 'Rejected', isBulkIconActivated);
      }
    };
    return (
      <Tooltip title="Remove">
        <DeleteIconV2
          disabled={!isBulkIconActivated}
          onClick={handleOnClick}
          className={`bulkActionIcon ${activatedBulkOptionIconClassName}`}
        />
      </Tooltip>
    );
  };

  handleOnCancel = () => {
    this.setState({ segmentModalVisibility: false, includeAlreadyAssociatedCandidates: false });
  };

  onCheckboxChange = e => {
    this.setState({ includeAlreadyAssociatedCandidates: e.target.checked ? ['Rejected'] : false });
  };

  onSegmentModalSubmit = (e, isBulkIconActivated, subsegmentId) => {
    this.handleAddButtonClick(e, 'Shortlisted', isBulkIconActivated, subsegmentId);
    this.setState({ segmentModalVisibility: false, includeAlreadyAssociatedCandidates: false });
  };

  onSegmentErrorModalSubmit = (e, isBulkIconActivated) => {
    this.handleAddButtonClick(e, 'Shortlisted', isBulkIconActivated);
  };

  onSegmentModalCancel = () => {
    this.setState({ segmentModalVisibility: false });
  };

  onTooltipFocus = () => {
    const { segmentModalVisibility } = this.state;
    if (!segmentModalVisibility) this.setState({ tooltipVisibility: true });
  };

  onTooltipRemove = () => {
    this.setState({ tooltipVisibility: false });
  };

  getAddV2Icon = (isBulkIconActivated, activatedBulkOptionIconClassName) => {
    const { tooltipVisibility } = this.state;
    const { setAddSubsegmentModalStatus } = this.props;
    return (
      <Tooltip
        title="Add"
        onMouseEnter={this.onTooltipFocus}
        onClick={this.onTooltipRemove}
        onMouseLeave={this.onTooltipRemove}
        visible={tooltipVisibility}
        disabled={isBulkIconActivated}
      >
        <AddIconV2
          onClick={() => {
            if (isBulkIconActivated) setAddSubsegmentModalStatus(true, { singleCandidateAdd: false });
          }}
          className={`bulkActionIcon ${activatedBulkOptionIconClassName}`}
          style={{ fontSize: '24px' }}
        />
      </Tooltip>
    );
  };

  handleDeleteButton = (isBulkIconActivated, activatedBulkOptionIconClassName) => {
    const { isBulkDeleteAvailable, bulkRejectionApiStatus, candidateListType } = this.props;
    const { segmentBulkRejectApiStatus } = this.state;
    if (candidateListType?.toLowerCase() === 'manualsearch') {
      return null;
    }
    if (
      isBulkDeleteAvailable &&
      segmentBulkRejectApiStatus !== 'INPROGRESS' &&
      bulkRejectionApiStatus !== 'INPROGRESS'
    ) {
      return this.getDeleteV2Icon(isBulkIconActivated, activatedBulkOptionIconClassName);
    }

    return null;
  };

  handleAddButton = (isBulkIconActivated, activatedBulkOptionIconClassName, isAddIconloading) => {
    const { isBulkAddAvailable, bulkShortlistApiStatus } = this.props;
    const { segmentShortListedApiStatus } = this.state;
    if (isAddIconloading) {
      return <Icon type="loading" className={`bulkActionIcon ${activatedBulkOptionIconClassName}`} />;
    }
    if (isBulkAddAvailable && segmentShortListedApiStatus !== 'INPROGRESS' && bulkShortlistApiStatus !== 'INPROGRESS') {
      return this.getAddV2Icon(isBulkIconActivated, activatedBulkOptionIconClassName);
    }
    return null;
  };

  handleContactPullCancel() {
    this.setState({
      contactPullerVisible: false,
    });
  }

  handleBulkShortListIconCancel = () => {
    this.setState({
      bulkShortlistConfirmationModalVisibility: false,
    });
  };

  onClickCreateCampaign = value => {
    this.setState({
      isCreateCampaign: value,
    });
  };

  handleCampaignTitle = value => {
    this.setState({
      createCampaignTitle: value,
    });
  };

  handleSubsegmentDelete = (e, subsegmentPayload) => {
    if (Object.keys(subsegmentPayload).length > 0) this.onSegmentBulkReject(e, subsegmentPayload);
  };

  onFetchContacts(excludeCandidatesWithContacts) {
    const { jobId, resetMultiSelect, selectedCandidates, fetchBulkContacts } = this.props;
    fetchBulkContacts(jobId, selectedCandidates, excludeCandidatesWithContacts);
    this.handleContactPullCancel();
    resetMultiSelect();
  }

  async onSendMail(values, subject) {
    const {
      sendBulkMails,
      jobId,
      resetMultiSelect,
      selectedCandidates,
      dripTemplatesById,
      candidateListType,
      setInvalidTemplateNotification,
      bulkActivitySelection,
      onBulkActivityPerformCallback,
      createCampaign,
      emailTemplatesById,
      runCampaign,
      filterContextId,
      candidateContext,
      _currentSelectedAllCandidates,
      _allPageCandidates,
      selectedCandidatesCountWithoutCustom,
      activeTab,
      selectedSubSegmentId,
    } = this.props;
    const {
      currentMailInputContent,
      templateId,
      isDripTemplate,
      editorUsed,
      isReadonly,
      mailPreHeader,
      createCampaignTitle,
      campaignId,
      isCreateCampaign,
      customSelectNumber,
      isSelectCustom,
    } = this.state;
    const sortedCurrentSelectedAllCandidates = _.sortBy(_currentSelectedAllCandidates || []);
    const sortedAllPageCandidate = _.sortBy(_allPageCandidates || []);
    const isAryaRecommendedSelectAllChecked =
      _currentSelectedAllCandidates?.length && _.isEqual(sortedCurrentSelectedAllCandidates, sortedAllPageCandidate);
    const isEnableCustomSelect =
      candidateListType !== 'manualsearch'
        ? _currentSelectedAllCandidates?.length === 0 && isSelectCustom && !isAryaRecommendedSelectAllChecked
        : !selectedCandidatesCountWithoutCustom && _currentSelectedAllCandidates?.length === 0 && isSelectCustom;
    const currentSelectedFromEmail = this.getCurrentSelectedFromEmail();
    const dripTemplate = _.cloneDeep(dripTemplatesById[templateId]);
    let mail;
    let body;
    let _subject = subject;
    if (!dripTemplate) {
      body =
        editorUsed?.toLowerCase() === 'reactemaileditor'
          ? currentMailInputContent
          : parseHtmlStringFromEditor(currentMailInputContent);
      mail = {
        Subject: subject,
        Body: body,
        TemplateId: templateId,
      };
    } else {
      const firstDrop = dripTemplate.Drops[0];
      const dripDrops = _.cloneDeep(values.Drops);
      firstDrop.Subject = subject;
      firstDrop.Body = currentMailInputContent;
      dripDrops.splice(0, 0, firstDrop);
      for (let i = 0; i < dripDrops.length; i += 1) {
        const currentDrip = dripDrops[i];
        const _body = parseHtmlStringFromEditor(currentDrip.Body);
        const currentDripDropSubject = currentDrip.Subject;
        dripDrops[i].Body = _body;
        body = `${body} ${_body}`;
        _subject = `${_subject} ${currentDripDropSubject}`;
      }
      dripTemplate.Drops = dripDrops;
      mail = dripTemplate;
    }
    const emailTemplateValidationPayload = { subject: _subject };
    emailTemplateValidationPayload.preHeader = mailPreHeader;
    if (!isReadonly && editorUsed?.toLowerCase() !== 'reactemaileditor') {
      emailTemplateValidationPayload.body = body;
    }
    if (!this.validateEmailTemplate(emailTemplateValidationPayload)) {
      setInvalidTemplateNotification();
      return;
    }
    mail = { ...mail, PreHeader: mailPreHeader, From: currentSelectedFromEmail };
    const selectedEmailTemplateName = _.get(emailTemplatesById, [templateId, 'Name'], null);
    if (candidateContext === 'segment') {
      const createCampaignConfiguration = {
        campaignName: createCampaignTitle,
        campaignActivity: 'EMAIL',
        templateId,
        templateName: selectedEmailTemplateName,
        fromAddress: currentSelectedFromEmail,
      };

      const payload = {
        FilterContextId: filterContextId,
        EmailDetails: {
          Subject: subject,
          Body: body,
          TemplateId: templateId,
          PreHeader: mailPreHeader,
          From: currentSelectedFromEmail,
        },
      };
      if (activeTab === 'subsegment') {
        payload.SubsegmentId = selectedSubSegmentId;
      }
      if (isEnableCustomSelect && customSelectNumber) {
        payload.ApplyCount = customSelectNumber;
      } else if (!isAryaRecommendedSelectAllChecked) {
        payload.Candidates = selectedCandidates;
      }

      if (isCreateCampaign) {
        const id = await createCampaign(createCampaignConfiguration, jobId);
        runCampaign(jobId, payload, id);
      } else {
        this.setCampaignId(0);
        runCampaign(jobId, payload, campaignId);
      }
    } else {
      sendBulkMails(jobId, selectedCandidates, mail, null, isDripTemplate, candidateListType, bulkActivitySelection);
    }
    this.setState({
      currentMailInputContent: '',
      mailSubject: '',
      templateId: '',
      mailPreHeader: '',
      isAllDripsVisible: false,
      isReadonly: false,
      editorUsed: null,
      isCreateCampaign: false,
      createCampaignTitle: undefined,
    });
    this.handleEmailComposerCancel();
    resetMultiSelect();
    if (onBulkActivityPerformCallback) {
      onBulkActivityPerformCallback();
    }
  }

  validateEmailTemplate = ({ subject, preHeader, body }) => {
    const filteredMergeTags = this.getFilteredMergeTags();
    const {
      emailSubjectMergeTags: subjectMergeTags,
      emailBodyMergeTags: bodyMergeTags,
      emailPreHeaderMergeTags: preHeaderMergeTags,
    } = getEmailMergeTags({
      mergeTags: filteredMergeTags,
    });
    return validateTemplate({
      subject,
      body,
      preHeader,
      subjectMergeTags,
      preHeaderMergeTags,
      bodyMergeTags,
    });
  };

  onUpdateMailContent({ htmlContent }) {
    this.setState({
      currentMailInputContent: htmlContent,
    });
  }

  onUpdateEmailSubject({ htmlContent }) {
    this.setState({
      mailSubject: htmlContent,
    });
  }

  onUpdateEmailPreHeader = ({ htmlContent }) => {
    this.setState({
      mailPreHeader: htmlContent,
    });
  };

  onEmailTemplateSelect(selectedEmailTemplateId) {
    const { emailTemplatesById, getDripTemplate, getEmailTemplate, dripTemplatesById } = this.props;
    if (selectedEmailTemplateId !== undefined) {
      const currentEmailTemplate = getEmailTemplateDetails(
        selectedEmailTemplateId,
        emailTemplatesById,
        dripTemplatesById
      );
      if (currentEmailTemplate.IsDrip) {
        getDripTemplate(selectedEmailTemplateId);
      } else {
        getEmailTemplate(selectedEmailTemplateId);
      }
      this.setState({
        currentMailInputContent: _.get(currentEmailTemplate, ['Body'], null),
        mailSubject: _.get(currentEmailTemplate, ['Subject'], null),
        mailPreHeader: _.get(currentEmailTemplate, ['PreHeader'], null),
        preHeaderVisibility: _.get(currentEmailTemplate, ['PreHeader'], false),
        templateId: selectedEmailTemplateId,
        isDripTemplate: _.get(currentEmailTemplate, ['IsDrip'], false),
        isReadonly: currentEmailTemplate?.IsReadonly,
        editorUsed: currentEmailTemplate?.EditorUsed,
      });
    } else {
      this.setState({
        currentMailInputContent: '',
        mailSubject: '',
        mailPreHeader: '',
        templateId: '',
        preHeaderVisibility: false,
        isReadonly: false,
        editorUsed: null,
      });
    }
    this.setState({ isAllDripsVisible: false });
  }

  showMessageComposerModal() {
    this.setState({
      messageComposerVisible: true,
    });
  }

  handleMessageComposerOk() {
    this.setState({
      messageComposerVisible: false,
    });
  }

  handleMessageComposerCancel() {
    this.setState({
      messageComposerVisible: false,
    });
  }

  validateTextTemplate = ({ text }) => {
    const messageMergeTags = this.getMessageMergeTags();
    return validateTemplate({ body: text, bodyMergeTags: messageMergeTags });
  };

  onSendNewMessage(text) {
    const {
      sendBulkMessages,
      jobId,
      resetMultiSelect,
      selectedCandidates,
      candidateListType,
      setInvalidTemplateNotification,
      bulkActivitySelection,
      onBulkActivityPerformCallback,
    } = this.props;
    const { messgaeTemplateId } = this.state;
    const modifiedMessageContent = text.replace(/ /g, ' '); // eslint-disable-line
    const message = {
      Body: modifiedMessageContent,
      TemplateId: messgaeTemplateId,
      IsConsentMessage: true,
    };
    if (!this.validateTextTemplate({ text })) {
      setInvalidTemplateNotification();
      return;
    }
    sendBulkMessages(jobId, selectedCandidates, message, 'SEND', undefined, candidateListType, bulkActivitySelection);
    this.setState({
      currentMessageInputContent: '',
      messgaeTemplateId: '',
    });
    this.handleMessageComposerCancel();
    resetMultiSelect();
    if (onBulkActivityPerformCallback) {
      onBulkActivityPerformCallback();
    }
  }

  onMessageTemplateSelect(selectedMessageTemplateId) {
    const { messageTemplatesById, getTextTemplate } = this.props;
    if (selectedMessageTemplateId !== undefined) {
      getTextTemplate(selectedMessageTemplateId);
      this.setState({
        currentMessageInputContent: _.get(messageTemplatesById, [selectedMessageTemplateId, 'Body'], null),
        isConsent: _.get(messageTemplatesById, [selectedMessageTemplateId, 'IsConsent'], null),
        messgaeTemplateId: selectedMessageTemplateId,
      });
    } else {
      this.setState({
        currentMessageInputContent: '',
        isConsent: null,
        messgaeTemplateId: null,
      });
    }
  }

  setIsJunkWordFound = (value) => {
    this.setState({ isJunkWordFound: value })
  }

  setJunkWordsFound = (value) => {
    this.setState({ junkWords: value })
  }

  onUpdateMessageContent({ htmlContent }) {
    this.setState({
      currentMessageInputContent: removeUnicodeQuotation(htmlContent),
    });
    debouncedCheckForJunkWordsInText(parseTextForJunkDetection(htmlContent), this.setIsJunkWordFound, this.setJunkWordsFound)
  }

  onAllPageCheckboxClick(event) {
    const { setCurrentSelectedAllCandidates, allPageCandidates, jobId, jobsById, setSelectedActiveCandidateCount } =
      this.props;
    const isChecked = event.target.checked;
    const candidateAggs = _.get(jobsById, [jobId, 'CandidateAggs', 'Source'], []);
    const candidateActiveAggr = candidateAggs.find(agg => agg.Group === 'Active');
    const allActiveCandidateCount = candidateActiveAggr ? _.get(candidateActiveAggr, 'Count', 0) : 0;
    if (isChecked) {
      setCurrentSelectedAllCandidates(allPageCandidates);
      if (setSelectedActiveCandidateCount) setSelectedActiveCandidateCount(allActiveCandidateCount);
    } else {
      setCurrentSelectedAllCandidates([]);
      if (setSelectedActiveCandidateCount) setSelectedActiveCandidateCount(0);
    }
    this.setState({ inputNumber: undefined });
  }

  onSelectCustomClick = event => {
    const {
      setCurrentSelectedAllCandidates,
      _currentSelectedAllCandidates,
      onCustomSelectBulkCandidates,
      candidateListType,
      onSelectBulkCandidates,
    } = this.props;
    const { customSelectNumber } = this.state;
    const isChecked = event.target.checked;
    if (isChecked) {
      this.setState({ isSelectCustom: true });
      if (_currentSelectedAllCandidates?.length > 0 || candidateListType === 'manualsearch') {
        setCurrentSelectedAllCandidates([]);
      }
      if (candidateListType === 'manualsearch') {
        onSelectBulkCandidates({ target: { checked: false } });
      }
    } else {
      this.setState({ isSelectCustom: false });
    }
    onCustomSelectBulkCandidates(event, customSelectNumber);
  };

  onSelectionInputChange = inputNumber => {
    const { allPageCandidates, setCurrentSelectedAllCandidates } = this.props;
    const candidateIds = allPageCandidates.slice(0, inputNumber);
    const allCandidatesCount = allPageCandidates.length;
    if (inputNumber >= 0 && inputNumber <= allCandidatesCount) {
      this.setState({ inputNumber });
      setCurrentSelectedAllCandidates(candidateIds);
    } else if (inputNumber < 0) {
      this.setState({ inputNumber: 0 });
      setCurrentSelectedAllCandidates([]);
    }
  };

  onCustomSelectChange = inputNumber => {
    const { totalCandidate, onCustomSelectBulkCandidates } = this.props;
    this.setState({ customSelectNumber: inputNumber });
    if (inputNumber >= 0 && inputNumber <= totalCandidate) {
      this.setState({ customInputValidationError: undefined });
      onCustomSelectBulkCandidates({ target: { checked: true } }, inputNumber);
    } else if (inputNumber < 0) {
      this.setState({ customInputValidationError: 'Value should be greater than 0' });
    } else {
      this.setState({
        customInputValidationError: 'Value should be less than the total count',
      });
    }
  };

  handleSelectedCount = () => {
    const { selectedCandidatesCount, sampleCandidateCount, totalCandidate, candidateContext, isExactMatchSearch } =
      this.props;
    if (selectedCandidatesCount === 0 || isExactMatchSearch) {
      return null;
    }
    if (candidateContext === 'segment' && selectedCandidatesCount < sampleCandidateCount) {
      return `${selectedCandidatesCount} selected`;
    }
    if (candidateContext === 'segment' && selectedCandidatesCount === sampleCandidateCount) {
      return `${totalCandidate} selected`;
    }
    return `${selectedCandidatesCount} selected`;
  };

  onCurrentPageCheckboxClick(event) {
    let currentSelectedAllCandidates = [];
    const {
      setCurrentSelectedAllCandidates,
      currentPageCandidates,
      _currentSelectedAllCandidates,
      setSelectedActiveCandidateCount,
      selectedActiveCandidateCount,
      candidatesById,
      updateCustomBulkActivity,
    } = this.props;
    const { customSelectNumber, isSelectCustom } = this.state;

    const isChecked = event.target.checked;
    if (isChecked) {
      const currentPageUncheckedActiveCandidateCount = Object.values(candidatesById).filter(candidate => {
        const candidateSourceName = getSourceName(getCandidateSource(candidate));
        return candidateSourceName?.toLowerCase() === 'active' && !_currentSelectedAllCandidates.includes(candidate.Id);
      }).length;
      currentSelectedAllCandidates = [..._currentSelectedAllCandidates, ...currentPageCandidates];
      if (setSelectedActiveCandidateCount)
        setSelectedActiveCandidateCount(selectedActiveCandidateCount + currentPageUncheckedActiveCandidateCount);
      this.setState({ customSelectNumber: undefined, isSelectCustom: false });
    } else {
      const currentPageActiveCandidateCount = Object.values(candidatesById).filter(candidate => {
        const candidateSourceName = getSourceName(getCandidateSource(candidate));
        return candidateSourceName?.toLowerCase() === 'active';
      }).length;
      currentSelectedAllCandidates = [..._currentSelectedAllCandidates].filter(
        selectedCandidate =>
          !currentPageCandidates.find(candidate => _.isEqual(candidate.CandidateId, selectedCandidate.CandidateId))
      );
      if (setSelectedActiveCandidateCount)
        setSelectedActiveCandidateCount(selectedActiveCandidateCount - currentPageActiveCandidateCount);
    }
    currentSelectedAllCandidates = _.uniqWith(currentSelectedAllCandidates, _.isEqual);
    setCurrentSelectedAllCandidates(currentSelectedAllCandidates, isChecked);
    this.setState({ inputNumber: undefined });
    updateCustomBulkActivity(isChecked, isSelectCustom, customSelectNumber);
  }

  bulkCandidateShortlist(actionName = 'Shortlisted', subsegmentId) {
    const {
      jobId,
      bulkCandidateRecommend,
      _currentSelectedAllCandidates,
      bulkActivitySelection,
      candidateListType,
      candidateContext = 'job',
      isNonInternalPortalForSegmentEnabled,
      bulkCustomActivitySelection,
      bulkSegmentCandidateRecommend,
      isPartialBulkSelectionChecked,
      selectedCandidatesCountWithoutCustom,
      candidatesRecommendationStatus,
      activeSourceName,
    } = this.props;
    const { isSelectCustom, includeAlreadyAssociatedCandidates } = this.state;
    const isEnableCustomSelect =
      !selectedCandidatesCountWithoutCustom && _currentSelectedAllCandidates?.length === 0 && isSelectCustom;
    const recommendedInfoUpdateCommand = _currentSelectedAllCandidates.map(candidate => {
      let _candidate;
      if (candidateListType === 'aryarecommended') _candidate = { CandidateId: candidate };
      else _candidate = _.cloneDeep(candidate);
      _.set(_candidate, ['RecommendedInfo', 'Status'], actionName);
      return _candidate;
    });
    if (
      candidateContext === 'segment' &&
      candidateListType === 'manualsearch' &&
      (isPartialBulkSelectionChecked || isEnableCustomSelect) &&
      (!isNonInternalPortalForSegmentEnabled ||
        (isNonInternalPortalForSegmentEnabled && activeSourceName?.toLowerCase() === 'internal'))
    ) {
      const bulkActivityPayload = isEnableCustomSelect ? bulkCustomActivitySelection : bulkActivitySelection;
      bulkSegmentCandidateRecommend({
        jobId,
        bulkCustomActivitySelection: bulkActivityPayload,
        actionName,
        includeCandidatesWithStatus: includeAlreadyAssociatedCandidates,
        isPartialRecommend: !!candidatesRecommendationStatus?.errorMessage,
        subsegmentId,
      });
    } else {
      bulkCandidateRecommend({
        jobId,
        recommendedInfoUpdateCommand,
        candidateListType,
        bulkActivitySelection,
        actionName,
        candidateContext,
        subsegmentId,
      });
    }
  }

  onBulkShortlist(e, actionName = 'Shortlisted', subsegmentId) {
    const { selectedCandidatesCount, activeTab } = this.props;
    e.stopPropagation();
    if (selectedCandidatesCount === 0 || activeTab?.toLowerCase() === actionName.toLowerCase()) return;
    this.bulkCandidateShortlist(actionName, subsegmentId);
    this.handleBulkShortListIconCancel();
  }

  getUpdatedFilterForSegments = subsegmentId => {
    const { jobId, _currentSelectedAllCandidates, _allPageCandidates, filterContextId } = this.props;
    const { customSelectNumber, isSelectCustom } = this.state;
    const sortedCurrentSelectedAllCandidates = _.sortBy(_currentSelectedAllCandidates || []);
    const sortedAllPageCandidate = _.sortBy(_allPageCandidates || []);
    const isAryaRecommendedSelectAllChecked =
      _currentSelectedAllCandidates?.length && _.isEqual(sortedCurrentSelectedAllCandidates, sortedAllPageCandidate);
    const isEnableCustomSelect =
      _currentSelectedAllCandidates?.length === 0 && isSelectCustom && !isAryaRecommendedSelectAllChecked;
    const filter = { candidateIds: _currentSelectedAllCandidates, jobId };
    if (subsegmentId) {
      filter.SubsegmentIds = [subsegmentId];
    }
    if (isAryaRecommendedSelectAllChecked) {
      filter.filterContextId = filterContextId;
      filter.candidateIds = undefined;
    } else if (isEnableCustomSelect && customSelectNumber) {
      filter.filterContextId = filterContextId;
      filter.ApplyCount = customSelectNumber;
      filter.candidateIds = undefined;
    }
    return filter;
  };

  onSegmentBulkShortList = async (e, subsegmentId) => {
    const { bulkCandidateShortlist } = this.props;
    e.stopPropagation();
    this.setState({
      segmentShortListedApiStatus: 'INPROGRESS',
    });
    const filter = this.getUpdatedFilterForSegments(subsegmentId);
    await bulkCandidateShortlist(filter);
    this.setState({
      segmentShortListedApiStatus: 'COMPLETED',
    });
  };

  onSegmentBulkReject = async (e, subsegmentPayload) => {
    const { bulkCandidateRemove } = this.props;
    e.stopPropagation();
    this.setState({
      segmentBulkRejectApiStatus: 'INPROGRESS',
    });
    const filter = this.getUpdatedFilterForSegments();
    if (subsegmentPayload) {
      const { SubSegmentIds, IsSubSegmentOnly } = subsegmentPayload;
      filter.SubSegmentIds = [parseInt(SubSegmentIds, 10)];
      filter.IsSubSegmentOnly = IsSubSegmentOnly;
    }
    await bulkCandidateRemove(filter);
    this.setState({
      segmentBulkRejectApiStatus: 'COMPLETED',
    });
  };

  handleDeleteButtonClick = (e, actionName, isBulkIconActivated) => {
    const { candidateContext, activeTab } = this.props;
    if (isBulkIconActivated) {
      if (candidateContext === 'segment' && activeTab === 'shortlisted') {
        this.onSegmentBulkReject(e);
      } else {
        this.onBulkShortlist(e, actionName);
      }
    }
  };

  handleAddButtonClick = (e, actionName, isBulkIconActivated, subsegmentId) => {
    const { candidateContext, activeTab } = this.props;
    if (isBulkIconActivated) {
      if (candidateContext === 'segment' && activeTab === 'rejected') {
        this.onSegmentBulkShortList(e, subsegmentId);
      } else {
        this.onBulkShortlist(e, actionName, subsegmentId);
      }
    }
  };

  handleBulkShortlistIconClick = () => {
    this.setState({
      bulkShortlistConfirmationModalVisibility: true,
    });
  };

  filterInvalidContactInfoPersonIds = () => {
    const { connectStatuses, candidatesById, selectedCandidates } = this.props;
    const { ConnectStatuses } = connectStatuses;
    const personIds = Object.keys(ConnectStatuses);
    const invalidContactInfoPersonIds = personIds
      .map(personId => {
        const contactInfo = ConnectStatuses[personId].Contact;
        let invalidContactInfo = true;
        if (!contactInfo.Emails && !contactInfo.Phones) return personId;
        if (contactInfo.Emails) {
          const { Emails } = contactInfo;

          for (let i = 0; i < Emails.length; i += 1) {
            if (Emails[i].ValidityStatus === 'Valid' || Emails[i].ValidityStatus === 'Unknown') {
              invalidContactInfo = false;
              break;
            }
          }
        }
        if (contactInfo.Phones && invalidContactInfo) {
          const { Phones } = contactInfo;
          for (let i = 0; i < Phones.length; i += 1) {
            if (Phones[i].ValidityStatus === 'Valid' || Phones[i].ValidityStatus === 'Unknown') {
              invalidContactInfo = false;
              break;
            }
          }
        }
        if (invalidContactInfo) return personId;
        return null;
      })
      .filter(id => id);

    return selectedCandidates
      .map(id => {
        return invalidContactInfoPersonIds.includes(candidatesById[id].PersonId) ? candidatesById[id].Name : null;
      })
      .filter(name => name);
  };

  publishCandidates = async () => {
    const { selectedCandidates, bulkPublishCandidates, jobId, resetMultiSelect, onStatusChange, activeTab } =
      this.props;
    const { isFinalCommit } = this.state;
    const candidates = selectedCandidates;
    const CandidateIds = candidates.map(candidate => {
      return candidate;
    });

    await bulkPublishCandidates(jobId, {
      CandidateIds,
      ServiceStatus: activeTab === 'engaged' ? 'Engaged' : 'Vetted',
      isFinalCommit,
    });
    onStatusChange(activeTab);
    resetMultiSelect();
  };

  bulkMoveToEngaged = async () => {
    const { jobId, selectedCandidates, updateBulkConnectStatus, resetMultiSelect, onStatusChange, activeTab } =
      this.props;
    const candidates = selectedCandidates;
    await updateBulkConnectStatus(jobId, candidates, 'Engaged');
    onStatusChange(activeTab);
    resetMultiSelect();
  };

  bulkMoveToConnected = async () => {
    const { jobId, selectedCandidates, updateBulkConnectStatus, resetMultiSelect, onStatusChange } = this.props;
    await updateBulkConnectStatus(jobId, selectedCandidates, 'Connected');
    onStatusChange('engaged');
    resetMultiSelect();
  };

  bulkMoveToConnectedFromShortlisted = async () => {
    const { jobId, selectedCandidates, updateBulkConnectStatus, resetMultiSelect, onStatusChange, candidatesById } =
      this.props;
    const candidates = selectedCandidates;
    const nonConnectedCandidates = candidates.filter(
      candidateId => _.get(candidatesById, [candidateId, 'ConnectionStatus'], '').toLowerCase() === 'notconnected'
    );
    const connectedCandidates = _.difference(candidates, nonConnectedCandidates);

    if (nonConnectedCandidates.length) {
      notification.open({
        duration: 3,
        message: null,
        description: (
          <div>
            {`The following ${getCountableNounForm(
              'candidate',
              'candidates',
              nonConnectedCandidates.length
            )} ${getCountableNounForm(
              'was',
              'were',
              nonConnectedCandidates.length
            )} not contacted and cannot be moved to connected:`}
            <div className="nonContactedCandidateNames">
              {nonConnectedCandidates.map(candidateId => {
                return <div>{_.get(candidatesById, [candidateId, 'Name'], null)}</div>;
              })}
            </div>
          </div>
        ),
      });
    }

    if (connectedCandidates.length) {
      await updateBulkConnectStatus(jobId, candidates, 'Connected');
      onStatusChange('shortlisted');
      resetMultiSelect();
    }
  };

  bulkMoveToShortlisted = async () => {
    const { jobId, selectedCandidates, updateBulkConnectStatus, resetMultiSelect, onStatusChange } = this.props;
    await updateBulkConnectStatus(jobId, selectedCandidates, 'Contacted');
    onStatusChange('connected');
    resetMultiSelect();
  };

  onChangeFinalCommit = e => {
    this.setState({
      isFinalCommit: e.target.value,
    });
  };

  confirm = () => {
    // const invalidContactInfoCandidates = this.filterInvalidContactInfoPersonIds();
    // let content = 'Once candidates are published, they will start appearing in the client’s candidate list.';
    // if (invalidContactInfoCandidates.length > 0) {
    //   const list = invalidContactInfoCandidates.map(name => <li>{name}</li>);
    //   content = (
    //     <>
    //       <p>publishing {invalidContactInfoCandidates.length} candidates with invalid email and phone numbers</p>
    //       <ul> {list}</ul>
    //       <p>Once candidates are published, they will start appearing in the client’s candidate list.</p>
    //     </>
    //   );
    // }
    const { isFinalCommit } = this.state;
    Modal.confirm({
      title: 'Are you sure you want to publish candidates?',
      content: <FinalPublishContent isFinalCommit={isFinalCommit} onChangeFinalCommit={this.onChangeFinalCommit} />,
      okText: 'Publish',
      cancelText: 'Cancel',
      onOk: this.publishCandidates,
    });
  };

  confirmPublishForPremiumJob = () => {
    const { _currentSelectedAllCandidates } = this.props;
    const { isFinalCommit } = this.state;
    // const invalidContactInfoCandidates = this.filterInvalidContactInfoPersonIds();
    // let content = (
    //   <>
    //     <p>This will publish candidates from two buckets</p>
    //     <Checkbox disabled>Engaged for customer ({_currentSelectedAllCandidates?.length})</Checkbox>
    //     <br />
    //     <Checkbox disabled>Vetted for customer ({shortlistedCount})</Checkbox>
    //     <br />
    //     <p>
    //       Publish is a one time activity. Once candidates are published, they will start appearing in the client’s
    //       candidate list and you won’t be able to publish new engaged ones.
    //     </p>
    //   </>
    // );
    // if (invalidContactInfoCandidates.length > 0) {
    //   const list = invalidContactInfoCandidates.map(name => <li>{name}</li>);
    //   content = (
    //     <>
    //       <p>
    //         publishing {invalidContactInfoCandidates.length} candidates with invalid email and phone numbers from
    //         shortlistedBucket
    //       </p>
    //       <ul> {list}</ul>
    //       {content}
    //     </>
    //   );
    // }
    Modal.confirm({
      width: 560,
      title: `Are you sure you want to publish ${_currentSelectedAllCandidates?.length} candidates?`,
      content: (
        <>
          {/* <p>This will publish candidates from two buckets</p> */}
          <Checkbox disabled>Engaged for customer ({_currentSelectedAllCandidates?.length})</Checkbox>
          {/* <br />
          <Checkbox disabled>Vetted for customer ({shortlistedCount})</Checkbox> */}
          <br />
          <FinalPublishContent isFinalCommit={isFinalCommit} onChangeFinalCommit={this.onChangeFinalCommit} />
        </>
      ),
      okText: 'Publish',
      okButtonProps: { shape: 'round' },
      cancelButtonProps: { shape: 'round' },
      cancelText: 'Cancel',
      onOk: this.publishCandidates,
    });
  };

  showAllDrips = () => {
    this.setState({ isAllDripsVisible: true });
  };

  onCloseCreditInfoAlert() {
    const { currentUser } = this.props;
    const { sub } = currentUser;
    const creditInfoAlertStatuses = JSON.parse(localStorage.getItem('CreditInfoAlertStatuses')) || {};
    creditInfoAlertStatuses[sub] = true;
    localStorage.setItem('CreditInfoAlertStatuses', JSON.stringify(creditInfoAlertStatuses));
  }

  onBulkShortlistCreditInfoAlertClose() {
    const { currentUser } = this.props;
    const { sub } = currentUser;
    const creditInfoAlertStatuses = getStorageData(BulkShortlistCreditInfoAlertStatus) || {};
    creditInfoAlertStatuses[sub] = true;
    localStorage.setItem(BulkShortlistCreditInfoAlertStatus, JSON.stringify(creditInfoAlertStatuses));
  }

  getEngagedCandidateBucket(activeTab, isBulkIconActivated) {
    const { featureToggleList } = this.props;
    const isEnagedBucketEnabled = featureToggleList?.EngagedBucket?.IsEnabled;

    if (isPulseUser() || !isEnagedBucketEnabled || !['connected', 'engaged', 'applied'].includes(activeTab))
      return null;

    return (
      <div>
        {activeTab?.toLowerCase() === 'engaged' ? (
          <div className="candidate-multi-select-engaged">
            <Button shape="round" onClick={this.bulkMoveToConnected} disabled={!isBulkIconActivated}>
            <FormattedMessage {...message.removeFromEngaged} />
            </Button>
          </div>
        ) : (
          <div className="candidate-multi-select-connected">
            <div className="move-to-engaged">
              <Button shape="round" onClick={this.bulkMoveToEngaged} disabled={!isBulkIconActivated}>
              <FormattedMessage {...message.moveToEngagedLabel} />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  onChangeFromEmail(emailId) {
    this.setState({
      selectedFromEmail: emailId,
    });
  }

  getCurrentSelectedFromEmail = () => {
    const { userEmails, currentUser, impersonatedUser, orgDefaultEmail, featureToggleList, connectSettings } =
      this.props;
    const { selectedFromEmail } = this.state;
    const { AdminEmailProviders: adminEmailProviders, UserEmailProviders: userEmailProviders } = connectSettings;
    const linkedEmails = getProviderLinkedEmails(userEmailProviders);
    const isLinkedEmailsTestSuccesful = getIsLinkedEmailsTestSuccesful({ userEmailProviders });
    const successfulTestedEmailList = getSuccessfulTestedEmailList({ userEmailProviders });
    const isAdminSMTPConfigurationExists = (adminEmailProviders || []).length !== 0;
    const isEmailProviderSMTPConfigurationExists =
      (linkedEmails || []).length !== 0 && !_.isEmpty(isLinkedEmailsTestSuccesful);
    const combinedEmails = [orgDefaultEmail, ...userEmails];
    const currentUserEmail = impersonatedUser ? impersonatedUser.Email : currentUser?.email;
    return getCurrentSelectedFromEmail({
      userEmails: combinedEmails,
      currentUserEmail,
      featureToggleList,
      selectedFromEmail,
      isAdminSMTPConfigurationExists,
      isEmailProviderSMTPConfigurationExists,
      orgDefaultEmail: orgDefaultEmail?.EmailId?.toLowerCase(),
      successfulTestedEmailList,
    });
  };

  getFilteredMergeTags = () => {
    const { mergeTags, featureToggleList, version, jobDetails } = this.props;
    return getFilteredMergeTags(
      mergeTags,
      {
        CustomizeJobUrl: featureToggleList.CustomizeJobUrl,
        JobSummary: featureToggleList.JobSummary,
        CustomizeJobNotInterestedUrl: featureToggleList.CustomizeJobNotInterestedUrl,
      },
      version,
      jobDetails
    );
  };

  getEmailMergeTags = () => {
    const filteredMergeTags = this.getFilteredMergeTags();
    return _.union(
      filteredMergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailSubject')),
      filteredMergeTags.filter(mergeTag => mergeTag.Scopes.includes('EmailBody'))
    );
  };

  getMessageMergeTags = () => {
    const filteredMergeTags = this.getFilteredMergeTags();
    return filteredMergeTags.filter(mergeTag => mergeTag.Scopes.includes('Sms'));
  };

  setCandidateTransferModalVisibility = value => {
    this.setState({
      isCandidateTransferModalVisible: value,
    });
  };

  handleBulkTranferIcon() {
    const { selectedCandidatesCount, totalCandidate } = this.props;
    const { customSelectNumber } = this.state;
    if (selectedCandidatesCount > 0 || (customSelectNumber > 0 && customSelectNumber <= totalCandidate))
      this.setState({
        isCandidateTransferModalVisible: true,
      });
  }

  addPreHeaderToEmail = () => {
    this.setState({
      preHeaderVisibility: true,
    });
  };

  removePreHeaderFromEmail = () => {
    this.setState({
      mailPreHeader: undefined,
      preHeaderVisibility: false,
    });
  };

  onClearSelection = () => {
    const { resetMultiSelect, candidateContext } = this.props;
    resetMultiSelect();
    this.setState({
      inputNumber: undefined,
    });
    if (candidateContext === 'segment') {
      this.setState({ isSelectCustom: false, customSelectNumber: undefined, customInputValidationError: undefined });
    }
  };

  render() {
    const {
      candidateCount,
      mailSubject,
      currentMailInputContent,
      currentMessageInputContent,
      contactPullerVisible,
      emailComposerVisible,
      messageComposerVisible,
      isConsent,
      templateId,
      messgaeTemplateId,
      isAllDripsVisible,
      isReadonly,
      editorUsed,
      bulkShortlistModalVisible,
      bulkShortlistConfirmationModalVisibility,
      isCandidateTransferModalVisible,
      mailPreHeader,
      preHeaderVisibility,
      inputNumber,
      isCreateCampaign,
      createCampaignTitle,
      isSelectCustom,
      customSelectNumber,
      customInputValidationError,
      segmentBulkRejectApiStatus,
      segmentShortListedApiStatus,
      segmentModalVisibility,
      includeAlreadyAssociatedCandidates,
      isJunkWordFound,
      junkWords
    } = this.state;
    const {
      emailTemplates,
      consentMessageTemplates,
      isConsentRequired,
      visiblityFlag,
      _currentSelectedAllCandidates,
      isCurrentPageChecked,
      _allPageCandidates,
      currentPageCandidates,
      isBulkSelectionAllowed,
      isCandidateBulkSelectionInputEnabled,
      candidateListType,
      bulkShortlistApiStatus,
      bulkRejectionApiStatus,
      isBulkRecommendEnabled,
      isBulkEmailEnabled,
      isBulkMessageEnabled,
      isBulkContactPullAllowed,
      emailTemplateIds,
      emailTemplatesById,
      emailTemplatesCount,
      messageTemplateIds,
      messageTemplatesById,
      messageTemplatesCount,
      activeTab,
      featureToggleList,
      jobId,
      jobsById,
      selectedActiveCandidateCount,
      dripTemplatesById,
      userConfig,
      currentUser,
      userEmails,
      orgDefaultEmail,
      signatures,
      connectSettings,
      availableCredits,
      userConnectConfig,
      onSelectBulkCandidates,
      isBulkSelectionChecked,
      isSelectThisPageDisabled,
      isBulkSelectionDisabled,
      selectedCandidatesCount,
      showBulkActivityText,
      candidateDownloadFilterConfig,
      candidateCommunicationFilterConfig,
      emailSmtpConfiguration,
      isBulkAddAvailable,
      isBulkDeleteAvailable,
      candidateContext = 'job',
      isBulkTransferAvailable,
      selectedCandidates,
      version,
      jobDetails,
      maxBulkCandidatesSelectionCount,
      activeSourceName,
      duplicateCandidateIds,
      campaignCreationStatus,
      totalCandidate,
      filterContextId,
      isExactMatchSearch,
      selectedCandidatesCountWithoutCustom,
      isNonInternalPortalForSegmentEnabled,
      candidatesRecommendationStatus,
      setSegmentBulkRecommendErrorMessage,
      fetchSubSegmentsAction,
      subsegmentList,
      fetchSubSegmentApiStatus,
      setSubsegmentModalStatus,
      sourcingStats,
      isPartialBulkSelectionChecked,
      deleteSubsegmentModalStatus,
      setAddSubsegmentModalStatus,
      addSubsegmentModalStatus,
      setSubsegmentDeleteModalStatusAction,
      publishedCandidates,
      adminFeatures,
      candidatePublishApiStatus,
      setSelectedActiveCandidateCount,
      setSelectedCandidates,
      setCreatedSubSegmentId,
      lastCreatedCandidateId,
      selectedSubSegmentId,
    } = this.props;
    if (!visiblityFlag) return null;
    const isPublishingCandidates = _.get(featureToggleList, ['PublishingCandidates', 'IsEnabled'], false);
    const isEmailEnabled = _.get(featureToggleList, ['EmailWrite', 'IsEnabled'], false);
    const isMessageEnabled = _.get(featureToggleList, ['MessageWrite', 'IsEnabled'], false);
    const isContactPullEnabled = _.get(featureToggleList, ['ContactFetch', 'IsEnabled'], false);
    const isBulkContactFetchEnabled = _.get(featureToggleList, ['BulkContactFetch', 'IsEnabled'], false);
    const isSelectAllChecked = selectedCandidatesCount && isBulkSelectionChecked;

    const sortedCurrentSelectedAllCandidates = _.sortBy(_currentSelectedAllCandidates || []);
    const sortedAllPageCandidate = _.sortBy(_allPageCandidates || []);
    const isAryaRecommendedSelectAllChecked =
      _currentSelectedAllCandidates?.length && _.isEqual(sortedCurrentSelectedAllCandidates, sortedAllPageCandidate);
    const orderInfo = _.get(jobsById, [jobId, 'OrderInfo'], {});
    const { ProductVariantName, Status, IsFinalCommit } = orderInfo;
    const candidateIds = selectedCandidates?.map(candidate => {
      return candidate;
    });

    const eventContactPullCallbacks = {
      onSend: this.onFetchContacts,
    };

    const eventBulkShortlistcallbacks = {
      onSend: this.onBulkShortlist,
    };

    const eventEmailCallbacks = {
      onSend: this.onSendMail,
      onUpdateMailContent: this.onUpdateMailContent,
      onUpdateEmailSubject: this.onUpdateEmailSubject,
      onUpdateEmailPreHeader: this.onUpdateEmailPreHeader,
      onTemplateSelect: this.onEmailTemplateSelect,
      onChangeFromEmail: this.onChangeFromEmail,
    };
    const currentEmailValues = {
      emailInputValue: currentMailInputContent,
      mailSubject,
      mailPreHeader,
      isReadonly,
      editorUsed,
    };
    const eventMessageCallbacks = {
      onSend: this.onSendNewMessage,
      onUpdateMessage: this.onUpdateMessageContent,
      onTemplateSelect: this.onMessageTemplateSelect,
    };

    const eventSegmentModalCallbacks = {
      onSubmit: this.onSegmentModalSubmit,
      onModalCancel: this.onSegmentModalCancel,
      onCheckboxChange: this.onCheckboxChange,
      onCancel: this.handleOnCancel,
    };

    const eventSegmentModalOnErrorCallbacks = {
      onSubmit: this.onSegmentErrorModalSubmit,
      onCancel: () => {
        setSegmentBulkRecommendErrorMessage({ jobId, errorMessage: undefined });
      },
      onModalCancel: () => {
        setSegmentBulkRecommendErrorMessage({ jobId, errorMessage: undefined });
      },
    };

    const currentMessageValues = {
      messageInputValue: currentMessageInputContent,
      isConsent,
    };

    //  !! DIRTY
    const aggregatedCandidates =
      candidateListType === 'aryarecommended'
        ? _.uniqWith([..._currentSelectedAllCandidates, ...currentPageCandidates], _.isEqual)
        : _.uniqBy([..._currentSelectedAllCandidates, ...currentPageCandidates], 'CandidateId');

    const isEnableCustomSelect =
      candidateListType !== 'manualsearch'
        ? _currentSelectedAllCandidates?.length === 0 && isSelectCustom && !isAryaRecommendedSelectAllChecked
        : !selectedCandidatesCountWithoutCustom && _currentSelectedAllCandidates?.length === 0 && isSelectCustom;

    const activeSourceConfigurations = _.get(userConfig, 'Sources', []).find(s => {
      const sourceName = getSourceName(s.Source);
      return sourceName.toLowerCase() === 'active';
    });

    const creditsDeductionForActive = _.get(activeSourceConfigurations, 'Credits', 0);
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const emailMergeTags = this.getEmailMergeTags();
    const messageMergeTags = this.getMessageMergeTags();
    const communicationDisabledMessage = getConsentComplianceInfo(jobsById?.[jobId], userConnectConfig);
    const isBulkIconActivated =
      selectedCandidatesCount > 0 || (customSelectNumber > 0 && customSelectNumber <= totalCandidate);
    const candidateTransferIconClassName = isBulkIconActivated ? 'activatedBulkOptionIcon' : '';
    const candidateBulkEmailIconClassName = candidateContext === 'job' ? `bulkOptionIcon` : `bulkOptionIconSegment`;
    const activatedBulkOptionIconClassName = isBulkIconActivated ? `activatedBulkOptionIcon` : '';
    const combinedEmails = [orgDefaultEmail, ...userEmails];
    const isAddIconloading =
      isBulkAddAvailable && (bulkShortlistApiStatus === 'INPROGRESS' || segmentShortListedApiStatus === 'INPROGRESS');

    const isBulkOptionFooterEnable =
      isBulkRecommendEnabled || isBulkEmailEnabled || isBulkMessageEnabled || isBulkContactPullAllowed;
    const { shortlistedCount } = getActualSourcingStats(sourcingStats);

    const { visible: addSubsegmentModalVisibilityStatus, singleCandidateAdd } = addSubsegmentModalStatus;
    const showMatchingCandidateChecbox = activeTab !== 'rejected';
    const handleOnclick = (e, subsegmentId) => {
      isPartialBulkSelectionChecked || (_currentSelectedAllCandidates?.length === 0 && isSelectCustom)
        ? this.onSegmentModalSubmit(e, isBulkIconActivated, subsegmentId)
        : this.handleAddButtonClick(e, 'Shortlisted', isBulkIconActivated, subsegmentId);
    };

    const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList);
    return (
      <div>
        <EmailComposer
          visible={emailComposerVisible}
          ok={this.handleEmailComposerOk}
          onCancel={this.handleEmailComposerCancel}
          eventCallbacks={eventEmailCallbacks}
          currentValues={currentEmailValues}
          emailTemplates={emailTemplates || []}
          mergeTags={emailMergeTags}
          emailTemplateIds={emailTemplateIds}
          emailTemplatesById={emailTemplatesById}
          emailTemplatesCount={emailTemplatesCount}
          fetchEmailTemplates={this.fetchEmailTemplates}
          selectedEmailTemplateId={templateId}
          onCloseCreditInfoAlert={this.onCloseCreditInfoAlert}
          selectedActiveCandidateCount={selectedActiveCandidateCount}
          dripTemplate={dripTemplatesById[templateId]}
          isAllDripsVisible={isAllDripsVisible}
          showAllDrips={this.showAllDrips}
          credits={creditsDeductionForActive}
          currentUser={currentUser}
          isPaidJobServiceEnabled={isPaidJobServiceEnabled}
          userEmails={combinedEmails}
          signatures={signatures}
          connectSettings={connectSettings}
          selectedFromEmail={this.getCurrentSelectedFromEmail()}
          candidateDownloadFilterConfig={candidateDownloadFilterConfig}
          candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
          communicationDisabledMessage={communicationDisabledMessage}
          emailSmtpConfiguration={emailSmtpConfiguration}
          version={version}
          jobDetails={jobDetails}
          addPreHeaderToEmail={this.addPreHeaderToEmail}
          preHeaderVisibility={preHeaderVisibility}
          removePreHeaderFromEmail={this.removePreHeaderFromEmail}
          candidateContext={candidateContext}
          availableCredits={availableCredits}
          onClickCreateCampaign={this.onClickCreateCampaign}
          isCreateCampaign={isCreateCampaign}
          createCampaignTitle={createCampaignTitle}
          handleCampaignTitle={this.handleCampaignTitle}
          setCampaignId={this.setCampaignId}
          campaignCreationStatus={campaignCreationStatus}
        />
        <BulkShortlistModal
          visible={bulkShortlistModalVisible}
          onCancel={this.handleBulkShortlistModalCancel}
          onCloseCreditInfoAlert={this.onBulkShortlistCreditInfoAlertClose}
          selectedActiveCandidateCount={selectedActiveCandidateCount}
          credits={creditsDeductionForActive}
          currentUser={currentUser}
          isPaidJobServiceEnabled={isPaidJobServiceEnabled}
          bulkCandidateShortlist={this.bulkCandidateShortlist}
          candidateContext={candidateContext}
          availableCredits={availableCredits}
          userConfig={userConfig}
        />
        <MessageComposer
          visible={messageComposerVisible}
          ok={this.handleMessageComposerOk}
          onCancel={this.handleMessageComposerCancel}
          consentMessageTemplates={consentMessageTemplates || []}
          eventCallbacks={eventMessageCallbacks}
          currentValues={currentMessageValues}
          mergeTags={messageMergeTags}
          consentRequire={isConsentRequired}
          messageTemplateIds={messageTemplateIds}
          messageTemplatesById={messageTemplatesById}
          messageTemplatesCount={messageTemplatesCount}
          fetchMessageTemplates={this.fetchMessageTemplates}
          selectedMessageTemplateId={messgaeTemplateId}
          onCloseCreditInfoAlert={this.onCloseCreditInfoAlert}
          selectedActiveCandidateCount={selectedActiveCandidateCount}
          credits={creditsDeductionForActive}
          currentUser={currentUser}
          isPaidJobServiceEnabled={isPaidJobServiceEnabled}
          candidateDownloadFilterConfig={candidateDownloadFilterConfig}
          candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
          communicationDisabledMessage={communicationDisabledMessage}
          availableCredits={availableCredits}
          featureToggleList={featureToggleList}
          userConfig={userConfig}
          isJunkWordFound={isJunkWordFound}
          junkWords={junkWords}
        />
        <ContactPuller
          visible={contactPullerVisible}
          onCancel={this.handleContactPullCancel}
          eventCallbacks={eventContactPullCallbacks}
          candidateCount={candidateCount}
          isJobBoardSourcePresent={this.getIsJobBoardSourcePresent()}
          availableCredits={availableCredits}
          candidateDownloadFilterConfig={candidateDownloadFilterConfig}
          candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
          userConfig={userConfig}
        />
        <BulkShortlistConfirmationModal
          visible={bulkShortlistConfirmationModalVisibility}
          onCancel={this.handleBulkShortListIconCancel}
          eventCallbacks={eventBulkShortlistcallbacks}
          candidateCount={!isExactMatchSearch ? selectedCandidatesCount : 'all'}
          candidateDownloadFilterConfig={candidateDownloadFilterConfig}
          candidateCommunicationFilterConfig={candidateCommunicationFilterConfig}
        />
        <SegmentBulkShortlistConfirmationModal
          visible={segmentModalVisibility}
          includeAlreadyAssociatedCandidates={includeAlreadyAssociatedCandidates}
          eventSegmentModalCallbacks={eventSegmentModalCallbacks}
          onCancel={this.handleBulkShortListIconCancel}
          isBulkIconActivated={isBulkIconActivated}
        />
        {candidatesRecommendationStatus?.errorMessage ? (
          <SegmentBulkShortlistErrorModal
            visible={candidatesRecommendationStatus?.errorMessage}
            errorMessage={candidatesRecommendationStatus?.errorMessage}
            isBulkIconActivated={isBulkIconActivated}
            eventSegmentModalOnErrorCallbacks={eventSegmentModalOnErrorCallbacks}
            isAddIconloading={isAddIconloading}
          />
        ) : null}

        {!(activeTab !== 'favourite' && isCandidatePublishEnabled) ? (
          <div className="bulkOptionsFooterWrapper">
            <div className="bulkOptionsFooter">
              <div className="bulkOptionsSelection">
                {showBulkActivityText ? <div className="bulkActivityText"> <FormattedMessage {...message.toPerformBulkActivityLabel} /></div> : null}
                <Checkbox
                  key="select-this-page"
                  onChange={this.onCurrentPageCheckboxClick}
                  checked={getIsSelectThisPageChecked(
                    _currentSelectedAllCandidates,
                    aggregatedCandidates,
                    isCurrentPageChecked,
                    activeSourceName,
                    duplicateCandidateIds,
                    isExactMatchSearch
                  )}
                  disabled={isSelectThisPageDisabled}
                >
                  <FormattedMessage {...message.selectThisPageLabel} />
                </Checkbox>
                {candidateListType === 'aryarecommended' ? (
                  <Checkbox
                    key="select-all"
                    onChange={this.onAllPageCheckboxClick}
                    checked={isAryaRecommendedSelectAllChecked}
                    disabled={isBulkSelectionDisabled}
                  >
                     <FormattedMessage {...message.selectAllLabel} />
                  </Checkbox>
                ) : null}
                {isBulkSelectionAllowed ? (
                  <Checkbox
                    key="bulkSelection"
                    onChange={e => onSelectBulkCandidates(e, isSelectCustom, customSelectNumber)}
                    checked={isSelectAllChecked}
                    disabled={isBulkSelectionDisabled}
                  >
                    <Tooltip
                      title={`${maxBulkCandidatesSelectionCount} candidates from first page onwards will be selected`}
                    >
                      <span>
                      <FormattedMessage {...message.selectAllLabel} /> <span style={{ fontSize: '12px' }}>(max {maxBulkCandidatesSelectionCount})</span>
                      </span>
                    </Tooltip>
                  </Checkbox>
                ) : null}
                {isCandidateBulkSelectionInputEnabled && candidateContext !== 'segment' ? (
                  <InputNumber
                    min={1}
                    size="small"
                    value={inputNumber}
                    placeholder="Count"
                    onChange={this.onSelectionInputChange}
                  />
                ) : null}
                {isCandidateBulkSelectionInputEnabled &&
                  candidateContext === 'segment' &&
                  (activeTab === 'shortlisted' ||
                    (candidateListType === 'manualsearch' &&
                      (!isNonInternalPortalForSegmentEnabled ||
                        (isNonInternalPortalForSegmentEnabled && activeSourceName?.toLowerCase() === 'internal')))) ? (
                  <div>
                    <Checkbox key="custome-select" onChange={this.onSelectCustomClick} checked={isEnableCustomSelect}>
                    <FormattedMessage {...message.selectCustomLabel} />
                    </Checkbox>
                    <InputNumber
                      size="small"
                      value={customSelectNumber}
                      placeholder="Count"
                      disabled={!isEnableCustomSelect}
                      onChange={this.onCustomSelectChange}
                    />
                  </div>
                ) : null}
                {isBulkIconActivated ? (
                  <div
                    className="clearSelection"
                    role="button"
                    tabIndex={0}
                    onKeyPress={this.onClearSelection}
                    onClick={this.onClearSelection}
                  >
                    <FormattedMessage {...message.clearSelectionLabel} />
                  </div>
                ) : null}
                {isEnableCustomSelect && customInputValidationError ? (
                  <div className="customInputValidation">{customInputValidationError}</div>
                ) : null}
              </div>

              {!isCandidatePublishEnabled ? (
                <div className="bulkActions">
                  {!isEnableCustomSelect ? (
                    <div className="selectedCandidatesText">{this.handleSelectedCount()}</div>
                  ) : null}
                  {isPublishIconDisplayAllowed(isBulkRecommendEnabled, activeTab, candidateContext) ? (
                    <Icon
                      className={`bulkOptionIcon ${isBulkIconActivated && activeTab !== 'shortlisted' ? `activatedBulkOptionIcon` : ''
                        }`}
                      type={bulkShortlistApiStatus === 'INPROGRESS' ? 'loading' : 'like'}
                      theme={bulkShortlistApiStatus === 'INPROGRESS' ? 'outlined' : 'filled'}
                      onClick={isBulkIconActivated ? this.handleBulkShortlistIconClick : () => { }}
                      sk-event-name={appendCandidateStatus(eventTypes.candidate.bulkActions.shortlist, activeTab)}
                    />
                  ) : null}
                  {isBulkEmailEnabled &&
                    isEmailEnabled &&
                    !(candidateContext === 'segment' && candidateListType === 'manualsearch') ? (
                    <EmailIcon
                      onClick={isBulkIconActivated ? this.showEmailComposerModal : () => { }}
                      className={`${candidateBulkEmailIconClassName} ${activatedBulkOptionIconClassName}`}
                      sk-event-name={appendCandidateStatus(eventTypes.candidate.bulkActions.mail, activeTab)}
                    />
                  ) : null}
                  {isBulkMessageEnabled && isMessageEnabled && candidateContext === 'job' ? (
                    <TextIcon
                      onClick={isBulkIconActivated ? this.showMessageComposerModal : () => { }}
                      className={`bulkOptionIcon ${activatedBulkOptionIconClassName}`}
                      sk-event-name={appendCandidateStatus(eventTypes.candidate.bulkActions.sms, activeTab)}
                    />
                  ) : null}
                  {isBulkContactFetchEnabled &&
                    isBulkContactPullAllowed &&
                    isContactPullEnabled &&
                    candidateContext === 'job' &&
                    activeSourceName !== 'scoutingAgent' ? (
                    <ContactPullIcon
                      onClick={isBulkIconActivated ? this.handleContactPull : () => { }}
                      className={`bulkOptionIcon ${activatedBulkOptionIconClassName}`}
                      sk-event-name={appendCandidateStatus(eventTypes.candidate.bulkActions.contactPull, activeTab)}
                    />
                  ) : null}
                  {this.handleAddButton(isBulkIconActivated, activatedBulkOptionIconClassName, isAddIconloading)}
                  {this.handleDeleteButton(isBulkIconActivated, activatedBulkOptionIconClassName)}
                  {(isBulkDeleteAvailable && bulkRejectionApiStatus === 'INPROGRESS') ||
                    segmentBulkRejectApiStatus === 'INPROGRESS' ? (
                    <Icon type="loading" className={`bulkActionIcon ${activatedBulkOptionIconClassName}`} />
                  ) : null}
                  {isBulkTransferAvailable ? (
                    <CandidateTransfer
                      candidateTransferIconClassName={`bulkActionIcon ${candidateTransferIconClassName} bulkCandidateTransfer`}
                      onClickTransferIcon={() => this.handleBulkTranferIcon()}
                      isCandidateTransferModalVisible={isCandidateTransferModalVisible}
                      setCandidateTransferModalVisibility={this.setCandidateTransferModalVisibility}
                      candidateIds={candidateIds}
                      jobId={jobId}
                      tooltipTitle="Copy/Move"
                      invalidCandidateCallbackMessage="Please select atleast 1 candidate"
                      transferType="bulk"
                      fillColor="#01cd75"
                      version={version}
                      isEnableCustomSelect={isEnableCustomSelect}
                      customSelectNumber={customSelectNumber}
                      filterContextId={filterContextId}
                      isAryaRecommendedSelectAllChecked={isAryaRecommendedSelectAllChecked}
                      candidateContext={candidateContext}
                      clearBulkActions={this.onClearSelection}
                      selectedSubSegmentId={selectedSubSegmentId}
                    />
                  ) : null}
                  {activeTab === 'shortlisted' && isPublishingCandidates && Status !== 'Cancelled' ? (
                    <div className="candidate-multi-select-shortlist">
                      {ProductVariantName === 'Premium' ? (
                        <div>
                          <Button
                            onClick={this.bulkMoveToConnectedFromShortlisted}
                            shape="round"
                            disabled={!isBulkIconActivated}
                          >
                            <FormattedMessage {...message.moveToConnectedLabel} />
                          </Button>
                        </div>
                      ) : null}
                      {!IsFinalCommit ? (
                        <div>
                          <Button onClick={this.confirm} type="primary" shape="round" disabled={!isBulkIconActivated}>
                          <FormattedMessage {...message.publishCandidatesLabel} />
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {['connected', 'applied'].includes(activeTab?.toLowerCase()) &&
                    isPublishingCandidates &&
                    ProductVariantName === 'Premium' &&
                    Status !== 'Cancelled' ? (
                    <div className="candidate-multi-select-connected">
                      {activeTab?.toLowerCase() === 'connected' ? (
                        <div className="remove-from-connected">
                          <Button shape="round" onClick={this.bulkMoveToShortlisted} disabled={!isBulkIconActivated}>
                          <FormattedMessage {...message.removeFromConnectedLabel} />
                          </Button>
                        </div>
                      ) : null}
                      <div className="move-to-engaged">
                        <Button
                          type="primary"
                          shape="round"
                          onClick={this.bulkMoveToEngaged}
                          disabled={!isBulkIconActivated}
                        >
                          <FormattedMessage {...message.moveToEngagedLabel} />
                        </Button>
                      </div>
                    </div>
                  ) : null}
                  {activeTab === 'engaged' &&
                    isPublishingCandidates &&
                    ProductVariantName === 'Premium' &&
                    Status !== 'Cancelled' &&
                    !IsFinalCommit ? (
                    <div className="candidate-multi-select-engaged">
                      <div>
                        <Button shape="round" onClick={this.bulkMoveToConnected} disabled={!isBulkIconActivated}>
                        <FormattedMessage {...message.removeFromEngagedLabel} />
                        </Button>
                      </div>
                      <div>
                        <Button onClick={this.confirm} type="primary" shape="round" disabled={!isBulkIconActivated}>
                        <FormattedMessage {...message.publishCandidatesLabel} />
                        </Button>
                      </div>
                    </div>
                  ) : null}

                  {this.getEngagedCandidateBucket(activeTab, isBulkIconActivated)}
                </div>
              ) : (
                <div className="favouriteCandidatePublishButton">
                  <div className="selectedCandidatesText">{this.handleSelectedCount()}</div>
                  <PublishButton
                    selectedCandidates={selectedCandidates}
                    jobId={jobId}
                    publishedCandidates={publishedCandidates}
                    candidatePublishApiStatus={candidatePublishApiStatus}
                    setSelectedActiveCandidateCount={setSelectedActiveCandidateCount}
                    setSelectedCandidates={setSelectedCandidates}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}
        {addSubsegmentModalVisibilityStatus && !singleCandidateAdd && (
          <AddCandidateToSegmentPopover
            visibleStatus={addSubsegmentModalStatus}
            setVisible={setAddSubsegmentModalStatus}
            fetchSubSegments={fetchSubSegmentsAction}
            fetchSubSegmentApiStatus={fetchSubSegmentApiStatus}
            segmentId={jobId}
            includeAlreadyAssociatedCandidates={includeAlreadyAssociatedCandidates}
            eventSegmentModalCallbacks={eventSegmentModalCallbacks}
            showMatchingCandidateChecbox={showMatchingCandidateChecbox}
            handleOnclick={handleOnclick}
            subsegmentList={subsegmentList}
            setSubsegmentModalStatus={setSubsegmentModalStatus}
            shortlistedCount={shortlistedCount}
            singleCandidateAdd={singleCandidateAdd}
            setCreatedSubSegmentId={setCreatedSubSegmentId}
            lastCreatedCandidateId={lastCreatedCandidateId}
          />
        )}
        <DeleteCandidateSegmentModal
          setIsModalVisible={setSubsegmentDeleteModalStatusAction}
          isModalVisible={deleteSubsegmentModalStatus}
          handleOnDelete={this.handleSubsegmentDelete}
          SubSegmentId={selectedSubSegmentId}
          segmentBulkRejectApiStatus={segmentBulkRejectApiStatus}
        />
      </div>
    );
  }
}

export { CandidateBulkActionsContainer as CandidateBulkActionsContainerWithoutStore };

export default connect(mapStateToProps, mapDispatchToProps)(CandidateBulkActionsContainer);
