import React from 'react';
import { connect } from 'react-redux';
import { Button, Divider, Drawer, Spin, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import store from '../../../store';
import CandidateAggregationIcon from '../../../Icons/CandidateAggregationIcon';
import {
  getAllTabAggregationFilters,
  getManualSearchAllTabDisplayedCandidateCount,
  getAllTabSelectedAggregationFilters,
  getIsAllTabFiltersApplied,
  getAllTabFetchedCandidatesCount,
  getAllTabAggregationKeywordsOperation,
} from '../../../Reducers/ManualSearchCandidateReducer';
import * as manualSearchCandidateActions from '../../../Actions/ManualSearchCandidateActions';
import * as jobCandidatesTabActions from '../../../Actions/JobCandidatesTabActions';
import * as manualSearchActions from '../../../Actions/ManualSearchActions';
import styles from './AllTabCandidateAggregationFilterDrawer.module.scss';
import {
  reorderAllTabCandidatesAggregationFilters,
  getSelectedFiltersCandidatesCount,
  allTabDefaultCandidatesFilters,
  allTabFiltersDefaultKeywordsOperation,
} from '../../../Utils/CandidateListUtils';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import SkillsFilter from './SkillsFilter';
import SourcesFilter from './SourcesFilter';
import LastActivityFilter from './LastActivityFilter';
import TitlesFilter from './TitlesFilter';
import RelocationStatusFilter from './RelocationStatusFilter';
import LocationFilter from './LocationFilter';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import message from '../../../Components/Placeholders/PlaceholdersMessages';

const mapStateToProps = (state, props) => ({
  allTabAggregationFilter: getAllTabAggregationFilters(state, { jobId: props.jobId }),
  allTabCandidatesCount: getManualSearchAllTabDisplayedCandidateCount(state, { jobId: props.jobId }),
  allTabSelectedAggregationFilter: getAllTabSelectedAggregationFilters(state, { jobId: props.jobId }),
  isAllTabFiltersApplied: getIsAllTabFiltersApplied(state, { jobId: props.jobId }),
  featureToggleList: getFeatureToggleList(state),
  allTabFetchedCandidatesCount: getAllTabFetchedCandidatesCount(state, { jobId: props.jobId }),
  allTabKeywordsOperation: getAllTabAggregationKeywordsOperation(state, { jobId: props.jobId }),
  allTabAggregationStatus: getApiStatus(state, 'allTabAggregation'),
});

const mapDispatchToProps = {
  setAllTabSelectedCandidateFilters: manualSearchCandidateActions.setAllTabSelectedCandidateFilters,
  setIsAllTabCandidateAggregationAppliedFlag: manualSearchActions.setIsAllTabCandidateAggregationAppliedFlag,
  setJobCandidateTabPagination: jobCandidatesTabActions.setJobCandidateTabPagination,
};

const AllTabCandidateAggregationFilterDrawer = props => {
  const {
    allTabAggregationFilter = {},
    form,
    setAllTabSelectedCandidateFilters,
    jobId,
    fetchManualSearchCandidateList,
    setIsAllTabCandidateAggregationAppliedFlag,
    allTabSelectedAggregationFilter,
    isAllTabFiltersApplied,
    setJobCandidateTabPagination,
    allTabKeywordsOperation,
    candidateContext,
    allTabCandidatesCount,
    allTabAggregationStatus,
  } = props;

  const [isDrawerVisible, setDrawerVisibility] = React.useState(false);
  const [isFilterTooltipvisible, toggleTooltipVisibilty] = React.useState(false);

  const drawerHeaderRef = React.useRef(null);
  const filterRef = React.useRef(null);
  const skillsRef = React.useRef(null);

  React.useEffect(() => {
    if (isDrawerVisible && drawerHeaderRef.current) {
      drawerHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [isDrawerVisible]);

  const filterSectionCommonStyles = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(210px, 1fr))',
    gap: '12px',
    maxHeight: '200px',
    overflowY: 'auto',
  };

  const sortedAllTabAggregationFilter = reorderAllTabCandidatesAggregationFilters(
    allTabAggregationFilter,
    allTabSelectedAggregationFilter
  );

  const candidatesSkillsField = 'candidatesSkills';
  const candidatesTitlesField = 'candidatesTitles';
  const candidatesRelocationStatusField = 'candidatesRelocationStatus';
  const candidatesLastActivityField = 'candidatesLastActivity';
  const candidatesLocationField = 'candidatesLocation';
  const candidatesSourceField = 'candidatesSource';
  const candidatesSkillsOperationField = 'candidatesSkillsOperation';

  const getShowCandidatesCount = () => {
    const {
      candidatesSkills,
      candidatesTitles,
      candidatesRelocationStatus,
      candidatesLastActivity,
      candidatesLocation,
      candidatesSource,
      candidatesSkillsOperation,
    } = form.getFieldsValue();

    const selectedFilters = {
      Skills: candidatesSkills || [],
      Titles: candidatesTitles || [],
      RelocationStatus: candidatesRelocationStatus || [],
      LastActivity: candidatesLastActivity || [],
      Location: candidatesLocation || [],
      Source: candidatesSource || [],
    };
    const nonEmptyFields = Object.values(selectedFilters).filter(array => array.length);
    if (!nonEmptyFields.length) return -1;
    return [
      ...getSelectedFiltersCandidatesCount({
        selectedFilters,
        allTabAggregationFilter,
        store: store.getState(),
        jobId,
        keywordsOperation: candidatesSkillsOperation || allTabFiltersDefaultKeywordsOperation,
      }),
    ].length;
  };
  const candidatesCount = getShowCandidatesCount();
  const showResultsButtonText = candidatesCount > -1 ? `Show ${candidatesCount} Results` : 'Show Results';

  const getIsAggregationFilterSelected = () => {
    const {
      candidatesSkills,
      candidatesTitles,
      candidatesRelocationStatus,
      candidatesLastActivity,
      candidatesLocation,
      candidatesSource,
    } = form.getFieldsValue();

    return [
      candidatesSkills,
      candidatesTitles,
      candidatesRelocationStatus,
      candidatesLastActivity,
      candidatesLocation,
      candidatesSource,
    ].some(array => array?.length > 0);
  };

  const drawerTitle = (
    <div ref={drawerHeaderRef}>
      <div className={styles.candidateAggregationIconAndTitle}>
        <CandidateAggregationIcon /> Filters
        <Spin spinning={allTabAggregationStatus === 'INPROGRESS'}></Spin>
      </div>
    </div>
  );

  const onClickFilterAggregationIcon = () => {
    toggleTooltipVisibilty(false);
    if (filterRef.current) {
      filterRef.current.scrollTop = 0;
    }
    if (skillsRef.current) {
      skillsRef.current.scrollToCell({ columnIndex: 0, rowIndex: 0 });
    }
  };

  const onCloseDrawer = () => {
    setDrawerVisibility(false);
  };

  const onClickApply = async () => {
    const {
      candidatesSkills,
      candidatesTitles,
      candidatesRelocationStatus,
      candidatesLastActivity,
      candidatesLocation,
      candidatesSource,
      candidatesSkillsOperation,
    } = form.getFieldsValue();
    setDrawerVisibility(false);
    const selectedFilters = {
      Skills: candidatesSkills || [],
      Titles: candidatesTitles || [],
      RelocationStatus: candidatesRelocationStatus || [],
      LastActivity: candidatesLastActivity || [],
      Location: candidatesLocation || [],
      Source: candidatesSource || [],
    };
    await setAllTabSelectedCandidateFilters({
      jobId,
      selectedFilters,
      keywordsOperation: candidatesSkillsOperation || allTabFiltersDefaultKeywordsOperation,
    });
    const hasNonEmptySelectedAggregation = Object.values(selectedFilters).some(array => array.length > 0);
    const isFilterAppliedFlag = hasNonEmptySelectedAggregation;
    await setIsAllTabCandidateAggregationAppliedFlag({ jobId, isFilterAppliedFlag });
    const redirectToPage = 1;
    await setJobCandidateTabPagination({
      currPage: redirectToPage,
    });
    fetchManualSearchCandidateList(redirectToPage);
  };

  const onClickReset = async () => {
    form.setFieldsValue({
      [candidatesSkillsField]: [],
      [candidatesTitlesField]: [],
      [candidatesRelocationStatusField]: [],
      [candidatesLastActivityField]: [],
      [candidatesLocationField]: [],
      [candidatesSourceField]: [],
      [candidatesSkillsOperationField]: allTabFiltersDefaultKeywordsOperation,
    });
    setDrawerVisibility(false);
    if (isAllTabFiltersApplied) {
      await setAllTabSelectedCandidateFilters({
        jobId,
        selectedFilters: allTabDefaultCandidatesFilters,
        keywordsOperation: allTabFiltersDefaultKeywordsOperation,
      });
      await setIsAllTabCandidateAggregationAppliedFlag({ jobId, isFilterAppliedFlag: false });
      const redirectToPage = 1;
      await setJobCandidateTabPagination({
        currPage: redirectToPage,
      });
      fetchManualSearchCandidateList(redirectToPage);
    }
  };

  const onClickClear = field => {
    form.setFieldsValue({
      [field]: [],
    });
  };

  return candidateContext === 'job' && allTabCandidatesCount ? (
    <>
      <div
        onClick={() => {
          setDrawerVisibility(true);
        }}
        style={{ cursor: 'pointer' }}
        className={styles.allTabAggregationFilterDrawer}
      >
        {isAllTabFiltersApplied ? <div className={styles.dot}></div> : null}
        <div
          onMouseMove={() => toggleTooltipVisibilty(true)}
          onMouseLeave={() => toggleTooltipVisibilty(false)}
          onClick={onClickFilterAggregationIcon}
        >
          <Tooltip title="Filters" zIndex={1} visible={isFilterTooltipvisible}>
            <CandidateAggregationIcon />
          </Tooltip>
        </div>
      </div>
      <Drawer
        placement="right"
        onClose={onCloseDrawer}
        visible={isDrawerVisible}
        title={drawerTitle}
        width={900}
        bodyStyle={{ padding: '0px' }}
      >
        <div className={styles.aggregationDrawer}>
          <div className={styles.aggregationDrawerSectionsContainer}>
            <SourcesFilter
              sortedSources={sortedAllTabAggregationFilter.Source}
              candidatesSourceField={candidatesSourceField}
              selectedSources={allTabSelectedAggregationFilter.Source}
              filterSectionCommonStyles={filterSectionCommonStyles}
              onClickClear={onClickClear}
              form={form}
            />

            <Divider className={styles.dividerLine} />

            <LastActivityFilter
              sortedLastActivity={sortedAllTabAggregationFilter.LastActivity}
              candidatesLastActivityField={candidatesLastActivityField}
              selectedLastActivity={allTabSelectedAggregationFilter.LastActivity}
              filterSectionCommonStyles={filterSectionCommonStyles}
              onClickClear={onClickClear}
              form={form}
            />

            <Divider className={styles.dividerLine} />

            <SkillsFilter
              sortedAllTabAggregationFilter={sortedAllTabAggregationFilter}
              form={form}
              candidatesSkillsOperationField={candidatesSkillsOperationField}
              allTabKeywordsOperation={allTabKeywordsOperation}
              onClickClear={onClickClear}
              candidatesSkillsField={candidatesSkillsField}
              allTabSelectedAggregationFilter={allTabSelectedAggregationFilter}
              filterRef={skillsRef}
            />

            <Divider className={styles.dividerLine} />

            <TitlesFilter
              sortedTitles={sortedAllTabAggregationFilter.Titles}
              candidatesTitlesField={candidatesTitlesField}
              selectedTitles={allTabSelectedAggregationFilter.Titles}
              filterSectionCommonStyles={filterSectionCommonStyles}
              onClickClear={onClickClear}
              form={form}
              filterRef={filterRef}
            />

            <Divider className={styles.dividerLine} />

            <RelocationStatusFilter
              sortedRelocationStatus={sortedAllTabAggregationFilter.RelocationStatus}
              candidatesRelocationStatusField={candidatesRelocationStatusField}
              selectedRelocationStatus={allTabSelectedAggregationFilter.RelocationStatus}
              onClickClear={onClickClear}
              form={form}
            />

            <Divider className={styles.dividerLine} />

            <LocationFilter
              sortedLocation={sortedAllTabAggregationFilter.Location}
              candidatesLocationField={candidatesLocationField}
              selectedLocation={allTabSelectedAggregationFilter.Location}
              filterSectionCommonStyles={filterSectionCommonStyles}
              onClickClear={onClickClear}
              form={form}
              filterRef={filterRef}
            />
          </div>

          <Divider className={styles.dividerLine} />

          <div className={styles.aggregationFilterFooter}>
            <Button
              type="primary"
              onClick={onClickApply}
              shape="round"
              disabled={candidatesCount === 0 || (!isAllTabFiltersApplied && !getIsAggregationFilterSelected())}
            >
              {showResultsButtonText}
            </Button>
            <Button type="secondary" shape="round" onClick={onClickReset}>
            <FormattedMessage {...message.resetLabel}/>
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(AllTabCandidateAggregationFilterDrawer);
export { AllTabCandidateAggregationFilterDrawer as AllTabCandidateAggregationFilterDrawerWithoutStore };
