import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Button } from 'antd';
import _ from 'lodash';
import applicationSettingMessages from '../../ApplicationSettings/ApplicationSettingMessages';

export default function AuthenticationButton({
  portalSettings,
  index,
  isAuthenticated,
  form,
  credentialsValidator,
  mandatoryFields,
  onClickAuthenticationButton,
}) {
  return (
    <Form.Item>
      <div className="btn-container">
        <Button
          htmlType="submit"
          loading={_.get(portalSettings, ['AuthenticationApiStatus', index], '') === 'INPROGRESS'}
          onClick={onClickAuthenticationButton}
          className={isAuthenticated ? 'disconnect-button' : 'authenticate-button'}
          shape="round"
          disabled={
            credentialsValidator.hasError(form.getFieldsError()) ||
            credentialsValidator.anyFieldEmpty(form, mandatoryFields)
          }
          block
        >
          {isAuthenticated ? (
            <FormattedMessage {...applicationSettingMessages.authenticationButtonDisconnect} />
          ) : (
            <FormattedMessage {...applicationSettingMessages.authenticationButtonAuthenticate} />
          )}
        </Button>
      </div>
    </Form.Item>
  );
}
