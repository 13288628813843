import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Collapse, Switch, Button, Row, Affix, Icon } from 'antd';
import GlobalInsightPanel from './GlobalInsightPanel';
import {
  getCheckBoxIntialValues,
  getFliterSupllyViewList,
  getMapingStatsList,
  getExperienceLabelName,
  getCurrentFilterFormValues,
} from '../../../Utils/DashBoardUtils';
import styles from './GlobalInsightSupplyFilter.module.scss';
import jobMessage from '../../JobForm/JobMessages';

const { Panel } = Collapse;

export default function GlobalInsightSupplyFilter(props) {
  const {
    form,
    globalInsightSupplyList,
    supplyPayload,
    filterActiveSwitchKeys,
    supplyFilterHandle,
    handleDropdownVisibleChange,
  } = props;
  const { CompaniesStats, IndustriesStats, SkillsStats, EducationsStats, ExperienceRangeStats, Total } =
    globalInsightSupplyList;
  const listOfCompaniesStats = getMapingStatsList(CompaniesStats, 'CompanyName', Total);
  const listOfIndustriesStats = getMapingStatsList(IndustriesStats, 'IndustryName', Total);
  const listOfSkillsStats = getMapingStatsList(SkillsStats, 'SkillName', Total);
  const experienceRanges = getMapingStatsList(ExperienceRangeStats, 'Range', Total);
  const initialSupplyPayload = {
    Companies: [...supplyPayload.Companies].sort(),
    Industries: [...supplyPayload.Industries].sort(),
    Skills: [...supplyPayload.Skills].sort(),
    Educations: [...supplyPayload.Educations].sort(),
    Experiences: [...supplyPayload.Experiences].sort(),
  };
  const listOfExperienceRangeStats = experienceRanges.map(obj => ({
    ...obj,
    DisplayName: getExperienceLabelName(obj.Name, '<'),
  }));
  const listOfEducationStats = getMapingStatsList(EducationsStats, 'Degree', Total);

  const [activeSwitchKeys, setActiveSwitchKeys] = React.useState(filterActiveSwitchKeys);
  const [initialFormValues, setInitialFormValue] = React.useState(supplyPayload);

  const onSwitchChange = (checked, currentSwitchKey) => {
    if (checked) {
      setActiveSwitchKeys(activeSwitchKeys.concat(currentSwitchKey));
    } else {
      setActiveSwitchKeys(activeSwitchKeys.filter(activeSwitchKey => activeSwitchKey !== currentSwitchKey));
    }
  };

  const onFilterApply = () => {
    form.validateFields((err, values) => {
      const formValues = _.cloneDeep(values);
      const filterFormValues = {
        Companies: [],
        Industries: [],
        Skills: [],
        Educations: [],
        Experiences: [],
      };
      if (activeSwitchKeys.includes('Skills') && formValues.filterSkills) {
        filterFormValues.Skills = formValues.filterSkills;
      }
      if (activeSwitchKeys.includes('Industries') && formValues.filterIndustries) {
        filterFormValues.Industries = formValues.filterIndustries;
      }
      if (activeSwitchKeys.includes('Companies') && formValues.filterCompanies) {
        filterFormValues.Companies = formValues.filterCompanies;
      }
      if (activeSwitchKeys.includes('Experiences') && formValues.filterExperience) {
        const experienceList = [];
        (formValues.filterExperience || []).forEach(value => {
          const fromAndToValues = _.split(value, '-', 2);
          if (fromAndToValues.length === 2) {
            if (fromAndToValues[0] === '*') {
              experienceList.push({ To: _.toInteger(fromAndToValues[1]) });
            } else if (fromAndToValues[1] === '*') {
              experienceList.push({ From: _.toInteger(fromAndToValues[0]) });
            } else {
              experienceList.push({ From: _.toInteger(fromAndToValues[0]), To: _.toInteger(fromAndToValues[1]) });
            }
          }
        });
        filterFormValues.Experiences = experienceList;
      }
      if (activeSwitchKeys.includes('Educations') && formValues.filterEducations) {
        filterFormValues.Educations = formValues.filterEducations;
      }
      supplyFilterHandle(filterFormValues, activeSwitchKeys);
    });
  };

  const onHandleAddButtonClick = (keyParameter, searchkey, filterName) => {
    const searchKeyValue = form.getFieldValue(searchkey);
    if (searchKeyValue) {
      const formValues = _.cloneDeep(initialFormValues);
      formValues[keyParameter].push(_.trim(searchKeyValue));
      setInitialFormValue(formValues);
      const filterValues = _.union(form.getFieldValue(filterName), getCheckBoxIntialValues([_.trim(searchKeyValue)]));
      form.setFieldsValue({ [searchkey]: '', [filterName]: filterValues });
    }
  };

  const generateSwitchElement = key => (
    <div className={styles.switchElement}>
      {activeSwitchKeys.includes(key) ? `Enabled ` : `Disabled `}
      <Switch
        size="small"
        defaultChecked
        onClick={(checked, event) => {
          event.stopPropagation();
        }}
        checked={activeSwitchKeys.includes(key)}
        onChange={checked => {
          onSwitchChange(checked, key);
        }}
      />
    </div>
  );

  const isFilterNotApplied = () => {
    return (
      _.isEqual([...filterActiveSwitchKeys].sort(), [...activeSwitchKeys].sort()) &&
      _.isEqual(initialSupplyPayload, getCurrentFilterFormValues(form.getFieldsValue()))
    );
  };

  return (
    <div className={styles.supplyFilterContainer}>
      <Affix offsetTop={68} offsetBottom={1} className={styles.supplyFilterAffix}>
        <Collapse accordion className={styles.supplyCollapseView}>
          <Panel
            showArrow={false}
            header="Advance Filter"
            extra={<Icon type="close" onClick={() => handleDropdownVisibleChange(false)} />}
          />
          <Panel header="Similar Skills" key="Skills" extra={generateSwitchElement('Skills')}>
            <GlobalInsightPanel
              form={form}
              placeHolder="Add Skills"
              searchKeyWord="searchSkillKeyWord"
              onHandleAddButtonClick={onHandleAddButtonClick}
              keyParameter="Skills"
              initialValue={getCheckBoxIntialValues(initialFormValues.Skills)}
              dataSource={getFliterSupllyViewList(listOfSkillsStats, initialFormValues.Skills)}
              filterName="filterSkills"
              title={<FormattedMessage {...jobMessage.selectedSkillLabel} />}
              isSearchAllowed
              isAddAllowed
              emptyDescription={<FormattedMessage {...jobMessage.skillsEmptyDescription} />}
              disabled={!activeSwitchKeys.includes('Skills')}
            />
          </Panel>
          <Panel header="Similar Industry" key="Industries" extra={generateSwitchElement('Industries')}>
            <GlobalInsightPanel
              form={form}
              placeHolder="Add Industry"
              searchKeyWord="searchIndustrieKeyWord"
              onHandleAddButtonClick={onHandleAddButtonClick}
              keyParameter="Industries"
              initialValue={getCheckBoxIntialValues(initialFormValues.Industries)}
              dataSource={getFliterSupllyViewList(listOfIndustriesStats, initialFormValues.Industries)}
              filterName="filterIndustries"
              title={<FormattedMessage {...jobMessage.selectIndustryLabel} />}
              emptyDescription={<FormattedMessage {...jobMessage.industryEmptyDescriptionLabel} />}
              disabled={!activeSwitchKeys.includes('Industries')}
            />
          </Panel>
          <Panel header="Companies" key="Companies" extra={generateSwitchElement('Companies')}>
            <GlobalInsightPanel
              form={form}
              placeHolder="Add Companies"
              searchKeyWord="searchCompanieKeyWord"
              onHandleAddButtonClick={onHandleAddButtonClick}
              keyParameter="Companies"
              initialValue={getCheckBoxIntialValues(initialFormValues.Companies)}
              dataSource={getFliterSupllyViewList(listOfCompaniesStats, initialFormValues.Companies)}
              filterName="filterCompanies"
              title={<FormattedMessage {...jobMessage.selectEmployerLabel} />}
              emptyDescription={<FormattedMessage {...jobMessage.emptySourcedEmployerDescriptionLabel} />}
              disabled={!activeSwitchKeys.includes('Companies')}
            />
          </Panel>
          <Panel header="Experience" key="Experiences" extra={generateSwitchElement('Experiences')}>
            <GlobalInsightPanel
              form={form}
              placeHolder="Add Experience"
              searchKeyWord="searchExperienceKeyWord"
              onHandleAddButtonClick={onHandleAddButtonClick}
              keyParameter="Experiences"
              initialValue={getCheckBoxIntialValues(initialFormValues.Experiences)}
              dataSource={listOfExperienceRangeStats}
              filterName="filterExperience"
              title={<FormattedMessage {...jobMessage.yearsLabel} />}
              emptyDescription={<FormattedMessage {...jobMessage.emptySourcedExperienceDescriptionLabel} />}
              disabled={!activeSwitchKeys.includes('Experiences')}
            />
          </Panel>
          <Panel header="Education" key="Educations" extra={generateSwitchElement('Educations')}>
            <GlobalInsightPanel
              form={form}
              placeHolder="Add Education"
              searchKeyWord="searchEducationKeyWord"
              onHandleAddButtonClick={onHandleAddButtonClick}
              keyParameter="Educations"
              initialValue={getCheckBoxIntialValues(initialFormValues.Educations)}
              dataSource={getFliterSupllyViewList(listOfEducationStats, initialFormValues.Educations)}
              filterName="filterEducations"
              title={<FormattedMessage {...jobMessage.degreeLabel} />}
              emptyDescription={<FormattedMessage {...jobMessage.emptySourcedEducationDescriptionLabel} />}
              disabled={!activeSwitchKeys.includes('Educations')}
            />
          </Panel>
        </Collapse>
        <Row justify="center" className={styles.supplyApplyFilterRow}>
          <Button type="primary" shape="round" onClick={onFilterApply} disabled={isFilterNotApplied()}>
            title={<FormattedMessage {...jobMessage.applyFilterLabel} />}{' '}
          </Button>
        </Row>
      </Affix>
    </div>
  );
}
