import { Form, Input, Modal, Button, Skeleton, Select } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import React from 'react';
import styles from './AddListModal.module.scss';
import { validateSegmentDescription, validateSegmentTitle } from '../../../Utils/SegmentValidationUtils';
import message from '../ListsMessages';
import placeholder from '../../../Components/Placeholders/PlaceholdersMessages';

const { Option } = Select;

function AddListModal(props) {
  const {
    isModalVisible,
    handleCancel,
    handleOk,
    title,
    buttonName,
    form,
    isLoading,
    inputText,
    characterLimit,
    onDescriptionInputChange,
    jobDetailsStatus,
    isOwnerEnabled,
    ownerOptions,
    textAreaStyle,
    maxCharactersAllowed,
    modalType,
    intl,
  } = props;
  const handleChange = event => {
    onDescriptionInputChange(event.target.value);
  };

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      centered
      onCancel={handleCancel}
      footer={[
        <Button shape="round" key="back" onClick={handleCancel}>
          <FormattedMessage {...message.cancelButtonLabel} />
        </Button>,
        <Button shape="round" key="submit" type="primary" onClick={handleOk} loading={isLoading}>
          {buttonName}
        </Button>,
      ]}
    >
      <Skeleton active paragraph={{ rows: 4 }} loading={jobDetailsStatus === 'INPROGRESS'}>
        <Form>
          <div className={styles.modalContent}>
            <Form.Item rules={[{ required: true }]}>
              {form?.getFieldDecorator('listName', {
                rules: [
                  {
                    required: true,
                    validator: (rule, value, callback) =>
                      validateSegmentTitle(form, value, callback, maxCharactersAllowed, modalType),
                  },
                ],
              })(<Input placeholder="Enter a name" />)}
            </Form.Item>
            <br />
            <br />
            <div className={styles.modalTextArea}>
              <Form.Item>
                {form?.getFieldDecorator('listDescription', {
                  rules: [{ validator: validateSegmentDescription }],
                })(
                  <Input.TextArea
                    placeholder="Enter short description"
                    style={{ height: '100px', ...textAreaStyle }}
                    onChange={handleChange}
                  />
                )}
              </Form.Item>
              {(inputText?.trim()?.length || 0) <= characterLimit ? (
                <p style={{ textAlign: 'right' }}>
                  {inputText?.trim()?.length || 0}/{characterLimit}
                </p>
              ) : (
                <p style={{ textAlign: 'right', color: '#f5222d' }}>
                  {characterLimit - inputText?.trim()?.length}/{characterLimit}
                </p>
              )}
            </div>
            <div>
              {isOwnerEnabled && (
                <Form.Item label="Owner" className={styles.ownerSelect}>
                  {form?.getFieldDecorator('ownerId')(
                    <Select onChange={() => {}}>
                      {ownerOptions
                        ?.filter(option => option?.name)
                        .map(option => (
                          <Option value={option.Id}>
                            {option.name.length > 50 ? option.name.slice(0, 50).concat('...') : option.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
              )}
            </div>
          </div>
        </Form>
      </Skeleton>
    </Modal>
  );
}

export default injectIntl(AddListModal);
export { AddListModal as AddListModalWithoutIntl };
