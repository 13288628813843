import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { CheckCircleIcon } from '../../Icons/AryaIcons';
import styles from './AoDRequestQuoteSuccessModal.module.scss';
import jobMessage from '../JobForm/JobMessages';

function AoDRequestQuoteSuccessModal(props) {
  const { onClose, isVisible } = props;
  return (
    <Modal
      width={400}
      title={<FormattedMessage {...jobMessage.applicantsOnDemandLabel} />}
      okText={<FormattedMessage {...jobMessage.confirmationLabel} />}
      okType="primary"
      okButtonProps={{ shape: 'round', style: { height: '26px' } }}
      onOk={onClose}
      cancelButtonProps={{ style: { visibility: 'hidden' } }}
      onCancel={onClose}
      visible={isVisible}
      className={styles.successModal}
    >
      <div className={styles.modalContent}>
        <div>
          <CheckCircleIcon style={{ fontSize: '23px', marginTop: '4px' }} />
        </div>
        <div className={styles.modalDescription}>
          <FormattedMessage {...jobMessage.applicantsOnDemandInterestConfirmationMessage} />
        </div>
      </div>
    </Modal>
  );
}

export default AoDRequestQuoteSuccessModal;
