import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PubSub from 'pubsub-js';
import { debounce } from 'lodash';
import { Form } from 'antd';
import RichTextEditor from '../Editor/RichTextEditor';
import { WarningAlert, SuccessAlert, LoadingAlert } from './JobDescriptionAlert';
import JobDescriptionRecommendationModal from './JobDescriptionRecommendationModal/JobDescriptionRecommendationModal';
import JobDescriptionRecommendationButton from './JobDescriptionRecommendationButton/JobDescriptionRecommendationButton';
import { getHtmlDescriptionForDisplay } from '../../Utils/JobDescriptionUtils';
import { getGtagPayload, recommendedJobDescription } from '../../Utils/JobUtils';
import {
  GO_TO_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE,
  SELECT_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE,
} from '../../PubSub/EventTypes';
import styles from './JobDescription.module.scss';
import placeholder from '../Placeholders/PlaceholdersMessages';

const FormItem = Form.Item;

const requirementsWarningMessage =
  'Did you mention job requirements in the description? Well-defined job requirements help communicate the right expectations to candidates.';
const requirementsSuccessMessage = 'Requirements are clear';
const responsibilitiesWarningMessage =
  'Have you mentioned the key responsibilities for this role? Give your hiring process a boost by clearly defining responsibilities. This will increase engagement from the right candidates.';
const responsibilitiesSuccessMessage = 'Responsibilities are clear';
const requirementsLoadingMessage = 'Validation in progress for requirements';
const responsibilitiesLoadingMessage = 'Validation in progress for responsibilities';

function JobDescription(props) {
  const {
    jobForm,
    parseJobDescription,
    updateDescription,
    parsedJobData,
    parserApiStatus,
    isRecommendationButtonRequired,
    suggestedJobDescriptions,
    descriptionSuggestionApiStatus,
    suggestJobDescriptions,
    jobDetails,
    recommendationModalInitialValues,
    formProperties,
    intl,
  } = props;

  const {
    fieldDecorator,
    fieldLabel,
    fieldColon,
    labelCssClassName,
    editorCssClassName,
    validator,
    initialValue,
    isRequired,
  } = formProperties;

  const [recommendationModalVisibility, setRecommendationModalVisibility] = React.useState(false);
  const debouncedJDParsing = React.useCallback(
    debounce((nextValue, callback) => parseJobDescription(nextValue, callback), 600),
    []
  );

  useEffect(() => {
    if (initialValue) {
      debouncedJDParsing(initialValue);
    }
  }, []);

  // eslint-disable-next-line no-unused-vars
  const onEditorBlur = (previousRange, source, editor) => {
    if (source === 'silent') {
      setTimeout(() => {
        const currentDescriptionValue = jobForm.getFieldValue(fieldDecorator ?? 'JobDescription');
        debouncedJDParsing(currentDescriptionValue?.htmlContent);
      }, 1 * 500);
    } else {
      debouncedJDParsing(editor.getHTML());
    }
  };

  const onDescriptionChange = ({ htmlContent, plainTextContent }) => {
    if (updateDescription) {
      updateDescription({ htmlContent, plainTextContent });
    }
  };

  const onDescriptionRecommendationClick = () => {
    setRecommendationModalVisibility(true);
    const gtagPayload = getGtagPayload(jobDetails);
    PubSub.publish(GO_TO_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE, gtagPayload);
  };

  const getValidDataForSuggestion = () => {
    if (!suggestedJobDescriptions?.length) return { requirements: [], responsibilities: [] };
    const nonZeroLengthDescription = suggestedJobDescriptions.find(
      description => description.Requirements.length && description.Responsibilities.length
    );
    const descriptionForDisplay = nonZeroLengthDescription ?? suggestedJobDescriptions[0];
    return {
      requirements: descriptionForDisplay.Requirements,
      responsibilities: descriptionForDisplay.Responsibilities,
    };
  };

  const addDescriptionFromTemplateSelectedOptions = (selectedRequirements, selectedResponsibilities) => {
    const currentHtmlDescription = jobForm.getFieldValue(fieldDecorator ?? 'JobDescription').htmlContent;
    const suggestedReqAndRes = getValidDataForSuggestion();
    const reqAndResData = {
      selectedValues: {
        requirements: selectedRequirements,
        responsibilities: selectedResponsibilities,
      },
      suggestedValues: {
        requirements: suggestedReqAndRes.requirements,
        responsibilities: suggestedReqAndRes.responsibilities,
      },
    };
    const htmlDescription = getHtmlDescriptionForDisplay(currentHtmlDescription, reqAndResData);
    const currentFieldDecorator = fieldDecorator ?? 'JobDescription';
    jobForm.setFieldsValue({ [currentFieldDecorator]: { htmlContent: htmlDescription } });
    debouncedJDParsing(htmlDescription);
    const gtagPayload = getGtagPayload(jobDetails, recommendedJobDescription, {
      selectedRequirements,
      selectedResponsibilities,
    });
    PubSub.publish(SELECT_JOB_DESCRIPTION_RECOMMENDATION_TEMPLATE, gtagPayload);
  };

  const getAlertForRequirements = () => {
    if (parserApiStatus === 'INPROGRESS') return <LoadingAlert text={requirementsLoadingMessage} />;
    if (parsedJobData?.Requirements?.length > 0) return <SuccessAlert text={requirementsSuccessMessage} />;
    return <WarningAlert text={requirementsWarningMessage} />;
  };

  const getAlertForResponsibilities = () => {
    if (parserApiStatus === 'INPROGRESS') return <LoadingAlert text={responsibilitiesLoadingMessage} />;
    if (parsedJobData?.Responsibilities?.length > 0) return <SuccessAlert text={responsibilitiesSuccessMessage} />;
    return <WarningAlert text={responsibilitiesWarningMessage} />;
  };

  const isRecommendationButtonInActive =
    descriptionSuggestionApiStatus === 'FAILED' ||
    (descriptionSuggestionApiStatus === 'COMPLETED' && !suggestedJobDescriptions?.length);

  const currentHtmlDescription = jobForm.getFieldValue(fieldDecorator ?? 'JobDescription')?.htmlContent;

  return (
    <div>
      <FormItem
        label={fieldLabel ?? 'Job Description'}
        colon={fieldColon ?? false}
        className={labelCssClassName ?? styles.labelClassName}
      >
        {jobForm.getFieldDecorator(fieldDecorator ?? 'JobDescription', {
          rules: [
            {
              required: isRequired,
              validator,
            },
          ],
          initialValue: {
            htmlContent: initialValue,
          },
          valuePropName: 'editorContent',
        })(
          <RichTextEditor
            showToolbar
            imageSupport={false}
            className={editorCssClassName ?? styles.editorClassName}
            onChange={onDescriptionChange}
            onBlur={onEditorBlur}
            placeholder={intl.formatMessage({ ...placeholder.pasteOrEnterJobDescriptionLabel })}
          >
            {isRecommendationButtonRequired && (
              <JobDescriptionRecommendationButton
                onDescriptionRecommendationClick={onDescriptionRecommendationClick}
                isInActive={isRecommendationButtonInActive}
                isLoading={descriptionSuggestionApiStatus === 'INPROGRESS'}
              />
            )}
          </RichTextEditor>
        )}
      </FormItem>
      <JobDescriptionRecommendationModal
        visibility={recommendationModalVisibility}
        onCancel={() => setRecommendationModalVisibility(false)}
        suggestedJobDescription={getValidDataForSuggestion()}
        descriptionSuggestionApiStatus={descriptionSuggestionApiStatus}
        suggestJobDescriptions={suggestJobDescriptions}
        initialValues={recommendationModalInitialValues}
        addDescriptionFromTemplateSelectedOptions={addDescriptionFromTemplateSelectedOptions}
        jobDescription={currentHtmlDescription}
      />
      {parserApiStatus !== 'FAILED' && (
        <div className={styles.alertsWrapper}>
          {getAlertForRequirements()}
          {getAlertForResponsibilities()}
        </div>
      )}
    </div>
  );
}

export default injectIntl(JobDescription);
export { JobDescription as JobDescriptionWithoutInjectIntl };
