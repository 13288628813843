/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { removeCommaFromEnd } from './TextUtils';
import message from '../Containers/CandidateDrawer/messages';

const getMatchingJobRedirectionLink = jobCode => {
  const { pathname } = window.location;
  const clientName = pathname.split('/')[1];
  return `/${clientName}/Job/JobViewNew?id=${jobCode}`;
};
const getMatchingJobCodeText = jobCode => {
  if (!jobCode) return '';
  return (
    <span>
      <FormattedMessage {...message.jobMatchingClientJobIdLabel} />: {jobCode} |
    </span>
  );
};

export const candidateJobMatchingfilters = {
  location: 'jobLocationFilter',
  industry: 'jobIndustryFilter',
  title: 'jobTitleFilter',
  experience: 'jobExperienceFilter',
};

export const getCandidateJobMatchingLocation = (location, jobCountryName) =>
  location ? `${removeCommaFromEnd(location)}, ${jobCountryName}` : jobCountryName;

export { getMatchingJobRedirectionLink, getMatchingJobCodeText };
