import React from 'react';
import { Modal, Button, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './ChatGptRestartConfirmationModal.module.scss';
import message from '../../Containers/CandidateDrawer/messages';

export default function ChatGptRestartConfirmationModal(props) {
  const { visible, onCancel, onConfirm } = props;
  return (
    <div>
      <Modal
        title={ <FormattedMessage {...message.restartAlertLabel} />}
        visible={visible}
        footer={[
          <Button shape="round" key="back" onClick={onCancel}>
           <FormattedMessage {...message.cancelLabel} />
          </Button>,
          <Button shape="round" key="submit" type="primary" onClick={onConfirm}>
          <FormattedMessage {...message.yesLabel} />
          </Button>,
        ]}
        onCancel={onCancel}
        zIndex={3200}
        centered
      >
        <div className={styles.modalContainer}>
          <div className={styles.iconDiv}>
            <Icon type="question-circle" theme="filled" />
          </div>
          <div>
            <div className={styles.restartConfirmationContent}>
            <FormattedMessage {...message.restartWarningLabel} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
