import React from 'react';
import { Modal, Button, Radio, Icon, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './SubSegmentStyles.module.scss';
import message from '../ManualSearchV2/ManualSearchMessages';

const DeleteCandidateSegmentModal = props => {
  const { isModalVisible, setIsModalVisible, handleOnDelete, segmentBulkRejectApiStatus } = props;
  const [selctedOption, setSelectedOption] = React.useState(1);
  const title = 'Delete Candidate';
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOn = e => {
    const { SubSegmentId } = props;
    const selectedSubSegmentId = SubSegmentId;
    const payload = {
      IsSubSegmentOnly: selctedOption === 1,
      SubSegmentIds: selectedSubSegmentId,
    };
    handleOnDelete(e, payload);
    handleCancel();
  };
  const onChange = e => {
    setSelectedOption(e.target.value);
  };

  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onCancel={handleCancel}
      bodyStyle={{ paddingTop: 12 }}
      footer={[
        <Button shape="round" key="back" onClick={handleCancel}>
          <FormattedMessage {...message.cancelLabel} />
        </Button>,
        <Button
          shape="round"
          key="submit"
          type="primary"
          loading={segmentBulkRejectApiStatus === 'INPROGRESS'}
          onClick={handleOn}
        >
           <FormattedMessage {...message.yesLabel} />
        </Button>,
      ]}
    >
      <div className={styles.alertMessageContainer}>
        <Alert
          message="Deleting candidate will remove him/her from the on-going drip campaign. Continue to delete?"
          type="warning"
          showIcon
        />
      </div>
      <Radio.Group onChange={onChange} className={styles.deleteOptionContainer} value={selctedOption}>
        <Radio value={1}><FormattedMessage {...message.fromSubSegmentLabel} /></Radio>
        <Radio value={2}><FormattedMessage {...message.bothSegmentAndSubSegmentLabel} /></Radio>
      </Radio.Group>
    </Modal>
  );
};

export default DeleteCandidateSegmentModal;
