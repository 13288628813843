import React from 'react';
import { Icon } from 'antd';

export const XSvg = props => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_375_10260)">
        <path
          d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
          fill="black"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M9.59875 10.5176L21.5485 26.4952L9.52344 39.4857H12.23L22.7581 28.1121L31.2642 39.4857H40.4741L27.8518 22.6096L39.0448 10.5176H36.3382L26.6427 20.9922L18.8086 10.5176H9.59875ZM13.5789 12.511H17.8098L36.4935 37.4923H32.2625L13.5789 12.511Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_375_10260">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const XIcon = props => <Icon component={XSvg} {...props} />;

export default XIcon;
