import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Skeleton } from 'antd';
import { TextIcon, CallIcon, EmailIcon } from '../../../Icons/AryaIcons';
import { getNonRefundedContacts } from '../../../Utils/ContactUtils';
import { onFetchContact } from '../../../Actions/ContactFetchActions';
import './FetchContactInfo.scss';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../../Analytics/EventTypes';
import Candidate360EmailAndMessageEmptyScreen from '../Candidate360ContactInfo/Candidate360EmailAndMessageEmptyScreen';
import message from '../../../Containers/CandidateDrawer/messages';

function FetchContactInfo({
  mediaType,
  candidateName,
  availableProviders,
  candidate,
  contact,
  currentJobDetails,
  isCandidateViewHeaderVisible,
  setComposeModalVisibility,
  contactInfoStyle,
  iconStyle,
  infoMessageStyle,
}) {
  if (!candidateName) {
    return <Skeleton rows={5} />;
  }
  const dispatch = useDispatch();
  const { nonRefundedPhones, nonRefundedEmails } = getNonRefundedContacts({ Contact: contact });
  const isAnyNonRefundedContactAvailable = nonRefundedPhones.length || nonRefundedEmails.length;
  const phones = _.get(contact, ['Phones'], []);
  const emails = _.get(contact, ['Emails'], []);
  const isContactAvailable = phones.length || emails.length;
  const candidateFirstName = candidateName.split(' ')[0];
  let messageInfo;
  let refundedContactClassName = '';
  if (mediaType === 'text') {
    messageInfo = <FormattedMessage {...message.textMessageInfoLabel} values={{ candidateFirstName }} />;
  } else if (mediaType === 'email') {
    messageInfo = <FormattedMessage {...message.emailMessageInfoLabel} values={{ candidateFirstName }} />;
  } else if (mediaType === 'call') {
    messageInfo = <FormattedMessage {...message.callMessageInfoLabel} values={{ candidateFirstName }} />;
  }

  const isLoading = _.get(candidate, 'fetchContactStatus') === 'InProgress';

  const getFetchContactButton = () => {
    return (
      <Button
        type="primary"
        onClick={() => dispatch(onFetchContact({ candidate, currentJobDetails }))}
        disabled={availableProviders === 0 || isLoading}
        loading={isLoading}
        shape="round"
        sk-event-name={getEventNameByCandidateStatus(eventTypes.candidate.candidateContactPull.pullContact, candidate)}
      >
        {isLoading ? (
          <FormattedMessage {...message.fetchingContactInfoLabel} />
        ) : (
          <FormattedMessage {...message.getContactsLabel} />
        )}
      </Button>
    );
  };

  const activeTab = mediaType === 'text' ? 'message' : 'email';
  if (!(!isAnyNonRefundedContactAvailable && isContactAvailable))
    return (
      <Candidate360EmailAndMessageEmptyScreen
        infoMessage={messageInfo}
        getFetchContactButton={getFetchContactButton}
        activeTab={activeTab}
        isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
        setComposeModalVisibility={setComposeModalVisibility}
        iconStyle={iconStyle}
        infoMessageStyle={infoMessageStyle}
        contactInfoStyle={contactInfoStyle}
      />
    );
  if (!isAnyNonRefundedContactAvailable && isContactAvailable) {
    messageInfo = (
      <span>
        <FormattedMessage {...message.contactPullRefundedMessage} />{' '}
        <a href="mailto:support@leoforce.com">support@leoforce.com</a>{' '}
        <FormattedMessage {...message.creditBackQueryLabel} />
      </span>
    );
    refundedContactClassName = 'contacts-refunded';
  }

  return (
    <div className="connect-fetch-contact-info">
      <div className="connect-contact-fetch-item">
        {mediaType === 'text' && <TextIcon style={{ fontSize: '50px', color: '#CCC' }} />}
        {mediaType === 'call' && <CallIcon style={{ fontSize: '50px', color: '#CCC' }} />}
        {mediaType === 'email' && <EmailIcon style={{ fontSize: '50px', color: '#CCC' }} />}
      </div>
      <div className={`connect-contact-fetch-item connect-no-phone-text ${refundedContactClassName}`}>
        {messageInfo}
      </div>
      <div>
        <Button
          type="primary"
          onClick={() => dispatch(onFetchContact({ candidate, currentJobDetails }))}
          disabled={availableProviders === 0 || isLoading}
          loading={isLoading}
          sk-event-name={getEventNameByCandidateStatus(
            eventTypes.candidate.candidateContactPull.pullContact,
            candidate
          )}
        >
          {isLoading ? (
            <FormattedMessage {...message.fetchingContactInfoLabel} />
          ) : (
            <FormattedMessage {...message.getContactInfoButton} />
          )}
        </Button>
      </div>
    </div>
  );
}

FetchContactInfo.propTypes = {
  mediaType: PropTypes.oneOf(['text', 'call', 'email']).isRequired,
};

export default FetchContactInfo;
