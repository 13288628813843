import { defineMessages } from 'react-intl';
import connectMessagesJsonEn from '../../Data/En_Us/ConnectMessagesEn_Us.json';
import connectMessagesJsonEs from '../../Data/Es_Es/ConnectMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'Connect';
const transformedMessage = formatMessagesWithComponentPrefix(connectMessagesJsonEn, component);
const connectMessages = defineMessages(transformedMessage);

export const connectMessagesEn = formatJsonWithComponentPrefix(connectMessagesJsonEn, component);
export const connectMessagesEs = formatJsonWithComponentPrefix(connectMessagesJsonEs, component);

export default connectMessages;
