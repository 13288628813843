import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
import { EmptyCandidatesIcon } from '../../../Icons/AryaIcons';
import { getSourceName } from '../../../Utils/SourceUtils';
import { getCandidateListError } from '../../../Reducers/CandidateReducer';
import jobMessage from '../../../Components/JobForm/JobMessages';

const QuickSearchEmptyScreen = props => {
  const { version, filterQueries, clearAggregationFilter, source } = props;
  const sourceName = getSourceName(source);
  const error = useSelector(state => getCandidateListError(state));
  const { Message: message, ErrorDescription: errorDescrption } = error || {};
  const defaultErrorMessage = <FormattedMessage {...jobMessage.noCandidatesFoundInfo} />;
  const defaultErrorDescription = <FormattedMessage {...jobMessage.modifySearchOptions} />;
  return (
    <Empty
      key="quick-search-empty-screen"
      image={<EmptyCandidatesIcon className="quick-serach-empty-list" />}
      description={
        <div>
          <div className="quick-search-no-candidates-found">{message ?? defaultErrorMessage}</div>
          <div className="quick-search-no-candidates-found-message">{errorDescrption ?? defaultErrorDescription}</div>
          {clearAggregationFilter && !_.isEmpty(filterQueries?.[sourceName]) ? (
            <div
              className="clear-aggregation-filter-button"
              role="button"
              tabIndex={0}
              onClick={() => clearAggregationFilter(sourceName)}
              onKeyPress={() => clearAggregationFilter(sourceName)}
            >
              <FormattedMessage {...jobMessage.clearFilterOption} />
            </div>
          ) : null}
          {error && (error.code === 400 || error.code === 401) && version !== 'ats' ? (
            <Link to="/settings/app">
              <div style={{ cursor: 'pointer', marginTop: '10px' }}>
                <FormattedMessage {...jobMessage.gotoSettingsButton} />
              </div>
            </Link>
          ) : null}
        </div>
      }
    />
  );
};

const QuickSearchEmptyTabsScreen = () => (
  <Empty
    image={<EmptyCandidatesIcon className="quick-serach-empty-list" />}
    description={
      <div>
        <div className="quick-search-no-option-selected">
          <FormattedMessage {...jobMessage.noCandidatesFoundInfo} />
        </div>
        <div className="quick-search-no-candidates-found-message">
          <FormattedMessage {...jobMessage.candidateSearchOptions} />
        </div>
      </div>
    }
  />
);

export { QuickSearchEmptyScreen, QuickSearchEmptyTabsScreen };
