import React from 'react';
import PubSub from 'pubsub-js';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { PLAN_UPGRADE } from '../../PubSub/EventTypes';
import styles from './UpgradePlanModal.module.scss';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

export default function UpgradePlanModal(props) {
  const { jobDetails, history } = props;
  const { JobId: jobId, JobGuid: jobGuid } = jobDetails || {};

  const onHandleUpgrade = () => {
    PubSub.publish(PLAN_UPGRADE, {
      currentPlan: jobDetails?.OrderInfo?.ProductVariantName,
      jobGuid: jobDetails?.JobGuid,
      sourceName: 'Upgrade',
    });
    history.push({
      pathname: '/select-plan',
      search: `?jobGuid=${jobGuid}&jobId=${jobId}&isFromFree=${true}`,
    });
  };
  return (
    <div className={styles.upgradeScreenContainer}>
      <div className={styles.upgradeModalWrapper}>
        <div className={styles.modalContainer}>
          <div className={styles.lockedFeature}>
            <div className={styles.lockOuterCircle}>
              <div className={styles.lockMiddleCircle}>
                <div className={styles.lockInnerCircle}>
                  <img
                    alt="premium feature"
                    className={styles.lockedImg}
                    src={`${process.env.PUBLIC_URL}/static/Images/locked_feature.svg`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.text}>
            <div className={styles.heading}><FormattedMessage {...message.upgradeForPotentialCandidatesLabel} /></div>
            <div className={styles.description}>
            <FormattedMessage {...message.connectWithTheCandidatesDirectlyLabel} />
            </div>
            <Button shape="round" onClick={onHandleUpgrade} className={styles.upgradeButton}>
            <FormattedMessage {...message.upgradeLabel} />
            </Button>
          </div>
        </div>
      </div>
      <img
        src={`${process.env.PUBLIC_URL}/static/Images/lockedcandidatelist.jpg`}
        alt="upgrade plan"
        className={styles.blurImage}
      />
    </div>
  );
}
