/* eslint-disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import message from '../Containers/Lists/ListsMessages';

function validateSegmentTitle(rule, value, callback, maxCharactersAllowed, modalType) {
  const maxCharactersAllowedTitle = maxCharactersAllowed ?? 150;
  const title = value && value.trim();
  if (title && title.length > maxCharactersAllowedTitle) {
    callback(<FormattedMessage {...message.segmentTitleLengthLabel} values={{ maxCharactersAllowedTitle }} />);
  } else if (title) {
    callback();
  } else {
    callback(`Please enter the ${modalType} name!`);
  }
}

function validateSegmentDescription(rule, value, callback) {
  const maxCharactersAllowedDescription = 200;
  const textAreaContent = value && value.trim();
  if (textAreaContent && textAreaContent.length > maxCharactersAllowedDescription) {
    callback(
      <FormattedMessage {...message.segmentDescriptionLengthLabel} values={{ maxCharactersAllowedDescription }} />
    );
  } else {
    callback();
  }
}

export { validateSegmentDescription, validateSegmentTitle };
