import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import store from './store';
import SsoRoutes from './SsoRoutes';
import HelmetComponent from './HelmetComponent';

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const msp = window.location.pathname.includes('msp');
    return (
      <Router>
        <Switch>
          <Provider store={store}>
            {!msp && <HelmetComponent />}
            <Route component={SsoRoutes} />
          </Provider>
        </Switch>
      </Router>
    );
  }
}

export default AppContainer;
