import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Badge, Button, Icon, Popover } from 'antd';
import TitlesSection from '../SearchCriteria/TitlesSection/TitlesSection';
import SkillsSection from '../SearchCriteria/SkillsSection/SkillsSection';
import styles from './ManualSearchMinimizedViewContainer.module.scss';
import ExperienceSection from '../SearchCriteria/ExperienceSection/ExperienceSection';
import ManualSearchFormFooter from '../ManualSearchFormFooter/ManualSearchFormFooter';
import { parseLocation } from '../../../Utils/LocationUtils';
import EnhancedLocationSection from '../SearchCriteria/EnhancedLocationSection/EnhancedLocationSection';
import message from '../ManualSearchMessages';

function ManualSearchMinimizedViewContainer(props) {
  const {
    jobId,
    form,
    defaultFormValues,
    currentUserDetails,
    booleanSearchStrings,
    fetchBooleanSearchStrings,
    handleShowMoreLessClick,
    candidateContext,
    unsavedCriteriaId,
    jobGuid,
    advancedSettings,
    sourceNamesSelected,
    initalSources,
    onSearchCandidates,
    isNonInternalPortalForSegmentEnabled,
    history,
    isManualSearchFormMinimized,
    setPreFetchRequired,
    isPreFetchRequied,
    manualSearchCandidatesFetchModalVisibility,
    searchContext,
    countries,
    pageName,
    version
  } = props;

  const {
    Titles: defaultTitles = [],
    Skills: defaultSkills = {},
    QueryString: defaultQueryString,
    ActiveSearchString: defaultActiveSearchString,
    IsExactTitleMatchRequired: defaultIsExactTitleMatchRequired,
  } = defaultFormValues;

  const titleSection = (
    <div className={styles.titlePopover}>
      <TitlesSection
        form={form}
        defaultTitles={defaultTitles}
        defaultIsExactTitleMatchRequired={defaultIsExactTitleMatchRequired}
        isManualSearchFormMinimized
      />
    </div>
  );

  const keyWordSection = (
    <SkillsSection
      jobId={jobId}
      booleanSearchStrings={booleanSearchStrings}
      fetchBooleanSearchStrings={fetchBooleanSearchStrings}
      form={form}
      defaultSkills={defaultSkills}
      defaultQueryString={defaultQueryString}
      defaultActiveSearchString={defaultActiveSearchString}
      isManualSearchFormMinimized
      currentUserDetails={currentUserDetails}
    />
  );

  const onClearExperienceRange = () => {
    const minExpereinceField = 'MinExperience';
    const maxExpereinceField = 'MaxExperience';
    form.setFieldsValue({
      [minExpereinceField]: undefined,
      [maxExpereinceField]: undefined,
    });
  };

  const experienceSection = (
    <>
      {' '}
      <div className={styles.popoverExperienceSectionContainer}>
        <ExperienceSection form={form} defaultFormValues={defaultFormValues} />
      </div>
      <div className={styles.experiencePopoverFooter}>
        <Button className={styles.clearButton} type="link" shape="round" onClick={onClearExperienceRange}>
          <FormattedMessage {...message.minimizedClearButton} />
        </Button>
      </div>
    </>
  );

  const singleLocationValue = form.getFieldValue('Location');
  const locationMiles = form.getFieldValue('Range');
  const multiLocationsValues = form.getFieldValue('SourcingLocations');
  const locationType = form.getFieldValue('LocationType');
  const isMultiLocationSectionVisible = locationType === 'MultiLocation';

  const multiLocationDisplayedValue = multiLocationsValues?.find(location => location.IsPrimaryLocation);

  const { Location: { CountryCode: jobCountryCode } = {} } = defaultFormValues || {};

  const jobCountryName = countries.find(country => country.Iso2Code === jobCountryCode)?.Name;
  const displayedSingleLocationText = parseLocation(singleLocationValue) || jobCountryName;
  const multiLocationDisplayedText =
    parseLocation(multiLocationDisplayedValue?.Location) || multiLocationDisplayedValue?.CountryCode || jobCountryName;
  const displayedLocationText = isMultiLocationSectionVisible
    ? multiLocationDisplayedText
    : displayedSingleLocationText;

  const singleLocationBadge = parseLocation(singleLocationValue) ? (
    <Badge count={1} style={{ backgroundColor: '#13C26B' }} />
  ) : null;
  const multilocationBadge = multiLocationsValues?.length ? (
    <Badge count={multiLocationsValues?.filter(x => x.isActive).length} style={{ backgroundColor: '#13C26B' }} />
  ) : null;
  const locationDisplayBadge = isMultiLocationSectionVisible ? multilocationBadge : singleLocationBadge;
  const locationSection = (
    <div className={styles.popoverLocationSectionContainer}>
      <EnhancedLocationSection
        form={form}
        defaultFormValues={defaultFormValues}
        candidateContext={candidateContext}
        isManualSearchFormMinimized={isManualSearchFormMinimized}
      />
    </div>
  );
  const displayedLocationMiles = locationMiles || <FormattedMessage {...message.milesLabel} />;
  const milesUnit = locationMiles ?  <FormattedMessage {...message.milesUnitLabel} /> : '';

  const titles = form.getFieldValue('Titles')?.filter(item => item.isSelected);
  const keywords = form.getFieldValue('PreferredSkills')?.filter(item => item.isPreferred);
  const booleanString = form.getFieldValue('QueryString');
  const activeSearchStringField = form.getFieldValue('ActiveSearchString');
  const isActiveSearchStringAI = activeSearchStringField === 'AI';
  const keywordBadgeDisplay = isActiveSearchStringAI && (
    <Badge count={keywords?.length} style={{ backgroundColor: '#13C26B' }} />
  );
  const minExperience = form.getFieldValue('MinExperience');
  const maxExpereince = form.getFieldValue('MaxExperience');

  const experienceString =
    typeof minExperience === 'number' && typeof maxExpereince === 'number' ? (
      `${minExperience} to ${maxExpereince} Years`
    ) : (
      <FormattedMessage {...message.minimizedExperinceLabel} />
    );

  return (
    <div
      className={styles.manualSearchMinimizedContainer}
      style={{ display: isManualSearchFormMinimized ? 'flex' : 'none' }}
    >
      <div className={styles.manualSearchMinimizedInputs}>
        <div className={styles.jobTitlePopoverContainer}>
          <div className={styles.jobTitleHeading}>
            {' '}
            <FormattedMessage {...message.jobTitlesLabel} />:
          </div>
          <Popover content={titleSection} placement="bottomLeft" trigger="click" overlayStyle={{ zIndex: 1000 }}>
            <div className={styles.titlePopoverSection}>
              <span className={styles.titleText}>
                {titles?.[0]?.name || <FormattedMessage {...message.jobTitlesLabel} />}
              </span>
              <div>
                <Badge count={titles?.length ?? 0} style={{ backgroundColor: '#13C26B' }} /> {`  `}
                <Icon type="down" />
              </div>
            </div>
          </Popover>
        </div>
        <div className={styles.keywordPopoverContainer}>
          <div className={styles.keywordHeading}>{isActiveSearchStringAI ? <><FormattedMessage {...message.keywords} />: </>:<FormattedMessage {...message.booleanLabelText} /> }</div>
          <Popover content={keyWordSection} placement="bottom" trigger="click" overlayStyle={{ zIndex: 1000 }}>
            <div className={isActiveSearchStringAI ? styles.keywordPopoverSection : styles.booleanPopoverSection}>
              {isActiveSearchStringAI ? (
                <span className={styles.aIKeywordsText}>{keywords?.[0]?.name || <FormattedMessage {...message.keywords} />} </span>
              ) : (
                <span className={styles.booleanText}>
                  {booleanString?.length
                    ? booleanString
                    : defaultQueryString || <FormattedMessage {...message.booleanLabel} />}
                </span>
              )}
              <div>
                {keywordBadgeDisplay} {`  `}
                <Icon type="down" />
              </div>
            </div>
          </Popover>
        </div>
        <div className={styles.experiencePopoverContainer}>
          <div className={styles.experienceHeading}>
            <FormattedMessage {...message.minimizedExperinceLabel} />:
          </div>
          <Popover content={experienceSection} placement="bottom" trigger="click" overlayStyle={{ zIndex: 1000 }}>
            <div className={styles.experiencePopoverSection}>
              <span>{experienceString}</span>
              <Icon type="down" />
            </div>
          </Popover>
        </div>
        <div className={styles.locationPopoverContainer}>
          <div className={styles.locationHeader}><><FormattedMessage {...message.location} />: </></div>
          <Popover
            content={locationSection}
            placement="bottom"
            trigger="click"
            overlayStyle={{ zIndex: 1000, width: '900px' }}
          >
            <div className={styles.locationPopoverSection}>
              <span className={styles.locationAndCountContainer}>
                <span className={styles.displayedLocationText}> {displayedLocationText} </span>
                {locationDisplayBadge}
              </span>
              {displayedLocationMiles && !form.getFieldValue('IsRemoteJob') ? (
                <span className={styles.displayedMiles}>
                  {displayedLocationMiles}
                  {milesUnit}
                </span>
              ) : null}
              <Icon type="down" />
            </div>
          </Popover>
        </div>
      </div>
      <div className={styles.sideBar}>
        <Button
          type="link"
          className={styles.expandButton}
          onClick={() => {
            handleShowMoreLessClick(false);
          }}
        >
          <FormattedMessage {...message.expandFormButton} />
        </Button>
        <ManualSearchFormFooter
          form={form}
          unsavedCriteriaId={unsavedCriteriaId}
          jobId={jobId}
          jobGuid={jobGuid}
          advancedSettings={advancedSettings}
          sourceNamesSelected={sourceNamesSelected}
          initalSources={initalSources}
          onSearchCandidates={onSearchCandidates}
          candidateContext={candidateContext}
          isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
          history={history}
          minimizedViewSearchButton
          setPreFetchRequired={setPreFetchRequired}
          isPreFetchRequied={isPreFetchRequied}
          manualSearchCandidatesFetchModalVisibility={manualSearchCandidatesFetchModalVisibility}
          searchContext={searchContext}
          pageName={pageName}
          version={version}
        />
      </div>
    </div>
  );
}
export default ManualSearchMinimizedViewContainer;
