import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './TemplateHeader.css';
import { getEventNameByConnectType } from '../../../Analytics/Connect/ConnectEventUtils';
import message from '../../../Containers/BaseTemplates/ConnectTemplatesMessages';

const { Search } = Input;

export default function TemplateHeader(props) {
  const {
    heading,
    onClick,
    placeholder,
    onChange,
    systemEmailTemplate,
    type,
    isWorkflow,
    onSearch,
    location = {},
  } = props;

  const { pathname } = location;

  const createButton = isWorkflow ? (
    <Link
      to={{
        pathname,
        search: '?tab=workflows',
        state: { chooseDefaultWorkflow: true },
      }}
    >
      <Button className="create-workflow" type="primary">
        <span>
          <FormattedMessage {...message.createWorkflowLabel} />
        </span>
      </Button>
    </Link>
  ) : (
    <Button
      className="create-template create"
      type="primary"
      onClick={onClick}
      sk-event-name={getEventNameByConnectType(type, 'create')}
    >
      <FormattedMessage {...message.addTemplateLabel} />
    </Button>
  );

  return (
    <div className="template-header">
      <span className="template-heading">{heading}</span>
      {!systemEmailTemplate ? createButton : null}
      <Search
        onSearch={onSearch}
        onChange={onChange}
        allowClear
        className="template-search"
        placeholder={placeholder}
      />
    </div>
  );
}

TemplateHeader.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  placeholder: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
