import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Icon, Divider } from 'antd';
import './ReviewCommentDetails.scss';
import config from '../../Config/Config';
import jobMessage from '../JobForm/JobMessages';

function ReviewCommentDetails(props) {
  const { onClose, jobId, AppName } = props;
  return (
    <div>
      <div className="arya-review">
        <div className="review-data">
          <div>
            <div className="review-comment-review-job-info-div">
              <div className="review-comment-review-job-info">
                <FormattedMessage {...jobMessage.reviewJobInfoLabel} />
              </div>
              <div className="review-comment-edit-job">
                <Link to={`/jobs/${jobId}/edit`}>
                  <div className="edit-job">
                    <FormattedMessage {...jobMessage.editJobLabel} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="review-comment-reason-list">
            <Icon type="check" className="review-comment-tick-icon" />
            <FormattedMessage {...jobMessage.tooManyMustHaves} />
          </div>
          <div className="review-comment-reason-list">
            <Icon type="check" className="review-comment-tick-icon" />
            <FormattedMessage {...jobMessage.increaseSearchRadiusLabel} />
          </div>
          <div className="review-comment-reason-list">
            <Icon type="check" className="review-comment-tick-icon" />
            <FormattedMessage {...jobMessage.notEnoughCandidatesLabel} />
          </div>
          <div className="review-comment-reason-list">
            <Icon type="check" className="review-comment-tick-icon" />
            <FormattedMessage {...jobMessage.verifyJobTitleLabel} />
          </div>
          <div className="review-comment-divider">
            <Divider orientation="left">
              <FormattedMessage {...jobMessage.orLabel} />
            </Divider>
          </div>
          <div className="review-comment-try-quick-search-div">
            <div className="review-comment-try-quick-search">Try Arya search</div>
            <div className="review-comment-refine-arya-intel">
              <Link to={`/jobs/${jobId}/quick-search`}>
                <div className="refine-arya-intel">
                  <FormattedMessage {...jobMessage.refineIntelLabel} values={{ AppName }} />
                  <FormattedMessage {...jobMessage.tryQuickSearchLabel} />
                </div>
              </Link>
            </div>
          </div>
          <div className="review-comment-divider">
            <Divider orientation="left">
              <FormattedMessage {...jobMessage.orLabel} />
            </Divider>
          </div>
          <div className="review-comment-support-div">
            <div className="review-comment-support">
              <FormattedMessage {...jobMessage.reachOutLabel} />
            </div>
            <div className="review-comment-support-link">
              <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>
            </div>
          </div>
        </div>
        <div className="review-comment-close-div">
          <div className="review-comment-close" onClick={onClose} onKeyPress={onClose} role="presentation">
            <FormattedMessage {...jobMessage.closeLabels} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewCommentDetails;
