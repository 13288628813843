import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Popover, Badge, Icon, Divider, Form } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import ConnectNotification from '../../Components/ConnectNotification/ConnectNotification';
import { getLastNotificationIdAndUpdateTime } from '../../Utils/NotificationUtils';
import { ConnectNotificationIcon } from '../../Icons/AryaIcons';

const ConnectNotifyComponent = props => {
  const {
    connectNotificationTitle,
    visiblePopover,
    popoverParams,
    handlePopoversVisibility,
    connectNotificationsUnviewedCount,
    showConnectNotification,
    connectNotifications,
    fetchConnectNofitications,
    form,
    currentUser,
    jobsById,
    jobGuidToIdMapping,
    currentPageConnectNotificationCount,
    onClickConnectNotificationItem,
    connectUrl,
    setCurrentMessagePopupInfos
  } = props;

  const getNotifyComponent = () => {
    const { Id: lastNotificationId, UpdatedTime: beforeTime } =
      getLastNotificationIdAndUpdateTime(connectNotifications);
    return connectNotifications.length ? (
      <div className="connect-notifications">
        <InfiniteScroll
          useWindow={false}
          initialLoad={false}
          loadMore={() =>
            fetchConnectNofitications(lastNotificationId, beforeTime, form.getFieldValue('ConnectUserCheckbox'))
          }
          hasMore={currentPageConnectNotificationCount === 10}
          loader={
            <div key="loader" style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
              <Icon style={{ fontSize: 20 }} type="loading" spin />
            </div>
          }
          threshold={220}
        >
          {connectNotifications.map(connectNotification => {
            const jobGuid = _.get(connectNotification, ['Payload', 'RefId'], undefined);
            const jobDetails = jobGuid ? _.get(jobsById, [jobGuidToIdMapping[jobGuid]], {}) : {};
            if (jobDetails?.EntityType !== 'Job') {
              return null;
            }
            return (
              <ConnectNotification
                notification={connectNotification}
                key={connectNotification.Id}
                onClick={onClickConnectNotificationItem}
                currentUser={currentUser}
                jobDetails={jobGuid ? _.get(jobsById, [jobGuidToIdMapping[jobGuid]], {}) : {}}
                setCurrentMessagePopupInfos={setCurrentMessagePopupInfos}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    ) : (
      <div className="connect-notification-empty">
        <div className="connect-notification-empty-icon-wrapper">
          <ConnectNotificationIcon className="connect-notification-icon" />
        </div>
        <div className="connect-notification-empty-title">No connect notification found</div>
        <div className="connect-notification-empty-description">
          All the replies from the candidates will appear here
        </div>
      </div>
    );
  };
  const connectNotificationComponent = getNotifyComponent();
  const connectNotificationFooter = connectNotifications.length ? (
    <div>
      <Divider style={{ margin: 0 }} />
      <div className="go-to-connect">
        <Link to={connectUrl} style={{ color: '#7F8FA5', fontSize: '14px', fontWeight: 'bold' }}>
          Go To Connect
        </Link>
      </div>
    </div>
  ) : null;
  return (
    <Popover
      title={connectNotificationTitle}
      visible={visiblePopover === 'ConnectNotification'}
      content={
        <div>
          {connectNotificationComponent}
          {connectNotificationFooter}
        </div>
      }
      {...popoverParams}
      onVisibleChange={visible => handlePopoversVisibility(visible, 'ConnectNotification')}
    >
      <div>
        <span className="navbar-menu-item-icon">
          <span className="badged-icon">
            <Badge count={connectNotificationsUnviewedCount} className="connect">
              <ConnectNotificationIcon
                style={{ cursor: 'pointer' }}
                className={`connect-notification-icon ${showConnectNotification ? 'wiggle' : ''}`}
              />
            </Badge>
          </span>
        </span>
      </div>
    </Popover>
  );
};
export { ConnectNotifyComponent as ConnectNotifyComponentWithoutForm };
export default Form.create()(ConnectNotifyComponent);
