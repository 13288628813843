import { defineMessages } from 'react-intl';
import ContactSettingsMessageJsonEn from '../../Data/En_Us/ContactSettingsMessageEn_Us.json';
import ContactSettingsMessageJsonEs from '../../Data/Es_Es/ContactSettingsMessageEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'ContactSettings';
const transformedMessage = formatMessagesWithComponentPrefix(ContactSettingsMessageJsonEn, component);
const contactSettingsMessages = defineMessages(transformedMessage);

const ContactSettingsMessage = defineMessages(contactSettingsMessages);
export const contactSettingsMessageEn = formatJsonWithComponentPrefix(ContactSettingsMessageJsonEn, component);

export const contactSettingsMessageEs = formatJsonWithComponentPrefix(ContactSettingsMessageJsonEs, component);

export default ContactSettingsMessage;
