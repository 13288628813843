import React from 'react';
import _ from 'lodash';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, Input, Checkbox, Avatar, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { getConfiguration } from '../Collaborators/Collaborators';
import './ShareJob.scss';
import messages from './messages';

const { Search } = Input;

class ShareJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSharedUsers: props.sharedUsers || [],
      topSharedUsers: props.sharedUsers || [],
      currentUnsharedUsers: [],
    };

    this.sortUserIdsByName = this.sortUserIdsByName.bind(this);
    this.onChangeUserSelection = this.onChangeUserSelection.bind(this);
    this.onShareJob = this.onShareJob.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchClear = this.handleSearchClear.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getOkText = this.getOkText.bind(this);
  }

  onChangeUserSelection(e) {
    const { currentSharedUsers, currentUnsharedUsers } = this.state;
    const { sharedUsers } = this.props;
    const checkedUserId = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      const _currentUnsharedUsers = [...currentUnsharedUsers];
      _.remove(_currentUnsharedUsers, userId => userId === checkedUserId);
      this.setState({
        currentSharedUsers: [...currentSharedUsers, checkedUserId],
        currentUnsharedUsers: _currentUnsharedUsers,
      });
    } else {
      const _currentSharedUsers = [...currentSharedUsers];
      _.remove(_currentSharedUsers, userId => userId === checkedUserId);
      if (sharedUsers.includes(checkedUserId)) {
        currentUnsharedUsers.push(checkedUserId);
      }
      this.setState({
        currentUnsharedUsers,
        currentSharedUsers: _currentSharedUsers,
      });
    }
  }

  onShareJob() {
    const { currentSharedUsers, currentUnsharedUsers } = this.state;
    const { onShare } = this.props;
    this.setState({
      searchValue: '',
    });
    onShare(currentSharedUsers, currentUnsharedUsers);
  }

  getOkText() {
    const { currentSharedUsers, currentUnsharedUsers } = this.state;
    const { sharedUsers, intl } = this.props;
    const checkForNewSharedUser = currentSharedUsers.findIndex(user => !sharedUsers.includes(user));
    if (checkForNewSharedUser === -1 && currentUnsharedUsers.length) {
      return intl.formatMessage({ ...messages.unshare });
    }
    return intl.formatMessage({ ...messages.share });
  }

  handleSearch(value) {
    const { fetchUsersForShare, usersById } = this.props;
    const { currentSharedUsers } = this.state;
    const cleanValue = value.trim();
    const topSharedUsers = currentSharedUsers.filter(id => {
      if (!usersById[id]) {
        return false;
      }
      return (
        usersById[id].FullName.toLowerCase().search(cleanValue) >= 0 ||
        usersById[id].Email.toLowerCase().search(cleanValue) >= 0
      );
    });
    this.setState({
      searchValue: cleanValue.toLowerCase(),
      topSharedUsers,
    });
    if (cleanValue) {
      fetchUsersForShare({ page: 1, searchTerm: cleanValue.toLowerCase(), isActive: true, listFor: 'ShareJob' });
    } else {
      fetchUsersForShare({ page: 1, isActive: true, listFor: 'ShareJob' });
    }
  }

  handleSearchClear(event) {
    const { fetchUsersForShare } = this.props;
    const { currentSharedUsers } = this.state;
    if (!event.target.value) {
      const cleanValue = event.target.value;
      this.setState({
        searchValue: cleanValue.toLowerCase(),
        topSharedUsers: currentSharedUsers,
      });
      if (cleanValue) {
        fetchUsersForShare({ page: 1, searchTerm: cleanValue.toLowerCase(), isActive: true, listFor: 'ShareJob' });
      } else {
        fetchUsersForShare({ page: 1, isActive: true, listFor: 'ShareJob' });
      }
    }
  }

  handleLoadMore(page) {
    const { fetchUsersForShare, maxCount } = this.props;
    const { searchValue } = this.state;
    if ((page - 1) * 10 > maxCount) {
      return;
    }
    if (searchValue) {
      fetchUsersForShare({ page, searchTerm: searchValue, isActive: true, listFor: 'ShareJob' });
    } else {
      fetchUsersForShare({ page, isActive: true, listFor: 'ShareJob' });
    }
  }

  handleClose() {
    const { onClose } = this.props;
    this.setState({
      searchValue: '',
    });
    onClose();
  }

  sortUserIdsByName(id1, id2) {
    const { usersById } = this.props;
    const firstUserName = usersById[id1].FullName.toLowerCase();
    const secondUserName = usersById[id2].FullName.toLowerCase();
    if (firstUserName < secondUserName) {
      return -1;
    }
    if (firstUserName > secondUserName) {
      return 1;
    }
    return 0;
  }

  render() {
    const { isVisible, maxCount, usersById, userIds, intl, sharedUsers, jobOwner, candidateContext } = this.props;
    const { currentSharedUsers, searchValue, topSharedUsers, currentUnsharedUsers } = this.state;
    userIds.sort((id1, id2) => this.sortUserIdsByName(id1, id2));
    const shareDisableFlag = !currentUnsharedUsers.length && _.isEqual(sharedUsers.sort(), currentSharedUsers.sort());
    return (
      <div>
        <Modal
          visible={isVisible}
          okText={this.getOkText()}
          cancelText={intl.formatMessage({ ...messages.cancel })}
          onOk={this.onShareJob}
          onCancel={this.handleClose}
          title={
            candidateContext === 'segment'
              ? intl.formatMessage({ ...messages.segmentTitle })
              : intl.formatMessage({ ...messages.jobTitle })
          }
          okButtonProps={{ disabled: shareDisableFlag }}
        >
          <div>
            <div className="share-search-box">
              <Search
                placeholder={intl.formatMessage({ ...messages.searchRecruiters })}
                shape="round"
                onSearch={this.handleSearch}
                onChange={this.handleSearchClear}
              />
            </div>
            {/* TODO: add in future when db support is there */}
            {/* <div>
              <Checkbox onChange={this.onCheckChangeAll} checked={checkedAll}>
                Share with all
              </Checkbox>
            </div> */}
            <div style={{ maxHeight: '45vh', overflowY: 'scroll' }}>
              <InfiniteScroll
                pageStart={1}
                key={searchValue || '1'}
                initialLoad={false}
                loadMore={this.handleLoadMore}
                hasMore={maxCount === -1 || userIds.length < maxCount}
                useWindow={false}
                threshold={220}
                loader={
                  <div className="loader" key={0}>
                    {`${intl.formatMessage({ ...messages.loading })}...`}
                  </div>
                }
              >
                {jobOwner
                  ? [jobOwner].map(id => {
                      const config = getConfiguration(_.get(usersById, [id, 'FullName'], ''));
                      return (
                        <div className="share-with-user" key={id}>
                          <Checkbox
                            onChange={this.onChangeUserSelection}
                            checked={currentSharedUsers.includes(id)}
                            value={id}
                            disabled
                          />
                          <Avatar style={{ color: '#ffffff', backgroundColor: config.color }}>{config.text}</Avatar>
                          <Tooltip title={usersById[id] ? usersById[id].Email : ''}>
                            <span>{_.get(usersById, [id, 'FullName'], '')}</span>
                          </Tooltip>
                          <div className="job-admin-share-wrapper">
                            {_.get(usersById, [id, 'IsActive'], false) ? null : (
                              <span className="user-inactive">{intl.formatMessage({ ...messages.inactiveLabel })}</span>
                            )}
                            <span className="job-admin">{intl.formatMessage({ ...messages.owner })}</span>
                            {_.get(usersById, [id, 'RoleName'], '') === 'Admin' ||
                            _.get(usersById, [id, 'HasGodView'], false) ? (
                              <span className="share-admin">{intl.formatMessage({ ...messages.admin })}</span>
                            ) : null}
                          </div>
                        </div>
                      );
                    })
                  : null}

                {topSharedUsers
                  .filter(id => id !== jobOwner)
                  .sort((id1, id2) => this.sortUserIdsByName(id1, id2))
                  .map(id => {
                    const config = getConfiguration(usersById[id].FullName);
                    return (
                      <div className="share-with-user" key={id}>
                        <Checkbox
                          onChange={this.onChangeUserSelection}
                          checked={currentSharedUsers.includes(id)}
                          value={id}
                        />
                        <Avatar style={{ color: '#ffffff', backgroundColor: config.color }}>{config.text}</Avatar>
                        <Tooltip title={usersById[id].Email}>
                          <span>{usersById[id].FullName}</span>
                        </Tooltip>
                        <div className="job-admin-share-wrapper">
                          {_.get(usersById, [id, 'IsActive'], false) ? null : (
                            <span className="user-inactive">{intl.formatMessage({ ...messages.inactiveLabel })}</span>
                          )}
                          {usersById[id].RoleName === 'Admin' || usersById[id].HasGodView ? (
                            <span className="share-admin">{intl.formatMessage({ ...messages.admin })}</span>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}

                {userIds
                  .filter(id => !topSharedUsers.includes(id))
                  .map(id => {
                    const config = getConfiguration(usersById[id].FullName);
                    return (
                      <div className="share-with-user" key={id}>
                        <Checkbox
                          onChange={this.onChangeUserSelection}
                          checked={currentSharedUsers.includes(id)}
                          value={id}
                        />
                        <Avatar style={{ color: '#ffffff', backgroundColor: config.color }}>{config.text}</Avatar>
                        <Tooltip title={usersById[id].Email}>
                          <span>{usersById[id].FullName}</span>
                        </Tooltip>
                        <div className="job-admin-share-wrapper">
                          {_.get(usersById, [id, 'IsActive'], false) ? null : (
                            <span className="user-inactive">{intl.formatMessage({ ...messages.inactiveLabel })}</span>
                          )}
                          {usersById[id].RoleName === 'Admin' || usersById[id].HasGodView ? (
                            <span className="share-admin">{intl.formatMessage({ ...messages.admin })}</span>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
              </InfiniteScroll>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ShareJob.propTypes = {
  onClose: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  maxCount: PropTypes.number.isRequired,
  fetchUsersForShare: PropTypes.func.isRequired,
  userIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  sharedUsers: PropTypes.arrayOf(PropTypes.number),
  usersById: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool.isRequired,
};

ShareJob.defaultProps = {
  sharedUsers: [],
};
export default injectIntl(ShareJob);

export { ShareJob as ShareJobWithoutHOC };
