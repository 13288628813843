/**
 * @author Mouli Kalakota
 * @email mouli.kalakota@leoforce.com
 * @create date 2019-02-14 04:43:29
 * @modify date 2019-02-14 04:43:29
 * @desc Call Dialog component
 */

import React from 'react';
import Draggable from 'react-draggable';
// import PropTypes from 'prop-types';
import { Button, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import Dialpad from '../Dialpad/Dialpad';
import './CallDialog.scss';
import CallNotes from '../../CallNotes/CallNotes';
import eventTypes from '../../../Analytics/EventTypes';
import {
  DialpadIcon,
  MuteIcon,
  SoundIcon,
  PauseIcon,
  RecordIcon,
  NotesIcon,
  CallIcon,
  CallDeclinedIcon,
  CallFinishedIcon,
  CallUnansweredIcon,
  OnCallIcon,
  OnCallRecordingIcon,
  DialingIcon,
  RingingIcon,
} from '../../../Icons/AryaIcons';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import message from '../../JobForm/JobMessages';

class CallDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialpadSelected: null,
      notesOpened: props.notesViewState || false,
      phoneNumber: props.phoneNumber || '',
      hours: 0,
      minutes: 0,
      seconds: 0,
      isRecording: false,
      isMuted: false,
      isOnHold: false,
    };
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.onCallInitiated = this.onCallInitiated.bind(this);
    this.onCallRinging = this.onCallRinging.bind(this);
    this.onDialpadClick = this.onDialpadClick.bind(this);
    this.onNotesClick = this.onNotesClick.bind(this);
    this.onRedial = this.onRedial.bind(this);
    this.onClearSingleNumber = this.onClearSingleNumber.bind(this);
    this.hangup = this.hangup.bind(this);
    this.toggleHold = this.toggleHold.bind(this);
    this.toggleMute = this.toggleMute.bind(this);
    this.toggleRecord = this.toggleRecord.bind(this);
    this.onSendDtmfTone = this.onSendDtmfTone.bind(this);
    this.tick = this.tick.bind(this);

    this.getCallStatus = this.getCallStatus.bind(this);
    this.getAryaCallHeader = this.getAryaCallHeader.bind(this);
    this.getBody = this.getBody.bind(this);
    this.getFooter = this.getFooter.bind(this);

    this.startCallTimer = this.startCallTimer.bind(this);
    this.stopCallTimer = this.stopCallTimer.bind(this);
    this.onCallButton = this.onCallButton.bind(this);
  }

  componentWillUnmount() {
    this.stopCallTimer();
  }

  onDialpadClick() {
    const { dialpadSelected } = this.state;
    const { callStatus } = this.props;
    if (callStatus === 'Answered' || callStatus === 'InProgress') {
      this.setState({ dialpadSelected: !dialpadSelected });
    }
  }

  onNotesClick() {
    const { notesOpened } = this.state;
    this.setState({ notesOpened: !notesOpened });
  }

  closeCallModal = () => {
    const { setCallDialogVisibility } = this.props;
    setCallDialogVisibility({ isVisible: false });
  };

  onClearSingleNumber() {
    const { phoneNumber } = this.state;
    if (!phoneNumber) {
      return;
    }
    const _phoneNumber = phoneNumber.slice(0, phoneNumber.length - 1);
    this.setState({ phoneNumber: _phoneNumber });
  }

  onPhoneNumberChange(event) {
    event.preventDefault();
    this.setState({
      phoneNumber: event.target.value,
    });
  }

  onRedial() {
    const { phoneNumber } = this.state;
    const { onCall } = this.props;
    onCall(phoneNumber);
  }

  onSendDtmfTone(tone) {
    const { onDtmfTone, callStatus } = this.props;
    const { phoneNumber } = this.state;
    if (!callStatus) {
      this.setState({ phoneNumber: (phoneNumber || '') + tone });
    }
    onDtmfTone(tone);
  }

  onCallButton() {
    const { onHangup, callStatus } = this.props;
    if (callStatus) {
      onHangup();
    }
  }

  onCallInitiated() {
    const { phoneNumber } = this.props;
    const { phoneNumber: currentPhoneNumber } = this.state;
    if (currentPhoneNumber !== phoneNumber) {
      this.setState({
        phoneNumber,
      });
    }
  }

  onCallRinging() {
    this.stopCallTimer();
    const { hours, minutes, seconds } = this.state;
    if (hours !== 0 || minutes !== 0 || seconds !== 0) {
      this.setState({
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    }
  }

  getCallStatus() {
    const { hours, minutes, seconds, isRecording } = this.state;
    const { callStatus } = this.props;
    let displaySeconds = parseInt(seconds, 10) + 1;
    displaySeconds = displaySeconds < 10 ? `0${displaySeconds}` : displaySeconds;
    if (callStatus === 'Initiated') {
      this.onCallInitiated();
      return (
        <div className="call-status call-status-dailing">
          <span className="call-status-icon">
            <DialingIcon />
          </span>
          <FormattedMessage {...message.dialingLabel}/>
        </div>
      );
    }
    if (callStatus === 'Ringing') {
      this.onCallRinging();
      return (
        <div className="call-status call-status-ringing">
          <span className="call-status-icon">
            <RingingIcon />
          </span>
          <FormattedMessage {...message.ringingLael}/>
        </div>
      );
    }
    if (callStatus === 'Failed') {
      return null; // FIXME: get this status?
    }
    if (callStatus === 'Unanswered') {
      return (
        <div className="call-status call-status-unanswered">
          <span className="call-status-icon">
            <CallUnansweredIcon />
          </span>
          <FormattedMessage {...message.unansweredLabel}/>
        </div>
      );
    }
    if (callStatus === 'Answered') {
      this.startCallTimer();
    }
    if (callStatus === 'Answered' || callStatus === 'InProgress') {
      return (
        <div className="call-status call-status-answered">
          <span className="call-status-icon">{isRecording ? <OnCallRecordingIcon /> : <OnCallIcon />}</span>
          {`${hours}:${minutes}:${displaySeconds}`}
        </div>
      );
    }
    if (callStatus === 'Completed') {
      this.stopCallTimer();
      return (
        <div className="call-status call-status-completed">
          <span className="call-status-icon">
            <CallFinishedIcon />
          </span>
          {`${hours}:${minutes}:${displaySeconds}`}
        </div>
      );
    }
    if (callStatus === 'Rejected') {
      this.stopCallTimer();
      return (
        <div className="call-status call-status-rejected">
          <span className="call-status-icon">
            <CallDeclinedIcon />
          </span>
          <FormattedMessage {...message.rejectedLabel}/>
        </div>
      );
    }
    if (callStatus === 'Canceled') {
      this.stopCallTimer();
      return (
        <div className="call-status call-status-rejected">
          <span className="call-status-icon">
            <CallDeclinedIcon />
          </span>
          <FormattedMessage {...message.canceledLabel}/>
        </div>
      );
    }
    return null;
  }

  getAtsCallHeader = () => {
    return (
      <div className="call-dialog-header-content">
        <h3 className="call-dialog-header-text"> <FormattedMessage {...message.candidateActivityLabel}/></h3>
        <Button type="link" onClick={this.closeCallModal}>
        <FormattedMessage {...message.xLabel}/>
        </Button>
      </div>
    );
  };

  getAryaCallHeader() {
    const { phoneNumber, notesOpened, hours, minutes, seconds, isRecording } = this.state;
    const { callStatus, candidateName, onClose } = this.props;
    let displaySeconds = parseInt(seconds, 10) + 1;
    displaySeconds = displaySeconds < 10 ? `0${displaySeconds}` : displaySeconds;
    if (notesOpened) {
      if (callStatus === 'Answered') {
        this.startCallTimer();
      } else if (['Ringing', 'Canceled', 'Rejected', 'Completed'].includes(callStatus)) {
        this.stopCallTimer();
      }
      return (
        <Button style={{ borderRadius: 16 }} onClick={() => this.onNotesClick()}>
          <Icon type="left" />
          {isRecording ? <OnCallRecordingIcon /> : <OnCallIcon />}
          {`${hours}:${minutes}:${displaySeconds}`}
        </Button>
      );
    }
    return (
      <div className="dialpad-header">
        <div className="candidate-name">{candidateName}</div>
        {callStatus === 'Completed' ||
        callStatus === 'Rejected' ||
        callStatus === 'Failed' ||
        callStatus === 'Unanswered' ||
        callStatus === 'Canceled' ? (
          <div className="close-dialog-icon">
            <Icon onClick={onClose} type="close" />
          </div>
        ) : null}
        <div className="candidate-number">{phoneNumber}</div>
        {this.getCallStatus()}
      </div>
    );
  }

  getHeader = () => {
    const { openSipCallWindowsApp } = this.props;
    if (openSipCallWindowsApp) return this.getAtsCallHeader();
    return this.getAryaCallHeader();
  };

  getBody() {
    const { dialpadSelected, isMuted, isRecording, isOnHold } = this.state;
    const { callStatus } = this.props;

    if (callStatus === 'Answered' || callStatus === 'InProgress') {
      if (dialpadSelected) {
        return <Dialpad onDtmfTone={this.onSendDtmfTone} />;
      }
      return (
        <>
          <div className={`call-dialog-button ${isMuted ? 'active' : ''}`}>
            {isMuted ? <MuteIcon onClick={() => this.toggleMute()} /> : <SoundIcon onClick={() => this.toggleMute()} />}
            <span>{isMuted ? 'Unmute' : 'Mute'}</span>
          </div>
          <div className={`call-dialog-button ${isOnHold ? 'active' : ''}`}>
            <PauseIcon onClick={() => this.toggleHold()} />{' '}
            {/* TODO: set style based on isOnHold status, and enable/disable on call status */}
            <span><FormattedMessage {...message.holdLabel}/></span>
          </div>
          {/* <div className={`call-dialog-button ${isRecording ? 'active' : ''}`}>
            <RecordIcon onClick={() => this.toggleRecord()} />{' '}
             TODO: set style based on isRecoriding status, and enable/disable on call status
            <span>Record</span>
          </div> */}
        </>
      );
    }
    return null;
  }

  getFooter() {
    const { callStatus, candidate } = this.props;
    const { phoneNumber } = this.state;

    if (callStatus && callStatus !== 'Initiated' && callStatus !== 'Ringing' && callStatus !== 'Answered') {
      return (
        <div>
          <Button
            shape="round"
            onClick={this.onNotesClick}
            className="see-notes-btn"
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnect.goToCallNotes,
              candidate
            )}
          >
            <NotesIcon
              sk-event-name={getEventNameByCandidateStatus(
                eventTypes.candidate.candidateConnect.goToCallNotes,
                candidate
              )}
            />
            <FormattedMessage {...message.seeNotesLabel}/>
          </Button>
          <Button
            shape="round"
            onClick={this.onRedial}
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnect.callCandidateRedial,
              candidate
            )}
          >
            <CallIcon
              sk-event-name={getEventNameByCandidateStatus(
                eventTypes.candidate.candidateConnect.callCandidateRedial,
                candidate
              )}
            />
             <FormattedMessage {...message.redialLabel}/>
          </Button>
        </div>
      );
    }
    return (
      <>
        <div className="call-dialog-button">
          <DialpadIcon
            onClick={this.onDialpadClick}
            sk-event-name={getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.goToDialPad, candidate)}
          />
          <span> <FormattedMessage {...message.dialPadLabel}/></span>
        </div>
        <div className="call-dialog-button">
          <Button
            className="call-icon-wrapper"
            shape="circle"
            onClick={this.onCallButton}
            disabled={!phoneNumber}
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnect.disconnectCall,
              candidate
            )}
          >
            <CallIcon
              className="call-icon"
              style={{ color: '#ad1c27', fontSize: '48px' }}
              width="3em"
              height="3em"
              sk-event-name={getEventNameByCandidateStatus(
                eventTypes.candidate.candidateConnect.disconnectCall,
                candidate
              )}
            />
          </Button>
        </div>
        <div className="call-dialog-button">
          <NotesIcon
            onClick={this.onNotesClick}
            sk-event-name={getEventNameByCandidateStatus(
              eventTypes.candidate.candidateConnect.goToCallNotes,
              candidate
            )}
          />
          <span> <FormattedMessage {...message.notesLabel}/></span>
        </div>
      </>
    );
  }

  hangup() {
    const { onHangup } = this.props;
    onHangup();
    clearInterval(this.timerHandle);
    this.timerHandle = null;
    this.timeElapsed = 0;
  }

  toggleHold() {
    const { isOnHold } = this.state;
    const { callStatus } = this.props;
    if (callStatus === 'Answered' || callStatus === 'InProgress') {
      this.setState({ isOnHold: !isOnHold });
      const { onToggleHold } = this.props;
      onToggleHold(!isOnHold);
    }
  }

  toggleMute() {
    const { isMuted } = this.state;
    const { callStatus } = this.props;
    if (callStatus === 'Answered' || callStatus === 'InProgress') {
      this.setState({ isMuted: !isMuted });
      const { onToggleMute } = this.props;
      onToggleMute(!isMuted);
    }
  }

  toggleRecord() {
    const { isRecording } = this.state;
    const { onToggleCallRecord, callStatus } = this.props;
    if (callStatus === 'Answered' || callStatus === 'InProgress') {
      this.setState({ isRecording: !isRecording });
      onToggleCallRecord(!isRecording);
    }
  }

  tick() {
    this.timeElapsed += 1;
    const hours = Math.floor(this.timeElapsed / 3600);
    const minutes = Math.floor(this.timeElapsed / 60) % 60;
    const seconds = this.timeElapsed % 60;
    this.setState({
      hours: hours < 10 ? `0${hours}` : hours,
      minutes: minutes < 10 ? `0${minutes}` : minutes,
      seconds: seconds < 10 ? `0${seconds}` : seconds,
    });
  }

  startCallTimer() {
    if (!this.timerHandle) {
      this.timeElapsed = 0;
      this.setState({
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      this.timerHandle = setInterval(this.tick, 1000);
    }
  }

  stopCallTimer() {
    this.timeElapsed = 0;
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
      this.timerHandle = null;
      this.timeElapsed = 0;
    }
  }

  render() {
    const {
      conversationId,
      postCallNotes,
      notes,
      audioConversationId,
      candidateId,
      callStatus,
      postAtsCallNotes,
      callNotesContainer,
      atsJobCode,
      atsCandidateId,
      usersById,
      jobId,
      candidate,
    } = this.props;
    const { notesOpened } = this.state;
    const atsCallNotes =
      callNotesContainer &&
      React.createElement(callNotesContainer, { candidateId: atsCandidateId, jobId: atsJobCode }, null);

    let callStatusClass = '';
    if (callStatus === 'Answered' || callStatus === 'InProgress') {
      callStatusClass = 'inprogress';
    } else if (callStatus === 'Completed' || callStatus === 'Failed' || callStatus === 'Rejected') {
      callStatusClass = 'completed';
    }
    return (
      <Draggable>
        <div className="call-dialog">
          <div className={`call-dialog-header ${callStatusClass} ${notesOpened ? 'call-notes' : ''}`}>
            {this.getHeader()}
          </div>
          {notesOpened ? (
            <div>
              {atsCallNotes || (
                <CallNotes
                  audioConversationId={audioConversationId}
                  candidateId={candidateId}
                  conversationId={conversationId}
                  postCallNotes={postCallNotes}
                  notes={notes}
                  postAtsCallNotes={postAtsCallNotes}
                  usersById={usersById}
                  isCallNotes
                  jobId={jobId}
                  candidate={candidate}
                />
              )}
            </div>
          ) : (
            <div>
              <div className="call-dialog-body">{this.getBody()}</div>
              <div className="call-dialog-footer">{this.getFooter()}</div>
            </div>
          )}
        </div>
      </Draggable>
    );
  }
}
export default CallDialog;
