import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, Button } from 'antd';
import { Link } from 'react-router-dom';
import './AlertMessage.scss';
import jobMessage from '../JobForm/JobMessages';

export default function AlertMessage(props) {
  const { message, description, showSettingsLink, type, isClosable, onClose, version } = props;

  return (
    <Alert
      message={message}
      description={
        <div>
          {description}
          <br />
          {showSettingsLink === true && version !== 'ats' ? (
            <Link to="/settings/app">
              <Button type="link" className="settings-redirect-link">
                <FormattedMessage {...jobMessage.gotoSettingsButton} />
              </Button>
            </Link>
          ) : null}
        </div>
      }
      type={type}
      showIcon
      closable={isClosable}
      onClose={onClose}
    />
  );
}
