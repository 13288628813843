import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'antd';
import styles from './SearchSavedCriteriaModal.module.scss';
import message from '../../../ManualSearchMessages';

export default function SearchSavedCriteriaModal({ isModalVisible, handleCancel, handleOk }) {
  return (
    <Modal visible={isModalVisible} width="479px" centered onCancel={handleCancel} footer={null}>
      <div className={styles.searchSavedCriteriaModalContent}>
        <div className={styles.saveCriteriaheader}>
          <FormattedMessage {...message.currentSearchCriteriaReplacedLabel} />
        </div>
        <div className={styles.saveCriteriaDescription}>
          <FormattedMessage {...message.currentCriteriaReplacedLabel} />
          <br /> <FormattedMessage {...message.criteriaReplacedConfirmationLabel} />
        </div>
        <div className={styles.saveCriteriaFooter}>
          <Button shape="round" onClick={handleCancel} size="large">
            <FormattedMessage {...message.noLabel} />
          </Button>
          <Button type="primary" shape="round" onClick={handleOk} size="large">
            <FormattedMessage {...message.yesLabel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
}
