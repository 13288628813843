import _ from 'lodash';

export const concatComponentWithValue = (component, value) => {
  return `${component}.${value}`;
};

export const formatMessagesWithComponentPrefix = (messageObject, component) => {
  const formattedMessages = {};
  Object.keys(messageObject).forEach(key => {
    const formattedKey = _.upperFirst(key);
    const messageId = concatComponentWithValue(component, formattedKey);
    formattedMessages[key] = { id: messageId };
  });
  return formattedMessages;
};

export const formatJsonWithComponentPrefix = (jsonObject, component) => {
  const formattedJson = {};
  Object.keys(jsonObject).forEach(key => {
    const formattedKey = concatComponentWithValue(component, _.upperFirst(key));
    formattedJson[formattedKey] = jsonObject[key];
  });
  return formattedJson;
};
