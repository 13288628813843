import React from 'react';
import _ from 'lodash';
import { Skeleton } from 'antd';
import classNames from 'classnames';
import CandidateAccomplishment from './CandidateAccomplishment';
import CandidateWorkHistory from './CandidateWorkHistory';
import styles from './CandidateWorkHistoryWrapper.module.scss';
import CalculateWorkExperience from '../../Utils/WorkExperienceCalculator';
import CandidateResearch from '../ResearchCard/CandidateResearch';
import AnchorScroll from '../Common/AnchorScroll/AnchorScroll';
import {
  candidateWorkHistory,
  candidateAccomplishment,
  candidateResearch,
  candidateWorkHistoryItems,
  getAnchorTargetOffset,
} from '../../Utils/CandidateWorkHistoryUtils';
import { highlightKeyWords } from '../../Utils/KeywordHighlightUtil';

function CandidateWorkHistoryWrapper(props) {
  const {
    candidate,
    candidateDetailsStatus,
    appName,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    keywordsToHighlight,
    mustHavesKeywords = [],
    activeTab,
    activeSourceName,

    candidateListStatus,
  } = props;

  const ref = React.useRef(null);
  const [scrollingContainer, setScrollingContainer] = React.useState(null);
  React.useEffect(() => {
    const onScroll = () => {
      if (ref.current.scrollTop === 0) setCandidateViewHeaderVisibility(true);
      else setCandidateViewHeaderVisibility(false);
    };
    if (ref.current && candidateDetailsStatus !== 'INPROGRESS') {
      setScrollingContainer(ref.current);
      ref.current.addEventListener('scroll', onScroll);
    }
    return () => {
      if (ref.current) ref.current.removeEventListener('scroll', onScroll);
    };
  }, [candidateDetailsStatus]);

  const workExperience = candidate.YearsOfExperience
    ? candidate.YearsOfExperience
    : CalculateWorkExperience(_.get(candidate, 'Experiences', []));

  const researchData = _.get(candidate, 'MappedProfile.Research', []);
  const anchorTargetOffset = getAnchorTargetOffset({ isCandidateViewHeaderVisible });
  const candidateSummary = _.get(candidate, ['Summary']) || _.get(candidate, ['Derived', 'Summary'], '');

  React.useEffect(() => {
    if (candidateDetailsStatus === 'COMPLETED') {
      const allHighlights = _.uniq([
        ...(keywordsToHighlight || []),
        ...(activeTab === 'sourced' && activeSourceName !== 'AryaRecommended' ? [] : candidate.Keywords || []),
      ]);
      const domSelectors = ['div#candidate360-candidate-summary'];
      const mergedKeywordsList = [...allHighlights, ...mustHavesKeywords];
      highlightKeyWords(domSelectors, mergedKeywordsList);
      highlightKeyWords(domSelectors, mustHavesKeywords, 'mustHaveMark');
    }
  }, [candidateDetailsStatus, keywordsToHighlight]);

  const filteredCandidateWorkHistoryItems = candidateWorkHistoryItems.filter(item => item.id !== 'keywords-ref');

  return (
    <div className={styles.completeHistory}>
      <Skeleton
        active
        paragraph={{ rows: 8 }}
        loading={candidateDetailsStatus === 'INPROGRESS' || candidateListStatus === 'INPROGRESS'}
      >
        <AnchorScroll
          scrollingContainer={scrollingContainer}
          anchorMenuList={filteredCandidateWorkHistoryItems}
          anchorTargetOffset={anchorTargetOffset}
          preventUrlChange
          isAnchorMenuListLoading={!ref.current}
          defaultActiveLink={`#${candidateWorkHistory.id}`}
        />
        <div
          className={classNames(styles.rightAlign, { [styles.extendView]: !isCandidateViewHeaderVisible })}
          ref={ref}
        >
          <div>
            {candidateSummary ? (
              <div className={styles.summary} id="candidate360-candidate-summary">
                {candidateSummary}
              </div>
            ) : null}
            <div>
              <div className={styles.title} id={candidateWorkHistory.id}>
                {candidateWorkHistory.name}
              </div>
            </div>
            <CandidateWorkHistory
              experiences={candidate?.Experiences}
              name={candidate?.Name}
              appName={appName}
              workExperience={workExperience}
              keywordsToHighlight={keywordsToHighlight}
              mustHavesKeywords={mustHavesKeywords}
              candidate={candidate}
              candidateDetailsStatus={candidateDetailsStatus}
              activeTab={activeTab}
              activeSourceName={activeSourceName}
            />
            <div className={styles.title} id={candidateAccomplishment.id}>
              {candidateAccomplishment.name}
            </div>
            <CandidateAccomplishment certifications={candidate?.Certifications} />
            <div className={styles.title} id={candidateResearch.id}>
              {candidateResearch.name}
            </div>
            <CandidateResearch researchData={researchData} />
          </div>
        </div>
      </Skeleton>
    </div>
  );
}

export default CandidateWorkHistoryWrapper;
