import { defineMessages } from 'react-intl';
import connectTemplatesMessagesJson from '../../../Data/En_Us/ConnectTemplatesMessagesEn_Us.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../../Utils/LanguageUtils';

const component = 'ConnectTemplates';
const connectTemplateMessages = formatMessagesWithComponentPrefix(connectTemplatesMessagesJson, component);

const connectTemplatesMessagesEn = formatJsonWithComponentPrefix(connectTemplatesMessagesJson, component);

const messages = defineMessages({
  emailTemplates: {
    id: 'Templates.Email.emailTemplates',
    defaultMessage: 'Email Templates',
  },
  searchEmailTemplates: {
    id: 'Templates.Email.searchEmailTemplates',
    defaultMessage: 'Search Email Templates',
  },
  template: {
    id: 'Templates.Email.Heading.template',
    defaultMessage: 'TEMPLATE',
  },
  updatedOn: {
    id: 'Templates.Email.Heading.updatedOn',
    defaultMessage: 'UPDATED ON',
  },
  includesBot: {
    id: 'Templates.Email.Heading.includesBot',
    defaultMessage: 'INCLUDES BOT',
  },
  shared: {
    id: 'Templates.Email.Heading.shared',
    defaultMessage: 'SHARED',
  },
  createdBy: {
    id: 'Templates.Email.Heading.createdBy',
    defaultMessage: 'CREATED BY',
  },
  updatedBy: {
    id: 'Templates.Email.Heading.updatedBy',
    defaultMessage: 'UPDATED BY',
  },
  myTemplates: {
    id: 'Templates.Email.Tab.myTemplates',
    defaultMessage: 'My Templates',
  },
  sharedTemplates: {
    id: 'Templates.Email.Tab.sharedTemplates',
    defaultMessage: 'Shared Templates',
  },
  emailTemplate: {
    id: 'Templates.Email.emailTemplate',
    defaultMessage: 'Email Template',
  },
  systemEmailTemplate: {
    id: 'Templates.Email.systemEmailTemplate',
    defaultMessage: 'System Templates',
  },
  emailTemplateName: {
    id: 'Templates.Email.emailTemplateName',
    defaultMessage: 'Email Template Name',
  },
  subjectLine: {
    id: 'Templates.Email.subjectLine',
    defaultMessage: 'Subject Line',
  },
  ...connectTemplateMessages,
});

export default messages;
export { connectTemplatesMessagesEn };
