import React from 'react';
import uuid from 'uuid';
import { Form } from 'antd';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';
import { validateBooleanString } from '../../../../../Utils/FormValidators';
import { getBooleanAutoSuggestParameters } from '../../../../../Utils/BooleanStringUtility';
import BooleanTextAreaStatic from './BooleanTextAreaStatic';
import styles from '../SkillsSection.module.scss';
import booleanSearchStyles from './BooleanSearch.module.scss';
import { fetchSkillAutocomplete } from '../../../../../Repository/AutocompleteRepository';
import {
  booleanOperators,
  getBooleanString,
  getValueToBeInserted,
  isStaticOption,
  stripQuotes,
  suggestionTypes,
  isWrappedByQuotes,
} from '../../../../../Utils/BooleanSearchUtils';
import message from '../../../ManualSearchMessages';

const { Item } = Form;

let matchedWord;
let matchedOperator;
let multiMatchedWords;
let skipSuggestions;

const skipSpecialCharacters = value => {
  return value.replace(/([^\w\s])/g, '\\$1');
};

function BooleanSearch(props) {
  const {
    form,
    keywords,
    defaultQueryString,
    searchStringValues,
    isBooleanSearch,
    fetchBooleanSearchStrings,
    jobId,
    isManualSearchFormMinimized,
    intl,
  } = props;
  const [suggestions, setSuggestions] = React.useState([]);
  const [isBooleanSearchStringsFetched, setIsBooleanSearchStringsFetched] = React.useState(false);
  const [activeSuggestionType, setActiveSuggestionType] = React.useState(null);
  const [uniqueIdentifier, setUniqueIdentifier] = React.useState(null);

  React.useEffect(() => {
    setUniqueIdentifier(uuid.v4());
    return () => {
      matchedWord = undefined;
      matchedOperator = undefined;
      multiMatchedWords = undefined;
      skipSuggestions = undefined;
    };
  }, []);

  const textAreaRef = document.getElementById(
    `booleanTextArea-${uniqueIdentifier}-${isManualSearchFormMinimized ? 'minimized' : 'expanded'}`
  );

  React.useEffect(() => {
    if (!isBooleanSearchStringsFetched && isBooleanSearch) {
      fetchBooleanSearchStrings({ jobId, size: 3 });
      setIsBooleanSearchStringsFetched(true);
    }
  }, [isBooleanSearch]);

  const trySuggestBooleanSearchStrings = () => {
    const value = form.getFieldValue('QueryString');
    if (!_.trim(value)) {
      setSuggestions([...searchStringValues]);
      setActiveSuggestionType(suggestionTypes.complexBoolean);
    }
  };

  const handleFocus = () => {
    trySuggestBooleanSearchStrings();
  };

  function resetSuggestions() {
    setSuggestions([]);
    setActiveSuggestionType(null);
    skipSuggestions = true;
  }

  function suggestSkills(newMatchedWord) {
    const matchedKeywords = keywords
      .map(item => item.toLowerCase())
      .filter(item => item.startsWith(newMatchedWord.toLowerCase()));
    fetchSkillAutocomplete({
      searchTerm: stripQuotes(newMatchedWord),
      from: 0,
      size: 5,
      includeSynonyms: false,
    }).then(response => {
      const { data } = response;
      const skills = data.Skills.map(x => x.Skill);
      const suggestionSkills = _.uniq(matchedKeywords.concat(skills)).slice(0, 5);
      if (skills.length === 1 && skills[0] === newMatchedWord) {
        // Same suggestion as matched word, hence abort
        resetSuggestions();
        return;
      }
      if (!skipSuggestions) {
        setSuggestions(suggestionSkills);
        setActiveSuggestionType(suggestionTypes.keywords);
      }
    });
  }

  function setStaticSuggestions() {
    setSuggestions(booleanOperators);
    setActiveSuggestionType(suggestionTypes.static);
    skipSuggestions = true;
  }

  function handleWordMatch(value, newMatchedWord, newMultiMatchedWords) {
    if (newMultiMatchedWords?.length > 0) {
      suggestSkills(newMultiMatchedWords.join(' '));
      return;
    }
    if (isWrappedByQuotes(newMatchedWord)) {
      // if empty quotes exist
      if (!stripQuotes(newMatchedWord)) {
        resetSuggestions();
        return;
      }
      if (_.endsWith(value, newMatchedWord)) {
        // Already suggested and selected previously. so skip
        resetSuggestions();
        return;
      }
      if (_.endsWith(_.trimEnd(value), newMatchedWord)) {
        // suggest static
        setStaticSuggestions();
        return;
      }
      const quote = newMatchedWord[0];
      const trimEnded = _.trimEnd(`${value}${quote}`);
      if ((_.endsWith(trimEnded), newMatchedWord)) {
        suggestSkills(stripQuotes(newMatchedWord));
        return;
      }
    }
    const trimmedWord = newMatchedWord?.trim();
    if (trimmedWord && newMatchedWord[newMatchedWord.length - 1] === ' ') {
      setStaticSuggestions();
      return;
    }
    if (!trimmedWord) {
      return;
    }
    suggestSkills(newMatchedWord);
  }

  function handleBooleanOperatorORMatch(newMatchedWord) {
    const searchTerm = stripQuotes(newMatchedWord.trim().toLowerCase());
    fetchSkillAutocomplete({
      searchTerm,
      from: 0,
      size: 1,
      includeSynonyms: true,
      includeUserSuggestions: true,
      userSuggestionsCount: 5,
    }).then(response => {
      const { data } = response;
      const skills = data.Skills;
      if (skills.length === 0) {
        return;
      }
      const skill = skills.find(x => x.Skill.toLowerCase() === searchTerm);
      if (!skill) {
        return;
      }
      let skillSynonyms = data.SkillSynonymGroups[skill.SynonymsId] ?? [];
      skillSynonyms = _.uniq(skillSynonyms);
      const netSynonyms = skillSynonyms.filter(x => x !== skill.Skill).slice(0, 5);
      const booleanString = getBooleanString(skill.Skill, netSynonyms);
      const synonymsBooleanStrings = booleanString ? [booleanString] : [];
      const userSuggestions = skills[0].UserSuggestions ?? [];
      const userSuggestedBooleanStrings = userSuggestions.map(userSuggestionGroup => {
        const netSkillSuggestionsGroup = userSuggestionGroup.filter(x => x !== skill.Skill).slice(0, 5);
        return getBooleanString(skill.Skill, netSkillSuggestionsGroup);
      });
      if (synonymsBooleanStrings.length || userSuggestedBooleanStrings.length) {
        setSuggestions([...synonymsBooleanStrings, ...userSuggestedBooleanStrings].slice(0, 5));
        setActiveSuggestionType(suggestionTypes.boolean);
      } else {
        setSuggestions([]);
      }
    });
  }

  const tryAutoSuggestOptions = value => {
    if (!value?.trim()) {
      resetSuggestions();
      matchedWord = null;
      matchedOperator = null;
      multiMatchedWords = null;
      return;
    }
    const {
      matchedWord: newMatchedWord,
      matchedOperator: newMatchedOperator,
      multiMatchedWords: newMultiMatchedWords,
    } = getBooleanAutoSuggestParameters(value);
    matchedWord = newMatchedWord;
    matchedOperator = newMatchedOperator;
    multiMatchedWords = newMultiMatchedWords;
    if (_.endsWith(_.trimEnd(value), newMatchedWord?.trim()) && _.trimEnd(value) !== value) {
      setStaticSuggestions();
      return;
    }
    if (!newMatchedWord && !newMatchedOperator && !newMultiMatchedWords) {
      resetSuggestions();
      return;
    }
    if ((newMatchedWord && !newMatchedOperator) || newMultiMatchedWords?.length > 0) {
      handleWordMatch(value, newMatchedWord, newMultiMatchedWords);
      return;
    }

    if (newMatchedOperator) {
      if (newMatchedOperator?.trim() === 'OR' && newMatchedWord?.trim()) {
        handleBooleanOperatorORMatch(newMatchedWord);
        return;
      }
      resetSuggestions();
    }
  };

  function capitalizeBooleanOperators(value) {
    if (!value) {
      return value;
    }
    let replacedValue = value;
    const matchedDoubleQuotes = replacedValue.match(/".*?"/g, 'Δ') ?? [];
    replacedValue = replacedValue.replace(/".*?"/g, 'Δ');
    const matchedSingleQuotes = replacedValue.match(/'.*?'/g, 'Ω') ?? [];
    replacedValue = replacedValue.replace(/'.*?'/g, 'Ω');
    replacedValue = replacedValue
      .replace(/\s(OR)\s/gi, ' OR ')
      .replace(/\s(AND)\s/gi, ' AND ')
      .replace(/\s(NOT)\s/gi, ' NOT ');
    let i = 0;
    while (i < matchedDoubleQuotes.length) {
      replacedValue = replacedValue.replace('Δ', matchedDoubleQuotes[i]);
      i += 1;
    }
    i = 0;
    while (i < matchedSingleQuotes.length) {
      replacedValue = replacedValue.replace('Ω', matchedSingleQuotes[i]);
      i += 1;
    }
    return replacedValue;
  }

  const onInputChange = e => {
    skipSuggestions = false;
    const { value } = e.target;
    const replacedValue = capitalizeBooleanOperators(value);
    const caretPosition = textAreaRef.selectionStart;
    const valueUntilCaretPosition = value.slice(0, caretPosition);
    form.setFieldsValue({ QueryString: replacedValue }, () => {
      form.validateFields(['QueryString']);
      textAreaRef.setSelectionRange(caretPosition, caretPosition);
      tryAutoSuggestOptions(valueUntilCaretPosition);
    });
  };

  function onFormFieldValueSet(preCaretText) {
    textAreaRef.focus();
    textAreaRef.setSelectionRange(preCaretText.length, preCaretText.length);
    form.validateFields(['QueryString']);
  }

  function handleStaticOptionSelect(queryString, selectedItem, caretPosition) {
    const valueToBeInserted = selectedItem === ')' ? selectedItem : ` ${selectedItem} `;
    const preCaretText = `${_.trimEnd(queryString.slice(0, caretPosition))}${valueToBeInserted}`;
    const updatedQueryString = `${preCaretText}${_.trimStart(queryString.slice(caretPosition))}`;
    form.setFieldsValue({ QueryString: updatedQueryString }, () => {
      onFormFieldValueSet(preCaretText);
    });
    if (activeSuggestionType === suggestionTypes.static) {
      resetSuggestions();
      tryAutoSuggestOptions(updatedQueryString);
    }
  }

  function handleMultiMatchedWordsSelect(queryString, selectedItem, caretPosition) {
    const replacableTerm = skipSpecialCharacters(multiMatchedWords.join(' '));
    const replaceLastOccurrenceRegex = new RegExp(`(\\b${replacableTerm}\\b)(?!.*\\b\\1\\b)`);
    const replacedText = queryString.slice(0, caretPosition).replace(replaceLastOccurrenceRegex, selectedItem);
    const preCaretText = `${replacedText} `;
    const updatedQueryString = `${preCaretText}${_.trimEnd(queryString.slice(caretPosition))}`.replace(/""|" "/, '"');
    form.setFieldsValue({ QueryString: `${updatedQueryString}` }, () => {
      onFormFieldValueSet(preCaretText);
      setStaticSuggestions();
    });
  }

  function handleBooleanStringOptionSelect(queryString, caretPosition, selectedBooleanString) {
    const appendableBooleanKeywords = selectedBooleanString.split(' OR ').slice(1);
    const appendableBooleanString = getBooleanString(null, appendableBooleanKeywords);
    const replacebleTerm = `${matchedWord} ${matchedOperator}`;
    const regex = new RegExp(`${skipSpecialCharacters(matchedWord)}\\s+${matchedOperator}\\s*$`);
    const preCaretText = _.trimEnd(queryString.slice(0, caretPosition)).replace(
      regex,
      `(${replacebleTerm} ${appendableBooleanString})`
    );
    const updatedString = `${preCaretText}${_.trimStart(queryString.slice(caretPosition))}`;
    form.setFieldsValue({ QueryString: updatedString }, () => {
      onFormFieldValueSet(preCaretText);
    });
  }

  function handleMatchedWordSelect(queryString, selectedItem, caretPosition) {
    const netMatchedWord = skipSpecialCharacters(stripQuotes(matchedWord));
    const regex = new RegExp(`${netMatchedWord}$|"${netMatchedWord}$|'${netMatchedWord}$`);
    const preCaretText = `${queryString.slice(0, caretPosition).replace(regex, selectedItem)} `;
    const updatedQueryString = `${preCaretText}${_.trimStart(queryString.slice(caretPosition))}`.replace(/""|" "/, '"');
    form.setFieldsValue({ QueryString: updatedQueryString }, () => {
      onFormFieldValueSet(preCaretText);
      setStaticSuggestions();
    });
  }

  function onMenuItemSelect(selectedItem) {
    const queryString = form.getFieldValue('QueryString');
    const caretPosition = textAreaRef.selectionStart;
    if (isStaticOption(selectedItem)) {
      handleStaticOptionSelect(queryString, selectedItem, caretPosition);
      return;
    }
    let newSelectedItem = selectedItem;
    if (
      selectedItem.trim().includes(' ') &&
      !(matchedWord && matchedOperator) &&
      (matchedWord || matchedOperator || multiMatchedWords)
    ) {
      newSelectedItem = `"${selectedItem}"`;
    }
    if (multiMatchedWords?.length > 0) {
      handleMultiMatchedWordsSelect(queryString, newSelectedItem, caretPosition);
      return;
    }
    if (matchedWord && !matchedOperator) {
      if (matchedWord) {
        handleMatchedWordSelect(queryString, newSelectedItem, caretPosition);
        return;
      }
    } else if (matchedWord && matchedOperator) {
      handleBooleanStringOptionSelect(queryString, caretPosition, newSelectedItem);
    } else {
      const preCaretText = `${_.trimEnd(queryString?.slice(0, caretPosition))} ${newSelectedItem}`;
      const updatedQueryString = `${preCaretText}${_.trimStart(queryString?.slice(caretPosition))}`;
      form.setFieldsValue({ QueryString: updatedQueryString }, () => {
        onFormFieldValueSet(preCaretText);
      });
      if (activeSuggestionType === suggestionTypes.static) {
        tryAutoSuggestOptions(updatedQueryString);
      } else {
        resetSuggestions();
      }
    }
    matchedWord = null;
    matchedOperator = null;
    multiMatchedWords = null;
    form.validateFields(['QueryString']);
  }

  const handleClick = value => {
    const caretPosition = textAreaRef.selectionStart;
    const queryString = _.trim(form.getFieldValue('QueryString'));
    const valueToBeInserted = getValueToBeInserted(value, queryString, caretPosition);
    const updatedQueryString = `${queryString.slice(0, caretPosition)}${valueToBeInserted}${queryString.slice(
      caretPosition
    )}`;
    form.setFieldsValue({ QueryString: updatedQueryString }, () => {
      textAreaRef.focus();
      textAreaRef.setSelectionRange(caretPosition + valueToBeInserted.length, caretPosition + valueToBeInserted.length);
      tryAutoSuggestOptions(updatedQueryString);
      form.validateFields(['QueryString']);
    });
  };

  const onTextAreaInputClick = () => {
    trySuggestBooleanSearchStrings();
  };

  return (
    <div>
      <div className={`${isManualSearchFormMinimized ? styles.queryString : styles.hideMinimisedView}`}>
        <Item colon={false}>
          {form.getFieldDecorator('QueryString', {
            initialValue: defaultQueryString,
            rules: [
              {
                validator: (rule, booleanString, callback) =>
                  validateBooleanString(rule, booleanString, callback, form),
              },
            ],
          })(
            <BooleanTextAreaStatic
              uniqueIdentifier={uniqueIdentifier}
              onFocus={handleFocus}
              onClick={onTextAreaInputClick}
              className={styles.booleanStringTextArea}
              style={{ overflow: 'hidden' }}
              placeholder={intl.formatMessage({ ...message.booleanSearchPlaceholder })}
              onInputChange={onInputChange}
              onSelect={onMenuItemSelect}
              options={suggestions}
              view="minimized"
              dropdownItemClassName={classNames(
                {
                  [booleanSearchStyles.suggestionDropdownItemViolet]: activeSuggestionType === suggestionTypes.keywords,
                },
                {
                  [booleanSearchStyles.suggestionDropdownItemGreen]: activeSuggestionType === suggestionTypes.static,
                },
                {
                  [booleanSearchStyles.suggestionDropdownItemBlue]:
                    activeSuggestionType === suggestionTypes.boolean ||
                    activeSuggestionType === suggestionTypes.complexBoolean,
                },
                {
                  [booleanSearchStyles.suggestionDropdownDotted]:
                    activeSuggestionType === suggestionTypes.complexBoolean,
                }
              )}
            />
          )}
        </Item>
      </div>
      <div className={`${isManualSearchFormMinimized ? styles.booleanMinimisedView : styles.booleanMaximizeView}`}>
        <div className={styles.booleanSkillsText}>
        <FormattedMessage {...message.createYourBooleanSearchLabel} />
        </div>
        <div className={styles.suggestedSkills}>
          {keywords.map(skill => (
            <div
              key={skill}
              className={styles.suggestedSkill}
              role="button"
              tabIndex={0}
              onClick={() => handleClick(skill)}
              onKeyPress={() => handleClick(skill)}
            >
              {skill}
            </div>
          ))}
        </div>
        <div className={styles.booleanOperators}>
          {booleanOperators.map(booleanOperator => (
            <div
              key={booleanOperator}
              className={styles.booleanOperatorTag}
              role="button"
              tabIndex={0}
              onClick={() => handleClick(booleanOperator)}
              onKeyPress={() => handleClick(booleanOperator)}
            >
              {booleanOperator}
            </div>
          ))}
        </div>
        <div className={styles.queryString}>
          <Item colon={false}>
            {form.getFieldDecorator('QueryString', {
              initialValue: defaultQueryString,
              rules: [
                {
                  validator: (rule, booleanString, callback) =>
                    validateBooleanString(rule, booleanString, callback, form),
                },
              ],
            })(
              <BooleanTextAreaStatic
                uniqueIdentifier={uniqueIdentifier}
                onFocus={handleFocus}
                onClick={onTextAreaInputClick}
                className={styles.booleanStringTextArea}
                style={{ overflow: 'hidden' }}
                placeholder='Example: ("Talent Acquisition" OR Staffing) AND ("Talent Management")'
                onInputChange={onInputChange}
                onSelect={onMenuItemSelect}
                options={suggestions}
                view="expanded"
                dropdownItemClassName={classNames(
                  {
                    [booleanSearchStyles.suggestionDropdownItemViolet]:
                      activeSuggestionType === suggestionTypes.keywords,
                  },
                  {
                    [booleanSearchStyles.suggestionDropdownItemGreen]: activeSuggestionType === suggestionTypes.static,
                  },
                  {
                    [booleanSearchStyles.suggestionDropdownItemBlue]:
                      activeSuggestionType === suggestionTypes.boolean ||
                      activeSuggestionType === suggestionTypes.complexBoolean,
                  },
                  {
                    [booleanSearchStyles.suggestionDropdownDotted]:
                      activeSuggestionType === suggestionTypes.complexBoolean,
                  }
                )}
              />
            )}
          </Item>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(BooleanSearch);
