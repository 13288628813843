import React from 'react';
import { Skeleton } from 'antd';
import queryString from 'query-string';
import _ from 'lodash';
import { connect } from 'react-redux';
import CandidateList from '../../Components/CandidateList/CandidateList';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getCandidateCount, getCandidatesAsList, getCurrentCandidateId } from '../../Reducers/CandidateReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getCurrentJobDetails, getSourcingStats } from '../../Reducers/JobReducer';
import {
  getCurrentPage,
  getPageSize,
  getSelectedBulkActivity,
  getSelectedCandidates,
  getSelectedCustomBulkActivity,
} from '../../Reducers/JobCandidatesTabReducer';
import {
  getManualSearchKeywordsToHighlight,
  getManualSearchCriteriaTypeAndRecordId,
} from '../../Reducers/ManualSearchReducer';
import { getIsCreditInfoPopoverVisible as _getIsCreditInfoPopoverVisible } from '../../Utils/CreditInfoUtils';
import { getCandidateSource, getSourceName } from '../../Utils/SourceUtils';
import * as candidateActions from '../../Actions/CandidateActions';
import * as contactFetchActions from '../../Actions/ContactFetchActions';
import * as jobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import * as jobActions from '../../Actions/JobActions';
import CandidateDrawerWrapper from '../CandidateDrawerWrapper/CandidateDrawerWrapper';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { QuickSearchEmptyScreen } from '../QuickSearch/QuickSearchEmptyScreen/QuickSearchEmptyScreen';
import styles from './ManualSearchCandidateList.module.scss';
import {
  getFetchedCandidatesCountBySource,
  getManualSearchCandidatesFetchApiStatus,
  getManualSearchCandidates,
  getAllNonGroupHeadDuplicateCandidateIds,
  getIsCandidateDuplicate,
  getAllTabCandidates,
} from '../../Reducers/ManualSearchCandidateReducer';
import {
  fetchSubSegments,
  setSubsegmentCreateModalStatus,
  setSubsegmentDeleteModalStatus,
  setSubsegmentAddModalStatus,
  setCreatedSubSegmentId as _setCreatedSubSegmentId,
} from '../../Actions/SubSegmentActions';
import {
  getAddModalVisbilityStatus,
  getSubSegmentListofSegment,
  getLastCreatedSubSegmentId,
} from '../../Reducers/SubsegmentReducer';
import { logSmartKarrotEvent } from '../../Analytics/EventUtils';
import { getCandidateMetadataActionEventName } from '../../Analytics/Candidate/CandidateEventUtils';
import { modules } from '../../Analytics/EventTypes';
import { getSortedManualSearchNonDuplicateCandidates, getIsDeDuplicationAllowed } from '../../Utils/DeDuplicationUtils';
import AddCandidateToSegmentPopover from '../SubSegment/AddCandidateToSegmentModal';
import { getActualSourcingStats } from '../../Utils/JobSourcingStatsUtil';
import { getAdminFeatures } from '../../Reducers/UserReducer';
import { getCandidatePublishStatus } from '../../Utils/CandidatePublishUtils';
import { getFilteredAllTabCandidates } from '../../Utils/CandidateListUtils';

const CandidateShortlist = 'CandidateShortlist';
const OpenCandidateDrawer = 'OpenCandidateDrawer';
const FetchContact = 'FetchContact';
const OpenCandidateView = 'OpenCandidateView';

const mapStateToProps = (state, props) => ({
  featureToggleList: getFeatureToggleList(state),
  candidates: getCandidatesAsList(state),
  candidateListStatus: getApiStatus(state, 'candidateListStatus'),
  candidateDetailsStatus: getApiStatus(state, 'candidateDetailsStatus'),
  candidateAllNotesFetchStatus: getApiStatus(state, 'fetchBulkCandidateAllNotesApiStatus'),
  candidateStatusUpdate: getApiStatus(state, 'sourceCandidateStatusUpdate'),
  candidateBookmarkApiStatus: getApiStatus(state, 'candidateBookmarkApiStatus'),
  config: getConfig(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  currentJobDetails: getCurrentJobDetails(state),
  currentPage: getCurrentPage(state),
  pageSize: getPageSize(state),
  candidateCount:
    props.activeSourceName === 'AryaRecommended'
      ? getCandidateCount(state)
      : getFetchedCandidatesCountBySource(state, { jobId: props.jobId, source: props.activeSourceName }) ?? 0,
  currentUser: getCurrentUser(state),
  currentSelectedAllCandidates: getSelectedCandidates(state, 'NonRecommended'),
  bulkActivitySelection: getSelectedBulkActivity(state, 'NonRecommended'),
  keywordsToHighlight: getManualSearchKeywordsToHighlight(state, { jobId: props.jobId })?.allKeywordsToHighlight,
  mustHavesKeywords: getManualSearchKeywordsToHighlight(state, { jobId: props.jobId })?.mustHavesKeywords,

  manualSearchCandidateFetchApiStatus: getManualSearchCandidatesFetchApiStatus(state, {
    jobId: props.jobId,
    source: props.activeSourceName,
  }),
  manualSearchCandidates: getManualSearchCandidates(state, props.jobId),
  allTabCandidates:
    props.candidateContext === 'job'
      ? getFilteredAllTabCandidates({ store: state, jobId: props.jobId })
      : getAllTabCandidates(state, props.jobId),
  duplicateCandidateIds: getAllNonGroupHeadDuplicateCandidateIds(state, props.jobId, props.activeSourceName),
  currentCandidateId: getCurrentCandidateId(state),
  getIsCurrentCandidateDuplicate: candidateId =>
    getIsCandidateDuplicate(state, props.jobId, candidateId, props.activeSourceName),
  bulkCustomActivitySelection: getSelectedCustomBulkActivity(state, 'NonRecommended'),
  addSubsegmentModalStatus: getAddModalVisbilityStatus(state),
  subsegmentList: getSubSegmentListofSegment(state, props.jobId),
  fetchSubSegmentApiStatus: getApiStatus(state, 'fetchSubSegmentApiStatus'),
  sourcingStats: getSourcingStats(state, props.jobId),
  adminFeatures: getAdminFeatures(state),
  manualSearchCriteriaTypeAndRecordId: getManualSearchCriteriaTypeAndRecordId(state, { jobId: props.jobId }),
  lastCreatedCandidateId: getLastCreatedSubSegmentId(state),
});

const mapDispatchToProps = {
  changeCandidateStatus: candidateActions.changeCandidateStatus,
  setCandidateRejectStatus: candidateActions.setCandidateRejectStatus,
  downloadResume: candidateActions.downloadResume,
  unlockCandidateResume: candidateActions.unlockCandidateResume,
  fetchCandidateDetails: candidateActions.fetchCandidateDetails,
  onFetchContact: contactFetchActions.onFetchContact,
  setSelectedCandidates: jobCandidatesTabActions.setSelectedCandidates,
  setSelectedBulkActivity: jobCandidatesTabActions.setSelectedBulkActivity,
  fetchJobSourcingStats: jobActions.fetchJobSourcingStats,
  updateCandidateBookmarkStatus: candidateActions.updateCandidateBookmarkStatus,
  setCustomSelectedBulkActivity: jobCandidatesTabActions.setCustomSelectedBulkActivity,
  setAddSubsegmentModalStatus: setSubsegmentAddModalStatus,
  fetchSubSegmentsAction: fetchSubSegments,
  setSubsegmentDeleteModalStatusAction: setSubsegmentDeleteModalStatus,
  setSubsegmentModalStatus: setSubsegmentCreateModalStatus,
  setCreatedSubSegmentId: _setCreatedSubSegmentId,
};

export const getUpdatedPageSize = (activeSourceName, candidates, pageSize) => {
  if (activeSourceName !== 'All') return pageSize;
  const filteredCandidates = candidates.filter(item => item.candidateIndex >= 0);
  return filteredCandidates.length;
};

export const getPrevCandidateIndex = (activeSourceName, isCandidateDuplicate, currentCandidateIndex) => {
  return getIsDeDuplicationAllowed(activeSourceName) && isCandidateDuplicate
    ? currentCandidateIndex
    : currentCandidateIndex - 1;
};

export const getIsNextButtonDisabled = (
  currentCandidateIndex,
  nextCandidateIndex,
  currentCandidateIndexInTheList,
  totalCandidate,
  manualSearchCandidates,
  allTabCandidates,
  duplicateCandidateIds,
  activeSourceName
) => {
  const manualSearchAllTabCandidates = getSortedManualSearchNonDuplicateCandidates(
    manualSearchCandidates,
    allTabCandidates,
    duplicateCandidateIds,
    activeSourceName
  );
  const totalManualSearchCandidates = getIsDeDuplicationAllowed(activeSourceName)
    ? manualSearchAllTabCandidates.length
    : totalCandidate;
  return (
    currentCandidateIndex === undefined ||
    nextCandidateIndex !== undefined ||
    currentCandidateIndexInTheList >= totalManualSearchCandidates - 1
  );
};

export const getGreatestCandidateIndexFromTheList = candidates => {
  return candidates.reduce((maxIndex, item) => {
    if (item.candidateIndex !== undefined && item.candidateIndex > maxIndex) {
      return item.candidateIndex;
    }
    return maxIndex;
  }, -Infinity);
};

function isCandidateWithinRange({ From: from, Size: size }, index, pageSize, currentPage) {
  const candidateSearchFilterFrom = (currentPage - 1) * pageSize;
  return from <= candidateSearchFilterFrom + index && candidateSearchFilterFrom + index < from + size;
}

function isCandidateWithinSearchCriteria(
  candidate,
  index,
  activeSource,
  bulkActivitySelection,
  pageSize,
  currentPage,
  bulkCustomActivitySelection = {}
) {
  const currentSource = activeSource?.Portal ?? activeSource?.Group;
  const bulkActivitySearchCriteria = bulkActivitySelection?.[currentSource]?.bulkActivitySearchCriteria;
  const bulkCustomActivitySearchCriteria = bulkCustomActivitySelection?.[currentSource]?.bulkActivitySearchCriteria;

  if (bulkCustomActivitySearchCriteria) {
    const { excludedCandidates } = bulkCustomActivitySelection[currentSource];
    if (excludedCandidates?.find(x => x.CandidateId === candidate.Id)) {
      return false;
    }
    return isCandidateWithinRange(bulkCustomActivitySearchCriteria, index, pageSize, currentPage);
  }

  if (!bulkActivitySearchCriteria) {
    return false;
  }

  const { excludedCandidates } = bulkActivitySelection[currentSource];
  if (excludedCandidates?.find(x => x.CandidateId === candidate.Id)) {
    return false;
  }

  return isCandidateWithinRange(bulkActivitySearchCriteria, index, pageSize, currentPage);
}

class ManualSearchCandidateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextOperation: undefined,
      nextOperationCandidateInfo: undefined,
      creditInfoVisibility: false,
      candidateDrawerKey: 'profile',
      candidateDrawerSubkey: 'profile',
      currentCandidateIndex: undefined,
      nextCandidateIndex: undefined,
      candidateDrawerVisiblity: false,
      currentCandidateId: undefined,
      drawerRenderFlag: false,
      candidateFilter: undefined,
      candidateSize: undefined,
      composeEmailType: false,
    };
    this.handleAddCandidateSubsegmentModal = this.handleAddCandidateSubsegmentModal.bind(this);
  }

  segmentCandidateSearchMaxLimit = 50000;

  showCandidateDrawer = (candidate, key, index, subKey) => {
    const { jobId, currentJobDetails, fetchCandidateDetails, featureToggleList, adminFeatures } = this.props;
    const jobGuid = currentJobDetails?.JobGuid;
    const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList);
    const isAssistPublishEnabled = featureToggleList.AssistedSourcing.Enabled;
    const { nextOperation } = this.state;
    const candidateInfo = { ...candidate, jobId, candidateId: candidate?.Id };
    const pushCandidateInfo = { refId: jobGuid, Country: currentJobDetails?.CountryCode };
    const isCreditInfoPopoverVisible = this.getIsCreditInfoPopoverVisible({
      candidate,
    });
    if (isCreditInfoPopoverVisible && !isAssistPublishEnabled && !isCandidatePublishEnabled) {
      this.setState({
        nextOperation: OpenCandidateDrawer,
        nextOperationCandidateInfo: candidate,
        creditInfoVisibility: true,
        candidateDrawerKey: key,
        candidateDrawerSubkey: subKey,
        currentCandidateIndex: index,
      });
      return;
    }
    if (!isCandidatePublishEnabled) fetchCandidateDetails(candidateInfo, pushCandidateInfo, true);

    this.setState({
      candidateDrawerVisiblity: true,
      candidateDrawerKey: key,
      candidateDrawerSubkey: subKey ?? 'profile',
      currentCandidateIndex: index,
      currentCandidateId: candidate?.Id,
      drawerRenderFlag: true,
    });
    if (nextOperation) {
      this.setState({
        nextOperation: null,
      });
    }
  };

  setComposeEmailType = value => {
    this.setState({
      composeEmailType: value,
    });
  };

  onCandidateReject = (candidate, size) => {
    const { setCandidateRejectStatus } = this.props;
    setCandidateRejectStatus(candidate.Id, size);
  };

  getIsCreditInfoPopoverVisible = ({ candidate }) => {
    const { currentUser, featureToggleList } = this.props;
    const { nextOperation } = this.state;
    return _getIsCreditInfoPopoverVisible({
      candidate,
      currentUser,
      featureToggleList,
      operationToBePerformed: nextOperation,
    });
  };

  handleContactPull = ({ candidate }) => {
    const { currentJobDetails, onFetchContact } = this.props;
    const { nextOperation } = this.state;

    const isCreditInfoPopoverVisible = this.getIsCreditInfoPopoverVisible({
      candidate,
    });
    if (isCreditInfoPopoverVisible) {
      this.setState({
        nextOperation: FetchContact,
        nextOperationCandidateInfo: candidate,
        creditInfoVisibility: true,
      });
      return;
    }
    onFetchContact({ candidate, currentJobDetails });
    if (nextOperation) {
      this.setState({
        nextOperation: FetchContact,
      });
    }
  };

  onCandidateStatusChange = (filter, size) => {
    const { jobId, candidateContext, getCurrentSourceConfig, fetchJobSourcingStats, changeCandidateStatus } =
      this.props;
    const { nextOperation } = this.state;

    const { candidate, status, rejectReasons } = filter;
    const isCreditInfoPopoverVisible =
      filter.status.toLowerCase() === 'shortlisted' &&
      this.getIsCreditInfoPopoverVisible({
        candidate,
      });
    if (isCreditInfoPopoverVisible) {
      this.setState({
        nextOperation: CandidateShortlist,
        creditInfoVisibility: true,
        candidateFilter: filter,
        candidateSize: size,
      });
      return;
    }
    const mustHaves = filter?.mustHaves?.split(',') ?? [];
    const sourceConfiguration = getCurrentSourceConfig(candidate);
    const postData = {
      candidateId: candidate.Id,
      metadata: candidate,
      status,
      jobId,
      sourceConfigId: sourceConfiguration?.Id ?? null,
      sourceGroupId: sourceConfiguration?.SourceGroupId ?? null,
      reasons: rejectReasons,
      selectedSubsegmentId: filter?.selectedSubsegmentId,
      mustHaves,
    };
    // const currentStatus = candidate.Status ?? 'Sourced';
    const onCandidateStatusChangeCallback = () => fetchJobSourcingStats([jobId]);
    changeCandidateStatus(postData, onCandidateStatusChangeCallback, true, candidateContext);
    if (status !== 'Shortlisted' && candidateContext === 'job') {
      if (status === 'Rejected' || candidate.Status === 'Rejected') {
        this.onCandidateReject(candidate, size);
      }
    }
    if (nextOperation) {
      this.setState({ nextOperation: null });
    }
  };

  openCandidateView = candidate => {
    const {
      jobId,
      keywordsToHighlight,
      openAryaCandidateView,
      candidateContext,
      mustHavesKeywords,
      manualSearchCriteriaTypeAndRecordId = {},
    } = this.props;
    const { nextOperation } = this.state;
    const isCreditInfoPopoverVisible = this.getIsCreditInfoPopoverVisible({
      candidate,
    });
    if (isCreditInfoPopoverVisible) {
      this.setState({
        nextOperation: OpenCandidateView,
        nextOperationCandidateInfo: candidate,
        creditInfoVisibility: true,
      });
      return;
    }
    const queryParamQuantum = candidate?.RecommendationId || candidate?.RecommendedBy ? 'v=3' : 'src=qs';
    const queryParamAts = candidate?.RecommendationId || candidate?.RecommendedBy ? '3' : 'qs';
    const { criteriaType, recordId } = manualSearchCriteriaTypeAndRecordId;
    const searchCriteriaType = candidate?.RecommendationId ? null : `criteriaType=${criteriaType}`;
    const searchCriteriaRecordId = candidate?.RecommendationId ? null : `recordId=${recordId}`;

    const stringifiedHighlights = queryString.stringify(
      { highlights: keywordsToHighlight ?? [] },
      { arrayFormat: 'comma' }
    );
    const stringifiedMustHaves = queryString.stringify(
      { mustHaves: mustHavesKeywords ?? [] },
      { arrayFormat: 'comma' }
    );
    if (openAryaCandidateView) {
      openAryaCandidateView(
        jobId,
        candidate.Id,
        null,
        stringifiedHighlights?.replace('highlights=', ''),
        queryParamAts,
        stringifiedMustHaves?.replace('mustHaves=', ''),
        searchCriteriaType?.replace('criteriaType=', ''),
        searchCriteriaRecordId?.replace('recordId=', '')
      );
    } else if (candidateContext === 'job') {
      window.open(
        `/jobs/${jobId}/candidates/${candidate.Id}?${stringifiedHighlights}&${stringifiedMustHaves}&${queryParamQuantum}&${searchCriteriaType}&${searchCriteriaRecordId}`
      );
    } else
      window.open(
        `/segments/${jobId}/candidates/${candidate.Id}?${stringifiedHighlights}&${stringifiedMustHaves}&${queryParamQuantum}`
      );
    if (nextOperation) {
      this.setState({ nextOperation: null });
    }
  };

  onClickAtsPush = (atsCandidateId, candidateGuid, resumeName, resumeDate, originalPortal) => {
    const { openAtsView } = this.props;
    if (openAtsView) openAtsView(atsCandidateId, candidateGuid, resumeName, resumeDate, originalPortal);
  };

  onTabClick = key => {
    const { candidate } = this.props;
    this.setState({
      candidateDrawerKey: key,
    });
    logSmartKarrotEvent(getCandidateMetadataActionEventName(key, candidate), {
      Module: modules.candidate.candidateActions,
    });
  };

  closeCandidateDrawer = () => {
    this.setState({
      candidateDrawerVisiblity: false,
    });
  };

  toggleDrawerRenderFlag = () => {
    const { drawerRenderFlag } = this.state;
    this.setState({
      drawerRenderFlag: !drawerRenderFlag,
    });
  };

  unlockResume = candidate => {
    const { jobId, currentJobDetails, unlockCandidateResume } = this.props;
    const jobGuid = currentJobDetails?.JobGuid;
    const jobCountryCode = currentJobDetails?.CountryCode;
    const pushCandidateInfo = { refId: jobGuid, Country: jobCountryCode };
    const candidateSource = getCandidateSource(candidate);
    unlockCandidateResume(
      {
        jobId,
        source: candidateSource,
        candidateId: candidate.Id,
      },
      pushCandidateInfo
    );
  };

  getCandidateRejectedStatusUpdate = candidate => {
    const { candidateStatusUpdate,jobId } = this.props;
    return candidateStatusUpdate?.[candidate.Id]?.[jobId]?.Rejected;
  };

  getCandidateShortlistedStatusUpdate = candidate => {
    const { candidateStatusUpdate,jobId } = this.props;
    return candidateStatusUpdate?.[candidate.Id]?.[jobId]?.Shortlisted;
  };

  onClickNext = () => {
    const { pageSize, currentPage, onPageChange, candidates, activeSourceName } = this.props;
    const { currentCandidateIndex } = this.state;
    const updatePageSize = getUpdatedPageSize(activeSourceName, candidates, pageSize);
    if (currentCandidateIndex < updatePageSize - 1) {
      this.showCandidateDrawer(candidates[currentCandidateIndex + 1], 'profile', currentCandidateIndex + 1);
    } else {
      this.setState({
        nextCandidateIndex: 0,
      });
      onPageChange(currentPage + 1, pageSize);
    }
  };

  onClickPrev = () => {
    const {
      pageSize,
      currentPage,
      onPageChange,
      candidates,
      activeSourceName,
      currentCandidateId,
      getIsCurrentCandidateDuplicate,
    } = this.props;
    const { currentCandidateIndex } = this.state;
    const isCandidateDuplicate = getIsCurrentCandidateDuplicate(currentCandidateId);
    const prevCandidateIndex = getPrevCandidateIndex(activeSourceName, isCandidateDuplicate, currentCandidateIndex);
    const candidateList = candidates;
    if (currentCandidateIndex > 0) {
      this.showCandidateDrawer(candidateList[prevCandidateIndex], 'profile', prevCandidateIndex);
    } else {
      const isDeDuplicationAllowed = getIsDeDuplicationAllowed(activeSourceName);
      if (!isDeDuplicationAllowed) {
        this.setState({
          nextCandidateIndex: pageSize - 1,
        });
        onPageChange(currentPage - 1, pageSize);
      } else {
        const callback = candidatesInTheList => {
          const prevPageLastIndex = getGreatestCandidateIndexFromTheList(candidatesInTheList);
          this.setState({
            nextCandidateIndex: prevPageLastIndex,
          });
        };
        onPageChange(currentPage - 1, pageSize, callback);
      }
    }
  };

  getCandidateDrawerWrapper = () => {
    const {
      jobId,
      pageSize,
      currentPage,
      currentJobDetails,
      featureToggleList,
      candidateAllNotesFetchStatus,
      candidateDetailsStatus,
      downloadResume,
      notesContainer,
      callNotesContainer,
      postAtsCallNotes,
      userConfig,
      version,
      keywordsToHighlight,
      mustHavesKeywords,
      candidateContext,
      candidateListStatus,
      isSegmentCandidateSearchLimitFeatureEnabled,
      candidateCount,
      openSipCallWindowsApp,
      openSegmentAtsView,
      openJobViewInNewTabCallBack,
      onClickAddJobCallBack,
      manualSearchCandidates = {},
      allTabCandidates = [],
      duplicateCandidateIds = [],
      activeSourceName,
    } = this.props;
    const {
      currentCandidateId,
      candidateDrawerVisiblity,
      currentCandidateIndex,
      nextCandidateIndex,
      candidateDrawerKey,
      candidateDrawerSubkey,
      drawerRenderFlag,
      composeEmailType,
    } = this.state;
    const totalCandidate =
      isSegmentCandidateSearchLimitFeatureEnabled && candidateContext === 'segment'
        ? Math.min(candidateCount, this.segmentCandidateSearchMaxLimit)
        : Math.min(candidateCount, 1000);
    const jobGuid = currentJobDetails?.JobGuid;
    const jobCountryCode = currentJobDetails?.CountryCode;
    const currentCandidateIndexInTheList = (currentPage - 1) * pageSize + currentCandidateIndex;
    const disableNext = getIsNextButtonDisabled(
      currentCandidateIndex,
      nextCandidateIndex,
      currentCandidateIndexInTheList,
      totalCandidate,
      manualSearchCandidates,
      allTabCandidates,
      duplicateCandidateIds,
      activeSourceName
    );
    const disablePrev =
      currentCandidateIndex === undefined || nextCandidateIndex !== undefined || currentCandidateIndexInTheList <= 0;
    const isConnectOptionsEnabled = featureToggleList.AdvanceSearchConnectOptions.IsEnabled;
    const candidateAllNotesFetched = candidateAllNotesFetchStatus !== 'INPROGRESS';
    return (
      <CandidateDrawerWrapper
        allowResumeDownload
        onTabClick={this.onTabClick}
        onClose={this.closeCandidateDrawer}
        visible={candidateDrawerVisiblity}
        toggleDrawerRenderFlag={this.toggleDrawerRenderFlag}
        drawerRenderFlag={drawerRenderFlag}
        isQuickSearchCandidate
        candidateId={currentCandidateId}
        jobId={jobId}
        jobGuid={jobGuid}
        activeKey={candidateDrawerKey}
        candidateDetailsStatus={candidateDetailsStatus}
        onCandidateReject={this.onCandidateReject}
        onCandidateStatusChange={this.onCandidateStatusChange}
        downloadResume={downloadResume}
        unlockCandidateResume={this.unlockResume}
        getCandidateRejectedStatusUpdate={this.getCandidateRejectedStatusUpdate}
        getCandidateShortlistedStatusUpdate={this.getCandidateShortlistedStatusUpdate}
        composeEmailType={composeEmailType}
        setComposeEmailType={this.setComposeEmailType}
        showPushCandidateButton={false}
        onClickAtsPush={this.onClickAtsPush}
        notesContainer={notesContainer}
        callNotesContainer={callNotesContainer}
        postAtsCallNotes={postAtsCallNotes}
        onClickNext={this.onClickNext}
        onClickPrev={this.onClickPrev}
        disableNext={disableNext}
        disablePrev={disablePrev}
        aryaVersion={userConfig?.SubscriptionType}
        showPushError={false}
        version={version}
        keywordsToHighlight={keywordsToHighlight}
        mustHavesKeywords={mustHavesKeywords}
        candidateType="quick-search"
        openCandidateView={this.openCandidateView}
        candidateListStatus={candidateListStatus}
        candidateAllNotesFetched={candidateAllNotesFetched}
        subTabKey={candidateDrawerSubkey}
        isConnectOptionsEnabled={isConnectOptionsEnabled}
        showContactInfoButton={isConnectOptionsEnabled}
        isJobActionsAllowed={!!jobId}
        revealActiveChannelSourceName={featureToggleList.RevealPortalsUnderGroup.IsEnabled}
        ignoreSimilar={false}
        jobCountryCode={jobCountryCode}
        currentJobDetails={currentJobDetails}
        candidateContext={candidateContext}
        openSipCallWindowsApp={openSipCallWindowsApp}
        hideRefineIntelBanner
        openSegmentAtsView={openSegmentAtsView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
        isCandidateSet
      />
    );
  };

  onCandidateCheckBoxClick = (event, index) => {
    const {
      currentSelectedAllCandidates,
      bulkActivitySelection,
      setSelectedCandidates,
      setSelectedBulkActivity,
      currentPage,
      activeSource,
      pageSize,
      getCurrentSourceConfig,
      candidateContext,
      bulkCustomActivitySelection,
      setCustomSelectedBulkActivity,
    } = this.props;
    const maxBulkCandidatesSelectionCount = candidateContext === 'segment' ? this.segmentCandidateSearchMaxLimit : 500;
    const isChecked = event.target.checked;
    const candidate = event.target.value;
    const candidateId = candidate.Id;
    const newBulkActivitySelection = _.cloneDeep(bulkActivitySelection);
    const newBulkCustomActivitySelection = _.cloneDeep(bulkCustomActivitySelection);
    const candidateIndex = (currentPage - 1) * pageSize + index;
    const currentSource = activeSource?.Portal ?? activeSource?.Group;
    const currentSourceBulkSelection = newBulkActivitySelection[currentSource] ?? {};
    const currentSourceCustomBulkSelection = newBulkCustomActivitySelection[currentSource];
    const sourceConfiguration = getCurrentSourceConfig(candidate);
    const targetCandidate = {
      CandidateId: candidateId,
      RecommendedInfo: {
        SourceConfigId: sourceConfiguration?.Id ?? null,
        SourceGroupId: sourceConfiguration?.SourceGroupId ?? null,
      },
      source: currentSource,
      index: candidateIndex,
    };
    let _currentSelectedAllCandidates = [...currentSelectedAllCandidates];
    const isCandidateExistsinBulkSelectionRange =
      !_.isEmpty(currentSourceBulkSelection.bulkActivitySearchCriteria) &&
      candidateIndex < maxBulkCandidatesSelectionCount;
    let bulkExcludedCandidates = currentSourceBulkSelection.excludedCandidates ?? [];
    if (!currentSourceCustomBulkSelection) {
      if (isChecked) {
        if (isCandidateExistsinBulkSelectionRange) {
          bulkExcludedCandidates = bulkExcludedCandidates.filter(x => x.CandidateId !== candidateId);
        } else {
          _currentSelectedAllCandidates.push(targetCandidate);
        }
      } else if (isCandidateExistsinBulkSelectionRange) {
        bulkExcludedCandidates = bulkExcludedCandidates.concat(targetCandidate);
      } else {
        _currentSelectedAllCandidates = _currentSelectedAllCandidates.filter(
          selectedCandidate => selectedCandidate?.CandidateId !== candidateId
        );
      }
      if (
        !_.isEmpty(currentSourceBulkSelection?.bulkActivitySearchCriteria) &&
        bulkExcludedCandidates?.length < currentSourceBulkSelection.bulkActivitySearchCriteria.Size
      ) {
        currentSourceBulkSelection.excludedCandidates = bulkExcludedCandidates;
        newBulkActivitySelection[currentSource] = currentSourceBulkSelection;
      } else if (newBulkActivitySelection[currentSource]) {
        delete newBulkActivitySelection[currentSource];
      }
      setSelectedCandidates({ candidateType: 'NonRecommended', selectedCandidates: _currentSelectedAllCandidates });
      setSelectedBulkActivity({ candidateType: 'NonRecommended', bulkActivitySelection: newBulkActivitySelection });
    }
    if (candidateContext === 'segment' && currentSourceCustomBulkSelection) {
      const isCandidateExistsinCustomBulkSelectionRange =
        !_.isEmpty(currentSourceCustomBulkSelection.bulkActivitySearchCriteria) &&
        candidateIndex < maxBulkCandidatesSelectionCount;
      let bulkCustomExcludedCandidates = currentSourceCustomBulkSelection.excludedCandidates ?? [];
      if (isChecked) {
        if (isCandidateExistsinCustomBulkSelectionRange) {
          bulkCustomExcludedCandidates = bulkCustomExcludedCandidates.filter(x => x.CandidateId !== candidateId);
        } else {
          _currentSelectedAllCandidates.push(targetCandidate);
        }
      } else if (isCandidateExistsinCustomBulkSelectionRange) {
        bulkCustomExcludedCandidates = bulkCustomExcludedCandidates.concat(targetCandidate);
      } else {
        _currentSelectedAllCandidates = _currentSelectedAllCandidates.filter(
          selectedCandidate => selectedCandidate?.CandidateId !== candidateId
        );
      }
      if (
        !_.isEmpty(currentSourceCustomBulkSelection?.bulkActivitySearchCriteria) &&
        bulkCustomExcludedCandidates?.length < currentSourceCustomBulkSelection.bulkActivitySearchCriteria.Size
      ) {
        currentSourceCustomBulkSelection.excludedCandidates = bulkCustomExcludedCandidates;
        newBulkCustomActivitySelection[currentSource] = currentSourceCustomBulkSelection;
      } else if (newBulkCustomActivitySelection[currentSource]) {
        delete newBulkCustomActivitySelection[currentSource];
      }
      setCustomSelectedBulkActivity({
        candidateType: 'NonRecommended',
        bulkCustomActivitySelection: newBulkCustomActivitySelection,
      });
    }
  };

  isCandidateChecked = (candidate, index) => {
    const {
      pageSize,
      currentPage,
      currentSelectedAllCandidates,
      activeSource,
      bulkActivitySelection,
      bulkCustomActivitySelection,
    } = this.props;

    if (currentSelectedAllCandidates?.find(selectedCandidate => selectedCandidate.CandidateId === candidate.Id)) {
      return true;
    }

    return isCandidateWithinSearchCriteria(
      candidate,
      index,
      activeSource,
      bulkActivitySelection,
      pageSize,
      currentPage,
      bulkCustomActivitySelection
    );
  };

  setCreditInfoVisibility = visibility => {
    this.setState({
      creditInfoVisibility: visibility,
    });
  };

  onCreditInfoModalCancel = () => {
    this.setCreditInfoVisibility(false);
    this.setState({
      creditInfoVisibility: false,
    });
    this.setState({ nextOperation: null });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      nextOperation,
      creditInfoVisibility,
      nextOperationCandidateInfo,
      candidateDrawerKey,
      currentCandidateIndex,
      candidateDrawerSubkey,
      candidateFilter,
      candidateSize,
      nextCandidateIndex,
    } = this.state;
    const { candidateListStatus, candidates } = this.props;
    if (prevState.nextOperation !== nextOperation || prevState.creditInfoVisibility !== creditInfoVisibility) {
      if (nextOperation && !creditInfoVisibility) {
        if (nextOperation === OpenCandidateDrawer) {
          this.showCandidateDrawer(
            nextOperationCandidateInfo,
            candidateDrawerKey,
            currentCandidateIndex,
            candidateDrawerSubkey
          );
        } else if (nextOperation === FetchContact) {
          this.handleContactPull({ candidate: nextOperationCandidateInfo });
        } else if (nextOperation === CandidateShortlist) {
          this.onCandidateStatusChange(candidateFilter, candidateSize);
        } else if (nextOperation === OpenCandidateView) {
          this.openCandidateView(nextOperationCandidateInfo);
        }
      }
    }

    if (candidateListStatus !== prevProps.candidateListStatus) {
      if (candidateListStatus === 'COMPLETED' && nextCandidateIndex !== undefined) {
        const candidateList = candidates;
        this.showCandidateDrawer(candidateList[nextCandidateIndex], 'profile', nextCandidateIndex);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          nextCandidateIndex: undefined,
        });
      }
    }
  }

  handleAddCandidateSubsegmentModal = (args1, selectedSubsegmentId) => {
    const { addSubsegmentModalStatus, candidates } = this.props;
    const { candidateId } = addSubsegmentModalStatus;
    const candidate = candidates.filter(item => item.Id === candidateId);
    this.onCandidateStatusChange({
      status: 'Shortlisted',
      candidate: candidate[0],
      selectedSubsegmentId,
    });
  };

  render() {
    const {
      version,
      featureToggleList,
      candidateAllNotesFetchStatus,
      whiteLabelInfo,
      currentJobDetails,
      config,
      userConfig,
      jobId,
      source,
      keywordsToHighlight,
      mustHavesKeywords,
      isBulkOptionsEnabled,
      clearAggregationFilter,
      filterQueries,
      updateCandidateBookmarkStatus,
      candidateBookmarkApiStatus,
      candidateListStatus,
      activeSource,
      candidateContext,
      allTabCandidateFetchApiStatus,
      manualSearchCandidateFetchApiStatus,
      candidates,
      pageSize,
      isNonInternalPortalForSegmentEnabled,
      manualSearchCandidates = {},
      allTabCandidates = [],
      duplicateCandidateIds = [],
      activeSourceName,
      setAddSubsegmentModalStatus,
      addSubsegmentModalStatus,
      fetchSubSegmentsAction,
      subsegmentList,
      fetchSubSegmentApiStatus,
      setSubsegmentModalStatus,
      sourcingStats,
      activeTab,
      adminFeatures,
      setCreatedSubSegmentId,
      lastCreatedCandidateId,
    } = this.props;

    const { creditInfoVisibility } = this.state;

    const isLoading =
      candidateListStatus === 'INPROGRESS' ||
      ((candidateContext === 'job' || (candidateContext === 'segment' && isNonInternalPortalForSegmentEnabled)) &&
        getSourceName(activeSource) === 'All' &&
        allTabCandidateFetchApiStatus === 'INPROGRESS');

    const candidateAllNotesFetched =
      candidateAllNotesFetchStatus === 'COMPLETED' || candidateAllNotesFetchStatus === 'FAILED';

    const isCandidateListLoading = isLoading || manualSearchCandidateFetchApiStatus === 'INPROGRESS';
    const currentPageCandidates = candidates.slice(0, pageSize);
    const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList);
    const isAssistPublishEnabled = featureToggleList.AssistedSourcing.Enabled;

    const { visible: addCandidateSubsegmentModalStatus, singleCandidateAdd } = addSubsegmentModalStatus;

    const { shortlistedCount } = getActualSourcingStats(sourcingStats);

    return (
      <div className={styles.manualSearchCandidateList}>
        {this.getCandidateDrawerWrapper()}

        <Skeleton active paragraph={{ rows: 12 }} loading={isCandidateListLoading}>
          {currentPageCandidates.length > 0 ? (
            <CandidateList
              candidates={currentPageCandidates}
              featureToggleList={featureToggleList}
              isCandidateChecked={this.isCandidateChecked}
              showCandidateDetail={this.showCandidateDrawer}
              setComposeEmailType={this.setComposeEmailType}
              onCandidateCheckBoxClick={this.onCandidateCheckBoxClick}
              showCandidateScore={config.IsCandidateScoreVisible}
              showVaultName={config.ShowVaultName}
              onCandidateStatusChange={this.onCandidateStatusChange}
              onCandidateReject={this.onCandidateReject}
              candidateType="quick-search"
              onClickAtsPush={this.onClickAtsPush}
              handleContactPull={this.handleContactPull}
              showConnectWidget={featureToggleList.AdvanceSearchConnectOptions.IsEnabled}
              connectStatusLoaded
              size="quick-search-list"
              ignoreSimilar={false}
              getCandidateRejectedStatusUpdate={this.getCandidateRejectedStatusUpdate}
              getCandidateShortlistedStatusUpdate={this.getCandidateShortlistedStatusUpdate}
              aryaVersion={userConfig?.SubscriptionType}
              version={version}
              showCandidateCheckboxSelection={isBulkOptionsEnabled}
              keywordsToHighlight={keywordsToHighlight}
              mustHavesKeywords={mustHavesKeywords}
              openCandidateView={this.openCandidateView}
              candidateAllNotesFetched={candidateAllNotesFetched}
              whiteLabelInfo={whiteLabelInfo}
              revealActiveChannelSourceName={featureToggleList.RevealPortalsUnderGroup.IsEnabled}
              jobId={jobId}
              currentJobDetails={currentJobDetails}
              showShortlistIconReject
              candidateContext={candidateContext}
              updateCandidateBookmarkStatus={updateCandidateBookmarkStatus}
              candidateBookmarkApiStatus={candidateBookmarkApiStatus}
              nonDuplicateManualSearchCandidates={getSortedManualSearchNonDuplicateCandidates(
                manualSearchCandidates,
                allTabCandidates,
                duplicateCandidateIds,
                activeSourceName
              )}
              setAddSubsegmentModalStatus={setAddSubsegmentModalStatus}
              activeSourceName={activeSourceName}
              activeTab={activeTab}
            />
          ) : (
            <QuickSearchEmptyScreen
              version={version}
              filterQueries={filterQueries}
              clearAggregationFilter={clearAggregationFilter}
              source={source}
            />
          )}
        </Skeleton>
        {addCandidateSubsegmentModalStatus && singleCandidateAdd && (
          <AddCandidateToSegmentPopover
            singleCandidateAdd={singleCandidateAdd}
            visibleStatus={addSubsegmentModalStatus}
            setVisible={setAddSubsegmentModalStatus}
            fetchSubSegments={fetchSubSegmentsAction}
            fetchSubSegmentApiStatus={fetchSubSegmentApiStatus}
            segmentId={jobId}
            handleOnclick={this.handleAddCandidateSubsegmentModal}
            subsegmentList={subsegmentList}
            setSubsegmentModalStatus={setSubsegmentModalStatus}
            shortlistedCount={shortlistedCount}
            setCreatedSubSegmentId={setCreatedSubSegmentId}
            lastCreatedCandidateId={lastCreatedCandidateId}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualSearchCandidateList);
export { ManualSearchCandidateList as ManualSearchCandidateListWithoutStore };
