import React from 'react';
import { Switch } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './NotificationSettingsItem.module.scss';
import reportPortalMessages from '../../../ReportPortal/ReportPortalMessages';

export default function NotificationSettingsItem(props) {
  const { className, title, onChange, isSubscribed, disabled, loading } = props;
  const excludedTitles = ['Alternate candidates are available', 'Reminders to act on alternate candidate list banner'];

  if (excludedTitles.includes(title)) {
    return null;
  }
  return (
    <div className={`${styles.notificationSettingsItem} ${className}`}>
      <div className={styles.title}>{title}</div>
      <div>
        <Switch
          checkedChildren={<FormattedMessage {...reportPortalMessages.notificationSettingItemOn} />}
          unCheckedChildren={<FormattedMessage {...reportPortalMessages.notificationSettingItemOn} />}
          onChange={onChange}
          checked={isSubscribed}
          loading={loading}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
