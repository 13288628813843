import { defineMessages } from 'react-intl';

import candidateFiltersMessagesJsonEn from '../../Data/En_Us/CandidateFiltersMessagesEn_Us.json';
import candidateFiltersMessagesJsonEs from '../../Data/Es_Es/CandidateFiltersMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'CandidateFilter';
const transformedMessage = formatMessagesWithComponentPrefix(candidateFiltersMessagesJsonEn, component);
const candidateFilterMessages = defineMessages(transformedMessage);

const CandidateFiltersMessage = defineMessages(candidateFilterMessages);
export const candidateFiltersMessagesEn = formatJsonWithComponentPrefix(candidateFiltersMessagesJsonEn, component);
export const candidateFiltersMessagesEs = formatJsonWithComponentPrefix(candidateFiltersMessagesJsonEs, component);

export default CandidateFiltersMessage;
