/* eslint-disable react/no-did-update-set-state */
/**
 * @author Mouli Kalakota
 * @email mouli.kalakota@leoforce.com
 * @create date 2019-03-19 11:34:48
 * @modify date 2019-03-19 11:34:48
 * @desc Select component for Clients
 */

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import placeholder from '../Placeholders/PlaceholdersMessages';

const { Option } = Select;

class ClientSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.isInitialClientIdSet = false;
    this.fetchClients = debounce(this.fetchClients, 600);
    this.handleChange = this.handleChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.fetchClientsInstantly = debounce(this.fetchClientsInstantly, 150);
  }

  componentDidMount() {
    const { selectedClientId, initialClientName, onChange } = this.props;
    if (selectedClientId) {
      this.setState({
        selectedClientId,
        selectedClientName: initialClientName,
      });
      onChange(selectedClientId);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedClientId: previousSelectedClientId } = prevProps;
    const {
      selectedClientId: currentSelectedClientId,
      initialClientName: currentSelectedClientName,
      onChange,
    } = this.props;
    if (previousSelectedClientId !== currentSelectedClientId) {
      this.setState({
        selectedClientId: currentSelectedClientId,
        selectedClientName: currentSelectedClientName,
      });
      onChange(currentSelectedClientId);
    }
  }

  fetchClients = filter => {
    const { fetchClients } = this.props;
    this.setState({
      SearchTerm: filter.SearchTerm,
    });
    fetchClients({ ...filter, IsActive: true });
  };

  fetchClientsInstantly = filter => {
    const { fetchClients } = this.props;
    this.setState({
      SearchTerm: filter.SearchTerm,
    });
    fetchClients({ ...filter, IsActive: true });
  };

  handleChange = (value, option) => {
    const selectedClientId = option && option.key ? parseInt(option.key, 10) : null;
    const selectedClientName = option ? option.props.children : null;
    this.setState({
      selectedClientId,
      selectedClientName,
    });

    if (!value) {
      this.fetchClients({ SearchTerm: value, From: 0 });
      this.setState({
        selectedClientId: undefined,
        selectedClientName: undefined,
      });
    }
    const { onChange } = this.props;
    if (onChange) {
      onChange(selectedClientId);
    }
  };

  handleScroll(e) {
    const { SearchTerm } = this.state;
    const { clients, clientTotal } = this.props;
    e.persist();
    // if (e.target.scrollTop + e.target.offsetHeight >= 0.5 * e.target.scrollHeight && clients.length < clientTotal) {
    if (e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 250 && clients.length < clientTotal) {
      // scrollToEnd, do something!!!
      this.fetchClientsInstantly({ SearchTerm, From: clients.length });
    }
  }

  render() {
    const { isLoading, clients, id, clientAddition, intl } = this.props;
    const { selectedClientId, selectedClientName } = this.state;
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        <Select
          style={{ width: '250px' }}
          id={id}
          value={selectedClientId ? selectedClientName : undefined}
          showSearch
          allowClear
          placeholder={intl.formatMessage({ ...placeholder.selectClientLabel })}
          notFoundContent={isLoading ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={value => this.fetchClients({ SearchTerm: value, From: 0 })}
          onChange={this.handleChange}
          onPopupScroll={this.handleScroll}
          onDropdownVisibleChange={visible => {
            if (visible === false) {
              this.fetchClients({ From: 0 });
            }
          }}
        >
          {selectedClientId && selectedClientName ? <Option key={selectedClientId}>{selectedClientName}</Option> : null}
          {clients.map(client =>
            client.Id !== selectedClientId ? <Option key={client.Id}>{client.Name}</Option> : null
          )}
        </Select>
        {clientAddition}
      </div>
    );
  }
}

export default injectIntl(ClientSelect);
export { ClientSelect as ClientSelectWithoutInjectedIntl };
