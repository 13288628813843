import PropTypes from 'prop-types';
import _ from 'lodash';
import cleanSet from 'clean-set';
import { createSelector } from 'reselect';
import deepEqual from 'fast-deep-equal';
import { klona as cloneDeep } from 'klona';
import shallowEqual from 'shallowequal';
import { getCandidateConnectStatus } from './ConnectReducer';
import { getCurrentUser } from './UserSessionReducer';
import { getConfig } from './ConfigReducer';
import {
  getCurrentPageDefaultSize as getCurrentPageDefaultSizeUtil,
  getSupportedPageSizes as getSupportedPageSizesUtil,
} from '../Utils/CandidatePaginationUtils';

import {
  SET_CANDIDATES,
  UPDATE_UNLOCKED_CANDIDATE,
  SET_CALL_NOTES,
  SET_CALL_NOTES_COUNT,
  SET_CANDIDATE_NOTES,
  SET_CANDIDATE_NOTES_COUNT,
  SET_CANDIDATE_PERSONALITY,
  SET_CANDIDATE_PERSONALITY_STATUS,
  SET_CANDIDATE_REJECT_FLAG,
  SET_CANDIDATE_ALL_DETAILS,
  SET_CANDIDATE_CONNECT_IDS,
  SET_BULK_FETCH_CONTACT_STATUS,
  SET_CANDIDATE_COUNT,
  SET_DILATED_CANDIDATE_COUNT,
  SET_CANDIDATE_ALL_DETAILS_ERROR,
  SET_CANDIDATE_LIST_ERROR,
  SET_BULK_CANDIDATE_NOTES,
  SET_BULK_CALL_NOTES_USING_CONVERSATION_ID,
  SET_BULK_CALL_NOTES_USING_CANDIDATE_ID,
  SET_CANDIDATES_AGGREGATIONS,
  SET_SOURCING_NOTIFICATION,
  SET_RECOMMENDED_STATUS_CHANGED_NOTIFICATION,
  SET_CANDIDATE_INTEL,
  SET_CANDIDATE_CONNECTION_STATUS_USING_CANDIDATE_ID,
  FETCH_CANDIDATE_SPECIFICATIONS,
  SET_FETCH_TRY_NOW_CANDIDATE_DETAILS,
  SET_CANDIDATE_LIST_TYPE,
  SET_BULK_SHORTLISTED_STATUS,
  SET_CANDIDATES_PUBLISHED_NOTIFICATION,
  SET_CANDIDATE_FAVOURITE_STATUS,
  SET_CANDIDATE_JOB_GLOBAL_TAGS,
  SET_CANDIDATE_TAGS,
  CREATE_CANDIDATE_TAG,
  DELETE_CANDIDATE_TAG,
  CREATE_CANDIDATE_JOB_GLOBAL_TAG,
  DELETE_CANDIDATE_JOB_GLOBAL_TAG,
  SET_CANDIDATE_ACTIVITY_LOGS,
  CLEAR_CANDIDATE_ACTIVITY_LOGS,
  SET_CANDIDATE_MATCHING_JOBS,
  SET_CANDIDATE_RECOMMENDATION_SOURCE,
  SET_CANDIDATE_ID,
  SET_IS_QUICKSEARCH_CANDIDATE,
  SET_FILTER_CONTEXT_ID,
  RESET_CANDIDATE_JOBS,
  SET_BULK_CANDIDATE_VIEW_STATUS,
  SET_CANDIDATE_JOB_MATCHING_LIST_SEARCH_TERM,
  SET_CANDIDATE_JOB_MATCHING_FILTERS,
  SET_SAMPLE_CANDIDATE_COUNT,
  SET_SCORING_STATUS_FOR_APPLIED_BUCKET,
} from '../Actions/ActionCreators/CandidateActions';
import {
  CREATE_CANDIDATE_NOTE,
  DELETE_CANDIDATE_NOTE,
  UPDATE_CANDIDATE_NOTE,
  SET_CANDIDATE_NOTES_V2,
  ADD_CANDIDATE_NOTE_TAGS,
  DELETE_CANDIDATE_NOTE_TAG,
} from '../Actions/ActionCreators/NoteActionCreator';
import {
  SET_CANDIDATE_SUGGESTED_TAGS,
  CLEAR_CANDIDATE_SUGGESTED_TAGS,
} from '../Actions/ActionCreators/TagActionCreator';
import { getIsDeDuplicationAllowed } from '../Utils/DeDuplicationUtils';
import { allowedDeDuplicationSources } from '../Utils/CandidateListUtils';

const emptyList = [];
const emptyObject = {};

const initialState = {
  ById: {},
  IsDownloaded: {},
  ResumesSaved: [],
  Candidate: {
    Jobs: [],
    ListsAndCampaigns: [],
    JobsCount: 0,
    ListsAndCampaignsCount: 0,
    JobAggregations: {},
    ListsAndCampaignAggregations: {},
    MatchingJobs: [],
    TotalMatchingJobsCount: 0,
    MatchingJobsAggregation: [],
  },
  RecommendationSource: undefined,
  mspIcons: {},
};

function resumeCleaner(state, candidateId) {
  const newState = cloneDeep(state);
  if (_.get(newState, ['ById', candidateId, 'Resumes', 'length'], 0) > 0) {
    newState.ResumesSaved.unshift([candidateId]);
    newState.ResumesSaved = _.uniqWith(newState.ResumesSaved, _.isEqual);
  }

  while (_.get(newState, ['ResumesSaved', 'length'], 0) > 5) {
    const resumeToRemove = newState.ResumesSaved.pop();
    _.set(newState, [resumeToRemove[0], 'Resumes'], null);
  }

  return newState;
}

function candidateMergeCustomizer(objValue, srcValue, key) {
  if (key === 'Sources') {
    const objValuePrimarySource = (objValue || []).find(
      source => _.get(source, 'Type', '').toLowerCase() === 'primary'
    );
    const srcValuePrimarySource = (srcValue || []).find(
      source => _.get(source, 'Type', '').toLowerCase() === 'primary'
    );
    const filteredObjValues = (objValue || []).filter(source => _.get(source, 'Type', '').toLowerCase() !== 'primary');
    const filteredSrcValues = (srcValue || []).filter(source => _.get(source, 'Type', '').toLowerCase() !== 'primary');
    const sources = filteredObjValues.concat(filteredSrcValues);
    sources.push({
      ...objValuePrimarySource,
      ...srcValuePrimarySource,
    });
    return _.uniqBy(sources, s => {
      return JSON.stringify(_.pick(s, ['Portal', 'CandidateId', 'Group', 'Type', 'Name']));
    });
  }
  if (key === 'Experiences') {
    return srcValue?.length ? srcValue : objValue;
  }
  if (key === 'PersonId') {
    return objValue || srcValue;
  }
  return undefined;
}

function CandidateReducer(state = initialState, action) {
  let newState;
  let candidateId;
  let candidateIds;
  let candidateNotes;
  let candidateNotesCount;
  let conversationIds;
  let candidateIdsByConversationId;
  let callNotes;
  let callNotesCount;
  let candidates;
  let candidate;
  let existingCandidate;
  let details;
  let candidateConnectIds;
  let notes;
  let fetchContactStatus;
  let personalityStatus;
  let Personality;
  let status;
  let intel;
  let extraTags;
  let newTags;
  let newCount;
  let noteIds;
  let index;
  let isFavourite;
  let globalTags;
  let globalTagsCount;
  let isQuickSearchCandidate;
  let updatedCandidateDetails;
  let candidateIntelV2;
  let newIntel;
  switch (action.type) {
    case SET_CANDIDATES: {
      ({ candidates } = action.payload);
      let candidatesById = {};
      const candidatesDownloadedStatus = { ...state.IsDownloaded };
      candidates.forEach(c => {
        if (c.Id) {
          candidatesById = cleanSet(candidatesById, [c.Id], c);
        }
        candidatesDownloadedStatus[c.Id] = c.IsDownloaded;
      });
      return {
        ...state,
        ById: candidatesById,
        IsDownloaded: candidatesDownloadedStatus,
      };
    }

    case SET_CANDIDATE_RECOMMENDATION_SOURCE:
      newState = cleanSet(state, 'RecommendationSource', action.payload);
      return newState;

    case SET_FILTER_CONTEXT_ID:
      newState = cleanSet(state, 'FilterContextId', action.payload);
      return newState;

    case SET_FETCH_TRY_NOW_CANDIDATE_DETAILS:
      newState = cleanSet(state, ['ById', action.payload.Id], action.payload);
      return newState;

    case UPDATE_UNLOCKED_CANDIDATE:
      ({ candidate } = action.payload);
      candidateId = candidate.Id;
      existingCandidate = _.get(state, ['ById', candidateId], {});
      updatedCandidateDetails = {
        ...existingCandidate,
        ...candidate,
      };
      newState = cleanSet(state, ['ById', candidateId], updatedCandidateDetails);
      return newState;

    case SET_CANDIDATE_ALL_DETAILS:
      ({ details } = action.payload);
      candidateId = details.Id;
      existingCandidate = cloneDeep(_.get(state, ['ById', candidateId], {}));
      candidate = _.mergeWith(
        existingCandidate,
        {
          ...details,
        },
        candidateMergeCustomizer
      );
      newState = cleanSet(state, ['ById', candidateId], candidate);
      return newState;
    case SET_CANDIDATE_ID:
      ({ candidateId } = action.payload);
      if (!_.get(state, ['ById', candidateId])) {
        newState = cleanSet(state, ['ById', candidateId], { Id: candidateId }, Object);
        return newState;
      }
      return state;

    case SET_IS_QUICKSEARCH_CANDIDATE:
      ({ candidateId, isQuickSearchCandidate } = action.payload);
      if (_.get(state, ['ById', candidateId])) {
        newState = cleanSet(state, ['ById', candidateId, 'IsQuickSearchCandidate'], isQuickSearchCandidate);
        return newState;
      }
      return state;

    case SET_CANDIDATE_ALL_DETAILS_ERROR:
      ({ candidateId } = action.payload);
      if (_.get(state, ['ById', candidateId])) {
        newState = cloneDeep(state);
        existingCandidate = _.get(newState, ['ById', candidateId], {});
        candidate = _.merge(existingCandidate, {
          ...action.payload,
        });
        _.setWith(newState, ['ById', candidateId], candidate, Object);
        return newState;
      }
      return state;
    case SET_CANDIDATE_CONNECT_IDS:
      newState = { ...state };
      ({ candidateConnectIds } = action.payload);
      Object.keys(candidateConnectIds).forEach(candId => {
        if (_.has(_.get(state, ['ById']), candId)) {
          newState = cleanSet(newState, ['ById', candId, 'PersonId'], candidateConnectIds[candId].PersonId);
          if (_.get(candidateConnectIds, [candId, 'ConversationId'])) {
            newState = cleanSet(
              newState,
              ['ById', candId, 'ConversationId'],
              candidateConnectIds[candId].ConversationId
            );
          }
        }
      });
      return newState;

    case SET_CANDIDATE_NOTES:
      ({ candidateId, notes } = action.payload);
      newState = cleanSet(state, ['ById', candidateId, 'Notes'], _.sortBy(notes, [note => note.CreatedDate]));
      return newState;

    case SET_CANDIDATE_NOTES_COUNT:
      candidateId = _.get(action, ['payload', 'candidateId'], '').toString();
      if (_.get(state, ['ById', candidateId])) {
        newState = cleanSet(
          state,
          ['ById', candidateId, 'CandidateNotesCount'],
          _.get(action, ['payload', 'candidateNotesCount'], 0)
        );
        return newState;
      }
      return state;

    case SET_BULK_CANDIDATE_NOTES:
      newState = { ...state };
      candidateIds = _.get(action, ['payload', 'candidateIds'], []);
      candidateIds.forEach(Id => {
        if (_.get(state, ['ById', Id])) {
          candidateNotes = _.get(action, ['payload', 'notesByCandidateId', Id, 'Notes'], []);
          candidateNotesCount = _.get(action, ['payload', 'notesByCandidateId', Id, 'Total'], 0);
          if (candidateNotes.length) {
            newState = cleanSet(newState, ['ById', Id, 'Notes'], _.sortBy(candidateNotes, [note => note.CreatedDate]));
          }
          newState = cleanSet(newState, ['ById', Id, 'CandidateNotesCount'], candidateNotesCount);
        }
      });
      return newState;

    case 'SET_CANDIDATES_NOTES_FETCH_API_STATUS':
      newState = { ...state };
      candidateIds = _.get(action, ['payload', 'candidateIds'], []);
      status = _.get(action, ['payload', 'status'], null);
      candidateIds.forEach(Id => {
        if (_.get(newState, ['ById', Id]))
          newState = cleanSet(newState, ['ById', Id, 'CandidateNotesApiStatus'], status);
      });
      return newState;

    case SET_CALL_NOTES:
      ({ candidateId, notes } = action.payload);
      if (_.get(state, ['ById', candidateId])) {
        newState = cleanSet(state, ['ById', candidateId, 'CallNotes'], notes);
        return newState;
      }
      return state;

    case SET_CALL_NOTES_COUNT:
      candidateId = _.get(action, ['payload', 'candidateId'], '').toString();
      if (_.get(state, ['ById', candidateId])) {
        newState = cleanSet(
          state,
          ['ById', candidateId, 'CallNotesCount'],
          _.get(action, ['payload', 'callNotesCount'], 0)
        );
        return newState;
      }
      return state;

    case SET_CANDIDATE_ACTIVITY_LOGS: {
      const existingLogs = _.get(state, ['ById', action.payload.candidateId, 'ActivityLogs'], []);
      const updatedLogs = [...existingLogs, ...action.payload.activityLogs.Activity];
      candidate = _.get(state, ['ById', action.payload.candidateId]);
      const candidateWithNewActivityLogs = {
        ...candidate,
        ActivityLogs: updatedLogs,
        ActivityLogsCount: action.payload.activityLogs.TotalCount,
      };
      newState = cleanSet(state, ['ById', action.payload.candidateId], candidateWithNewActivityLogs);
      return newState;
    }

    case CLEAR_CANDIDATE_ACTIVITY_LOGS: {
      candidate = _.get(state, ['ById', action.payload.candidateId]);
      const candidateWithNewActivityLogs = {
        ...candidate,
        ActivityLogs: undefined,
        ActivityLogsCount: undefined,
      };
      newState = cleanSet(state, ['ById', action.payload.candidateId], candidateWithNewActivityLogs);
      return newState;
    }
    case SET_BULK_CALL_NOTES_USING_CONVERSATION_ID:
      newState = { ...state };
      conversationIds = _.get(action, ['payload', 'conversationIds'], []);
      candidateIdsByConversationId = _.get(action, ['payload', 'candidateIdsByConversationId'], {});
      conversationIds.forEach(conversationId => {
        candidateId = _.get(candidateIdsByConversationId, conversationId, '');
        if (_.get(newState, ['ById', candidateId])) {
          callNotes = _.get(action, ['payload', 'notesByConversationId', conversationId, 'Notes'], []);
          callNotesCount = _.get(action, ['payload', 'notesByConversationId', conversationId, 'Total'], 0);
          if (callNotes.length) {
            newState = cleanSet(newState, ['ById', candidateId, 'CallNotes'], callNotes);
          }
          newState = cleanSet(newState, ['ById', candidateId, 'CallNotesCount'], callNotesCount);
        }
      });
      return newState;

    case SET_BULK_CALL_NOTES_USING_CANDIDATE_ID: {
      ({ candidateIds } = action.payload);
      let candidatesByIds = { ...state.ById };
      candidateIds.forEach(Id => {
        if (_.get(state, ['ById', Id])) {
          candidate = state.ById[Id];
          callNotes = _.get(action, ['payload', 'notesByCandidateId', Id, 'Notes'], []);
          callNotesCount = _.get(action, ['payload', 'notesByCandidateId', Id, 'Total'], 0);
          const newCandidate = { ...candidate, CallNotesCount: callNotesCount };
          if (callNotes.length) {
            newCandidate.CallNotes = callNotes;
          }
          candidatesByIds = cleanSet(candidatesByIds, [Id], newCandidate);
        }
      });
      newState = cleanSet(state, 'ById', candidatesByIds);
      return newState;
    }

    case 'SET_CANDIDATES_CALL_NOTES_FETCH_API_STATUS': {
      conversationIds = _.get(action, ['payload', 'conversationIds'], []);
      candidateIdsByConversationId = _.get(action, ['payload', 'candidateIdsByConversationId'], {});
      status = _.get(action, ['payload', 'status'], null);
      let candidatesByIds = { ...state.ById };
      conversationIds.forEach(conversationId => {
        candidateId = _.get(candidateIdsByConversationId, conversationId, '');
        if (_.get(state, ['ById', candidateId]))
          candidatesByIds = cleanSet(candidatesByIds, [candidateId, 'CallNotesApiStatus'], status);
      });
      return {
        ...state,
        ById: candidatesByIds,
      };
    }

    case SET_BULK_FETCH_CONTACT_STATUS: {
      ({ candidateIds, fetchContactStatus } = action.payload);
      let candidatesByIds = { ...state.ById };
      candidateIds.forEach(CandidateId => {
        if (_.get(state, ['ById', CandidateId]))
          candidatesByIds = cleanSet(candidatesByIds, [CandidateId, 'fetchContactStatus'], fetchContactStatus);
      });
      return {
        ...state,
        ById: candidatesByIds,
      };
    }

    case SET_CANDIDATE_SUGGESTED_TAGS:
      extraTags = _.get(action, ['payload', 'Tags'], []);
      newCount = _.get(action, ['payload', 'Total'], 0);
      newTags = _.get(state, ['utilities', 'tags', 'Tags'], []).concat(extraTags);
      newState = cleanSet(state, ['utilities', 'tags'], {
        Tags: _.uniqBy(newTags, 'Id'),
        TagsCount: newCount,
      });
      return newState;

    case CLEAR_CANDIDATE_SUGGESTED_TAGS:
      newState = cleanSet(state, ['utilities', 'tags'], {
        Tags: [],
        TagsCount: undefined,
      });
      return newState;

    case SET_CANDIDATE_NOTES_V2: {
      newState = { ...state };
      noteIds = action.payload.notes.map(note => note.Id);
      existingCandidate = _.get(newState, ['ById', action.payload.candidateId], {});
      const notesById = {};
      if (_.get(newState, ['ById', action.payload.candidateId])) {
        for (index = 0; index < noteIds.length; index += 1) {
          const id = noteIds[index];
          notesById[id] = action.payload.notes[index];
        }
        const candidateNotesById = { NotesById: notesById };
        candidate = _.merge(existingCandidate, candidateNotesById);
        _.setWith(newState, ['ById', action.payload.candidateId], candidate, Object);
      }
      return newState;
    }

    case ADD_CANDIDATE_NOTE_TAGS:
      newState = cleanSet(
        state,
        ['ById', action.payload.candidateId, 'NotesById', action.payload.noteId, 'Tags'],
        [
          ..._.get(state, ['ById', action.payload.candidateId, 'NotesById', action.payload.noteId, 'Tags'], []),
          ...action.payload.tags,
        ]
      );
      return newState;

    case DELETE_CANDIDATE_NOTE_TAG:
      newState = cloneDeep(state);
      _.remove(_.get(newState, ['ById', action.payload.candidateId, 'NotesById', action.payload.noteId, 'Tags'], []), {
        AssociationId: action.payload.tagAssociationId,
      });
      return newState;

    case CREATE_CANDIDATE_NOTE:
    case UPDATE_CANDIDATE_NOTE:
      newState = cleanSet(
        state,
        ['ById', action.payload.candidateId, 'NotesById', action.payload.note.Id],
        action.payload.note
      );
      return newState;

    case DELETE_CANDIDATE_NOTE:
      newState = cleanSet(state, ['ById', action.payload.candidateId, 'NotesById', action.payload.noteId], null);
      _.unset(newState, ['ById', action.payload.candidateId, 'NotesById', action.payload.noteId]);
      return newState;

    case SET_CANDIDATE_JOB_GLOBAL_TAGS:
      newState = { ...state };
      action.payload.candidateIds.forEach(Id => {
        if (_.get(state, ['ById', Id])) {
          globalTags = _.get(action, ['payload', 'tags', Id, 'CandidateTags'], []);
          globalTagsCount = _.get(action, ['payload', 'tags', Id, 'Total']);
          candidate = _.get(state, ['ById', Id], {});
          const newCandidate = {
            ...candidate,
            CandidateJobGlobalTags: globalTags,
            CandidateJobGlobalTagsCount: globalTagsCount,
          };
          newState = cleanSet(newState, ['ById', Id], newCandidate);
        }
      });
      return newState;

    case CREATE_CANDIDATE_JOB_GLOBAL_TAG:
      newState = { ...state };
      newState = cleanSet(
        newState,
        ['ById', action.payload.candidateId, 'CandidateJobGlobalTags'],
        [..._.get(newState, ['ById', action.payload.candidateId, 'CandidateJobGlobalTags'], []), ...action.payload.tags]
      );
      newState = cleanSet(
        newState,
        ['ById', action.payload.candidateId, 'CandidateJobGlobalTagsCount'],
        _.get(newState, ['ById', action.payload.candidateId, 'CandidateJobGlobalTagsCount'], 0) +
          action.payload.tags.length
      );
      return newState;

    case DELETE_CANDIDATE_JOB_GLOBAL_TAG:
      newState = cloneDeep(state);
      _.remove(_.get(newState, ['ById', action.payload.candidateId, 'CandidateJobGlobalTags'], []), {
        AssociationId: action.payload.tagAssociationId,
      });
      return newState;

    case SET_CANDIDATE_TAGS: {
      let candidatesByIds = { ...state.ById };
      action.payload.candidateIds.forEach(id => {
        if (_.get(state, ['ById', id])) {
          candidatesByIds = {
            ...candidatesByIds,
            [id]: {
              ...candidatesByIds[id],
              CandidateGlobalTags: action.payload.tags?.[id]?.CandidateTags,
              CandidateGlobalTagsCount: action.payload.tags?.[id]?.Total,
            },
          };
        }
      });

      return {
        ...state,
        ById: candidatesByIds,
      };
    }

    case CREATE_CANDIDATE_TAG:
      newState = { ...state };
      newState = cleanSet(
        newState,
        ['ById', action.payload.candidateId, 'CandidateGlobalTags'],
        [..._.get(newState, ['ById', action.payload.candidateId, 'CandidateGlobalTags'], []), ...action.payload.tags]
      );
      newState = cleanSet(
        newState,
        ['ById', action.payload.candidateId, 'CandidateGlobalTagsCount'],
        _.get(newState, ['ById', action.payload.candidateId, 'CandidateGlobalTagsCount'], 0) +
          action.payload.tags.length
      );
      return newState;

    case DELETE_CANDIDATE_TAG:
      newState = cloneDeep(state);
      _.remove(_.get(newState, ['ById', action.payload.candidateId, 'CandidateGlobalTags'], []), {
        AssociationId: action.payload.tagAssociationId,
      });
      return newState;

    case SET_CANDIDATE_REJECT_FLAG:
      ({ candidateId } = action.payload);
      if (action.payload.size === 'medium' || action.payload.size === 'small') {
        newState = cleanSet(
          state,
          ['ById', candidateId, 'drawerRejectFlag'],
          !_.get(state, ['ById', candidateId, 'drawerRejectFlag'])
        );
      } else {
        newState = cleanSet(
          state,
          ['ById', candidateId, 'rejectFlag'],
          !_.get(state, ['ById', candidateId, 'rejectFlag'], false)
        );
      }
      return newState;

    case SET_CANDIDATE_PERSONALITY_STATUS:
      ({ candidateId, personalityStatus } = action.payload);
      newState = cleanSet(state, ['ById', candidateId, 'PersonalityStatus'], personalityStatus);
      return newState;

    case SET_CANDIDATE_PERSONALITY:
      ({ candidateId, Personality } = action.payload);
      newState = cleanSet(state, ['ById', candidateId, 'Personality'], Personality);
      newState = cleanSet(newState, ['ById', candidateId, 'PersonalityStatus'], 'PersonalityFound');
      return newState;

    case SET_CANDIDATE_FAVOURITE_STATUS:
      ({ candidateId, isFavourite } = action.payload);
      if (_.get(state, ['ById', candidateId])) {
        return cleanSet(state, ['ById', candidateId, 'IsFavourite'], isFavourite);
      }
      return state;

    case SET_CANDIDATE_COUNT:
      return {
        ...state,
        CandidateCount: action.payload.count,
      };

    case SET_SAMPLE_CANDIDATE_COUNT:
      return {
        ...state,
        SampleCandidateCount: action.payload.count,
      };

    case SET_DILATED_CANDIDATE_COUNT:
      return {
        ...state,
        DilatedCandidateCount: action.payload.count,
      };

    case SET_CANDIDATE_LIST_ERROR:
      return {
        ...state,
        candidateListError: action.payload.error,
      };

    case FETCH_CANDIDATE_SPECIFICATIONS:
      return {
        ...state,
        candidateSpecifications: action.payload,
      };

    case 'SET_CURRENT_CANDIDATE_ID':
      return {
        ...state,
        CurrentCandidateId: action.payload,
      };

    case 'SET_FETCH_CANDIDATES_FLAG':
      return {
        ...state,
        FetchCandidatesFlag: action.payload,
      };

    case 'SET_CANDIDATE_CONNECT_DETAILS':
      return {
        ...state,
        ConnectDetails: action.payload.connectDetails,
      };

    case 'SET_REJECTED_CANDIDATE_IDS':
      return {
        ...state,
        RejectedCandidateIds: action.payload.rejectedCandidateIds,
      };

    case SET_CANDIDATE_CONNECTION_STATUS_USING_CANDIDATE_ID:
      ({ candidateId, status } = action.payload);
      if (_.get(state, ['ById', candidateId])) {
        return cleanSet(state, ['ById', candidateId, 'ConnectionStatus'], status);
      }
      return state;

    case SET_CANDIDATES_AGGREGATIONS:
      return {
        ...state,
        Aggregations: action.payload,
      };

    case SET_SOURCING_NOTIFICATION:
      return {
        ...state,
        SourcingNotification: {
          jobId: action.payload.jobId,
          // TODO: Remove Date.now() as it violates pure function
          time: Date.now(),
        },
      };

    case SET_CANDIDATE_INTEL:
      ({ candidateId, intel } = action.payload);
      newIntel = { ...intel };
      if (intel?.CandidateIntelV1) {
        newIntel.CandidateIntelV1 = JSON.parse(intel.CandidateIntelV1);
      }
      if (intel?.CandidateIntelV2) {
        newIntel.CandidateIntelV2 = JSON.parse(intel.CandidateIntelV2);
      }
      newState = cleanSet(state, ['ById', candidateId, 'Intel'], newIntel, Object);
      return newState;

    case SET_RECOMMENDED_STATUS_CHANGED_NOTIFICATION:
      return {
        ...state,
        RecommendedStatusChangedNotification: {
          jobId: action.payload.jobId,
          // TODO: Remove Date.now() as it violates pure function
          time: Date.now(),
        },
      };

    case SET_CANDIDATE_LIST_TYPE:
      return {
        ...state,
        CandidateListType: action.payload,
      };

    case SET_BULK_SHORTLISTED_STATUS:
      return {
        ...state,
        BulkShortlistedNotification: {
          jobId: action.payload.jobId,
          // TODO: Remove Date.now() as it violates pure function
          time: Date.now(),
        },
      };

    case SET_CANDIDATES_PUBLISHED_NOTIFICATION:
      return {
        ...state,
        CandidatesPublishedNotification: {
          jobId: action.payload.jobId,
          // TODO: Remove Date.now() as it violates pure function
          time: Date.now(),
        },
      };

    case 'SET_LATEST_CANDIDATE_SEARCH_SUCCESS_PAGE': {
      const { jobId, source } = action.payload;
      newState = cleanSet(
        state,
        ['ByJobId', jobId, 'BySource', source, 'PreviousSuccessfulCandidateFetchedPage'],
        action.payload?.page
      );
      return newState;
    }

    case 'SET_CANDIDATE_JOBS': {
      const selectedCandidate = state.Candidate;
      const newSelectedCandidate = {
        ...selectedCandidate,
        Jobs: action.payload.CandidateJobStatuses,
        JobsCount: action.payload.TotalCount,
        JobAggregations: action.payload.Aggregations,
      };
      return {
        ...state,
        Candidate: newSelectedCandidate,
      };
    }

    case RESET_CANDIDATE_JOBS: {
      return {
        ...state,
        Candidate: {},
      };
    }

    case SET_CANDIDATE_MATCHING_JOBS: {
      const matchingJobs = state.Candidate;
      const newMatchingJobs = {
        ...matchingJobs,
        MatchingJobs: action.payload.Jobs,
        MatchingJobHighlights: action.payload.Highlights,
        MatchingJobCandidateDetails: action.payload.CandidateJobDetails,
        TotalMatchingJobsCount: action.payload.TotalCount,
        MatchingJobsAggregation: action.payload.Aggregations,
      };
      return {
        ...state,
        Candidate: newMatchingJobs,
      };
    }

    case 'APPEND_CANDIDATE_JOBS':
      newState = cleanSet(state, 'Candidate.Jobs', [...state.Candidate.Jobs, ...action.payload.CandidateJobStatuses]);
      return newState;

    case 'SET_CANDIDATE_LISTS_AND_CAMPAIGNS': {
      const selectedCandidate = state.Candidate;
      const newSelectedCandidate = {
        ...selectedCandidate,
        ListsAndCampaigns: action.payload.CandidateJobStatuses,
        ListsAndCampaignsCount: action.payload.TotalCount,
        ListsAndCampaignAggregations: action.payload.Aggregations,
      };
      return {
        ...state,
        Candidate: newSelectedCandidate,
      };
    }

    case 'APPEND_CANDIDATE_LISTS_AND_CAMPAIGNS':
      newState = cleanSet(state, 'Candidate.ListsAndCampaigns', [
        ...state.Candidate.ListsAndCampaigns,
        ...action.payload.CandidateJobStatuses,
      ]);
      return newState;
    case SET_BULK_CANDIDATE_VIEW_STATUS:
      newState = { ...state };
      candidateIds = _.get(action, ['payload', 'candidateIds'], []);
      candidateIds.forEach(Id => {
        if (_.get(state, ['ById', Id])) {
          const newSelectedCandidate = {
            ...state.ById[Id],
            IsCandidateViewed: action.payload.viewStatusByCandidateId[Id].IsViewed,
            LastViewedDate: action.payload.viewStatusByCandidateId[Id].LatestViewedDate,
            LastViewedBy: action.payload.viewStatusByCandidateId[Id].LatestViewedBy,
            LastViewedByName: action.payload.viewStatusByCandidateId[Id].LatestViewedByName,
          };
          newState = cleanSet(newState, ['ById', Id], newSelectedCandidate);
        }
      });
      return newState;
    case SET_CANDIDATE_JOB_MATCHING_LIST_SEARCH_TERM: {
      const matchingJobs = state.Candidate;
      const newMatchingJobs = {
        ...matchingJobs,
        MatchingJobListSearchTerm: action.payload,
      };
      return {
        ...state,
        Candidate: newMatchingJobs,
      };
    }
    case SET_CANDIDATE_JOB_MATCHING_FILTERS: {
      const matchingJobs = state.Candidate;
      const newMatchingJobs = { ...matchingJobs, filters: {} };
      const { key, value } = action.payload;
      switch (key) {
        case 'JobTitle':
          newMatchingJobs.filters.matchingJobTitleFilter = value;
          break;
        case 'Location':
          newMatchingJobs.filters.matchingJobLocationFilter = value;
          break;
        case 'Industry':
          newMatchingJobs.filters.matchingJobIndustryFilter = value;
          break;
        case 'MinExperience':
          newMatchingJobs.filters.matchingJobMinExperienceFilter = value;
          break;
        case 'MaxExperience':
          newMatchingJobs.filters.matchingJobMaxExperienceFilter = value;
          break;
        default:
          return newMatchingJobs;
      }
      return {
        ...state,
        Candidate: newMatchingJobs,
      };
    }
    case SET_SCORING_STATUS_FOR_APPLIED_BUCKET: {
      const oldState = state.Candidate;
      const newState = {
        ...oldState,
        AppliedBucketScoringStatus: {
          ...oldState?.AppliedBucketScoringStatus,
          [action.payload.jobId]: { status: action.payload.status, source: action.payload.source },
        },
      };
      return {
        ...state,
        Candidate: newState,
      };
    }

    case 'SET_MSP_ICONS': {
      const { mspIcons } = state;
      mspIcons[action.payload.candidateId] = action.payload.data;
      return {
        ...state,
        mspIcons,
      };
    }

    default:
      return state;
  }
}

function CandidateReducerWrapper(state = initialState, action) {
  let newState = CandidateReducer(state, action);
  const candidateId = _.get(action, ['payload', 'candidateId'], false);
  if (candidateId) {
    newState = resumeCleaner(newState, candidateId);
  }

  return newState;
}

CandidateReducer.propTypes = {
  state: PropTypes.shape({}),
  action: PropTypes.shape({
    type: PropTypes.string,
  }),
};

function getCandidateCount(state) {
  return state.CandidateReducer.CandidateCount;
}

function getSampleCandidateCount(state) {
  return state.CandidateReducer.SampleCandidateCount;
}

function getDilatedCandidateCount(state) {
  return state.CandidateReducer.DilatedCandidateCount;
}

function getSourcingNotification(state) {
  return state.CandidateReducer.SourcingNotification;
}

function getBulkShortlistedNotification(state) {
  return state.CandidateReducer.BulkShortlistedNotification;
}

function getCandidatesPublishedNotification(state) {
  return state.CandidateReducer.CandidatesPublishedNotification;
}

function getRecommendedStatusChangedNotification(state) {
  return state.CandidateReducer.RecommendedStatusChangedNotification;
}

function getCandidates(state) {
  const candidateInfoById = cloneDeep(state.CandidateReducer.ById) ?? {};
  // TODO: Why have CandidateExtraInfoReducer, simply return candidates if extra info is empty?
  const candidateExtraInfoById = cloneDeep(state.CandidateExtraInfoReducer?.CandidatesById) ?? {};
  if (_.isEmpty(candidateExtraInfoById)) return candidateInfoById;
  const currentCandidateIds = Object.keys(candidateInfoById);
  const currentCandidateExtraInfoById = _.pick(candidateExtraInfoById, currentCandidateIds);
  return _.mergeWith(candidateInfoById, currentCandidateExtraInfoById, candidateMergeCustomizer);
}

export function getMergedAllTabDuplicateCandidates(state, jobId) {
  const manualSearchCandidatesById = state.ManualSearchCandidateReducer.ByJobId?.[jobId]?.CandidatesById ?? {};
  const extraInfoByCandidateId = state.CandidateExtraInfoReducer?.CandidatesById ?? {};
  if (!extraInfoByCandidateId) return manualSearchCandidatesById;
  return _.mergeWith(manualSearchCandidatesById, extraInfoByCandidateId, candidateMergeCustomizer);
}

const getCandidateIds = createSelector(
  state => state.CandidateReducer.ById,
  byId => Object.keys(byId) ?? emptyList,
  {
    memoizeOptions: {
      resultEqualityCheck: deepEqual,
    },
  }
);

const getMergedCandidate = createSelector(
  [
    state => state.CandidateReducer.ById,
    state => state.CandidateExtraInfoReducer.CandidatesById,
    state => state.ManualSearchCandidateReducer?.ByJobId,
    state => state.CandidateDeDuplicationReducer?.ByJobId || [],
    (state, candidateId) => candidateId,
    state => state,
  ],
  (
    candidatesById,
    extraInfoByCandidateId,
    manualSearchCandidatesById,
    deDuplicatedCandidatesById,
    candidateId,
    state
  ) => {
    const { activeSourceName } = state.JobCandidatesTabReducer || {};
    const jobId = _.get(state, ['JobReducer', 'currentJobId'], null);
    const candidateInfo = cloneDeep(candidatesById[candidateId]) ?? {};
    let candidateInfoById =
      _.isEmpty(candidateInfo) && getIsDeDuplicationAllowed(activeSourceName)
        ? manualSearchCandidatesById[jobId].CandidatesById?.[candidateId]
        : candidateInfo;
    if (
      allowedDeDuplicationSources.filter(source => source !== 'All').includes(activeSourceName) &&
      _.isEmpty(candidateInfoById || {})
    ) {
      candidateInfoById = (deDuplicatedCandidatesById?.[jobId]?.BySource?.[activeSourceName] || []).find(
        candidate => candidate.Id === candidateId
      );
    }
    const candidateExtraInfoById = cloneDeep(extraInfoByCandidateId?.[candidateId]);
    if (!candidateExtraInfoById) {
      return candidateInfoById;
    }
    return _.mergeWith(candidateInfoById, candidateExtraInfoById, candidateMergeCustomizer);
  },
  {
    memoizeOptions: {
      equalityCheck: (a, b) => shallowEqual(a, b),
      maxSize: 20,
    },
  }
);

const getCandidatesAsList = createSelector(
  state => state.CandidateReducer.ById,
  byId => Object.values(byId ?? {})
);

function getCandidatesById(state) {
  return state.CandidateReducer.ById;
}

function getCandidateCallNotes(state, candidateId) {
  return _.get(state, ['CandidateReducer', 'ById', candidateId, 'CallNotes'], emptyList);
}

function getIsDownloadedStatus(state) {
  return _.get(state.CandidateReducer.IsDownloaded, emptyObject);
}

function getCandidateKeywords(state, source, candidateId) {
  return _.get(state.CandidateReducer.ById, [candidateId, 'Keywords'], emptyList);
}

function getCandidateFetchContactStatus(state, candidateId) {
  return _.get(state, ['CandidateReducer', 'ById', candidateId, 'fetchContactStatus'], null);
}

function getCandidateListError(state) {
  return state.CandidateReducer.candidateListError;
}

function getCurrentCandidateId(state) {
  return state.CandidateReducer.CurrentCandidateId;
}

function getCandidatesFetchFlag(state) {
  return state.CandidateReducer.FetchCandidatesFlag;
}

function getCandidatesAggregations(state) {
  return state.CandidateReducer.Aggregations || emptyObject;
}

function getCandidateIntel(state, candidateId) {
  return _.get(state, ['CandidateReducer', 'ById', candidateId, 'Intel'], null);
}

function getCandidateSpecifications(state) {
  return state?.CandidateReducer?.candidateSpecifications ?? emptyObject;
}

function getCandidateDetails(state, candidateId) {
  const candidate = getMergedCandidate(state, candidateId) || {};
  const connectStatus = getCandidateConnectStatus(state, candidate.PersonId);
  if (!candidate.Id) {
    return {};
  }
  if (!connectStatus) {
    return candidate;
  }
  return {
    ...candidate,
    connectInfo: connectStatus,
  };
}

function getConnectDetails(state) {
  return state?.CandidateReducer?.ConnectDetails;
}

function getCandidateGlobalTags(state, candidateId) {
  return _.get(state, ['CandidateReducer', 'ById', candidateId, 'CandidateGlobalTags'], emptyList);
}

function getCandidateGlobalTagsCount(state, candidateId) {
  return _.get(state, ['CandidateReducer', 'ById', candidateId, 'CandidateGlobalTagsCount']);
}

function getCandidateJobGlobalTags(state, candidateId) {
  return _.get(state, ['CandidateReducer', 'ById', candidateId, 'CandidateJobGlobalTags'], emptyList);
}

function getCandidateJobGlobalTagsCount(state, candidateId) {
  return _.get(state, ['CandidateReducer', 'ById', candidateId, 'CandidateJobGlobalTagsCount']);
}

function getCandidateListType(state) {
  return state.CandidateReducer.CandidateListType;
}

function getCandidateSuggestedTags(state) {
  return state.CandidateReducer?.utilities?.tags?.Tags ?? emptyList;
}

function getCandidateSuggestedTagsCount(state) {
  return state.CandidateReducer?.utilities?.tags?.TagsCount;
}

function getCandidateNotesByCandidateId(state, candidateId) {
  return _.get(state, ['CandidateReducer', 'ById', candidateId, 'NotesById'], emptyObject);
}

function getRejectedCandidateIds(state) {
  return state.CandidateReducer.RejectedCandidateIds ?? emptyList;
}
function getLatestCandidateSearchSuccessPage(state, { jobId, source }) {
  return state.CandidateReducer.ByJobId?.[jobId]?.BySource?.[source]?.PreviousSuccessfulCandidateFetchedPage || 1;
}

function getCandidateActivityLogs(state, candidateId) {
  return _.get(state, ['CandidateReducer', 'ById', candidateId, 'ActivityLogs'], emptyList);
}

function getCandidateActivityLogsCount(state, candidateId) {
  return _.get(state, ['CandidateReducer', 'ById', candidateId, 'ActivityLogsCount']);
}

function getCandidateJobs(state) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'Jobs'], emptyList);
}
function getCandidateMatchingJobs(state) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'MatchingJobs'], emptyList);
}

function getCandidateMatchingJobHighlights(state) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'MatchingJobHighlights'], emptyList);
}

function getCandidateMatchingJobDetails(state) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'MatchingJobCandidateDetails'], emptyList);
}

function getCandidateMatchingJobsTotalCount(state) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'TotalMatchingJobsCount'], emptyList);
}
function getCandidateMatchingJobsAggregations(state) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'MatchingJobsAggregation'], emptyList);
}
function getCandidateMatchingJobSearchTerm(state) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'MatchingJobListSearchTerm'], '');
}
function getCandidateMatchingJobFilters(state) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'filters'], {});
}
function getCandidateJobsTotalCount(state) {
  return _.get(state.CandidateReducer, ['Candidate', 'JobsCount']);
}
function getJobAggregations(state) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'JobAggregations'], emptyObject);
}
function getCandidateListsAndCampaigns(state) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'ListsAndCampaigns'], emptyList);
}
function getCandidateListsAndCampaignsTotalCount(state) {
  return _.get(state.CandidateReducer, ['Candidate', 'ListsAndCampaignsCount'], 2);
}
function getListsAndCampaignsAggregations(state) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'ListsAndCampaignAggregations'], emptyObject);
}
function getCandidateRecommendationSource(state) {
  return _.get(state, ['CandidateReducer', 'RecommendationSource'], undefined);
}
function getFilterContextId(state) {
  return _.get(state, ['CandidateReducer', 'FilterContextId'], undefined);
}

function getSupportedPageSizes(state, candidateContext) {
  const config = getConfig(state);
  const candidateListType = getCandidateListType(state);
  return getSupportedPageSizesUtil(config, candidateListType, candidateContext);
}

function getCandidateListDefaultPageSize(state, candidateContext = 'job', candidateListType) {
  const config = getConfig(state);
  const currentUser = getCurrentUser(state);
  return getCurrentPageDefaultSizeUtil(config, candidateListType, candidateContext, currentUser?.email);
}

function getAppliedBucketScoringStatus(state, { jobId }) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'AppliedBucketScoringStatus', jobId, 'status'], false);
}

function getAppliedBucketScoringStatusSource(state, { jobId }) {
  return _.get(state, ['CandidateReducer', 'Candidate', 'AppliedBucketScoringStatus', jobId, 'source'], undefined);
}

function getSegmentBulkRecommendationNotification(state) {
  return state.CandidateReducer?.BulkSegmentRecommendationNotification;
}
function getMspIcons(state, candidateId) {
  return state.CandidateReducer.mspIcons[candidateId] ?? {};
}

export {
  CandidateReducerWrapper as CandidateReducer,
  getCandidateNotesByCandidateId,
  getCandidateActivityLogs,
  getCandidateActivityLogsCount,
  getCandidateSuggestedTags,
  getCandidateSuggestedTagsCount,
  getCandidates,
  getCandidateCount,
  getSourcingNotification,
  getDilatedCandidateCount,
  getCandidateCallNotes,
  getCandidateKeywords,
  getIsDownloadedStatus,
  getCandidateFetchContactStatus,
  getCandidateListError,
  getCurrentCandidateId,
  getCandidatesFetchFlag,
  candidateMergeCustomizer,
  getCandidatesAggregations,
  getCandidateIntel,
  getRecommendedStatusChangedNotification,
  getCandidateSpecifications,
  getCandidateDetails,
  getCandidateListType,
  getBulkShortlistedNotification,
  getCandidatesPublishedNotification,
  getRejectedCandidateIds,
  getLatestCandidateSearchSuccessPage,
  getCandidateGlobalTagsCount,
  getCandidateGlobalTags,
  getCandidateJobGlobalTags,
  getCandidateJobGlobalTagsCount,
  getCandidateJobs,
  getCandidateListsAndCampaigns,
  getCandidateJobsTotalCount,
  getCandidateListsAndCampaignsTotalCount,
  getJobAggregations,
  getListsAndCampaignsAggregations,
  getCandidateMatchingJobs,
  getCandidateMatchingJobHighlights,
  getCandidateMatchingJobDetails,
  getCandidateMatchingJobsTotalCount,
  getCandidateMatchingJobsAggregations,
  getCandidateRecommendationSource,
  getFilterContextId,
  getCandidatesAsList,
  getMergedCandidate,
  getCandidateIds,
  getCandidatesById,
  getConnectDetails,
  getCandidateListDefaultPageSize,
  getSupportedPageSizes,
  getCandidateMatchingJobSearchTerm,
  getCandidateMatchingJobFilters,
  getSampleCandidateCount,
  getAppliedBucketScoringStatus,
  getAppliedBucketScoringStatusSource,
  getSegmentBulkRecommendationNotification,
  getMspIcons,
};
