import React from 'react';
import classname from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Popover, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './FilterAryaSearch.module.scss';
import FilterIconWithTooltip from '../Common/FilterIconWithTooltip/FilterIconWithTooltip';
import * as JobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import FilterAryaSearch from './FilterAryaSearch';
import { getCandidateCollapsedFilters } from '../../Reducers/JobCandidatesTabReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getCandidatesAsList } from '../../Reducers/CandidateReducer';
import message from '../Placeholders/PlaceholdersMessages';

const mapStateToProps = (state, props) => ({
  candidateCollapsedFilters: getCandidateCollapsedFilters(state, props.jobId),
  featureToggleList: getFeatureToggleList(state),
  candidates: getCandidatesAsList(state),
});

const mapDispatchToProps = {
  seCandidateCollapsedFilters: JobCandidatesTabActions.seCandidateCollapsedFilters,
  setCandidateCollapsedFilterStatus: JobCandidatesTabActions.setCandidateCollapsedFilterStatus,
};

const AryaFilterPopover = props => {
  const [popoverVisibility, setPopoverVisibility] = React.useState(false);
  const [isFilterApplied, setisFilterApplied] = React.useState(false);

  const {
    form,
    seCandidateCollapsedFilters,
    setCandidateCollapsedFilterStatus,
    jobId,
    candidateCollapsedFilters,
    featureToggleList,
    candidates = [],
    candidateContext,
    showV2FilterIcon,
  } = props;

  const collapsedFilterField = 'collapsedFilter';

  const selectedKeys = form.getFieldValue(collapsedFilterField) || [];

  const onFilterIconClick = () => {
    setPopoverVisibility(!popoverVisibility);
  };

  const onApplyClick = async e => {
    setCandidateCollapsedFilterStatus('INPROGRESS');
    await seCandidateCollapsedFilters({ jobId, filters: selectedKeys });
    setCandidateCollapsedFilterStatus('COMPLETED');
    if (selectedKeys.length) setisFilterApplied(true);
    else setisFilterApplied(false);
    setPopoverVisibility(false);
  };

  const onClearClick = async () => {
    form.setFieldsValue({ collapsedFilter: [] });
    setCandidateCollapsedFilterStatus('INPROGRESS');
    await seCandidateCollapsedFilters({ jobId, filters: [] });
    setCandidateCollapsedFilterStatus('COMPLETED');
    setisFilterApplied(false);
  };

  const additionalInlineStyles = candidates.length ? {} : { cursor: 'not-allowed', opacity: 0.3 };

  const content = (
    <FilterAryaSearch
      form={form}
      candidateCollapsedFilters={candidateCollapsedFilters}
      inlineStyles={{ margin: '20px 16px' }}
      candidateContext={candidateContext}
    />
  );

  return featureToggleList?.AdvanceSearchV2?.IsEnabled ? (
    <div className="candidate-filter-icon">
      <Popover
        className="filter-popover"
        visible={popoverVisibility && candidates.length}
        onVisibleChange={onFilterIconClick}
        overlayClassName={styles.candidateFilterPopover}
        content={content}
        placement="bottomRight"
        trigger="click"
        autoAdjustOverflow={false}
        title={
          <div className={styles.candidateFilterAggregation}>
            <div className={styles.candidateFilterAggregationTitle}>Collapse</div>
            <div className={styles.candidateFilterApplyClear}>
              <Button
                className={classname(styles.candidateFilterButton, styles.candidateFilterClearButton)}
                onClick={onClearClick}
              >
                <FormattedMessage {...message.clearLabel}/>
              </Button>
              <Button
                type="primary"
                className={classname(styles.candidateFilterButton, styles.candidateFilterApplyButton)}
                onClick={onApplyClick}
              >
                <FormattedMessage {...message.applyLabel}/>
              </Button>
            </div>
          </div>
        }
      >
        <div className={styles.candidateIconFilterButton}>
          <FilterIconWithTooltip
            className="candidate-icon-filter-image"
            title="Collapse Candidates"
            style={{ fontSize: 20, marginTop: 5, ...additionalInlineStyles }}
            onClick={onFilterIconClick}
            isFilterApplied={isFilterApplied}
            showV2FilterIcon={showV2FilterIcon}
            dotStyle={{ top: '6px' }}
          />
        </div>
      </Popover>
    </div>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(AryaFilterPopover));
export { AryaFilterPopover as AryaFilterPopoverWithoutContainer };
