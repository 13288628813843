import { defineMessages } from 'react-intl';
import candidateCardMessagesJsonEn from '../../Data/En_Us/CandidateCardMessagesEn_Us.json';
import candidateCardMessagesJsonEs from '../../Data/Es_Es/CandidateCardMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'CandidateCard';
const transformedMessage = formatMessagesWithComponentPrefix(candidateCardMessagesJsonEn, component);
const candidateCardMessages = defineMessages(transformedMessage);

export const candidateCardMessagesEn = formatJsonWithComponentPrefix(candidateCardMessagesJsonEn, component);
export const candidateCardMessagesEs = formatJsonWithComponentPrefix(candidateCardMessagesJsonEs, component);
export default candidateCardMessages;
