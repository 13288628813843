import React from 'react';
import { Icon, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import _, { debounce } from 'lodash';
import SelectDropDownContent from '../../../../Components/Utils/SelectDropDownContent/SelectDropDownContent';
import styles from './SearchCriteriaSelect.module.scss';
import message from '../../ManualSearchMessages';

export default function SearchCriteriaSelect(props) {
  const {
    criteriaIdentifiers,
    fetchManualSearchCriteriaIdentifiers,
    clearManualSearchCriteriaIdentifiers,
    fetchManualSearchCriteriaIdentifiersApiStatus,
    jobId,
    onSelect,
  } = props;
  const [searchTerm, setSearchTerm] = React.useState();
  const { Suggestions: criteriaIdentifiersSuggestions = [], Count: totalCount = 0 } = criteriaIdentifiers;
  const _fetchManualSearchCriteriaIdentifiers = debounce(fetchManualSearchCriteriaIdentifiers, 600);

  const handleSearch = debounce(value => {
    if (value) {
      if (criteriaIdentifiersSuggestions.length) clearManualSearchCriteriaIdentifiers({ jobId, criteriaType: 'Saved' });
      setSearchTerm(value);
      fetchManualSearchCriteriaIdentifiers({
        payload: { jobId, searchTerm: value },
        isOrgLevelSearch: true,
        criteriaType: 'Saved',
      });
    }
  }, 600);

  const handleChange = debounce(value => {
    if (!value) {
      setSearchTerm(undefined);
      fetchManualSearchCriteriaIdentifiers({ payload: { jobId }, criteriaType: 'Saved' });
    }
  });

  const handleScroll = e => {
    if (fetchManualSearchCriteriaIdentifiersApiStatus === 'INPROGRESS') return;
    e.persist();
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 250 &&
      criteriaIdentifiersSuggestions.length < totalCount
    ) {
      _fetchManualSearchCriteriaIdentifiers({
        payload: { jobId, searchTerm, from: criteriaIdentifiersSuggestions.length, size: 10 },
        isOrgLevelSearch: !!searchTerm,
        criteriaType: 'Saved',
      });
    }
  };

  const handleOnFocus = () => {
    if (_.isEmpty(criteriaIdentifiersSuggestions) && fetchManualSearchCriteriaIdentifiersApiStatus !== 'INPROGRESS')
      fetchManualSearchCriteriaIdentifiers({ payload: { jobId }, criteriaType: 'Saved' });
  };

  const handleSelect = id => {
    setSearchTerm(undefined);
    onSelect(id);
  };

  const placeholder = (
    <div className={styles.searchCriteriaSelectPlaceholder}>
      <FormattedMessage {...message.searchCriteriaPlaceholder} />
      <Icon type="search" />
    </div>
  );
  return (
    <Select
      style={{
        width: '232px',
      }}
      value={searchTerm}
      onSelect={handleSelect}
      placeholder={placeholder}
      className={styles.searchCriteriaSelect}
      allowClear
      showSearch
      showArrow={false}
      onSearch={handleSearch}
      onChange={handleChange}
      onPopupScroll={handleScroll}
      onFocus={handleOnFocus}
      optionFilterProp="children"
      notFoundContent={searchTerm ? ' ' : ''}
      dropdownRender={menuItems => {
        if (!searchTerm) return menuItems;
        return (
          <SelectDropDownContent
            menuItems={menuItems}
            isLoading={fetchManualSearchCriteriaIdentifiersApiStatus === 'INPROGRESS'}
            text={<FormattedMessage {...message.fetchingSavedCriteriasLabel} />}
          />
        );
      }}
    >
      {criteriaIdentifiersSuggestions.map(criteria => (
        <Select.Option key={criteria.CriteriaId} value={criteria.CriteriaId}>
          {criteria.CriteriaName}
        </Select.Option>
      ))}
    </Select>
  );
}
