import React from 'react';
import { injectIntl } from 'react-intl';
import ReactDOM from 'react-dom';
import { Select, Form, Collapse, Affix, Button, Input, Radio, Row, Col, Empty, Skeleton, Divider } from 'antd';
import RichTextEditor from '../../Editor/RichTextEditor';
import { getJobBoardsIcon } from '../../../Utils/JobBoardUtils';
import { selectComponent } from '../../../Utils/JobDistributionUtils/JobDistributionFormUtils';
import { mapMonsterJobInventoryToDisplayName } from '../../../Utils/JobDistributionUtils/MonsterJobDistributionUtils';
import { validateDescription } from '../../../Utils/JobDistributionUtils/JobDistributionUtils';
import { getLocaleDateString } from '../../../Utils/RelativeTimeCalculator';
import { validatePhone, validateEmail } from '../../../Utils/FormValidators';
import MonsterJobDistributionParameters from '../../../Data/JobDistribution/MonsterJobDistributionParameters.json';
import styles from './MonsterJobPublishForm.module.scss';
import eventTypes from '../../../Analytics/EventTypes';
import placeholder from '../../Placeholders/PlaceholdersMessages';

const { Panel } = Collapse;
const JobBoard = 'monster';

function MonsterJobPublishForm(props) {
  const {
    form,
    onSubmit,
    onCancel,
    currentUser,
    jobDetails,
    isPublished,
    distributedJobInfo,
    monsterInventories,
    filterLocations,
    locationOptions,
    locationDropDownStatus,
    distributeLoading,
    monsterInventoriesLoading,
    intl,
  } = props;

  const [selectedInventory, setSelectedInventory] = React.useState({});
  const [applyMethod, setApplyMethod] = React.useState(distributedJobInfo?.DistributionQuery?.CandidateApplyMethod);
  const [initialValues, setInitialValues] = React.useState({
    JobTitle: jobDetails.JobTitle,
    JobDescription: jobDetails.Description,
    Location: jobDetails.Location,
    CompanyName: jobDetails.Client,
  });

  React.useEffect(() => {
    if (isPublished) {
      setInitialValues({
        ...distributedJobInfo.DistributionQuery,
        CompanyName: distributedJobInfo.DistributionQuery?.CompanyContact?.CompanyName,
        ContactPerson: distributedJobInfo.DistributionQuery?.CompanyContact?.Name,
        Phone: distributedJobInfo.DistributionQuery?.CompanyContact?.Phone,
        Email: distributedJobInfo.DistributionQuery?.CompanyContact?.Email,
        Location: [
          distributedJobInfo.DistributionQuery?.PostingLocation?.City,
          distributedJobInfo.DistributionQuery?.PostingLocation?.State,
        ].join(', '),
      });
      setApplyMethod(distributedJobInfo.DistributionQuery.CandidateApplyMethod);
    }
  }, [JSON.stringify(distributedJobInfo)]);

  const filteredJobInventories = monsterInventories?.filter(
    inventory =>
      inventory.ResourceLicenseInfo.ResourceAclID.MonsterId === 233 &&
      inventory.ResourceLicenseInfo.ResourceLicenseTypeID.MonsterId === 4
  );

  React.useEffect(() => {
    setSelectedInventory(filteredJobInventories[0]);
  }, [JSON.stringify(filteredJobInventories)]);

  const handleSubmit = () => {
    form.validateFieldsAndScroll({ scroll: { offsetBottom: 150, offsetTop: 150 } }, (err, values) => {
      if (!err && (selectedInventory || isPublished)) {
        const location = values.Location?.split(',');
        const inventory = !isPublished
          ? {
              InventoryName: selectedInventory.DisplayName,
              BoardId: selectedInventory.InventoryAttributes.BoardId,
              AutoRefresh: {
                Desired: selectedInventory.InventoryAttributes.AutoRefresh.RefreshDuration > 0,
                Frequency: selectedInventory.InventoryAttributes.AutoRefresh.RefreshDuration,
              },
              ApplyBolding: selectedInventory.InventoryAttributes.HasBolding,
              ApplySocialAdTwitter: selectedInventory.InventoryAttributes.HasSocialJobAd,
              PostingDuration: selectedInventory.InventoryAttributes.PostingDuration.Duration,
              CANDuration: selectedInventory.InventoryAttributes.CANDuration,
            }
          : distributedJobInfo.DistributionQuery.MonsterPublishFields;

        const distributionRequest = {
          PostingLocation: {
            Location: values.Location,
            City: location?.[0]?.trim(),
            State: location?.[1]?.trim(),
            CountryCode: jobDetails.CountryCode,
          },
          JobType: values.JobType,
          JobStatus: values.JobStatus,
          EducationLevel: values.EducationLevel,
          CareerLevel: values.CareerLevel,
          CompanyIndustry: values.CompanyIndustry,
          JobTitle: jobDetails.JobTitle,
          JobDescription: values.JobDescription.htmlContent,
          JobCategory: values.JobCategory,
          CompanyContact: {
            CompanyName: values.CompanyName,
            Name: values.ContactPerson,
            Phone: values.Phone,
            Email: values.Email,
          },
          MonsterPublishFields: inventory,
          CandidateApplyMethod: applyMethod ?? 'IntegratedApply',
        };
        onSubmit(distributionRequest, JobBoard);
      }
    });
  };

  const getFooter = () => {
    return ReactDOM.createPortal(
      <Affix offsetBottom={0} className={styles.actionBtnAffix}>
        <div className={styles.actionBtnsContainer}>
          <div className={styles.actionBtnsWrapper}>
            {getJobBoardsIcon(JobBoard.toUpperCase(), { fontSize: '32px' })}
            <div>
              <Button
                key="cancel-publish"
                className={styles.cancelBtn}
                onClick={onCancel}
                sk-event-name={
                  isPublished
                    ? eventTypes.job.publishJob.cancelEditPublishJob
                    : eventTypes.job.publishJob.cancelPublishJob
                }
              >
                Cancel
              </Button>
              <Button
                key="submit-publish"
                className={styles.publishBtn}
                type="primary"
                onClick={handleSubmit}
                loading={distributeLoading}
                sk-event-name={
                  isPublished ? eventTypes.job.publishJob.updatePublishJob : eventTypes.job.publishJob.submitPublishJob
                }
              >
                {isPublished ? 'Update' : 'Publish'}
              </Button>
            </div>
          </div>
        </div>
      </Affix>,
      document.getElementById('body-content')
    );
  };

  const onInventorySelect = e => {
    setSelectedInventory(e.target.value);
  };

  const applyMethodSelect = e => {
    setApplyMethod(e.target.value);
  };

  if (filteredJobInventories.length === 0 && !isPublished && !monsterInventoriesLoading) {
    return (
      <div>
        <Empty description="Please integrate Monster credentials for this job with available inventory in Application Settings" />
      </div>
    );
  }

  return (
    <div className={styles.jobPublishForm}>
      <div className={styles.jobPublishHeader}>
        <Row gutter={16}>
          <Col span={16}>
            <div className={styles.publishJobTitle}>{initialValues?.JobTitle}</div>
            {isPublished ? (
              <div className={styles.publishStats}>
                Published on {getLocaleDateString(distributedJobInfo.DistributedDateTime)}
                <Divider type="vertical" />
                Last Modified on {getLocaleDateString(distributedJobInfo.ModifiedDateTime)}
                <Divider type="vertical" />
                Expires on {getLocaleDateString(distributedJobInfo.ExpiryDateTime)}
              </div>
            ) : null}
          </Col>
        </Row>
      </div>

      <Form.Item label="Company" colon={false}>
        {form.getFieldDecorator('CompanyName', {
          initialValue: initialValues?.CompanyName,
        })(
          <Input
            key="CompanyName"
            placeholder={intl.formatMessage({ ...placeholder.enterCompanyNameLabel })}
            className={styles.inputComponent}
          />
        )}
      </Form.Item>

      {selectComponent(
        form,
        'Company Industry',
        'CompanyIndustry',
        initialValues?.CompanyIndustry,
        'Add industry',
        MonsterJobDistributionParameters.JobIndustryOptions
      )}

      {selectComponent(form, 'Country', 'CountryCode', jobDetails.CountryCode, 'Add Country', [], null, true)}

      <Form.Item label="Location" colon={false}>
        {form.getFieldDecorator('Location', {
          rules: [
            {
              required: true,
              message: 'Please input location',
            },
          ],
          initialValue: initialValues?.Location,
        })(
          <Select
            allowClear
            key="Location"
            showSearch
            placeholder={intl.formatMessage({ ...placeholder.locationLabel })}
            notFoundContent={locationDropDownStatus}
            onSearch={filterLocations}
            showArrow={false}
          >
            {locationOptions}
          </Select>
        )}
      </Form.Item>

      {selectComponent(
        form,
        'Job Type',
        'JobType',
        initialValues?.JobType,
        'Select Job Type',
        MonsterJobDistributionParameters.JobTypeOptions
      )}

      {selectComponent(
        form,
        'Job Status',
        'JobStatus',
        initialValues?.JobStatus,
        'Select Job Status',
        MonsterJobDistributionParameters.JobStatusOptions
      )}

      {selectComponent(
        form,
        'Education Level',
        'EducationLevel',
        initialValues?.EducationLevel,
        'Select Education Level',
        MonsterJobDistributionParameters.EducationLevelOptions
      )}

      {selectComponent(
        form,
        'Career Level',
        'CareerLevel',
        initialValues?.CareerLevel,
        'Select Career Level',
        MonsterJobDistributionParameters.CareerLevelOptions
      )}

      <Form.Item label="About the Job" className={styles.jobPublishDescription} colon={false}>
        {form.getFieldDecorator('JobDescription', {
          rules: [
            {
              required: true,
              validator: validateDescription,
            },
          ],
          initialValue: {
            htmlContent: initialValues?.JobDescription,
          },
          valuePropName: 'editorContent',
        })(<RichTextEditor showToolbar imageSupport={false} className={styles.richEditorJobPublishDescription} />)}
      </Form.Item>

      {selectComponent(
        form,
        'Job Category',
        'JobCategory',
        initialValues?.JobCategory,
        'Select Job Category',
        MonsterJobDistributionParameters.JobCategotyOptions,
        [
          {
            required: true,
            message: 'Please select category',
          },
        ]
      )}

      <div className={styles.jobInventories}>
        {!isPublished ? (
          <>
            <div className={styles.labelHeader}>Select Job Inventory</div>
            <Skeleton active paragraph={{ rows: 3 }} loading={monsterInventoriesLoading}>
              <div className={styles.inventoryOptions}>
                <Radio.Group onChange={onInventorySelect} defaultValue={filteredJobInventories[0]}>
                  {filteredJobInventories.map(inventory => (
                    <Radio value={inventory}>{mapMonsterJobInventoryToDisplayName(inventory)}</Radio>
                  ))}
                </Radio.Group>
              </div>
            </Skeleton>
          </>
        ) : (
          <>
            <div className={styles.labelHeader}>Selected Job Inventory</div>
            <div>{distributedJobInfo.DistributionQuery?.MonsterPublishFields?.InventoryName}</div>
          </>
        )}
      </div>

      <div className={styles.contactDetails}>
        <Collapse>
          <Panel header="Contact Details">
            <Form.Item label="Contact Person" colon={false}>
              {form.getFieldDecorator('ContactPerson', {
                initialValue: initialValues?.ContactPerson,
              })(
                <Input
                  key="ContactPerson"
                  placeholder={intl.formatMessage({ ...placeholder.enterContactPersonLabel })}
                  className={styles.inputComponent}
                  maxLength={100}
                />
              )}
            </Form.Item>
            <Form.Item label="Phone" colon={false}>
              {form.getFieldDecorator('Phone', {
                rules: [{ validator: validatePhone }],
                initialValue: initialValues?.Phone ?? currentUser.OfficePhone,
              })(
                <Input
                  key="Phone"
                  placeholder={intl.formatMessage({ ...placeholder.enterPhoneNumberLabel })}
                  className={styles.inputComponent}
                />
              )}
            </Form.Item>
            <Form.Item label="Email" colon={false}>
              {form.getFieldDecorator('Email', {
                rules: [{ validator: validateEmail }],
                initialValue: initialValues?.Email ?? currentUser.Email,
              })(<Input key="Email" placeholder="Enter Email Address" className={styles.inputComponent} />)}
            </Form.Item>
          </Panel>
        </Collapse>
      </div>

      <div className={styles.applyMethod}>
        <div className={styles.labelHeader}>I want Job Seekers to apply via: </div>
        <div className={styles.applyRadioSelect}>
          <Radio.Group
            onChange={applyMethodSelect}
            defaultValue={isPublished ? distributedJobInfo.DistributionQuery.CandidateApplyMethod : 'IntegratedApply'}
          >
            <Radio value="IntegratedApply">Monster</Radio>
            <Radio value="ATSApply">My company&apos;s website</Radio>
          </Radio.Group>
        </div>
      </div>
      {getFooter()}
    </div>
  );
}

export default injectIntl(MonsterJobPublishForm);
export { MonsterJobPublishForm as MonsterJobPublishFormWithoutInjectIntl };
