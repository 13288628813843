import _ from 'lodash';
import { Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { getSourceDisplayName } from './SourceUtils';
import './CandidateApiErrorResponseMapper.scss';
import store from '../store';
import { getCurrentUser } from '../Reducers/UserSessionReducer';
import { getLocaleDateStringWithTimeStamp } from './RelativeTimeCalculator';
import candidateApiErrorMessages from './CandidateApiErrorMessagesUtils';

function mapErrorCodes(error) {
  const source = _.get(error, ['response', 'data', 'Error', 'Info']) || {};
  const parseValue = value => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };

  const info = parseValue(source);
  const state = store.getState();
  const userConfig = state?.ConfigReducer?.UserConfig ?? {};
  const displayName = getSourceDisplayName(source, userConfig);
  let showErrorMessageForGroup = false;
  const isGroup = Boolean(source.Group);
  let errorData = {
    Message: <FormattedMessage {...candidateApiErrorMessages.oopSomethingWentWrongMessage} />,
    ErrorDescription: <FormattedMessage {...candidateApiErrorMessages.sourceErrorDescriptionMessage} />,
  };
  const appCode = _.get(error, ['response', 'data', 'Error', 'Code'], '');
  const statusCode = _.get(error, ['response', 'status']);
  const message = _.get(error, ['response', 'data', 'Error', 'Message'], '');
  if (isGroup && statusCode) {
    if (appCode.includes('ARYA_CREDIT_EXHAUSTED')) {
      showErrorMessageForGroup = true;
    }
  }

  if (statusCode && (!isGroup || showErrorMessageForGroup)) {
    const portal = source.Portal;
    switch (statusCode) {
      case 503:
        if (appCode.includes('GATEWAY_ERROR') || appCode.includes('SOURCE_UNAVAILABLE_AT_THE_MOMENT'))
          errorData = {
            Message: (
              <FormattedMessage
                {...candidateApiErrorMessages.serviceInterruptionMessageLabel}
                values={{ displayName }}
              />
            ),
            ErrorDescription: <FormattedMessage {...candidateApiErrorMessages.sourceErrorDescriptionMessage} />,
          };
        break;
      case 404:
        if (appCode.includes('CANDIDATE_NOT_FOUND'))
          errorData = {
            Message: <FormattedMessage {...candidateApiErrorMessages.profileInfoNotAvailable} />,
            ErrorDescription: <FormattedMessage {...candidateApiErrorMessages.candidateProfileRemovedFromPortalInfo} />,
          };
        else if (appCode.includes(`CREDENTIALS_NOT_FOUND`))
          errorData = {
            ...errorData,
            Message: <FormattedMessage {...candidateApiErrorMessages.portalDisconnectedLabel} values={{ portal }} />,
            ErrorDescription: (
              <FormattedMessage {...candidateApiErrorMessages.accountNotSignedInLabel} values={{ displayName }} />
            ),
            showMessageAsWarning: true,
            showSettingsLink: true,
          };
        else if (appCode.includes('SOURCE_CANDIDATE_RESUME_UPDATED'))
          errorData = {
            ...errorData,
            Message: <FormattedMessage {...candidateApiErrorMessages.profileInfoNotAvailable} />,
            ErrorDescription: <FormattedMessage {...candidateApiErrorMessages.candidateProfileUpdatedInPortalInfo} />,
          };
        else if (appCode.includes('SOURCE_CANDIDATE_RESUME_NOT_AVAILABLE'))
          errorData = {
            ...errorData,
            Message: <FormattedMessage {...candidateApiErrorMessages.resumeInfoNotAvailableLabel} />,
            ErrorDescription: <FormattedMessage {...candidateApiErrorMessages.jobBoardUnableToProvideInfoLabel} />,
          };
        break;
      case 403:
        if (appCode.includes('SOURCE_FORBIDDEN'))
          errorData = {
            Message: <FormattedMessage {...candidateApiErrorMessages.profileAccessRestrictedInfo} />,
            ErrorDescription: <FormattedMessage {...candidateApiErrorMessages.candidatePrivateProfileLabel} />,
          };
        else if (appCode.includes('SOURCE_REQUEST_FAILED'))
          errorData = {
            ...errorData,
            Message: (
              <FormattedMessage {...candidateApiErrorMessages.portalTechnicalIssueLabel} values={{ displayName }} />
            ),
          };
        else if (appCode.includes(`CREDENTIALS_NOT_SUPPORTED`))
          errorData = {
            ...errorData,
            Message: <FormattedMessage {...candidateApiErrorMessages.portalDisconnectedInfo} values={{ portal }} />,
            ErrorDescription: (
              <FormattedMessage {...candidateApiErrorMessages.accountNotSignedInLabel} values={{ displayName }} />
            ),
            showMessageAsWarning: true,
            showSettingsLink: true,
          };
        break;
      case 401:
        if (appCode.includes('INVALID_SOURCE_CREDENTIALS'))
          errorData = {
            Message: (
              <FormattedMessage {...candidateApiErrorMessages.sourceDisconnectedInfo} values={{ displayName }} />
            ),
            ErrorDescription: (
              <FormattedMessage {...candidateApiErrorMessages.accountNotSignedInLabel} values={{ displayName }} />
            ),
            showMessageAsWarning: true,
            showSettingsLink: true,
          };
        break;
      case 402:
        if (appCode.includes('SOURCE_CREDIT_EXHAUSTED'))
          errorData = {
            ...errorData,
            Message: (
              <FormattedMessage
                {...candidateApiErrorMessages.insufficiendViewRemainingLabel}
                values={{ displayName }}
              />
            ),
            ErrorDescription: <FormattedMessage {...candidateApiErrorMessages.contactAdminSuggestionLabel} />,
          };
        else if (appCode.includes('ARYA_CREDIT_EXHAUSTED')) {
          const currentStore = store.getState();
          const currentUser = getCurrentUser(currentStore);
          const currentUserName = currentUser?.given_name;
          errorData = {
            ...errorData,
            Message: (
              <FormattedMessage {...candidateApiErrorMessages.lowCreditsInfoLabel} values={{ currentUserName }} />
            ),
            ErrorDescription: <FormattedMessage {...candidateApiErrorMessages.insufficientCreditSuggestion} />,
            showMessageAsWarning: true,
          };
        }
        break;
      case 406:
        if (appCode.includes('SOURCE_NOT_CONFIGURED'))
          errorData = {
            Message: <FormattedMessage {...candidateApiErrorMessages.oopSomethingWentWrongMessage} />,
            ErrorDescription: (
              <FormattedMessage {...candidateApiErrorMessages.jobBoardNotEnabledInfo} values={{ displayName }} />
            ),
            showMessageAsWarning: true,
          };
        break;
      case 429:
        if (appCode.includes('SOURCE_LIMIT_EXCEEDED'))
          errorData = {
            Message: <FormattedMessage {...candidateApiErrorMessages.unableToGetProfileInfoLabel} />,
            ErrorDescription: <FormattedMessage {...candidateApiErrorMessages.apiCallesLimitExceededLabel} />,
            showMessageAsWarning: true,
          };
        break;
      case 422:
        if (appCode.includes('UNPROCESSABLE_REQUEST'))
          errorData = {
            Message: message,
          };
        break;
      case 400:
        if (appCode.includes('INVALID_STATUS')) {
          if (info?.StatusReasons) {
            const { StatusReasons: statusReasons } = info;
            const isOptOutPresent = statusReasons.some(obj => obj.Name === 'OPT_OUT');
            if (isOptOutPresent) {
              errorData = {
                Message: 'Cannot unreject candidate when Opted out is selected as reject reason',
                ErrorDescription: null,
                showMessageAsWarning: true,
              };
            }
          }
        }
        break;
      case 500:
      default:
        break;
    }
  } else if (!statusCode) {
    // eslint-disable-next-line no-console
    console.error(error);
    errorData = null;
  }
  return errorData;
}

function getCommunicationTabsErrorMessage(status) {
  const { candidateStatus, candidateName, style, subscription } = status;
  if (candidateStatus) {
    return (
      <div className="consent-status-info-message consent-denied candidate-communication-disabled-banner" style={style}>
        <Icon type="info-circle" theme="filled" />
        <span>
          <FormattedMessage
            {...candidateApiErrorMessages.communicationRestrictedLabel}
            values={{ candidateStatus, candidateName }}
          />
        </span>
      </div>
    );
  }
  if (subscription?.Status?.toLowerCase() === 'unsubscribed') {
    const { UpdatedTime, Remarks: remarks, CreatedByName } = subscription;
    return (
      <div className="consent-status-info-message consent-denied candidate-communication-disabled-banner" style={style}>
        <Icon type="info-circle" theme="filled" />
        <span>{`You won't be able to communicate with ${candidateName} as ${CreatedByName} has marked the candidate as unsubscribed with reason “ ${remarks} ” on ${getLocaleDateStringWithTimeStamp(
          UpdatedTime
        )}`}</span>
      </div>
    );
  }

  return (
    <div className="consent-status-info-message consent-denied candidate-communication-disabled-banner" style={style}>
      <Icon type="info-circle" theme="filled" />
      <span>
        <FormattedMessage {...candidateApiErrorMessages.communicationDisabledLabel} values={{ candidateName }} />
      </span>
    </div>
  );
}

export { getCommunicationTabsErrorMessage, mapErrorCodes };
