import { defineMessages } from 'react-intl';
import applicationSettingMessagesJsonEn from '../../Data/En_Us/ApplicationSettingMessagesEn_Us.json';
import applicationSettingMessagesJsonEs from '../../Data/Es_Es/ApplicationSettingMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'ApplicationSetting';
const transformedMessage = formatMessagesWithComponentPrefix(applicationSettingMessagesJsonEn, component);
const applicationSettingMessages = defineMessages(transformedMessage);

export const applicationSettingMessagesEn = formatJsonWithComponentPrefix(applicationSettingMessagesJsonEn, component);
export const applicationSettingMessagesEs = formatJsonWithComponentPrefix(applicationSettingMessagesJsonEs, component);

export default applicationSettingMessages;
