import React from 'react';
import { Button, Input, Popover, Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styles from './UnsubscribeCandidate.module.scss';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

import * as ConnectActions from '../../Actions/ConnectActions';

const { TextArea } = Input;

const UnsubscribeCandidateButton = props => {
  const [popoverVisibility, setPopoverVisibility] = React.useState(false);
  const [unsubscribeReason, setUnsubscribeReason] = React.useState(null);
  const [selectedUnsubscribeReason, setSelectedUnsubscribeReason] = React.useState('Job');
  const isSubmitButtonDisabled = !unsubscribeReason?.trim()?.length;

  const togglePopoverVisibility = () => {
    setPopoverVisibility(!popoverVisibility);
  };
  const onClickUnsubscribeButton = () => {
    togglePopoverVisibility();
  };

  const handleUnsubscribe = () => {
    const { unsubscribeCandidate, candidate } = props;
    const { ConversationId, PersonId } = candidate;
    togglePopoverVisibility();
    const isJobLevel = selectedUnsubscribeReason === 'Job';
    unsubscribeCandidate(PersonId, unsubscribeReason, ConversationId, 'Unsubscribed', isJobLevel);
    setUnsubscribeReason(null);
    setSelectedUnsubscribeReason(null);
  };

  const handleUnsubscribeReason = e => {
    setUnsubscribeReason(e.target.value);
  };

  const unsubscribeTypeToText = {
    Job: 'From this Job',
    Platform: 'From the Platform',
  };
  const setUnsubscribeOption = value => {
    setSelectedUnsubscribeReason(value);
  };

  const unsubscribeButtonPopoverContent = () => {
    return (
      <div className={styles.unsubscribeButtonPopoverContent}>
        <Radio.Group
          name="radiogroup"
          onChange={e => setUnsubscribeOption(e.target.value)}
          className={styles.radioGroup}
          value={selectedUnsubscribeReason}
        >
          {Object.keys(unsubscribeTypeToText).map(unsubscribeType => (
            <Radio className={styles.radio} value={unsubscribeType} key={unsubscribeType}>
              {unsubscribeTypeToText[unsubscribeType]}
            </Radio>
          ))}
        </Radio.Group>

        <TextArea
          onChange={handleUnsubscribeReason}
          rows={4}
          placeholder={`Enter Remarks\n(max 70 characters)`}
          maxLength={70}
          value={unsubscribeReason}
          className={styles.textarea}
        />

        <div className={styles.submitButton}>
          <Button
            size="small"
            type="primary"
            shape="round"
            onClick={handleUnsubscribe}
            className={styles.buttonMargin}
            disabled={isSubmitButtonDisabled}
            data-testid="custom-element"
          >
            <FormattedMessage {...message.submitLabel} />
          </Button>
        </div>
      </div>
    );
  };

  const unsubscribeButtonContentWithPopover = () => {
    const popoverContent = unsubscribeButtonPopoverContent();
    return (
      <div className={styles.popoverContent}>
        <Popover
          mouseEnterDelay={0.2}
          placement="top"
          style={{ margin: '10px' }}
          content={popoverContent}
          trigger="click"
          title="Unsubscribe Candidate"
          visible={popoverVisibility}
        >
          <Button shape="round" className={styles.unsubscribeCandidateButton} onClick={onClickUnsubscribeButton}>
            <span className={styles.unsubscribeCandidateButtonText}><FormattedMessage {...message.unsubscribeCandidateLabel} /></span>
          </Button>
        </Popover>
      </div>
    );
  };
  return <div>{unsubscribeButtonContentWithPopover()}</div>;
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  unsubscribeCandidate: ConnectActions.unsubscribeCandidate,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribeCandidateButton);

export { UnsubscribeCandidateButton as UnsubscribeCandidateButtonWithoutStore };
