import React from 'react';
import moment from 'moment';
import { Badge } from 'antd';
import styles from './CrmNotification.module.scss';
import { TaskIcon, EventIcon } from '../../../Icons/AryaIcons';

const CrmNotification = props => {
  const { notification, onATSSalesCallback, hidePopover } = props;
  const { Payload: payload, UpdatedTime, UnreadCount } = notification;
  const { Subject, DisplayDate, SubType } = payload;
  const onClick = () => {
    onATSSalesCallback(payload);
    hidePopover();
  };
  const parsedUpdatedTime = moment.utc(UpdatedTime).format('DD MMMM YYYY, HH:mm');
  return (
    <div className={styles.crmnotificationContainer} role="button" tabIndex={0} onKeyDown={onClick} onClick={onClick}>
      <div className={styles.isViewed}>
        <Badge color="#1570EF" dot={UnreadCount > 0} />
      </div>
      <div className={styles.icon}>
        {(SubType === 'Task' || SubType === 'Call') && <TaskIcon />}
        {SubType === 'Event' && <EventIcon />}
        {!SubType && <TaskIcon />}
      </div>
      <div className={styles.content}>
        <div className={styles.subject}>{Subject}</div>
        <div className={styles.displayDate}>{DisplayDate}</div>
        <div className={styles.updatedTime}>{parsedUpdatedTime}</div>
      </div>
    </div>
  );
};

export default CrmNotification;
