import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

const PublishButton = ({
  selectedCandidates,
  jobId,
  publishedCandidates,
  candidatePublishApiStatus,
  setSelectedCandidates,
  setSelectedActiveCandidateCount,
}) => {
  const handlePublishCandidate = () => {
    setSelectedCandidates({ candidateType: 'Recommended', selectedCandidates: [] });
    setSelectedActiveCandidateCount({ candidateType: 'Recommended', selectedActiveCandidateCount: 0 });
    publishedCandidates({ selectedCandidates, jobId });
  };
  const isPublishCandidateButtonDisabled = selectedCandidates.length;
  return (
    <Button
      type="primary"
      loading={candidatePublishApiStatus === 'INPROGRESS'}
      onClick={handlePublishCandidate}
      disabled={!isPublishCandidateButtonDisabled}
    >
      <FormattedMessage {...message.publishCandidateLabel} />
    </Button>
  );
};

export default PublishButton;
