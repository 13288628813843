import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { NotesIcon } from '../../../Icons/AryaIcons';
import './ContentPaneHeader.scss';
import jobMessage from '../../JobForm/JobMessages';

export default function(props) {
  const { candidateDetails, showProfile } = props;
  const { Name, Title, Company } = candidateDetails;
  const primaryInfo = Title && Company ? `${Title}  @  ${Company}` : Title || Company;
  return (
    <div className="contentHeaderBox">
      <div className="infoAndSettings">
        <div className="personInfo">
          <div className="headerTitle">
            <div className="personName">
              <span>{Name}</span>
            </div>
            <div className="primaryInfo">{primaryInfo} </div>
          </div>
          <div className="notesIcon">
            <NotesIcon
              onClick={() => {
                showProfile('notes');
              }}
            />
          </div>
          <div className="profileButton">
            <Button
              size="small"
              onClick={() => {
                showProfile('profile');
              }}
            >
              <FormattedMessage {...jobMessage.profileLabel} />
            </Button>
          </div>
          {/* <div className="shortlistButton">
            <Icon style={{ fontSize: '24px' }} type="like" />
          </div> */}
        </div>
      </div>
    </div>
  );
}
