import React from 'react';
import { Skeleton } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import SettingsLayout from '../../Common/SettingsLayout/SettingsLayout';
import SettingsLayoutItem from '../../Common/SettingsLayoutItem/SettingsLayoutItem';
import { ScrollTo, ScrollItem } from '../../Common/Scroll/Scroll';
import styles from './NotificationSettings.module.scss';
import NotificationSettingsItem from './NotificationSettingsItem/NotificationSettingsItem';
import AllNotificationsActivator from './AllNotificationsActivator/AllNotificationsActivator';
import { NetworkErrorComponent } from '../../ErrorComponent/NetworkErrorComponent';
import reportPortalMessages from '../../ReportPortal/ReportPortalMessages';

export function getNotificationTypeTitle(type, intl) {
  let typeText = '';
  switch (type?.toLowerCase()) {
    case 'email':
      typeText = intl.formatMessage({ ...reportPortalMessages.notificationSettingTextEmail });
      break;
    case 'inapp':
      typeText = intl.formatMessage({ ...reportPortalMessages.notificationSettingTextInApp });
      break;
    default:
      typeText = intl.formatMessage({ ...reportPortalMessages.notificationSettingTextOther });
  }
  return <FormattedMessage {...reportPortalMessages.notificationSettingReturn} values={{ typeText }} />;
}

function NotificationSettings(props) {
  const {
    notificationConfig,
    fetchNotificationConfigApiStatus,
    fetchNotificationConfig,
    updateNotificationConfigByType,
    updateNotificationConfigById,
    activateAllNotificationByType,
    intl,
  } = props;

  React.useEffect(() => {
    const defaultNotificationTypes = ['Email'];
    const filter = {
      types: defaultNotificationTypes,
    };
    fetchNotificationConfig(filter);
  }, []);

  const onToggleSubscription = (id, value) => {
    const config = {
      Subscribe: value,
    };
    updateNotificationConfigById({ id, config });
  };

  const onToggleNotificationType = (type, value) => {
    const config = {
      Activate: value,
    };
    updateNotificationConfigByType({ type, config });
  };

  const onActivateAllNotifications = (type, value) => {
    const config = {
      SubscribeAll: value,
    };
    activateAllNotificationByType({ type, config });
  };

  const sections = notificationConfig?.ConfigTypes?.map((configType, index) => {
    const title = getNotificationTypeTitle(configType, intl);
    const config = notificationConfig?.ConfigByType?.[configType];
    let isAllNotificationSubscribed = true;
    const subscriptionItems = config?.SubscriptionIds?.map(subscriptionId => {
      const subscription = notificationConfig?.SubscriptionById?.[subscriptionId];
      isAllNotificationSubscribed = isAllNotificationSubscribed && subscription?.IsSubscribed;
      return (
        <NotificationSettingsItem
          title={subscription?.Name}
          key={subscription?.Id}
          isSubscribed={config?.IsActive && subscription?.IsSubscribed}
          loading={subscription?.apiStatus?.toUpperCase() === 'INPROGRESS'}
          onChange={value => onToggleSubscription(subscription?.Id, value)}
          disabled={
            !config?.IsActive ||
            config?.apiStatus?.toUpperCase() === 'INPROGRESS' ||
            config?.activateAllApiStatus?.toUpperCase() === 'INPROGRESS'
          }
        />
      );
    });
    return {
      id: index,
      title,
      content: (
        <SettingsLayoutItem
          title={
            <NotificationSettingsItem
              key={configType}
              className={styles.notificationSettingsTitle}
              title={<div className={styles.notificationSettingsTitleText}>{title}</div>}
              isSubscribed={config?.IsActive}
              onChange={value => onToggleNotificationType(configType, value)}
              loading={config?.apiStatus?.toUpperCase() === 'INPROGRESS'}
            />
          }
        >
          <div className={styles.notificationSettingsWrapper}>
            <AllNotificationsActivator
              key={configType}
              checked={config?.IsActive && isAllNotificationSubscribed}
              onChange={value => onActivateAllNotifications(configType, value)}
              loading={config?.activateAllApiStatus?.toUpperCase() === 'INPROGRESS'}
              disabled={!config?.IsActive || config?.apiStatus?.toUpperCase() === 'INPROGRESS'}
            />
            <div className={styles.notificationSettingsItemsWrapper}>{subscriptionItems}</div>
          </div>
        </SettingsLayoutItem>
      ),
    };
  });
  const sider = sections?.map(section => (
    <ScrollTo id={section.id} key={section.id}>
      <div className={styles.settingNavigation}>{section.title}</div>
    </ScrollTo>
  ));
  const content = sections?.map(section => (
    <ScrollItem id={section.id} key={section.id}>
      {section.content}
    </ScrollItem>
  ));

  return (
    <Skeleton
      active
      paragraph={{ rows: 16 }}
      loading={!['COMPLETED', 'FAILED'].includes(fetchNotificationConfigApiStatus?.toUpperCase())}
    >
      {fetchNotificationConfigApiStatus?.toUpperCase() === 'FAILED' ? (
        <NetworkErrorComponent />
      ) : (
        <SettingsLayout sider={sider} content={content} />
      )}
    </Skeleton>
  );
}
export default injectIntl(NotificationSettings);
export { NotificationSettings as NotificationSettingsWithoutInjectIntl };
