import { defineMessages } from 'react-intl';
import EmailSettingsMessageJsonEn from '../../Data/En_Us/EmailSettingsMessageEn_Us.json';
import EmailSettingsMessageJsonEs from '../../Data/Es_Es/EmailSettingsMessageEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'EmailSettings';
const transformedMessage = formatMessagesWithComponentPrefix(EmailSettingsMessageJsonEn, component);
const emailSettingsMessages = defineMessages(transformedMessage);

const EmailSettingsMessage = defineMessages(emailSettingsMessages);
export const emailSettingsMessageEn = formatJsonWithComponentPrefix(EmailSettingsMessageJsonEn, component);
export const emailSettingsMessageEs = formatJsonWithComponentPrefix(EmailSettingsMessageJsonEs, component);
export default EmailSettingsMessage;
