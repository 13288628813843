import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './WorkflowCandidateContext.module.scss';
import message from '../Connect/ConnectMessages';

function WorkflowCandidateContext({ candidateContextId, workflowHistory }) {
  const workflowCandidateContext = JSON.parse(localStorage.getItem('WorkflowCandidateContext'));
  const currentCandidateContext = workflowCandidateContext[candidateContextId];
  const { jobId, jobTitle, candidateName } = currentCandidateContext || {};
  let displayStatus = '';
  const disclaimerMessage = <FormattedMessage {...message.candidatesJourneyThroughTheWorkflow} />;
  const showDisclaimerMessage =
    workflowHistory?.[0]?.ActivityStatuses?.some(activity => activity.State === 'Completed') ?? false;
  if (workflowHistory !== undefined)
    switch (workflowHistory[0]?.Status) {
      case 'Running':
      case 'Idle':
      case 'Suspended':
        displayStatus = 'Active';
        break;
      case 'Faulted':
        displayStatus = 'Failed';
        break;
      case 'Cancelled':
        displayStatus = 'Stopped';
        break;
      default:
        displayStatus = workflowHistory[0]?.Status;
    }
  return (
    <>
      {showDisclaimerMessage && <div className={styles.workflowReportNote}>{disclaimerMessage}</div>}
      <div className={styles.workflowContextWrapper}>
        <div>
          <b><FormattedMessage {...message.jobIdLabel} /> : </b>
          {jobId}
        </div>
        <div>
          <b><FormattedMessage {...message.jobTitleLabel} /> : </b>
          {jobTitle}
        </div>
        <div>
          <b><FormattedMessage {...message.candidateNameLabel} /> : </b>
          {candidateName}
        </div>
        {displayStatus && (
          <div>
            <b><FormattedMessage {...message.statusLabel} /> : </b>
            {displayStatus}
          </div>
        )}
      </div>
    </>
  );
}

const MemoizedWorkflowCandidateContextComponent = React.memo(WorkflowCandidateContext);
export default MemoizedWorkflowCandidateContextComponent;
