/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Input, Icon } from 'antd';
import * as DragAndDropHook from '../../../Hooks/useDragAndDrop';
import styles from './InfoGatheringQuestionItem.module.scss';
import placeholder from '../../Placeholders/PlaceholdersMessages';

function InfoGatheringQuestionItem(props) {
  const {
    categoryErrors,
    dragType,
    infoGatheringLastQuestionRef,
    onCategoryChange,
    removeQuestion,
    onDragQuestion,
    onInfoQuestionChange,
    question,
    questionErrors,
    questionIndex,
    readOnly,
    intl,
  } = props;

  const [isTextFocused, setTextFocused] = React.useState(false);
  const ref = React.useRef(null);
  const [handlerId, drop, drag] = DragAndDropHook.useDragAndDrop({
    itemType: dragType,
    index: questionIndex,
    itemId: question.Id,
    moveCard: onDragQuestion,
    ref,
    isDragAllowed: !isTextFocused,
  });
  drag(drop(ref));

  React.useEffect(() => {
    infoGatheringLastQuestionRef.current = ref.current;
  }, [ref.current]);

  return (
    <div
      className={styles.infoGatheringQuestionItem}
      ref={ref}
      data-handler-id={handlerId}
      data-testid="info-gathering-question-item"
    >
      <Form.Item
        validateStatus={categoryErrors && categoryErrors[questionIndex] ? 'error' : ''}
        help={categoryErrors && categoryErrors[questionIndex] ? 'Category name is required' : ''}
      >
        <Input
          className={styles.infoGatheringCategory}
          value={question.CategoryName}
          placeholder={intl.formatMessage({ ...placeholder.addCategoryNameLabel })}
          onChange={event => onCategoryChange(questionIndex, event.target.value)}
          disabled={readOnly}
          onMouseEnter={() => setTextFocused(true)}
          onMouseLeave={() => setTextFocused(false)}
        />
      </Form.Item>
      <div className={styles.infoGatheringQuestion}>
        <Form.Item
          validateStatus={questionErrors && questionErrors[questionIndex] ? 'error' : ''}
          help={questionErrors && questionErrors[questionIndex] ? 'Question is required' : ''}
        >
          <Input
            className={styles.questionInput}
            value={question.Question}
            placeholder={intl.formatMessage({ ...placeholder.addAQuestionLabel })}
            onChange={event => onInfoQuestionChange(questionIndex, event.target.value)}
            disabled={readOnly}
            onMouseEnter={() => setTextFocused(true)}
            onMouseLeave={() => setTextFocused(false)}
          />
        </Form.Item>
        {!readOnly ? (
          <Icon type="minus-circle" onClick={removeQuestion} data-testid="remove-icon" style={{ fontSize: 18 }} />
        ) : null}
      </div>
    </div>
  );
}

export default injectIntl(InfoGatheringQuestionItem);
export { InfoGatheringQuestionItem as InfoGatheringQuestionItemWithoutInjectIntl };
