import React from 'react';
import { Row, Col, Form, Select, Typography, Divider, Tag, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getSupportedSourcesDisplayText } from '../../../CompoundComponents/EducationWrapper/Degree';
import { GlobalWorkStatusOptions } from '../../../Utils/AdvanceRefineIntelUtils';
import TitlesSection from './TitlesSection/TitlesSection';
import SkillsSection from './SkillsSection/SkillsSection';
import ExperienceSection from './ExperienceSection/ExperienceSection';
import ExcludeCandidateSection from '../AdvancedCriteria/ExcludeCandidateSection/ExcludeCandidateSection';
import styles from './SearchCriteria.module.scss';
import ResumeLastUpdatedSection from './ResumeLastUpdatedSection/ResumeLastUpdatedSection';
import ManualSearchSources from '../ManualSearchSources/ManualSearchSources';
import ClearAllButton from '../ClearAllButton/ClearAllButton';
import EnhancedLocationSection from './EnhancedLocationSection/EnhancedLocationSection';
import message from '../ManualSearchMessages';

export function InputItem({ children, label }) {
  return (
    <Row>
      <Col span={5}>
        <div className={styles.inputLabels}>{label}</div>
      </Col>
      <Col span={19}>{children}</Col>
    </Row>
  );
}

export function DisplaySelectedWorkAuthorization(props) {
  const { selectedVisaStatuses, form } = props;
  if (!selectedVisaStatuses?.length) return null;

  const onRemoveVisaStatus = value => {
    const currentVisaStatuses = form.getFieldValue('VisaStatuses');
    const updateVisaStatuses = currentVisaStatuses.filter(visaStatus => visaStatus !== value);
    form.setFieldsValue({ VisaStatuses: updateVisaStatuses });
  };

  return (
    <div className={styles.displayedWorkAuth}>
      {selectedVisaStatuses?.map(visaStatus => (
        <Tag key={visaStatus} className={styles.workAuthTag}>
          <div className={styles.workAuthTagText}>
            {GlobalWorkStatusOptions.find(item => item.value === visaStatus).label}
          </div>
          <Icon type="close" onClick={() => onRemoveVisaStatus(visaStatus)} style={{ fontSize: '18px' }} />
        </Tag>
      ))}
    </div>
  );
}

function SearchCriteria(props) {
  const {
    jobId,
    isManualSearchFormMinimized,
    form,
    defaultFormValues,
    currentUserDetails,
    booleanSearchStrings,
    fetchBooleanSearchStrings,
    candidateContext,
    sources,
    sourcesConfig,
    advancedSettings,
    defaultAdvancedSettings,
    setAdvancedSettings,
    sourceNamesSelected,
    setSelectedSourceNames,
    currentJobDetails,
    isNonInternalPortalForSegmentEnabled,
  } = props;

  const {
    Titles: defaultTitles = [],
    Skills: defaultSkills = {},
    VisaStatuses: defaultVisaStatuses = [],
    QueryString: defaultQueryString,
    ActiveSearchString: defaultActiveSearchString,
    IsExactTitleMatchRequired: defaultIsExactTitleMatchRequired,
  } = defaultFormValues;

  const onClearExpResumeWorkAuth = () => {
    const minExpereinceField = 'MinExperience';
    const maxExpereinceField = 'MaxExperience';
    const resumeLastUpdatedDuration = 'ResumeUpdateDurationQuantity';
    const resumeLastUpdatedType = 'ResumeUpdateDurationType';
    const workAuthorizationField = 'VisaStatuses';
    const updatedFieldsValue = {
      [minExpereinceField]: undefined,
      [maxExpereinceField]: undefined,
      [resumeLastUpdatedDuration]: 6,
      [resumeLastUpdatedType]: 'Months',
      [workAuthorizationField]: [],
    };
    form.setFieldsValue(updatedFieldsValue);
  };

  const clearAllButton = (
    <div className={styles.clearAllButton}>
      <ClearAllButton onClear={onClearExpResumeWorkAuth} />
    </div>
  );
  return (
    <div className={styles.searchCriteriaWrapper}>
      <TitlesSection
        form={form}
        defaultTitles={defaultTitles}
        defaultIsExactTitleMatchRequired={defaultIsExactTitleMatchRequired}
        isManualSearchFormMinimized={isManualSearchFormMinimized}
      />
      <SkillsSection
        jobId={jobId}
        booleanSearchStrings={booleanSearchStrings}
        fetchBooleanSearchStrings={fetchBooleanSearchStrings}
        form={form}
        defaultSkills={defaultSkills}
        defaultQueryString={defaultQueryString}
        defaultActiveSearchString={defaultActiveSearchString}
        isManualSearchFormMinimized={isManualSearchFormMinimized}
        currentUserDetails={currentUserDetails}
      />
      <EnhancedLocationSection
        form={form}
        defaultFormValues={defaultFormValues}
        candidateContext={candidateContext}
        isManualSearchFormMinimized={isManualSearchFormMinimized}
      />
      <Divider className={styles.workStatusDivider} />
      <div className={styles.experienceLastUpdatedWrapper}>
        <ExperienceSection form={form} defaultFormValues={defaultFormValues} />
        <ResumeLastUpdatedSection form={form} defaultFormValues={defaultFormValues} />
        <div className={styles.workStatusSection}>
          <div>
            <FormattedMessage {...message.workAuthorizationHeader} />
          </div>
          <div className={styles.selectWorkAuthorization}>
            <Form.Item>
              {form.getFieldDecorator('VisaStatuses', {
                initialValue: defaultVisaStatuses,
              })(
                <Select
                  placeholder={<FormattedMessage {...message.selectWorkAuthorizationLabel} />}
                  mode="multiple"
                  className={styles.selectDropdown}
                  allowClear
                  showArrow
                >
                  {GlobalWorkStatusOptions.map(({ value, label, supportedBy }) => (
                    <Select.Option key={value} value={value} style={{ whiteSpace: 'pre-wrap' }}>
                      {label}
                      <br />
                      <Typography.Text type="secondary">{getSupportedSourcesDisplayText(supportedBy)}</Typography.Text>
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </div>
        </div>
        {clearAllButton}
      </div>
      <div className={styles.searchCriteriaManualSearchSources}>
        <ManualSearchSources
          form={form}
          sources={sources}
          sourcesConfig={sourcesConfig}
          advancedSettings={advancedSettings}
          defaultAdvancedSettings={defaultAdvancedSettings}
          setAdvancedSettings={setAdvancedSettings}
          sourceNamesSelected={sourceNamesSelected}
          setSelectedSourceNames={setSelectedSourceNames}
          currentJobDetails={currentJobDetails}
          jobId={jobId}
          candidateContext={candidateContext}
          isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
        />
      </div>
      <div>
        {candidateContext === 'segment' && (
          <ExcludeCandidateSection
            form={form}
            defaultFormValues={defaultFormValues}
            jobId={jobId}
            setAdvancedSettings={setAdvancedSettings}
            advancedSettings={advancedSettings}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(SearchCriteria);
