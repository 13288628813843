import { FormattedMessage, injectIntl } from 'react-intl';
import { Affix, Button, Col, Dropdown, Form, Input, Menu, Row, Select, Checkbox, Divider } from 'antd';
import { debounce } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import PaymentJobAd from '../../../Containers/PaymentJobAd/PaymentJobAd';
import { selectComponent } from '../../../Utils/JobDistributionUtils/JobDistributionFormUtils';
import {
  AdCredits,
  CreditCard,
  getDistributionJobDetails,
  validateDescription,
} from '../../../Utils/JobDistributionUtils/JobDistributionUtils';
import { getLocaleDateString } from '../../../Utils/RelativeTimeCalculator';
import AdvertisementPaymentMethod from '../AdvertisementPayment/AdvertisementPaymentMethod';
import JobDescriptionContainer from '../../../Containers/JobDescriptionContainer/JobDescriptionContainer';
import styles from './AryaAdvertisementForm.module.scss';
import eventTypes from '../../../Analytics/EventTypes';
import { JOBBOARDFORPUBLISH } from '../../../Utils/JobBoardUtils';
import placeholder from '../../Placeholders/PlaceholdersMessages';

export const validateConfirmationCheck = (rule, value, callback) => {
  if (value) {
    callback();
    return;
  }
  callback('Confirmation is required');
};

function AryaAdvertisementForm(props) {
  const {
    form,
    onSubmit,
    onCancel,
    jobDetails,
    isPublished,
    isDrafted,
    distributedJobInfo,
    filterLocations,
    locationOptions,
    locationDropDownStatus,
    distributeLoading,
    availableAdCredits,
    portalTariff,
    zipCodeProps,
    productVariant,
    productVariantId,
    setPaymentScreen,
    AppName,
    intl,
  } = props;
  const { zipCodes, clearZipValidationError, searchZipCode, isZipCodeInValid, fetchZipCodes, clearZipCodeError } =
    zipCodeProps;

  const [initialValues, setInitialValues] = React.useState(getDistributionJobDetails(jobDetails, distributedJobInfo));
  const [paymentPopupVisible, setPaymentPopupVisible] = React.useState(false);
  const [zipCodeOptions, setZipCodeOptions] = React.useState(zipCodes ?? []);
  const [paymentPageVisible, setPaymentPageVisible] = React.useState(false);

  React.useEffect(() => {
    setZipCodeOptions(zipCodes);
  }, [zipCodes]);

  React.useEffect(() => {
    if (initialValues.Location) {
      fetchZipCodes({ country: jobDetails.CountryCode, location: initialValues.Location });
    }
  }, []);

  React.useEffect(() => {
    setPaymentScreen(paymentPageVisible);
  }, [paymentPageVisible]);

  React.useEffect(() => {
    setInitialValues(getDistributionJobDetails(jobDetails, distributedJobInfo));
  }, [JSON.stringify(distributedJobInfo)]);

  const validateZipCode = code => {
    if (!code.length || zipCodeOptions.filter(zipcodes => zipcodes.includes(code)).length) {
      clearZipValidationError();
    }
    if (code.trim().length && !zipCodeOptions.filter(zipcodes => zipcodes.includes(code).length)) {
      searchZipCode({
        postalCode: code,
        countryCode: form.getFieldValue('Country'),
      });
    }
  };

  const zipcodeValidStatus = isZipCodeInValid && !(zipCodeOptions || []).includes(form.getFieldValue('Zipcode'));

  const debouncedValidateZipCode = React.useCallback(
    debounce(nextValue => validateZipCode(nextValue), 600),
    []
  );

  const zipCodeMenuList = () => {
    if (!zipCodeOptions?.length) return <div />;
    const filteredCodes = form.getFieldValue('Zipcode')
      ? zipCodeOptions.filter(zipcodes => zipcodes.includes(form.getFieldValue('Zipcode')))
      : zipCodeOptions;
    return (
      <Menu>
        {filteredCodes.map(zipcode => (
          <Menu.Item
            key={zipcode}
            onClick={() => {
              form.setFieldsValue({ Zipcode: zipcode });
              debouncedValidateZipCode(zipcode);
            }}
          >
            {zipcode}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const callForZipCodes = value => {
    const country = form.getFieldValue('CountryCode');
    const location = form.getFieldValue('Location');
    if (location !== value) {
      form.setFieldsValue({
        Zipcode: undefined,
      });
    }
    fetchZipCodes({
      country,
      location,
    });
  };

  const locationChange = () => {
    form.setFieldsValue({
      Zipcode: undefined,
    });
    clearZipCodeError();
  };

  const handleSubmit = (isDraft = false, isConfirmationCheckRequired = false) => {
    setPaymentPageVisible(false);
    if (isConfirmationCheckRequired) form.setFieldsValue({ Confirmation: true });
    form.validateFieldsAndScroll({ scroll: { offsetTop: 150, offsetBottom: 150 } }, (err, values) => {
      if (!err) {
        const location = values.Location?.split(',');
        const distributionRequest = {
          PostingLocation: {
            Location: values.Location,
            City: location?.[0]?.trim(),
            State: location?.[1]?.trim(),
            CountryCode: jobDetails.CountryCode,
            ZipCode: values.Zipcode,
          },
          JobTitle: jobDetails.JobTitle,
          JobDescription: values.JobDescription.htmlContent,
          CompanyContact: {
            CompanyName: values.CompanyName,
          },
          IsDraft: isDraft,
        };
        let successMessage = `Congratulations! Your Intelligent Job Advertising campaign has been launched. Review the 'Job Info' tab for results.`;
        if (isPublished)
          successMessage = `Congratulations! Your Intelligent Job Advertising campaign has been updated. Review the 'Job Info' tab for results.`;
        onSubmit(distributionRequest, JOBBOARDFORPUBLISH, successMessage);
      }
    });
  };

  const handleAdvertise = () => {
    if (isPublished) handleSubmit();
    else {
      handleSubmit(true);
      form.validateFieldsAndScroll((err, values) => {
        if (!err && values.JobDescription.htmlContent.length > 199) {
          setPaymentPopupVisible(true);
        }
      });
    }
  };

  const handleAdvertiseCallback = () => {
    handleSubmit(false, true);
  };

  const handlePaymentPopupConfirm = paymentMethod => {
    setPaymentPopupVisible(false);
    if (paymentMethod === CreditCard) {
      setPaymentPageVisible(true);
    }
    if (paymentMethod === AdCredits) handleSubmit();
  };

  const getFooterText = () => {
    if (isPublished) {
      return (
        <div>
          &quot;Once you click &apos;Update&apos;, all details of the existing advertising campaign will be
          overridden.&quot;
        </div>
      );
    }
    return (
      <Form.Item colon={false}>
        {form.getFieldDecorator('Confirmation', {
          rules: [
            {
              required: true,
              validator: validateConfirmationCheck,
            },
          ],
          valuePropName: 'checked',
        })(
          <Checkbox>
            Once you click &apos;Advertise Job&apos; we will begin intelligently distributing it to industry job boards
            to drive candidate applications.
          </Checkbox>
        )}
      </Form.Item>
    );
  };

  const getFooter = () => {
    return ReactDOM.createPortal(
      <Affix offsetBottom={0} className={styles.actionBtnsAffix}>
        <div className={styles.actionBtnsContainerWrapper}>
          <div className={styles.actionBtnsWrapper}>
            <div className={styles.footerTextContent}>{getFooterText()}</div>
            <div className={styles.actionBtnsContainer}>
              <Button
                key="cancel-publish"
                onClick={onCancel}
                sk-event-name={
                  isPublished
                    ? eventTypes.job.advertiseJob.cancelEditAdvertiseJob
                    : eventTypes.job.advertiseJob.cancelAdvertiseJob
                }
              >
                Cancel
              </Button>
              <Button
                key="submit-publish"
                type="primary"
                onClick={handleAdvertise}
                loading={distributeLoading}
                sk-event-name={
                  isPublished
                    ? eventTypes.job.advertiseJob.updateAdvertiseJob
                    : eventTypes.job.advertiseJob.submitAdvertiseJob
                }
              >
                {isPublished ? 'Update' : 'Advertise Job'}
              </Button>
            </div>
          </div>
        </div>
      </Affix>,
      document.getElementById('body-content')
    );
  };

  if (paymentPageVisible) {
    return (
      <PaymentJobAd
        productVariantId={productVariantId}
        productVariant={productVariant}
        advertiseCallback={handleAdvertiseCallback}
        backNavigation={() => setPaymentPageVisible(false)}
        quantity={1}
        placeOrderButtonText="Advertise Now"
      />
    );
  }

  return (
    <div className={styles.jobPublishForm}>
      <AdvertisementPaymentMethod
        visible={paymentPopupVisible}
        validationLoading={!isDrafted}
        onCancel={() => setPaymentPopupVisible(false)}
        onConfirm={handlePaymentPopupConfirm}
        requiredCredits={portalTariff?.Credits}
        availableCredits={availableAdCredits}
        AppName={AppName}
      />

      <div className={styles.jobPublishHeader}>
        <Row gutter={16}>
          {isPublished ? (
            <Col>
              <div className={styles.publishStats}>
                Last Modified on: {getLocaleDateString(distributedJobInfo.ModifiedDateTime)}
                <Divider type="vertical" />
                Published on: {getLocaleDateString(distributedJobInfo.DistributeInitiatedDateTime)}
              </div>
            </Col>
          ) : null}
        </Row>
      </div>
      <div className={styles.publishJobTitle}>{initialValues?.JobTitle}</div>

      <Form.Item label="Company" colon={false}>
        {form.getFieldDecorator('CompanyName', {
          rules: [
            {
              required: true,
              message: 'Please input company name',
            },
          ],
          initialValue: initialValues?.CompanyName,
        })(
          <Input
            key="CompanyName"
            placeholder={intl.formatMessage({ ...placeholder.enterCompanyNameLabel })}
            className={styles.inputComponent}
          />
        )}
      </Form.Item>

      <Row gutter={16}>
        <Col span={8}>
          {selectComponent(form, 'Country', 'CountryCode', jobDetails.CountryCode, 'Add Country', [], null, true)}
        </Col>
        <Col span={8}>
          <Form.Item label="City/State" colon={false}>
            {form.getFieldDecorator('Location', {
              rules: [
                {
                  required: true,
                  message: 'Please input location',
                },
              ],
              initialValue: initialValues?.Location,
            })(
              <Select
                allowClear
                key="Location"
                showSearch
                placeholder={intl.formatMessage({ ...placeholder.city_StateLabel })}
                notFoundContent={locationDropDownStatus}
                onSearch={filterLocations}
                onSelect={callForZipCodes}
                onChange={locationChange}
                showArrow={false}
              >
                {locationOptions}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Postal Code"
            colon={false}
            validateStatus={zipcodeValidStatus ? 'error' : undefined}
            help={zipcodeValidStatus ? 'Invalid Postal Code' : undefined}
            className={styles.zipCode}
          >
            <Dropdown
              overlay={zipCodeMenuList}
              trigger={['click']}
              overlayClassName="zipcode-dropdown"
              disabled={!form.getFieldValue('Location') || !zipCodeOptions.length}
            >
              {form.getFieldDecorator('Zipcode', {
                rules: [{ required: true, message: 'Please input Postal Code' }],
                initialValue: initialValues?.Zipcode,
              })(
                <Input
                  key="ZipCode"
                  placeholder={intl.formatMessage({ ...placeholder.postalCodeLabel })}
                  allowClear
                  autoComplete="disabled"
                  onChange={event => debouncedValidateZipCode(event.target.value)}
                />
              )}
            </Dropdown>
          </Form.Item>
        </Col>
      </Row>
      <JobDescriptionContainer
        jobForm={form}
        formProperties={{
          fieldDecorator: 'JobDescription',
          fieldLabel: 'Description',
          fieldColon: false,
          validator: validateDescription,
          initialValue: initialValues?.JobDescription,
          isRequired: true,
        }}
        isRecommendationNotRequired
      />
      {getFooter()}
    </div>
  );
}

export default injectIntl(AryaAdvertisementForm);
export { AryaAdvertisementForm as AryaAdvertisementFormWithoutInjectIntl };
