/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import CKEditor from 'ckeditor4-react';
import { Popover } from 'antd';
import './RichTextEditor.scss';
import message from '../../Containers/Connect/ConnectMessages';

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  addTags = tag => {
    if (tag.IsUrl) {
      window.CKEDITOR.instances.editor1.insertHtml(`<a href={{${tag.Key}}}> click here </a>`);
    } else {
      window.CKEDITOR.instances.editor1.insertHtml(`{{${tag.Key}}}`);
    }
  };

  popoverContent = mergeTags => {
    return (
      <div className="tags-popover-content">
        {mergeTags.map(mergeTag => (
          <div
            className="merge-tag"
            onClick={() => {
              this.addTags(mergeTag);
            }}
            key={mergeTag.Key}
            role="presentation"
          >
            + {mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );
  };

  onChange(evt) {
    const { onChange } = this.props;
    const newContent = evt.editor.getData();
    onChange(newContent);
  }

  render() {
    const { content, mergeTags } = this.props;
    return (
      <>
        <div className="ckeditor-merge-tag-wrapper">
          <span className="ql-formats">
            <button className="ckeditor-merge-tag-button">
              <Popover content={this.popoverContent(mergeTags)} placement="left" trigger="click">
                <span className="merge-tags-text"> <FormattedMessage {...message.mergeTagsLabel} /></span>
              </Popover>
            </button>
          </span>
        </div>
        <CKEditor
          activeClass="p10"
          data={content}
          config={{
            toolbar: [
              {
                name: 'basicstyles',
                items: [
                  'Bold',
                  'Italic',
                  'Underline',
                  'Strike',
                  'Blockquote',
                  'NumberedList',
                  'BulletedList',
                  'JustifyBlock',
                  'JustifyCenter',
                  'JustifyLeft',
                  'JustifyRight',
                  'Outdent',
                  'Indent',
                  'Link',
                  'Image',
                  'imageExplorer',
                  'RemoveFormat',
                  'Source',
                ],
              },
            ],
            removeButtons: 'Subscript,Superscript',
            allowedContent: true,
            height: '500px',
            removePlugins: 'elementspath',
            extraPlugins: 'justify',
            resize_enabled: false,
          }}
          onChange={this.onChange}
        />
      </>
    );
  }
}
export default RichTextEditor;
