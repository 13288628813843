import React from 'react';
import CandidateCardPersonalInfo from './CandidateCardPersonalInfo';
import NewCandidateShortlistReject from './NewCandidateShortlistReject/NewCandidateShortlistReject';
import styles from './CandidatePersonalInfoWithShortlistReject.module.scss';
import CandidateCardContext from '../../CompoundComponents/CandidateCardWrapper/CandidateCardContext';
import CandidateCardConnectOptions from './CandidateCardConnectOptions';
import FavouriteCandidateStarIcon from '../../Utils/FavouriteCandidateStarIcon';
import { getCandidatePublishStatus } from '../../Utils/CandidatePublishUtils';

function CandidatePersonalInfoWithShortlistReject(props) {
  const {
    candidate = {},
    isPaidJobServiceEnabled,
    activeTab,
    featureToggleList,
    onCandidateStatusChange,
    candidateContext,
    isShortlistIconEnabled,
    candidateStatusUpdate,
    size,
    onCandidateReject,
    jobId,
    candidateId,
    userConfig,
    whiteLabelInfo,
    candidateType,
    updateCandidateBookmarkStatus,
    candidateBookmarkApiStatus,
    version,
    isCandidateViewIconVisible,
    openCandidateView,
    isCandidateGroupHead,
    duplicateCandidateIds,
    indexKey,
    connectStatusLoaded,
    showCandidateDetail,
    setComposeEmailType,
    currentJobDetails,
    handleContactPull,
    showConnectWidget,
    setAddSubsegmentModalStatus,
    showExpandedCandidateCard,
  } = React.useContext(CandidateCardContext);

  const {
    candidateCardTertiaryContent,
    showCandidateQualificationSummary,
    isScoreVisible,
    recommendFavouriteCandidate,
    adminFeatures,
    favouriteCandidateApiStatus,
    removeFavouriteCandidate,
    removeFavouriteCandidateApiAtatus,
    defaultFilter,
    fetchCandidates,
    fetchJobSourcingStats,
    msp,
    mspIcons,
    setMspIcons,
    selectedSubSegmentId,
  } = props;
  const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList);

  const candidateStatus = candidate.Status;
  const isAddVisible =
    (candidateContext === 'job' && isShortlistIconEnabled) ||
    (candidateContext === 'segment' &&
      (!candidateStatus ||
        candidateStatus?.toLowerCase() === 'rejected' ||
        candidateStatus?.toLowerCase() === 'sourced'));

  const isDeleteVisible =
    candidateContext === 'job' ||
    (candidateContext === 'segment' &&
      (!candidateStatus ||
        candidateStatus?.toLowerCase() === 'shortlisted' ||
        candidateStatus?.toLowerCase() === 'sourced'));

  const candidateCardConnectOptions = (
    <CandidateCardConnectOptions
      featureToggleList={featureToggleList}
      connectStatusLoaded={connectStatusLoaded}
      showCandidateDetail={showCandidateDetail}
      setComposeEmailType={setComposeEmailType}
      candidate={candidate}
      indexKey={indexKey}
      currentJobDetails={currentJobDetails}
      handleContactPull={handleContactPull}
      showConnectWidget={showConnectWidget}
      candidateContext={candidateContext}
      isCandidateViewIconVisible={isCandidateViewIconVisible}
    />
  );

  return (
    <div className={styles.personalInfoShortlistWrapper}>
      <CandidateCardPersonalInfo
        candidate={candidate}
        userConfig={userConfig}
        whiteLabelInfo={whiteLabelInfo}
        featureToggleList={featureToggleList}
        candidateContext={candidateContext}
        candidateType={candidateType}
        updateCandidateBookmarkStatus={updateCandidateBookmarkStatus}
        jobId={jobId}
        candidateBookmarkApiStatus={candidateBookmarkApiStatus}
        version={version}
        isCandidateViewIconVisible={isCandidateViewIconVisible}
        openCandidateView={openCandidateView}
        isCandidateGroupHead={isCandidateGroupHead}
        duplicateCandidateIds={duplicateCandidateIds}
        showExpandedCandidateCard={showExpandedCandidateCard}
        adminFeatures={adminFeatures}
        msp={msp}
        activeTab={activeTab}
      />
      <div>
        {!isCandidatePublishEnabled ? (
          <NewCandidateShortlistReject
            isPaidJobServiceEnabled={isPaidJobServiceEnabled}
            activeTab={activeTab}
            featureToggleList={featureToggleList}
            onCandidateStatusChange={onCandidateStatusChange}
            candidateContext={candidateContext}
            isShortlistIconEnabled={isShortlistIconEnabled}
            candidateStatus={candidateStatus}
            candidateStatusUpdate={candidateStatusUpdate}
            candidate={candidate}
            size={size}
            onCandidateReject={onCandidateReject}
            jobId={jobId}
            candidateId={candidateId}
            isAddVisible={isAddVisible}
            isDeleteVisible={isDeleteVisible}
            candidateCardTertiaryContent={candidateCardTertiaryContent}
            showCandidateQualificationSummary={showCandidateQualificationSummary}
            isScoreVisible={isScoreVisible}
            version={version}
            msp={msp}
            mspIcons={mspIcons}
            setMspIcons={setMspIcons}
            showExpandedCandidateCard={showExpandedCandidateCard}
            setAddSubsegmentModalStatus={setAddSubsegmentModalStatus}
            selectedSubSegmentId={selectedSubSegmentId}
          />
        ) : (
          <FavouriteCandidateStarIcon
            candidate={candidate}
            recommendFavouriteCandidate={recommendFavouriteCandidate}
            userConfig={userConfig}
            activeTab={activeTab}
            jobId={jobId}
            favouriteCandidateApiStatus={favouriteCandidateApiStatus}
            removeFavouriteCandidate={removeFavouriteCandidate}
            removeFavouriteCandidateApiAtatus={removeFavouriteCandidateApiAtatus}
            defaultFilter={defaultFilter}
            fetchCandidates={fetchCandidates}
            fetchJobSourcingStats={fetchJobSourcingStats}
          />
        )}
        {!showExpandedCandidateCard ? candidateCardConnectOptions : null}
      </div>
    </div>
  );
}

export default CandidatePersonalInfoWithShortlistReject;
