/* eslint-disable max-classes-per-file */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactQuill from 'react-quill';
import uuid from 'uuid';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import { Spin } from 'antd';
import 'react-quill/dist/quill.snow.css';
import CustomToolbar from './CustomToolBar';
import './RichTextEditor.scss';
import { supportedReactQuillFormats } from '../../Utils/ConnectUtils';
import message from '../../Containers/CandidateDrawer/messages';
import { clickSendTextMessageSpamWordsList } from '../Utils/SmsUtils';

export const updateEditorFontSize = (reactQuillRef, selectedSize) => {
  const quill = reactQuillRef.getEditor();
  quill.format('size', selectedSize);
};

const { Quill } = ReactQuill;
const Font = Quill.import('formats/font');
Font.whitelist = ['Ubuntu', 'Raleway', 'Roboto'];
Quill.register(Font, true);

const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

const Inline = Quill.import('blots/inline');
class RequirementBlot extends Inline { }
RequirementBlot.blotName = 'requirement';
RequirementBlot.tagName = 'requirement';
Quill.register(RequirementBlot);

class ResponsibilityBlot extends Inline { }
ResponsibilityBlot.blotName = 'responsibility';
ResponsibilityBlot.tagName = 'responsibility';
Quill.register(ResponsibilityBlot);

class IndentAttributor extends Quill.import('parchment').Attributor.Style {
  multiplier = 3;

  add(node, value) {
    node.setAttribute('class', 'ql-indent');
    node.setAttribute('style', 'margin-top:0px; margin-bottom:0px; padding:0px; border:none');
    return super.add(node, `${value * this.multiplier}em`);
  }

  value(node) {
    return parseFloat(super.value(node)) / this.multiplier || undefined;
  }
}

class RichTextEditor extends React.Component {
  modules = {
    toolbar: {
      container: '#toolbar',
    },
  };

  static getDerivedStateFromProps(nextProps) {
    if ('editorContent' in nextProps) {
      return {
        ...(nextProps.editorContent || {}),
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = { htmlContent: '', displayHtmlContent: false, fontSize: 'medium' };
    this.quillRef = null; // Quill instance
    this.reactQuillRef = null; // ReactQuill component
    this.handleChange = this.handleChange.bind(this);
    this.addTags = this.addTags.bind(this);
    this.attachQuillRefs = this.attachQuillRefs.bind(this);
    this.toolbarId = `toolbar-${uuid()}`;
    this.modules.toolbar.container = `#${this.toolbarId}`;
    this.handleUserGivenHtmlContent = this.handleUserGivenHtmlContent.bind(this);
    this.toggleComposePreview = this.toggleComposePreview.bind(this);
    this.syncViews = this.syncViews.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  componentDidMount() {
    this.attachQuillRefs();
    this.createCustomIndent();
    document.querySelectorAll('.ql-container').forEach(node => {
      node.addEventListener('mousedown', this.handleMouseDown);
    });
  }

  componentWillUnmount() {
    document.querySelectorAll('.ql-container').forEach(node => {
      node.removeEventListener('mousedown', this.handleMouseDown);
    });
  }

  handleMouseDown = event => {
    event.stopPropagation();
  };

  createCustomIndent = () => {
    const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const multiplier = 3;
    const indentStyle = new IndentAttributor('indent', 'margin-left', {
      scope: Quill.import('parchment').Scope.BLOCK,
      whitelist: levels.map(value => `${value * multiplier}em`),
    });
    Quill.register(indentStyle, true);
  };

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  attachQuillRefs = () => {
    if (!this.reactQuillRef) return;
    if (typeof this.reactQuillRef.getEditor !== 'function') return;
    this.quillRef = this.reactQuillRef.getEditor();
  };

  addTags(tag, enableHyperlink = true) {
    const selection = this.quillRef.getSelection(true);
    if (enableHyperlink && tag.IsUrl) {
      const selectedText = this.quillRef.getText(selection.index, selection.length);
      const linkText = selectedText || 'click here';
      this.quillRef.insertText(selection.index, linkText, 'link', `{{${tag.Key}}}`);
      this.quillRef.deleteText(selection.index + selection.length, selection.length);
    } else {
      this.quillRef.insertText(selection.index, `{{${tag.Key}}}`);
    }
  }

  handleChange(htmlContent) {
    const { onChange, disabled } = this.props;
    if (this.quillRef && onChange && !disabled) {
      const plainTextContent = _.trimEnd(this.quillRef.getText(), '\n');
      this.setState(
        {
          htmlContent,
          // eslint-disable-next-line react/no-unused-state
          plainTextContent,
        },
        onChange({ htmlContent, plainTextContent })
      );
    }
  }

  handleOnBlur(previousRange, source, editor) {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(previousRange, source, editor);
    }
  }

  handleUserGivenHtmlContent(event) {
    const userGivenHtml = _.get(event, ['target', 'value'], '');
    this.setState({
      userGivenHtml,
    });
  }

  toggleComposePreview() {
    const { displayHtmlContent } = this.state;
    const { setHtmlToolActiveStatus } = this.props;
    setHtmlToolActiveStatus(!displayHtmlContent);
    this.setState({ displayHtmlContent: !displayHtmlContent });
    this.syncViews(displayHtmlContent);
  }

  syncViews(fromUserGiven) {
    const { userGivenHtml, htmlContent } = this.state;
    if (fromUserGiven) {
      const cleanHtmlContent = DOMPurify.sanitize(userGivenHtml, { FORBID_TAGS: ['script'], KEEP_CONTENT: false });
      this.setState({ userGivenHtml: cleanHtmlContent });
      this.handleChange(cleanHtmlContent);
    } else {
      this.setState({ userGivenHtml: htmlContent });
    }
  }

  handleFontSizeChange = selectedSize => {
    this.setState({
      fontSize: selectedSize,
    });
    updateEditorFontSize(this.reactQuillRef, selectedSize);
  };

  render() {
    const {
      className,
      readOnly,
      mergeTags,
      showToolbar,
      placeholder,
      onlyMergeTags,
      disabled,
      imageSupport,
      sourceCodeSupport,
      children,
      textEditorContentVisibility,
      isChatGptContentGenerated,
      type,
      footerButtons,
      restrictedReactQuillFormats = [],
      enableGenerationThroughChatGpt,
      isCandidate360MailWindow,
      showSubjectLabel,
      isEmailTemplateCreate,
      mailRef = {},
      isSubmitButtonEnabled = true,
      isEmailPanel,
      isDripEmail,
      isCreateDripTemplate,
    } = this.props;
    const { htmlContent, userGivenHtml, displayHtmlContent, fontSize } = this.state;
    const allowedReactQuillformats = supportedReactQuillFormats.filter(
      format => !restrictedReactQuillFormats.includes(format)
    );

    if (readOnly) {
      this.modules.toolbar = false;
    }
    if (!showToolbar) {
      this.modules.toolbar = false;
    }
    if (this.reactQuillRef && this.reactQuillRef.getEditor()) this.reactQuillRef.getEditor().enable(!disabled);
    const reactQuillCustomProps = isSubmitButtonEnabled ? { value: htmlContent || '' } : null;
    return (
      <div className={`rich-text-editor ${disabled ? 'disabled' : ''}`}>
        {showToolbar ? (
          <CustomToolbar
            onlyMergeTags={onlyMergeTags || false}
            mergeTags={mergeTags}
            addTags={this.addTags}
            id={this.toolbarId}
            imageSupport={imageSupport}
            sourceCodeSupport={sourceCodeSupport}
            isHtmlToolActive={displayHtmlContent}
            toggleComposePreview={this.toggleComposePreview}
            isChatGptContentGenerated={isChatGptContentGenerated}
            handleFontSizeChange={this.handleFontSizeChange}
            fontSize={fontSize}
            enableGenerationThroughChatGpt={enableGenerationThroughChatGpt}
            isCandidate360MailWindow={isCandidate360MailWindow}
            mailRef={mailRef}
            isEmailPanel={isEmailPanel}
            isEmailTemplateCreate={isEmailTemplateCreate}
            isDripEmail={isDripEmail}
            isCreateDripTemplate={isCreateDripTemplate}
          />
        ) : null}
        {children}
        <div className={`rich-text-editor-${!displayHtmlContent}`}>
          <Spin tip="ChatGPT is working for you..." spinning={type === 'body' && !textEditorContentVisibility}>
            <div className={`${showSubjectLabel && 'subject-container'}`}>
              {showSubjectLabel && (
                <div className="subject-label">
                  <FormattedMessage {...message.subjectLabel} />:{' '}
                </div>
              )}
              <ReactQuill
                readOnly={disabled || false}
                ref={el => {
                  this.reactQuillRef = el;
                }}
                onChange={this.handleChange}
                onBlur={this.handleOnBlur}
                modules={this.modules}
                theme="snow"
                className={className}
                placeholder={placeholder}
                formats={allowedReactQuillformats}
                {...reactQuillCustomProps}
              />
            </div>
            {footerButtons}
          </Spin>
        </div>

        {displayHtmlContent ? (
          <textarea
            className={`source-input source-input-${displayHtmlContent}`}
            onChange={this.handleUserGivenHtmlContent}
            value={userGivenHtml}
          />
        ) : null}
      </div>
    );
  }
}

export default RichTextEditor;
