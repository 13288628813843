import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Menu } from 'antd';
import MoreMenuButton from '../../../../../../../Common/MoreMenuButton/MoreMenuButton';
import { getEventNameByReportType } from '../../../../../../../../Analytics/Report/ReportEventUtils';
import styles from './ListItemMoreMenuButton.module.scss';
import reportPortalMessages from '../../../../../../ReportPortalMessages';

export default function ListItemMoreMenuButton(props) {
  const { onEdit, onDelete, onShare, type } = props;
  const moreMenu = (
    <Menu>
      <Menu.Item key="edit" onClick={onEdit}>
        <div
          className={styles.listItemMenuOption}
          role="presentation"
          sk-event-name={getEventNameByReportType(type, 'editQuery')}
        >
          <div className={styles.listItemMenuOptionText} sk-event-name={getEventNameByReportType(type, 'editQuery')}>
            <FormattedMessage {...reportPortalMessages.listItemMenuButtonEdit} />
          </div>
        </div>
      </Menu.Item>
      <Menu.Item key="delete" onClick={onDelete}>
        <div
          className={styles.listItemMenuOption}
          role="presentation"
          sk-event-name={getEventNameByReportType(type, 'deleteQuery')}
        >
          <div className={styles.listItemMenuOptionText} sk-event-name={getEventNameByReportType(type, 'deleteQuery')}>
            <FormattedMessage {...reportPortalMessages.listItemMenuButtonDelete} />
          </div>
        </div>
      </Menu.Item>
      <Menu.Item key="share" onClick={onShare}>
        <div
          className={styles.listItemMenuOption}
          role="presentation"
          sk-event-name={getEventNameByReportType(type, 'shareQuery')}
        >
          <div className={styles.listItemMenuOptionText} sk-event-name={getEventNameByReportType(type, 'shareQuery')}>
            <FormattedMessage {...reportPortalMessages.listItemMenuButtonShare} />
          </div>
        </div>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="more-wrapper">
      <Dropdown overlay={moreMenu} placement="bottomRight">
        <div className="interview-header-menu">
          <MoreMenuButton size="small" />
        </div>
      </Dropdown>
    </div>
  );
}
