import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import _ from 'lodash';
import { Select, Tooltip } from 'antd';
import * as UserActions from '../../Actions/UserActions';
import { getOrganizations } from '../../Reducers/OrganizationsReducer';
import { getOrganizations as fetchOrgs } from '../../Actions/OrganizationActions';
import './OrganizationSelect.scss';
import placeholder from '../Placeholders/PlaceholdersMessages';

const { Option } = Select;

const mapStateToProps = state => ({
  orgsInfo: getOrganizations(state),
});

const mapDispatchToProps = {
  fetchOrganizations: fetchOrgs,
  fetchUsersForRecruiterSelect: UserActions.fetchUsersForDropdownLists,
};

class OrganizationSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LastFetchedPage: 1,
    };
    this.onSearch = this.onSearch.bind(this);
    this.fetchOrgs = debounce(this.fetchOrgs, 250);
    this.handleScroll = this.handleScroll.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resetToFirstPage = this.resetToFirstPage.bind(this);
  }

  componentDidMount() {
    const { fetchOrganizations } = this.props;
    fetchOrganizations(1, null);
  }

  fetchOrgs(page, SearchTerm) {
    const { fetchOrganizations } = this.props;
    const { LastFetchedPage } = this.state;
    if (LastFetchedPage !== page) {
      this.setState(
        {
          SearchTerm,
          LastFetchedPage: page,
        },
        fetchOrganizations(page, SearchTerm)
      );
    }
  }

  onChange(value) {
    const { fetchUsersForRecruiterSelect } = this.props;
    fetchUsersForRecruiterSelect({ page: 1, searchTerm: null, listFor: 'RecruiterSelect' }, value);
  }

  handleScroll(e) {
    const { orgsInfo } = this.props;
    const { SearchTerm } = this.state;
    e.persist();
    const loadedOrgsCount = _.get(orgsInfo, 'Organizations', []).length;
    const totalOrgs = orgsInfo.Total;
    const pageToFetch = loadedOrgsCount / 10 + 1;
    if (e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <= 250 && loadedOrgsCount < totalOrgs) {
      // scrollToEnd, do something!!!
      this.fetchOrgs(pageToFetch, SearchTerm);
    }
  }

  resetToFirstPage(SearchTerm) {
    this.setState(
      {
        LastFetchedPage: 0,
      },
      this.fetchOrgs(1, SearchTerm)
    );
  }

  onSearch(value) {
    if (value) {
      this.resetToFirstPage(value);
    }
  }

  render() {
    const { orgsInfo,intl } = this.props;
    return (
      <Select
        showSearch
        onChange={this.onChange}
        onSearch={this.onSearch}
        filterOption={false}
        allowClear
        onPopupScroll={this.handleScroll}
        placeholder={intl.formatMessage({ ...placeholder.selectOrganizationLabel})}
        // onSelect={fetchJobsByUser}
        className="recruiter-select"
        // dropdownClassName="recruiter-select-dropdown"
        onDropdownVisibleChange={visible => {
          if (visible === false) {
            this.resetToFirstPage();
          }
        }}
      >
        {_.get(orgsInfo, 'Organizations', []).map(o => (
          <Option value={o.Id} key={o.Id}>
            <Tooltip title={o.Id}>
              {o.Name} ({o.Id})
            </Tooltip>
          </Option>
        ))}
      </Select>
    );
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrganizationSelect));

export { OrganizationSelect };
