import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Radio, Input, Form } from 'antd';
import styles from './CandidateOptOutContent.module.scss';
import placeholder from '../Placeholders/PlaceholdersMessages';

const { TextArea } = Input;
const { Item } = Form;

function CandidateOptOutContent({ value, onChange, intl }) {
  const triggerOnChange = changedValue => {
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };

  const handleOptOutTypeChange = e => {
    triggerOnChange({
      CandidateOptOutType: e.target.value,
    });
  };

  const handleOptOutReasonChange = e => {
    triggerOnChange({
      CandidateOptOutReason: e.target.value,
    });
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };
  return (
    <div className={styles.candidateOptOutReasonContent}>
      <div><FormattedMessage {...placeholder.selectedOptOutReason}/></div>
      <Item>
        <Radio.Group value={value?.CandidateOptOutType} onChange={handleOptOutTypeChange}>
          <Radio style={radioStyle} value="Job">
            Job
          </Radio>
        </Radio.Group>
      </Item>
      <div><FormattedMessage {...placeholder.additionalDetailsForTheOptOut}/></div>
      <Item>
        <TextArea
          onChange={handleOptOutReasonChange}
          value={value?.CandidateOptOutReason}
          rows={4}
          placeholder={intl.formatMessage({ ...placeholder.whyCandidateChoseLabel })}
        />
      </Item>
    </div>
  );
}

export default injectIntl(CandidateOptOutContent);
export { CandidateOptOutContent as CandidateOptOutContentWithoutInjectIntl };
