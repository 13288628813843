import React from 'react';
import { Tabs, Row, Col, Skeleton, Pagination, Empty } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import TemplateHeader from '../../../Components/Connect/TemplateHeader/TemplateHeader';
import styles from './WorkflowListContainer.module.scss';
import UserWorkflowTemplateRow from '../../../Components/Connect/TemplateRow/UserWorkflowTemplateRow/UserWorkflowTemplateRow';
import * as WorkflowActions from '../../../Actions/WorkflowActions';
import getMergeTags from '../../../Actions/MergeTagsActions';
import * as UserActions from '../../../Actions/UserActions';
import * as ConnectActions from '../../../Actions/ConnectActions';
import * as WorkflowReducer from '../../../Reducers/WorkflowReducer';
import {
  getConnectUsersById,
  getUsersByGuId,
  getUserListForShareTemplate,
  getConnectMaxCount,
} from '../../../Reducers/UserReducer';
import * as MergeTagsReducer from '../../../Reducers/MergeTagsReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getCurrentUser } from '../../../Reducers/UserSessionReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import { EmptyWorkflowListIcon } from '../../../Icons/AryaIcons';
import connectTemplateMessage from '../ConnectTemplatesMessages';

const mapStateToProps = state => ({
  workflowTemplatesFetchApiStatus: WorkflowReducer.getWorkflowTemplatesFetchApiStatus(
    state,
    'getWorkflowsFetchApiStatus'
  ),
  currentWorkflowDripTemplateIds: WorkflowReducer.getCurrentWorkflowDripTemplateIds(state),
  workflowDripTemplatesById: WorkflowReducer.getWorkflowDripTemplates(state),
  workflowDripTemplatesCount: WorkflowReducer.getWorkflowDripTemplatesCount(state),
  userListForShareTemplate: getUserListForShareTemplate(state),
  connectUsersById: getConnectUsersById(state),
  usersByGuId: getUsersByGuId(state),
  maxCount: getConnectMaxCount(state),
  mergeTags: MergeTagsReducer.getMergeTags(state),
  testTemplateStatus: getApiStatus(state, 'testTemplateStatus'),
  getWorkflowDripTemplateStatus: getApiStatus(state, 'getWorkflowDripTemplateStatus'),
  CurrentUser: getCurrentUser(state),
  featureToggleList: getFeatureToggleList(state),
});

const mapDispatchToProps = {
  searchWorkflowTemplates: WorkflowActions.searchWorkflowTemplates,
  saveWorkflowTemplate: WorkflowActions.saveWorkflowTemplate,
  duplicateWorkflowTemplate: WorkflowActions.duplicateWorkflowTemplate,
  editWorkflowDripTemplate: WorkflowActions.editWorkflowDripTemplate,
  deleteWorkflowDripTemplate: WorkflowActions.deleteWorkflowDripTemplate,
  fetchMergeTags: getMergeTags,
  fetchUsersForShare: UserActions.fetchUsersForShareTemplate,
  shareWorkflowDripTemplate: WorkflowActions.shareWorkflowDripTemplate,
  setInvalidTemplateNotification: ConnectActions.setInvalidTemplateNotification,
};

const { TabPane } = Tabs;

const WorkflowListContainer = props => {
  const [state, setState] = React.useState({
    page: 1,
    pageSize: 10,
    searchQuery: '',
    isDraft: false,
  });
  const [activeTab, setActiveTab] = React.useState('active-workflows');
  const { page, pageSize, searchQuery, isDraft } = state;

  const {
    searchWorkflowTemplates,
    shareWorkflowDripTemplate,
    usersByGuId,
    userListForShareTemplate,
    maxCount,
    fetchUsersForShare,
    CurrentUser,
    connectUsersById,
    workflowDripTemplatesCount,
    currentWorkflowDripTemplateIds,
    workflowDripTemplatesById,
    workflowTemplatesFetchApiStatus,
    duplicateWorkflowTemplate,
    location,
  } = props;

  function searchTemplates() {
    searchWorkflowTemplates({
      page: page - 1,
      pageSize,
      searchTerm: searchQuery,
      isDraft,
    });
  }

  const columnLengthName = !isDraft ? 11 : 10;
  const columnLengthDate = !isDraft ? 8 : 9;
  React.useEffect(() => {
    searchTemplates();
  }, [state]);

  function onSearch(newSearchQuery) {
    setState({ ...state, searchQuery: newSearchQuery });
  }

  function onPageChange(currentPage, currentPageSize) {
    setState({ ...state, page: currentPage, pageSize: currentPageSize });
  }

  function onShowSizeChange(currentPage, currentPageSize) {
    setState({ ...state, page: currentPage, pageSize: currentPageSize });
  }

  function duplicateTemplate(templateId) {
    const filter = {
      page: 0,
      pageSize: 10,
      searchTerm: searchQuery,
      isDraft,
    };
    duplicateWorkflowTemplate(templateId, filter);
  }

  function onTabChange(key) {
    switch (key) {
      case 'active-workflows':
        setState({
          ...state,
          page: 1,
          pageSize: 10,
          isDraft: false,
        });
        setActiveTab('active-workflows');
        break;

      case 'draft-workflows':
        setState({
          ...state,
          page: 1,
          pageSize: 10,
          isDraft: true,
        });
        setActiveTab('draft-workflows');
        break;

      default:
        break;
    }
  }

  React.useEffect(() => {
    const saveAsDraft = localStorage.getItem('DraftSaved');
    if (saveAsDraft) {
      onTabChange('draft-workflows');
      setActiveTab('draft-workflows');
      localStorage.removeItem('DraftSaved');
    }
  }, []);

  const onSearchChange = event => {
    const searchTerm = event.target.value;
    if (!searchTerm) {
      setState({ ...state, searchQuery: searchTerm });
    }
  };

  const displayWorkflowsList = () => (
    <>
      {currentWorkflowDripTemplateIds.length === 0 ? (
        <Skeleton loading={workflowTemplatesFetchApiStatus !== 'Completed'}>
          <Empty
            description={
              <div>
                <span className={styles.emptyWorkflowsHeading}>
                  {isDraft ? (
                    <FormattedMessage {...connectTemplateMessage.noDraftWorkflowAvailable} />
                  ) : (
                    <FormattedMessage {...connectTemplateMessage.noActiveWorkflowAvailable} />
                  )}
                </span>
                <br />
                <span>
                  <FormattedMessage {...connectTemplateMessage.clickWorkFlowInfoLabel} />{' '}
                </span>
              </div>
            }
            style={{ marginTop: '20vh' }}
            image={<EmptyWorkflowListIcon style={{ fontSize: '60px' }} />}
          />
        </Skeleton>
      ) : (
        <div className={styles.workflowListItem}>
          <Row className={styles.workflowTemplateHeader}>
            <Col span={columnLengthName}>
              <FormattedMessage {...messages.workflowName} />
            </Col>
            <Col span={columnLengthDate}>
              <FormattedMessage {...connectTemplateMessage.updatedOnLabel} />{' '}
            </Col>
            {!isDraft ? (
              <Col span={4}>
                <FormattedMessage {...connectTemplateMessage.reportLabel} />
              </Col>
            ) : null}
          </Row>
          <Skeleton loading={workflowTemplatesFetchApiStatus !== 'Completed'}>
            {currentWorkflowDripTemplateIds?.map(templateId => {
              const workflow = workflowDripTemplatesById[templateId];
              const firstName = _.get(connectUsersById, [workflow?.CreatedBy, 'FirstName'], 'Firstname');
              const lastName = _.get(connectUsersById, [workflow?.CreatedBy, 'LastName'], 'Lastname');
              return (
                <UserWorkflowTemplateRow
                  key={workflow?.Id}
                  workflowId={workflow?.DefinitionId}
                  startActivityId={workflow?.StartActivityId}
                  title={workflow?.Name}
                  activites={workflow?.Activities}
                  connections={workflow?.Connections}
                  duplicateTemplate={duplicateTemplate}
                  IsPublic={false}
                  updatedTime={workflow?.CreatedAt}
                  isPublished={workflow?.IsPublished}
                  shareWorkflowDripTemplate={shareWorkflowDripTemplate}
                  sharedUsers={workflow?.sharedWith || []}
                  usersByGuId={usersByGuId}
                  userListForShareTemplate={userListForShareTemplate}
                  CurrentUser={CurrentUser}
                  fetchUsersForShare={fetchUsersForShare}
                  maxCount={maxCount}
                  isDraft={isDraft}
                  createdBy={`${firstName} ${lastName}`}
                  location={location}
                  columnLengthName={columnLengthName}
                  columnLengthDate={columnLengthDate}
                />
              );
            })}
            <Pagination
              showSizeChanger
              size="small"
              total={workflowDripTemplatesCount}
              onChange={onPageChange}
              onShowSizeChange={onShowSizeChange}
              hideOnSinglePage={workflowDripTemplatesCount <= 10}
              current={page}
              pageSize={pageSize}
            />
          </Skeleton>
        </div>
      )}
    </>
  );

  return (
    <div className={styles.dripWorkflowTemplates}>
      <TemplateHeader
        onSearch={value => onSearch(value)}
        onChange={event => onSearchChange(event)}
        placeholder="Search workflow"
        isWorkflow
        location={location}
      />
      <Tabs activeKey={activeTab} onChange={onTabChange}>
        <TabPane tab="Active Workflows" key="active-workflows">
          {displayWorkflowsList()}
        </TabPane>
        <TabPane tab="Draft Workflows" key="draft-workflows">
          {displayWorkflowsList()}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowListContainer);
export { WorkflowListContainer as WorkflowListContainerWithoutStore };
