import { defineMessages } from 'react-intl';
import reportPortalMessagesJsonEn from '../../Data/En_Us/ReportPortalMessageEn_Us.json';
import reportPortalMessagesJsonEs from '../../Data/Es_Es/ReportPortalMessageEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'ReportPortal';
const transformedMessage = formatMessagesWithComponentPrefix(reportPortalMessagesJsonEn, component);
const reportPortalMessages = defineMessages(transformedMessage);

export const reportPortalMessagesEn = formatJsonWithComponentPrefix(reportPortalMessagesJsonEn, component);

export const reportPortalMessagesEs = formatJsonWithComponentPrefix(reportPortalMessagesJsonEs, component);

export default reportPortalMessages;
