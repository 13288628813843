import React from 'react';
import { FormattedMessage } from 'react-intl';
import UserAlerts from '../UserAlerts/UserAlerts';
import './PageNotAllowed.scss';
import jobMessage from '../JobForm/JobMessages';

export default function PageNotAllowed(props) {
  const { page } = props;
  let imageFileName;
  if (page === 'quick-search') {
    imageFileName = 'background-quick-search.jpg';
  } else if (page === 'connect') {
    imageFileName = 'background-global-connect.jpg';
  }
  return (
    <div className="page-not-allowed">
      <img
        src={`${process.env.PUBLIC_URL}/static/Images/${imageFileName}`}
        alt={`${page} Disabled`}
        className="background-dummy-image"
      />
      <div className="user-alert-wrapper">
        <UserAlerts
          header="Pro feature alert!"
          content={
            <span>
              <FormattedMessage {...jobMessage.featureNotEnabledInfoLabel} />{' '}
              <a href="mailto:support@leoforce.com">support@leoforce.com</a>{' '}
              <FormattedMessage {...jobMessage.upgradeYourPlanLabel} />
            </span>
          }
        />
      </div>
    </div>
  );
}
