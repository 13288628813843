import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon, Button, Badge, Tooltip } from 'antd';
import RecallJobDistribution from '../RecallJobDistribution/RecallJobDistribution';
import { JobBoardPortals, GetJobBoardIcon } from '../../../Utils/JobDistributionUtils/JobDistributionUtils';
import styles from './JobBoardPublishComponent.module.scss';
import { isExpiredDateTime } from '../../../Utils/RelativeTimeCalculator';
import eventTypes from '../../../Analytics/EventTypes';
import jobMessage from '../../JobForm/JobMessages';

export default function JobBoardPublishComponent(props) {
  const {
    onPublishPortal,
    portals,
    currentUser,
    distributedJobInfo,
    distributedPortals,
    recalledPortals,
    recallPortalJobDistribution,
    aryaJobStatus,
  } = props;

  const [recallVisible, setRecallVisible] = React.useState(false);

  const isPublished = distributedPortals?.find(p => p.toLowerCase() === JobBoardPortals[0]);
  const isRecalled =
    recalledPortals?.find(p => p.toLowerCase() === JobBoardPortals[0]) ||
    isExpiredDateTime(distributedJobInfo.ExpiryDateTime);
  const isPublishClosed = !isPublished && aryaJobStatus === 'Closed';

  const hasEditAccess =
    currentUser?.Id === distributedJobInfo.CreatedBy || currentUser?.Id === distributedJobInfo.UpdatedBy;

  const editButton = () =>
    hasEditAccess ? (
      <div
        className={styles.editButton}
        role="presentation"
        onClick={() => onPublishPortal(portals[0])}
        sk-event-name={eventTypes.job.publishJob.editPublishJob}
      >
        <Icon type="edit" theme="outlined" style={{ color: '#13c26b' }} />{' '}
        <FormattedMessage {...jobMessage.editLabel} />
      </div>
    ) : null;

  const postButton = () => {
    const postBtn = (
      <Button
        type="secondary"
        size="small"
        shape="round"
        onClick={() => onPublishPortal(portals[0])}
        disabled={isPublishClosed || isRecalled}
        sk-event-name={eventTypes.job.publishJob.goToPublishJob}
      >
        <FormattedMessage {...jobMessage.postLabel} />
      </Button>
    );
    if (isRecalled)
      return <Tooltip title={<FormattedMessage {...jobMessage.jobExpiredOrClosedInfo} />}>{postBtn}</Tooltip>;
    if (isPublishClosed)
      return <Tooltip title={<FormattedMessage {...jobMessage.closedJobNotPublishInfo} />}>{postBtn}</Tooltip>;
    return postBtn;
  };

  const portalsList = () => {
    const badgeStatus = isPublished ? 'success' : 'error';
    return portals.map(portal => (
      <div className={styles.portalList}>
        <div className={styles.portalLeftSection}>
          <GetJobBoardIcon portal={portal} style={{ fontSize: '25px' }} />
          {isPublished || isRecalled ? (
            <Badge status={badgeStatus} className={styles.activeBadge} />
          ) : (
            <div className={styles.inactiveBadge}></div>
          )}
          <div className={styles.portalName}>{portal}</div>
        </div>
        <div className={styles.portalRightSection}>
          {isPublished ? (
            <>
              {editButton()}
              <Button
                disabled={!hasEditAccess}
                size="small"
                type="secondary"
                onClick={() => setRecallVisible(true)}
                sk-event-name={eventTypes.job.publishJob.recallPublishJob}
              >
                <FormattedMessage {...jobMessage.stopLabel} />
              </Button>
            </>
          ) : (
            postButton()
          )}
        </div>
      </div>
    ));
  };

  const onRecallConfirm = () => {
    setRecallVisible(false);
    recallPortalJobDistribution();
  };

  return (
    <div className={styles.publishJobWrapper}>
      <RecallJobDistribution
        visible={recallVisible}
        onCancel={() => setRecallVisible(false)}
        onConfirm={onRecallConfirm}
        portal={portals[0]}
      />
      <div className={styles.publishJobTitleWrapper}>
        <FormattedMessage {...jobMessage.jobBoardPublishLabel} />
      </div>
      <div className={styles.publishDescriptionWrapper}>
        <FormattedMessage {...jobMessage.postJobToPlatformsLabel} />
      </div>
      <div className={styles.publishActionsWrapper}>{portalsList()}</div>
    </div>
  );
}
