import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Skeleton } from 'antd';
import CandidateList from '../../Components/CandidateList/CandidateList';
import { getConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getCurrentJobDetails } from '../../Reducers/JobReducer';
import { getCandidatesAsList } from '../../Reducers/CandidateReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import * as CandidateActions from '../../Actions/CandidateActions';
import * as JobActions from '../../Actions/JobActions';
import CandidateDrawerWrapper from '../CandidateDrawerWrapper/CandidateDrawerWrapper';
import * as CandidateActionCreators from '../../Actions/ActionCreators/CandidateActions';

const mapStateToProps = state => ({
  candidates: getCandidatesAsList(state),
  currentJobDetails: getCurrentJobDetails(state),
  featureToggleList: getFeatureToggleList(state),
  candidateDetailsStatus: getApiStatus(state, 'candidateDetailsStatus'),
  connectStatus: getApiStatus(state, 'ConnectStatus'),
  whiteLabelInfo: getWhiteLabelInformation(state),
  config: getConfig(state),
});

const mapDispatchToProps = {
  fetchCandidateList: CandidateActions.fetchCandidateList,
  setMspIcons: CandidateActions.setMspIcons,
  setCandidateListType: CandidateActionCreators.setCandidateListType,
  fetchJobDetails: JobActions.fetchJobDetails,
  setCurrentJobId: JobActions.setCurrentJobId,
};

const Msp = props => {
  const {
    fetchCandidateList,
    location,
    setCandidateListType,
    candidates,
    config,
    currentJobDetails,
    featureToggleList,
    fetchJobDetails,
    history,
    candidateDetailsStatus,
    connectStatus,
    whiteLabelInfo,
    setCurrentJobId,
    setMspIcons,
  } = props;
  const [candidateDrawerVisiblity, setCandidateDrawerVisiblity] = React.useState(false);
  const [candidateId, setCandidateId] = React.useState('');
  const [defaultActiveKey, setDefaultActiveKey] = React.useState('profile');
  const [drawerRenderFlag, setDrawerRenderFlag] = React.useState(false);
  const [composeEmailType, setcomposeEmailType] = React.useState(false);
  const queryParams = queryString.parse(location.search);
  const { jobId, jobguid } = queryParams;
  React.useEffect(() => {
    setCandidateListType('SourcedCandidateList');
    fetchCandidates();
    setCurrentJobId(jobId);
    fetchJobDetails(jobId);
  }, [jobId]);

  const filter = {
    Applied: true,
    ConnectionStatus: null,
    EmailStats: [],
    Genders: [],
    Industries: [],
    IsDiverse: null,
    IsFavorite: null,
    IsMinority: null,
    IsVeteran: null,
    Movers: [],
    Portals: [],
    PublishedStatus: null,
    Ratings: [],
    ScoutingAgentIds: [],
    SearchQuery: '',
    ShortlistedBy: [],
    Size: 10,
    SortBy: 'Ranking',
    Sources: [],
    Statuses: null,
    isAryaRankedTab: false,
    jobGuid: jobguid,
    jobId: parseInt(jobId, 10),
  };

  const onTabClick = key => {
    setDefaultActiveKey(key);
  };

  async function fetchCandidates() {
    await fetchCandidateList(filter, false, true, true, null, null, null, null, true, null, null, null);
  }
  const onClickOpenCandidateDrawer = (candidate, key, index, subKey) => {
    setCandidateId(candidate.Id);
    setCandidateDrawerVisiblity(true);
    setDrawerRenderFlag(true);
  };

  const closeCandidateDrawer = () => setCandidateDrawerVisiblity(false);
  const { JobTitle: jobTitle, Synonyms: jobSynonyms = [], CountryCode: jobCountryCode } = currentJobDetails ?? {};
  const connectStatusLoaded = connectStatus !== 'INPROGRESS';
  return (
    <div>
      <Skeleton loading={connectStatus === 'INPROGRESS'}>
        <CandidateList
          isCandidateChecked={() => {}}
          showCandidateDetail={onClickOpenCandidateDrawer}
          setComposeEmailType={val => setcomposeEmailType(val)}
          onCandidateCheckBoxClick={() => {}}
          showCandidateScore={null}
          showVaultName={config?.ShowVaultName}
          candidates={candidates}
          onCandidateStatusChange={() => {}}
          connectStatusLoaded={connectStatusLoaded}
          onCandidateReject={() => {}}
          status="Applied"
          publishedStatus={null}
          currentSelectedCandidate={null}
          connectionStatus={null}
          isFilterApplied={false}
          clearAllFilter={() => {}}
          onClickAtsPush={() => {}}
          aryaVersion={config?.SubscriptionType}
          ignoreSimilar={null}
          showConnectWidget={false}
          getCandidateRejectedStatusUpdate={() => {}}
          getCandidateShortlistedStatusUpdate={() => {}}
          showCandidateCheckboxSelection
          keywordsToHighlight={[]}
          mustHavesKeywords={[]}
          featureToggleList={featureToggleList}
          openCandidateView={null}
          candidateAllNotesFetched={null}
          whiteLabelInfo={whiteLabelInfo}
          activeTab="applied"
          jobId={jobId}
          activeSourceName="AryaRecommended"
          jobGuid={jobguid}
          candidateContext="job"
          defaultFilter={filter}
          revealActiveChannelSourceName={featureToggleList.RevealPortalsUnderGroup.IsEnabled}
          currentJobDetails={currentJobDetails ?? {}}
          config={config}
          fetchCandidates={fetchCandidates}
          msp
          setMspIcons={setMspIcons}
        />
      </Skeleton>
      <CandidateDrawerWrapper
        subTabKey="profile"
        version={undefined}
        onTabClick={onTabClick}
        onClose={closeCandidateDrawer}
        disableNext
        disablePrev
        visible={candidateDrawerVisiblity}
        drawerRenderFlag={drawerRenderFlag}
        toggleDrawerRenderFlag={() => setDrawerRenderFlag(!drawerRenderFlag)}
        candidateId={candidateId}
        composeEmailType={composeEmailType}
        setComposeEmailType={val => setcomposeEmailType(val)}
        downloadResume={() => {}}
        jobId={jobId}
        jobGuid={jobguid}
        jobTitle={jobTitle}
        jobSynonyms={jobSynonyms}
        activeKey={defaultActiveKey}
        candidateDetailsStatus={candidateDetailsStatus}
        onCandidateReject={() => {}}
        onCandidateStatusChange={() => {}}
        fetchCandidateAllDetails={() => {}}
        notesContainer={undefined}
        onClickAtsPush={() => {}}
        callNotesContainer={undefined}
        onPageChange={() => {}}
        keywordsToHighlight={[]}
        mustHavesKeywords={[]}
        unlockCandidateResume={() => {}}
        showPushCandidateButton
        showContactInfoButton={featureToggleList.ContactFetch.IsEnabled}
        history={history}
        aryaVersion={config?.SubscriptionType}
        onClickNext={() => {}}
        onClickPrev={() => {}}
        getCandidateRejectedStatusUpdate={() => {}}
        getCandidateShortlistedStatusUpdate={() => {}}
        openInNewTab={undefined}
        candidateAllNotesFetched
        candidateListStatus="COMPLETED"
        isConnectOptionsEnabled
        isJobActionsAllowed={!!jobId}
        openCandidateView={() => {}}
        ignoreSimilar
        jobCountryCode={jobCountryCode}
        currentJobDetails={currentJobDetails}
        scrollToRefineIntel={() => {}}
        candidateContext="job"
        isDiversityAttributesVisible={false}
        openSipCallWindowsApp={undefined}
        isCandidateSet={false}
        openSegmentAtsView={undefined}
        openJobViewInNewTabCallBack={() => {}}
        onClickAddJobCallBack={() => {}}
        fetchCandidates={fetchCandidates}
        defaultFilter={filter}
        msp
        setMspIcons={setMspIcons}
      />
    </div>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Msp));
