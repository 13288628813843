import React from 'react';
import { Button, Popover } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getUsersByGuId } from '../../Reducers/UserReducer';
import { getSubscriptionData } from '../../Reducers/ConnectReducer';
import { generateNotesDateTime } from '../NotesCard/NotesCardItemUtils';
import message from '../../Containers/ManualSearchV2/ManualSearchMessages';

const UnsubscribedButton = props => {
  const unsubscribedPopoverContent = () => {
    const { candidate, subscriptiondata, userByGuId } = props;
    const subscription = candidate?.connectInfo?.Contact?.Subscription;
    const { UpdatedTime, CreatedBy, Remarks: remarks } = subscriptiondata || subscription || {};
    const updatedBy = userByGuId[CreatedBy]?.FullName;
    const updatedTime = generateNotesDateTime(UpdatedTime);

    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: '15px', marginTop: '5px' }}>
        <div>
          <span style={{ fontWeight: 500 }}><FormattedMessage {...message.unsubscribedByLabel} />:</span> {updatedBy}
        </div>
        <div>
          <b><FormattedMessage {...message.dateLabel} />: </b>
          {updatedTime}
        </div>
        <div>
          <span style={{ fontWeight: 500 }}><FormattedMessage {...message.remarksLabel} />:</span> {remarks}
        </div>
      </div>
    );
  };

  return (
    <div style={{ marginLeft: '24px' }}>
      <Popover
        mouseEnterDelay={0.2}
        placement="top"
        style={{ margin: '10px' }}
        content={unsubscribedPopoverContent()}
        title={<FormattedMessage {...message.candidateUnsubscribedLabel} />}
      >
        <Button shape="round" style={{ borderColor: 'rgb(250 226 20 / 96%)', borderWidth: '2px' }}>
          <span style={{ color: 'rgba(250, 226, 20, 0.96)' }}><FormattedMessage {...message.unsubscribedLabel} /></span>
        </Button>
      </Popover>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userByGuId: getUsersByGuId(state),
  subscriptiondata: getSubscriptionData(state, ownProps.candidate.PersonId),
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(UnsubscribedButton);

export { UnsubscribedButton as UnsubscribedButtonWithoutStore };
