import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { Skeleton } from 'antd';
import _ from 'lodash';
import memoize from 'memoize-one';
import CandidateList from '../../Components/CandidateList/CandidateList';
import ActiveChannelCreditInfo from '../ActiveChannelCreditInfo/ActiveChannelCreditInfo';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import CandidateDrawerWrapper from '../CandidateDrawerWrapper/CandidateDrawerWrapper';
import * as CandidateActions from '../../Actions/CandidateActions';
import * as CandidateActionCreators from '../../Actions/ActionCreators/CandidateActions';
import { setCandidateAllDetailsApiStatus as _setCandidateAllDetailsApiStatus } from '../../Actions/ActionCreators/CandidateActions';
import { getSourcingStats, getMustHaves, getNiceToHaves } from '../../Reducers/JobReducer';
import {
  getCandidatesById,
  getCandidatesAsList,
  getCandidateCount,
  getDilatedCandidateCount,
  getCandidatesFetchFlag,
  getCurrentCandidateId,
  getSourcingNotification,
  getRecommendedStatusChangedNotification,
  getBulkShortlistedNotification,
  getCandidatesPublishedNotification,
  getRejectedCandidateIds,
  getSampleCandidateCount,
  getAppliedBucketScoringStatus,
  getAppliedBucketScoringStatusSource,
} from '../../Reducers/CandidateReducer';
import { getConfig, getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { getCandidatesConnectInfo } from '../../Reducers/ConnectReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import * as JobActions from '../../Actions/JobActions';
import * as JobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import * as ContactFetchActions from '../../Actions/ContactFetchActions';
import * as AryaPayActions from '../../Actions/AryaPayActions';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getAvailableCredits } from '../../Reducers/AryaPayReducer';
import { FREE_TRIAL } from '../../Utils/PlanSelectionUtils';
import { getCandidateSource, getSourceName, getAtsSource } from '../../Utils/SourceUtils';
import FindingCandidates from '../../Components/FindingCandidates/FindingCandidates';
import { getActualSourcingStats } from '../../Utils/JobSourcingStatsUtil';
import { getKeyWordsToHighlight } from '../../Utils/CandidateListUtils';
import { getIsCreditInfoPopoverVisible } from '../../Utils/CreditInfoUtils';
import './JobCandidateList.scss';
import {
  getActiveSourceName,
  getSelectedActiveCandidatesCount,
  getSelectedCandidates,
} from '../../Reducers/JobCandidatesTabReducer';
import {
  getAddModalVisbilityStatus,
  getSubSegmentListofSegment,
  getLastCreatedSubSegmentId,
} from '../../Reducers/SubsegmentReducer';
import {
  fetchSubSegments,
  setSubsegmentCreateModalStatus,
  setSubsegmentDeleteModalStatus,
  setSubsegmentAddModalStatus,
  setCreatedSubSegmentId as _setCreatedSubSegmentId,
} from '../../Actions/SubSegmentActions';
import { isPulseUser } from '../../Utils/ConfigUtils';
import store from '../../store';
import AddCandidateToSegmentPopover from '../SubSegment/AddCandidateToSegmentModal';
import { getAdminFeatures } from '../../Reducers/UserReducer';
import { getCandidatePublishStatus } from '../../Utils/CandidatePublishUtils';
import { getVariantId } from '../../Utils/ProductVariantDetails';
import { getScoutingAgentUpdateStats } from '../../Reducers/ScoutingAgentReducer';

const mapStateToProps = (state, props) => ({
  config: getConfig(state),
  candidatesById: getCandidatesById(state, props.jobId),
  candidates: getCandidatesAsList(state),
  candidatesConnectInfo: getCandidatesConnectInfo(state),
  connectStatus: getApiStatus(state, 'ConnectStatus'),
  candidateAllNotesFetchStatus: getApiStatus(state, 'fetchBulkCandidateAllNotesApiStatus'),
  candidateListStatus: getApiStatus(state, 'candidateListStatus'),
  candidateDetailsStatus: getApiStatus(state, 'candidateDetailsStatus'),
  sourceCandidateStatusUpdate: getApiStatus(state, 'sourceCandidateStatusUpdate'),
  candidateBookmarkApiStatus: getApiStatus(state, 'candidateBookmarkApiStatus'),
  mustHaves: getMustHaves(state, props.jobId),
  niceToHaves: getNiceToHaves(state, props.jobId),
  currentUser: getCurrentUser(state),
  featureToggleList: getFeatureToggleList(state),
  totalCandidate: getCandidateCount(state),
  addSubsegmentModalStatus: getAddModalVisbilityStatus(state),
  subsegmentList: getSubSegmentListofSegment(state, props.jobId),
  sampleCandidateCount: getSampleCandidateCount(state),
  dilatedCandidateTotal: getDilatedCandidateCount(state),
  candidatesFetchFlag: getCandidatesFetchFlag(state),
  v2CandidateId: getCurrentCandidateId(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  sourcingStats: getSourcingStats(state, props.jobId),
  sourcingNotification: getSourcingNotification(state),
  bulkShortlistedNotification: getBulkShortlistedNotification(state),
  recommendedStatusChangedNotification: getRecommendedStatusChangedNotification(state),
  currentSelectedAllCandidates: getSelectedCandidates(state, 'Recommended'),
  selectedActiveCandidateCount: getSelectedActiveCandidatesCount(state, 'Recommended'),
  activeSourceName: getActiveSourceName(state),
  candidatesPublishedNotification: getCandidatesPublishedNotification(state),
  availableCredits: getAvailableCredits(state),
  candidateAggregationsApiStatus: getApiStatus(state, 'CandidateAggregationsApiStatus'),
  fetchSubSegmentApiStatus: getApiStatus(state, 'fetchSubSegmentApiStatus'),
  appliedBucketScoringStatus: getAppliedBucketScoringStatus(state, { jobId: props.jobId }),
  appliedBucketScoringStatusSource: getAppliedBucketScoringStatusSource(state, { jobId: props.jobId }),
  adminFeatures: getAdminFeatures(state),
  lastCreatedCandidateId: getLastCreatedSubSegmentId(state),
  scoutingAgentDeletedNotification: getScoutingAgentUpdateStats(state, props.jobId),
});

const mapDispatchToProps = {
  downloadResume: CandidateActions.downloadResume,
  unlockCandidateResume: CandidateActions.unlockCandidateResume,
  changeCandidateStatus: CandidateActions.changeCandidateStatus,
  setCandidateRejectStatus: CandidateActions.setCandidateRejectStatus,
  fetchCandidateDetails: CandidateActions.fetchCandidateDetails,
  fetchJobSourcingStats: JobActions.fetchJobSourcingStats,
  fetchJobDetails: JobActions.fetchJobDetails,
  resetCandidates: CandidateActions.resetCandidates,
  setFetchCandidatesFlag: CandidateActions.setFetchCandidatesFlag,
  setCandidateAllDetailsApiStatus: _setCandidateAllDetailsApiStatus,
  fetchCandidateDetailsforV2Version: CandidateActions.fetchCandidateDetailsforV2Version,
  setCurrentJobId: JobActions.setCurrentJobId,
  onFetchContact: ContactFetchActions.onFetchContact,
  setCandidateListType: CandidateActionCreators.setCandidateListType,
  setSelectedCandidates: JobCandidatesTabActions.setSelectedCandidates,
  setSelectedActiveCandidateCount: JobCandidatesTabActions.setSelectedActiveCandidateCount,
  updateCandidateBookmarkStatus: CandidateActions.updateCandidateBookmarkStatus,
  setAddSubsegmentModalStatus: setSubsegmentAddModalStatus,
  fetchSubSegmentsAction: fetchSubSegments,
  setSubsegmentDeleteModalStatusAction: setSubsegmentDeleteModalStatus,
  setSubsegmentModalStatus: setSubsegmentCreateModalStatus,
  fetchAvailableCredits: AryaPayActions.fetchAvailableCredits,
  setJobCandidateTabPagination: JobCandidatesTabActions.setJobCandidateTabPagination,
  setJobCandidateTabFrom: JobCandidatesTabActions.setJobCandidateTabFrom,
  setCreatedSubSegmentId: _setCreatedSubSegmentId,
};

class JobCandidateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultActiveKey: 'profile',
      defaultSubTabKey: 'profile',
      disableNext: false,
      disablePrev: false,
      drawerRenderFlag: false,
      creditInfoVisibility: false,
      isCandidateSet: false,
      composeEmailType: false,
      currentCandidateIndexInPage: undefined,
      nextCandidateIndex: 0,
      currentPageCandidateIds: undefined,
      isPageChanged: false,
    };
    this.initialRenderFlag = true;
    this.atsCandidateViewedFlag = true;
  }

  componentDidMount() {
    const { openInNewTab, jobId, setCurrentJobId, setCandidateListType, fetchAvailableCredits } = this.props;
    if (openInNewTab) {
      this.fetchCandidateViewDetails();
    }
    if (jobId && openInNewTab) setCurrentJobId(jobId);
    this.initialRenderFlag = false;
    setCandidateListType('SourcedCandidateList');
    fetchAvailableCredits(getVariantId('Arya'), 'Arya');
    fetchAvailableCredits(getVariantId('SMS'), 'SMS');
  }

  componentWillUnmount() {
    const { resetCandidates, setCandidateListType } = this.props;
    resetCandidates();
    setCandidateListType(null);
  }

  fetchCandidateViewDetails = async () => {
    const {
      fetchJobDetails,
      fetchCandidateDetails,
      setCandidateAllDetailsApiStatus,
      jobId,
      candidateViewVersion,
      portal,
      fetchCandidateDetailsforV2Version,
      candidateId,
    } = this.props;
    setCandidateAllDetailsApiStatus('INPROGRESS');
    if (jobId) {
      const jobDetails = await fetchJobDetails(jobId);
      const { JobGuid, CountryCode } = jobDetails;
      this.setState({ defaultActiveKey: 'profile' });
      if (candidateViewVersion === 'v3') {
        this.setState({ candidateId });
        const filter = {
          candidateId,
          jobId,
        };
        fetchCandidateDetails(filter, { refId: JobGuid, Country: CountryCode });
      } else {
        fetchCandidateDetailsforV2Version({ jobId, candidateId, portal }, { refId: JobGuid, Country: CountryCode });
      }
    } else fetchCandidateDetailsforV2Version({ candidateId, portal });
  };

  getIsPageRefreshRequired = prevProps => {
    const {
      jobId,
      sourcingNotification,
      recommendedStatusChangedNotification,
      bulkShortlistedNotification,
      featureToggleList,
      sourcingStats,
      candidatesPublishedNotification,
      appliedBucketScoringStatus,
      appliedBucketScoringStatusSource,
      scoutingAgentDeletedNotification,
    } = this.props;
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const { sourcedCount } = getActualSourcingStats(sourcingStats, isPaidJobServiceEnabled);
    const isCandidatesPublishedNotification =
      !_.isEqual(prevProps.candidatesPublishedNotification, candidatesPublishedNotification) &&
      jobId?.toString() === candidatesPublishedNotification?.jobId?.toString();

    if (
      (!_.isEqual(prevProps.sourcingNotification, sourcingNotification) &&
        jobId?.toString() === sourcingNotification?.jobId?.toString()) ||
      (!_.isEqual(prevProps.recommendedStatusChangedNotification, recommendedStatusChangedNotification) &&
        jobId?.toString() === recommendedStatusChangedNotification?.jobId?.toString()) ||
      (!_.isEqual(prevProps.bulkShortlistedNotification, bulkShortlistedNotification) &&
        jobId?.toString() === bulkShortlistedNotification?.jobId?.toString()) ||
      (!_.isEqual(prevProps.appliedBucketScoringStatus, appliedBucketScoringStatus) &&
        appliedBucketScoringStatusSource === 'notification') ||
      !_.isEqual(prevProps.scoutingAgentDeletedNotification, scoutingAgentDeletedNotification)
    )
      return true;
    if (isCandidatesPublishedNotification && isPaidJobServiceEnabled && !sourcedCount) return true;
    return false;
  };

  componentDidUpdate(prevProps) {
    const {
      jobId,
      candidates,
      fetchJobSourcingStats,
      openInNewTab,
      candidateListStatus,
      version,
      activeSourceName,
      candidateContext,
      status,
      adminFeatures,
      featureToggleList,
    } = this.props;
    const { candidateDrawerVisiblity, currentPageCandidateIds, isPageChanged } = this.state;
    if (!openInNewTab && !candidateDrawerVisiblity && this.getIsPageRefreshRequired(prevProps)) {
      fetchJobSourcingStats([jobId]);
      if (activeSourceName === 'AryaRecommended') this.refreshCurrentPage(status === 'Applied');
    }
    const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList);
    const {
      nextOperation,
      creditInfoVisibility,
      candidate,
      key,
      candidateFilter,
      size,
      makeCandidatesFetchCall,
      index,
      subKey,
    } = this.state;
    if (nextOperation && !creditInfoVisibility) {
      if (nextOperation === 'OpenCandidateDrawer') {
        this.onClickOpenCandidateDrawer(candidate, key, index, subKey);
      } else if (nextOperation === 'FetchContact') {
        this.handleContactPull({ candidate });
      } else if (nextOperation === 'CandidateShortlist') {
        this.changeCandidateStatusForJob(candidateFilter, size, makeCandidatesFetchCall, true);
      } else if (nextOperation === 'openCandidateView') {
        this.openCandidateView(candidate);
      }
    }
    if (prevProps.candidateListStatus === 'INPROGRESS' && candidateListStatus === 'COMPLETED') {
      if (version === 'ats') {
        if (this.atsCandidateViewedFlag) {
          this.openCandidateDrawerIfRankIndexExistsForATS();
        }
      } else {
        this.openCandidateDrawerIfIndexExistsInUrl();
      }
    }
    if (
      candidates?.length &&
      (candidateListStatus !== prevProps.candidateListStatus ||
        (candidateContext === 'segment' && status !== 'Shortlisted'))
    ) {
      const { currentCandidateIndexInPage, nextCandidateIndex } = this.state;
      if (
        candidateListStatus === 'COMPLETED' &&
        nextCandidateIndex !== undefined &&
        currentCandidateIndexInPage !== undefined
      ) {
        this.setState({ isCandidateSet: false }, () => {
          const filter = {
            candidateId: candidates[0].Id,
            jobId,
          };
          if (!isCandidatePublishEnabled) this.fetchCandidateAllDetails(filter);
          this.setState(
            {
              currentCandidateIndexInPage: 0,
              nextCandidateIndex: undefined,
            },
            () => {
              this.setNextandPrev();
              this.setState({ isCandidateSet: true });
            }
          );
        });
      }
    }
    if (
      candidateContext === 'segment' &&
      status === 'Shortlisted' &&
      currentPageCandidateIds?.length &&
      isPageChanged
    ) {
      this.setState({ isCandidateSet: false, isPageChanged: false }, () => {
        const filter = {
          candidateId: currentPageCandidateIds[0].Id,
          jobId,
        };
        this.fetchCandidateAllDetails(filter);
        this.setState(
          {
            currentCandidateIndexInPage: 0,
            nextCandidateIndex: undefined,
          },
          () => {
            this.setNextandPrev();
            this.setState({ isCandidateSet: true });
          }
        );
      });
    }
  }

  getCurrentTabAndSubTabForCandidateView = view => {
    let currentTabDetails = {
      Tab: 'profile',
      SubTab: 'profile',
    };
    const candidateTabsList = {
      text: { Tab: 'contact', SubTab: 'message' },
      chatbot: { Tab: 'contact', SubTab: 'bot' },
    };
    const currentTabToView = candidateTabsList[view?.trim().toLowerCase()];
    if (currentTabToView) currentTabDetails = currentTabToView;
    return currentTabDetails;
  };

  openCandidateDrawerIfIndexExistsInUrl = () => {
    const { location, candidatesById, history, jobId, pageSize } = this.props;
    const queryParams = queryString.parse(location.search);
    const { index: candidateIndex, id, status, view, activeTab } = queryParams;
    this.setState({
      currentCandidateIndexInPage: candidateIndex,
    });
    if (candidateIndex) {
      const currentCandidateDetails = candidatesById[id];
      if (!_.isEmpty(currentCandidateDetails)) {
        const tabToDirect = this.getCurrentTabAndSubTabForCandidateView(view);
        this.onClickOpenCandidateDrawer(
          currentCandidateDetails,
          tabToDirect.SubTab,
          candidateIndex % pageSize,
          tabToDirect.Tab
        );
      }
      let url = `/jobs/${jobId}/candidates?status=${status}`;
      if (activeTab === 'scoutingAgent') url = `${url}&activeTab=scoutingAgent`;
      history.replace(url);
    }
  };

  openCandidateDrawerIfRankIndexExistsForATS = () => {
    const { location, candidatesById, pageSize, atsCandidateRankIndex, candidateId } = this.props;
    const queryParams = queryString.parse(location.search);
    const { view } = queryParams;
    const currentCandidateDetails = candidatesById[candidateId];
    if (!_.isEmpty(currentCandidateDetails)) {
      const tabToDirect = this.getCurrentTabAndSubTabForCandidateView(view);
      this.onClickOpenCandidateDrawer(
        currentCandidateDetails,
        tabToDirect.SubTab,
        atsCandidateRankIndex % pageSize,
        tabToDirect.Tab
      );
      this.atsCandidateViewedFlag = false;
    }
  };

  static getDerivedStateFromProps(props, state) {
    const newState = { ...state };
    const { candidateId, source } = state;
    const { candidateViewVersion, v2CandidateId, candidatesById } = props;
    const candidateDetails = candidatesById?.[candidateId];
    if (candidateViewVersion === 'v2' && v2CandidateId && v2CandidateId !== candidateId)
      newState.candidateId = v2CandidateId;
    if (candidateViewVersion === 'v3' && (candidateDetails?.Source || candidateDetails?.Sources) && !source) {
      const candidateSource = getCandidateSource(candidateDetails);
      const atsPrimarySource = getAtsSource(candidateDetails.Sources);
      newState.source = atsPrimarySource || candidateSource;
    }
    return newState;
  }

  refreshCurrentPage = (showLoader = false) => {
    const {
      defaultFilter,
      fetchCandidates,
      sampleCandidateCount,
      currPage,
      pageSize,
      activeTab,
      candidateContext,
      setJobCandidateTabPagination,
      setJobCandidateTabFrom,
    } = this.props;
    const isLastIndexCandidate = sampleCandidateCount - (currPage - 1) * pageSize < 2;
    let offsetFromCurrentPage = isLastIndexCandidate ? 1 : 0;
    if (currPage - 1 - offsetFromCurrentPage < 0) {
      offsetFromCurrentPage = 0;
    }
    fetchCandidates({
      filter: defaultFilter,
      showLoader,
      invokationContext: 'defaultLoad',
    });
    if (isLastIndexCandidate && candidateContext === 'segment' && activeTab !== 'sourced') {
      setJobCandidateTabPagination({ currPage: currPage - offsetFromCurrentPage });
      setJobCandidateTabFrom(currPage - offsetFromCurrentPage);
    }
  };

  openCandidateView = candidate => {
    const { jobId, openAryaCandidateView, candidateContext = 'job' } = this.props;
    const isCreditInfoPopoverVisible = this.getIsCreditInfoPopoverVisible({
      candidate,
      nextOperation: 'openCandidateView',
    });
    if (isCreditInfoPopoverVisible) {
      return;
    }
    if (openAryaCandidateView) openAryaCandidateView(jobId, candidate.Id, '3');
    else if (candidateContext === 'job') window.open(`/jobs/${jobId}/candidates/${candidate.Id}?v=3`);
    else window.open(`/segments/${jobId}/candidates/${candidate.Id}?v=3`);
    this.setState({ nextOperation: null });
  };

  getIsCreditInfoPopoverVisible = ({
    candidate,
    key,
    index,
    subKey,
    candidateFilter,
    size,
    makeCandidatesFetchCall,
    nextOperation,
  }) => {
    // returning false from here directly to get isCreditInfoPopoverVisible always false.
    return false;
    // const { currentUser, featureToggleList } = this.props;
    // const { nextOperation: operationToBePerformed } = this.state;
    // const isCreditInfoPopoverVisible = getIsCreditInfoPopoverVisible({
    //   candidate,
    //   currentUser,
    //   featureToggleList,
    //   operationToBePerformed,
    // });
    // if (isCreditInfoPopoverVisible) {
    //   this.setState({
    //     creditInfoVisibility: true,
    //     nextOperation,
    //     candidate,
    //     key,
    //     index,
    //     subKey,
    //     candidateFilter,
    //     size,
    //     makeCandidatesFetchCall,
    //     currentCandidateIndexInPage: index,
    //   });
    // }
    // return isCreditInfoPopoverVisible;
  };

  onClickOpenCandidateDrawer = (candidate, key, index, subKey) => {
    const {
      jobId,
      onCloseIdealCandidateIntelPopup,
      candidates,
      currPage,
      pageSize,
      candidateContext,
      status,
      adminFeatures,
      featureToggleList,
    } = this.props;
    const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList);
    const candidateSource = getCandidateSource(candidate);
    const filter = {
      candidateId: candidate.Id,
      jobId,
      source: candidateSource,
      isFetchBulkUnreadConversationsCount: true,
    };
    const isCreditInfoPopoverVisible = this.getIsCreditInfoPopoverVisible({
      candidate,
      key,
      index,
      subKey,
      nextOperation: 'OpenCandidateDrawer',
    });
    if (isCreditInfoPopoverVisible) {
      return;
    }
    const atsPrimarySource = getAtsSource(candidate.Sources);
    if (!isCandidatePublishEnabled) {
      this.fetchCandidateAllDetails(filter);
    }
    if (candidateContext === 'segment' && status === 'Shortlisted') {
      const from = (currPage - 1) * pageSize;
      const currentPageCandidateIds = candidates.slice(from, from + pageSize);
      this.setState({
        currentPageCandidateIds,
      });
    }
    this.setState(
      {
        candidateId: candidate.Id,
        defaultActiveKey: key,
        source: atsPrimarySource || candidateSource,
        candidateDrawerVisiblity: true,
        defaultSubTabKey: subKey || 'profile',
        drawerRenderFlag: true,
        nextOperation: null,
        currentCandidateIndexInPage: index,
      },
      () => this.setNextandPrev()
    );
    if (onCloseIdealCandidateIntelPopup) {
      onCloseIdealCandidateIntelPopup();
    }
  };

  setComposeEmailType = value => {
    this.setState({
      composeEmailType: value,
    });
  };

  closeCandidateDrawer = () => {
    const {
      jobId,
      candidatesFetchFlag,
      setFetchCandidatesFlag,
      fetchJobSourcingStats,
      onPageChange,
      currPage,
      pageSize,
    } = this.props;
    this.setState({
      candidateDrawerVisiblity: false,
    });
    if (candidatesFetchFlag) {
      const callback = () => fetchJobSourcingStats([jobId]);
      onPageChange(currPage, pageSize, callback);
    }
    setFetchCandidatesFlag(false);
  };

  onTabClick = key => {
    this.setState({ defaultActiveKey: key });
  };

  onCandidateReject = (candidate, size) => {
    const { setCandidateRejectStatus } = this.props;
    setCandidateRejectStatus(candidate.Id, size);
  };

  setSelectedActiveCandidateCount = selectedActiveCandidateCount => {
    const { setSelectedActiveCandidateCount } = this.props;
    setSelectedActiveCandidateCount({ candidateType: 'Recommended', selectedActiveCandidateCount });
  };

  setSelectedCandidates = selectedCandidates => {
    const { setSelectedCandidates } = this.props;
    setSelectedCandidates({ candidateType: 'Recommended', selectedCandidates });
  };

  onCandidateCheckBoxClick = event => {
    const { currentSelectedAllCandidates, selectedActiveCandidateCount } = this.props;
    const isChecked = event.target.checked;
    const candidate = event.target.value;
    const candidateSourceName = getSourceName(getCandidateSource(candidate)).toLowerCase();
    const _currentSelectedAllCandidates = [...currentSelectedAllCandidates];
    if (isChecked) {
      _currentSelectedAllCandidates.push(candidate.Id);
      if (candidateSourceName === 'active') {
        this.setSelectedActiveCandidateCount(selectedActiveCandidateCount + 1);
      }
    } else {
      _.remove(_currentSelectedAllCandidates, candidateId => candidateId === candidate.Id);
      if (candidateSourceName === 'active') {
        this.setSelectedActiveCandidateCount(selectedActiveCandidateCount - 1);
      }
    }
    this.setSelectedCandidates(_currentSelectedAllCandidates);
  };

  getMergedCandidates = candidates => {
    const { candidatesConnectInfo } = this.props;
    if (!candidates) {
      return [];
    }
    const connectStatuses = candidatesConnectInfo.ConnectStatuses || {};
    return candidates.map(candidate => {
      const connectInfo = connectStatuses?.[candidate.PersonId] ?? {};
      return {
        ...candidate,
        connectInfo,
      };
    });
  };

  fetchCandidateAllDetails = filter => {
    const { fetchCandidateDetails, currentJobDetails } = this.props;
    const { candidateId } = filter;
    const jobGuid = currentJobDetails.JobGuid;
    const countryCode = currentJobDetails.CountryCode;
    this.setState({ candidateId, defaultActiveKey: 'profile' });
    fetchCandidateDetails(filter, { refId: jobGuid, Country: countryCode });
  };

  changeCandidateStatusForJob = (filter, size, makeCandidatesFetchCall = true, shouldUnmaskCandidate = false) => {
    const {
      jobId,
      changeCandidateStatus,
      fetchJobSourcingStats,
      currentJobDetails,
      totalCandidate,
      featureToggleList,
      currPage,
      pageSize,
      defaultFilter,
      fetchCandidates,
      onPageChange,
      onCloseIdealCandidateIntelPopup,
      candidateContext = 'job',
      activeTab,
    } = this.props;
    const jobTitle = currentJobDetails.JobTitle;
    let isLastCandidate = false;
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
    const { candidate } = filter;
    const isCreditInfoPopoverVisible =
      filter.status.toLowerCase() === 'shortlisted' &&
      this.getIsCreditInfoPopoverVisible({
        candidate,
        nextOperation: 'CandidateShortlist',
        candidateFilter: filter,
        size,
        makeCandidatesFetchCall,
      });
    if (isCreditInfoPopoverVisible) {
      return;
    }
    if (makeCandidatesFetchCall && totalCandidate % pageSize === 1 && currPage !== 1) {
      isLastCandidate = true;
      onPageChange(currPage - 1, pageSize);
    }
    const mustHaves = _.get(filter, 'mustHaves', null) ? _.get(filter, 'mustHaves').split(',') : [];
    const candidateStatus = isPaidJobServiceEnabled ? candidate.PublishStatus : candidate.Status;
    if (
      filter.status !== 'Shortlisted' &&
      (filter.status === 'Rejected' || candidateStatus === 'Rejected') &&
      candidateContext === 'job'
    ) {
      this.onCandidateReject(candidate, size);
    }
    const candidateQuery = {
      jobId,
      candidateId: candidate.Id,
      type: filter.type,
      status: filter.status,
      reasons: filter.rejectReasons,
      includeSimilar: filter.includeSimilar,
      mustHaves,
      jobTitle,
      isPublished: candidate.IsPublished,
    };
    if (candidateContext === 'segment' && filter.selectedSubsegmentId) {
      candidateQuery.subsegmentId = filter.selectedSubsegmentId;
    }
    if (activeTab === 'subsegment' && filter.selectedSubsegmentId) {
      candidateQuery.subsegmentId = filter.selectedSubsegmentId;
      candidateQuery.IsSubSegmentOnly = filter.selctedOption === 1;
    }
    const onCandidateStausChangeCallback = () => {
      if (!isLastCandidate) {
        fetchCandidates({
          filter: defaultFilter,
          showLoader: false,
          invokationContext: 'defaultLoad',
          isCandidateShortlistedReject: true,
        });
      }
      fetchJobSourcingStats([jobId]);
    };
    if (makeCandidatesFetchCall && !filter.includeSimilar) {
      changeCandidateStatus(
        candidateQuery,
        onCandidateStausChangeCallback,
        false,
        candidateContext,
        activeTab,
        shouldUnmaskCandidate
      ); // when includeSimilar is true, we refresh page once recommendedStatusChangedNotification is received
    } else {
      changeCandidateStatus(candidateQuery, () => {}, false, candidateContext, activeTab, shouldUnmaskCandidate);
    }
    if (onCloseIdealCandidateIntelPopup) {
      onCloseIdealCandidateIntelPopup();
    }
    this.setState({
      creditInfoVisibility: false,
      nextOperation: null,
    });
  };

  handleContactPull = ({ candidate }) => {
    const isCreditInfoPopoverVisible = this.getIsCreditInfoPopoverVisible({
      candidate,
      nextOperation: 'FetchContact',
    });
    if (isCreditInfoPopoverVisible) {
      return;
    }
    const { currentJobDetails, onFetchContact } = this.props;
    onFetchContact({ candidate, currentJobDetails });
    this.setState({
      creditInfoVisibility: false,
      nextOperation: null,
    });
  };

  onClickAtsPush = (atsCandidateId, candidateGuid, resumeName, resumeDate, originalPortal) => {
    const { openAtsView } = this.props;
    if (openAtsView) openAtsView(atsCandidateId, candidateGuid, resumeName, resumeDate, originalPortal);
  };

  unlockResume = candidate => {
    const { unlockCandidateResume, jobId, currentJobDetails } = this.props;
    const { CountryCode: countryCode, JobGuid: jobGuid } = currentJobDetails;
    unlockCandidateResume({ jobId, candidateId: candidate.Id }, { refId: jobGuid, Country: countryCode });
  };

  getCandidatesList = () => {
    const { currentJobDetails, candidates } = this.props;
    const state = store.getState();
    const userConfig = getConfig(state);
    const rejectedCandidateIds = getRejectedCandidateIds(state);
    if (isPulseUser() && rejectedCandidateIds.length > 0 && userConfig.Role !== 'Service Rep') {
      const candidateContactDetailsList = [];
      rejectedCandidateIds.forEach(key => {
        if (key.UserType === 'PulseUser') {
          candidateContactDetailsList.push(key.CandidateId);
        }
      });
      return candidateContactDetailsList;
    }
    const currentPageCandidateIds = candidates.map(x => x.Id);
    return currentJobDetails.CandidateIds ?? currentPageCandidateIds;
  };

  onClickNext = async () => {
    const { jobId, onPageChange, currPage, pageSize, candidateContext, status, adminFeatures, featureToggleList } =
      this.props;
    const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList);
    const { nextCandidateId, currentCandidateIndexInPage } = this.state;
    if (currentCandidateIndexInPage === pageSize - 1) {
      this.setState({ currentCandidateIndexInPage: 0, nextCandidateIndex: 0 });
      await onPageChange(currPage + 1, pageSize);
      const { candidates } = this.props;
      if (candidateContext === 'segment' && status === 'Shortlisted') {
        const updatedPage = currPage + 1;
        const from = (updatedPage - 1) * pageSize;
        const currentPageCandidateIds = candidates.slice(from, from + pageSize);
        this.setState({
          isPageChanged: true,
          candidateId: currentPageCandidateIds[0]?.Id,
          currentPageCandidateIds,
        });
      } else {
        this.setState({
          candidateId: candidates[0].Id,
        });
      }
    } else {
      this.setState({ isCandidateSet: false }, () => {
        const filter = {
          candidateId: nextCandidateId,
          jobId,
        };
        if (!isCandidatePublishEnabled) this.fetchCandidateAllDetails(filter);
        this.setState(
          {
            candidateId: nextCandidateId,
            currentCandidateIndexInPage: currentCandidateIndexInPage + 1,
          },
          () => {
            this.setNextandPrev();
            this.setState({ isCandidateSet: true });
          }
        );
      });
    }
  };

  setNextandPrev = () => {
    this.setNext();
    this.setPrev();
  };

  setNext = () => {
    const { currentCandidateIndexInPage, currentPageCandidateIds } = this.state;
    const { totalCandidate, currPage, pageSize, sampleCandidateCount, candidateContext, candidates, status } =
      this.props;
    const currentCandidateIndex = (currPage - 1) * pageSize + currentCandidateIndexInPage + 1;
    const candidateCount =
      candidateContext === 'segment' && status === 'Shortlisted' ? sampleCandidateCount : totalCandidate;
    const isLastCandidate = currentCandidateIndex === candidateCount;
    const hasNextCandidate = currentCandidateIndex < candidateCount;
    if (totalCandidate === 1 || isLastCandidate) {
      this.setState({
        disableNext: true,
      });
    } else if (currentCandidateIndexInPage === 0 || (currentCandidateIndexInPage > 0 && hasNextCandidate)) {
      if (currentCandidateIndexInPage !== pageSize - 1)
        this.setState({
          disableNext: false,
          nextCandidateId:
            candidateContext === 'segment' && status === 'Shortlisted'
              ? currentPageCandidateIds[currentCandidateIndexInPage + 1].Id
              : candidates[currentCandidateIndexInPage + 1].Id,
        });
      else {
        this.setState({
          disableNext: false,
          nextCandidateId: undefined,
        });
      }
    }
  };

  setPrev = () => {
    const { currentCandidateIndexInPage, currentPageCandidateIds } = this.state;
    const { totalCandidate, currPage, pageSize, candidates, sampleCandidateCount, candidateContext, status } =
      this.props;
    const currentCandidateIndex = (currPage - 1) * pageSize + currentCandidateIndexInPage + 1;
    const candiateCount =
      candidateContext === 'segment' && status === 'Shortlisted' ? sampleCandidateCount : totalCandidate;
    const isLastCandidate = currentCandidateIndex === candiateCount;
    const hasPreviousCandidate = currentCandidateIndexInPage < currentCandidateIndex;
    if (totalCandidate === 1 || currentCandidateIndex === 1) {
      this.setState({
        disablePrev: true,
      });
    } else if (isLastCandidate && currentCandidateIndexInPage === 0) {
      this.setState({ disablePrev: false });
    } else if (isLastCandidate || (currentCandidateIndexInPage > 0 && hasPreviousCandidate)) {
      this.setState({
        disablePrev: false,
        prevCandidateId:
          candidateContext === 'segment' && status === 'Shortlisted'
            ? currentPageCandidateIds[currentCandidateIndexInPage - 1]?.Id
            : candidates[currentCandidateIndexInPage - 1]?.Id,
      });
    }
  };

  onClickPrev = async () => {
    const { jobId, onPageChange, currPage, pageSize, candidateContext, status, featureToggleList, adminFeatures } =
      this.props;
    const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList);
    const { prevCandidateId, currentCandidateIndexInPage } = this.state;
    if (currentCandidateIndexInPage <= 0) {
      await onPageChange(currPage - 1, pageSize);
      const { candidates } = this.props;
      if (candidateContext === 'segment' && status === 'Shortlisted') {
        const updatedPage = currPage - 1;
        const from = (updatedPage - 1) * pageSize;
        const prevPageCandidateIds = candidates.slice(from, from + pageSize);
        const filter = {
          candidateId: prevPageCandidateIds[pageSize - 1].Id,
          jobId,
        };
        if (!isCandidatePublishEnabled) this.fetchCandidateAllDetails(filter);
        this.setState(
          { currentCandidateIndexInPage: pageSize - 1, currentPageCandidateIds: prevPageCandidateIds },
          () => {
            this.setNextandPrev();
          }
        );
      } else {
        const filter = {
          candidateId: candidates[pageSize - 1].Id,
          jobId,
        };
        if (!isCandidatePublishEnabled) this.fetchCandidateAllDetails(filter);
        this.setState({ currentCandidateIndexInPage: pageSize - 1 });
        this.setNextandPrev();
      }
    } else {
      const filter = {
        candidateId: prevCandidateId,
        jobId,
      };
      if (!isCandidatePublishEnabled) this.fetchCandidateAllDetails(filter);
      this.setState(
        {
          candidateId: prevCandidateId,
          currentCandidateIndexInPage: currentCandidateIndexInPage - 1,
        },
        () => {
          this.setNextandPrev();
        }
      );
    }
  };

  isCandidateChecked = candidate => {
    const { currentSelectedAllCandidates } = this.props;
    return currentSelectedAllCandidates.includes(candidate.Id);
  };

  getCandidateRejectedStatusUpdate = candidate => {
    const { sourceCandidateStatusUpdate } = this.props;
    return sourceCandidateStatusUpdate?.[candidate.Id]?.Rejected;
  };

  getCandidateShortlistedStatusUpdate = candidate => {
    const { sourceCandidateStatusUpdate } = this.props;
    return sourceCandidateStatusUpdate?.[candidate.Id]?.Shortlisted;
  };

  onCandidateStatusChangeInCandidateView = (filter, size) => {
    const { setFetchCandidatesFlag } = this.props;
    setFetchCandidatesFlag(true);
    this.changeCandidateStatusForJob(filter, size, false, true);
  };

  onCreditInfoModalCancel = () => {
    this.setState({
      creditInfoVisibility: false,
      nextOperation: null,
    });
  };

  setCreditInfoVisibility = creditInfoVisibility => {
    this.setState({
      creditInfoVisibility,
    });
  };

  toggleDrawerRenderFlag = () => {
    const { drawerRenderFlag } = this.state;
    this.setState({ drawerRenderFlag: !drawerRenderFlag });
  };

  handleAddCandidateSubsegmentModal = async (args1, selectedSubsegmentId) => {
    const { addSubsegmentModalStatus, candidates, setFetchCandidatesFlag, openInNewTab } = this.props;
    const { candidateDrawerVisiblity } = this.state;
    const { candidateId } = addSubsegmentModalStatus;
    const candidate = candidates.filter(item => item.Id === candidateId);
    const filter = {
      status: 'Shortlisted',
      candidate: candidate[0],
      selectedSubsegmentId,
    };
    if (candidateDrawerVisiblity) setFetchCandidatesFlag(true);
    if (openInNewTab) this.changeCandidateStatusForJob(filter, 'small', false, false);
    else this.changeCandidateStatusForJob(filter, 'small', !candidateDrawerVisiblity, false);
  };

  getCandidateDrawerWrapper = () => {
    const {
      candidateDetailsStatus,
      notesContainer,
      callNotesContainer,
      downloadResume,
      history,
      currentJobDetails,
      version,
      openInNewTab,
      featureToggleList,
      config,
      candidateAllNotesFetchStatus,
      candidateListStatus,
      jobId,
      mustHaves,
      niceToHaves,
      onPageChange,
      scrollToRefineIntel,
      candidateContext = 'job',
      isDiversityAttributesVisible,
      openSipCallWindowsApp,
      keywordsToHighlight,
      mustHavesKeywords,
      openSegmentAtsView,
      openJobViewInNewTabCallBack,
      onClickAddJobCallBack,
      defaultFilter,
      fetchCandidates,
    } = this.props;
    const {
      disableNext,
      disablePrev,
      defaultSubTabKey,
      drawerRenderFlag,
      defaultActiveKey,
      candidateId,
      candidateDrawerVisiblity,
      isCandidateSet,
      composeEmailType,
    } = this.state;
    const {
      JobGuid: jobGuid,
      JobTitle: jobTitle,
      Synonyms: jobSynonyms = [],
      CountryCode: jobCountryCode,
    } = currentJobDetails;
    const candidateAllNotesFetched = candidateAllNotesFetchStatus !== 'INPROGRESS';
    const isPaidJobServiceEnabled = featureToggleList.PaidJobService.IsEnabled;
    const jobMustHaves = this.keywordsToHighlight(mustHaves, niceToHaves, currentJobDetails, true);
    const mustHavesToHighlight = mustHavesKeywords || jobMustHaves;
    return (
      <CandidateDrawerWrapper
        subTabKey={defaultSubTabKey}
        version={version}
        onTabClick={this.onTabClick}
        onClose={this.closeCandidateDrawer}
        disableNext={disableNext}
        disablePrev={disablePrev}
        visible={candidateDrawerVisiblity}
        drawerRenderFlag={drawerRenderFlag}
        toggleDrawerRenderFlag={this.toggleDrawerRenderFlag}
        candidateId={candidateId}
        composeEmailType={composeEmailType}
        setComposeEmailType={this.setComposeEmailType}
        downloadResume={downloadResume}
        jobId={jobId}
        jobGuid={jobGuid}
        jobTitle={jobTitle}
        jobSynonyms={jobSynonyms}
        activeKey={defaultActiveKey}
        candidateDetailsStatus={candidateDetailsStatus}
        onCandidateReject={this.onCandidateReject}
        onCandidateStatusChange={this.onCandidateStatusChangeInCandidateView}
        fetchCandidateAllDetails={this.fetchCandidateAllDetails}
        notesContainer={notesContainer}
        onClickAtsPush={this.onClickAtsPush}
        callNotesContainer={callNotesContainer}
        onPageChange={onPageChange}
        keywordsToHighlight={
          keywordsToHighlight === undefined
            ? this.keywordsToHighlight(mustHaves, niceToHaves, currentJobDetails)
            : keywordsToHighlight
        }
        mustHavesKeywords={mustHavesToHighlight}
        unlockCandidateResume={this.unlockResume}
        showPushCandidateButton
        showContactInfoButton={featureToggleList.ContactFetch.IsEnabled}
        history={history}
        aryaVersion={config?.SubscriptionType}
        onClickNext={this.onClickNext}
        onClickPrev={this.onClickPrev}
        getCandidateRejectedStatusUpdate={this.getCandidateRejectedStatusUpdate}
        getCandidateShortlistedStatusUpdate={this.getCandidateShortlistedStatusUpdate}
        openInNewTab={openInNewTab}
        candidateAllNotesFetched={candidateAllNotesFetched}
        candidateListStatus={candidateListStatus}
        isConnectOptionsEnabled
        isJobActionsAllowed={!!jobId}
        openCandidateView={this.openCandidateView}
        ignoreSimilar={!isPaidJobServiceEnabled}
        jobCountryCode={jobCountryCode}
        currentJobDetails={currentJobDetails}
        scrollToRefineIntel={scrollToRefineIntel}
        candidateContext={candidateContext}
        isDiversityAttributesVisible={isDiversityAttributesVisible}
        openSipCallWindowsApp={openSipCallWindowsApp}
        isCandidateSet={isCandidateSet}
        openSegmentAtsView={openSegmentAtsView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
        fetchCandidates={fetchCandidates}
        defaultFilter={defaultFilter}
      />
    );
  };

  keywordsToHighlight = memoize((mustHaves, niceToHaves, currentJobDetails, onlyMustHave) => {
    const { mustHavesKeywords, allKeywordsToHighlight } = getKeyWordsToHighlight({
      mustHaves,
      niceToHaves,
      currentJobDetails,
    });
    return onlyMustHave ? mustHavesKeywords : allKeywordsToHighlight;
  });

  render() {
    const {
      jobId,
      candidateContext = 'job',
      jobGuid,
      config,
      connectStatus,
      candidateListStatus,
      status,
      publishedStatus,
      connectionStatus,
      isBulkActionEnabled,
      version,
      featureToggleList,
      totalCandidate,
      dilatedCandidateTotal,
      openInNewTab,
      candidateAllNotesFetchStatus,
      whiteLabelInfo,
      sourcingStats,
      currentJobDetails,
      mustHaves,
      niceToHaves,
      isFilterApplied,
      clearAllFilter,
      activeTab,
      pageSize,
      currPage,
      isDiversityAttributesVisible,
      currentSelectedAllCandidates,
      updateCandidateBookmarkStatus,
      candidateBookmarkApiStatus,
      candidates,
      candidateAggregationsApiStatus,
      activeSourceName,
      from,
      defaultFilter,
      fetchCandidates,
      setAddSubsegmentModalStatus,
      subsegmentList,
      fetchSubSegmentsAction,
      fetchSubSegmentApiStatus,
      setSubsegmentModalStatus,
      addSubsegmentModalStatus,
      setCreatedSubSegmentId,
      lastCreatedCandidateId,
    } = this.props;
    const { visible: addCandidateSubsegmentModalStatus, singleCandidateAdd } = addSubsegmentModalStatus;
    const isPaidJobServiceEnabled = featureToggleList.PaidJobService.IsEnabled;
    const { shortlistedCount, allCandidatesCount } = getActualSourcingStats(sourcingStats, isPaidJobServiceEnabled);
    if (openInNewTab) {
      return (
        <>
          {addCandidateSubsegmentModalStatus && singleCandidateAdd && (
            <AddCandidateToSegmentPopover
              singleCandidateAdd={singleCandidateAdd}
              visibleStatus={addSubsegmentModalStatus}
              setVisible={setAddSubsegmentModalStatus}
              fetchSubSegments={fetchSubSegmentsAction}
              fetchSubSegmentApiStatus={fetchSubSegmentApiStatus}
              segmentId={jobId}
              handleOnclick={this.handleAddCandidateSubsegmentModal}
              subsegmentList={subsegmentList}
              setSubsegmentModalStatus={setSubsegmentModalStatus}
              shortlistedCount={shortlistedCount}
              setCreatedSubSegmentId={setCreatedSubSegmentId}
              lastCreatedCandidateId={lastCreatedCandidateId}
            />
          )}
          {this.getCandidateDrawerWrapper()}
        </>
      );
    }
    const { creditInfoVisibility } = this.state;

    const connectStatusLoaded = connectStatus !== 'INPROGRESS';
    const candidateAllNotesFetched = candidateAllNotesFetchStatus !== 'INPROGRESS';

    const isFreeTrialPlan = currentJobDetails?.OrderInfo?.ProductVariantName === FREE_TRIAL;
    const dilatedCandidateStartIndex = totalCandidate - (dilatedCandidateTotal || 0) - (currPage - 1) * pageSize;
    if (candidateListStatus !== 'INPROGRESS' && allCandidatesCount === 0 && isPaidJobServiceEnabled) {
      return <FindingCandidates isFreeTrialPlan={isFreeTrialPlan} jobId={jobId} />;
    }
    const currentPageCandidates = candidates.slice(from, from + pageSize);
    return (
      <>
        <Skeleton
          active
          paragraph={{ rows: 12 }}
          loading={this.initialRenderFlag || candidateListStatus === 'INPROGRESS'}
        >
          <CandidateList
            isCandidateChecked={this.isCandidateChecked}
            showCandidateDetail={this.onClickOpenCandidateDrawer}
            setComposeEmailType={this.setComposeEmailType}
            onCandidateCheckBoxClick={this.onCandidateCheckBoxClick}
            showCandidateScore={config?.IsCandidateScoreVisible}
            showVaultName={config?.ShowVaultName}
            candidates={currentPageCandidates}
            onCandidateStatusChange={this.changeCandidateStatusForJob}
            connectStatusLoaded={connectStatusLoaded}
            onCandidateReject={this.onCandidateReject}
            status={status}
            publishedStatus={publishedStatus}
            currentSelectedCandidate={currentSelectedAllCandidates}
            connectionStatus={connectionStatus}
            isFilterApplied={isFilterApplied}
            clearAllFilter={clearAllFilter}
            onClickAtsPush={this.onClickAtsPush}
            aryaVersion={config?.SubscriptionType}
            ignoreSimilar={!isPaidJobServiceEnabled}
            showConnectWidget={featureToggleList.ConnectWidget.IsEnabled}
            getCandidateRejectedStatusUpdate={this.getCandidateRejectedStatusUpdate}
            getCandidateShortlistedStatusUpdate={this.getCandidateShortlistedStatusUpdate}
            showCandidateCheckboxSelection={isBulkActionEnabled}
            version={version}
            keywordsToHighlight={this.keywordsToHighlight(mustHaves, niceToHaves, currentJobDetails)}
            mustHavesKeywords={this.keywordsToHighlight(mustHaves, niceToHaves, currentJobDetails, true)}
            featureToggleList={featureToggleList}
            openCandidateView={this.openCandidateView}
            candidateAllNotesFetched={candidateAllNotesFetched}
            whiteLabelInfo={whiteLabelInfo}
            activeTab={activeTab}
            dilatedCandidateStart={dilatedCandidateStartIndex}
            candidateCount={dilatedCandidateTotal}
            jobId={jobId}
            candidateContext={candidateContext}
            jobGuid={jobGuid}
            revealActiveChannelSourceName={featureToggleList.RevealPortalsUnderGroup.IsEnabled}
            currentJobDetails={currentJobDetails}
            handleContactPull={this.handleContactPull}
            config={config}
            isDiversityAttributesVisible={isDiversityAttributesVisible}
            updateCandidateBookmarkStatus={updateCandidateBookmarkStatus}
            candidateBookmarkApiStatus={candidateBookmarkApiStatus}
            activeSourceName={activeSourceName}
            defaultFilter={defaultFilter}
            fetchCandidates={fetchCandidates}
          />
        </Skeleton>
        {addCandidateSubsegmentModalStatus && singleCandidateAdd && (
          <AddCandidateToSegmentPopover
            singleCandidateAdd={singleCandidateAdd}
            visibleStatus={addSubsegmentModalStatus}
            setVisible={setAddSubsegmentModalStatus}
            fetchSubSegments={fetchSubSegmentsAction}
            fetchSubSegmentApiStatus={fetchSubSegmentApiStatus}
            segmentId={jobId}
            handleOnclick={this.handleAddCandidateSubsegmentModal}
            subsegmentList={subsegmentList}
            setSubsegmentModalStatus={setSubsegmentModalStatus}
            shortlistedCount={shortlistedCount}
            setCreatedSubSegmentId={setCreatedSubSegmentId}
            lastCreatedCandidateId={lastCreatedCandidateId}
          />
        )}
        {this.getCandidateDrawerWrapper()}
      </>
    );
  }
}

export { JobCandidateList as JobCandidateListWithoutStore };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobCandidateList));
