import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Switch } from 'antd';
import styles from './CandidateAutoRejectSwitch.module.scss';
import InfoCircleIcon from '../../Icons/SuggestedIcons/InfoCircleIcon';
import jobMessage from '../JobForm/JobMessages';

export default function CandidateAutoRejectSwitch(props) {
  const { autoRejectValue, onChange, disabled } = props;
  const title = <FormattedMessage {...jobMessage.autoRejectExternalSourceCandidatesLabel} />;
  const infoTitle = <FormattedMessage {...jobMessage.autoRejectionInfoMessage} />;
  const infoDescription = <FormattedMessage {...jobMessage.autoRejectMechanismLabel} />;

  return (
    <div className={styles.candidateAutoRejectSwitchWrapper}>
      <div className={styles.autoRejectSwitchWrapper}>
        <div className={styles.autoRejectSwitchTitle}>{title}</div>
        <div className={styles.autoRejectSwitch}>
          <Switch
            unCheckedChildren={<FormattedMessage {...jobMessage.switchOffLabel} />}
            checkedChildren={<FormattedMessage {...jobMessage.switchOnLabel} />}
            checked={autoRejectValue}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={styles.autoRejectSwitchInfoWrapper}>
        <div className={styles.autoRejectSwitchInfoIconWrapper}>
          <InfoCircleIcon />
        </div>
        <div className={styles.autoRejectSwitchInfo}>
          <div className={styles.autoRejectSwitchInfoTitle}>{infoTitle}</div>
          <div className={styles.autoRejectSwitchInfoDescription}>{infoDescription}</div>
        </div>
      </div>
    </div>
  );
}
