import { Button, Icon, Modal, Spin } from 'antd';
import { FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import CloseMailAndMessageComposeWindowModal from '../Candidate360MailAndMessageComposeWindow/CloseMailAndMessageComposeWindowModal';
import { getSendButtonWithTooltip } from '../Candidate360MailAndMessageComposeWindow/Candidate360MailAndMessageComposeWindow';
import styles from './WorkflowWindow.module.scss';
import { getTotalEmails } from './CandidateWorkflowUtils';
import CandidateViewIconV2 from '../../../Icons/CandidateViewIconV2';
import message from '../../../Containers/CandidateDrawer/messages';

const WorkflowWindow = ({
  htmlType,
  candidateName,
  jobTitle,
  isSendDisabled,
  setModalVisibility,
  getCandidate360MailWindow,
  shouldShowTooltip,
  jobId,
  sendButtonTooltipText,
  isModalVisible,
  workflowId,
  workflowData,
  candidateId,
  jobGuid,
  selectedFromEmail,
  selectedMultipleEmail,
  startCandidateWorkflow,
  id,
  startWorkflowWindowApiStatus,
  setCandidateWorkflowHistoryAction,
}) => {
  const [isCloseModalVisible, setCloseModalVisibility] = React.useState(false);
  const modalContent = getCandidate360MailWindow();

  const modalBodyStyle = {
    height: 'calc(100vh - 91px)',
    padding: '0px',
    backgroundColor: '#FAFAFA',
  };

  const handleWorkflowModalClose = () => {
    setModalVisibility(false);
    if (isCloseModalVisible) setCloseModalVisibility(false);
  };

  const handleOnClickCloseIcon = () => {
    setCloseModalVisibility(true);
  };

  const startWorkflowPayload = () => ({
    CandidateId: candidateId,
    DefinitionId: workflowData.DefinitionId,
    DefinitionName: workflowData.Name,
    JobGuid: jobGuid,
    JobId: jobId,
    EmailActivityFromAddress: selectedFromEmail,
    ToEmails: selectedMultipleEmail,
  });

  const handleStartWorkflow = async () => {
    const payload = startWorkflowPayload();
    await startCandidateWorkflow(payload);
    setModalVisibility(false);
    setCandidateWorkflowHistoryAction(jobGuid, candidateId);
  };

  const startWorkflowButton = (
    <Button
      key="submit"
      type="primary"
      shape="round"
      id={id}
      htmlType={htmlType}
      disabled={isSendDisabled}
      onClick={handleStartWorkflow}
    >
      <FormattedMessage {...message.startWorkflowLabel} />
    </Button>
  );

  const displayedSendButton = getSendButtonWithTooltip({
    shouldShowTooltip,
    sendButtonTooltipText,
    sendButton: startWorkflowButton,
    workflowWindowFlag: true,
  });

  let showMoreEmails = false;
  let totalEmails = 0;

  if (workflowData) {
    const { Activities } = workflowData;
    if (Activities && Activities.length > 0) {
      totalEmails = getTotalEmails(Activities);
    }
  }

  const handleEmailsClick = () => {
    const workflowCandidateContext = JSON.parse(localStorage.getItem('WorkflowCandidateContext')) || {};
    const candidateContextId = `${jobId}_${candidateId}`;
    workflowCandidateContext[candidateContextId] = {
      jobGuid,
      jobId,
      jobTitle,
      selectedFromEmail,
      selectedMultipleEmail,
      candidateId,
      candidateName,
    };
    localStorage.setItem('WorkflowCandidateContext', JSON.stringify(workflowCandidateContext));
  };

  if (totalEmails && totalEmails > 0) showMoreEmails = true;
  const hiddenEmails = totalEmails - 3;
  const footerButtons = (
    <div className={styles.footerContainer}>
      {showMoreEmails ? (
        <div>
          <Link
            to={{
              pathname: `/connect`, // !! Handle for ATS
              search: `?tab=workflows&workflowId=${workflowId}&type=preview&initiateWorkflowFlag=true&candidateContextId=${jobId}_${candidateId}&isWorkflowEditAllowedInPreview=true`,
            }}
            style={{ display: 'flex', alignItems: 'center' }}
            target="_blank"
          >
            <Button type="link" onClick={handleEmailsClick}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <div style={{ textDecoration: 'underline', color: '13c26b' }}>
                  {hiddenEmails > 0 ? `+${hiddenEmails} emails in this Workflow` : `View Workflow`}
                </div>{' '}
                <CandidateViewIconV2 style={{ position: 'relative', bottom: '1px' }} />
              </div>
            </Button>
          </Link>
        </div>
      ) : null}
      <div className={styles.actionButtons}>{displayedSendButton}</div>
    </div>
  );
  const windowTitle = workflowData?.Name ?? 'New Workflow';
  const title = (
    <div className={styles.candidate360ComposeModalTitle} style={{ fontWeight: '400' }}>
      <span>{windowTitle}</span>
      <div className={styles.expandShrinkIcons}>
        <Icon type="close" onClick={handleOnClickCloseIcon} />
      </div>
    </div>
  );
  return (
    <div>
      <Modal
        className={classNames('candidate-360-compose-modal maximized-modal')}
        title={title}
        visible={isModalVisible}
        zIndex={2222}
        footer={[footerButtons]}
        style={{ maxHeight: '100vh', overflow: 'hidden' }}
        wrapClassName={styles.modalContainer}
        onCancel={handleWorkflowModalClose}
        maskClosable={false}
        bodyStyle={modalBodyStyle}
        closable={false}
      >
        <div className="maximized-modal-content">
          <Spin spinning={startWorkflowWindowApiStatus === 'INPROGRESS'}>{modalContent}</Spin>
        </div>
      </Modal>
      {isCloseModalVisible && (
        <CloseMailAndMessageComposeWindowModal
          isCloseModalVisible={isCloseModalVisible}
          setCloseModalVisibility={setCloseModalVisibility}
          onCloseComposeWindow={handleWorkflowModalClose}
          context="workflow"
        />
      )}
    </div>
  );
};

export default WorkflowWindow;
