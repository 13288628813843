import React from 'react';
import { Modal, Input, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './SaveReportQueryModal.module.scss';
import { validateQueryName } from '../../../../Utils/ReportPortalUtils/FormUtils';
import reportPortalMessages from '../../ReportPortalMessages';

export function SaveReportQueryModal(props) {
  const { queryDetails, isVisible, onCancel, onSave, saveQueryApiStatus, form } = props;

  const onSaveClick = () => {
    form.validateFields().then(values => {
      onSave({ queryName: values?.queryName, savedQueryId: queryDetails?.SavedReportId });
    });
  };

  const saveApiStatus = saveQueryApiStatus?.status?.toUpperCase();
  const saveApiStatusError = saveQueryApiStatus?.error?.data?.toLowerCase();
  const saveApiStatusErrorCode = saveQueryApiStatus?.error?.status;

  const getErrorMessage = () => {
    if (saveApiStatus === 'FAILED') {
      if (saveApiStatusError === 'already name exists' && saveApiStatusErrorCode === 422) {
        return (
          <div className={styles.errorMessage}>
            <FormattedMessage {...reportPortalMessages.saveReportQueryModalAlreadyExistsError} />
          </div>
        );
      }
      return (
        <div className={styles.errorMessage}>
          <FormattedMessage {...reportPortalMessages.saveReportQueryModalApiStatusError} />
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      title="Save Query"
      okText={queryDetails ? 'Update Query' : 'Save Query'}
      visible={isVisible}
      onOk={onSaveClick}
      onCancel={onCancel}
      okButtonProps={{ loading: saveApiStatus === 'INPROGRESS', shape: 'round' }}
      cancelButtonProps={{ shape: 'round' }}
      destroyOnClose
      centered
      maskClosable={false}
    >
      <Form>
        <Form.Item label="Query Name" colon={false}>
          {form.getFieldDecorator(`queryName`, {
            initialValue: queryDetails?.ReportName,
            rules: [{ validator: validateQueryName }],
          })(<Input placeholder="Enter query name" maxLength={500} />)}
        </Form.Item>
      </Form>
      {getErrorMessage()}
    </Modal>
  );
}
export default Form.create()(SaveReportQueryModal);
