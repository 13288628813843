import React from 'react';
import { Col, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import RemoteJob from '../../../../../CompoundComponents/LocationWrapper/RemoteJob/RemoteJob';
import RegionSelect from '../../../../../CompoundComponents/MultiLocationWrapper/RegionSelect/RegionSelect';
import LocationRegionSelectDropdown from '../../../../../CompoundComponents/LocationWrapper/LocationRegionSelectDropdown/LocationRegionSelectDropdown';
import MultiLocationWrapper from '../../../../../CompoundComponents/MultiLocationWrapper/MultiLocationWrapper';
import EnhancedAddLocationButton from '../../../../../CompoundComponents/MultiLocationWrapper/EnhancedAddLocationButton/EnhancedAddLocationButton';
import { getLocationSectionConfig } from '../../../../../Utils/MultiLocationUtils';
import CountrySelect from '../../../../../CompoundComponents/MultiLocationWrapper/CountrySelect/CountrySelect';
import LocationSelect from '../../../../../CompoundComponents/MultiLocationWrapper/LocationSelect/LocationSelect';
import OutputLocations from '../../../../../CompoundComponents/MultiLocationWrapper/OutputLocations/OutputLocations';
import MileSelect from '../../../../../CompoundComponents/MultiLocationWrapper/MileSelect/MileSelect';
import LocationWrapper from '../../../../../CompoundComponents/LocationWrapper/LocationWrapper';
import { locationLayers, searchLocationPlaceholder } from '../../../../../Utils/LocationUtils';
import message from '../../../ManualSearchMessages';

function EnhancedLocationWrapperInput(props) {
  const {
    form,
    isPaidJobServiceEnabled,
    isCountryWideSearchEnabled,
    defaultIsStateWideSearchChecked,
    defaultIsRemoteLocation,
    defaultMilesObject,
    AppName,
    candidateContext,
    isManualSearchFormMinimized,
    onChange,
    value,
    countryOptions,
    fetchRegionsByCountryCode,
    defaultMiles,
  } = props;
  const InputGroup = Input.Group;
  const isRemoteJobChecked = form.getFieldValue('IsRemoteJob');
  const locationContext = form.getFieldValue('LocationContext');
  const isDisabled = candidateContext === 'job' || isCountryWideSearchEnabled || isRemoteJobChecked;
  const displayedLocations = value.filter(
    location => location?.Location || location?.Region || location?.State || location?.isVisible
  );
  const { isRegionSelectionLimitReached, isLocationSelectionLimitReached, isCountryWideSearchEnabledCountry } =
    getLocationSectionConfig(displayedLocations, countryOptions, true);
  const key = value?.[value.length - 1]?.Id || null;
  const location = value?.[value.length - 1] || null;
  const isFirstELement = value?.length === 1;

  const countryCode = value?.[0]?.CountryCode;
  React.useEffect(() => {
    if (countryCode) fetchRegionsByCountryCode({ countryCode });
  }, [countryCode]);

  return (
    <>
      {!isPaidJobServiceEnabled ? (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <LocationWrapper form={form} layers={locationLayers}>
            <RemoteJob initialValue={defaultIsRemoteLocation} isAdvanceSearchV2 />
          </LocationWrapper>
          <span> <FormattedMessage {...message.remoteJobTitle} /></span>
          <span style={{ opacity: '0.49' }}>({AppName}  <FormattedMessage {...message.remoteJobInfoMessage} />)</span>
        </div>
      ) : null}
      <MultiLocationWrapper
        onChange={onChange}
        currentKey={key}
        locations={value}
        currentLocation={location}
        isLocationSelectionLimitReached={isLocationSelectionLimitReached}
        isRegionSelectionLimitReached={isRegionSelectionLimitReached}
        isCountryWideSearchEnabledCountry={isCountryWideSearchEnabledCountry}
        countryOptions={countryOptions}
        isDisabled={isDisabled}
        defaultMiles={defaultMiles}
        isAdvanceSearch
        layers={locationLayers}
        form={form}
      >
        <div style={{ display: 'flex', flexDirection: 'row', gap: isManualSearchFormMinimized ? '40px' : '70px' }}>
          <LocationWrapper form={form} layers={locationLayers}>
            <LocationRegionSelectDropdown
              size="large"
              initialValue="Location"
              isCountryWideSearchEnabledCountry={isCountryWideSearchEnabledCountry}
              onChange={onChange}
              currentLocation={location}
              sourcingLocations={value}
            />
          </LocationWrapper>
          {form.getFieldDecorator('IsStateWideSearch', { initialValue: defaultIsStateWideSearchChecked })}
          {locationContext === 'Location' ? (
            <InputGroup compact style={{ width: '700px', maxWidth: 'fit-content' }}>
              <div style={{ minWidth: '120px', width: 'max-content' }}>
                {' '}
                <CountrySelect isDisabled={isDisabled} />
              </div>
              <div style={{ width: 'max-content', minWidth: '240px' }}>
                <LocationSelect placeholder={searchLocationPlaceholder} isRemoteJobChecked={isRemoteJobChecked} />
              </div>
              <div
                style={{
                  paddingLeft: '20px',
                  minWidth: '10px',
                  maxWidth: '200px',
                  width: isManualSearchFormMinimized ? '150px' : '200px',
                }}
              >
                <MileSelect
                  isRemoteJob={isRemoteJobChecked}
                  locationType="MultiLocation"
                  isCountryWideSearchEnabled={isCountryWideSearchEnabled}
                  initialRange={defaultMilesObject}
                  isManualSearchFormMinimized={isManualSearchFormMinimized}
                  currentKey={key}
                  form={form}
                  currentLocation={location}
                  locationContext={locationContext}
                />
              </div>
            </InputGroup>
          ) : (
            <>
              {' '}
              <InputGroup
                compact
                style={{ maxWidth: isManualSearchFormMinimized ? '400px' : 'fit-content', minWidth: 'fit-content' }}
              >
                <div style={{ minWidth: '120px', width: 'max-content' }}>
                  <CountrySelect isDisabled={isDisabled} />
                </div>

                <div style={{ width: '200px' }}>
                  <RegionSelect isRemoteJobChecked={isRemoteJobChecked} />
                </div>
                <Col span={7}>
                  <div style={{ paddingLeft: '20px', width: '200px' }}>
                    {' '}
                    <MileSelect
                      isRemoteJob={isRemoteJobChecked}
                      locationType="MultiLocation"
                      currentKey={key}
                      isCountryWideSearchEnabled={isCountryWideSearchEnabled}
                      initialRange={defaultMilesObject}
                      isManualSearchFormMinimized={isManualSearchFormMinimized}
                      form={form}
                      currentLocation={location}
                      locationContext={locationContext}
                    />
                  </div>
                </Col>
              </InputGroup>
            </>
          )}
          <EnhancedAddLocationButton
            isRemoteJobChecked={isRemoteJobChecked}
            locationContext={locationContext}
            isFirstELement={isFirstELement}
            currentLocation={location}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            width: isManualSearchFormMinimized ? '750px' : '1100px',
            flexWrap: 'wrap',
          }}
        >
          <OutputLocations
            isRemoteJobChecked={isRemoteJobChecked}
            isManualSearchFormMinimized={isManualSearchFormMinimized}
            isAdvanceSearch
            form={form}
          />
        </div>
      </MultiLocationWrapper>
    </>
  );
}

export default EnhancedLocationWrapperInput;
