/* eslint-disable */
import _ from 'lodash';
import messages from '../Containers/CandidatePipeline/messages';
import { getActualSourcingStats } from './JobSourcingStatsUtil';
import { selectPlanCustomTooltip } from './TooltipUtils';
import EngageIcon from '../Icons/EngageIcon';
import { engagedTooltipTitle } from '../Components/SampleCandidatePipeline/SampleCandidatePipeline';
import eventTypes from '../Analytics/EventTypes';
import { isPulseUser } from './ConfigUtils';
import { getCandidatePublishStatus } from './CandidatePublishUtils';

export function setPipelinesDisabledStatus(pipelineData = [], isPremiumProduct) {
  return pipelineData?.map(pipeline => {
    const newPipelineData = _.cloneDeep(pipeline);
    if (['connected', 'applied'].includes(pipeline?.name?.toLowerCase())) {
      newPipelineData.disabled = !isPremiumProduct;
    }
    return newPipelineData;
  });
}

export function getUpgradeContent({ productVariantName, jobGuid, jobId }) {
  const selectPlanLink = `/select-plan?jobGuid=${jobGuid}&jobId=${jobId}`;
  const buttonText = 'Select a plan';
  switch (productVariantName) {
    case 'Basic':
      return {
        link: `/select-plan?jobGuid=${jobGuid}&jobId=${jobId}&isUpgrade=${true}`,
        buttonText: 'Engage Candidates',
      };
    case 'FreeTrial':
      return {
        link: `/select-plan?jobGuid=${jobGuid}&jobId=${jobId}&isFromFree=${true}`,
        buttonText: 'Upgrade',
      };

    default:
      return {
        link: selectPlanLink,
        buttonText,
      };
  }
}

export function getPipelineTooltipContent({ productVariantName, size, jobGuid, jobId, role }) {
  if (size === 'small') return 'Engaged';
  if (productVariantName !== 'Premium' && role !== 'Service Rep') {
    const content = getUpgradeContent({ productVariantName, jobGuid, jobId });
    return selectPlanCustomTooltip(content.link, engagedTooltipTitle, EngageIcon, content.buttonText);
  }
  return null;
}

export function getPipelineData({
  intl,
  sourcingStats,
  onPipelineItemClick,
  size,
  activeByDefault,
  featureToggleList,
  jobId,
  userConfig,
  jobsById,
  productVariantsById,
  aryaSearchDisplayCount,
  pipelineContext,
  smartRecruiterVersion,
  adminFeatures,
}) {
  const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList);
  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
  const isEnagedBucketEnabled = featureToggleList?.EngagedBucket?.IsEnabled;
  const {
    AryaRanking: { IsEnabled: isAryaRankingEnabled = false, IsAllowed: isAryaRankingUnlocked = false } = {},
    AdvanceSearchV2: { IsEnabled: isAdvanceSearchV2Enabled = false } = {},
    ConnectedCandidatesV2: { IsEnabled: isConnectedV2Enabled } = {},
  } = featureToggleList || {};

  let changeBucketColor = isAdvanceSearchV2Enabled;
  if (smartRecruiterVersion) changeBucketColor = smartRecruiterVersion === 'v2';

  const {
    sourcedCount,
    shortlistedCount,
    connectedCount,
    appliedCount,
    engagedCount,
    allCandidatesCount,
    favouriteCount,
  } = getActualSourcingStats(sourcingStats, isPaidJobServiceEnabled);

  const jobGuid = _.get(jobsById, [jobId, 'JobGuid'], '');
  const Role = _.get(userConfig, ['Role'], '');
  const orderInfo = _.get(jobsById, [jobId, 'OrderInfo'], {});
  const { ProductVariantName } = orderInfo;
  const premiumProductVariant = Object.values(productVariantsById).find(variant => variant.Name === 'Premium');
  const premiumPlanDisplayName = premiumProductVariant ? premiumProductVariant.DisplayName : 'Premium';
  const defaultActiveClassname = activeByDefault && changeBucketColor ? 'default-activated-pipeline-btn-v2' : '';
  let showAryaSearch = isAdvanceSearchV2Enabled;
  if (smartRecruiterVersion) showAryaSearch = smartRecruiterVersion === 'v2';

  let pipelineData = [
    {
      className: `sourced-btn ${activeByDefault ? 'sourced-btn-activated' : ''} ${
        changeBucketColor ? 'sourced-btn-v2' : ''
      } ${defaultActiveClassname}`,
      activatedClassName: `sourced-btn-activated ${changeBucketColor ? 'sourced-btn-activated-v2' : ''}`,
      name: 'sourced',
      tooltipContent: size === 'small' ? 'Sourced' : null,
      onClick: () => onPipelineItemClick('sourced'),
      content:
        size === 'small'
          ? sourcedCount
          : `${
              showAryaSearch
                ? intl.formatMessage({
                    ...messages.aryaSearch,
                  })
                : intl.formatMessage({
                    ...messages.sourced,
                  })
            } ${showAryaSearch ? '' : `(${sourcedCount})`} `,
      disabled: false,
      dry: activeByDefault ? sourcedCount === 0 : false,
      skEventName: eventTypes.candidate.candidateList.goToSourcedBucket,
    },
    {
      className: `shortlisted-btn ${activeByDefault ? 'shortlisted-btn-activated' : ''} ${
        changeBucketColor ? 'shortlisted-btn-v2' : ''
      } ${defaultActiveClassname}`,
      activatedClassName: `shortlisted-btn-activated ${changeBucketColor ? 'shortlisted-btn-activated-v2' : ''}`,
      name: 'shortlisted',
      tooltipContent: size === 'small' ? 'Shortlisted' : null,
      onClick: () => onPipelineItemClick('shortlisted'),
      content:
        size === 'small'
          ? shortlistedCount
          : `${intl.formatMessage({
              ...messages.shortlisted,
            })} (${shortlistedCount})`,
      disabled: false,
      dry: activeByDefault ? shortlistedCount === 0 : false,
      skEventName: eventTypes.candidate.candidateList.goToShortlistedBucket,
    },
    {
      className: `connected-btn ${activeByDefault ? 'connected-btn-activated' : ''} ${
        changeBucketColor ? 'connected-btn-v2' : ''
      } ${defaultActiveClassname}`,
      activatedClassName: `connected-btn-activated ${changeBucketColor ? 'connected-btn-activated-v2' : ''}`,
      name: 'connected',
      tooltipContent: size === 'small' ? 'Connected' : null,
      onClick: () => (isConnectedV2Enabled ? onPipelineItemClick('contacted') : onPipelineItemClick('connected')),
      content:
        size === 'small'
          ? `${connectedCount}`
          : `${intl.formatMessage({
              ...messages.connected,
            })} (${connectedCount})`,
      disabled: false,
      dry: activeByDefault ? connectedCount === 0 : false,
      skEventName: eventTypes.candidate.candidateList.goToConnectedBucket,
    },
    {
      className: `applied-btn ${activeByDefault ? 'applied-btn-activated' : ''} ${
        changeBucketColor ? 'applied-btn-v2' : ''
      } ${defaultActiveClassname}`,
      activatedClassName: `applied-btn-activated ${changeBucketColor ? 'applied-btn-activated-v2' : ''}`,
      name: 'applied',
      tooltipContent: size === 'small' ? 'Applied' : null,
      onClick: () => onPipelineItemClick('applied'),
      content:
        size === 'small' ? `${appliedCount}` : `${intl.formatMessage({ ...messages.applied })} (${appliedCount})`,
      disabled: false,
      dry: activeByDefault ? appliedCount === 0 : false,
      skEventName: eventTypes.candidate.candidateList.goToAppliedBucket,
    },
  ];

  let showAryaRanked = isAdvanceSearchV2Enabled && isAryaRankingEnabled;
  if (showAryaRanked) {
    const aryaRankedContent =
      isAryaRankingUnlocked || smartRecruiterVersion === 'v2'
        ? `${intl.formatMessage({
            ...messages.aryaRanked,
          })} (${sourcedCount})`
        : `${intl.formatMessage({
            ...messages.aryaRanked,
          })}`;
    let disableAryaRanking = !isAryaRankingUnlocked;
    if (smartRecruiterVersion === 'v2') {
      disableAryaRanking = false;
    }
    const aryaRankedSmallCount = isAryaRankingUnlocked ? sourcedCount : null;
    const elementToInsert = {
      className: `aryaranked-btn ${activeByDefault ? 'aryaranked-btn-activated' : ''} ${
        changeBucketColor ? 'aryaranked-btn-v2' : ''
      } ${defaultActiveClassname}`,
      activatedClassName: `aryaranked-btn-activated ${changeBucketColor ? 'aryaranked-btn-activated-v2' : ''}`,
      name: 'ranked',
      tooltipContent: size === 'small' ? 'Arya Ranked' : null,
      onClick: () => {
        onPipelineItemClick('ranked');
      },
      content: size === 'small' ? aryaRankedSmallCount : aryaRankedContent,
      disabled: disableAryaRanking,
      isLocked: disableAryaRanking,
      dry: activeByDefault ? sourcedCount === 0 : false,
      skEventName: eventTypes.candidate.candidateList.goToSourcedBucket,
    };
    pipelineData.splice(1, 0, elementToInsert);
  }
  if (isCandidatePublishEnabled) {
    const elementToInsert = {
      className: `sourced-btn ${activeByDefault ? 'sourced-btn-activated' : ''} ${
        changeBucketColor ? 'sourced-btn-v2' : ''
      } ${defaultActiveClassname}`,
      activatedClassName: `sourced-btn-activated ${changeBucketColor ? 'sourced-btn-activated-v2' : ''}`,
      name: 'favourite',
      tooltipContent: size === 'small' ? 'Favourite' : null,
      onClick: () => onPipelineItemClick('favourite'),

      content:
        size === 'small'
          ? `${favouriteCount}`
          : `${intl.formatMessage({ ...messages.aryaFavourite })} (${favouriteCount})`,
      disabled: false,
      dry: activeByDefault ? favouriteCount === 0 : false,
      skEventName: eventTypes.candidate.candidateList.goToFavouriteBucket,
    };
    pipelineData.splice(1, 0, elementToInsert);
  }

  if (isPaidJobServiceEnabled) {
    pipelineData.splice(2, 2);
  }

  if (Role === 'Service Rep') {
    pipelineData = setPipelinesDisabledStatus(pipelineData, ProductVariantName?.toLowerCase() === 'premium');
  }

  if (smartRecruiterVersion) {
    showAryaRanked = smartRecruiterVersion === 'v2';
  }

  if (isEnagedBucketEnabled) {
    let commonProps = {
      className: `engaged-btn ${activeByDefault ? 'engaged-btn-activated' : ''} ${
        changeBucketColor ? 'engaged-btn-v2' : ''
      } ${defaultActiveClassname}`,
      activatedClassName: `engaged-btn-activated ${changeBucketColor ? 'engaged-btn-activated-v2' : ''}`,
      name: 'engaged',
      onClick: () => onPipelineItemClick('engaged'),
      dry: activeByDefault ? engagedCount === 0 : false,
      disabled: false,
      tooltipContent: size === 'small' ? 'Engaged' : null,
      content: size === 'small' ? engagedCount : `${intl.formatMessage({ ...messages.engaged })} (${engagedCount})`,
    };
    if (isPulseUser()) {
      const content = ProductVariantName !== 'Premium' ? `${premiumPlanDisplayName} only` : engagedCount;
      commonProps = {
        ...commonProps,
        tooltipContent: getPipelineTooltipContent({
          productVariantName: ProductVariantName,
          size,
          jobGuid,
          jobId,
          role: Role,
        }),
        content: size === 'small' ? `${engagedCount}` : `${intl.formatMessage({ ...messages.engaged })} (${content})`,
        disabled: ProductVariantName !== 'Premium',
        tooltipPlacement: 'bottom',
        overlayClassName: 'custom-tooltip',
      };
    }
    pipelineData.push(commonProps);
  }
  if (pipelineContext === 'jobsPage' && isAdvanceSearchV2Enabled) pipelineData.shift();
  return { pipelineData, allCandidatesCount };
}

const getCandidateStatusFilter = (pipelineName, isPaidJobService, featureToggleList, smartRecruiterVersion) => {
  const filter = (() => {
    switch (pipelineName) {
      case 'sourced':
        return { Status: 'sourced', ConnectionStatus: null, PublishedStatus: 'sourced' };

      case 'shortlisted':
        return {
          Status: 'shortlisted',
          ConnectionStatus: null,
          PublishedStatus: 'shortlisted',
        };
      case 'subsegment':
        return {
          Status: 'subsegment',
          ConnectionStatus: null,
          PublishedStatus: 'subsegment',
        };
      case 'connected':
        return { Status: null, ConnectionStatus: 'connected' };
      case 'contacted':
        return { Status: null, ConnectionStatus: 'contacted' };
      case 'applied':
        return { Status: null, ConnectionStatus: null, Applied: true };
      case 'rejected':
        return { Status: 'rejected', ConnectionStatus: null, PublishedStatus: 'rejected' };
      case 'failed':
        return { Status: null, ConnectionStatus: 'failed' };
      case 'engaged':
        return { Status: null, ConnectionStatus: 'engaged', PublishedStatus: 'sourced' };
      case 'favourite':
        return { Status: 'favourite' };
      default:
        return { Status: 'sourced', ConnectionStatus: null, PublishedStatus: 'sourced' };
    }
  })();
  if (isPaidJobService) {
    filter.Status = null;
  } else {
    filter.PublishedStatus = null;
  }
  const {
    AryaRanking: { IsEnabled: isAryaRankingEnabled, IsAllowed: isAryaRankingUnlocked },
    AdvanceSearchV2: { IsEnabled: isAdvanceSearchV2Enabled },
  } = featureToggleList;
  let setAryaRanked =
    pipelineName === 'ranked' && isAryaRankingEnabled && isAryaRankingUnlocked && isAdvanceSearchV2Enabled;
  if (smartRecruiterVersion) {
    setAryaRanked = smartRecruiterVersion === 'v2' && pipelineName === 'ranked';
  }
  if (setAryaRanked) filter.isAryaRankedTab = true;
  if (pipelineName !== 'ranked') filter.isAryaRankedTab = false;
  return filter;
};

export { getCandidateStatusFilter };
