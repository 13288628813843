import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, Icon } from 'antd';
import styles from './ReActivationAlert.module.scss';
import jobMessage from '../../../Components/JobForm/JobMessages';

const title = <FormattedMessage {...jobMessage.reactivationAlertInfo} />;

export default function ReActivationAlert({ retainId }) {
  const [reActivationAlertVisibility, setReActivationAlertVisibility] = React.useState(false);

  React.useEffect(() => {
    if (retainId === 1) {
      setReActivationAlertVisibility(true);
    }
  }, [retainId]);

  const onClickOk = () => {
    setReActivationAlertVisibility(false);
  };

  return (
    <Modal
      title={null}
      centered
      visible={reActivationAlertVisibility}
      width="566px"
      footer={null}
      closable={false}
      zIndex={1000}
    >
      <div className={styles.reActivationAlertModal}>
        <div className={styles.reActivationAlertModalContent}>
          <div className={styles.infoIcon}>
            <Icon type="info-circle" />
          </div>
          <div>
            <p className={styles.subTitle}>{title}</p>
          </div>
        </div>
        <div className={styles.footerButtons}>
          <Button type="primary" shape="round" onClick={onClickOk}>
            <FormattedMessage {...jobMessage.gotItLabel} />;
          </Button>
        </div>
      </div>
    </Modal>
  );
}
