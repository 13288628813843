import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as AtsActions from '../../Actions/AtsActions';
import { postCandidateNotes as _postCandidateNotes } from '../../Actions/CandidateActions';
import {
  addContact as _addContact,
  postCallNotes as _postCallNotes,
  pushCandidateToConnect as _pushCandidateToConnect,
  fetchPersonality as _fetchPersonality,
  getRefundForInvalidContacts as _getRefund,
} from '../../Actions/ConnectActions';
import * as candidateActions from '../../Actions/CandidateActions';
import Candidate360ViewWrapper from '../../Components/CandidateDrawer/Candidate360ViewWrapper';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getProductVariantsById } from '../../Reducers/AryaPayOrderReducer';
import { getAtsConfig } from '../../Reducers/AtsReducer';
import {
  getIsDownloadedStatus,
  getCandidateJobsTotalCount,
  getCandidateListsAndCampaignsTotalCount,
  getCandidateIntel,
} from '../../Reducers/CandidateReducer';
import {
  getConfig,
  getConnectConfig,
  getMaskingConfig,
  getVaults,
  getWhiteLabelInformation,
} from '../../Reducers/ConfigReducer';
import { getCandidatesConnectInfo, getConversationsById, getCreditsRefunded } from '../../Reducers/ConnectReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getConnectUsersById, getUsersById } from '../../Reducers/UserReducer';
import { setSubsegmentAddModalStatus } from '../../Actions/SubSegmentActions';
import { getActiveTab, getActiveSourceName } from '../../Reducers/JobCandidatesTabReducer';
import { getJobsById } from '../../Reducers/JobReducer';

const mapStateToProps = (state, props) => {
  const {
    candidate: { Id },
  } = props;
  return {
    // TODO: getCandidatesConnectInfo: Get the current candidate's connect info instead of all candidates
    connectInfo: getCandidatesConnectInfo(state),
    getPersonalityStatus: getApiStatus(state, 'getPersonalityStatus'),
    fetchPersonalityStatus: getApiStatus(state, 'fetchPersonalityStatus'),
    resumeUnlockStatus: getApiStatus(state, 'resumeUnlockStatus'),
    candidateDetailsStatus: getApiStatus(state, 'candidateDetailsStatus'),
    candidateBookmarkApiStatus: getApiStatus(state, 'candidateBookmarkApiStatus'),
    usersById: getUsersById(state),
    connectUsersById: getConnectUsersById(state),
    userConfig: getConfig(state),
    pushCandToAtsStatus: getApiStatus(state, 'pushCandToAtsStatus'),
    conversationsById: getConversationsById(state),
    atsConfig: getAtsConfig(state),
    IsDownloadedStatus: getIsDownloadedStatus(state),
    connectConfig: getConnectConfig(state),
    featureToggleList: getFeatureToggleList(state),
    vaults: getVaults(state),
    whiteLabelInfo: getWhiteLabelInformation(state),
    creditsRefunded: getCreditsRefunded(state),
    productVariantsById: getProductVariantsById(state),
    maskingConfig: getMaskingConfig(state),
    activeTab: getActiveTab(state),
    candidateJobsTotalCount: getCandidateJobsTotalCount(state),
    candidateSegmentsTotalCount: getCandidateListsAndCampaignsTotalCount(state),
    fetchCandidateJobsApiStatus: getApiStatus(state, 'fetchCandidateJobsApiStatus'),
    activeSourceName: getActiveSourceName(state),
    candidateStatusUpdate: getApiStatus(state, 'sourceCandidateStatusUpdate'),
    bulkNotesFetchApiStatus: getApiStatus(state, 'fetchBulkCandidateNotesApiStatus'),
    candidateIntel: getCandidateIntel(state, Id),
    jobsById: getJobsById(state),
  };
};
const mapsDispatchToProps = {
  postCandidateNotes: _postCandidateNotes,
  addContact: _addContact,
  postCallNotes: _postCallNotes,
  pushCandidateToConnect: _pushCandidateToConnect,
  pushCandidateToAts: AtsActions.pushCandidate,
  fetchPersonality: _fetchPersonality,
  getRefundForInvalidContacts: _getRefund,
  updateCandidateBookmarkStatus: candidateActions.updateCandidateBookmarkStatus,
  fetchCandidateMatchingJobs: candidateActions.fetchCandidateMatchingJobs,
  setAddSubsegmentModalStatus: setSubsegmentAddModalStatus,
  fetchBulkCandidateAllNotes: candidateActions.fetchBulkCandidateAllNotes,
  fetchCandidateIntel: candidateActions.fetchCandidateIntel,
};
function Candidate360ViewContainer(props) {
  const {
    openCandidateView,
    candidate,
    currentJobDetails,
    featureToggleList,
    connectInfo,
    candidateType,
    onCandidateStatusChange,
    candidateContext,
    onCandidateReject,
    getCandidateRejectedStatusUpdate,
    getCandidateShortlistedStatusUpdate,
    jobId,
    keywordsToHighlight,
    mustHavesKeywords,
    isTryNow,
    downloadResume,
    allowResumeDownload,
    version,
    jobGuid,
    candidateDetailsStatus,
    candidateBookmarkApiStatus,
    updateCandidateBookmarkStatus,
    tabName,
    jobCountryCode,
    toggleBotConfigDrawer,
    onCandidate360TabChange,
    whiteLabelInfo,
    activeTab,
    isDiversityAttributesVisible,
    onTabClick,
    activeKey,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    candidateListStatus,
    maskingConfig,
    fetchCandidateMatchingJobs,
    ignoreSimilar,
    aryaVersion,
    showCandidateSource,
    showPortalIcon,
    userConfig,
    isJobActionsAllowed,
    unlockCandidateResume,
    resumeUnlockStatus,
    selectPlan,
    creditsRefunded,
    history,
    candidateJobsTotalCount,
    fetchCandidateJobsApiStatus,
    revealActiveChannelSourceName,
    pushCandToAtsStatus,
    atsConfig,
    showPushError,
    showContactInfoButton,
    showPushCandidateButton,
    onClickAtsPush,
    openInNewTab,
    isCandidateMatchingJobModalVisible,
    setCandidateMatchingJobModalVisiblity,
    onPushCandToAts,
    isCandidateDownloaded,
    notesContainer,
    onNotesChange,
    currentNotes,
    candidateId,
    postCandidateNotes,
    usersById,
    connectUsersById,
    showCandidateDetailTabs,
    openSipCallWindowsApp,
    activeSourceName,
    candidateStatusUpdate,
    candidateSegmentsTotalCount,
    bulkNotesFetchApiStatus,
    fetchBulkCandidateAllNotes,
    fetchCandidateIntel,
    candidateIntel,
    productVariantsById,
    jobsById,
    composeEmailType,
    setComposeEmailType,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    isConnectTab,
    fetchCandidates,
    defaultFilter,
    setAddSubsegmentModalStatus,
    mspIcons,
    setMspIcons,
  } = props;
  return (
    <Candidate360ViewWrapper
      openSipCallWindowsApp={openSipCallWindowsApp}
      openCandidateView={openCandidateView}
      candidate={candidate}
      currentJobDetails={currentJobDetails}
      featureToggleList={featureToggleList}
      connectInfo={connectInfo}
      candidateType={candidateType}
      onCandidateStatusChange={onCandidateStatusChange}
      onCandidateReject={onCandidateReject}
      candidateContext={candidateContext}
      isDiversityAttributesVisible={isDiversityAttributesVisible}
      candidateShortlistedStatusUpdate={
        getCandidateShortlistedStatusUpdate ? getCandidateShortlistedStatusUpdate(candidate) : undefined
      }
      candidateRejectedStatusUpdate={
        getCandidateRejectedStatusUpdate ? getCandidateRejectedStatusUpdate(candidate) : undefined
      }
      keywordsToHighlight={keywordsToHighlight}
      mustHavesKeywords={mustHavesKeywords}
      jobId={jobId}
      jobGuid={jobGuid}
      isTryNow={isTryNow}
      downloadResume={downloadResume}
      allowResumeDownload={allowResumeDownload}
      version={version}
      candidateDetailsStatus={candidateDetailsStatus}
      candidateBookmarkApiStatus={candidateBookmarkApiStatus?.[candidate?.Id]}
      updateCandidateBookmarkStatus={updateCandidateBookmarkStatus}
      jobCountryCode={jobCountryCode}
      toggleBotConfigDrawer={toggleBotConfigDrawer}
      tabName={tabName}
      onCandidate360TabChange={onCandidate360TabChange}
      appName={_.get(whiteLabelInfo, ['AppName'], 'Arya')}
      activeSubTab={activeKey}
      onSubTabClick={onTabClick}
      isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
      setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
      candidateListStatus={candidateListStatus}
      maskingConfig={maskingConfig}
      fetchCandidateMatchingJobs={fetchCandidateMatchingJobs}
      ignoreSimilar={ignoreSimilar}
      aryaVersion={aryaVersion}
      showCandidateSource={showCandidateSource}
      showPortalIcon={showPortalIcon}
      userConfig={userConfig}
      isJobActionsAllowed={isJobActionsAllowed}
      unlockCandidateResume={unlockCandidateResume}
      resumeUnlockStatus={resumeUnlockStatus}
      selectPlan={selectPlan}
      creditsRefunded={creditsRefunded}
      history={history}
      candidateJobsTotalCount={candidateJobsTotalCount}
      fetchCandidateJobsApiStatus={fetchCandidateJobsApiStatus}
      whiteLabelInfo={whiteLabelInfo}
      revealActiveChannelSourceName={revealActiveChannelSourceName}
      pushCandToAtsStatus={pushCandToAtsStatus}
      atsConfig={atsConfig}
      showPushError={showPushError}
      showContactInfoButton={showContactInfoButton}
      onClickAtsPush={onClickAtsPush}
      showPushCandidateButton={showPushCandidateButton}
      openInNewTab={openInNewTab}
      isCandidateMatchingJobModalVisible={isCandidateMatchingJobModalVisible}
      setCandidateMatchingJobModalVisiblity={setCandidateMatchingJobModalVisiblity}
      isCandidateDownloaded={isCandidateDownloaded}
      onPushCandToAts={onPushCandToAts}
      notesContainer={notesContainer}
      onNotesChange={onNotesChange}
      currentNotes={currentNotes}
      candidateId={candidateId}
      postCandidateNotes={postCandidateNotes}
      usersById={usersById}
      connectUsersById={connectUsersById}
      showCandidateDetailTabs={showCandidateDetailTabs}
      activeTab={activeTab}
      activeSourceName={activeSourceName}
      candidateStatusUpdate={candidateStatusUpdate}
      candidateSegmentsTotalCount={candidateSegmentsTotalCount}
      bulkNotesFetchApiStatus={bulkNotesFetchApiStatus}
      fetchBulkCandidateAllNotes={fetchBulkCandidateAllNotes}
      candidateIntel={candidateIntel}
      fetchCandidateIntel={fetchCandidateIntel}
      productVariantsById={productVariantsById}
      jobsById={jobsById}
      composeEmailType={composeEmailType}
      setComposeEmailType={setComposeEmailType}
      openSegmentAtsView={openSegmentAtsView}
      openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
      onClickAddJobCallBack={onClickAddJobCallBack}
      isConnectTab={isConnectTab}
      defaultFilter={defaultFilter}
      setAddSubsegmentModalStatus={setAddSubsegmentModalStatus}
      fetchCandidates={fetchCandidates}
      mspIcons={mspIcons}
      setMspIcons={setMspIcons}
    />
  );
}
export default connect(mapStateToProps, mapsDispatchToProps)(Candidate360ViewContainer);
