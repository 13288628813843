import React from 'react';
import { Form } from 'antd';
import TiocMismatchContent from '../TiocMismatchContent/TiocMismatchContent';
import ExperienceMismatchContent from '../ExperienceMismatchContent/ExperienceMismatchContent';
import MoreCandidateRejectReasonsContent from '../MoreCandidateRejectReasonsContent/MoreCandidateRejectReasonsContent';
import SkillMissingContent from '../SkillMissingContent/SkillMissingContent';
import CandidateOptOutContent from '../CandidateOptOutContent/CandidateOptOutContent';
import {
  rejectReasonKeysToTitleMapper,
  rejectReasonKeysToMoreSuitableReasonsTextMapper,
  getRejectReasonInitialValue,
} from '../../Utils/CandidateRejectReasonsUtils';

const { Item } = Form;

export default function RejectReasonsContentMapper(props) {
  const {
    rejectReasonKey,
    form,
    candidateStatusReasons,
    suggestions,
    negativeValues,
    jobId,
    candidateId,
    suggestionsFetchApiStatus,
    experienceRange,
    openInNewTab,
    featureToggleList,
  } = props;

  switch (rejectReasonKey) {
    case 'TITLE_MISMATCH':
    case 'INDUSTRY_MISMATCH':
    case 'JOB_FUNCTION_MISMATCH':
    case 'COMPANY_MISMATCH': {
      const initialValue = getRejectReasonInitialValue(candidateStatusReasons, rejectReasonKey);
      const negativeValue = negativeValues?.[rejectReasonKey];
      return (
        <Item className="tioc-mismatch-content">
          {form.getFieldDecorator(rejectReasonKey, {
            initialValue: initialValue || (negativeValue ? [negativeValue] : undefined),
          })(
            <TiocMismatchContent // TitleIndustryOccupationCompanyMismatchContent
              form={form}
              suggestions={suggestions?.[rejectReasonKey]}
              negativeValue={negativeValue}
              mismatchTitleText={rejectReasonKeysToTitleMapper[rejectReasonKey]}
              moreReasonsTitleText={rejectReasonKeysToMoreSuitableReasonsTextMapper[rejectReasonKey]}
              rejectReasonKey={rejectReasonKey}
              jobId={jobId}
              candidateId={candidateId}
              suggestionsFetchApiStatus={suggestionsFetchApiStatus?.[rejectReasonKey]}
            />
          )}
        </Item>
      );
    }
    case 'MISSING_MANDATORY_SKILLS': {
      const initialValue = getRejectReasonInitialValue(candidateStatusReasons, rejectReasonKey);
      return (
        <Item>
          {form.getFieldDecorator(rejectReasonKey, { initialValue })(
            <SkillMissingContent
              form={form}
              suggestions={suggestions?.[rejectReasonKey]}
              negativeValue={negativeValues?.[rejectReasonKey]}
              rejectReasonKey={rejectReasonKey}
              jobId={jobId}
              candidateId={candidateId}
              suggestionsFetchApiStatus={suggestionsFetchApiStatus?.[rejectReasonKey]}
              openInNewTab={openInNewTab}
              featureToggleList={featureToggleList}
            />
          )}
        </Item>
      );
    }
    case 'EXPERIENCE_MISMATCH': {
      const {
        EXPERIENCE_MISMATCH: initalExperienceMismatchReason,
        MinExperience,
        MaxExperience,
      } = candidateStatusReasons;
      return (
        <ExperienceMismatchContent
          form={form}
          initalExperienceMismatchReason={initalExperienceMismatchReason}
          initialMinExperience={MinExperience}
          initialMaxExperience={MaxExperience}
          experienceRange={experienceRange}
        />
      );
    }
    case 'MORE_REASONS': {
      const {
        LOCATION_MISMATCH: initialLocationMismatch,
        EDUCATION_MISMATCH: initialEducationMismatch,
        IRRELEVANT_CERTIFICATION: initialCertificationMismatch,
        MISSING_REQUIRED_CERTIFICATION: initialMissingCertification,
        OTHER_REASON: initialOtherReason,
        LOCATION_MISMATCH_CHECKED: isLocationMismatchChecked,
        EDUCATION_MISMATCH_CHECKED: isEducationMismatchChecked,
        CERTIFICATION_MISMATCH_CHECKED: isCertificationMismatchChecked,
        OTHER_REASON_CHECKED: isOtherReasonChecked,
      } = candidateStatusReasons;

      return (
        <MoreCandidateRejectReasonsContent
          form={form}
          initialLocationMismatch={initialLocationMismatch}
          initialEducationMismatch={initialEducationMismatch}
          initialCertificationMismatch={initialCertificationMismatch}
          initialMissingCertification={initialMissingCertification}
          initialOtherReason={initialOtherReason}
          isLocationMismatchChecked={isLocationMismatchChecked}
          isEducationMismatchChecked={isEducationMismatchChecked}
          isCertificationMismatchChecked={isCertificationMismatchChecked}
          isOtherReasonChecked={isOtherReasonChecked}
        />
      );
    }

    case 'OPT_OUT': {
      const initialValue = getRejectReasonInitialValue(candidateStatusReasons, rejectReasonKey);
      return form.getFieldDecorator('OPT_OUT', { initialValue })(<CandidateOptOutContent form={form} />);
    }

    default:
      return null;
  }
}
