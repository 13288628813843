import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { getEventNameByCandidateStatus } from '../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../Analytics/EventTypes';
import styles from './CandidateCardNotesIcon.module.scss';
import messages from './CandidateCardMessages';

function CandidateCardNotesIcon(props) {
  const { candidate = {}, onCandidateCardClick, featureToggleList } = props;
  const { CallNotesCount: candidateNotesCount, CandidateNotesCount: callNotesCount } = candidate;
  const {
    NotesIndicator: { IsEnabled: isNotesIndicatorEnabled },
    Notes: { IsEnabled: isNotesEnabled },
  } = featureToggleList;

  const isNotesIndicatorDisplayed = isNotesIndicatorEnabled && isNotesEnabled;

  const notesDisplayedText = <FormattedMessage {...messages.candidateNotes} />;
  const notesCount = candidateNotesCount + callNotesCount + null || 0;

  const notesButton = (
    <Button
      type="link"
      className={styles.candidateNotesLinkButton}
      onClick={e => {
        e.stopPropagation();
        onCandidateCardClick('notes');
      }}
      size="small"
      sk-event-name={getEventNameByCandidateStatus(
        eventTypes.candidate.candidate360Profile.goToCandidateNotes,
        candidate
      )}
    >
      {notesDisplayedText} ({notesCount})
    </Button>
  );

  return isNotesIndicatorDisplayed ? <div className={styles.candidateCardNotesWrapper}>{notesButton}</div> : null;
}

export default CandidateCardNotesIcon;
