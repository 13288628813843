import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import { Popover, Button, Timeline, Skeleton, Icon } from 'antd';
import * as jobActivityActions from '../../../Actions/JobActivityActions';
import * as manualSearchActions from '../../../Actions/ManualSearchActions';
import { getJobActivity, getJobActivityTotalCount } from '../../../Reducers/JobActivityReducer';
import { getLocaleDateStringWithTimeStamp } from '../../../Utils/RelativeTimeCalculator';
import RetrieveCriteriaModal from '../RetrieveCriteriaModal/RetrieveCriteriaModal';
import styles from './JobReactivationTree.module.scss';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getUsersById } from '../../../Reducers/UserReducer';
import JobReactivationIconV2 from '../../../Icons/JobReactivationIconV2';
import message from '../../ManualSearchV2/ManualSearchMessages';

const { Item } = Timeline;

const eventTypeToDisplayNameMapper = {
  'AryaSuggestedCandidateSearchCriteria.Created': 'Saved',
  'AryaSuggestedCandidateSearchCriteria.Updated': 'Updated',
  'CandidateSearchCriteria.Created': 'Saved',
  'CandidateSearchCriteria.Updated': 'Updated',
  'CandidateSearchCriteria.AdvanceSearchV2Activated': 'Applied Score and Rank',
  'CandidateSearchCriteria.Activated': 'Reactivated',
};

const getJobActivityItemContent = ({ activity, usersById, AppName }) => {
  const content = {};
  content.username = [
    'AryaSuggestedCandidateSearchCriteria.Created',
    'AryaSuggestedCandidateSearchCriteria.Updated',
  ].includes(activity.EventName)
    ? `${AppName} recommended`
    : usersById[activity.CreatedBy]?.FirstName ?? activity.CreatedBy;
  content.jobActivityId = activity.RefId;
  content.dateTime = getLocaleDateStringWithTimeStamp(activity.CreatedTime);
  content.event = eventTypeToDisplayNameMapper[activity.EventName];
  if (
    ['AryaSuggestedCandidateSearchCriteria.Created', 'AryaSuggestedCandidateSearchCriteria.Updated'].includes(
      activity.EventName
    )
  )
    return content;
  content.criteriaName = activity.EventData?.CriteriaName;
  return content;
};

const mapStateToProps = (state, props) => ({
  jobActivity: getJobActivity(state, { jobId: props.jobId }),
  fetchJobActivityApiStatus: getApiStatus(state, 'fetchJobActivityStatus'),
  usersById: getUsersById(state),
  jobActivityTotalCount: getJobActivityTotalCount(state, { jobId: props.jobId }),
});

const mapDispatchToProps = {
  fetchJobActivity: jobActivityActions.fetchJobActivity,
  fetchManualSearchCriteriaByRecordId: manualSearchActions.fetchManualSearchCriteriaByRecordId,
};

function JobReactivationTree(props) {
  const {
    jobActivity,
    fetchJobActivity,
    jobId,
    currentJobDetails,
    fetchManualSearchCriteriaByRecordId,
    fetchJobActivityApiStatus,
    usersById,
    AppName,
    jobActivityTotalCount,
    showV2ReactivationIcon,
  } = props;
  const [jobActivityId, setJobActivityId] = React.useState();
  const [isModalVisible, setModalVisibility] = React.useState(false);
  const [isPopoverVisible, setPopoverVisibility] = React.useState(false);
  const [jobActivityStartIndex, setJobActivityStartIndex] = React.useState(0);
  const activityGroupSizePerCall = 10;

  const { JobTitle: jobTitle } = currentJobDetails;

  const handleOnClick = _jobActivityId => {
    setModalVisibility(true);
    setPopoverVisibility(false);
    setJobActivityStartIndex(0);
    fetchManualSearchCriteriaByRecordId({ id: _jobActivityId, jobId });
    setJobActivityId(_jobActivityId);
  };

  const handleCancel = () => {
    setModalVisibility(false);
  };

  const handleVisibleChange = ({ visibilty, isFromInfiniteScroll }) => {
    if (visibilty && (!isFromInfiniteScroll || jobActivity?.length < jobActivityTotalCount)) {
      setModalVisibility(false);
      fetchJobActivity({
        from: jobActivityStartIndex,
        size: activityGroupSizePerCall,
        jobId,
        isFromInfiniteScroll,
      });
      setJobActivityStartIndex(jobActivityStartIndex + activityGroupSizePerCall);
    } else setJobActivityStartIndex(0);
    setPopoverVisibility(visibilty);
  };

  const getJobActivityTimeline = () => {
    return (
      <InfiniteScroll
        useWindow={false}
        initialLoad={false}
        loadMore={() => handleVisibleChange({ visibilty: true, isFromInfiniteScroll: true })}
        hasMore={jobActivity?.length < jobActivityTotalCount}
        loader={
          <div key="loader" className={styles.loader}>
            <Icon className={styles.loadingIcon} type="loading" spin />
          </div>
        }
        threshold={226}
      >
        <Timeline>
          {jobActivity.map(activity => {
            const content = getJobActivityItemContent({ activity, jobId, usersById, AppName });
            return (
              <Item color={content.jobId ? 'blue' : 'green'} key={content.jobActivityId} className="test">
                <span>{content.username}</span>
                <span> | {content.dateTime}</span>
                {content.criteriaName ? <span> | {content.criteriaName}</span> : null}
                {content.jobId ? (
                  <span>
                    | <span style={{ color: '#1890ff' }}>Job ID: {content.jobId}</span>
                  </span>
                ) : null}
                {content.event ? (
                  <>
                    <span> | </span>
                    <Button
                      onClick={() => handleOnClick(content.jobActivityId)}
                      type="link"
                      className={styles.eventTypeButton}
                      size="small"
                    >
                      <span className={styles.eventName}>{content.event}</span>
                    </Button>
                  </>
                ) : null}
              </Item>
            );
          })}
        </Timeline>
      </InfiniteScroll>
    );
  };

  return (
    <div className={styles.jobActivity}>
      <Popover
        className={styles.popover}
        placement="bottomRight"
        visible={isPopoverVisible}
        mouseEnterDelay={0.5}
        onVisibleChange={visibilty => handleVisibleChange({ visibilty, isFromInfiniteScroll: false })}
        destroyTooltipOnHide
        content={
          <div className={styles.jobActivityContentWrapper}>
            <Skeleton
              loading={fetchJobActivityApiStatus === 'INPROGRESS' && !jobActivity?.length}
              active
              paragraph={{ rows: 3 }}
            >
              <div className={styles.jobActivityContent}>
                {jobActivity.length ? (
                  getJobActivityTimeline()
                ) : (
                  <div className={styles.emptyCriteriaContent}><FormattedMessage {...message.noCriteriasAvailableLabel} /></div>
                )}
              </div>
            </Skeleton>
          </div>
        }
      >
        {showV2ReactivationIcon ? (
          <JobReactivationIconV2 className={styles.clockIcon} />
        ) : (
          <Icon type="clock-circle" theme="filled" className={styles.clockIcon} style={{ color: '#83878C' }} />
        )}
      </Popover>
      <RetrieveCriteriaModal
        isModalVisible={isModalVisible}
        handleOnClick={handleOnClick}
        handleCancel={handleCancel}
        jobId={jobId}
        jobTitle={jobTitle}
        jobActivityId={jobActivityId}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JobReactivationTree);
export { JobReactivationTree as JobReactivationTreeWithoutStore };
