import { defineMessages } from 'react-intl';
import ContactPullerMessageJsonEn from '../../Data/En_Us/ContactPullerMessageEn_Us.json';
import ContactPullerMessageJsonEs from '../../Data/Es_Es/ContactPullerMessageEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'ContactPuller';
const transformedMessage = formatMessagesWithComponentPrefix(ContactPullerMessageJsonEn, component);
const contactPullerMessages = defineMessages(transformedMessage);

const ContactPullerMessage = defineMessages(contactPullerMessages);
export const contactPullerMessageEn = formatJsonWithComponentPrefix(ContactPullerMessageJsonEn, component);
export const contactPullerMessageEs = formatJsonWithComponentPrefix(ContactPullerMessageJsonEs, component);

export default ContactPullerMessage;
