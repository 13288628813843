import React from 'react';
import { Modal, Button, Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './DeleteNoteCardModal.module.scss';
import message from '../../../Containers/ManualSearchV2/ManualSearchMessages';

export default function DeleteNoteCardModal(props) {
  const { isModalVisible, onCancel, onDelete, jobNoteDeleteApiStatus } = props;

  const onDeleteClick = () => {
    onDelete();
  };
  const isLoading = jobNoteDeleteApiStatus === 'INPROGRESS';
  return (
    <Modal
      title={<FormattedMessage {...message.deleteNoteLabel} />}
      visible={isModalVisible}
      onCancel={onCancel}
      centered
      footer={[
        <Button shape="round" key="back" onClick={onCancel}>
          <FormattedMessage {...message.cancelLabel} />
        </Button>,
        <Button shape="round" key="submit" onClick={onDeleteClick} loading={isLoading} type="primary">
          <FormattedMessage {...message.yesLabel} />
        </Button>,
      ]}
      zIndex={2001}
    >
      <div className={styles.modalContent}>
        <div className={styles.iconDiv}>
          <Icon type="exclamation-circle" />
        </div>
        <div className={styles.message}><FormattedMessage {...message.deleteNoteWarningLabel} /></div>
      </div>
    </Modal>
  );
}
