import React from 'react';
import _ from 'lodash';
import { Icon, Alert } from 'antd';
import styles from './SpamAlertText.module.scss';
import InfoIconWithTooltip from '../InfoIconWithTooltip/InfoIconWithTooltip';

const SpamAlertText = props => {
  const { style, junkWords = [], setIsJunkWordFound, alertStyle } = props;

  const onClickClose = () => {
    if (setIsJunkWordFound) setIsJunkWordFound(false);
  };

  return (
    <div style={style}>
      <Alert
        message={
          <div className={styles.messageWithTooltip}>
            <div>Spam words detected! Networks may block text delivery. Please review.</div>
            <InfoIconWithTooltip
              tooltipTitle={_.uniq(junkWords).join(' , ')}
              style={{ zIndex: 11111, maxHeight: '250px', overflowY: 'auto' }}
              className={styles.spamTooltip}
              placement="right"
            />
          </div>
        }
        type="error"
        showIcon
        icon={<Icon type="close-circle" theme="filled" />}
        closable
        className={styles.spamAlertText}
        onClose={onClickClose}
        style={alertStyle}
      />
    </div>
  );
};

export default SpamAlertText;
