import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Button, Icon } from 'antd';
import PropTypes from 'prop-types';
import config from '../../Config/Config';
import './NotesForm.scss';
import RichTextEditor from '../Editor/RichTextEditor';
import { getCandidateNotesEventName } from '../../Analytics/Candidate/CandidateEventUtils';
import placeholder from '../Placeholders/PlaceholdersMessages';

class NotesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      noteLength: 0,
    };
  }

  handleSubmit = e => {
    const { postNotes, onNotesChange, isCallNotes } = this.props;
    const { content } = this.state;
    e.preventDefault();
    const { form } = this.props;
    form.validateFields(err => {
      if (!err) {
        postNotes(content);
        form.resetFields();
        this.setState({ content: null });
        if (!isCallNotes) {
          onNotesChange(null);
        }
      }
    });
  };

  updateDescriptionContent = ({ htmlContent, plainTextContent }) => {
    const { onNotesChange, isCallNotes } = this.props;
    this.setState({
      content: htmlContent,
    });
    this.setState({
      noteLength: plainTextContent?.length,
    });
    if (!isCallNotes) {
      onNotesChange(htmlContent);
    }
  };

  render() {
    const { isCallNotes, candidate, intl } = this.props;
    const { noteLength, content } = this.state;
    return (
      <Form layout="inline" onSubmit={this.handleSubmit} style={{ marginBottom: 25 }}>
        <div className="notes-input-button">
          <div style={{ width: '100%' }}>
            <Form.Item style={{ width: '100%' }}>
              <RichTextEditor
                editorContent={{ htmlContent: content }}
                onChange={this.updateDescriptionContent}
                placeholder={intl.formatMessage({ ...placeholder.writeNotesLabel })}
                className={isCallNotes ? 'call-notes-richtext-editor' : 'notes-richtext-editor'}
              />
              <div className="notes-characters-count">
                {config.charactersPerNote - noteLength < 200
                  ? `${config.charactersPerNote - noteLength}/${config.charactersPerNote} characters remaining`
                  : ''}
              </div>
            </Form.Item>
          </div>
          <div className="notes-submit-button">
            <Form.Item>
              <Button
                htmlType="submit"
                className="notes-submit"
                type="primary"
                sk-event-name={getCandidateNotesEventName(isCallNotes, candidate)}
              >
                <Icon type="arrow-right" style={{ color: '#FFFFFF' }} />
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  }
}

NotesForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
  }).isRequired,
};

export default injectIntl(Form.create()(NotesForm));
export { NotesForm as NotesFormWithoutInjectIntl };
