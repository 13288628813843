import React from 'react';
import { Tooltip } from 'antd';
import InfoCircleIcon from '../../../Icons/SuggestedIcons/InfoCircleIcon';
import styles from './InfoIconWithTooltip.module.scss';

export default function InfoIconWithTooltip(props) {
  const { tooltipTitle, style, overlayClassName, placement, className } = props;
  return (
    <Tooltip title={tooltipTitle} overlayStyle={style} overlayClassName={overlayClassName} placement={placement} className={className}>
      <InfoCircleIcon className={styles.infoIcon} />
    </Tooltip>
  );
}
