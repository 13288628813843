import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tabs, message } from 'antd';
import * as ClientActions from '../../Actions/ClientActions';
import * as ConnectSettingsActions from '../../Actions/ConnectSettingsActions';
import * as JobActions from '../../Actions/JobActions';
import * as ConfigActions from '../../Actions/ConfigActions';
import * as AryaPayActions from '../../Actions/AryaPayActions';
import {
  getClientIds,
  getClientsById,
  getClientTotal,
  getActiveClientTotal,
  getAddClientDrawerVisibility,
} from '../../Reducers/ClientReducer';
import { getConfig } from '../../Reducers/ConfigReducer';
import { getJobUtilities } from '../../Reducers/JobReducer';
import { getConnectSettings } from '../../Reducers/ConnectSettingsReducer';
import ApplicationSettings from '../../Components/ApplicationSettings/ApplicationSettings';
import AdminSettings from './AdminSettings/AdminSettings';
import ClientList from '../../Components/ClientList/ClientList';
import ProfileSettingsContainer from './ProfileSettings/ProfileSettings';
import './Settings.scss';
import { getAvailableCredits } from '../../Reducers/AryaPayReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import * as clientFormActions from '../../Actions/ActionCreators/CreateClientActionCreator';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import NotificationSettingsContainer from './NotificationSettings/NotificationSettings';
import PaymentJobAd from '../PaymentJobAd/PaymentJobAd';
import * as JobDistributionActions from '../../Actions/JobDistributionActions';
import { getProductVariantsById } from '../../Reducers/AryaPayOrderReducer';
import { getPortalTariffInfo } from '../../Reducers/JobDistributionReducer';
import { JOBBOARDFORPUBLISH } from '../../Utils/JobBoardUtils';

const { TabPane } = Tabs;
const mapStateToProps = state => {
  return {
    userConfig: getConfig(state),
    clientsById: getClientsById(state),
    clientIds: getClientIds(state),
    utilities: getJobUtilities(state),
    connectConfig: getConnectSettings(state),
    clientTotal: getClientTotal(state),
    activeClientTotal: getActiveClientTotal(state),
    availableCredits: getAvailableCredits(state),
    requestForCreditsApiStatus: getApiStatus(state, 'requestCreditsStatus'),
    addClientDrawerVisibility: getAddClientDrawerVisibility(state),
    featureToggleList: getFeatureToggleList(state),
    createClientApiStatus: getApiStatus(state, 'createClientApiStatus'),
    productVariantsById: getProductVariantsById(state),
    portalTariff: getPortalTariffInfo(state, JOBBOARDFORPUBLISH),
    availableAdCredits: getAvailableCredits(state),
  };
};

const mapDispatchToProps = {
  fetchAvailableJobAdCredits: JobDistributionActions.fetchAvailableJobAdCredits,
  fetchPortalTariff: JobDistributionActions.fetchPortalTariff,
  fetchAllPortalCredentials: ConfigActions.fetchAllPortalCredentials,
  fetchBotDetails: ConnectSettingsActions.fetchBotDetails,
  fetchCountries: JobActions.fetchCountries,
  fetchIndustries: JobActions.fetchIndustries,
  integratePortal: ConfigActions.integratePortal,
  integrateSource: ConfigActions.integrateSource,
  fetchAvailableCredits: AryaPayActions.fetchAvailableCredits,
  requestForCredits: AryaPayActions.requestForCredits,
  updateBotDetails: ConnectSettingsActions.updateBotDetails,
  fetchCallerIds: ConnectSettingsActions.fetchCallerIds,
  updateCallerIds: ConnectSettingsActions.updateCallerIds,
  fetchEmailConfig: ConnectSettingsActions.fetchEmailConfig,

  fetchContactType: ConnectSettingsActions.fetchContactType,
  updateContactType: ConnectSettingsActions.updateContactType,
  patchUpdateConfig: ConfigActions.patchUpdateConfig,
  addClient: ClientActions.addClient,
  editClient: ClientActions.patchUpdateClient,
  fetchClientList: ClientActions.fetchClientList,
  fetchClientDetails: ClientActions.fetchClientDetails,
  changeClientStatus: ClientActions.changeClientStatus,
  toggleAddClientDrawer: ClientActions.toggleAddClientDrawer,
  fetchContactProviders: ConnectSettingsActions.fetchContactProviders,
  bulkSubscribeContactProviders: ConnectSettingsActions.bulkSubscribeContactProviders,
  setClientFormApiStatus: clientFormActions.setCreateClientApiStatus,
};

class Settings extends React.Component {
  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      activeKey: match.params.tab,
      jobAdCreditsForPurchase: 0,
      paymentPageVisiblity: false,
    };
    this.onTabChange = this.onTabChange.bind(this);
    this.onProceedToPayment = this.onProceedToPayment.bind(this);
    this.setPaymentPageVisiblity = this.setPaymentPageVisiblity.bind(this);
    this.paymnetSuccessfullMessage = this.paymnetSuccessfullMessage.bind(this);
  }

  componentDidMount() {
    const { fetchPortalTariff } = this.props;
    fetchPortalTariff(JOBBOARDFORPUBLISH);
  }

  static getDerivedStateFromProps(props, state) {
    if (_.get(props, 'match.params.tab', state.activeKey) !== state.activeKey) {
      return {
        activeKey: _.get(props, 'match.params.tab', state.activeKey),
      };
    }
    return null;
  }

  onTabChange(key) {
    const { history, fetchEmailConfig, fetchAvailableJobAdCredits, fetchAvailableCredits } = this.props;
    if (key === 'app') {
      fetchEmailConfig({ isAdmin: false });
      fetchEmailConfig({ isAdmin: true });
      fetchAvailableJobAdCredits();
    }
    if (key === 'admin') {
      fetchAvailableCredits(null, 'Arya');
    }
    history.push(`/settings/${key}`);
  }

  onProceedToPayment(inputCredits) {
    this.setState({
      paymentPageVisiblity: true,
      jobAdCreditsForPurchase: inputCredits,
    });
  }

  setPaymentPageVisiblity(visibility) {
    this.setState({
      paymentPageVisiblity: visibility,
    });
  }

  paymnetSuccessfullMessage() {
    const { jobAdCreditsForPurchase } = this.state;
    message.success(`The purchase is successful for ${jobAdCreditsForPurchase} Job Ad Credit(s)`);
  }

  render() {
    const { activeKey, paymentPageVisiblity, jobAdCreditsForPurchase } = this.state;
    const {
      userConfig,
      integratePortal,
      addClient,
      clientsById,
      fetchCallerIds,
      utilities,
      fetchCountries,
      fetchIndustries,
      updateCallerIds,
      connectConfig,
      patchUpdateConfig,
      fetchEmailConfig,
      fetchContactType,
      updateContactType,
      fetchClientList,
      changeClientStatus,
      clientIds,
      fetchClientDetails,
      clientTotal,
      addClientDrawerVisibility,
      toggleAddClientDrawer,
      fetchContactProviders,
      fetchAllPortalCredentials,
      bulkSubscribeContactProviders,
      fetchBotDetails,
      updateBotDetails,
      editClient,
      location,
      activeClientTotal,
      availableCredits,
      fetchAvailableCredits,
      requestForCredits,
      requestForCreditsApiStatus,
      userConnectConfig,
      integrateSource,
      featureToggleList,
      createClientApiStatus,
      setClientFormApiStatus,
      productVariantsById,
      portalTariff,
      availableAdCredits,
      fetchAvailableJobAdCredits,
    } = this.props;

    if (paymentPageVisiblity) {
      return (
        <PaymentJobAd
          productVariant={productVariantsById?.[portalTariff?.ProductVariantId]}
          productVariantId={portalTariff?.ProductVariantId}
          advertiseCallback={() => {
            this.setPaymentPageVisiblity(false);
            fetchAvailableJobAdCredits();
            this.paymnetSuccessfullMessage();
          }}
          backNavigation={() => this.setPaymentPageVisiblity(false)}
          quantity={jobAdCreditsForPurchase}
          placeOrderButtonText="Buy Now"
        />
      );
    }

    const isApplicationSettingsEnabled = featureToggleList.ApplicationSettings.IsEnabled;
    const isNotificationSettingsEnabled = featureToggleList.SourcingNotificationEmails.IsEnabled;
    return (
      <>
        <h1 className="settings-title">Settings</h1>
        <div className="settings-tab-wrapper">
          <Tabs defaultActiveKey="app" activeKey={activeKey} onTabClick={this.onTabChange}>
            <TabPane tab="Profile" key="profile">
              <ProfileSettingsContainer />
            </TabPane>
            {isApplicationSettingsEnabled ? (
              <TabPane tab="Application" key="app">
                <ApplicationSettings
                  fetchBotDetails={fetchBotDetails}
                  availableAdCredits={availableAdCredits}
                  updateBotDetails={updateBotDetails}
                  userConfig={userConfig}
                  saveSettings={this.saveSettings}
                  integrateSource={integrateSource}
                  authenticate={integratePortal}
                  fetchAllPortalCredentials={fetchAllPortalCredentials}
                  patchUpdateConfig={patchUpdateConfig}
                  connectConfig={connectConfig}
                  roleType={_.get(userConfig, 'Role', '')}
                  aryaVersion={_.get(userConfig, 'SubscriptionType')}
                  featureToggleList={featureToggleList}
                  onProceedToPayment={this.onProceedToPayment}
                />
              </TabPane>
            ) : null}

            {_.get(userConfig, 'Role', '') !== 'Recruiter' ? (
              <TabPane tab="Admin" key="admin">
                <AdminSettings
                  userConnectConfig={userConnectConfig}
                  patchUpdateConfig={patchUpdateConfig}
                  userConfig={userConfig}
                  fetchCallerIds={fetchCallerIds}
                  fetchContactType={fetchContactType}
                  fetchAvailableCredits={fetchAvailableCredits}
                  updateContactType={updateContactType}
                  updateCallerIds={updateCallerIds}
                  utilities={utilities}
                  fetchCountries={fetchCountries}
                  fetchEmailConfig={fetchEmailConfig}
                  callerIds={_.get(connectConfig, 'CallerIds', false) || []}
                  contactSettings={connectConfig.ContactSettings || {}}
                  fetchContactProviders={fetchContactProviders}
                  bulkSubscribeContactProviders={bulkSubscribeContactProviders}
                  availableCredits={availableCredits}
                  requestForCredits={requestForCredits}
                  requestForCreditsApiStatus={requestForCreditsApiStatus}
                  aryaVersion={userConfig?.SubscriptionType}
                  location={location}
                />
              </TabPane>
            ) : null}
            {/* {_.get(userConfig, 'Role', '') !== 'Recruiter' ? (
              <TabPane tab="User" key="3">
                <UserList />
              </TabPane>
            ) : null} */}
            {_.get(userConfig, 'Role', '') !== 'Recruiter' ? (
              <TabPane tab="Clients" key="clients">
                <ClientList
                  defaultDrawerVisibility={_.get(location, 'state.clientDrawerVisibility', false)}
                  addClient={addClient}
                  clientsById={clientsById}
                  fetchClientList={fetchClientList}
                  clientIds={clientIds}
                  fetchClientDetails={fetchClientDetails}
                  clientTotal={clientTotal}
                  editClient={editClient}
                  changeClientStatus={changeClientStatus}
                  activeClientTotal={activeClientTotal}
                  visible={addClientDrawerVisibility}
                  toggleAddClientDrawer={toggleAddClientDrawer}
                  utilities={utilities}
                  fetchIndustries={fetchIndustries}
                  createClientApiStatus={createClientApiStatus}
                  setClientFormApiStatus={setClientFormApiStatus}
                />
              </TabPane>
            ) : null}
            {isNotificationSettingsEnabled ? (
              <TabPane tab="Notifications" key="notifications">
                <NotificationSettingsContainer />
              </TabPane>
            ) : null}
          </Tabs>
        </div>
      </>
    );
  }
}

export { Settings as SettingsWithoutStore };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));
