import { defineMessages } from 'react-intl';
import profileSettingMessagesJsonEn from '../../Data/En_Us/ProfileSettingMessagesEn_Us.json';
import profileSettingMessagesJsonEs from '../../Data/Es_Es/ProfileSettingMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'ProfileSetting';
const transformedMessage = formatMessagesWithComponentPrefix(profileSettingMessagesJsonEn, component);
const profileSettingMessages = defineMessages(transformedMessage);

export const profileSettingMessagesEn = formatJsonWithComponentPrefix(profileSettingMessagesJsonEn, component);

export const profileSettingMessagesEs = formatJsonWithComponentPrefix(profileSettingMessagesJsonEs, component);

export default profileSettingMessages;
