import React from 'react';
import { Form, Button, Drawer } from 'antd';
import { FormattedMessage } from 'react-intl';
import WorkflowNodeModalContent from '../WorkflowNodeModalContent/WorkflowNodeModalContent';
import { getWorkflowNodeModalTitle } from '../../Utils/WorkflowUtils';
import { onAddWorkflowNode } from '../../Containers/WorkflowCreationContainer/WorkflowUtils/WorkflowElementUtils';
import styles from './WorkflowNodeModal.module.scss';
import message from '../../Containers/Connect/ConnectMessages';

function WorkflowNodeModal(props) {
  const {
    visible,
    type,
    form,
    onSaveCallback,
    onCancelCallback,
    initialValue,
    mergeTags,
    isDisabled,
    isFirstEdge,
    isFirstNode,
  } = props;

  const onCancel = () => {
    onCancelCallback();
    form.resetFields();
  };

  const onAdd = () => {
    form.validateFields(err => {
      if (!err) {
        onSaveCallback(onAddWorkflowNode(type, form));
      }
    });
  };

  const drawerFooter = isDisabled ? null : (
    <div className={styles.workflowNodeDrawerFooter}>
      <Button key="back" onClick={onCancel} shape="round">
      <FormattedMessage {...message.cancelLabel} />
      </Button>
      <Button key="submit" type="primary" onClick={onAdd} shape="round">
      <FormattedMessage {...message.addLabel} />
      </Button>
    </div>
  );

  return (
    <Drawer
      destroyOnClose
      visible={visible}
      title={getWorkflowNodeModalTitle(type)}
      placement="right"
      onClose={onCancel}
      closable
      width="auto"
    >
      <div className={styles.workflowActionsContainer}>
        <WorkflowNodeModalContent
          initialValue={initialValue}
          isFirstEdge={isFirstEdge}
          isFirstNode={isFirstNode}
          type={type}
          form={form}
          onCancel={onCancel}
          onSave={onAdd}
          drawerFooter={drawerFooter}
          mergeTags={mergeTags}
          isDisabled={isDisabled}
        />
      </div>
    </Drawer>
  );
}

export default Form.create()(WorkflowNodeModal);
