import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { updateScheduledEmailsStatus } from '../../../Actions/ConnectActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getDripStats } from '../../../Reducers/ConnectReducer';
import EndDripIcon from '../../../Icons/EndDripIcon';
import './DripConfigSwitch.scss';
import { getEventNameByCandidateStatus } from '../../../Analytics/Candidate/CandidateEventUtils';
import eventTypes from '../../../Analytics/EventTypes';
import jobMessage from '../../JobForm/JobMessages';

export default function DripConfigSwitch({
  conversationId,
  selectedFromEmail,
  isVisible,
  candidate,
  isDripActive,
  dripConfigStatus,
}) {
  const [isDripConfirmModalVisible, setIsDripConfirmModalVisible] = useState(false);
  const dripStatsApiStatus = useSelector(state => getApiStatus(state, 'dripStatsApiStatus'));
  const dispatch = useDispatch();

  const toggleCancelDripConfirmationModal = () => {
    setIsDripConfirmModalVisible(x => !x);
  };

  return isVisible && isDripActive ? (
    <div className="drip-config-container">
      <Button
        type="danger"
        ghost
        onClick={toggleCancelDripConfirmationModal}
        shape="round"
        sk-event-name={getEventNameByCandidateStatus(eventTypes.candidate.candidateConnect.cancelDripEmail, candidate)}
      >
        <FormattedMessage {...jobMessage.stopAllDripLabel} />
      </Button>
      <Modal
        title={<FormattedMessage {...jobMessage.areYouSureYouWantToEndDripsLabel}/>}
        onOk={async () => {
          if (isDripActive) {
            await dispatch(updateScheduledEmailsStatus(conversationId, selectedFromEmail, 'Cancelled'));
            toggleCancelDripConfirmationModal();
          }
        }}
        okText="Confirm"
        confirmLoading={dripConfigStatus === 'INPROGRESS' || dripStatsApiStatus === 'INPROGRESS'}
        onCancel={toggleCancelDripConfirmationModal}
        visible={isDripConfirmModalVisible}
        zIndex={4000}
        okButtonProps={{
          'sk-event-name': getEventNameByCandidateStatus(
            eventTypes.candidate.candidateConnect.confirmDripEmailCancellation,
            candidate
          ),
        }}
        cancelButtonProps={{
          'sk-event-name': getEventNameByCandidateStatus(
            eventTypes.candidate.candidateConnect.cancelDripEmailCancellation,
            candidate
          ),
        }}
      >
       <FormattedMessage {...jobMessage.byEndingDripsYouWillHaveToRestart}/>
      </Modal>
    </div>
  ) : null;
}
