import { defineMessages } from 'react-intl';

import emailComposerMessageJsonEn from '../../Data/En_Us/EmailComposerMessageEn_Us.json';
import emailComposerMessageJsonEs from '../../Data/Es_Es/EmailComposerMessageEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'EmailComposer';
const transformedMessage = formatMessagesWithComponentPrefix(emailComposerMessageJsonEn, component);

const EmailComposerMessage = defineMessages(transformedMessage);
export const emailComposerMessageEn = formatJsonWithComponentPrefix(emailComposerMessageJsonEn, component);
export const emailComposerMessageEs = formatJsonWithComponentPrefix(emailComposerMessageJsonEs, component);
export default EmailComposerMessage;
