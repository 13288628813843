import React from 'react';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from './EducationSection.module.scss';
import EducationWrapper from '../../../../CompoundComponents/EducationWrapper/EducationWrapper';
import Degree from '../../../../CompoundComponents/EducationWrapper/Degree';
import School from '../../../../CompoundComponents/EducationWrapper/School';
import Specialization from '../../../../CompoundComponents/EducationWrapper/Specialization';
import Certification from '../../../../CompoundComponents/EducationWrapper/Certification';
import TextWithInfoIcon from '../../../../Components/Common/TextWithInfoIcon/TextWithInfoIcon';
import ClearAllButton from '../../ClearAllButton/ClearAllButton';
import eventTypes from '../../../../Analytics/EventTypes';
import InfoIconWithTooltip from '../../../../Components/Common/InfoIconWithTooltip/InfoIconWithTooltip';
import message from '../../ManualSearchMessages';

export default function EducationSection(props) {
  const { form, defaultFormValues, maskingConfig, diversityForUniversityText } = props;
  const onClear = () => {
    form.setFieldsValue({
      Degree: undefined,
      School: undefined,
      Specialization: undefined,
      Certification: undefined,
    });
  };
  const { IsUniversityMasked: isUniversityMasked } = maskingConfig;
  const tooltipText = isUniversityMasked ? diversityForUniversityText('university', 'School/University') : '';
  return (
    <div className={styles.educationSection}>
      <div className={styles.educationSectionClearAll}>
        <div className={styles.educationSectionLabelWrapper}>
          <FormattedMessage {...message.educationHeader} />
        </div>
        <div className={styles.clearAll}>
          <ClearAllButton onClear={onClear} skEventName={eventTypes.job.manualSearch.clearEducationFilter} />
        </div>
      </div>
      <div className={styles.educationWrapper}>
        <EducationWrapper form={form}>
          <div className={styles.educationSectionRow}>
            <div className={styles.titleSelection}>
              <div className={styles.quickSearchFormItemLabel}>
                <TextWithInfoIcon
                  text={
                    <div className={styles.quickSearchFormItemLabelText}>
                      <FormattedMessage {...message.educationDegreeHeader} />
                    </div>
                  }
                  tooltipTitle={<FormattedMessage {...message.educationDegreeLabel} />}
                />
              </div>
              <Degree initialValue={defaultFormValues?.Education?.Degree} />
            </div>
            <div className={styles.titleSelection}>
              <div style={{ display: 'flex', gap: '5px' }}>
                <div className={styles.quickSearchFormItemLabelText}><FormattedMessage {...message.schoolUniversityHeader} /></div>
                {isUniversityMasked ? <InfoIconWithTooltip tooltipTitle={tooltipText} /> : null}
              </div>
              <School initialValue={defaultFormValues?.Education?.School} isUniversityMasked={isUniversityMasked} />
            </div>
          </div>
          <div className={styles.educationSectionRow}>
            <div className={styles.titleSelection}>
              <div className={styles.quickSearchFormItemLabel}>
                <TextWithInfoIcon
                  text={
                    <div className={styles.quickSearchFormItemLabelText}>
                      <FormattedMessage {...message.specializationsHeader} />
                    </div>
                  }
                  tooltipTitle={<FormattedMessage {...message.specializationLabel} />}
                />
              </div>
              <Specialization initialValue={defaultFormValues?.Education?.Specialization} />
            </div>
            <div className={styles.titleSelection}>
              <div className={styles.quickSearchFormItemLabel}>
                <TextWithInfoIcon
                  text={
                    <div className={styles.quickSearchFormItemLabelText}>
                      <FormattedMessage {...message.certificationsHeader} />
                    </div>
                  }
                  tooltipTitle={<FormattedMessage {...message.certificationsLabel} />}
                />
              </div>
              <Certification initialValue={defaultFormValues?.Education?.Certification} />
            </div>
          </div>
        </EducationWrapper>
      </div>
    </div>
  );
}
