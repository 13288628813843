import React from 'react';
import _ from 'lodash';
import { Checkbox, Button, Form, Popover } from 'antd';
import { FormattedMessage } from 'react-intl';
import { defaultFilter, SR, CUSTOMER } from '../../../Utils/CandidateFilterUtils';
import FilterIconWithTooltip from '../../../Components/Common/FilterIconWithTooltip/FilterIconWithTooltip';
import styles from './CandidateRejectFilter.module.scss';
import store from '../../../store';
import { getCurrentUserDetails } from '../../../Reducers/UserSessionReducer';
import message from '../CandidateFiltersMessage';

const { Item } = Form;

const initialState = {
  filterVisible: false,
};

class CandidateRejectionFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = _.cloneDeep(initialState);
  }

  componentDidUpdate(prevProps) {
    const { form, currentPipelineFilter, setIsFilterApplied, filterConfiguration } = this.props;
    const filterKeys = Object.keys(defaultFilter);
    const currentFilter = _.pick(currentPipelineFilter, filterKeys);
    const previousFilter = _.pick(prevProps.currentPipelineFilter, filterKeys);
    if (!_.isEqual(currentFilter, previousFilter)) {
      const isFilterApplied = !_.isEqual(currentFilter, defaultFilter);
      form.setFieldsValue({
        [filterConfiguration.filterValue]: currentPipelineFilter?.[filterConfiguration.filterParameter],
      });
      setIsFilterApplied(isFilterApplied);
    }
  }

  applyFilter = filters => {
    const { applyCandidateFilter, currentPipelineFilter, filterConfiguration } = this.props;
    const filterBy = filters?.[filterConfiguration.filterValue];
    const isFilterModified = !_.isEqual(currentPipelineFilter?.[filterConfiguration.filterParameter], filterBy);
    if (isFilterModified) {
      const modifiedFilters = { [filterConfiguration.filterParameter]: filterBy };
      applyCandidateFilter({ modifiedFilters, isFilterModified });
    }
  };

  onSubmit = event => {
    event.preventDefault();
    const { form, filterConfiguration } = this.props;
    const formValues = { ...form.getFieldsValue() };
    this.applyFilter({
      [filterConfiguration.filterValue]: formValues?.[filterConfiguration.filterValue] || [],
    });

    this.setState({ filterVisible: false });
  };

  onClear = () => {
    const { filterConfiguration } = this.props;
    this.applyFilter({
      [filterConfiguration.filterValue]: [],
    });
    this.setState({ filterVisible: false });
  };

  getFilter = () => {
    const { candidateAggs, filterConfiguration, totalCandidate, candidates } = this.props;
    const state = store.getState();
    const currentUserDetails = getCurrentUserDetails(state);
    let sourcedCandidateCount = 0;
    if (candidates) {
      candidates.forEach(candidateId => {
        if (candidateId?.Status === 'Sourced') {
          sourcedCandidateCount += 1;
        }
      });
    }

    const filterInfo = candidateAggs?.[filterConfiguration.filterValueAggregation];
    const clientFilterInfoKey = filterInfo?.find(x => x.Id === currentUserDetails.Id);
    const filterInfoKey = filterInfo?.find(x => x.Id !== currentUserDetails.Id);
    const clientCandidateCount = clientFilterInfoKey?.Count ?? 0;

    return (
      <>
        {totalCandidate - clientCandidateCount !== 0 ? (
          <div key={filterInfoKey?.Id}>
            <Checkbox className={styles.filterCheckbox} value={SR}>
            <FormattedMessage {...message.serviceRepresentativeLabel} /> ({totalCandidate - clientCandidateCount})
            </Checkbox>
          </div>
        ) : null}
        {clientCandidateCount - sourcedCandidateCount !== 0 ? (
          <div key={clientFilterInfoKey?.Id}>
            <Checkbox className={styles.filterCheckbox} value={CUSTOMER}>
            <FormattedMessage {...message.meLabel} /> ({clientCandidateCount - sourcedCandidateCount})
            </Checkbox>
          </div>
        ) : null}
      </>
    );
  };

  handleVisibleChange = filterVisible => {
    this.setState({ filterVisible });
  };

  render() {
    const { form, candidates, isFilterApplied, candidateAggs, filterConfiguration, totalCandidate } = this.props;
    const { filterVisible } = this.state;
    const filterBy = candidateAggs?.[filterConfiguration.filterValueAggregation];
    const filter = filterBy ? this.getFilter() : undefined;
    const filterPopover = (
      <Form className={styles.candidateFilterDisplayContentSection} onSubmit={this.onSubmit}>
        <div className={styles.candidateFilterItemWraper}>
          <div className={styles.candidateFilterSource}>
            <Item>
              {form.getFieldDecorator(`${filterConfiguration.filterValue}`)(
                <Checkbox.Group className={styles.checkboxGroup}>
                  <h3>{filterConfiguration.filterTitle}</h3>
                  <div className={styles.sourceFilter}>{filter}</div>
                </Checkbox.Group>
              )}
            </Item>
          </div>
        </div>
      </Form>
    );
    return (
      <Popover
        className="filter-popover"
        overlayClassName={styles.candidateFilterPopover}
        content={candidates.length ? filterPopover : null}
        placement="bottomRight"
        trigger="click"
        autoAdjustOverflow={false}
        visible={filterVisible}
        onVisibleChange={this.handleVisibleChange}
        title={
          totalCandidate ? (
            <div className={styles.candidateFilterAggregation}>
              <div className={styles.candidateFilterTitle}>{filterConfiguration.filter}</div>
              <div className={styles.candidateFilterApplyClear}>
                <Button className={styles.candidateFilterClearButton} onClick={this.onClear}>
                  {filterConfiguration.clear}
                </Button>
                <Button className={styles.candidateFilterApplyButton} type="primary" onClick={this.onSubmit}>
                  {filterConfiguration.apply}
                </Button>
              </div>
            </div>
          ) : null
        }
      >
        <FilterIconWithTooltip title={filterConfiguration.filterIconToolTipTitle} isFilterApplied={isFilterApplied} />
      </Popover>
    );
  }
}
export default Form.create()(CandidateRejectionFilter);
export { CandidateRejectionFilter as CandidateRejectionFilterWithoutForm };
