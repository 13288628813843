import { defineMessages } from 'react-intl';

import candidateSortingFilterMessageJsonEn from '../../Data/En_Us/CandidateSortingFilterMessageEn_Us.json';
import candidateSortingFilterMessageJsonEs from '../../Data/Es_Es/CandidateSortingFilterMessageEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'CandidateSortingFilter';
const transformedMessage = formatMessagesWithComponentPrefix(candidateSortingFilterMessageJsonEn, component);
const candidateSortingFilterMessages = defineMessages(transformedMessage);

const CandidateSortingFilterMessage = defineMessages(candidateSortingFilterMessages);
export const candidateSortingFilterMessageEn = formatJsonWithComponentPrefix(
  candidateSortingFilterMessageJsonEn,
  component
);

export const candidateSortingFilterMessageEs = formatJsonWithComponentPrefix(
  candidateSortingFilterMessageJsonEs,
  component
);

export default CandidateSortingFilterMessage;
