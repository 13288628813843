import { defineMessages } from 'react-intl';

import listMessagesJsonEn from '../../Data/En_Us/ListMessagesEn_Us.json';
import listMessagesJsonEs from '../../Data/Es_Es/ListMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'Lists';
const transformedMessage = formatMessagesWithComponentPrefix(listMessagesJsonEn, component);
const listMessages = defineMessages(transformedMessage);

const ListMessage = defineMessages(listMessages);
export const listMessagesEn = formatJsonWithComponentPrefix(listMessagesJsonEn, component);
export const listMessagesEs = formatJsonWithComponentPrefix(listMessagesJsonEs, component);
export default ListMessage;
