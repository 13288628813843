import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import styles from './SourceInfoWidgetWrapper.module.scss';
import { getCandidateOriginalSourceImage } from '../../../Utils/DownloadedCandidatesSourceUtils';
import { getLatestProfileDateMarkup } from '../../../Utils/CandidateListUtils';
import messages from '../../CandidateCard/CandidateCardMessages';

function SourceInfoWidgetWrapper(props) {
  const { candidate, isInternalIcon, sourceInfostyle = {}, showVaultName, candidateOriginalSourceName } = props;
  const latestProfileDataMarkup = getLatestProfileDateMarkup(candidate, false, true);
  return (
    <div
      className={classNames(styles.candidateDownloadedPortalText, styles.candidateDownloadedPortalTextLatest)}
      style={sourceInfostyle}
    >
      {!isInternalIcon ? (
        <>
          <FormattedMessage {...messages.fromLabel} />{' '}
          {getCandidateOriginalSourceImage(candidate, 15, showVaultName, candidateOriginalSourceName)}{' '}
          {latestProfileDataMarkup ? <div style={{ fontStyle: 'normal' }}> | </div> : null}
        </>
      ) : null}{' '}
      {latestProfileDataMarkup}
    </div>
  );
}

export default SourceInfoWidgetWrapper;
