import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, Form, Button, Input, Icon, Tooltip } from 'antd';
import { validatePassword } from '../../Utils/FormValidators';
import { getPasswordToolTipText } from '../../Utils/TextUtils';
import './PasswordResetModal.scss';
import placeholder from '../Placeholders/PlaceholdersMessages';

class PasswordResetModal extends React.Component {
  handleOk = () => {
    const { form, updatePassword } = this.props;
    const password = form.getFieldValue('password');
    updatePassword(password);
  };

  render() {
    const { visible, updatePasswordApiStatus, form, intl } = this.props;
    const error = form.getFieldError('password');
    const password = form.getFieldValue('password');
    const isSaveButtonDisabled = !password || error?.length;
    return (
      <Modal
        title="Password Setup"
        visible={visible}
        okText="Save"
        zIndex={2300}
        width="512px"
        closable={false}
        centered
        footer={[
          <Button
            key="submit"
            type="primary"
            shape="round"
            onClick={this.handleOk}
            disabled={isSaveButtonDisabled}
            loading={updatePasswordApiStatus === 'INPROGRESS'}
          >
            Save
          </Button>,
        ]}
      >
        <div>
          <div className="password-reset-description">
            One last thing: Let&apos;s set a password to secure your account.
          </div>
          <Form layout="vertical">
            <div className="password-reset-wrapper">
              <Form.Item label="Password">
                {form.getFieldDecorator('password', {
                  rules: [{ validator: validatePassword }],
                })(
                  <Input.Password
                    className="reset-password-input"
                    type="password"
                    placeholder={intl.formatMessage({ ...placeholder.enterPasswordLabel })}
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Tooltip title={getPasswordToolTipText()} overlayClassName="reset-password-tooltip">
                  <Icon type="info-circle" className="reset-password-info" />
                </Tooltip>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
}

export default injectIntl(Form.create()(PasswordResetModal));
export { PasswordResetModal as PasswordResetModalWithoutInjectIntl };
