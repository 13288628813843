/* eslint-disable */
import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getMapingStatsList } from './DashBoardUtils';
import jobMessage from '../Components/JobForm/JobMessages';

export const defaultExperienceRanges = [
  {
    To: 2,
  },
  {
    From: 2,
    To: 5,
  },
  {
    From: 5,
    To: 11,
  },
  {
    From: 11,
    To: 15,
  },
  {
    From: 15,
  },
];

export const menuItemsList = {
  industries: {
    name: <FormattedMessage {...jobMessage.industriedLabel} />,
    id: 'Supply-Industries',
    sectionName: 'industry',
  },
  similarRoles: {
    name: <FormattedMessage {...jobMessage.similarRolesLabel} />,
    id: 'Supply-Roles',
    sectionName: 'role',
  },
  companies: {
    name: <FormattedMessage {...jobMessage.companiesLabel} />,
    id: 'Supply-Companies',
    sectionName: 'company',
  },
  marketSkills: {
    name: <FormattedMessage {...jobMessage.marketSkillsLabel} />,
    id: 'Supply-Skills',
    sectionName: 'skill',
  },
  experience: {
    name: <FormattedMessage {...jobMessage.experienceLabel} />,
    id: 'Supply-Experience',
    sectionName: 'experience',
  },
  location: {
    name: <FormattedMessage {...jobMessage.locationLabel} />,
    id: 'Supply-Location',
    sectionName: 'location',
  },
  education: {
    name: <FormattedMessage {...jobMessage.educationLabel} />,
    id: 'Supply-Education',
    sectionName: 'education',
  },
  certifications: {
    name: <FormattedMessage {...jobMessage.certificationsLabel} />,
    id: 'Supply-Certifications',
    sectionName: 'certification',
  },
};

export const menuList = Object.values(menuItemsList);

export const getActiveInactiveSupplyInsightsList = (supplyInsights, initialActiveList, initialInActiveList) => {
  const activeList = _.cloneDeep(initialActiveList);
  const inActiveList = [...initialInActiveList];
  supplyInsights.forEach(stats => {
    if (_.isEmpty(stats.data)) {
      inActiveList.push(stats?.item?.id);
    } else {
      activeList.push(stats?.item);
    }
  });
  return [activeList, inActiveList];
};

export const getLocationSupplyInsightsList = (
  supplyPayload,
  globalInsightSupplyList,
  isCandidateSupplyAnalyticsView
) => {
  const { States = [], Cities = [], Total, LocationSpecificProfileCount } = globalInsightSupplyList;
  if (isCandidateSupplyAnalyticsView && !States?.length && !Cities?.length) {
    return [[], []];
  }
  const listOfMapStates = [];
  const listOfMapCities = [];
  if (supplyPayload.State) {
    const citiesList = getMapingStatsList(Cities, 'CityName', LocationSpecificProfileCount || Total);
    listOfMapCities.push(...citiesList);
    const statesList = getMapingStatsList(States, 'StateName', Total);
    listOfMapStates.push(...statesList);
  }
  if (!_.isEmpty(supplyPayload.City)) {
    const locationSpecificCount = LocationSpecificProfileCount ?? Total;
    const cityName = _.trim(supplyPayload.City);
    const citiesList = [
      {
        CityName: cityName,
        Count: locationSpecificCount,
      },
    ];
    listOfMapCities.push(getMapingStatsList(citiesList, 'CityName', locationSpecificCount)[0]);
  }
  return [listOfMapStates, listOfMapCities];
};
