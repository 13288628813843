import React from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import { Modal, Button, Select, InputNumber, message, Tooltip, Icon } from 'antd';
import { getSourceDisplayName, getSourceImage } from '../../Utils/SourceUtils';
import { getLocaleDateString } from '../../Utils/RelativeTimeCalculator';
import './DistributeJobComponent.scss';
import store from '../../store';
import placeholder from '../Placeholders/PlaceholdersMessages';

message.config({
  top: 100,
  duration: 10,
  maxCount: 1,
});

const { Option } = Select;
class DistributeJobComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currencyType: 'USD',
      Amount: '',
      ActiveButton: '',
    };
  }

  handleJobDistribution = portal => {
    const { handleDistribute } = this.props;
    const { Amount, currencyType } = this.state;
    let payload = {};
    this.setState({ ActiveButton: portal });
    if (portal === 'indeed') {
      if (Amount === '' || Amount === null || !Amount) {
        message.error('Please Enter Amount');
        return;
      }
      payload = {
        Portals: [
          {
            Name: portal,
          },
        ],
        Amount,
        currencyType,
      };
    } else {
      payload = {
        Portals: [
          {
            Name: portal,
          },
        ],
      };
    }
    handleDistribute(payload);
  };

  handleCurrencyTypeChange = value => {
    this.setState({ currencyType: value });
  };

  handleAmountChange = value => {
    this.setState({ Amount: value });
  };

  render() {
    const {
      isVisible,
      handleClose,
      usedPortalsForJobDistribution,
      allPortals,
      distributeJobApiStatus,
      jobDistributionStatusesByPortal,
      intl,
    } = this.props;

    const { currencyType, Amount, ActiveButton } = this.state;

    return (
      <div className="distribute-job-modal-wrapper">
        <Modal
          className="distribute-job-modal"
          visible={isVisible}
          okText="Done"
          title="Distribute Job"
          centered
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', marginTop: '5px' }}>
                <Tooltip
                  overlayClassName="distribute-job-tooltip-overlay"
                  placement="topLeft"
                  title={
                    <span>
                      Feed creation does not indicate that job has been published to the job board. It will take
                      sometime for job board to pick the job and publish on their portal. Please keep checking the job
                      board to know the publish status.
                    </span>
                  }
                >
                  <Icon style={{ cursor: 'pointer', marginTop: '3px' }} type="info-circle" />
                </Tooltip>
                <span>
                  <p style={{ marginLeft: '10px' }}>What is Feed Creation?</p>
                </span>
              </div>
              <div>
                <Button type="primary" shape="round" onClick={handleClose}>
                  Done
                </Button>
              </div>
            </div>
          }
          destroyOnClose
          onCancel={handleClose}
          width={540}
          bodyStyle={{ padding: '12px 24px' }}
        >
          <div className="modal-content">
            {allPortals.map(portal => {
              const dummyPortalSource = { Portal: portal };
              const state = store.getState();
              const userConfig = state?.ConfigReducer?.UserConfig ?? {};
              const sourceDisplayName = getSourceDisplayName(dummyPortalSource, userConfig);
              const distributionDate = _.get(jobDistributionStatusesByPortal, [portal, 'DistributedDateTime'], null);
              const isDisabled = usedPortalsForJobDistribution.includes(portal);

              return (
                <>
                  <div className="portals-container">
                    <div className="align-portal-icon">
                      <div>{getSourceImage(dummyPortalSource, { fontSize: 30 })}</div>
                      <div className="source-portal-name"> {sourceDisplayName} </div>
                    </div>
                    <div>
                      {portal === 'indeed' ? (
                        <>
                          <Select
                            defaultValue={currencyType}
                            style={{ width: 75 }}
                            size="small"
                            onChange={this.handleCurrencyTypeChange}
                          >
                            <Option value="USD">$ USD</Option>
                          </Select>
                          <InputNumber
                            size="small"
                            placeholder={intl.formatMessage({ ...placeholder.amountLabel })}
                            style={{ width: 85 }}
                            min={1}
                            max={100000}
                            defaultValue={Amount}
                            onChange={this.handleAmountChange}
                          />
                        </>
                      ) : null}
                      <Button
                        style={{ marginLeft: '12px' }}
                        type="primary"
                        size="small"
                        disabled={isDisabled}
                        onClick={() => this.handleJobDistribution(portal)}
                        loading={portal === ActiveButton && distributeJobApiStatus?.status === 'INPROGRESS'}
                      >
                        Distribute
                      </Button>
                    </div>
                  </div>
                  <div className="job-distribution-date">
                    {distributionDate
                      ? `Feed Created Successfully on ${getLocaleDateString(distributionDate)}`
                      : 'Not Distributed Yet'}
                  </div>
                  <div className="draw-horizontal-line"></div>
                </>
              );
            })}
          </div>
        </Modal>
      </div>
    );
  }
}

export default injectIntl(DistributeJobComponent);
export { DistributeJobComponent as DistributeJobComponentWithoutInjectIntl };
