import React from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input, Button } from 'antd';
import './HorizontalLoginForm.scss';
import placeholder from '../Placeholders/PlaceholdersMessages';

class HorizontalLoginForm extends React.Component {
  componentDidMount() {
    // To disable submit button at the beginning.
    const { form } = this.props;
    form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields(err => {
      if (!err) {
        const { handleAuthentication, handleDisconection, isAuthenticate, type } = this.props;
        if (type === 'username') {
          if (!isAuthenticate) {
            handleAuthentication(form.getFieldValue('username'), form.getFieldValue('password'));
          } else {
            handleDisconection();
            form.setFieldsValue({ password: undefined });
          }
        } else if (!isAuthenticate) {
          handleAuthentication(form.getFieldValue('api'));
        } else {
          handleDisconection();
          form.setFieldsValue({ api: undefined });
        }
      }
    });
  };

  render() {
    const { form, isAuthenticate, userName, isLoading, type, intl } = this.props;
    const { getFieldDecorator, getFieldError, isFieldTouched, getFieldValue } = form;
    // Only show error after a field is touched.
    let usernameError;
    let passwordError;
    let apiKeyError;
    if (type === 'username') {
      usernameError = isFieldTouched('username') && getFieldError('username');
      passwordError = isFieldTouched('password') && getFieldError('password');
    } else {
      apiKeyError = isFieldTouched('api') && getFieldError('api');
    }

    const isAuthenticateButtonDisabled =
      type === 'username' ? !getFieldValue('username') || !getFieldValue('password') : !getFieldValue('api');
    return (
      <div className="login-form">
        <Form layout="inline" onSubmit={this.handleSubmit}>
          {type === 'username' ? (
            <span>
              <Form.Item validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                {getFieldDecorator('username', {
                  initialValue: userName,
                  rules: [{ required: true, message: 'Please input your username!' }],
                })(
                  <Input
                    placeholder={intl.formatMessage({ ...placeholder.usernameLabel })}
                    autoComplete="off"
                    readOnly={isAuthenticate}
                    className="contact-provider-username-input"
                  />
                )}
              </Form.Item>

              <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please input your Password!' }],
                  initialValue: isAuthenticate ? '********' : undefined,
                })(
                  <Input
                    type="password"
                    autoComplete="off"
                    placeholder={intl.formatMessage({ ...placeholder.passwordLable })}
                    readOnly={isAuthenticate}
                    className="contact-provider-password-input"
                  />
                )}
              </Form.Item>
            </span>
          ) : (
            <Form.Item validateStatus={apiKeyError ? 'error' : ''} help={apiKeyError || ''}>
              {getFieldDecorator('api', {
                rules: [{ required: true, message: 'Please input your API Key!' }],
                initialValue: isAuthenticate ? '****************' : undefined,
              })(
                <Input
                  type="password"
                  autoComplete="off"
                  placeholder={intl.formatMessage({ ...placeholder.apiKeyLable })}
                  style={{ width: '400px', marginRight: '15px', marginLeft: '15px' }}
                  readOnly={isAuthenticate}
                  className="contact-provider-password-input"
                />
              )}
            </Form.Item>
          )}

          <Form.Item>
            {!isAuthenticate ? (
              <Button
                type="primary"
                htmlType="submit"
                disabled={isAuthenticateButtonDisabled}
                shape="round"
                loading={isLoading}
              >
                Authenticate
              </Button>
            ) : (
              <Button type="primary" htmlType="submit" shape="round" className="disconnectButton" loading={isLoading}>
                Disconnect
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default injectIntl(Form.create()(HorizontalLoginForm));
export { HorizontalLoginForm as HorizontalLoginFormWithoutForm };
