import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Row, Col, Popover, Tooltip, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import MoreIcon from '../../../Icons/MoreIcon';
import CollaboratorsContainer from '../../CollaboratorsContainer/CollaboratorsContainer';
import styles from './GroupListItem.module.scss';
import AddListModal from '../Modals/AddListModal';
import ArchiveListModal from '../ArchiveListModal/ArchiveListModal';
import { ScoutingAgentIcon } from '../../../Icons/AryaIcons';
import * as JobActions from '../../../Actions/JobActions';
import { getJobDetails } from '../../../Reducers/JobReducer';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import * as ScoutingAgentActions from '../../../Actions/ScoutingAgentActions';
import { getScoutingAgentsofEnabledJobs } from '../../../Reducers/ScoutingAgentReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import message from '../ListsMessages';

const mapStateToProps = (state, props) => ({
  currentJobDetails: getJobDetails(state, props.segmentId),
  putEditSegmentApiStatus: getApiStatus(state, 'putEditSegmentApiStatus'),
  createSegmentApiStatus: getApiStatus(state, 'createSegmentApiStatus'),
  activeScoutingAgentCount: getScoutingAgentsofEnabledJobs(state, props.segmentId),
  jobDetailsStatus: getApiStatus(state, 'jobDetailsStatus'),
  featureToggleList: getFeatureToggleList(state),
});
const mapDispatchToProps = {
  fetchJobDetails: JobActions.fetchJobDetails,
  setScoutingAgentDrawerVisibility: ScoutingAgentActions.setScoutingAgentDrawerVisibility,
  fetchJobScoutingAgent: ScoutingAgentActions.fetchJobScoutingAgent,
};
function GroupListItem(props) {
  const [isEditModalVisible, setIsEditModalVisible] = React.useState(false);
  const [isArchiveModalVisible, setIsArchiveModalVisible] = React.useState(false);
  const [buttonClicked, setButtonClicked] = React.useState(undefined);
  const [isPopoverVisible, setPopoverVisbility] = React.useState(false);
  const [inputText, setInputText] = React.useState('');
  const [characterLimit] = React.useState(200);
  const onDescriptionChange = value => {
    setInputText(value);
  };
  const {
    usersById,
    segmentName,
    events,
    time,
    createdBy,
    form,
    segmentId,
    fetchJobDetails,
    editSegment,
    segmentDetails,
    putEditSegmentApiStatus,
    createSegment,
    createSegmentApiStatus,
    currentJobDetails,
    onSegmentCreationCallback,
    setSelectedSegmentId,
    setScoutingAgentDrawerVisibility,
    activeScoutingAgentCount,
    segment,
    jobDetailsStatus,
    featureToggleList,
  } = props;
  const { Description, JobTitle } = currentJobDetails;
  const showEditModal = () => {
    fetchJobDetails(segmentId);
    setPopoverVisbility(false);
    setIsEditModalVisible(true);
    onDescriptionChange(Description);
    setButtonClicked('edit');
  };

  const showDuplicateModal = () => {
    fetchJobDetails(segmentId);
    setPopoverVisbility(false);
    setIsEditModalVisible(true);
    onDescriptionChange(Description);
    setButtonClicked('duplicate');
  };
  const handleCancel = () => {
    setIsEditModalVisible(false);
    setInputText('');
    form.setFieldsValue({ listName: undefined, listDescription: undefined });
  };

  const handleOk = async () => {
    segmentDetails.JobTitle = form.getFieldValue('listName');
    segmentDetails.Description = form.getFieldValue('listDescription');
    form.validateFields(err => {
      if (!err) {
        updateOrCreateSegment();
      }
    });
  };

  const updateOrCreateSegment = async () => {
    if (buttonClicked === 'edit') await editSegment(segmentId, segmentDetails, onSegmentCreationCallback);
    else await createSegment(segmentDetails, onSegmentCreationCallback);
    setIsEditModalVisible(false);
    form.setFieldsValue({ listName: undefined, listDescription: undefined });
  };

  let allUsers = [];
  if (usersById) {
    allUsers = allUsers.concat(segment?.SharedWith || []);
    allUsers = allUsers.concat(segment?.AssignedTo || []);
  }
  const getUserName = userId => {
    return usersById[userId] ? usersById[userId].FullName : null;
  };
  const getUserEmail = userId => {
    return usersById[userId] ? usersById[userId].Email : null;
  };
  let users = _.uniq(allUsers).map(userId => {
    return {
      name: getUserName(userId),
      Id: userId,
      Email: getUserEmail(userId),
    };
  });
  users = users.filter(user => !!user);
  const handleVisibleChange = newVisible => {
    setPopoverVisbility(newVisible);
  };

  React.useEffect(() => {
    if (currentJobDetails.Description || currentJobDetails.JobTitle) {
      form.setFieldsValue({ listName: currentJobDetails.JobTitle, listDescription: currentJobDetails.Description });
    }
  }, [currentJobDetails.Description, isEditModalVisible]);

  const modalTitle =
    buttonClicked === <FormattedMessage {...message.editLabel} /> ? (
      <FormattedMessage {...message.editNameAndDescription} />
    ) : (
      <FormattedMessage {...message.createANewSegmentLabel} />
    );
  const buttonName = <FormattedMessage {...message.editLabel} /> ? (
    <FormattedMessage {...message.saveButtonLabel} />
  ) : (
    <FormattedMessage {...message.createButtonLabel} />
  );
  const isLoading =
    buttonClicked === 'edit' ? putEditSegmentApiStatus === 'INPROGRESS' : createSegmentApiStatus === 'INPROGRESS';
  const onSegmentClick = () => {
    onSegmentCreationCallback(segmentId);
  };
  const handleScoutingAgentIconClick = () => {
    setSelectedSegmentId(segmentId);
    setScoutingAgentDrawerVisibility(true);
  };
  let isScoutingAgentEnabled = false;
  if (activeScoutingAgentCount > 0) {
    isScoutingAgentEnabled = true;
  }
  const isSegmentCreationEnabled = featureToggleList?.SegmentCreation?.IsEnabled;

  return (
    <div className={styles.segmentCardDetails} role="presentation">
      <Row className={styles.segmentCard}>
        <Col xs={24} sm={6}>
          <Button type="link" onClick={onSegmentClick}>
            <Tooltip title={segmentName}>
              <div className={styles.listName}>{segmentName}</div>
            </Tooltip>
          </Button>
        </Col>
        <Col xs={24} sm={6}>
          <div className={events?.length ? styles.events : styles.none}>{events || 'None'}</div>
        </Col>

        <Col xs={24} sm={6}>
          <Tooltip title={usersById[createdBy]?.FullName}>
            <div className={styles.creatorName}>{usersById[createdBy]?.FullName}</div>
          </Tooltip>
          <div className={styles.dateTime}>{moment.utc(time).local().format('hh:mm A | MMM DD, YYYY')}</div>
        </Col>

        <Col xs={24} sm={4}>
          <div className={styles.sharedAvatarSegment}>
            <CollaboratorsContainer
              collaborators={users}
              shareSize="small"
              jobId={segmentId}
              candidateContext="segment"
            />
          </div>
        </Col>
        <Col className={styles.moreIcon}>
          {isScoutingAgentEnabled ? (
            <div className={classNames(styles.scoutingAgentIcon)} onClick={handleScoutingAgentIconClick}>
              <ScoutingAgentIcon width="16" height="16" style={{ fontSize: '14px' }} />
            </div>
          ) : null}
          <Popover
            trigger={['click']}
            visible={isPopoverVisible}
            onVisibleChange={handleVisibleChange}
            content={
              <div className={styles.content}>
                <div onClick={showEditModal} onKeyPress={showEditModal} role="button" tabIndex={0}>
                  <FormattedMessage {...message.editButtonLabel} />
                </div>
                <div onClick={showDuplicateModal} onKeyPress={showDuplicateModal} role="button" tabIndex={0}>
                  <FormattedMessage {...message.duplicateLabel} />
                </div>
              </div>
            }
            placement="left"
            style={{ width: '125px', height: '92px' }}
          >
            {isSegmentCreationEnabled ? (
              <div className={styles.moreIconWrapper}>
                <MoreIcon fillColor="#83878C" />
              </div>
            ) : null}
          </Popover>
        </Col>
      </Row>

      {isEditModalVisible ? (
        <AddListModal
          isModalVisible={isEditModalVisible}
          handleCancel={handleCancel}
          handleOk={handleOk}
          title={modalTitle}
          buttonName={buttonName}
          form={form}
          isLoading={isLoading}
          inputText={inputText}
          characterLimit={characterLimit}
          onDescriptionInputChange={onDescriptionChange}
          jobDetailsStatus={jobDetailsStatus}
          modalType="Segment"
        />
      ) : null}
      <ArchiveListModal isModalVisible={isArchiveModalVisible} setIsArchiveModalVisible={setIsArchiveModalVisible} />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupListItem);
export { GroupListItem };
