import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from 'antd';
import ReviewCommentDetails from './ReviewCommentDetails';
import './ReviewCommentsHeader.scss';
import jobMessage from '../JobForm/JobMessages';

function ReviewCommentsHeader(props) {
  const { onClickSeeDetails, isExpanded, jobId, AppName } = props;
  return (
    <div className="review-comment">
      <div className="review-comment-title">
        <Icon type="exclamation-circle" className="review-comment-exclamation" />
        <FormattedMessage {...jobMessage.reviewCommentsLabel} />;
      </div>
      <div className="review-comment-description">
        <span>
          <FormattedMessage {...jobMessage.candidateFindingIssueLabel} />
        </span>
        <div onClick={onClickSeeDetails} role="presentation" className="see-details-wrapper">
          <span className="see-details">
            <FormattedMessage {...jobMessage.seeDetailsLabel} />
          </span>
          <span className="arrow-icon">
            <Icon type={isExpanded ? 'down' : 'right'} />
          </span>
        </div>
      </div>
      {isExpanded ? <ReviewCommentDetails onClose={onClickSeeDetails} jobId={jobId} AppName={AppName} /> : null}
    </div>
  );
}

export default ReviewCommentsHeader;
