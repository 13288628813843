import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import classNames from 'classnames';
import { Tooltip, Skeleton } from 'antd';
import { isPulseUser } from '../../Utils/ConfigUtils';
import styles from './CandidateCardHeader.module.scss';
import {
  getCandidateCardCandidateStatus,
  getJoinedDisplayedInfo,
  getJobCandidateStatus,
} from '../../Utils/CandidateCardUtils';
import CandidateCardContext from '../../CompoundComponents/CandidateCardWrapper/CandidateCardContext';
import { getInternalSourceWithCandidateId, getPushedAtsSource } from '../../Utils/SourceUtils';
import PushIconV2 from '../../Icons/PushIconV2';
import { ScoutingAgentOutlinedSvg } from '../../Icons/ScoutingAgentIcon';
import { getCandidateRejectSection, getPublishedTag } from '../CandidateListItem/CandidateListItem';
import TrophyAwardIcon from '../../Icons/TrophyAwardIcon';
import messages from './CandidateCardMessages';

function CandidateCardHeader(props) {
  const {
    candidate = {},
    activeTab,
    featureToggleList = {},
    version,
    onCandidateCardClick,
    candidateId,
    rejectedCandidateIds,
    userConfig,
    currentUserDetails,
    candidateViewedApiStatus,
    candidateContext,
    currentUserViewedStatusFlag,
    redirectTabName,
    areDuplicateCandidatesVisible,
    duplicateCandidateIds,
    candidateCardContext,
    atsConfig,
  } = React.useContext(CandidateCardContext);

  const { showExpandedCandidateCard } = props;

  const candidateCardWrapperStyle =
    (areDuplicateCandidatesVisible && duplicateCandidateIds.length) || candidateCardContext === 'duplicate'
      ? {
          borderTopLeftRadius: '0px',
        }
      : {};

  const {
    PublishingCandidates: { IsEnabled: isPublishingCandidatesEnabled },
    ShowAllClientCandidateStatus: { IsEnabled: isShowAllClientCandidateStatus },
  } = featureToggleList;

  const lastViewedByName = currentUserDetails.Id === candidate.LastViewedBy ? 'Me' : candidate.LastViewedByName;
  const lastViewedDate = moment.utc(candidate.LastViewedDate).local().format(`Do MMM'YY`);
  const isCandidateHeaderLoading = candidateViewedApiStatus === 'INPROGRESS';
  const lastViewedText = !currentUserViewedStatusFlag ? 'Last' : null;
  const candidateLastViewedInfo = candidate.IsCandidateViewed ? (
    <div className={styles.shortlistInfo}>
      {lastViewedText}{' '}
      <span className={styles.highlightedText}>
        <FormattedMessage {...messages.viewedLabel} />
      </span>{' '}
      <FormattedMessage {...messages.byLabel} /> {lastViewedByName} <FormattedMessage {...messages.onLabel} />{' '}
      {lastViewedDate}
    </div>
  ) : null;

  const recommendedByFullName = candidate.RecommendationBy?.FullName;
  const isPulse = isPulseUser();
  const recommendedTime = moment.utc(candidate.RecommendedTime).local().format('ddd DD MMM hh:mm A');

  const shouldDisplayShortlistedInfo =
    activeTab !== 'rejected' &&
    candidate.Status === 'Shortlisted' &&
    !isPulse &&
    recommendedByFullName &&
    recommendedTime;

  const shortlistedCandidateInfoText =
    candidateContext === 'job' ? (
      <FormattedMessage {...messages.shortlistedLabel} />
    ) : (
      <FormattedMessage {...messages.addedLabel} />
    );

  const shortlistedInfo = shouldDisplayShortlistedInfo ? (
    <div className={styles.shortlistInfo}>
      <span className={styles.highlightedText}>{shortlistedCandidateInfoText}</span>{' '}
      <FormattedMessage {...messages.byLabel} />: {recommendedByFullName}, {recommendedTime}
    </div>
  ) : null;

  const { displayedCandidateStatusMessage, showTrophyIcon, statusTag } = getCandidateCardCandidateStatus(
    candidate,
    featureToggleList,
    atsConfig
  );

  const jobCandidateStatus = getJobCandidateStatus(candidate, featureToggleList);

  const candidateHeaderInfo =
    isShowAllClientCandidateStatus && showTrophyIcon
      ? [candidateLastViewedInfo, shortlistedInfo, jobCandidateStatus]
      : [candidateLastViewedInfo, shortlistedInfo, displayedCandidateStatusMessage];
  const displayedCandidateHeaderInfo = getJoinedDisplayedInfo(candidateHeaderInfo, '>');
  const isValidCandidateHeaderInfo = candidateHeaderInfo.some(value => value);
  const showCandidateHeaderInfo = isValidCandidateHeaderInfo ? (
    <div
      className={classNames(styles.displayedCandidateHeaderInfo, {
        [styles.collapsedCandidateHeaderInfo]: !showExpandedCandidateCard,
      })}
      style={candidateCardWrapperStyle}
    >
      {displayedCandidateHeaderInfo}
    </div>
  ) : null;

  const candidateSources = candidate.Sources;
  const isAtsVersion = version === 'ats';
  const candidateSource = isAtsVersion
    ? getInternalSourceWithCandidateId(candidateSources)
    : getPushedAtsSource(candidateSources);

  const handleCardClick = e => {
    if (version === 'ats') {
      e.stopPropagation();
      onCandidateCardClick(redirectTabName);
    }
  };

  const candidateCardPushIcon = candidateSource ? (
    <Tooltip
      title={
        <div>
          <FormattedMessage {...messages.clientCandidateId} />: {candidateSource.CandidateId}
        </div>
      }
    >
      <div>
        <PushIconV2 className={styles.pushStatusIcon} onClick={handleCardClick} fillColor="#07101a" />
      </div>
    </Tooltip>
  ) : null;
  const pulseCandidateRejectSection = getCandidateRejectSection(
    candidateId,
    userConfig,
    activeTab,
    rejectedCandidateIds
  );
  const showPublishedTag = candidate.IsPublished && isPublishingCandidatesEnabled;
  const publishedStatus = candidate.PublishStatus || null;

  const publishedTag = showPublishedTag
    ? getPublishedTag({ publishedStatus, candidateStatus: displayedCandidateStatusMessage, candidate })
    : null;
  const getTime = moment.utc(candidate.ScoutedTime).local().format('hh:mm A | DD MMM YY');
  return (
    <div className={styles.candidateCardHeaderInfo}>
      <Skeleton loading={isCandidateHeaderLoading} active rows={1} title={false}>
        <div className={styles.candidateHeaderInfoWithPushIcon}>
          {candidate.ScoutingAgentId && (
            <Tooltip
              title={
                candidate.IsScoutingAgentDeleted
                  ? `Scouted on: ${getTime} by ${candidate.ScoutingAgent} (Deleted)`
                  : `Scouted on: ${getTime} by ${candidate.ScoutingAgent}`
              }
              placement="topLeft"
            >
              <div className={styles.scoutingAgentIcon} role="presentation">
                <ScoutingAgentOutlinedSvg width="16" height="16" />
              </div>
            </Tooltip>
          )}
          {showCandidateHeaderInfo}
          {candidateCardPushIcon}
        </div>
        <div className={styles.candidateCardTags}>
          {isShowAllClientCandidateStatus && (showTrophyIcon || statusTag) ? (
            <div className={styles.candidateStatusWithIcon}>
              <TrophyAwardIcon />
              <div className={styles.candidateStatus}>{statusTag || displayedCandidateStatusMessage}</div>
            </div>
          ) : null}
          {pulseCandidateRejectSection}
          {publishedTag}
        </div>
      </Skeleton>
    </div>
  );
}

export default CandidateCardHeader;
