import React from 'react';
import { Empty } from 'antd';
import { FormattedMessage } from 'react-intl';
import { EmptyCandidatesIcon } from '../../../Icons/AryaIcons';
import styles from './ManualSearchFormEmptyScreen.module.scss';
import message from '../../../Components/Placeholders/PlaceholdersMessages';

const ManualSearchEmptyTabScreen = () => (
  <div className={styles.manualSearchEmptyScreenWrapper}>
    <Empty
      image={<EmptyCandidatesIcon className={styles.manualSearchEmptyScreen} />}
      description={
        <div>
          <div className={styles.messageHeader}> <FormattedMessage {...message.noSearchPerformedLabel}/></div>
          <div className={styles.messageInfo}><FormattedMessage {...message.performSearchWithRecommendedSearchCriteriaLabel}/></div>
        </div>
      }
    />
  </div>
);

export { ManualSearchEmptyTabScreen };
