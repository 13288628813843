import React from 'react';
import { Skeleton } from 'antd';
import { getCleanedHighlights } from '../../Utils/KeywordHighlightUtil';
import HtmlViewer from '../HtmlViewer/HtmlViewer';
import PdfViewer from '../PdfViewer/PdfViewer';
import TextViewer from '../TextViewer/TextViewer';
import ResumeIcon from '../../Icons/ResumeIcon';
import ResumePreviewNotAvailableIcon from '../../Icons/ResumePreviewNotAvailableIcon';
import styles from './ResumeViewer.module.scss';

const emptyArray = [];

function ResumeViewer(props) {
  const {
    resume,
    highlights,
    mustHavesKeywords = emptyArray,
    candidateDetailsStatus,
    candidateListStatus,
    isSocialResumeDownloadAllowed,
    isSocialResumeGenerationEnabled,
    resumeDownloadButton,
  } = props;

  const cleanedHighlights = getCleanedHighlights(highlights);

  const resumeViewer = resumeDetails => {
    switch (resumeDetails.MediaType) {
      case 'application/pdf':
        return (
          <PdfViewer
            highlights={cleanedHighlights}
            mustHavesKeywords={mustHavesKeywords}
            base64PdfContent={`data:application/pdf;base64,${resumeDetails.Content}`}
          />
        );
      case 'text/html':
        return (
          <HtmlViewer
            mustHavesKeywords={mustHavesKeywords}
            highlights={cleanedHighlights}
            htmlContent={resumeDetails.Content}
          />
        );
      case 'text/plain':
        return (
          <TextViewer
            mustHavesKeywords={mustHavesKeywords}
            highlights={cleanedHighlights}
            className="resume-textviewer"
            text={resumeDetails.Content}
          />
        );
      default:
        return (
          <div style={{ height: 'inherit' }}>
            <ResumePreviewNotAvailableIcon className={styles.resumeIcon} />
            <h2 style={{ textAlign: 'center' }}>Preview not available</h2>
            <div className={styles.resumeDownloadButton}>{resumeDownloadButton}</div>
          </div>
        );
    }
  };

  const getResumeInfoText = () => {
    if (isSocialResumeDownloadAllowed)
      return (
        <div
          style={{
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ResumeIcon className={styles.resumeIcon} />
          <h2 style={{ textAlign: 'center' }}>You can download Arya generated resume</h2>
          <div className={styles.resumeDownloadButton}>{resumeDownloadButton}</div>
        </div>
      );
    if (isSocialResumeGenerationEnabled)
      return (
        <div style={{ height: 'inherit' }}>
          <ResumeIcon className={styles.resumeIcon} />
          <h2 style={{ textAlign: 'center' }}>Pull contacts to generate a resume</h2>
        </div>
      );
    return (
      <div style={{ height: 'inherit' }}>
        <ResumeIcon className={styles.resumeIcon} />
        <h2 style={{ textAlign: 'center' }}>Resume Not Available</h2>
      </div>
    );
  };

  const resumeInfoText = getResumeInfoText();

  return (
    <Skeleton
      paragraph={{ rows: 10 }}
      loading={candidateDetailsStatus === 'INPROGRESS' || candidateListStatus === 'INPROGRESS'}
    >
      {resume ? resumeViewer(resume) : resumeInfoText}
    </Skeleton>
  );
}

export default ResumeViewer;
