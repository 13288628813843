import { defineMessages } from 'react-intl';
import connectTemplatesMessagesJsonEn from '../../Data/En_Us/ConnectTemplatesMessagesEn_Us.json';
import connectTemplatesMessagesJsonEs from '../../Data/Es_Es/ConnectTemplatesMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'ConnectTemplates';
const connectTemplateMessages = formatMessagesWithComponentPrefix(connectTemplatesMessagesJsonEn, component);

const connectTemplatesMessagesEn = formatJsonWithComponentPrefix(connectTemplatesMessagesJsonEn, component);

const connectTemplatesMessagesEs = formatJsonWithComponentPrefix(connectTemplatesMessagesJsonEs, component);

const messages = defineMessages(connectTemplateMessages);

export default messages;
export { connectTemplatesMessagesEn, connectTemplatesMessagesEs };
