import { Icon } from 'antd';
import * as React from 'react';

const MessageSentSvg = props => (
  <svg fill="none" height="12" viewBox="0 0 9 7" width="16" xmlns="http://www.w3.org/2000/svg">
    <path d="m8.75.705-6 6-2.75-2.75.705-.705 2.045 2.04 5.295-5.29z" fill="#83878c" />
  </svg>
);

const MessageSentIcon = props => <Icon component={() => MessageSentSvg(props)} {...props} />;
export default MessageSentIcon;
