import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import { Drawer, Form, Select } from 'antd';
import _ from 'lodash';
import BaseTemplateForm from '../BaseTemplateForm';
import ScreeningQuestionItem from '../../ScreeningQuestionItem/ScreeningQuestionItem';
import { getRemovedFormFields } from '../../../../Utils/FormUtils.ts';
import './CreateAryabotTemplate.scss';
import eventTypes from '../../../../Analytics/EventTypes';
import LinkButton from '../../../Common/LinkButton/LinkButton';
import message from '../../../../Containers/CandidateDrawer/messages';

class CreateAryabotTemplate extends BaseTemplateForm {
  constructor(props) {
    super(props);
    this.state = {
      botQuestions: [],
    };

    this.saveTemplate = this.saveTemplate.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const propQuestions = _.get(props, ['initialValues', 'Questions'], []);

    if (!_.isEqual(_.get(props, ['initialValues', 'Questions'], []), state.propQuestions)) {
      return {
        ...state,
        botQuestions: _.get(props, ['initialValues', 'Questions'], []),
        propQuestions,
      };
    }

    return {
      ...state,
      propQuestions,
    };
  }

  addQuestion() {
    const { botQuestions } = this.state;
    const cloneBotQuestions = _.cloneDeep(botQuestions);
    cloneBotQuestions.push({ Question: '', ExpectedAnswer: 'Yes' });

    this.setState({
      botQuestions: cloneBotQuestions,
    });
  }

  removeQuestion(index) {
    const { botQuestions } = this.state;
    const { form } = this.props;
    const formValues = form.getFieldsValue();
    const removedFields = getRemovedFormFields(formValues, index);
    const cloneBotQuestions = _.cloneDeep(botQuestions);
    cloneBotQuestions.splice(index, 1);
    this.setState({
      botQuestions: cloneBotQuestions,
    });
    form.resetFields(removedFields);
  }

  getSelectTemplateItem(label) {
    const { form } = this.props;
    return (
      <Form.Item label={label} colon={false}>
        {form.getFieldDecorator('template')(<Select />)}
      </Form.Item>
    );
  }

  swapQuestionFormValue = ({ dragIndex, hoverIndex }) => {
    const { form } = this.props;
    const dragQuestionFormValue = form.getFieldValue(`bot-question_${dragIndex}`);
    const dragAnswerFormValue = form.getFieldValue(`bot-answer_${dragIndex}`);

    form.setFieldsValue({
      [`bot-question_${dragIndex}`]: form.getFieldValue(`bot-question_${hoverIndex}`),
      [`bot-answer_${dragIndex}`]: form.getFieldValue(`bot-answer_${hoverIndex}`),
      [`bot-question_${hoverIndex}`]: dragQuestionFormValue,
      [`bot-answer_${hoverIndex}`]: dragAnswerFormValue,
    });
  };

  moveQuestions = (dragIndex, hoverIndex) => {
    const { botQuestions } = this.state;
    const clonedQuestions = _.cloneDeep(botQuestions);
    const dragQuestion = clonedQuestions[dragIndex];
    clonedQuestions.splice(dragIndex, 1);
    clonedQuestions.splice(hoverIndex, 0, dragQuestion);
    this.swapQuestionFormValue({ dragIndex, hoverIndex });
    this.setState({ botQuestions: clonedQuestions });
  };

  getQuestions() {
    const { botQuestions } = this.state;
    const { form, viewOnly } = this.props;
    return (
      <div>
        {!viewOnly ? (
          <div>
            <FormattedMessage {...message.chatbotQuestionsLabel} /> &nbsp;&nbsp;
            <LinkButton buttonName="Add Questions" onClickButton={this.addQuestion} />
          </div>
        ) : null}
        <div className="questions">
          {botQuestions.map((question, index) => {
            return (
              <ScreeningQuestionItem
                key={question.Id}
                question={question.Question}
                expectedAnswer={question.ExpectedAnswer}
                form={form}
                index={index}
                removeQuestion={() => this.removeQuestion(index)}
                readOnly={viewOnly}
                onDragQuestion={this.moveQuestions}
                dragType="template_screening_question_drag"
                questionId={question.Id}
              />
            );
          })}
        </div>
      </div>
    );
  }

  createRequestBody() {
    const { botQuestions } = this.state;
    const { form } = this.props;
    return botQuestions.map((question, index) => {
      return {
        Question: form.getFieldValue(`bot-question_${index}`),
        ExpectedAnswer: form.getFieldValue(`bot-answer_${index}`),
        CategoryName: 'Filtering',
        Order: index + 1,
      };
    });
  }

  saveTemplate(event) {
    const { onSubmit, form, initialValues } = this.props;
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const isPublic = initialValues?.IsPublic ?? false;
        onSubmit(values['template-name'], isPublic, this.createRequestBody());
        form.resetFields();
      }
    });
  }

  onClose() {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const { title, visible, initialValues, viewOnly, onClose } = this.props;

    return (
      <Drawer
        className="create-template aryabot drawer"
        destroyOnClose
        title={title}
        placement="right"
        onClose={this.onClose}
        visible={visible}
        width={650}
      >
        <Form onSubmit={this.saveTemplate}>
          {this.getTemplateNameItem(`AryaBot Template Name`, 'Please input the template name', initialValues.Name)}
          {/* {this.getSelectTemplateItem('Select Template')} */}
          {this.getQuestions()}
          <div className="footer">
            {!viewOnly
              ? this.getSaveButton(
                  <FormattedMessage {...message.saveTemplateLabel} />,
                  null,
                  eventTypes.connect.aryaBotTemplates.save
                )
              : null}
            {this.getCancelButton(
              <FormattedMessage {...message.cancelButton} />,
              onClose,
              eventTypes.connect.aryaBotTemplates.cancel
            )}
          </div>
        </Form>
      </Drawer>
    );
  }
}

CreateAryabotTemplate.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}),
};

CreateAryabotTemplate.defaultProps = {
  initialValues: {},
};

export default DragDropContext(HTML5Backend)(Form.create()(CreateAryabotTemplate));

export { CreateAryabotTemplate as CreateAryabotTemplateWithoutForm };
