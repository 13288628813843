import _ from 'lodash';
import { getCurrentSearchResult } from '../../Utils/AggregationFilterUtils';
import { convertToDays, getTimePeriodWithUnit } from '../../Utils/DaysConversion';
import {
  toBooleanQueryStringUsingOR,
  generateBooleanString,
  generateBooleanNiceToHaves,
  generateArrayFromBooleanString,
} from '../../Utils/BooleanStringUtility';
import { generateMandatorySkillsArray } from '../../Utils/CandidateRejectReasonsUtils';
import { getActiveSourcingLocations, getSourcingLocations } from '../../Utils/LocationUtils';
import NaukriOptions from '../../Data/AdvancedOptions/Naukri/NaukriOptions.json';

function getColonSeperatedString(value) {
  return value?.length ? value.join(':') : undefined;
}

export function getCommaSplittedString(value) {
  return value?.length ? _.split(value, ',') : undefined;
}

export function getColonSplittedArray(value) {
  return value?.length ? _.split(value, ':') : undefined;
}

export function filterMonsterClassicLanguages(languages) {
  const filteredLanguages = languages.filter(language => language.Language && language.Proficiency);
  return filteredLanguages.length ? JSON.stringify(filteredLanguages) : undefined;
}

export function getMonsterClassicCompanyCategories(companyCategories) {
  const jobIds = companyCategories.map(companyCategory => companyCategory[0]);
  return _.uniq(jobIds).map(jobId => ({
    TargetJobId: jobId,
    TargetOccupations: companyCategories
      .filter(companyCategory => companyCategory[0] === jobId)
      .map(filteredCategory => filteredCategory[1])
      .filter(occupationId => occupationId),
  }));
}

export function generateAlternateLocationsFromForm(values, jobBoard, type) {
  return Object.keys(values)
    .filter(value => value.split('-')[0] === jobBoard && value.split('-')[1] === type)
    .map(option => {
      if (values[option]) return { value: values[option], index: option.split('-')[2] };
      return null;
    })
    .filter(value => value);
}
export const getStateForPayload = value => {
  try {
    return JSON.parse(value)?.state;
  } catch (e) {
    return value;
  }
};
export const getCityStateForPayload = value => {
  try {
    return `${JSON.parse(value).city}${', '}${JSON.parse(value).state}`;
  } catch {
    return value;
  }
};

export function generateAlternateLocationsPayload(values, jobBoard) {
  const alternateLocations = generateAlternateLocationsFromForm(values, jobBoard, 'alternatelocation');
  if (!alternateLocations?.length) return undefined;
  const alternateZipcodes = generateAlternateLocationsFromForm(values, jobBoard, 'alternatezipcode');
  const alternateDistances = generateAlternateLocationsFromForm(values, jobBoard, 'distance');
  const alternateDistanceUnits = generateAlternateLocationsFromForm(values, jobBoard, 'distanceunit');
  const cityStateLocations = generateAlternateLocationsFromForm(values, jobBoard, 'IsStateWideSearch');
  const alternateLocationsPayload = alternateLocations.map(alternateLocation => {
    const isStateWideSearchChecked = cityStateLocations.find(
      alternateZipcode => alternateZipcode.index === alternateLocation.index
    )?.value;
    const location = {
      PostalCode: alternateZipcodes.find(alternateZipcode => alternateZipcode.index === alternateLocation.index)?.value,
    };
    if (isStateWideSearchChecked) {
      location.State = getStateForPayload(alternateLocation?.value);
    } else {
      location.Location = getCityStateForPayload(alternateLocation?.value);
      if (alternateDistances?.length) {
        location.Distance = alternateDistances.find(
          alternateDistance => alternateDistance.index === alternateLocation.index
        )?.value;
      }
    }
    if (alternateDistanceUnits?.length) {
      location.DistanceUnit = alternateDistanceUnits.find(
        alternateDistanceUnit => alternateDistanceUnit.index === alternateLocation.index
      )?.value;
    }
    return location;
  });
  return JSON.stringify(alternateLocationsPayload);
}

export function getMonsterClassicSalaryCurrency(values) {
  const monsterSalaryCurrency = values['monster-salary-currency'];
  const monsterMinSalary = values['monster-min-salary'];
  const monsterMaxSalary = values['monster-max-salary'];
  let salaryCurrency;
  if (monsterSalaryCurrency && (monsterMinSalary || monsterMaxSalary)) {
    salaryCurrency = monsterSalaryCurrency;
  } else if (!monsterSalaryCurrency && (monsterMinSalary || monsterMaxSalary)) {
    salaryCurrency = 'USD';
  }
  return salaryCurrency;
}

export function getIncludeResumeWithNoSalaryInfo(values) {
  const minSalary = values['monster-min-salary'];
  const maxSalary = values['monster-max-salary'];
  if (Number.isInteger(minSalary) || Number.isInteger(maxSalary)) {
    return values['monster-include-resumes-with-no-salary-info'] !== false ? 1 : 0;
  }
  return undefined;
}

export function getNaukriPortalQueries(values) {
  return {
    MinSalary: values.Naukri_MinSalary,
    MaxSalary: values.Naukri_MaxSalary,
    IncludeResumesWithNoSalaryInfo: values.Naukri_IncludeResumesWithNoSalaryInfo,
    ExcludePreferredLocation: !values.Naukri_ExcludePreferredLocation,
    ExcludeAnywhereCandidates: !!values.Naukri_ExcludeAnywhereCandidates,
    NoticePeriod: values.Naukri_NoticePeriod?.join(','),
    LastActiveInDays: values.Naukri_LastActiveInDays,
  };
}

export function getSocialPortalQueries(values) {
  return {
    SocialSourceName: values['social_source-name'] ? _.join(values['social_source-name'], ',') : undefined,
  };
}

export function getPassivePortalQueries(values) {
  return {
    SocialSourceName: values['passive_source-name'] ? _.join(values['passive_source-name'], ',') : undefined,
  };
}

export function getClassicMonsterPortalQueries(values) {
  return {
    RecentJobTitle: values['monster-candidate-recent-job-title'] || undefined,
    RecentJobDescription: values['monster-candidate-recent-job-description'] || undefined,
    TargetJobTitle: values['monster-target-job-title'] || undefined,
    CareerLevels: values['monster-career-level'] ? values['monster-career-level'].join(',') : undefined,
    LanguageSkills: _.get(values, 'monster-languages', []).length
      ? filterMonsterClassicLanguages(values['monster-languages'])
      : undefined,
    DesiredJobType: values['monster-job-type'] ? values['monster-job-type'].join(',') : undefined,
    CompanyIndustries: values['monster-company-industries']
      ? values['monster-company-industries'].join(',')
      : undefined,
    CompanyCatagories: _.get(values, 'monster-company-catagories', []).length
      ? JSON.stringify(getMonsterClassicCompanyCategories(values['monster-company-catagories']))
      : undefined,
    SalaryType: values['monster-salary-type'],
    SalaryCurrency: getMonsterClassicSalaryCurrency(values),
    MinSalary: values['monster-min-salary'],
    MaxSalary: values['monster-max-salary'],
    IncludeResumesWithNoSalaryInfo: getIncludeResumeWithNoSalaryInfo(values),
    TargetWorkSite: values['monster-target-work-site'],
    WillingToRelocate: values['monster-relocation'],
    HasProjectLeadershipExperience: values['monster-project-leadership-experience'],
    HasManagerialExperience: values['monster-managerial-experience'],
    SecurityClearance: values['monster-current-security-clearance'],
  };
}

export function getPowerMonsterPortalQueries(values) {
  return {
    CandidateName: values['monster-candidate-name'] || undefined,
    IncludeResumesWithNoSalaryInfo: getIncludeResumeWithNoSalaryInfo(values),
    SalaryType: values['monster-salary-type'] || undefined,
    SalaryCurrency: values['monster-salary-currency'],
    // ! Commenting out the code for future use
    // CareerLevels: values['monster-career-level'],
    // MinSalary: values['monster-min-salary'],
    LanguageSkills: _.get(values, 'monster-languages', []).length
      ? filterMonsterClassicLanguages(values['monster-languages'])
      : undefined,
    MaxSalary: values['monster-max-salary'],
    DesiredJobType: values['monster-job-type'] ? values['monster-job-type'].join(',') : undefined,
    JobDuration: values['monster-job-duration'] || undefined,
    SecurityClearance: values['monster-security-clearance']
      ? values['monster-security-clearance'].join(',')
      : undefined,
    WillingnessToTravel: values['monster-willing-to-travel'],
    WillingToRelocate: values['monster-relocation'] ? 'true' : undefined,
    ExperienceType: values['monster-experience-type'] || 'NiceToHave',
    WorkAuthorization: values['monster-work-authorization'] || 'ShowAll',
    VateranStatus: values['monster-veteran-status'] || undefined,
    Citizenship: _.get(values, 'monster-power-citizenship', []).length
      ? values['monster-power-citizenship'].join(',')
      : undefined,
  };
}

export function getCareerBuilderPortalQueries(values) {
  const alternateLocations = generateAlternateLocationsPayload(values, 'careerbuilder');
  return {
    IsSearchWithMostRecentJobTitle: values['careerbuilder-search-recent-job-title'],
    // JobCompanies: values['careerbuilder-companies'] ? values['careerbuilder-companies'].join(',') : undefined,
    IsSearchWithMostRecentCompany: values['careerbuilder-search-recent-company'],
    IsSearchWithLastActive: values['careerbuilder-last-active'],
    OrderBy: values['careerbuilder-order-by'],
    JobCategories: values['careerbuilder-category'] ? values['careerbuilder-category'].join(',') : undefined,
    DesiredJobType: values['careerbuilder-employment-type']?.join(','),
    MinimumTravelRequirement: values['careerbuilder-minimum-travel'],
    LanguagesSpoken: values['careerbuilder-language'] ? values['careerbuilder-language'].join(',') : undefined,
    WorkStatus: values['careerbuilder-work-authorization']
      ? values['careerbuilder-work-authorization'].join(',')
      : undefined,
    SecurityClearance:
      values['careerbuilder-security-clearance'] === 'Either' ? undefined : values['careerbuilder-security-clearance'],
    MilitaryExperience: values['careerbuilder-military-experience']
      ? values['careerbuilder-military-experience'].join(',')
      : undefined,
    SalaryType: values['careerbuilder-salary-type'],
    MinSalary: values['careerbuilder-min-salary'],
    MaxSalary: values['careerbuilder-max-salary'],
    SalaryCurrency: 'USD',
    CurrentlyEmployed: values['careerbuilder-currently-employed'],
    ManagementExperience: values['careerbuilder-management-experience'],
    MinimumEmployeesManaged: values['careerbuilder-minimum-employees'],
    AlternateLocations: alternateLocations,
    // ExcludeResumesWithNoSalary: values['careerbuilder-exclude-resumes-no-salary'],
  };
}

export function getCareerBuilderEdgePortalQueries(values) {
  return {
    Names: values['cbedge_candidate-name'] || undefined,
    Email: getColonSeperatedString(values.cbedge_email),
    Employer: values['cbedge_company-name'] || undefined,
    CompanySizeCode: getColonSeperatedString(values['cbedge_company-size']),
    DesiredJobType: getColonSeperatedString(values['cbedge_desired-job-type']),
    WorkStatus: values['cbedge_work-status'],
    WillingToRelocate:
      values['cbedge_willing-to-relocate'] === 'not_selected' ? undefined : values['cbedge_willing-to-relocate'],
    SecurityClearance:
      values['cbedge_security-clearance'] === 'not_selected' ? undefined : values['cbedge_security-clearance'],
  };
}

export function getDicePortalQueries(values) {
  const alternateLocations = generateAlternateLocationsPayload(values, 'dice');
  return {
    LastUpdatedInDays: values['dice-last-update'] || undefined,
    ContactMethod: values['dice-contact-method'] ? values['dice-contact-method'].join(',') : undefined,
    ContactMethodOperator:
      values['dice-contact-method']?.length > 1 ? values['dice-contact-method-boolean'] : undefined,
    LikelyToMove: values['dice-likely-to-switch'],
    ProfileSource: values['dice-exclude-social-only-profiles'],
    Language: values['dice-languages'] ? values['dice-languages'].join(',') : undefined,
    SocialProfiles: values['dice-social-profiles']?.join('|'),
    DesiredJobType: values['dice-job-types']?.join(','),
    SecurityClearance: values['dice-security-clearance'],
    WillingToRelocate: values['dice-relocation'],
    WillingToRelocateIncludeLocals: values['dice-include-local-candidates'],
    HasPatent: values['dice-has-patent'],
    ShouldExcludeFounders: values['dice-exclude-founder'],
    ShouldExcludeThirdParty: values['dice-exclude-3rd-party'],
    MinSalary: values['dice-min-salary'],
    MaxSalary: values['dice-max-salary'],
    IncludeCandidatesWithoutCompensation: values['dice-include-candidates-without-compensation'],
    SalaryCurrency: values['dice-currency'],
    SalaryType: values['dice-salary-type'],
    WorkStatus: values['dice-work-authorizations']?.join(','),
    AlternateLocations: alternateLocations,
    TravelPreference: values['dice-travel-preference'],
    AuthorizedCountries: values['dice-authorized-countries']?.join(','),
    ExcludeLocationInSearch: values['dice-include-country-search'],
  };
}

export function getClearanceJobsPortalQueries(values) {
  return {
    Firstname: values['cj_candidate-first-name'],
    Lastname: values['cj_candidate-last-name'],
    SortInfo: values['cj_sort-by'],
    Industries: values.cj_industry?.join(','),
    WillingToRelocate: values.cj_relocation === 'not_selected' ? undefined : values.cj_relocation,
    StateList: values['cj_state-location']?.join(','),
    LocationsUs: values['cj_domestic-location']?.join(','),
    LocationsInternational: values['cj_international-location']?.join(','),
    CareerLevel: values['cj_career-level']?.join(','),
    Salary: values.cj_salary?.join(','),
    ResumeFlags: values['cj_resume-flags']?.join(','),
    JobType: values['cj_job-type']?.join(','),
    Polygraph: values.cj_polygraphs?.join(','),
    Clearance: values['cj_security-clearance']?.join(','),
  };
}

export function getDiceDefaultValue(key) {
  switch (key) {
    case 'dice-contact-method':
      return ['Email'];
    case 'dice-contact-method-boolean':
      return 'OR';
    case 'dice-likely-to-switch':
      return null;
    case 'dice-exclude-social-only-profiles':
      return true;
    case 'dice-salary-type':
      return 'Yearly';
    case 'dice-currency':
      return 'USD';
    case 'dice-include-candidates-without-compensation':
      return false;
    case 'dice-include-local-candidates':
      return false;
    case 'dice-travel-preference':
      return null;
    case 'dice-exclude-3rd-party':
      return true;
    case 'dice-has-patent':
      return false;
    case 'dice-exclude-founder':
      return false;
    case 'dice-relocation':
      return null;
    case 'dice-include-country-search':
      return false;
    default:
      return undefined;
  }
}

export function getCareerBuilderClassicDefaultValue(key) {
  switch (key) {
    case 'careerbuilder-last-active':
      return true;
    case 'careerbuilder-order-by':
      return 'Relevancy';
    case 'careerbuilder-salary-type':
      return 'Yearly';
    case 'careerbuilder-currency':
      return 'USD';
    case 'careerbuilder-management-experience':
      return 'Either';
    case 'careerbuilder-search-recent-job-title':
      return false;
    case 'careerbuilder-search-recent-company':
      return false;
    default:
      return undefined;
  }
}

export function getNaukriDefaultValue(key) {
  switch (key) {
    case 'Naukri_LastActiveInDays':
      return NaukriOptions.LastActiveInDays['6 months'];
    case 'Naukri_ExcludePreferredLocation':
      return true;
    default:
      return undefined;
  }
}
export function getCareerBuilderEdgeDefaultValue(key) {
  switch (key) {
    case 'cbedge_security-clearance':
    case 'cbedge_willing-to-relocate':
      return 'not_selected';
    default:
      return undefined;
  }
}
export function getSocialDefaultValues(key) {
  return undefined;
}

export function getDefaultSwitchValue(value, defaultValue) {
  return value !== undefined ? value?.toString() === 'true' : defaultValue;
}

export function getMonsterDefaultValue(key) {
  switch (key) {
    case 'monster-salary-type':
      return 'Yearly';
    case 'monster-include-resumes-with-no-salary-info':
      return true;
    case 'monster-work-authorization':
      return 'ShowAll';
    case 'monster-experience-type':
      return 'NiceToHave';
    case 'monster-languages':
      return [{ Language: undefined, Proficiency: undefined }];
    case 'monster-language-keys':
      return [0];
    case 'monster-power-citizenship':
      return [];
    default:
      return undefined;
  }
}

export function getCareerBuilderEdgeFilterQueries(values) {
  return {
    AcademicDiscipline: getColonSeperatedString(values.cbedgefilter_AcademicDiscipline),
    CaroteneV3Title: getColonSeperatedString(values.cbedgefilter_CaroteneV3Title),
    City: getColonSeperatedString(values.cbedgefilter_City),
    ContactInfo: getColonSeperatedString(values.cbedgefilter_ContactInfo),
    Country: getColonSeperatedString(values.cbedgefilter_Country),
    CurrentEmployer: getColonSeperatedString(values.cbedgefilter_CurrentEmployer),
    CurrentlyEmployed:
      values.cbedgefilter_CurrentlyEmployed === 'not_selected' ? undefined : values.cbedgefilter_CurrentlyEmployed,
    DegreeLevel: getColonSeperatedString(values.cbedgefilter_DegreeLevel),
    Industry: getColonSeperatedString(values.cbedgefilter_Industry),
    State: getColonSeperatedString(values.cbedgefilter_State),
    Source: getColonSeperatedString(values.cbedgefilter_Source),
    Skill: getColonSeperatedString(values.cbedgefilter_Skill),
    PreviousEmployer: getColonSeperatedString(values.cbedgefilter_PreviousEmployer),
    HasResume: values.cbedgefilter_SearchResults === 'Resume Results' ? 'true' : undefined,
    HasSocialSource: values.cbedgefilter_SearchResults === 'Social Results' ? 'true' : undefined,
  };
}

export function getCareerBuilderEdgeDefaultFilterQueries(values) {
  const {
    AcademicDiscipline,
    CaroteneV3Title,
    City,
    ContactInfo,
    Country,
    CurrentEmployer,
    CurrentlyEmployed,
    DegreeLevel,
    Industry,
    State,
    Source,
    Skill,
    PreviousEmployer,
    HasResume,
    HasSocialSource,
  } = values;
  return {
    AcademicDiscipline: getColonSplittedArray(AcademicDiscipline),
    CaroteneV3Title: getColonSplittedArray(CaroteneV3Title),
    City: getColonSplittedArray(City),
    ContactInfo: getColonSplittedArray(ContactInfo),
    Country: getColonSplittedArray(Country),
    CurrentEmployer: getColonSplittedArray(CurrentEmployer),
    CurrentlyEmployed: CurrentlyEmployed || 'not_selected',
    DegreeLevel: getColonSplittedArray(DegreeLevel),
    Industry: getColonSplittedArray(Industry),
    State: getColonSplittedArray(State),
    Source: getColonSplittedArray(Source),
    Skill: getColonSplittedArray(Skill),
    PreviousEmployer: getColonSplittedArray(PreviousEmployer),
    HasResume,
    HasSocialSource,
  };
}

export function getdefaultFilterQueries(values, source) {
  const portal = source?.Portal?.toLowerCase();
  if (portal === 'careerbuilderedge') {
    return getCareerBuilderEdgeDefaultFilterQueries(values);
  }
  return {};
}

export function getFilterQueriesPayload(values, source) {
  const portal = source?.Portal?.toLowerCase();
  if (portal === 'careerbuilderedge') {
    return getCareerBuilderEdgeFilterQueries(values);
  }
  return {};
}

export function clearFilterQueries(form, sourceName) {
  const formValues = form.getFieldsValue();
  const fields = Object.keys(formValues);
  const values = {};
  const lowerCasedSourceName = sourceName.toLowerCase();
  if (lowerCasedSourceName === 'careerbuilderedge') {
    fields
      .filter(field => field.split('_')[0] === 'cbedgefilter')
      .forEach(option => {
        if (option === 'cbedgefilter_CurrentlyEmployed') values[option] = 'not_selected';
        else if (option === 'cbedgefilter_SearchResults') values[option] = 'All Results';
        else values[option] = undefined;
      });
    form.setFieldsValue(values);
  }
}

export function clearAggregation(form, sourceName, aggregationKey) {
  const lowerCasedSourceName = sourceName.toLowerCase();
  if (lowerCasedSourceName === 'careerbuilderedge') {
    form.setFieldsValue({ [`cbedgefilter_${aggregationKey}`]: undefined });
  }
}

const getCarrerBuilderEdgeAggregationCountPairs = aggregations => {
  const aggregationCountPairs = [];
  Object.entries(aggregations).forEach(aggregation => {
    const [aggregationKey, aggregationValue] = aggregation;
    if (
      aggregationValue &&
      !['ContactInfo', 'CurrentlyEmployed', 'HasResume', 'HasSocialSource'].includes(aggregationKey)
    ) {
      aggregationCountPairs.push({ key: aggregationKey, count: aggregationValue.length });
    }
  });
  return aggregationCountPairs;
};

export const getAggregationCountPairs = (aggregations, sourceName) => {
  const lowerCasedSourceName = sourceName?.toLowerCase();
  if (lowerCasedSourceName === 'careerbuilderedge') {
    return getCarrerBuilderEdgeAggregationCountPairs(aggregations);
  }
  return [];
};

export const QuickSearchEducationOptions = [
  {
    value: 'SomeHighSchoolCoursework',
    displayText: 'Some High School Coursework',
    supportedBy: ['Monster Power'],
  },
  {
    value: 'HighSchoolEquivalent',
    displayText: 'High School or equivalent',
    supportedBy: ['Dice', 'CB', 'Monster', 'ClearanceJobs'],
  },
  { value: 'VocationalHighSchool', displayText: 'Vocational - High School', supportedBy: ['Dice', 'ClearanceJobs'] },
  {
    value: 'VocationalDegree',
    displayText: 'Vocational - Degree',
    supportedBy: ['Dice', 'CB', 'Monster', 'ClearanceJobs'],
  },
  {
    value: 'SomeCollegeCourseworkCompleted',
    displayText: 'Some College Coursework Completed',
    supportedBy: ['Monster Power'],
  },
  {
    value: 'AssociateDegree',
    displayText: 'Associate Degree',
    supportedBy: ['Dice', 'CB', 'Monster', 'ClearanceJobs'],
  },
  {
    value: 'BachelorsDegree',
    displayText: "Bachelor's Degree",
    supportedBy: ['Dice', 'CB', 'Monster', 'ClearanceJobs'],
  },
  {
    value: 'MastersDegree',
    displayText: "Master's Degree",
    supportedBy: ['Dice', 'CB', 'Monster', 'ClearanceJobs'],
  },
  { value: 'MBA', displayText: 'MBA', supportedBy: ['Dice'] },
  { value: 'Doctorate', displayText: 'Doctorate', supportedBy: ['ÇB', 'Monster', 'Dice', 'ClearanceJobs'] },
  {
    value: 'Certification',
    displayText: 'Certification',
    supportedBy: ['Monster', 'ClearanceJobs'],
  },
  { value: 'Professional', displayText: 'Professional', supportedBy: ['Monster', 'ClearanceJobs'] },
  { value: 'MilitaryService', displayText: 'Military Service', supportedBy: ['Dice'] },
  { value: 'PreBachelors', displayText: 'Pre-Bachelors', supportedBy: ['Dice'] },
  { value: 'PreMasters', displayText: 'Pre-Masters', supportedBy: ['Dice'] },
  { value: 'PreDoctorate', displayText: 'Pre-Doctorate', supportedBy: ['Dice'] },
];

export function getIsAggregationFilterApplied(filterQueries, sourceName) {
  const lowerCasedSourceName = sourceName?.toLowerCase();
  const clonedFilterQueries = _.cloneDeep(filterQueries);
  if (lowerCasedSourceName === 'careerbuilderedge') {
    return (
      Object.values(_.omit(clonedFilterQueries, ['CurrentlyEmployed'])).filter(filterValue => filterValue).length > 0 ||
      clonedFilterQueries.CurrentlyEmployed !== 'not_selected'
    );
  }
  return false;
}

export function getIsAdvancedSettingsEnabled(source, isSocialAdvanceSettingsEnabled) {
  const sources = ['monster', 'careerbuilder', 'careerbuilderedge', 'dice', 'clearancejobs', 'naukri'];
  if (isSocialAdvanceSettingsEnabled) {
    sources.push(...['social', 'passive']);
  }
  return sources.includes(source?.Portal?.toLowerCase()) || sources.includes(source?.Group?.toLowerCase());
}

export function getAllowedSources(sources, allowActiveSource) {
  return sources.filter(
    source =>
      (source.Portal && source.Portal.toLowerCase() !== 'indeed') ||
      (source.Group && allowActiveSource) ||
      source?.Group?.toLowerCase() === 'passive'
  );
}

export const getFilteredCandidatesSearchResult = (filterQueries, sourceName) => {
  if (sourceName?.toLowerCase() === 'careerbuilderedge') {
    return getCurrentSearchResult(filterQueries);
  }
  return null;
};

const getQuickSearchFormPayload = ({ values, currentJobDetails, userGivenIntel, isMultiLocationEnabled }) => {
  const {
    Occupations,
    IsWillingToRelocate,
    SecurityClearance,
    CurrentlyEmployed,
    VisaStatuses,
    EmploymentTypes,
    CareerLevels,
    NoticePeriod,
    LastActiveInDays,
    Salary,
  } = userGivenIntel;
  const isStateWideSearchChecked = values.IsStateWideSearch;
  const jobSynonyms = values['job-synonyms'] ?? [];
  const jobTitle = values['job-title'];
  const combinedJobTitleSynonyms = jobTitle ? [jobTitle, ...jobSynonyms] : jobSynonyms;
  const resumeUpdateDurationQuantity = values['resume-update-duration-qty'];
  const resumeUpdateDurationType = values['resume-updated-duration-type'];
  const resumeUpdateDuration = resumeUpdateDurationQuantity
    ? convertToDays(resumeUpdateDurationQuantity, resumeUpdateDurationType)
    : null;
  const candidateFilter = {
    JobGuid: currentJobDetails?.JobGuid,
    Titles: combinedJobTitleSynonyms.map(synonym => synonym.trim()),
    QueryString: values['search-string']?.replace(/\n/g, ' '),
    LastUpdatedFrom: resumeUpdateDuration,
    LastUpdatedFromRaw: getTimePeriodWithUnit(resumeUpdateDurationQuantity, resumeUpdateDurationType),
    Keywords: {
      Musts:
        values['must-haves'] && !_.isEmpty(values['must-haves'])
          ? generateBooleanString(generateMandatorySkillsArray(values['must-haves']))
          : '',
      Prefers: generateBooleanNiceToHaves(values['nice-to-haves']),
      Excludes: values['exclude-keywords'] ? values['exclude-keywords'] : [],
      RawMusts:
        'must-haves' in values
          ? generateMandatorySkillsArray(values['must-haves'])?.map(x => x?.[0])
          : userGivenIntel?.RawMusts,
      RawPrefers: 'nice-to-haves' in values ? values['nice-to-haves'] : userGivenIntel?.RawPrefers,
    },
    Companies: {
      Musts: values['include-companies'] ? toBooleanQueryStringUsingOR(values['include-companies']) : '',
      RawMusts: values['include-companies'] || [],
      Excludes: values['exclude-companies'] ? values['exclude-companies'] : [],
    },
    CurrentCompanies: values['monster-current-companies'] ? values['monster-current-companies'] : [],
    Experience: {
      MinYears: values.MinExperience,
      MaxYears: values.MaxExperience,
    },
    Education: {
      Degree: values.degree ? toBooleanQueryStringUsingOR(values.degree) : undefined,
      School: values.school,
      Specialization: values.specialization,
      Certification: values.certification,
    },
    Occupations,
    Industries: {
      Musts: generateBooleanNiceToHaves(values['include-industries']),
      Excludes: values?.['exclude-industries'],
    },
    IsWillingToRelocate,
    SecurityClearance,
    CurrentlyEmployed,
    VisaStatuses,
    EmploymentTypes,
    CareerLevels,
    Salary,
    IsRemoteJob: values?.IsRemoteJob,
    NoticePeriod,
    LastActiveInDays,
  };
  if (candidateFilter.IsRemoteJob) {
    candidateFilter.Location = {
      CountryCode: values.CountryCode,
    };
    candidateFilter.Locations = undefined;
    return candidateFilter;
  }
  if (isMultiLocationEnabled) {
    const activeSourcingLocations = getActiveSourcingLocations(values.SourcingLocations);
    const sourcingLocations = getSourcingLocations(activeSourcingLocations);
    if (sourcingLocations?.length) candidateFilter.Locations = sourcingLocations;
    else candidateFilter.Location = { CountryCode: currentJobDetails.CountryCode };
    candidateFilter.IsMultiLocationJob = true;
  } else {
    candidateFilter.Location = {
      CountryCode: values.CountryCode,
      Region: values.CountryCode,
      PostalCode: values.Zipcode,
    };
    candidateFilter.IsMultiLocationJob = false;
    if (isStateWideSearchChecked) {
      candidateFilter.Location.State = getStateForPayload(values.Location);
    } else {
      candidateFilter.Location.Location = getCityStateForPayload(values.Location);
    }
    candidateFilter.Location.Distance = values.distance ? Number(values.distance) : undefined;
    candidateFilter.Location.DistanceUnit = 'Miles';
  }
  return candidateFilter;
};

const getKeywordsToHighlight = ({ searchString, mustHaves, niceToHaves, titles = [] }) => {
  const searchStringKeywords = _.flatten(generateArrayFromBooleanString(searchString));
  const mustHaveKeywords = _.flatten(generateArrayFromBooleanString(mustHaves));
  const niceToHaveKeywords = _.flatten(generateArrayFromBooleanString(niceToHaves));
  return [...titles, ...mustHaveKeywords, ...niceToHaveKeywords, ...searchStringKeywords];
};

export { getQuickSearchFormPayload, getKeywordsToHighlight };
