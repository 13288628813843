import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover, Button } from 'antd';
import FilterIconWithTooltip from '../Common/FilterIconWithTooltip/FilterIconWithTooltip';
import styles from './Candidate360TabFilterPopover.module.scss';
import message from '../../Containers/CandidateDrawer/messages';

function Candidate360TabFilterPopover(props) {
  const { content, isFilterApplied, isFilterPopoverVisible, onClearFilter, onApplyFilter, handleVisibleChange } = props;

  return (
    <Popover
      trigger="click"
      content={content}
      visible={isFilterPopoverVisible}
      onVisibleChange={handleVisibleChange}
      placement="leftTop"
      getPopupContainer={trigger => trigger.parentNode}
      title={
        <div className={styles.candidate360TabFilterPopover}>
          <div className={styles.candidate360TabFilterTitle}>
            <FormattedMessage {...message.filtersLabel} />
          </div>
          <div className={styles.candidate360TabFilterApplyClear}>
            <Button shape="round" className={styles.candidate360TabFilterClearButton} onClick={onClearFilter}>
              <FormattedMessage {...message.clearButton} />
            </Button>
            <Button
              shape="round"
              className={styles.candidate360TabFilterApplyButton}
              type="primary"
              onClick={onApplyFilter}
            >
              <FormattedMessage {...message.applyButton} />
            </Button>
          </div>
        </div>
      }
    >
      <FilterIconWithTooltip
        title={<FormattedMessage {...message.filtersLabel} />}
        isFilterApplied={isFilterApplied}
        style={{ fontSize: 20, marginTop: 5 }}
      />
    </Popover>
  );
}

export default Candidate360TabFilterPopover;
