import React from 'react';
import { Row, Col, Affix, Empty } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import JobListItem from '../JobListItem/JobListItem';
import messages from './messages';
import './JobList.scss';
import { EmptyJobsIcon } from '../../Icons/AryaIcons';
import { getShowSourcingProgress } from '../../Utils/SourceUtils';
import ScoutingAgentDrawer from '../../Containers/ManualSearch/ScoutingAgent/ScoutingAgentDrawer';
import ScoutingAgentModal from '../../Containers/ManualSearch/ScoutingAgent/ScoutingAgentModal';

function JobList(props) {
  const {
    jobs,
    jobStatus,
    onJobStatusChange,
    usersById,
    history,
    showReviewDialog,
    searchTerm,
    clearSearch,
    currStatus,
    countries,
    userConfig,
    toggleJobCard,
    featureToggleList,
    productVariantsById,
    fetchJobScoutingAgent,
    setScoutingAgentDrawerVisibility,
    scoutingAgentConfig,
  } = props;

  return (
    <div id="job-table">
      {featureToggleList.ScoutingAgent.IsEnabled && scoutingAgentConfig && (
        <>
          <ScoutingAgentDrawer
            jobId={history.location.search.split('=')[1]}
            history={history}
            candidateContext="jobs"
          />
          <ScoutingAgentModal jobId={history.location.search.split('=')[1]} />
        </>
      )}

      <Affix offsetTop={64}>
        <Row id="table-heading" type="flex" justify="space-between">
          <Col xs={24} sm={5}>
            <FormattedMessage {...messages.title} />
          </Col>
          <Col xs={24} sm={5}>
            <FormattedMessage {...messages.client} />
          </Col>
          {featureToggleList.ShareJob.IsEnabled ? (
            <Col xs={24} sm={3}>
              <FormattedMessage {...messages.shared} />
            </Col>
          ) : null}

          {featureToggleList.CandidatePipeline.IsEnabled ? (
            <Col xs={24} sm={5}>
              <FormattedMessage {...messages.candidates} />
            </Col>
          ) : null}

          {featureToggleList.OrderProgress.IsEnabled ? (
            <Col xs={24} sm={3} style={{ padding: '0px 0px 0px 20px' }}>
              <FormattedMessage {...messages.plan} />
            </Col>
          ) : null}
          {featureToggleList.OrderProgress.IsEnabled ? (
            <Col xs={24} sm={10} style={{ padding: '0px 0px 0px 235px' }}>
              <FormattedMessage {...messages.status} />
            </Col>
          ) : null}
          {featureToggleList.JobStatus.IsEnabled ? (
            <Col xs={24} sm={2} style={{ padding: '0px 0px 0px 7px' }}>
              <FormattedMessage {...messages.status} />
            </Col>
          ) : null}
          <Col xs={24} sm={1} className="status-tag"></Col>
        </Row>
      </Affix>
      {jobs.length ? (
        jobs.map(job => (
          <JobListItem
            scoutingAgentConfig={scoutingAgentConfig}
            isTagSelected={job.IsTagSelected}
            toggleJobCard={toggleJobCard}
            job={job}
            usersById={usersById}
            key={job.JobId}
            jobStatus={jobStatus}
            onJobStatusChange={onJobStatusChange}
            onSourcingProgressView={props.onSourcingProgressView}
            showReviewDialog={showReviewDialog}
            history={history}
            countries={countries}
            userConfig={userConfig}
            featureToggleList={featureToggleList}
            orderStatusDetails={_.get(job, 'OrderInfo', {})}
            productVariantsById={productVariantsById}
            setShowDrawer={setScoutingAgentDrawerVisibility}
            fetchJobScoutingAgent={fetchJobScoutingAgent}
          />
        ))
      ) : (
        <Empty
          image={<EmptyJobsIcon className="job-list-empty" />}
          description={
            <span>
              <span className="job-list-no-job-found"> No Jobs found</span>
              <br />
              {!searchTerm.trim().length ? (
                <span className="job-list-no-job-found-message">All the {currStatus} jobs appear here</span>
              ) : (
                <span
                  className="job-list-no-job-remove-search"
                  role="button"
                  tabIndex={0}
                  onClick={clearSearch}
                  onKeyPress={clearSearch}
                >
                  Clear search
                </span>
              )}
            </span>
          }
        />
      )}
    </div>
  );
}

JobList.propTypes = {
  jobStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSourcingProgressView: PropTypes.func,
};

JobList.defaultProps = {
  onSourcingProgressView: () => {},
};

export default JobList;
