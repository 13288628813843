import { defineMessages } from 'react-intl';
import sourcesMessagesJsonEn from '../Data/En_Us/SourcesMessagesEn_Us.json';
import sourcesMessagesJsonEs from '../Data/Es_Es/SourcesMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from './LanguageUtils';

const component = 'CandidateApiError';
const transformedMessage = formatMessagesWithComponentPrefix(sourcesMessagesJsonEn, component);
const candidateApiErrorMessages = defineMessages(transformedMessage);

export const candidateApiErrorMessagesEn = formatJsonWithComponentPrefix(sourcesMessagesJsonEn, component);
export const candidateApiErrorMessagesEs = formatJsonWithComponentPrefix(sourcesMessagesJsonEs, component);
export default candidateApiErrorMessages;
