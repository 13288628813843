import { defineMessages } from 'react-intl';

import PlaceholderMessagesJsonEn from '../../Data/En_Us/PlaceholderMessagesEn_Us.json';
import PlaceholderMessagesJsonEs from '../../Data/Es_Es/PlaceholderMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'Placeholders';
const transformedMessage = formatMessagesWithComponentPrefix(PlaceholderMessagesJsonEn, component);
const placeholderMessages = defineMessages(transformedMessage);

const PlaceholderMessage = defineMessages(placeholderMessages);
export const placeholderMessagesEn = formatJsonWithComponentPrefix(PlaceholderMessagesJsonEn, component);
export const placeholderMessagesEs = formatJsonWithComponentPrefix(PlaceholderMessagesJsonEs, component);

export default PlaceholderMessage;
