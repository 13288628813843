import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, Input } from 'antd';
import { connect } from 'react-redux';
import { getApiStatus } from '../../../../../Reducers/ApiStatusReducer';
import styles from './SaveSearchCriteriaModal.module.scss';
import message from '../../../ManualSearchMessages';

const mapStateToProps = state => ({
  saveManualSearchCriteriaApiStatus: getApiStatus(state, 'saveManualSearchCriteriaApiStatus'),
});
function SaveSearchCriteriaModal({
  isSaveSearchCriteriaModalVisible,
  handleSaveCriteria,
  setIsSaveSearchCriteriaModalVisible,
  saveManualSearchCriteriaApiStatus,
  setCriteriaName,
  isSaveSearchCriteriaFailureModalVisible,
  setSaveSearchCriteriaFailureModalVisible,
  criteriaName,
  handleUpdate,
}) {
  const handleSave = () => {
    setSaveSearchCriteriaFailureModalVisible(false);
    setIsSaveSearchCriteriaModalVisible(true);
  };
  const handleCancelSaveSearchCriteriaFailureModal = () => {
    setSaveSearchCriteriaFailureModalVisible(false);
  };
  const handleCancelSaveSearchCriteriaModal = () => {
    setIsSaveSearchCriteriaModalVisible(false);
  };
  return (
    <>
      <Modal
        title={
          <div className={styles.saveSearchCriteriaModalTitle}>
            <FormattedMessage {...message.saveSearchCriteria} />
          </div>
        }
        footer={null}
        height="223px"
        width="471px"
        visible={isSaveSearchCriteriaModalVisible}
        onCancel={handleCancelSaveSearchCriteriaModal}
      >
        <div className={styles.saveSearchCriteriaModalContent}>
          <div className={styles.saveSearchCriteriaModalDescription}>
            <Input
              size="large"
              placeholder="Frontend Devloper_Ben"
              onChange={event => setCriteriaName(event.target.value)}
              style={{ width: '409px', marginBottom: '31px' }}
            />
          </div>
          <div className={styles.saveSearchCriteriaModalFooter}>
            <div className={styles.note}>
              <FormattedMessage {...message.saveSearchCriteriaNote} />
            </div>
            <Button
              type="primary"
              shape="round"
              onClick={handleSaveCriteria}
              size="large"
              loading={saveManualSearchCriteriaApiStatus === 'INPROGRESS'}
              disabled={!criteriaName?.length}
            >
              <FormattedMessage {...message.saveCriteriaButton} />
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={isSaveSearchCriteriaFailureModalVisible}
        onCancel={handleCancelSaveSearchCriteriaFailureModal}
        width="479px"
        centered
        footer={null}
      >
        <div className={styles.saveSearchCriteriaFailureModalContent}>
          <div className={styles.saveSearchCriteriaFailureModalHeader}>
            {`"${criteriaName}"`} <FormattedMessage {...message.alreadyExistsLabel} />
            <br /> <FormattedMessage {...message.savedCriteriaUpdateMessage} />
          </div>
          <div className={styles.saveSearchCriteriaFailureModalDescription}>
            <FormattedMessage {...message.savedCriteriaFailureDescription} />
          </div>
          <div className={styles.saveSearchCriteriaFailureModalFooter}>
            <Button
              onClick={handleUpdate}
              shape="round"
              size="large"
              loading={saveManualSearchCriteriaApiStatus === 'INPROGRESS'}
            >
              <FormattedMessage {...message.updateLabel} />
            </Button>
            <Button onClick={handleSave} type="primary" shape="round" size="large">
              <FormattedMessage {...message.saveAsLabel} />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default connect(mapStateToProps)(SaveSearchCriteriaModal);
export { SaveSearchCriteriaModal as SaveSearchCriteriaModalWithoutStore };
