import React from 'react';
import _ from 'lodash';
import { Form, Table, Menu, Dropdown, Icon, Button, Input, Skeleton, Pagination, Tooltip, Descriptions } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { EditIcon } from '../../Icons/AryaIcons';
import styles from './SubSegmentStyles.module.scss';
import AddListModal from '../Lists/Modals/AddListModal';
import TransferConfirmationModal from './TransferConfirmationModal';
import EmptyScreen from './EmptyScreen';
import { EllipsedText } from '../../Utils/TextUtils';
import message from '../../Components/Placeholders/PlaceholdersMessages';

const SubSegmentList = props => {
  const {
    getAryaRecommendedTab,
    onStatusChange,
    form,
    fetchSubSegments,
    segmentId,
    fetchSubSegmentApiStatus,
    subsegmentList,
    setSubsegmentModalStatus,
    usersById,
    currentJobDetails,
    updateSubSegmentAction,
    updateSubsegmentApiStatus,
    subsegmentPaginationData,
    setSubsegmentPaginationData,
    setSelectedSubsegment,
    selectedSubsegment,
    featureToggleList,
  } = props;
  const [renderState, setRenderState] = React.useState('listView');
  const [subSegmentData, setSubsegmentData] = React.useState([]);
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [inputText, setInputText] = React.useState('');
  const [transferModalVisible, setTransferModalVisible] = React.useState(false);
  const { pageNumber, size } = subsegmentPaginationData;
  const { subSegments, total } = subsegmentList;

  const isSubSegmentCreationEnabled = featureToggleList?.SubSegmentCreation?.IsEnabled;
  let allUsers = [];
  if (usersById) {
    allUsers = allUsers.concat(currentJobDetails?.SharedWith || []);
    allUsers = allUsers.concat(currentJobDetails?.AssignedTo || []);
  }
  const getUserName = id => {
    return usersById[id] ? usersById[id].FullName : null;
  };

  const getUserEmail = id => {
    return usersById[id] ? usersById[id].Email : null;
  };

  let users = _.uniq(allUsers).map(userId => {
    return {
      name: getUserName(userId),
      Id: userId,
      Email: getUserEmail(userId),
    };
  });
  users = users.filter(user => !!user);

  React.useEffect(() => {
    if (fetchSubSegments) fetchSubSegments(segmentId, null, pageNumber, size);
  }, [fetchSubSegments, segmentId, renderState, pageNumber, size]);

  React.useEffect(() => {
    return () => {
      setSubsegmentPaginationData(1, 10);
    };
  }, [segmentId]);

  React.useEffect(() => {
    if (subSegments) setSubsegmentData(subSegments);
    if (renderState === 'listView') localStorage.setItem('subsegmentState', 'listView');
  }, [renderState, subSegments]);

  const onDescriptionChange = value => setInputText(value);

  const handleCancelEditModal = () => setEditModalVisible(!editModalVisible);
  const handleSearchValue = e => setSearchValue(e.target.value);

  const onSearch = val => fetchSubSegments(segmentId, val, pageNumber, size);

  const onPageChange = (_page, _pageSize) => {
    setSubsegmentPaginationData(_page, _pageSize ?? size);
    fetchSubSegments(segmentId, searchValue, _page, _pageSize ?? size);
  };

  const handleAddButton = () => {
    const formValues = form.getFieldsValue();
    const { ownerId } = formValues;
    form.validateFields(err => {
      if (!err) {
        if (selectedSubsegment.CreatedByUserId === ownerId) {
          updateSubsegmentDetails();
        } else {
          setTransferModalVisible(true);
          setEditModalVisible(false);
        }
      }
    });
  };

  const updateSubsegmentDetails = async () => {
    const formValues = form.getFieldsValue();
    const { listName, listDescription, ownerId } = formValues;
    const payload = {
      Name: listName,
      Description: listDescription,
      TransferOwnershipTo: ownerId,
    };
    await updateSubSegmentAction(segmentId, selectedSubsegment.Id, payload);
    setEditModalVisible(false);
    const updatedSubSegment = {
      ...subSegmentData,
      Name: listName,
      Description: listDescription,
      TransferOwnershipTo: ownerId,
    };
    setSelectedSubsegment(updatedSubSegment);
    if (renderState === 'listView') fetchSubSegments(segmentId, searchValue, pageNumber, size);
  };

  const isLoading = updateSubsegmentApiStatus === 'INPROGRESS';

  const handleMenuItemClick = (e, data) => {
    if (e.domEvent) e.domEvent.stopPropagation();
    form.setFieldsValue({
      listName: data.Name,
      listDescription: data.Description,
      ownerId: data.CreatedByUserId,
    });
    setSelectedSubsegment(data);
    handleCancelEditModal();
  };
  const generateMenu = (menuItems, rowData) => {
    return (
      <Menu>
        {menuItems.map(menuItem => (
          <Menu.Item key={menuItem} onClick={e => handleMenuItemClick(e, rowData)}>
            {menuItem}
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const columnWidth = 280;
  const columns = [
    {
      title: 'SUB-SEGMENT NAME',
      dataIndex: 'Name',
      key: 'Name',
      render: text => (
        <Tooltip title={text}>
          <b style={{ fontSize: 16 }}>{text.length > 20 ? text.slice(0, 20).concat('...') : text}</b>
        </Tooltip>
      ),
      width: columnWidth,
    },
    {
      title: 'CANDIDATES',
      dataIndex: 'AssociatedCandidateCount',
      key: 'AssociatedCandidateCount',
      render: text => (
        <div style={{ color: '#83878C' }}>
          <Icon type="usergroup-add" /> {text}
        </div>
      ),
      width: columnWidth,
    },
    {
      title: 'CREATED BY',
      dataIndex: 'CreatedByUser',
      key: 'CreatedByUser',
      render: (CreatedByUser, data) => (
        <div className={styles.tableItemCreatedBy}>
          <b>{CreatedByUser}</b>
          <div>{moment.utc(data.CreatedTime).local().format('hh:mm A | MMM DD, YYYY')}</div>
        </div>
      ),
      width: columnWidth,
    },
    {
      title: '',
      render: (_, data) => {
        const menuItems = ['Edit', 'Transfer Ownership'];
        return isSubSegmentCreationEnabled ? (
          <Dropdown overlay={generateMenu(menuItems, data)} overlayStyle={{ zIndex: 2004 }}>
            <Icon onClick={e => e.stopPropagation()} type="more" style={{ cursor: 'pointer', fontSize: 24 }} />
          </Dropdown>
        ) : null;
      },
      width: 20,
    },
  ];

  const handleRenderState = () => {
    setRenderState('listView');
    localStorage.setItem('subsegmentState', 'listView');
  };

  const handleRowClick = async rowData => {
    localStorage.setItem('subSegmentSelectedId', rowData.Id);
    await setSelectedSubsegment(rowData);
    onStatusChange('subsegment');
    setRenderState('');
    localStorage.setItem('subsegmentState', null);
  };
  const editModal = (
    <AddListModal
      isModalVisible={editModalVisible}
      handleCancel={handleCancelEditModal}
      handleOk={handleAddButton}
      title="Edit name and description"
      textAreaStyle={{ resize: 'none' }}
      buttonName="Save"
      form={form}
      isLoading={isLoading}
      inputText={inputText}
      characterLimit={200}
      ownerOptions={users}
      isOwnerEnabled
      onDescriptionInputChange={onDescriptionChange}
      maxCharactersAllowed={100}
      modalType="Sub-Segment"
    />
  );

  const transferModal = (
    <TransferConfirmationModal
      visible={transferModalVisible}
      setIsVisible={setTransferModalVisible}
      onConfirm={updateSubsegmentDetails}
      isLoading={isLoading}
    />
  );
  if (renderState === 'listView')
    return (
      <div className={styles.subSegmentList}>
        <div className={styles.filterContainer}>
          <div>
            <Input.Search
              size="default"
              placeholder="Search List"
              allowClear
              sh
              className={styles.listsInputSearch}
              onSearch={value => onSearch(value)}
              onChange={handleSearchValue}
              value={searchValue}
            />
          </div>
          <Tooltip
            title={
              !isSubSegmentCreationEnabled
                ? 'You do not have permission to Create a Sub-Segment. Please contact your admin'
                : ''
            }
          >
            <Button
              type="primary"
              shape="round"
              onClick={() => setSubsegmentModalStatus(true)}
              disabled={!isSubSegmentCreationEnabled}
            >
              Create new
            </Button>
          </Tooltip>
        </div>
        <Skeleton loading={fetchSubSegmentApiStatus === 'INPROGRESS'}>
          {subSegmentData.length > 0 && (
            <>
              <Table
                pagination={false}
                rowClassName={() => styles.subsegmentRow}
                className={styles.subSegmentTable}
                dataSource={subSegmentData}
                columns={columns}
                onRow={record => ({
                  onClick: () => handleRowClick(record),
                })}
              />
              <div className={styles.paginationContainer}>
                <Pagination
                  showSizeChanger
                  size="small"
                  pageSize={size}
                  total={total}
                  onShowSizeChange={onPageChange}
                  onChange={onPageChange}
                  hideOnSinglePage={total <= 10}
                  current={pageNumber}
                />
              </div>
            </>
          )}
          {subSegmentData.length === 0 && <EmptyScreen />}
        </Skeleton>
        {editModal}
        {transferModal}
      </div>
    );
  return (
    <div>
      <Button type="link" className={styles.backTolist} onClick={handleRenderState}>
        {'< Back to list'}
      </Button>
      <div className={styles.listNameWrapper}>
        <div className={styles.listName}>
          <EllipsedText text={selectedSubsegment?.Name} maxTextLength={20} />
        </div>
        <div
          className={styles.edit}
          onClick={e => handleMenuItemClick(e, selectedSubsegment)}
          role="button"
          tabIndex={0}
        >
          {isSubSegmentCreationEnabled ? <EditIcon fill="grey" /> : null}
        </div>
      </div>
      {editModal}
      {transferModal}
      {getAryaRecommendedTab}
    </div>
  );
};

export { SubSegmentList as SubSegmentListWithoutForm };
export default Form.create()(SubSegmentList);
