import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ClearAllButton.module.scss';
import message from '../ManualSearchMessages';

function ClearAllButton(props) {
  const { onClear, skEventName } = props;
  return (
    <div
      className={styles.clearAll}
      role="button"
      onClick={onClear}
      onKeyPress={onClear}
      tabIndex={0}
      sk-event-name={skEventName}
    >
      <FormattedMessage {...message.clearAllButton} />
    </div>
  );
}

export default ClearAllButton;
