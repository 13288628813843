import { defineMessages } from 'react-intl';
import candidateDrawerMessagesJsonEn from '../../Data/En_Us/CandidateDrawerMessagesEn_Us.json';
import candidateDrawerMessagesJsonEs from '../../Data/Es_Es/CandidateDrawerMessagesEs_Es.json';
import { formatMessagesWithComponentPrefix, formatJsonWithComponentPrefix } from '../../Utils/LanguageUtils';

const component = 'CandidateDetail';
const candidateDrawerMessages = formatMessagesWithComponentPrefix(candidateDrawerMessagesJsonEn, component);

const candidateDrawerMessagesEn = formatJsonWithComponentPrefix(candidateDrawerMessagesJsonEn, component);
const candidateDrawerMessagesEs = formatJsonWithComponentPrefix(candidateDrawerMessagesJsonEs, component);
const messages = defineMessages({
  prev: {
    id: 'CandidateDetail.prev',
    defaultMessage: 'Prev',
  },
  next: {
    id: 'CandidateDetail.next',
    defaultMessage: 'Next',
  },
  profile: {
    id: 'CandidateDetail.profile',
    defaultMessage: 'Profile',
  },
  personality: {
    id: 'CandidateDetail.personality',
    defaultMessage: 'Personality',
  },
  notes: {
    id: 'CandidateDetail.notes',
    defaultMessage: 'Notes',
  },
  candidateIntel: {
    id: 'CandidateDetail.candidateIntel',
    defaultMessage: 'Candidate Intel',
  },
  ...candidateDrawerMessages,
});

export default messages;
export { candidateDrawerMessagesEn, candidateDrawerMessagesEs };
