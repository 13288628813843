import React from 'react';
import { Modal, Button, Form, Input, Select } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import styles from './SubSegmentStyles.module.scss';
import message from '../ManualSearchV2/ManualSearchMessages';

const SubsegmentModal = props => {
  const {
    isModalVisible,
    setIsModalVisible,
    createSubSegmentAction,
    segmentId,
    createSubsegmentApiStatus,
    form,
    activeTab,
    setAddSubsegmentModalStatus,
    addSubsegmentModalStatus,
    currentUser: { given_name: name },
    fetchSubSegments,
    setSubsegmentPaginationData,
    intl,
  } = props;

  const [modalData, setModalData] = React.useState({ Name: '', Description: '' });
  React.useEffect(() => {
    return () => {
      setIsModalVisible(false);
    };
  }, []);
  const title = <FormattedMessage {...message.createANewSubSegment} />;
  const resetField = () => {
    setModalData({ Name: '', Description: '' });
    form.setFieldsValue({
      Name: '',
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    resetField();
    if (activeTab === 'sourced' || activeTab === 'newTab' || activeTab === 'rejected')
      setAddSubsegmentModalStatus(true, addSubsegmentModalStatus);
  };

  const handleOnChange = e => {
    const { name, value } = e.target;
    const tempModalData = { ...modalData };
    tempModalData[name] = value;
    setModalData(tempModalData);
  };

  const handleOnSubmit = () => {
    form.validateFields(async err => {
      if (!err) {
        const isNotFromListView = activeTab === 'sourced' || activeTab === 'newTab' || activeTab === 'rejected';
        if (modalData.Description.length > 200) return;
        await createSubSegmentAction(segmentId, modalData, isNotFromListView);
        resetField();
        await fetchSubSegments(segmentId, null, 1, 10);
        if (isNotFromListView) {
          setAddSubsegmentModalStatus(true, addSubsegmentModalStatus);
        } else {
          setSubsegmentPaginationData(1, 10);
        }
        setIsModalVisible(false);
      }
    });
  };
  const characterLimit = 200;
  return (
    <Modal
      title={title}
      visible={isModalVisible}
      centered
      zIndex={3000}
      onCancel={handleCancel}
      footer={[
        <Button shape="round" key="back" onClick={handleCancel}>
          <FormattedMessage {...message.cancelLabel} />
        </Button>,
        <Button
          loading={createSubsegmentApiStatus === 'INPROGRESS'}
          shape="round"
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={handleOnSubmit}
          disabled={modalData.Name.trim() === ''}
        >
          <FormattedMessage {...message.createLabel} />
        </Button>,
      ]}
    >
      <Form>
        <Form.Item>
          {form.getFieldDecorator('Name', {
            rules: [
              {
                validator: (_, value) => {
                  if (value.length > 100)
                    return Promise.reject(new Error(intl.formatMessage({ ...message.subSegmentTitleLengthWarning })));
                  if (value.trim() === '') return Promise.reject(new Error(intl.formatMessage({ ...message.pleaseEnterTheSubSegmentNameLabel })));
                  return Promise.resolve();
                },
              },
            ],
          })(<Input onChange={handleOnChange} placeholder={intl.formatMessage({ ...message.enterAName })}name="Name" />)}
        </Form.Item>
        <br />
        <Form.Item>
          <Input.TextArea
            onChange={handleOnChange}
            value={modalData.Description}
            rows={4}
            className={styles.createSubDescription}
            name="Description"
            placeholder={intl.formatMessage({ ...message.enterShortDescriptionLabel })}
          />
          <div className={styles.textAreaValidation}>
            {modalData.Description?.trim()?.length > 200 && (
              <span>
                <FormattedMessage {...message.descriptionWordLengthWarning} />
              </span>
            )}
            {(modalData.Description?.trim()?.length || 0) <= characterLimit ? (
              <p>
                {modalData.Description?.trim()?.length || 0}/{characterLimit}
              </p>
            ) : (
              <p style={{ color: '#f5222d' }}>
                {characterLimit - modalData.Description?.trim()?.length}/{characterLimit}
              </p>
            )}
          </div>
        </Form.Item>
        <div>
          <Form.Item label="Owner" className={styles.ownerSelect}>
            <Select disabled value={name}></Select>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
export { SubsegmentModal as SubsegmentModalWithoutForm };
export default (injectIntl(Form.create()(SubsegmentModal)));
